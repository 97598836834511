import * as React from 'react';
import { Card, Col, Modal } from 'react-bootstrap';
import { Localization, SimpleTranslationTable } from "@viamap/viamap2-common";
import { Logger } from '@viamap/viamap2-common';
import { FormatUtils } from 'src/managers/FormatUtils';
import './PropertyInformationDialog.css';
import { Cookies } from '@viamap/viamap2-common';
import {BsChevronRight} from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { usePanWindow } from 'src/common/components/CustomHooks';
import { MarketData } from 'src/managers/MarketDataInterface';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { FeatureProperties } from 'src/managers/WmsLayerFunc';
import { PropertyInfoInterface as PropertyInfoInterface2} from 'src/compentsModus/PropertyInfoInterface';
import { PropertyInfoInterface} from '../managers/PropertyInfoInterface';
import { KeyValueTable, RenderLinkToCompanyGraph, RenderLinkToPersonGraph, ViewButtonIcon } from 'src/propertyInfoTemplates/PropertyComponents';
import { ViewButtonCheck } from 'src/propertyInfoTemplates/PropertyComponents';
import { ProtectedFeature } from './ProtectedFeature';
import { actionSetShowWindow, MapitStateContext, MapitWindowId } from 'src/states/MapitState';
import { PiGraphFill } from 'react-icons/pi';


type propertyData = {
   matrikelNr?: string;
   ejerlavsKode?: string;
   ejerlav?: string;
   bfeNr?: string;
   kommunekode?:string;
   kommunenavn?:string;
   postnummer?:string;
   postbynavn?:string;
   tib?: any;
   bbr?: any;
   mat?: any;
   lokalPlan?: any;
   kommunePlanRamme?: any;
   ejd?: any;
   ejdAdresse?: any;
   stats?: any;
   dhm_overflade?: any;
   dhm_terraen?: any;
   bbrGrund?:any;
   bbrBygninger?: any;
   bbrEnheder?:any;
   samletFastEjendom?: any;
   vurderingsEjendom?: any;
   esr_ejendomsnummer?: any;
   handelsOplysning?:any;
   marketData?: {[ejendomsType:string]:MarketData};
   travelTimeInfo?:  {         catchmentState: any,   polygons: any };
   obliqueImage?: {point:any[], image:any, width:number, height:number, pictureDate: Date, alternativePoint?:any[]};
   overviewMapInfo?: {lat:number, lng:number};
   marketHeatMapData?:any;
};

type Props = {
   showWindow: number;
   callBackOnClose: () => void;
   cvrNr: number;
   cols?: number;
};
const COOKIE_InfoSelectedTab="CompanyInfoSelectedTab";
const COOKIE_AutoColapsed="CompanyAutoColapsed";
const COOKIE_ShowAsGroups="CompanyShowAsGroups";

export function CompanyInformationDialog (props:Props) {


   const [propertyMarketDataTypeSelected,setPropertyMarketDataTypeSelected] = React.useState("Kontor")
   const [propertyDefaultCollapsed,setPropertyDefaultCollapsed] = React.useState(Cookies.getCookieBoolean(COOKIE_AutoColapsed))
   const {hasAccessToFeature} = React.useContext(ApplicationStateContext)
   const {dispatch: mapitStateDispatch} = React.useContext(MapitStateContext)
   const [data,setData] = React.useState<any>({})

   const [cvrNr,setCvrNr] = React.useState<number>(0);

   const propertyShowAsGroups = true

   React.useEffect(() => {
      if (props.showWindow && props.cvrNr) {
         setData({})
         setCvrNr(props.cvrNr)

         getDataAsync(props);
      }
   },[props.showWindow,props.cvrNr])

   
   function onClickClose() {
      props.callBackOnClose();
   }

   async function getDataAsync(props:Props) {
      let data:any = {};
      let cvrNr = props.cvrNr;
      if (!cvrNr) {
         return;
      }

      let cvrData = await PropertyInfoInterface2.getCVRSearch(   { "query":{
         "bool": {
            "must": [
               {
                  "nested": {
                     "path": "Vrvirksomhed.livsforloeb",
                     "query": {
                        "bool": {
                           "must_not": [
                              {
                                 "exists": {
                                    "field": "Vrvirksomhed.livsforloeb.periode.gyldigTil"
                                 }
                              }
                           ]
                        }
                     }
                  }
               }, {
         "term":{"Vrvirksomhed.cvrNummer":""+cvrNr}
         }
      ]
         }
      }});
      let keyData = PropertyInfoInterface2.extractRelevantData(cvrData);

      let punitData = await PropertyInfoInterface.PEnhederforCVR(cvrNr);

      setData({...keyData, punits:punitData});
   }

   function renderCVRLink(cvrNr:number) {
      let cvrLink = "https://datacvr.virk.dk/enhed/virksomhed/"+cvrNr;
      return   cvrNr ? (
         <a href={cvrLink} target="_blank" style={{textAlign: "left", fontWeight:"600", color:"black"}}>{cvrNr}</a>
       ) : null;
   }

   let renderVirkGeneralData: {title:string, body:any} | null = null;
   if (data.branchekode) {
      let props = {};
      try {
         props = {
            "CVR Nummer": data.cvrNummer,
            "Navn": data.navn,
            "Branchekode": data.branchekode,
            "Branchetekst": data.branchetekst
         };
      } catch {
         Logger.logError("CompanyInformationDialog", "render", "data service output wrong format");
      }
      let feat =formatFeatureInfoResponse(props);
      renderVirkGeneralData = AgroupParam('Virksomheden', feat);
   }

   let renderVirkAttributter: {title:string, body:any} | null = null;
   if (data.attributter) {
      let feat = (
         <div>
            <table className="mit-geo-details-popup">
               <tbody>
                  {data.attributter && data.attributter.filter((att) => [
                     "FORMÅL", 
                     "KAPITAL", 
                     "KAPITALVALUTA", 
                     "TEGNINGSREGEL", 
                     "FØRSTE_REGNSKABSPERIODE_START",
                     "FØRSTE_REGNSKABSPERIODE_SLUT",
                     "VEDTÆGT_SENESTE"].includes(att.type)).map((att, i) => {
                        let formatted:string|undefined = undefined;
                        if ("decimal" == att.vaerditype) {
                           let float = parseFloat(att.vaerdi)
                           formatted = new Intl.NumberFormat("da-dk",{minimumFractionDigits:2}).format(float);
                        }
                     return (
                        <tr key={att.type+i}>
                           <td >
                              {att.type}
                           </td>
                           <td>
                              {formatted ?? att.vaerdi}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
               </tbody>
            </table>
         </div>
      );
      renderVirkAttributter = AgroupParam('Attributter', feat);
   }

   let renderVirkEjere: {title:string, body:any} | null = null;
   if (data.ejere) {
      let feat = (
         <div>
            <table style={{width:"100%"}}>
            <thead>
                  <tr>
                     <th>Navn</th>
                     <th>CVR-nummer</th>
                     <th>Type</th>
                     <th>Andel</th>
                  </tr>
               </thead>
               <tbody>
                  {data.ejere && data.ejere.map((ejer, i) => {
                     return (
                        <tr key={ejer.forretningsnoegle}>
                           <td >
                              {ejer.navn}
                           </td>
                           <td>
                              {ejer.forretningsnoegle ? 
                              // renderCVRLink(ejer.forretningsnoegle)
                              <RenderLinkToCompanyGraph cvrNr={ejer.forretningsnoegle} />
                              :
                              <RenderLinkToPersonGraph cvrPersonId={ejer.cvrPersonId} />
                              }
                           </td>
                           <td >
                              {(ejer.enhedstype || "").replace("ANDEN_DELTAGER","UDENLANDSK_AKTØR")}
                           </td>
                           <td>
                              {ejer.andel+"%"}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
               </tbody>
            </table>
         </div>
      );
      renderVirkEjere = AgroupParam('Ejere', feat);
   }

   let renderVirkPunitData: {title:string, body:any} | null = null;
   if (data.punits) {
      function formatBeskaeftigelse(data:any) {
         return data ? (
           <KeyValueTable>
             {
               ["aar", "kvartal", "antalAnsatte", "antalAarsvaerk"].reduce<(Element | JSX.Element)[]>((result, item, idx) => {
                 let val = data[item];
                 if (val) {
                   let itemFormatted = (
                     <tr key={idx}>
                     <td>{item}</td>
                     <td>{val}</td>
                   </tr>    
                   )
                   return [...result, itemFormatted];
                 } else {
                   return result;
                 } 
               }, [])
             }
           </KeyValueTable>
         ) : null;
       }
      let feat = (
         <div>
            <table style={{width:"100%"}}>
            <thead>
                  <tr>
                     {/* <th>Navn</th>
                     <th>Branche</th> */}
                     <th>Adresse</th>
                     <th>Kontaktopl</th>
                     <th>Ansatte</th>
                     <th>Årsværk</th>
                  </tr>
               </thead>
               <tbody>
                  {data.punits && data.punits.map((unit, i) => {
                     return (
                        <tr key={unit.id}>
                           {/* <td >
                              {unit.navn}
                           </td>
                           <td>
                              {unit.branchetekst}
                           </td> */}
                           <td >
                              {unit.adresse}
                           </td>
                           <td>
                              {unit.kontaktoplysninger.reduce((result, txt, idx) => result+(idx>0?", ":"")+txt, "")}
                           </td>
                           <td>
                              {unit.beskaeftigelseKvt?.antalAnsatte || unit.beskaeftigelseAar?.antalAnsatte || ""}
                           </td>
                           <td>
                              {unit.beskaeftigelseKvt?.antalAarsvaerk || unit.beskaeftigelseAar?.antalAarsvaerk || ""}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
               </tbody>
            </table>
         </div>
      );
      renderVirkPunitData = AgroupParam('Produktions Enheder', feat);
   }

   // let renderBoligmarkedsStatistik: {title:string, body:any} | null =  null;
   // if (data.BoligmarkedsStatistik && data.ejd && data.stats && data.stats.Samlet) {
   //    let shortenArray = (val:any, idx:number) => {return idx>3;};
   //    let renderBoligmarkedsStatistikData = (
   //       <>
   //       <div>
   //          <table className="mit-geo-details-popupxx" style={{width:"100%"}}>
   //             <thead>
   //                <tr>
   //                   <th>Kvartal:</th>
   //                   {data.stats.Samlet?.perioder?.filter(shortenArray).map((per,idx) => { return (<th key={idx}>{per}</th>); })}
   //                </tr>
   //             </thead>
   //             <tbody>
   //                <tr>
   //                   <td>{data.ejd.kommuneNavn + " (" + data.kommunekode+")"}</td>
   //                   {data.stats.Samlet.vaerdierBM010.filter(shortenArray).map((per,idx) => { return (<td key={idx}>{per}</td>); })}
   //                </tr>
   //                <tr>
   //                   <td>{data.postnummer  + " " + data.postbynavn}</td>
   //                   {data.stats.Samlet.vaerdierBM011.filter(shortenArray).map((per,idx) => { return (<td key={idx}>{per}</td>); })}
   //                </tr>
   //             </tbody>
   //          </table>
   //          <h5 style={{ textAlign: "left" }}>Kilde: {data.stats.Samlet.kilde}</h5>
   //          <h5 style={{ textAlign: "left" }}>Enhed: {data.stats.Samlet.enhed}</h5>
   //       </div>
   //       </>
   //    );

   //    renderBoligmarkedsStatistik = AgroupParam('Realiceret handelspris, Parcel-/rækkehus', renderBoligmarkedsStatistikData);
   // }

   // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
 
      if (props.showWindow) {
         let closeButton = (
            <MdClose className='CloseButton' onClick={() => props.callBackOnClose()} />
            );
         let renderArealer: {title:string, body:any} | null = null;
         let renderHoejdeData = (
            <table className="mit-geo-details-popup">
               <tbody>
                  <tr>
                     <td>Højde, grund</td><td>123</td>
                  </tr>
                  <tr>
                     <td>Højde, hus (omtrentligt)</td><td>456</td>
                  </tr>
               </tbody>
            </table>
         );
         renderArealer = AgroupParam('Højder', renderHoejdeData);
      
         // --------------------------------------------- render cvr information -----------------------------------------------------


         // ---------------------------------------------- generic stuff -------------------------------------------------------

         function RemberCollapseSettings(val:any) {
            Cookies.setCookieBoolean(COOKIE_AutoColapsed , !val);
         }

         let fold = (
            <div >

            <ViewButtonCheck
               checked={!propertyDefaultCollapsed} 
               onCheck={(e, val) => {RemberCollapseSettings(val); setPropertyDefaultCollapsed(!val)}}
            >
               {Localization.getText("Expanded")}
            </ViewButtonCheck>
            {/* <Form.Check type="checkbox" 
            style={{paddingLeft:"40px"}}
            defaultChecked={state.propertyShowAsGroups} 
            onChange={(e) => {RemberGroupSettings(e); setState({propertyShowAsGroups: (e.target as any).checked});}}
            label= {Localization.getText("Group")}
            /> */}
            </div>
         );
         
         return (
            <DragModal style={{zIndex:1000 + props.showWindow, isolation:"isolate"}} className="mit-developer-information Flat-Modal unsetWidth Flat-PropertyView TempCSS scrollbarHidden MitNotInfoFix"
               headerContent={<><h4>Virksomhedsinformation</h4>
               <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
                  <ViewButtonIcon 
                  title={Localization.getText("Show ownership graph")}
                  className="no-print"
                  onClick={() => {
                     mapitStateDispatch(actionSetShowWindow(MapitWindowId.OwnerShipDiagram, true, {cvrNr: props.cvrNr}))
                  }}
                  >
                     <PiGraphFill />
                  </ViewButtonIcon>
               </ProtectedFeature>
               {fold}{closeButton}</>}
            >  
               <RenderAsGroups group={true} selectedGroup={Cookies.getCookie(COOKIE_InfoSelectedTab) || 'Alle'} cols={props.cols || 1}>
                  
                  <RenderGroup 
                     group='Virksomhed'
                     title={renderVirkGeneralData != null ? renderVirkGeneralData.title : ""} 
                     body={renderVirkGeneralData != null ? renderVirkGeneralData.body : ""} 
                     expand={!propertyDefaultCollapsed} 
                     defaultExpanded={!propertyDefaultCollapsed} 
                     grid={false}
                     layout=''
                  />
                  
                  <RenderGroup 
                     group='Attributter'
                     title={renderVirkAttributter != null ? renderVirkAttributter.title : ""} 
                     body={renderVirkAttributter != null ? renderVirkAttributter.body : ""} 
                     expand={!propertyDefaultCollapsed} 
                     defaultExpanded={!propertyDefaultCollapsed} 
                     grid={false}
                     layout=''
                  />
                  
                  <RenderGroup 
                     group='Ejere'
                     title={renderVirkEjere != null ? renderVirkEjere.title : ""} 
                     body={renderVirkEjere != null ? renderVirkEjere.body : ""} 
                     expand={!propertyDefaultCollapsed} 
                     defaultExpanded={!propertyDefaultCollapsed} 
                     grid={false}
                     layout=''
                  />

                  <RenderGroup 
                     group='Produktions Enheder'
                     title={renderVirkPunitData != null ? renderVirkPunitData.title : ""} 
                     body={renderVirkPunitData != null ? renderVirkPunitData.body : ""} 
                     expand={!propertyDefaultCollapsed} 
                     defaultExpanded={!propertyDefaultCollapsed} 
                     grid={false}
                     layout=''
                  />

               </RenderAsGroups>

            </DragModal>
         );
      } else {
         return null;
      }

}

// -------------------------------------- generic stuff - TODO: move to common class -----------------------

function DragModal(props:{className, headerContent ,children, style}) {
   let [eventer, setRef, clickEventer] = usePanWindow(0)

   return (
      <Modal.Dialog style={props.style} className={props.className} ref={setRef}>
         <Modal.Header onMouseDown={eventer} onTouchStart={eventer}>
            <div className='GrabLines' onMouseDown={clickEventer} />
            {props.headerContent}
         </Modal.Header>
         {props.children}
      </Modal.Dialog>
   )
}

function AgroupParam(title:string, body:any, other?:any) {
   return other? {title: title, body:body, ...other} : {title: title, body:body};
}

function RenderArrayOfGroups(props: {group: string, children?: any}) {
   return (props.children ? props.children : <></>);
}

function RenderGroup(props: {title: string,subTitle?: string,body: any,expand?: boolean, defaultExpanded?: boolean, group?:string, id?:any, className?:string, grid?:boolean, layout?:string}):JSX.Element {
   let [expanded, setExpanded] = React.useState(props.defaultExpanded !== undefined ? props.defaultExpanded : false);

   React.useEffect(() => {setExpanded(props.expand || false);},[props.expand]);

   if (!props.title) {
      return (<></>);
   }
   return (
      <Card key={props.title} id="mit-developer-information-group" className={props.className || ""} >
         <Card.Header onClick={(evt => setExpanded((a) => !a))} style={{ textAlign: "left" }} >
            <Card.Title >
               <h3 style={{display:"inline"}}>
                  {formatToSentenceCase(props.title)}
                  {props.subTitle? (<span style={{fontSize:"0.8em",alignSelf:"end"}}> ({props.subTitle})</span>):<></>}
               </h3>
               <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(expanded?"90deg":"0deg")+")"}}><BsChevronRight /></span>
            </Card.Title>
         </Card.Header>
         {expanded ? <Card.Body className={(props.grid ? "useGrid " : "") + props.layout}>
             {props.body}
         </Card.Body> : null}
      </Card>
   );
}

function RenderAsGroups(props: {group: boolean, selectedGroup?:string, children: any, cols:number}):JSX.Element {
   let [showGroup, setShowGroup] = React.useState(props.selectedGroup);
   let thisRef = React.useRef<HTMLElement>()

   React.useEffect(() => {
      

      console.log(thisRef.current)
      if (thisRef.current) {
         let element = thisRef.current as HTMLDivElement
         const moveScrollEvent = (e:MouseEvent) => {
            let {left, right} = element.getBoundingClientRect();
            if (e.clientX < left || e.clientX > right) {
               return
            }
            if ((e as any).sourceCapabilities?.firesTouchEvents) {
               return
            }
            element.scrollLeft = (element.scrollWidth-element.clientWidth)*(e.clientX-left)/element.clientWidth;
         }
         document.addEventListener("mousemove",moveScrollEvent);
         return () => document.removeEventListener("mousemove",moveScrollEvent);
      }
   },[])

   let groups = [... new Set([...React.Children.map(
         props.children,
         (element) => {
            return element?.props?.group;
         }
         ),"Alle"].filter((a) => a))];

   function select(a:string) {
         setShowGroup(a);
         Cookies.setCookie(COOKIE_InfoSelectedTab, a);
   }

   if (props?.group) {
      let showedGroups = props.children.filter((a) => {return showGroup === "Alle" || showGroup == undefined || !groups.includes(showGroup) || showGroup === a?.props?.group;});
      return (
      <>
      <Col ref={thisRef} className='Flat-FlexButton-Group' style={{scrollbarWidth:"none", maxHeight:"49px"}}  >
         {groups.map((a) => {
            return (
               <button key={a} style={{flexGrow:1}} className={"btn small " + (a === showGroup? 'btn-success' : 'btn-outline-success')} onClick={(evt) => select(a)}>{a}</button>
            );
         })}
         </Col>
      <Modal.Body className="mit-developer-information-container EjdSplitted" style={{paddingTop:"5px"}}>
         <ColumnSplitter cols={props.cols} >
            {showedGroups}
         </ColumnSplitter>
      </Modal.Body>
      </>
      );
   }
   return (
      <Modal.Body className="mit-developer-information-container" style={{paddingTop:0}}>
         {props.children}
      </Modal.Body>
   );
}

function formatToSentenceCase(text: string):string {
   text = text.replace(/([A-Z])/g, " $1");
   text = text.charAt(0).toUpperCase() + text.slice(1);
   
   text = text.replace(/(AE)/, "Æ");
   text = text.replace(/(ae)/, "æ");
   text = text.replace(/(OE)/, "Ø");
   text = text.replace(/(oe)/, "ø");
   text = text.replace(/(AA)/, "Å");
   text = text.replace(/(aa)/, "å");
   text = text.replace(/(S F E)/, "SFE");
   text = text.replace(/(B F E)/, "BFE");
   text = text.replace(/(E S R)/, "ESR");
   text = text.replace(/(V U R)/, "VUR");
   text = text.replace(/(B B R)/, "BBR");


   text = text.replace(/_/g, ' ');

   return text + " ";
}

function extractProperties(result:any):any {
   return result && result.features && result.features.length > 0 && result.features[0].properties;
}



function ColumnSplitter(props:{children, cols}) {
   let min = Math.min(props.cols, props.children.length) || 1;
   let indices:number[] = [...Array(min).keys()]
   return (
      <>
      {
         indices.map((colIndex) => {
            return (
               <div className='PropCol' key={colIndex}>
                  {props.children.filter((a,childIndex) => (childIndex) % (min) == colIndex)}
               </div>
            )
      })
      }
      </>
   )

}



function formatFeatureInfoResponse(propertiesToDisplay:FeatureProperties, translationTable?:SimpleTranslationTable):JSX.Element {
   const amountOfSubElementsToGroupAt = 5;
   const formatterDkk = new Intl.NumberFormat('da-dk', {style: 'currency', currency:'dkk'});

   let res;
   let rows:any[] = [];
   if (propertiesToDisplay) {
     Object.keys(propertiesToDisplay).forEach((key,idx) => {
       let name= translationTable ? Localization.getTextSpecificTable(key, translationTable) : key;
       let keepName = true;
       let value=propertiesToDisplay[key];
       let valElm: JSX.Element;
       if (value && value.toString().toLowerCase().startsWith("http")) {
         // If link, show as a hyperlink
         valElm = (
           <a key={key} href={value} target="_blank">{value}</a>
         );
       } else if (value && value.constructor === [].constructor) {
          // If list, show as indexed items
          valElm = value.map((val, index) => {
             if (val && (val.constructor === [].constructor || val.constructor === ({}).constructor)) {
               keepName = false;
               return (
                  <RenderGroup 
                     key={Localization.convertPluralToSingular(key) + " " + (val.år ? val.år : (index + 1))} 
                     title={Localization.convertPluralToSingular(key) + " " + (val.år ? val.år : (index + 1))} 
                     body={formatFeatureInfoResponse(val)} 
                     subTitle={val.tekst ? val.tekst : undefined}
                     defaultExpanded={false}
                  />
               );
             } else {
               return <div key={val}>{val}</div>;
            }
          });
          if (value.length > amountOfSubElementsToGroupAt) {
            keepName = false;
            valElm = <RenderGroup key={key} title={name} subTitle={value.length + Localization.getText(" results total")} body={valElm} defaultExpanded={false}/>;
          }
       } else if (value && value.constructor === ({}).constructor) {
          keepName = false;
          valElm = <RenderGroup key={key} title={formatToSentenceCase(key)} body={formatFeatureInfoResponse(value)} defaultExpanded={false}/>;
          if (value.length > amountOfSubElementsToGroupAt) {
             valElm = <RenderGroup key={key} title={name} subTitle={value.length + Localization.getText(" results total")} body={valElm} defaultExpanded={false}/>;
          }
       } else if (typeof(value) === "string" && value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{6}\+[0-9]{2}:[0-9]{2}/)) {
          // it is a full date time. show only the date
          let dateObj = new Date(value);
          valElm = (
            <div key={key}>{FormatUtils.formatDate(dateObj)}</div>
         );
       } else if ((!key.toLowerCase().includes("kode")) && ["beløb","værdi"].some((a) => key.toLowerCase().includes(a))) {
         valElm = (
            <div key={key}>{value > 100000 ? formatterDkk.format(value).replace(",00","") : formatterDkk.format(value) }</div>
         );
       } else {
          // default
          valElm = (
             <div key={key}>{value}</div>
          );
       }
       let elm=(
         <tr key={"props"+idx+key}>{keepName ? <td><strong>{formatToSentenceCase(name)}</strong></td> : <></>}<td>{valElm}</td></tr>
       );       
       rows.push(elm);
     });
     res = (
       <table className="mit-geo-details-popup">
         <tbody>
           {rows}
         </tbody>
       </table>
     );
   }
   if (rows.length === 0) {
     res = (
       <strong>{Localization.getText("No data")}</strong>
     );
   }
   return res;
}
 