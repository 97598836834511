import { Badge, Button, Dropdown } from "react-bootstrap";
import { AiOutlineMenu, AiFillBug } from "react-icons/ai";
import { FiBell, FiGrid, FiMapPin, FiSettings, FiUser, FiChevronDown, FiClock } from "react-icons/fi";
import { MdLocationSearching } from "react-icons/md";
import polylabel from "polylabel"

import React, { MutableRefObject, useContext, useRef, useState } from "react";
import { actionSetErrorMessage, AppMessagesContext, LanguageSelector, LicensingContext, LoadedSettingsWindow, Localization, Cookies, MitCookies, PersistenceScope, SessionContext, SettingsManager, UserHelpContext, Utils, actionSetInfoMessage, actionSetProgressMessage, actionClearProgressMessage } from "@viamap/viamap2-common";
import { actionLogoff, actionSetLoginDialogMode, SessionActionType } from "@viamap/viamap2-common";
import { AppMode, DawaAutoCompleteResult } from '../common/managers/Types';

import Indstilinger from '../svg/NewSecSVG/Indstillinger_blue.svg'
import Burgermenu from '../svg/NewSecSVG/Burgermenu.svg'
import Pin from '../svg/NewSecSVG/Pointer_blue.svg'
import Min_Position from '../svg/NewSecSVG/Min_Position_blue.svg'
import Notifikationer from '../svg/NewSecSVG/Notifikationer_blue.svg'
import Min_Side from '../svg/NewSecSVG/Min_Side_blue.svg'
import ViamapLogo from '../svg/ViamapIcon.svg'
import EstateExLogo from '../svg/Estate_Explorer_logo.svg'

import { actionAddDataLayer, actionAddPopup, actionSetFeatureLayerVisibility, actionSetMapInteractionState, actionSetMapInteractionStateDefault, actionSetMessage, actionSetShowUserLocation, actionSetShowWindow, MapInteractionState, MapitStateContext, MapitWindowId } from "src/states/MapitState";
import { LayerFunc } from "src/managers/LayerFunc";
import { PopBtn, PopDown, PopExtendSide } from "./ComponentUtils";
import { actionDisableLicense, actionSwapLicenseVariant, ViamapLicense } from "@viamap/viamap2-common";
import { LicenseStateCompact } from "@viamap/viamap2-common/dist/components/LicenseStateView"
import { ProtectedFeature } from "./ProtectedFeature";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { LoginDialogMode } from "@viamap/viamap2-common";
import { showReleases, showUserGuide } from "@viamap/viamap2-common/dist/states/UserHelpState";
import { UserAccessRightsWindow } from "./UserAccessRightsWindow";
import { ThemeSelector } from "./ThemeManager";
import { DialogMode } from "./MultiPointTravelDialog";
import { licenseVariantToRoles } from "src/states/ApplicationStateVariantMapping";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { MitLatLng } from "src/managers/MapFacade";
import { SimulateOtherUserDialog } from "./SimulateOtherUserDialog";
import { SearchBarGeneral } from "./SearchBarGeneral";
import { AddressAutocomplete, BFEAutocomplete, CVRNumberAutocomplete, CVRNavnAutocomplete, CVRPersonAutocomplete, EjerlavAutocomplete, EjerNavnAutocomplete, MatrikelAutocomplete, VejAutocomplete, FeatureLayerAutoComplete, StednavnAutoComplete } from "src/managers/SearchBarGeneralPlugin";
import { GenerateGeomUtils } from "src/managers/GenerateGeomUtils";
import { PropertyInformation } from "src/managers/PropertyInformation";
import { FeatureLayer } from "src/managers/WmsLayerFunc";
import { DataLayerClickFunction } from "./MapScreen";
import { MapFacadeMaplibre } from "src/managers/MapFacadeMaplibre";

export const ModusContext = React.createContext("Map");

export default function NavigationBar(props: {children: any}) {
  
  const {dispatch:appMessageDispatch} = React.useContext(AppMessagesContext);
  const {state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters} = React.useContext(MapitStateContext);
  const {state : licensingState, dispatch:licensingDispatch} = useContext(LicensingContext);
  const {state : sessionState, dispatch: sessionDispatch} = useContext(SessionContext);
  const [showLoadedSettingsWindow, setShowLoadedSettingsWindow] = React.useState<boolean>(false);
  const [showUserAccessRightsWindow, setShowUserAccessRightsWindow] = React.useState<boolean>(false);
  const [showSimulateOtherUserWindow, setShowSimulateOtherUserWindow] = React.useState<boolean>(false);
  
  const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = React.useContext(ApplicationStateContext);

  function LogOut() {
    sessionDispatch({
      type: SessionActionType.Logoff,
      payload: {
        userName: sessionState.userName
      }
    });
  } 
  
  function ChangePassword() {
    sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.ChangePassword));
  }

  // ------------------------------- HANDLERS --------------------------------------

 /**
   * 
   * @param addressInfo The DAWA data structure representing an address
   */
 function callbackOnSearchAddress(addressInfo: DawaAutoCompleteResult) {
  // Lookup the coordinates
  let req = addressInfo && addressInfo.data && addressInfo.data.href;
  req && Utils.asyncFetchJsonFromUrl(req)
    .then((body) => {
      let createNewPoint = true;
      if (applicationState.appMode === AppMode.Embedded) {
        // Check if there is a point already there. Then just zoom.
        throw new Error("Not implemented");
        // let addressLookupResponse:DawaAddressLookupResult = body;
        // let center: MitLatLng = new MitLatLng(addressLookupResponse.adgangspunkt.koordinater[1], addressLookupResponse.adgangspunkt.koordinater[0]);
        // let radius = Utils.getSystemSetting("gotoAdressUseExistingPointRadiusMeters",50);
        // let metaData:PointMetaData[] = GenerateGeom.spatialSelection(mapitState.map!, center, radius);
        // if (metaData && metaData.length > 0) {
        //   // pick the first target, if more.
        //   let {index, lat, lng} = metaData[0];
        //   let p:MitLatLngExpression = [lat,lng];
        //   let bounds: MitLatLngBoundsExpression = MitLatLngBounds(p, p);
        //   // zoom to the area
        //   mapitState.map && mapitState.map.fitBounds(bounds);
        //   createNewPoint = false;
        // }
      }

      if (createNewPoint) {
        // Create a full layer for the adress point.
        let layerInfo = LayerFunc.createLayerInfoForOneAddress(addressInfo, body);
        mapitStateDispatch(actionAddDataLayer(layerInfo, true));
      }
    }
    )
    .catch(error => {
      appMessageDispatch(actionSetErrorMessage(JSON.stringify(error)));
    })
  // hide search window
  mapitStateDispatch(actionSetShowWindow(MapitWindowId.ShowSearchField, false))
}

  async function drawMatrikelLocation(matrNr: string, ejerlavskode: number, betegnelse: string): Promise<void> {
    let res = await PropertyInfoInterface.getGeojsonOfCadasters([{matrikelNr: matrNr, ejerlavKode:ejerlavskode}])

    if (res.features[0]) {
      let [x,y] = polylabel(res.features[0].geometry.coordinates[0], 0.00001)
      let layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(y, x), betegnelse + " " + matrNr, {...res.features[0].properties});
     
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.ShowSearchField,false));
      
      if (hasAccessToFeature(Feature.ThemeNewSec)) {
        layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(y, x), betegnelse + " " + matrNr, {});
      }
      mapitStateDispatch(actionAddDataLayer(layerInfo, true, true));
      if (mapitState.showWindow[MapitWindowId.PropertyInformationDialog]?.stackingOrder) {
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, true, {latlng:new MitLatLng(y, x)}));
      }
    } else {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("No data for given cadaster")));
    }
  }

  /* ------------------------------------------------------- */
  return (
    <>
    <div className="NavigationTopBar" id="Mit-NavigationTopBar">
    <div className="TopMenu" style={{overflowX:"clip"}}>
      <div className="group">
        <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={
          <img className="HiddenXSmall" src={ViamapLogo} alt="viamap" width={"48px"} height={"48px"} style={{zIndex:-1}} />
        }>
          <img className="HiddenXSmall" src={EstateExLogo} alt="Estate Explorer" height={"48px"} onClick={() => location.reload()} style={{zIndex:1, height:"48px", cursor:"pointer" ,width:"unset", marginLeft: "16px", marginRight: "32px",}} />
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.BasicMap} contentsIfNoAccess={<></>}>
          <SearchBarGeneral
          showWindow={true}
          autocompleters={[
            new EjerlavAutocomplete(), // If selected sets context limiting other searches
            new VejAutocomplete(), // If selected sets context limiting other searches
            new MatrikelAutocomplete((data:any)=> {
              drawMatrikelLocation(data.matrikelnummer, data.ejerlavskode, data.visningstekst); // NB Async
              
            }),

            new AddressAutocomplete((data:any)=> {
              let createPoint=true;
              // convert coordinates from UTM32 to WGS84
              let wgsCoords = data.geometri.coordinates[0].toReversed();
              if (createPoint) {
                let layerInfo = LayerFunc.createLayerInfoForOneAddressV2(data.vejnavn, data.husnummer, wgsCoords.reverse());
                mapitStateDispatch(actionAddDataLayer(layerInfo, true));

                if (mapitState.showWindow[MapitWindowId.PropertyInformationDialog]?.stackingOrder) {
                  mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, true, {latlng:new MitLatLng(wgsCoords[1], wgsCoords[0])}));
                }
              } else {
                mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, true, {latlng:new MitLatLng(wgsCoords[1], wgsCoords[0])}));
              }


            }),
            new CVRNumberAutocomplete((data:any)=> {
              // Open CompanyInfo for cvr = data.cvrNummer
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.CompanyInformationDialog, true, {cvrNr:data.cvrNummer}));
            }),
            new CVRNavnAutocomplete((data:any)=> {
              // Open CompanyInfo for cvr = data.cvrNummer
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.CompanyInformationDialog, true, {cvrNr:data.cvrNummer}));
            }),
            new CVRPersonAutocomplete((data:any)=> {
              // Open PersonInfo for navn og cvr person enhedsnummer
              let gaeldendeNavn = data.navne.find((n:any) => { return n.periode.gyldigTil === null});
              let adr = data.deltagerpersonMetadata?.nyesteBeliggenhedsadresse;
              let postnr = adr?.postnummer;
              let adresse = adr?.vejnavn+ " "+adr?.husnummerFra; // Todo: bogstav og side...
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.PersonInformationDialog, true, {
                navn:gaeldendeNavn.navn, 
                postnr: postnr, 
                adresse: adresse, 
                vejnavn: adr?.vejnavn, 
                husnr: adr?.husnummerFra, 
                foedselsdato: undefined, 
                cvrPersonId:data.enhedsNummer}));
            }),
            new EjerNavnAutocomplete((data:any, searchText?:string)=> {
              // If the property is owned by more than one person. Make sure we show information for the person we searched for.
              let owners = data?.property_record?.hits?.hits?.[0]?._source?.ejf_ejere_liste
                || data?.property_record?.hits?.hits?.[0]?._source.ejf_ejere.split(",").filter((a) => a).map((a) => ({navn:a.trim()})) || []

              let selectedOwnerData = PropertyInformation.propertyOwnerMatchingName(owners, searchText ||"");
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.PersonInformationDialog, true, {
                navn:selectedOwnerData.navn, 
                postnr: selectedOwnerData.postnr, 
                foedselsdato: selectedOwnerData.foedselsdato && new Date(selectedOwnerData.foedselsdato), 
                adresse: selectedOwnerData.adresse
              }));
            }),
            new StednavnAutoComplete((data:any) => {
              (mapitState.map as MapFacadeMaplibre).zoomToData(data.bbox, 150)
            }),
            // new BFEAutocomplete((data:any)=> {
            //   // todo: Open PropertyInfo for bfe = data.bfe_nr
            //   alert("not implemented yet");
            //   mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, true, {latlng:new MitLatLng(wgsCoords[0], wgsCoords[1])}));
            // }),
            new FeatureLayerAutoComplete(Object.values(mapitState.featureLayers), (mapitState.selectedFeatureLayerKeys), (selected) => {
              const show = !mapitState.selectedFeatureLayerKeys.includes(selected.layer.label)
              mapitStateDispatch(actionSetFeatureLayerVisibility(selected.layer.label, show, selected))
            })
          ]}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
        </ProtectedFeature>
        <MenuPopdown />
        <ProtectedFeature feature={Feature.CreateMarkerNavigation} contentsIfNoAccess={<></>}>
          <div type-title={Localization.getText("Add Pin")} className="HiddenSmall" >
          <img 
            src={Pin} 
            className={mapitState.mapInteractionState === MapInteractionState.ClickToSetMarker ? "ActiveImg":""}
            onClick={(e) => {
              if (mapitState.mapInteractionState === MapInteractionState.ClickToSetMarker) {
                mapitStateDispatch(actionSetMapInteractionStateDefault())
              } else {
                mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.ClickToSetMarker))
              }
            }}
          />
          </div> {/*<FiMapPin />*/}
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.DeviceLocation} contentsIfNoAccess={<></>}>
          <div type-title={Localization.getText("Your Location")} className="HiddenSmall" >
            <img 
              src={Min_Position} 
              className={mapitState.showUserLocation ? "ActiveImg":""} 
              onClick={(e)=>{
                mapitStateDispatch(actionSetShowUserLocation(!mapitState.showUserLocation));
              }}
              />
            </div>{/*<MdLocationSearching />*/}
          </ProtectedFeature>
          <ProtectedFeature feature={Feature.Debugging} contentsIfNoAccess={<></>}>
            <PopDown direction="right" hoverText="Debugging" className="HiddenSmall" icon={<AiFillBug />} >
                <PopBtn className="PopBtn" onClick={()=>{
                  licensingDispatch(actionDisableLicense(sessionState.customerRef, sessionState.userRef, licensingState.currentlicense!, () => {
                    alert(`License ${licensingState.currentlicense!.product+":"+licensingState.currentlicense!.productVariant}removed for user ${sessionState.userName}. Logging off`);
                  }));
                  // Logout
                  sessionDispatch(actionLogoff(sessionState.userRef));
                }}>Remove License</PopBtn>
                <PopExtendSide header="Vælg Style" direction="right"  >
                  <ThemeSelector />
                </PopExtendSide>
                <PopExtendSide header="Console.log" direction="right">
                  <PopBtn onClick={() => { console.log(mapitState.layers)}} >Layers</PopBtn>
                  <PopBtn onClick={() => { console.log(mapitState.featureLayers)}} >featuresLayers</PopBtn>
                  <PopBtn onClick={() => { console.log(mapitState.layerHierarchy)}} >layerHierarchy</PopBtn>
                </PopExtendSide>
                <PopExtendSide header="App Window" direction="right">
                  <PopBtn onClick={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.CompanyInformationDialog, true, {cvrNr:25483839}))}} >Show Company (25483839)</PopBtn>
                  <PopBtn onClick={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.PersonInformationDialog, true, {name:"Test", dateOfBirth: new Date("1-1-2000")}))}} >Show Person (Test)</PopBtn>
                  <PopBtn onClick={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.MapLinkOverview, true))}} >Show Saved Maps</PopBtn>
                  <ProtectedFeature feature={Feature.SaveMapAdminCloud} contentsIfNoAccess={<></>}>
                  <PopBtn onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.AdminCloudSaveDialog, true))} >{"Save map on Admin Cloud"}</PopBtn>
                </ProtectedFeature>
                </PopExtendSide>
                <PopExtendSide header="App Messaging" direction="right">
                  <PopBtn onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 0", 0));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),1000) }} >Set Progress 0</PopBtn>
                  <PopBtn onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 33", 33));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),1000) }} >Set Progress 33</PopBtn>
                  <PopBtn onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 50", 50));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),1000) }} >Set Progress 50</PopBtn>
                  <PopBtn onClick={() => { appMessageDispatch(actionSetProgressMessage("Text: Set Progress 100", 100));setTimeout(() => appMessageDispatch(actionClearProgressMessage()),1000) }} >Set Progress 100</PopBtn>
                  <PopBtn onClick={() => { appMessageDispatch(actionSetInfoMessage("Text: SetInfoMessage")) }} >SetInfoMessage</PopBtn>
                  <PopBtn onClick={() => { appMessageDispatch(actionSetErrorMessage("Text: SetErrorMessage")) }} >SetErrorMessage</PopBtn>
                </PopExtendSide>
                {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
               <PopBtn onClick={() => { setShowLoadedSettingsWindow(true); }}>Show Loaded Settings</PopBtn>
               <PopBtn onClick={() => { setShowUserAccessRightsWindow(true); }}>Show Access Rights</PopBtn>
               <PopBtn onClick={() => { setShowSimulateOtherUserWindow(true); }}>Simulate Other User (beta)</PopBtn>
            </PopDown>
            <LoadedSettingsWindow
            show={showLoadedSettingsWindow}
            bucket={SettingsManager.getSystemSetting("viamapStoreS3Bucket")}
            onClose={() => setShowLoadedSettingsWindow(false)}
         />
            <UserAccessRightsWindow
                        show={showUserAccessRightsWindow}
                        onClose={() => setShowUserAccessRightsWindow(false)}
            />
            <SimulateOtherUserDialog
                show={showSimulateOtherUserWindow}
                onClose={() => setShowSimulateOtherUserWindow(false)}
            />
          </ProtectedFeature>
          <ProtectedFeature feature={Feature.ShowZoomLevelInHeader} contentsIfNoAccess={<></>}>
            {mapitState.map ? (
              <div className="mit-show-zoomlevel-display">Zoom: {mapitState.viewerState && mapitState.viewerState.zoom?.toFixed(1)}</div>
            ) : "not"}
          </ProtectedFeature>

      </div>
      
      {/* <div className="group">
        <div>Logo</div><div>Mapit</div>
      </div> */}
      <div className="group HiddenSmall">
        <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={
          <LicenseStateCompact/>
        } ><></></ProtectedFeature>
        {/* <TimeLeftBadge /> */}
        <PopDown direction="left" hoverText={Localization.getText("Settings")} icon={<img src={Indstilinger}/>}>
          <div className="PopDown Title">{Localization.getText("Settings")}</div>
          {/* <button className="PopBtn">CustomerSettings</button> */}
          <PopBtn 
            onClick={()=> {
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.MapSettingsEditor, true));
            }} 
            className="PopBtn"
            >{Localization.getText("Menuitem:MapSettings")}</PopBtn>
          <PopBtn 
            onClick={()=> {
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.UserSettingsEditor, true));
            }} 
            className="PopBtn"
          >{Localization.getText("Menuitem:UserSettings")}</PopBtn>
          <ProtectedFeature feature={Feature.SettingsCustomer} contentsIfNoAccess={<></>} >
          <PopBtn 
            onClick={()=> {
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.CustomerSettingsEditor, true));
            }} 
            className="PopBtn"
            >{Localization.getText("Menuitem:CustomerSettings")}</PopBtn>
          </ProtectedFeature>
        </PopDown>
        <NotificationPopDown />
        <UserPopDown /> 
        <LanguageSelector />
      </div>
      
      <div className="group DisplaySmall">
        <PopDown direction="left" icon={<FiChevronDown />} >
          <div className="PopDown Title">{Localization.getText("Menu")}</div>
          <PopBtn onClick={(e)=>{mapitStateDispatch(actionSetShowWindow( MapitWindowId.InformationWindow, true));}}>{Localization.getText("Info Page")}</PopBtn>
          <LicenseStateCompact/>
          <PopExtendSide direction="left" header={Localization.getText("Settings")} >
          <PopBtn 
                onClick={()=> {
                  mapitStateDispatch(actionSetShowWindow(MapitWindowId.MapSettingsEditor, true));
                }} 
                className="PopBtn"
                >{Localization.getText("Menuitem:MapSettings")}</PopBtn>
              <PopBtn 
                onClick={()=> {
                  mapitStateDispatch(actionSetShowWindow(MapitWindowId.UserSettingsEditor, true));
                }} 
                className="PopBtn"
              >{Localization.getText("Menuitem:UserSettings")}</PopBtn>
          </PopExtendSide>
          <ProtectedFeature feature={Feature.SwapLicense} contentsIfNoAccess={<></>} >
            <PopExtendSide direction="left" header="Vælg License" >
            {Object.keys(licenseVariantToRoles).map((a) => {
              return (<React.Fragment key={a}>
                <PopBtn onClick={() => licensingDispatch(actionSwapLicenseVariant(a))} >{a}</PopBtn>
              </React.Fragment>)
            })}
            </PopExtendSide>
          </ProtectedFeature>
          <PopBtn className="PopBtn" onClick={() => ChangePassword()} >{Localization.getText("Change Password")}</PopBtn>
          <PopBtn className="PopBtn" onClick={() => LogOut()}>{Localization.getText("Log Off")}</PopBtn>
        </PopDown>
      </div>
      </div>
    </div>
      {props.children}
    </>
  )
}


function TimeLeftBadge() {
  let ref = React.useRef<HTMLDivElement | null>(null)
  const {state : licensingState} = useContext(LicensingContext);
  React.useEffect(() => {
    let currentLicense = licensingState.currentlicense;  
    let interval
    if (currentLicense && currentLicense.expires) {
      let y = ref.current
      let UpdateTimer = () => {
        if (y) {
          y.innerHTML = Math.round((currentLicense!.expires.getTime() - new Date().getTime()) / 3600000) + " Hours Left"
          ref.current!.style.backgroundColor = "blue"
        }
      }
      interval = setInterval(UpdateTimer ,10000)
      UpdateTimer()
    } else if (currentLicense) {
      ref.current!.innerHTML = currentLicense!.productVariant
      ref.current!.style.backgroundColor = "green"
    } else {
      ref.current!.innerHTML = "Error"
      ref.current!.style.backgroundColor = "red"
    }
    return () => clearInterval(interval)
  },[licensingState.currentlicense])

  if (!licensingState.currentlicense) {
    return null
  }
  return (
    <PopDown direction="left" hoverText="License" icon={<div ref={ref} className="TimeLeftBadge" >..Loading</div>} >
      <div className="PopDown Title">{Localization.getText("License")}</div>
      {licensingState.currentlicense ? KeyValuePair([licensingState.currentlicense]) : "Error Loading License"}
    </PopDown>
  )
}

function KeyValuePair(a: ViamapLicense[]) {
  return (
    <>
    {a.map((lic, id) => {return (
        <div key={lic.productVariant} className="LicCon">
          <div className="product">{lic.product}</div>
          <div className="enddate">{lic.expires.toLocaleDateString() || Localization.getText("Never")}<FiClock /></div>
          <div className="variant">{lic.productVariant}</div>
          <div className="type">{lic.licenseType}</div>
        </div>
    )})}
    </>
  )
}

export function UserPopDown() {
  let {state:sessionState, dispatch:sessionDispatch} = React.useContext(SessionContext)
  const {state : licensingState, dispatch:licensingDispatch} = useContext(LicensingContext);

  function LogOut() {
    sessionDispatch({
      type: SessionActionType.Logoff,
      payload: {
        userName: sessionState.userName
      }
    });
  } 
  
  function ChangePassword() {
    sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.ChangePassword));
  }

  return (
    <PopDown direction="left" icon={<img src={Min_Side} />} hoverText={sessionState.userName || Localization.getText("User")}> {/*<FiUser />*/}
      <div className="PopDown Title">{Localization.getText("User")}</div>

      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
        {/* <div className=""> */}
          <LicenseStateCompact/>
        {/* </div> */}
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SwapLicense} contentsIfNoAccess={<></>} >
        <PopExtendSide direction="left" header="Vælg License" >
        {Object.keys(licenseVariantToRoles).map((a) => {
          return (<React.Fragment key={a}>
            <PopBtn onClick={() => licensingDispatch(actionSwapLicenseVariant(a))} >{a}</PopBtn>
          </React.Fragment>)
        })}
        </PopExtendSide>
      </ProtectedFeature>
      <PopBtn className="PopBtn" onClick={() => ChangePassword()} >{Localization.getText("Change Password")}</PopBtn>
      <PopBtn className="PopBtn" onClick={() => LogOut()}>{Localization.getText("Log Off")}</PopBtn>
    </PopDown>
  );

}

function NotificationPopDown() {
  return (
    <PopDown direction="left" icon={<img src={Notifikationer} />} hoverText={Localization.getText("Notifications")} > {/*<FiBell />*/}
      <div className="PopDown Title"> {Localization.getText("Notifications")} </div>
      <div>{Localization.getText("No New")}</div>
    </PopDown>
  )
}

function MenuPopdown() {
  const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
  const { state: helpState, dispatch: helpStateDispatch} = React.useContext(UserHelpContext);

  // ------------------------------------HANDLERS-------------------------------------------------------------------
  function gotoUserGuide() {
    const contactInformation = SettingsManager.getSystemSetting("contactInformation");
    const linkToManual = contactInformation && contactInformation.linkToManual ? contactInformation.linkToManual : "";
    window.open(linkToManual, '_blank')!.focus();
  }
  function buttonClickedGettingStarted(event:any) {
    // setState({showReleases:true, gettingStarted:true});
    helpStateDispatch(showUserGuide());
  }

  function buttonClickedShowReleases(event:any) {
    // setState({showReleases:true, gettingStarted:false});
    helpStateDispatch(showReleases());
  }

  function buttonClickedGenerateReport(event:any) {
    mapitStateDispatch(actionSetShowWindow( MapitWindowId.DemographyReportDialog, true));
    // mapitStateDispatch(actionSetShowWindow( MapitWindowId.SelectCircle, true));
  }

  function buttonClickedSpatialSelection(event:any) {
    mapitStateDispatch(actionSetShowWindow( MapitWindowId.SpatialSelection, true));
  }

  // -------------------------------------------------------------------------------------------------------
  //FIXME: 
  return (
    <PopDown direction="right" icon={<img src={Burgermenu} />} hoverText="Menu" className="HiddenSmall" >  {/*<AiOutlineMenu />*/}
      <div className="PopDown Title">{Localization.getText("Menu")}</div>
      <PopBtn onClick={(e)=>{mapitStateDispatch(actionSetShowWindow( MapitWindowId.InformationWindow, true));}}>{Localization.getText("Info Page")}</PopBtn>
      <ProtectedFeature feature={Feature.GettingStarted} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e)=>gotoUserGuide()}>{Localization.getText("User guide")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.GettingStarted} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e)=>buttonClickedShowReleases(e)}>{Localization.getText("Getting Started")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ShowReleases} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e)=>buttonClickedGettingStarted(e)}>{Localization.getText("Releases")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveMap} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveMapDialog, true))} >{Localization.getText("Save map")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveMapCloud} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.CloudSaveDialog, true))} >{Localization.getText("Save map on Cloud")}</PopBtn>
        <PopBtn onClick={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.ViewCloudSaveDialog, true))}} >Show Saved Maps</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveLink} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e) => {
          Cookies.getCookieBoolean(MitCookies.RememberMapLinkGDPRAccept) ? mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, true)) : mapitStateDispatch(actionSetShowWindow(MapitWindowId.GDPRNoticeApproval, true, {
            cookie: MitCookies.RememberMapLinkGDPRAccept,
            acceptCallback: () => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, true))
          }));
          }} >{Localization.getText("Generate map link")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.GeodataReport} contentsIfNoAccess={<></>}>
        <PopBtn className="NewSecNone" onClick={(e) => buttonClickedGenerateReport(e)}>{Localization.getText("Demography Report")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SpatialExport} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e) => buttonClickedSpatialSelection(e)}>{Localization.getText("Spatial Export")}</PopBtn>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.TravelTimeMultiple} contentsIfNoAccess={<></>}>
        <PopBtn onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, true, {dialogMode:"Polygons"}))} >{Localization.getText("Generate combined catchment area")}</PopBtn>
      </ProtectedFeature>
    </PopDown>
  )
}

function newSearchBar() {
  return (<div></div>)
}