export const ParserGetFeatureInfo = {
  plainTextToFeatures: plainTextToFeatures
} as const;
export default ParserGetFeatureInfo;

function plainTextToFeatures(text:string) {
  const splitter = "--------------------------------------------\n--------------------------------------------";
  const featuresPlain = text.split(splitter);
  return featuresPlain.map((feature) => {
    let lines = feature.split(/\r?\n/);
    let attrs2 = {};
    lines.forEach(line => {
      if (line.includes("the_geom")) {
        return
      }
      if (line.includes("=")) {
        let parts = line.split("=");
        let key = parts[0].trim();
        // Strings are quoted with '. For presentation remove ' from strings
        let val = parts[1] && parts[1].trim().split("'").join("");
        attrs2[key]=val;
      }
    });
    return {"properties": attrs2};
  });
}