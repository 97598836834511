import { SettingsManager } from "@viamap/viamap2-common"

export class ViamapKortUdsnitApi {


  static constructParams(ejerlav:string, matrikelNr:string) {
    return `&ejerlav=${ejerlav}&matrikel=${matrikelNr}`;
  }
  
  static async bbrMap(ejerlav:string, matrikelNr:string) {
    const bbrMapToken = SettingsManager.getSystemSetting("bbrMapToken")
    const bbrMapUrl = SettingsManager.getSystemSetting("bbrMapUrl")

    return fetch(bbrMapUrl + this.constructParams(ejerlav, matrikelNr),{headers:{"x-api-key": bbrMapToken ,Accept: 'application/json'}}).then((a) => {
      if (a.ok) {
        return a.json()
      }
    })
  }

  static mtrkInfo(ejerlav:string, matrikelNr:string) {
    const mtrkInfoToken = SettingsManager.getSystemSetting("mtrkInfoToken")
    const mtrkInfoUrl = SettingsManager.getSystemSetting("mtrkInfoUrl")

    return fetch(mtrkInfoUrl + this.constructParams(ejerlav, matrikelNr),{headers:{"x-api-key": mtrkInfoToken ,Accept: 'application/json'}}).then((a) => {
      if (a.ok) {
        return a.json()
      }
    })

  }

}