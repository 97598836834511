import * as React from "react";

type Props = {
  customerIconLink: string;
  customerIconURL: string;
};
export function CustomerLogo(props: Props) {
  if (props.customerIconURL) {
    return (
      <a
        href={props.customerIconLink}
        title={props.customerIconLink}
        target="_blank"
      >
        <div>
          <img src={props.customerIconURL} className="mit-customerLogo" />
        </div>
      </a>
    );
  }
  return null;
}
