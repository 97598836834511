import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import './index.css';
// import of Mapit CSS
import './css/mit-controls.css';
import './css/clusterpies.css';
import './css/mit-flat.css';
import './css/autocomplete2.css';
import App from './App';
import './css/newStyle.css'
import './css/newsec.css'

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(<App />);
// root.render(<div>TEST</div>)


