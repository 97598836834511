import { SettingsManager } from "@viamap/viamap2-common";
import { useEffect, useState, useContext } from "react";
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";

import EstateFavicon from "/images/EEfavicon.png"
import { MitStorage } from "src/managers/MitStorage";
import { MitThemeList } from "./ThemeHelpers";

export type MitThemes = ('Default'|"BigPinTheme"|'NewSecTheme')

export function ThemeLoader() {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)

  useEffect(() => {
    const hostname = window.location.hostname;
    let theme = SettingsManager.getSystemSetting('ApplicationTheme', 'De', true);
    let favicon = "";
    if (hostname.includes("estate") || hostname.includes("explore") || hasAccessToFeature(Feature.ThemeNewSec)) {
      theme = "NewSecTheme";
      favicon = "/Estate_Explorer_favikon.png";
    }
    const save = MitStorage.getValue<string>("MitStyle") || '';

    var link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }


    if (save === '') {
      //Theme from SystemSettings
      document.body.className = theme;
      link.href = new URL(favicon || '/favicon.png', import.meta.url).href
    } else {
      //Theme from localstorage
      let lsTheme = save.split("---")
      if (lsTheme && lsTheme[0] === theme && lsTheme.length > 1) {
        document.body.className = lsTheme[1];
        link.href = new URL(favicon || '/favicon.png', import.meta.url).href
      } else {
        document.body.className = theme;
        link.href = new URL(favicon || '/favicon.png', import.meta.url).href
      }
    }
  },[hasAccessToFeature])

  return <></>
}

export function ThemeSelector() {
  let [style, setStyle] = useState(document.body.className);

  function setStyleHandler(style: MitThemes) {
    document.body.className = style;
    let theme = SettingsManager.getSystemSetting('ApplicationTheme', 'De', true);
    MitStorage.setValue("MitStyle",theme+"---"+style);
    setStyle(style);
  }

  return (
    <>
      {MitThemeList.map((a) => (<button key={a} className="styleSelector PopBtn PopBtnPreIcon" onClick={(e) => setStyleHandler(a)}>{Boolean(style === a) ? <BsCheckCircle  />:<BsCircle />} {a ? a : 'Default'}</button>))}
    </>
  )
}