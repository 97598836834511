import { Card } from "react-bootstrap"

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import './OwnershipDiagram.css';
import { PropertyInfoInterface } from "./PropertyInfoInterface";
import { SettingsManager } from "@viamap/viamap2-common";
import { GraphNode } from "./OwnershipStructureTopDown";

/**
 * Diagram initialization method, which is passed to the ReactDiagram component.
 * This method is responsible for making the diagram and initializing the model and any templates.
 * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
 */
function initDiagram() {
   go.Diagram.licenseKey = SettingsManager.getSystemSetting("goJSLicenseKey_viamap_net");

   const $ = go.GraphObject.make;
   // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
   const diagram =
      $(go.Diagram,
         {
            'undoManager.isEnabled': true,  // must be set to allow for model change listening
            // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
            'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
            'contentAlignment': go.Spot.Center,
            model: new go.GraphLinksModel(
               {
                  linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
               })
         });

   diagram.layout = $(go.TreeLayout, { angle: 180, layerSpacing:120 });

   // define a simple Node template
   diagram.nodeTemplate =
      $(go.Node, 'Auto',  // the Shape will go around the TextBlock
         // new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
         $(go.Shape, 'RoundedRectangle',
            { name: 'SHAPE', fill: 'white', strokeWidth: 0 },
            // Shape.fill is bound to Node.data.color
            new go.Binding('fill', 'color')),
         $(go.TextBlock,
            { margin: 8, editable: true, stroke:"#F8F8F6" },  // some room around the text
            new go.Binding('text').makeTwoWay()
         )
      );

   diagram.linkTemplate =
      $(go.Link,
         $(go.Shape),                           // this is the link shape (the line)
         // $(go.Shape, { toArrow: "Standard" }),  // this is an arrowhead
         $(go.Panel, "Auto",  // this whole Panel is a link label
         $(go.Shape, "RoundedRectangle", { fill: "ghostwhite", stroke: "454659" }),
         $(go.TextBlock, { margin: 3, stroke:"#454659" },
           new go.Binding("text", "text"))
       )
         // $(go.TextBlock,                        // this is a Link label
         //    new go.Binding("text", "text"))
      );

   return diagram;
}
/**
 * This function handles any changes to the GoJS model.
 * It is here that you would make any updates to your React state, which is dicussed below.
 */
function handleModelChange(changes: any) {
   // alert('GoJS model changed!');
}

export type NodeType = "Ejendom" | "Person" | "Firma" | "Aktieselskab" | "Link" | "AndenDeltager"

export type OwnerShipStructureData = {
   title: string,
   type: NodeType,
   ejere: OwnerShipInstance[]   
}   

export type OwnerShipInstance = {
   pct: string,
   ejer: OwnerShipStructureData
}

type Props = {
   ownership?:OwnerShipStructureData
}

export function generateNodesAndLinks(ownerShip: OwnerShipStructureData, nextNodeId: number, nextLinkId: number, nodeDataArray: {}[], linkDataArray: {}[], parentNode: number | undefined, level: number): { nodeId: number, nextNodeId: number, nextLinkId: number, nodeDataArray: any; linkDataArray: any; } {
   let topNodeId = nextNodeId++;
   nodeDataArray.push(createNode(topNodeId, ownerShip.title, ownerShip.type));
   ownerShip.ejere.forEach((ej) => {
      let { nodeId: ejNodeId, nextNodeId: nextNodeIdMod, nextLinkId: nextLinkIdMod, nodeDataArray: nodeDataArrayMod, linkDataArray: linkDataArrayMod } = generateNodesAndLinks(ej.ejer, nextNodeId, nextLinkId, nodeDataArray, linkDataArray, topNodeId, level + 1)
      linkDataArrayMod.push(createLink(nextLinkIdMod--, topNodeId, ejNodeId, ej.pct));
      nextNodeId = nextNodeIdMod;
      nextLinkId = nextLinkIdMod;
      nodeDataArray = nodeDataArrayMod;
      linkDataArray = linkDataArrayMod;
   });
   return { nodeId: topNodeId, nextNodeId, nextLinkId, nodeDataArray, linkDataArray };
}

function createNode(nodeId: number, title: string, type: string): {} {
   let color = 'lightblue';
   switch (type as NodeType) {
      case "Ejendom": color = '#BD9060'; break;
      case "Person": color = '#454659'; break;
      case "Firma": color = '#6785B8'; break;
      case "Aktieselskab": color = '#9AA9BA'; break;
   }
   return { key: nodeId, text: title, color: color, loc: '0 0' };
}

function createLink(linkId: number, from: number, to: number, pct: string): {} {
   return { key: linkId, from: from, to: to, text: pct+"%" };
}

type Reverse = {
   data: {
      reverse: true,
   }
}

function nodeTypeFromEnhedsType(a:string): NodeType {
   return ({
      "VIRKSOMHED": "Firma",
      "ANDEN_DELTAGER": "AndenDeltager"
   } as const)[a] || "Person" 

}

export async function buildOwnershipStructure(nodeType:NodeType, navn:string, id:number):Promise<GraphNode & Reverse> {
   switch(nodeType) {

   case "Ejendom":
      throw new Error("Not implemented yet");
      return {
         data: {
            title: navn,
            type: "Ejendom",
            countResult: {virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund: 0, ejerlejlighed: 0},
            lat : 0,
            lng : 0,
            entityId: id,
            expandedAsDefault: false,
            isCollapsed: true,
            pct: 0,
            ejendomsType: "",
            reverse: true,
         },
         children: []
      }

   case "AndenDeltager":
      return {
         data: {title: navn,
            type: "AndenDeltager",
            countResult: {virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund: 0, ejerlejlighed: 0},
            lat : 0,
            lng : 0,
            entityId: id,
            expandedAsDefault: false,
            isCollapsed: true,
            pct: 0,
            reverse: true,
            ejendomsType: "",},
         children: []
      }
   case "Person":
      return {
         data: {title: navn,
            type: "Person",
            countResult: {virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund: 0, ejerlejlighed: 0},
            lat : 0,
            lng : 0,
            entityId: id,
            expandedAsDefault: false,
            isCollapsed: true,
            pct: 0,
            reverse: true,
            ejendomsType: "",},
         children: []
      }
   
   case "Aktieselskab":
   case "Firma":
      let result3 = await PropertyInfoInterface.getCVRData(id as number);
      let keyData = PropertyInfoInterface.extractRelevantData(result3);
      let ej2: GraphNode[] = [];

      for (let i:number=0; i< keyData.ejere.length; i++) {
         let ej = keyData.ejere[i];
         if (ej.forretningsnoegle == id) {
            continue;
         }
         let res = await buildOwnershipStructure(
            nodeTypeFromEnhedsType(ej.enhedstype),
            ej.navn,
            ej.forretningsnoegle || ej.cvrPersonId
         )
         ej2.push({...res, data:{...res.data, pct: ej.andel}})
      }
      return {
         data: {
         title: keyData.navn,
         type: (keyData.navn as string).includes("ApS") ? "Firma" : "Aktieselskab",
         countResult: {virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund: 0, ejerlejlighed: 0},
         lat : 0,
         lng : 0,
         entityId: id,
         expandedAsDefault: false,
         isCollapsed: true,
         reverse: true,
         pct: 0,
         ejendomsType: ""},
         children: ej2
      }

   default:
      throw new Error("Unexpected state");
   }
}