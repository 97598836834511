export function createDonutChart(props) {
  let offsets: number[] = [];
  const counts = Object.keys(props).reduce<number[]>((result, key) => {
    if (key.startsWith("itemCount")) {
      result.push(props[key]);
    }
    return result;
  }, []);
  const colors: string[] = Object.keys(props).reduce<string[]>(
    (result, key) => {
      if (key.startsWith("itemColor")) {
        result.push(props[key]);
      }
      return result;
    },
    []
  );
  const labels: string[] = Object.keys(props).reduce<string[]>(
    (result, key) => {
      if (key.startsWith("itemLabel")) {
        result.push(props[key]);
      }
      return result;
    },
    []
  );
  let total = 0;
  for (let i = 0; i < counts.length; i++) {
    offsets.push(total);
    total += counts[i];
  }
  const fontSize =
    total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
  const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

  let i;
  for (i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      colors[i],
      labels[i] + (labels[i].length > 0 ? " " : "") + "(" + counts[i] + ")"
    );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${r}, ${r})">${total.toLocaleString()}</text></svg></div>`;

  const el = document.createElement("div");
  el.innerHTML = html;
  return el.firstChild;
}

function htmlEncode(input: string) {
  return input
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function donutSegment(start, end, r, r0, color, title) {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    "L",
    r + r * x0,
    r + r * y0,
    "A",
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    "L",
    r + r0 * x1,
    r + r0 * y1,
    "A",
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    `" fill="${color}">`,
    `<title>${htmlEncode(title)}</title>`,
    `</path>`,
  ].join(" ");
}
