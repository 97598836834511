import { MouseEvent, useEffect, useMemo, useState } from "react";
import { PoiManager } from "../managers/PoiManager";

export type TEventType =
  | "MouseMove"
  | "MouseLeave"
  | "MouseClick"

export type SortKeys =
  | "Type"
  | "Dist"

export type TMouseEventCallbackParams = {
  event: TEventType,
  pos: {x:number, y:number},
  type: string
}

type TDistancesParams = {
  callBack: (a:TMouseEventCallbackParams) => {}
}

export function Distances(props: TDistancesParams) {
  let [sort, setSort] = useState("Dist")
  let [subscription, setSubscription] = useState(0);

  useEffect(() => {
    PoiManager.instance().subscribe("POS",setSubscription);
    PoiManager.instance().subscribe("POI",setSubscription);
  }, []);

  let PoiCards = useMemo(() => {
    function handleMouseMove(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: "MouseMove"})
    }
  
    function handleMouseLeave(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: "MouseLeave"})
    }
  
    function handleMouseClick(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: "MouseClick"})
    }

    let sortFunc = (a:any,b:any) => a.routedmeters-b.routedmeters
    switch (sort) {
      case "Dist":
        break;
      case "Type":
        sortFunc = (a:any,b:any) => a.dk > b.dk ? 1 : -1;
    }

    let pTypes = PoiManager.instance().getTypes()
    let currentPos = PoiManager.instance().getCurrentPos()
    let pObjec = pTypes.map((e) => PoiManager.instance().getPoi(e)).filter((e) => e.routedmeters).sort(sortFunc);
    return pObjec.map((elm) => { 
      if (elm === undefined) {
        return null
      }
      function distanceFormat(x:number):string {
        if (x > 1000) {
          return Math.round(x / 100)/10 + " km"
        }
        return Math.round(x) + " meter"
      } 
      if (elm.routedmeters === "" || elm.routedmeters === undefined || currentPos === undefined) {
        return (null)
      }
      let mouseOnMoveHandler = (ev:any) => handleMouseMove(ev,elm.type as string);
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        mouseOnMoveHandler = (ev:any) => {return};
      }
        // console.log(elm.routedmeters)
      return (
        <li key={elm.type as string} onMouseMove={mouseOnMoveHandler} onClick={(ev) => handleMouseClick(ev,elm.type as string)} onMouseLeave={(ev) => handleMouseLeave(ev,elm.type as string)} className="distance clickable">
          <div style={{position:"relative",bottom:"0px",height:"100%", width:"100%"}}>
            <h2 className="type">{(elm.dk as string).replace(" og",' & ')}</h2>
            <p className="name">{elm.name || ""}</p>
            <div className="icon2btm">
              <img src={"/"+elm.icon+".png" as string || ""} alt="" />
              <p className="result">{distanceFormat(elm.routedmeters as number)}</p></div>
            </div>
        </li>
      );
    })
    

  }, [subscription, sort])

  function handleSortButton (a: SortKeys) {
    setSort(a);
  }

  return (
    <>
    <div style={{margin:"auto", width:"80%"}}>
      {/* {PoiCards.length > 0 ?
    <div className="SortButtons" style={{top: window.scrollY > 10 ? "80px" : ""}}>
    <div className={"bi-sort-alpha-down "+(sort === "Type" ? "ActiveSort":"")} onClick={() => handleSortButton("Dist")}>Kategori</div>
    <div className={"bi bi-sort-numeric-down "+(sort === "Dist" ? "ActiveSort":"")} onClick={() => handleSortButton("Type")}>Afstand</div>
    </div>:null} */}
    <ul className="distances">
      {PoiCards}
    </ul>
    </div>
    </>
  );
}