import React, { useContext, useEffect, useReducer, useRef, useState } from "react"
import { Modal, Card, Row, Col, Button, Alert } from "react-bootstrap"
import { FiChevronRight } from "react-icons/fi"
import { AiTwotoneStar } from "react-icons/ai"
import uuid from "uuid";
import * as L from 'leaflet';
import { DemographyReportMetaData } from "./DemographyReport";
import { PropertyInformationDialog } from "./PropertyInformationDialog";
import { FieldGroup1, FieldGroup2, StarRating, TagChanger, TagChangerTypeAhead, TextInput } from "src/common/components/InputFields";
import { usePanWindow } from "src/common/components/CustomHooks";
import { BsChevronRight, BsStarFill, BsTrashFill, BsX } from "react-icons/bs";
import { relative } from "path";
import { actionClearProgressMessage, actionSetErrorMessage, actionSetInfoMessage, actionSetProgressMessage, AppMessagesContext, Localization, PersistenceObjectType, PersistenceScope, SessionContext, SettingsManager, SimpleTranslationTable, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { MitLatLng } from "src/managers/MapFacade";
import { LayerFunc } from "src/managers/LayerFunc";
import { actionAddDataLayer, actionSetShowWindow, MapitStateContext, MapitWindowId } from "src/states/MapitState";
import { ProjectContext, TEjendomsMetaData, removePersistentTag, addPersistentTag, addTag, addValue, handleRemoveEjendom, removeTag, removeValue, setEjendomme, setState, useSetupProjectState, setInfo, ProjectState, handleToBeAdded, handleAddEjendom } from "src/states/ProjectState";
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { IoClose } from "react-icons/io5";
import { HiLockClosed, HiLockOpen} from "react-icons/hi2";
import { MitButton } from "./ComponentUtils";
import { RightPaneContext, RightPaneModus, actionGoToModus, actionToggleModus } from "src/states/RightPaneState";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { MdClose } from "react-icons/md";
import { SheetFunc } from "src/managers/SheetFunc";
import { ESInterface } from "src/compentsModus/ESInterface";
import { ExploreSearchContext, FilterType } from "src/states/ExploreSearchState";
import { AddressInterface } from "src/managers/AddressInterface";
import { ExportData } from "src/managers/ExportData";
import { MitStorage } from "src/managers/MitStorage";
import ReactDOM from "react-dom";
import { ViewButtonIcon } from "src/propertyInfoTemplates/PropertyComponents";
import { PropertyInfoView } from "src/propertyInfoTemplates/PropertyInfoView";

async function ProjStateMigration(state:ProjectState) {
  // Migration from 18/12-2023
  let newData = (state.data?.filter((a) => a.latLng).map(async (a) => {
    if ("id" in a) {
      if (typeof a.id == "string" && a.id.length === 7) {
        a.bfeNr = parseInt(a.id);
      }
    }
    if (!a.bfeNr) {
      let resp = await AddressInterface.reverseGeocodeFull(a.latLng[0], a.latLng[1]);
      let addrData = await resp.json();
      let jordstykke = await (await fetch(addrData.jordstykke.href)).json();
      a.bfeNr = jordstykke.bfenummer;
    }
    return { ...a };
  }))
  return {...state, data: await Promise.all(newData)}
}

export function ProjectManager({children}) {
  const {state:SessionState} = useContext(SessionContext) 
  const ProjectState = useSetupProjectState(SessionState)
  const {dispatch: mapitStateDispatch} = useContext(MapitStateContext)
  const {dispatch: rightPaneDispatch} = useContext(RightPaneContext)
  const {dispatch: appMessageDispatch} = useContext(AppMessagesContext)
  const [lastUser, setLastUser] = useState<string>("")

  useEffect(() => {
    if (SessionState.userRef+SessionState.customerRef !== lastUser) {
      setLastUser(SessionState.userRef+SessionState.customerRef)
      ProjectState.dispatch(setState({isLoading:true}))
      
      let persistLayer = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket", ""))
      
      persistLayer.getObject(PersistenceScope.User, PersistenceObjectType.Settings, SessionState.customerRef, SessionState.userRef, "Projects.json").then((resutlJson) => {
        return JSON.parse(resutlJson.Body.toString())
      }).then((state) => {
        ProjStateMigration(state).then((migratedState) => {
          ProjectState.dispatch(setState(
            {...migratedState, user:{userRef:SessionState.userRef, customerRef:SessionState.customerRef}, isLoading:false, isEmpty:false, isLoaded:true}
          ))
        })
      }).catch((a) => {
        if (typeof a === "string" && a.includes("NoSuchKey")) {
          ProjectState.dispatch(setState({isEmpty:true, isLoaded:true, isLoading:false, user:{userRef:SessionState.userRef, customerRef:SessionState.customerRef}}))
          return
        } else if ("code" in a && a["code"] == "NoSuchKey") {
          ProjectState.dispatch(setState({isEmpty:true, isLoaded:true, isLoading:false, user:{userRef:SessionState.userRef, customerRef:SessionState.customerRef}}))
          return
        }
        ProjectState.dispatch(setState({isEmpty:true, isLoaded:true, isLoading:false, user:{userRef:SessionState.userRef, customerRef:SessionState.customerRef}}))
        let msg = a?.msg ?? a
        appMessageDispatch(actionSetErrorMessage(
          "Loading Project" + msg
        ))
      })

    }

  },[SessionState])


  return (
    <>
    {ProjectState.state.info ? 
      <Modal show={true} id="mit-info" >
          <Modal.Header><></></Modal.Header>
          <Modal.Body>
            <div className="closeBtn" onClick={(e) => {ProjectState.dispatch(setInfo())}}><BsX/></div>
            <Alert variant="success">{ProjectState.state.info}</Alert>
              <div className="flex-mid" >
            <MitButton style={{marginInline:"auto"}} variant={"nsNormal"} onClick={(e) => {
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, false))
              ProjectState.dispatch(setInfo())
              rightPaneDispatch(actionGoToModus(RightPaneModus.Projects))
            }}>{Localization.getText("Go to Projects")}</MitButton>
            </div>
          </Modal.Body>
      </Modal>
    : null}
    <ProjectContext.Provider
      value={ProjectState}
      >
        
    {ProjectState.state.toBeAdded?.length ? 
      ReactDOM.createPortal(<AddWindow data={ProjectState.state.data} toAdd={ProjectState.state.toBeAdded} callBackOnSubmit={
        (a) => {a.forEach((ej) => {
          ProjectState.dispatch(handleAddEjendom(ej.bfeNr, ej.name, ej.latLng, ej.tags))

          });
          if (a.length > 1) {
            ProjectState.dispatch(
              setInfo(Localization.getText("Property has been added"))
              );
          } else {
            ProjectState.dispatch(
              setInfo(Localization.getFormattedText("{nr} properties have been added",{nr: a.length}))
              );
          }
          ProjectState.dispatch(handleToBeAdded([]))}
    } callBackOnCancel={() => ProjectState.dispatch(handleToBeAdded([]))} /> ,document.body)
    : <></>}
      {children}
    </ ProjectContext.Provider>
    </>
  )
}


export function Projects(props:{show:boolean}) {
  const {state: projState, dispatch: projDispatch} = React.useContext(ProjectContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
  const {dispatch:rightPaneDispatch} = useContext(RightPaneContext)
  const {dispatch:appMessageDispatch} = React.useContext(AppMessagesContext);
  const {state:explorerSearchState} = useContext(ExploreSearchContext);

  let [viewTag, setViewTag] = React.useState<string[]>([]);
  let [viewEjn, setViewEjn] = React.useState<number[]>([]);

  let uploadRef = React.useRef<HTMLInputElement>(null);
  let linkRef = React.useRef<HTMLAnchorElement>(null);

  let [dataEjn, setDataEjn] = React.useState<{bfeNr?: number,dot?:{latlng: MitLatLng}} | null>(null);

  let [showProps, setShowProps] = React.useState<boolean>(false);
  let [showEjn, setShowEjn] = React.useState<boolean>(true);
  const [Eventer, ref, clickEventer] = usePanWindow(40);

  

  function handleSelTag(e:MouseEvent|undefined, key: string | null) {
    if (key == null) {
      setViewTag([])
      return
    }

    if (viewEjn && key) {
      let x:number[] = []
      viewEjn.forEach((view) => {
        let safeCurrent = projState.data.find((a) => a.bfeNr === view)
        if (safeCurrent && safeCurrent.tags.includes(key)) {
          x = [...x, view]
        }
      })
      setViewEjn(x)
    }
    if (e?.ctrlKey || e?.shiftKey) {
      if (viewTag.includes(key)) {
        setViewTag((a) => a.filter((a) => a !== key));
      } else {
        setViewTag((a) => [...a, key])
      }
    } else {
      setViewTag([key]);
    }
  }

  function saveToXLSX(e:any) {

  }

  function saveToFile(e:any) {
    console.log("clicked")
    let {data, persistentTags, version} = projState

    var file = new Blob([JSON.stringify({version, persistentTags, data})], {type: "json"});
    if (linkRef.current) {
      linkRef.current.href = URL.createObjectURL(file);
      linkRef.current.setAttribute('target', '_blank');
      linkRef.current.download = "Project.json";
      linkRef.current.click()
    }
  }

  
  function V0ToV1Migration(a:any, state:ProjectState): ProjectState {
    if (Array.isArray(a)) {
      return {
        version: 1,
        isLoaded: true,
        isLoading: false,
        persistentTags: [],
        toBeAdded: [],
        isEmpty: false,
        ViamapPersistenceLayer: state.ViamapPersistenceLayer,
        user: state.user,
        info: "Old version migrated",
        data: a.map((a) => {
          if (a.coords) {
            let coords = a.coords
            delete a.coords
            return {...a, latLng: coords?.reverse?.()}
          }
          return a
        }).filter(a => a.latLng)
      }
    } else {
      return a
    }
  }

  function importFile(e:React.DragEvent<HTMLElement>|React.ChangeEvent<HTMLInputElement>) {
    let x = (e as React.ChangeEvent<HTMLInputElement>).target?.files?.[0] || (e as React.DragEvent<HTMLElement>).dataTransfer.files?.[0]
    if (x) {
      x.text().then((a) => {
        projDispatch(setState(V0ToV1Migration(JSON.parse(a), projState)))
      })
    }
    if (uploadRef.current) {
      uploadRef.current.value=""
      uploadRef.current.type=""
      uploadRef.current.type="file"
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function handleSelEjendom(e: MouseEvent, bfeNr:number) {
    if (e?.ctrlKey || e?.shiftKey) {
      if (viewEjn.includes(bfeNr)) {
        setViewEjn((a) => a.filter((a) => a !== bfeNr));
      } else {
        setViewEjn((a) => [...a, bfeNr])
      }
    } else {
      setViewEjn([bfeNr]);
    }
  }

  function handleCreateLayerForTag(viewTag:string[], ejnInTag: TEjendomsMetaData[]) {
    if (!viewTag) {
      return
    }
    let points = ejnInTag.map((val) => {
      return {
        name:val.name, 
        latlng: new MitLatLng(val.latLng[0], val.latLng[1]), 
        properties:{
          // "tags": val.tags.join(', '),
          "description": val.cf_siteDescA, 
          "zoning": val.cf_siteDescB,
          "starsOverall": val.cf_starAcc,
          "starsVisibility": val.cf_starVis
        }
      }
    })
    let layerInfo = LayerFunc.createLayerInfoForManyPoints(viewTag.toString(), points);
    mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
    appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("New map layer created from project {project}", {project:viewTag})));
  }

  async function handleExportExcelForTag(viewTag:string[], ejnInTag: TEjendomsMetaData[]) {
    if (!viewTag) {
      return
    }

    try {
            
      let createdDate = new Date();
      let count = ejnInTag.length;
      let wb = await (new ExportData()).createExcelExportForProject(viewTag.toString(), ejnInTag, createdDate, explorerSearchState.selectableFilterList,         
        (progress:number) => {
          appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/count)));
      });
      appMessageDispatch(actionClearProgressMessage());
      let blob = SheetFunc.createBlob(wb);
      let timeStamp = new Date
      let fileName = "ProjectExport_"+viewTag+"_"+createdDate.toLocaleDateString()+"_"+createdDate.toLocaleTimeString();
      fileName += ".xlsx";
      ExportData.downloadBlob(fileName, blob);
      appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Project {project} has been exported to excel",{project:viewTag+""})));
    }
    catch(error:any) {
      appMessageDispatch(actionSetErrorMessage(error));
    }
  }

  useEffect(() => {
    let x = MitStorage.getValue<{showProps:boolean, showEjn:boolean}>("ProjPersist")
    if (x) {
      const {showProps, showEjn} = x
      setShowProps(showProps);
      setShowEjn(showEjn);
    }
  },[])

  useEffect(() => {
    MitStorage.setValue("ProjPersist",{showProps:showProps, showEjn:showEjn})
  },[showProps,showEjn])

  useEffect(() => {
    if (viewEjn.length == 1) {
      const selected = projState.data.filter((a) => a.bfeNr === viewEjn[0])[0] || projState.data[0]
      const mitLatLng = new MitLatLng(selected?.latLng?.[0] || 0, selected?.latLng?.[1] || 0)
      const data = {
        bfeNr: selected.bfeNr,
        dot: {latlng: mitLatLng}
      }
      setDataEjn(data)
    } else {
      const data = {
        id: undefined,
        dot: undefined
      }
      setDataEjn(data)
    }
  },[viewEjn])

  function removeTag(e) {
    const tag = e.dataTransfer.getData("text/plain");
    projDispatch(removePersistentTag(tag))
    e.preventDefault();
  }

  function deleteEjendom(DeleteID:number) {
    let safeCurrent = projState.data.filter((a) => a.bfeNr === DeleteID)[0];
    if (safeCurrent) {
      projDispatch(handleRemoveEjendom(DeleteID));
      if (!safeCurrent.tags.length) {
        setViewEjn([])
      }
    }
  }

  const EjnInTag = projState.data.filter((a) => (viewTag.length === 0 || viewTag.every((Tag) =>  a.tags.includes(Tag)) || viewTag.includes("untagged") && a.tags.length === 0))
  // const SellEjn = projState.data.filter((a) => a.bfeNr === viewEjn)[0] || projState.data[0]

  if (!!!props.show) {
    return <></>
  }

    return (
    <>
      {projState.isEmpty && projState.isLoaded ? (
        <ConfirmationDialog showWindow={1000} header={""} onCancel={() => projDispatch(setState({isLoaded:false}))} onSubmit={() => projDispatch(setState({isEmpty:false}))} >
          <>
          <div>{Localization.getText("An Existing Project cound't be found, create new?")}</div>
          {/* <div>{Localization.getText("Click cancel if you already h")}</div> */}
          </>
        </ConfirmationDialog>
      ) : null}
      <input type="file" ref={uploadRef} onChange={(e) => importFile(e)} style={{display:"none"}}/>
      <a ref={linkRef} style={{opacity:0, position:"absolute"}}></a>
      <div className='NewSecTheme' style={{position: "fixed", "inset":"0px",zIndex:1,top: "var(--topBarHeight)", pointerEvents:"none"}}>
      <Modal.Dialog className='nModal MitNotInfoFix' ref={ref}>
        <Modal.Header className='' onMouseDown={Eventer} onTouchStart={Eventer}>
        <div className='GrabLines' onMouseDown={clickEventer} />
          <h4>{Localization.getText("Projects")}</h4><span>
          {/* <ProtectedFeature feature={Feature.ExplorerImportExport} contentsIfNoAccess={<></>} > */}
          <div className="ViewButtonSplitter">
          {viewTag && EjnInTag.length ?
          <ViewButtonIcon title={Localization.getText("Export selected tag to Excel")} onClick={(e) => handleExportExcelForTag(viewTag, EjnInTag)} ><img src="./images/NewSecSVG/excel.svg"></img></ViewButtonIcon>
          :
          <ViewButtonIcon disabled onClick={(e) => saveToXLSX(e)} ><img src="./images/NewSecSVG/excel.svg"></img></ViewButtonIcon>
          }
          <ViewButtonIcon title={Localization.getText("Export all projects to Json")} onClick={(e) => saveToFile(e)} ><img src="./images/NewSecSVG/json.svg"></img></ViewButtonIcon>
          <ViewButtonIcon title={Localization.getText("Import and overwrite all projects from Json")} onClick={(e) => uploadRef.current?.click()} onDrop={(e) => importFile(e)} ><img src="./images/NewSecSVG/importer.svg"></img></ViewButtonIcon>
          </div>
          {/* </ProtectedFeature> */}
          </span>
          {/* <Modal.Title> */}
          {/* </Modal.Title> */}
          <MdClose className='CloseButton' onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Projects))} />
        </Modal.Header>
          <Modal.Body className='bodyLimitHeight Projects' style={{alignItems:"strech", height:"100%"}}>
            <Row className="NS">
            <Col sm={2}>
            <Card >
              <Card.Header  style={{color:"#454659"}} className="FloatHead">
                <span onClick={() => handleSelTag(undefined, null)}>{Localization.getText("Tag")}</span>
                <Button 
                  onClick={(e) => {viewTag && viewTag.forEach((tag) => projDispatch(removePersistentTag(tag))); e.preventDefault()}}
                  onDrop={removeTag} onDragOver={(e) => e.preventDefault()} size="sm" variant="alert" title={Localization.getText("Drop empty tag here to remove")} style={{fontSize:"18px"}} ><BsTrashFill /></Button>
              </Card.Header>
              <Card.Body>
                <TagList viewtag={viewTag} persistentTags={projState.persistentTags} tags={[...projState.data.reduce((a:string[],b) => [...b.tags.filter((c) => !a.includes(c)),...a] ,projState.persistentTags || []).sort().filter((a) => a !== "untagged" ),"untagged"]} callBackClick={handleSelTag} />
                <input style={{background:"#e0e4e8" , textAlign:"center",color:"#96a9bc", marginTop:"4px", boxShadow:"none", width:"100%"}} type="text" placeholder={Localization.getText("+ add new tag")} defaultValue={""} onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.currentTarget.value.trim()) {
                      projDispatch(addPersistentTag(e.currentTarget.value.trim()))
                      e.currentTarget.value = ""
                    }
                  }  
                }} /> 
              </Card.Body>
            </Card>
            </Col>
            <Col sm={4}>
            <Card >
              <Card.Header className="FloatHead">
                <div style={{color:"#454659"}}>{Localization.getText("Items")}</div>
                {/* <Button style={{opacity: 1}} onClick={() => handleExportExcelForTag(viewTag, EjnInTag)} disabled={Boolean(!viewTag || viewTag === "untagged" || !EjnInTag.length)} size="sm" variant="alert" >{Localization.getText("Export Excel")}</Button> */}
                <Button style={{opacity: Number(!(viewTag.length == 0))}} onClick={() => handleCreateLayerForTag(viewTag, EjnInTag)} disabled={Boolean(!(viewTag.length || viewTag.includes("untagged")))} size="sm" variant="alert" >{Localization.getText("Add as layer")}</Button>
                  <div style={{color:"#454659"}}>({EjnInTag.length})</div>
              </Card.Header>
              <Card.Body>
              <EjendomList active={viewEjn} EjnList={EjnInTag} callBackClick={(e, bfe) => handleSelEjendom(e, bfe)} />
              </Card.Body>
            </Card>
            </Col>
            <Col sm={6}>
            <Card >
              <Card.Header className="FloatHead">
                <div>
                  <span style={{position:"relative",marginRight:"30px",color:"#454659"}} onClick={() => setShowEjn(!showEjn)}>{Localization.getText("Items info")} <span className='mit-transformer' style={{position:"absolute" ,marginLeft:"6px", transform:"Rotate("+(showEjn ?"90deg":"0deg")+")"}}><BsChevronRight /></span></span>
                <span style={{position:"relative",marginRight:"30px"}} onClick={() => setShowProps(!showProps)}>{Localization.getText("Property information")}<span className='mit-transformer' style={{position:"absolute" ,marginLeft:"6px", transform:"Rotate("+(showProps ?"90deg":"0deg")+")"}}><BsChevronRight /></span></span>
                </div><Button onClick={() => viewEjn && viewEjn.forEach((a) => deleteEjendom(a))} disabled={viewEjn.length === 0} size="sm" variant="alert" style={{fontSize:"18px", opacity: Number(viewEjn.length !== 0)}} ><BsTrashFill /></Button>
              </Card.Header>
              <Card.Body className="fixedEjendom relafix">
               {showEjn? <EjendomView EjnList={projState.data.filter((a) => viewEjn.includes(a.bfeNr))} tagList={projState.data.reduce((a:string[],b) => [...b.tags.filter((c) => !a.includes(c)),...a] ,[]).sort().filter((a) => a !== "untagged" )} />: null}
               {(dataEjn?.dot && showProps) ? <Modal.Body>
          <PropertyInfoView
            key={dataEjn.dot.latlng.lat.toString()}
            foldetUd={true}
            cols={1}
            labelLatLng={dataEjn.dot}
            callBackOnLoaded={() => {}}
            allowExtendedInformation={true}
          />
          </Modal.Body>
               :null}
              </Card.Body>
            </Card>
            </Col>
            </Row>
          </Modal.Body>
      </Modal.Dialog>
      </div>
      </>
  )
}

function TagSelector({name, persist,onPersist,onClick, ...rest}) {return (<div {...rest} tabIndex={0}><button onClick={onClick} style={{textAlign:"left"}}  >{name}</button>
{/* <button title={
  !persist ? Localization.getText("Unlock - Remove tag when empty") : Localization.getText("Locked - Keep tag even when empty")
} onClick={onPersist}>{!persist ? <HiLockOpen /> : <HiLockClosed />}</button> */}
</div>)}
function TagList(props:{persistentTags:string[], tags:string[], viewtag:string[], callBackClick: (e:MouseEvent, a:string) => void }) {
  const {dispatch: projDispatch} = useContext(ProjectContext);

  function onDragHandler(e, tag) {
    console.log(tag)
    e.dataTransfer.setData("text/plain", tag)
  }

  return (
    <div className="TagList" style={{display:"flex", flexDirection:"column", justifyContent:"stretch", flexWrap:"wrap"}} >
    {props.tags.map((a, index) => <TagSelector key={a} onPersist={() => {props.persistentTags.includes(a) ? projDispatch(removePersistentTag(a)) : projDispatch(addPersistentTag(a))}} persist={props.persistentTags.includes(a)} name={index === props.tags.length - 1 ? Localization.getText("untagged") : a} className={props.viewtag && props.viewtag.includes(a) ? "active" : ""} onDragStart={(e) => onDragHandler(e, a)} draggable="true" onClick={(e) => props.callBackClick(e,a) } />)}
    </div>
  )
}

function StarViewer(stars:number|null) {
  let fixedStar = stars || 0
  return (
    <div className="SmallStar">
      {[1,2,3,4,5].map((a) =><BsStarFill key={a} style={{color: (fixedStar && fixedStar >= a ? "#bd9060" : "#816c5d" )}} />)}
    </div>
    )

}


function ListItem({id, value, ...rest}) {
  const {dispatch: projDispatch} = React.useContext(ProjectContext)

  return (
    <div {...rest} style={{cursor:"pointer", flex:"95%", display:"flex", justifyContent:"space-between"}}>
      <div>{value.name.split?.(",")[0]},</div>
      <div>{value.name.split?.(",").at(-1)}</div>
      {value.cf_starAcc ? <div>{StarViewer(value.cf_starAcc)} </div> : <div><FiChevronRight /></div>}
      <div className="TagsInList">{value.tags.map((b) => <span key={b}><button className="rmTag" onClick={(e) => {projDispatch(removeTag(value.bfeNr, b));e.preventDefault();e.stopPropagation()}}><IoClose /></button>{b}</span>)}</div>
    </div>
  )
}

function MultiItemView({Ejendomme, tagList, ...rest}) {
  const {state, dispatch} = React.useContext(ProjectContext)

  const callbacks = {
    addV:  (id ,cfKey, b) => {Ejendomme.forEach(element => {dispatch(addValue(element.bfeNr ,cfKey, b))})},
    remV:  (id, cfKey) => {Ejendomme.forEach(element => {dispatch(removeValue(element.bfeNr, cfKey))})},
    remT:  (id, a) => {Ejendomme.forEach(element => {
      dispatch(removeTag(element.bfeNr, a))
    }); },
    addT:  (id, a) => {
      Ejendomme.forEach(element => {
        dispatch(addTag(element.bfeNr, a))
      }); },
  }

  return (
    <>
    <FieldGroup1 title={Localization.getText("Items")} >
      <h6>{Ejendomme.length } Selected</h6>
    </FieldGroup1>
    <FieldGroup1 title={Localization.getText("Tag")} >
    <TagChangerTypeAhead id="UnknonwnID" value={{tags:[...new Set(Ejendomme.flatMap((a) => a.tags))], bfeNr:1001010}} options={tagList} callbacks={callbacks} {...rest} />
    </FieldGroup1>
  
    </>
  )
}

function ItemView({id, value, tagList, ...rest}) {
  const {state, dispatch} = React.useContext(ProjectContext)

  const callbacks = {
      addV:  (id ,cfKey, b) => {dispatch(addValue(id ,cfKey, b))},
      remV:  (id, cfKey) => {dispatch(removeValue(id, cfKey))},
      remT:  (id, a) => {dispatch(removeTag(id, a))},
      addT:  (id, a) => {dispatch(addTag(id, a))},
  }

  return (
    <>
    <FieldGroup1 title={Localization.getText("Address")} >
      <h6>{value.name}</h6>
      {/* <div><h6>Created</h6><div>{new Date(value.created).toLocaleDateString()}</div></div> */}
      {/* <div><h6>Changed</h6><div>{new Date(value.changed).toLocaleDateString()}</div></div> */}
    </FieldGroup1>
    {/* <FieldGroup1 title="Position" > */}
      {/* <MapView  name={"TEST"} {...{id, value, callbacks, ...rest}} /> */}
    {/* </FieldGroup1> */}
    <FieldGroup1 title={Localization.getText("Tag")} >
    <TagChangerTypeAhead {...{id, value, options:tagList, callbacks, ...rest}} />
    </FieldGroup1>
    <FieldGroup1 title={Localization.getText("Site description")}>
      <TextInput name={"Site Desc"} value={value} cfKey={"cf_siteDescA"} callbacks={callbacks} />
    </FieldGroup1>
    <FieldGroup1 title={Localization.getText("Zoning")}>
      <TextInput name={"Site Desc"} value={value} cfKey={"cf_siteDescB"} callbacks={callbacks} />
    </FieldGroup1>
    <FieldGroup2 title={Localization.getText("Rating")} >
      <StarRating name={Localization.getText("Overall evaluation")} value={value} cfKey={"cf_starAcc"} callbacks={callbacks} />
      <StarRating name={Localization.getText("Visibility")} value={value} cfKey={"cf_starVis"} callbacks={callbacks} />
    </FieldGroup2>
    </>
  )
}
function EjendomView(props:{EjnList, tagList}) {

  if (props.EjnList.length > 1) {
    
    return (
      <div className="EjendomView" style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}} >
    <MultiItemView Ejendomme={props.EjnList} tagList={props.tagList} />
    </div>
    )
  }

  return (
    <div className="EjendomView" style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}} >
    {props.EjnList.map((a,b) => <ItemView key={a.bfeNr+b} id={a.bfeNr} value={a} tagList={props.tagList} />)}
    </div>
  )
}

function EjendomList(props:{EjnList, active, callBackClick: (e:MouseEvent, a:number) => void}) {
  const {state, dispatch} = React.useContext(ProjectContext)

  function DropHandler(bfeNr,e) {
    console.log(e)
    const tag = e.dataTransfer.getData("text/plain");
    const listTag = tag.split("	")
    listTag.forEach(element => {
      if (element.trim()) {
        dispatch(addTag(bfeNr, tag))
      }
    });((a) => {})
    
    e.preventDefault();
  }

  return (
    <div className="Project list" style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}} >
    {props.EjnList.map((a,b) => <ListItem key={a.bfeNr} id={b}  onDragOver={(e) => e.preventDefault()} onDrop={(e) => DropHandler(a.bfeNr,e)} value={a} className={(props.active.includes(a.bfeNr) ? "active" : "")+" ResItem Item"} onClick={(e) => props.callBackClick(e,a.bfeNr)} />)}
    </div>
  )
}

function ExpandedInfo() {
  let [infoList, setInfoList] = React.useState(Array.from(new Array(50), ((a,id) => {return {name: "ValueName"+id, value: "value" + id}})));

  return (
    <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}} >
    {infoList.map((a) => <KeyValue key={a.name} name={a.name} value={a.value} />)}
    </div>
  )

}


function ProjectItem(props: {name:string}) {
  return (
    <div className='ResItem' style={{flex:"95%", display:"flex", justifyContent:"space-between"}} >
    <div>{props.name}</div><FiChevronRight />
  </div>
  )
}

type KeyValueTypes = "Text"|"Number"|"Range";
type KeyValueValue = undefined | string | number
function KeyValue(props: {name:string, value:KeyValueValue, type?:KeyValueTypes}) {
  return (
    <div className='ResItem' style={{flex:"45%", display:"flex", justifyContent:"space-between"}} >
      <div>{props.name}</div>
      <div>{props.value}</div>
    </div>
  )
}


// function MapView({name, value ,callbacks, ...rest}) {
//   let mD:DemographyReportMetaData = {
//     center: new MitLatLng(value.coords[1], value.coords[0]),
//     radiusMeters:50,
//     logo:"",
//     reportDate: new Date(),
//     languageCode: "da",
//     reportName: "Non"
//   }
//   return <EmbeddedSimpleMap metaData={mD} showWindow={true} />
// }

function StringToColor(k:string) {
  let y = ([...k].map((a:string) => a.charCodeAt(0))).reduce((a,b) => a+b,0) 
  return `hsl(${y%360}, 63%, 57%)`
}


function AddWindow(props:{data:any[], toAdd:any[], callBackOnSubmit?:(a:any) => void, callBackOnCancel?:() => void}) {
  let [loading, setLoading] = useState(true);
  let [valuesToAdd, setValuesToAdd] = useState([])
  let {state:projState} = React.useContext(ProjectContext)
  let {dispatch:modusDispatch} = React.useContext(RightPaneContext)
  let [selectedTags, setSelectedTags] = useState<string[]>([])

  useEffect(() => {
     if (props.toAdd.length > 0) {
        setLoading(false)
     } else {
        setLoading(true)
     }
  },[props.toAdd])

  function handleSubmit() {
    try {

    
     const result = props.toAdd.map((a) => {
        if (!("bfeNr" in a && a.bfeNr)) {
          throw new Error("Missing bfeNr")
        }
        if (!("latLng" in a && a.latLng)) {
          throw new Error("Missing latLng")
        }
        if (!("name" in a && a.name)) {
          throw new Error("Missing name")
        }
        return {...a, tags:selectedTags}
      })

      props.callBackOnSubmit?.(result)
    }
    catch (e: any) {
      console.error(e)
      console.error("Error")
    }
  } 

  function addTag(bfe, tag) {
    setSelectedTags((a) => [...a,tag])
  }

  function remTag(bfe, tag: string) {
    setSelectedTags((a) => [...(a.filter((rTag) => rTag !== tag))])
  }

  const tagList = React.useMemo(() => {
     return [...props.data.reduce((a:string[],b) => [...b.tags.filter((c) => !a.includes(c)),...a] ,[]).sort().filter((a) => a !== "untagged" ),"untagged"]
  },[props.data])

  if (projState.isEmpty) {
     return (<Modal show={true} backdrop={true} id="mit-info" className="AddProject" >
     <Modal.Header><></></Modal.Header>
     <Modal.Body>
        <div className="closeBtn" onClick={(e) => {props.callBackOnCancel?.()}} ><BsX/></div>
        <Alert variant="succes" >{Localization.getText("Please visit the Project tabs, an action is needed!")}</Alert>
        {/* <RRAINPUT freeForm={true} suggestions={tags} onBlur={setSelectedTags} /> */}
        <div className="flex-mid" >
           <MitButton style={{marginInline:"auto"}} variant={"nsNormal"} onClick={(e) => 
              modusDispatch(actionGoToModus(RightPaneModus.Projects))} >{Localization.getText("Go to Projects")}</MitButton>
        </div>
        {/* <Button onClick={handleSubmit} >{Localization.getText("Add and go to Project")}</Button> */}
     </Modal.Body>
     </Modal>
     )
  }

  return (<Modal show={true} backdrop={true} className="AddProject" >
     <Modal.Header><></>
     </Modal.Header>
        <div className="closeBtn" onClick={(e) => {props.callBackOnCancel?.()}} ><BsX/></div>
     <Modal.Body>

        {props.toAdd?.length !== 1 ?
        <Alert variant="succes">{loading ? "Loading..." : Localization.getFormattedText(`Add {number} items to Project`, {number: props.toAdd.length}) }</Alert>
        :
        <Alert variant="succes">{loading ? "Loading..." : Localization.getText(`Add Property to Project`) }</Alert>
        }
        {/* <RRAINPUT freeForm={true} suggestions={tags} onBlur={setSelectedTags} /> */}
        {/* <div className="EjendomView" > */}
        <TagChangerTypeAhead id="ss"  value={{bfeNr:0, tags: selectedTags}} options={tagList.filter((a) => a !== "untagged")} callbacks={{remT:remTag, addT: addTag}} />
        {/* </div> */}
        <div className="flex-mid" >
        <MitButton style={{marginInline:"auto"}} variant={"nsNormal"} onClick={handleSubmit} >{Localization.getText("Add")}</MitButton>
        <div style={{flexGrow:"20"}}></div>
        </div>
     </Modal.Body>
  </Modal>)

}
