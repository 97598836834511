import { LicensingContext, Logger, SessionContext, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { Product } from "@viamap/viamap2-common/dist/managers/Types";
import { actionInitializeLicenseManager } from "@viamap/viamap2-common";
import React from "react";
import { Button, Col, Container, Form, FormControl, Modal, Row, Table } from "react-bootstrap";
import { ApplicationStateContext, actionUpdateAvailableFeatures } from "src/states/ApplicationState";

export const SimulateOtherUserDialog = (props: { show: boolean, onClose: () => void }) => {
   const [userEmail,setUserEmail] = React.useState<string>("");
   const {state:sessionState} = React.useContext(SessionContext);
   const {state:licensingState, dispatch:licencingDispatch} = React.useContext(LicensingContext);
   const {state:applicationState, dispatch:applicationStateDispatch} = React.useContext(ApplicationStateContext);
   const [originalUserRef] = React.useState<string>(sessionState.userRef);


   // React.useEffect(() => {
   //    if (sessionState.userRef != originalUserRef) {
   //       alert("new userref. License:"+licensingState.currentlicense?.productVariant);
   //       // SettingsManager.initializeAfterUserIsKnown({}, sessionState.customerRef, sessionState.userRef, "mapit");
   //       // applicationStateDispatch(actionUpdateAvailableFeatures(sessionState.userRoles, licensingState?.currentlicense));
   //       // Logger.initialize(licensingState.product, sessionState.userRef, sessionState.userName);
   //    } else {
   //       alert("unchanged")
   //    }
   // }, [sessionState.userRef]);

   function actionSimulateUser() {
      try {
         let userRef = userEmail;
         let customerRef =  ViamapPersistenceLayer.extractCustomerRef(userEmail)||"unknown";
         licencingDispatch(actionInitializeLicenseManager(Product.Mapit, customerRef, userRef));
         // SettingsManager.initializeAfterUserIsKnown({}, customerRef, userRef, Product.Mapit);
         alert("Simulerer nu access rights for bruger "+userEmail);
      } catch (err:any) {
         alert("Simulering fejlede. "+(err.message||err))
      }
      props.onClose();
   }

   return (
      <Modal show={props.show} onHide={props.onClose} >
         <Modal.Header closeButton>
            <Modal.Title>
               <h3>{"Simulate other user login"}</h3>
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form>
            <FormControl size="sm" type="text" disabled={false} value={userEmail} placeholder={"User email"} onChange={(e) => {setUserEmail(e.target.value)}}/>
         <Button onClick={() => {
            actionSimulateUser();
         }}>Do it</Button>
         </Form>
         </Modal.Body>
      </Modal>
   );
}