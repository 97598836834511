import * as React from 'react';
import {Alert, Modal} from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import {Logger} from '@viamap/viamap2-common';
import {Cookies, MitCookies} from '@viamap/viamap2-common';
import { createPortal } from 'react-dom';
import { MitBody, MitButton, MitDialog, MitFooter, MitHeader } from './ComponentUtils';
import cookie from '../svg/markersNewSec/cookie.svg'
 



export default function CookieApproval() {
  let [show, setShow] = React.useState(!Cookies.getCookieBoolean(MitCookies.RememberCookieAccept));
  if (!show) {
    return null
  }

  function AproveCookies(...rest) {
    Cookies.setCookieBoolean(MitCookies.RememberCookieAccept, true);
    setShow(false);
    Logger.logAction("MapScreen","onCookieApprovalCommit","User approved Cookie notice");
  }

    let okbutton = <MitButton variant={["normal","nsNormal"]} type="submit" onClick={AproveCookies} >{Localization.getText("CookieAcceptButton")}</MitButton>;
    return createPortal(
      <MitDialog className='CookieApproval' show={10000} style={{left:"50%",top:"20%", transform: "translate(-50%,-20%)"}} >
        <MitHeader position='center'>
          <><img style={{maxHeight:"100px", margin:"auto", display:"block"}} src={cookie} alt='Cookie' /></>
        </MitHeader>
        <MitBody style={{borderRadius:"0px"}} dangerouslySetInnerHTML={{__html:Localization.getText(MitCookies.RememberCookieAccept).replaceAll(". ",`.<br/>`)}} />
      <MitFooter style={{justifyContent: "center"}}>
            {okbutton}
      </MitFooter>
    </MitDialog>
    , document.body)
  
}