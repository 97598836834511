import React, { useEffect, useState } from "react"

export function SimplePathRouter(props: {
  endPoints: {queryKey:string, to: React.ReactElement}[],
  children: React.ReactElement
}) {
  const [path,setPath] = useState<string>(new URL(document.URL).pathname)

  for (let i = 0; i < props.endPoints.length; i++) {
    const elem = props.endPoints[i]
    if (path.startsWith(elem.queryKey)) {
      return elem.to
    }
  }
  return props.children
}

export class MITLink {

  static goto(path: `/${string}`) {
    const x = document.URL.split("/").at(0)
    window.history.replaceState(null, "", x + path)
  }
}