import { LicensingContext, SessionContext, SettingsManager } from "@viamap/viamap2-common";
import React from "react";
import { Modal, Button, Table, Form, Col, Container, Row } from "react-bootstrap";
import { ApplicationStateContext, FeatureType, actionAddAvailableFeature, actionRemoveAvailableFeature } from "src/states/ApplicationState";

/** Show current user Access Rights. For debugging use */
export const UserAccessRightsWindow = (props: { show: boolean, onClose: () => void }) => {
   const { state: sessionState, dispatch: sessionDispatch } = React.useContext(SessionContext);
   const { state: applicationState, dispatch: applicationStateDispatch, hasAccessToFeature } = React.useContext(ApplicationStateContext);
   const { state: licensingState } = React.useContext(LicensingContext);
   // const [availableRoles, setAvailableRoles] = React.useState<string[]>();
   // const [availableFeatures, setAvailableFeatures] = React.useState<any>();

   function removeFeature(features: FeatureType[], removeFt: FeatureType): FeatureType[] {
      return features.filter((ft, idx) => {
         return ft !== removeFt;
      });
   }

   function addFeature(features: FeatureType[], addFt: FeatureType): FeatureType[] {
      // test for undefined to ensure that '0' values are also matched
      if (features.find((val) => val === addFt) === undefined) {
         features.push(addFt);
      }
      return features;
   }

   // React.useEffect(() => {
   //    let userRoles: string[] = ["MINIMAL_FEATURES_FOR_ANY_USER"];
   //    userRoles = [...userRoles, ...sessionState.userRoles];
   //    if (licensingState.currentlicense) {
   //       let lic = licensingState.currentlicense;
   //       let variant = lic?.productVariant || "Unknown";
   //       if (applicationState.licenseVariant2Roles[variant]) {
   //          userRoles = [...userRoles, ...applicationState.licenseVariant2Roles[variant]];
   //       }
   //    }

   //       // Construct feature set from role/groups memberships

   //       let featureSet:FeatureType[] = [];
   //       // phase 1: all allows
   //       userRoles && userRoles.forEach((role,idx) => {
   //           let featuresforgroup = applicationState.userRole2Features[role];
   //           if (featuresforgroup) {
   //               featuresforgroup.allow.forEach((ft) => {
   //                   featureSet = addFeature(featureSet, ft);
   //               });
   //           }
   //       });
   //       // phase 2: all denys
   //       userRoles && userRoles.forEach((role,idx) => {
   //           let featuresforgroup = applicationState.userRole2Features[role];
   //           if (featuresforgroup) {
   //               // remove any items in deny list
   //               featuresforgroup.deny && featuresforgroup.deny.forEach(element => {
   //                   featureSet = removeFeature(featureSet, element);
   //               });
   //           }
   //       });

   //       // remove any duplicates
   //       let availableFeatures = [...new Set(featureSet)];
   //       setAvailableFeatures(availableFeatures);
   //       setAvailableRoles(userRoles);
   // }, [sessionState, applicationState])
   return (
      <Modal show={props.show} onHide={props.onClose} >
         <Modal.Header closeButton>
            <Modal.Title>
               <h2>Shows the contents of Access Rights.</h2><h3 style={{ display: "inline-flex" }}>Customer: "{sessionState.customerRef}" User: "{sessionState.userRef}"</h3>
               {/* <Button onClick={() => {
                  setSettingsState(SettingsManager.getState());
               }}>Refresh</Button> */}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Container fluid={true}>
               <Row>
                  <Col>
                  <h4 style={{height:"unset"}}>Roles</h4>
                     <Table size="sm">
                        <tbody>
                           {applicationState.availableRoles ? applicationState.availableRoles.sort().map((role, idx) => {
                              return (
                                 <tr key={role + idx}>
                                    <td style={{border:"unset"}}>{role}</td>
                                 </tr>
                              )
                           }) : "no roles"}
                        </tbody>
                     </Table>
                  </Col>
                  {/* <Col >
                  <h4 style={{height:"unset"}}>All Roles</h4>
                     <Form>
                        {applicationState.userRole2Features ? Object.keys(applicationState.userRole2Features).sort().map((role, idx) => {
                           return (
                              <Form.Check // prettier-ignore
                                 checked={applicationState.availableRoles.includes(role)}
                                 onChange={(e) => {
                                    let checked = applicationState.availableFeatures ? applicationState.availableFeatures.includes(role) : false;
                                    if (checked) {
                                       applicationStateDispatch(actionRemoveAvailableFeature(role));
                                    } else {
                                       applicationStateDispatch(actionAddAvailableFeature(role));
                                    }
                                 }
                                 }
                                 key={role + idx}
                                 type="checkbox"
                                 id="custom-switch"
                                 label={role}
                                 disabled
                              />
                           )
                        }) : "no roles"}
                     </Form>
                  </Col> */}
                  {/* <Col>
                  <h4 style={{height:"unset"}}>Features</h4>

                     <Table>
                        <tbody>
                           {applicationState.availableFeatures ? applicationState.availableFeatures.sort().map((feature, idx) => {
                              return (
                                 <tr key={feature + idx}>
                                    <td>{feature}</td>
                                 </tr>
                              )
                           }) : "no features"}
                        </tbody>
                     </Table>
                  </Col> */}
                  <Col>
                  <h4 style={{height:"unset"}}>All Features</h4>
                     <Form>
                        {applicationState.features ? applicationState.features.sort().map((feature, idx) => {
                           return (
                              <Form.Check // prettier-ignore
                                 checked={applicationState.availableFeatures ? applicationState.availableFeatures.includes(feature) : false}
                                 onChange={(e) => {
                                    let checked = applicationState.availableFeatures ? applicationState.availableFeatures.includes(feature) : false;
                                    if (checked) {
                                       applicationStateDispatch(actionRemoveAvailableFeature(feature));
                                    } else {
                                       applicationStateDispatch(actionAddAvailableFeature(feature));
                                    }
                                 }
                                 }
                                 key={feature + idx}
                                 type="checkbox"
                                 id="custom-switch"
                                 label={feature}
                              />
                           )
                        }) : "no features2"}
                     </Form>
                  </Col>
               </Row>
            </Container>



         </Modal.Body>
      </Modal>
   );
}