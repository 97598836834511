import React from "react";
import { Modal } from "react-bootstrap";
import { usePanWindow } from "src/common/components/CustomHooks";

export function AdvancedLargeDragModal(props: {
    show;
    className;
    headerContent;
    children;
    style;
    posistionLocalStorageKey?: string
  }) {
    let [eventer, setRef, clickEventer, setOpen] = usePanWindow(0, {localStorName: props.posistionLocalStorageKey});
  
    React.useEffect(() => {
      setOpen({});
    }, [props.show]);
  
    return (
      <Modal.Dialog style={props.style} className={props.className} ref={setRef}>
        <Modal.Header onMouseDown={eventer} onTouchStart={eventer}>
          <div className="GrabLines" onMouseDown={clickEventer} />
          {props.headerContent}
        </Modal.Header>
        {props.children}
      </Modal.Dialog>
    );
  }
  