import { Localization } from "@viamap/viamap2-common";
import { MitBody, MitButton, MitDialog, MitFooter, MitHeader, MitInfo } from "./ComponentUtils";
import { LayerInfo } from "src/common/managers/Types";
import { useState } from "react";
import React from "react";


type Props= {
  showWindow:number;
  layers?: LayerInfo | LayerInfo[]
  callBackConfirm: (layers: LayerInfo[]) => void;
  callBackCancel: () => void;
};
export function NameOnCreation(props: Props) {
  let layers = !props.layers ? [] : "type" in props.layers ? [props.layers] : props.layers
  const [name, setName] = useState(layers.map((a) => a.datasetname))

  function updateName(value:string, idx: number) {
    setName((name) => name.map((a, idy) => idx === idy ? (value ?? "") : a))
  }

  function Accept() {
    let newLayers = layers.map((layer, idx) => ({
      ...layer,
      datasetname: name[idx] || layer.datasetname
    }))
    props.callBackConfirm(newLayers)
  }

  function Cancel() {
    props.callBackCancel()
  }
   
   
  return <MitDialog show={props.showWindow} style={{top:"10px", left:"50%", transform:"TranslateX(-50%)", backgroundColor:"#f0f1f1"}}>
    <MitHeader position="center" >
      {Localization.getText("Rename")}
    </MitHeader>
    <MitBody>
      <MitInfo>
       {name.length < 2 ? Localization.getText("Change the name of new the layer before adding it to the list"): Localization.getText("Change the names of the new layers before they are added to the list")}
      </MitInfo>

      {layers.map((layer,idx) => {
        return (<React.Fragment key={layer.datasetname ?? "Error"}>
          <div style={{color:"grey", textAlign:"left", fontSize:"11px"}}>{Localization.getText("Layer")+": "+(idx+1)}</div>
          <input autoFocus={idx == 0} style={{color:"black", paddingLeft:"6px"}} type="text" value={name[idx]} placeholder={layer.datasetname ?? "Error"} onChange={(ev) => updateName(ev.target.value, idx)}
            onKeyDown={(e) => {
              console.log(e);
              ( idx == 0 && e.code == "Enter" && Accept());
              (layers.length - 1 == idx &&  e.code == "Escape" && Cancel());
            }}
          />
        </React.Fragment>) 
      })}
    </MitBody>
    <MitFooter>
      <MitButton variant="normal" onClick={(e) => Accept()}>{Localization.getText("Save")}</MitButton>
      <MitButton variant="close" onClick={(e) => Cancel()}>{Localization.getText("Close")}</MitButton>
    </MitFooter>
  </MitDialog>
}