import { FormatUtils } from "src/managers/FormatUtils";
import { FileImportOptions, FileImportType } from "./FileImportHooks";
import { AppMessagesContext, Localization, Logger, ObjectRef, PersistenceObjectType, PersistenceScope, SessionContext, SettingsManager, ViamapPersistenceLayer, actionClearInfoMessage, actionSetErrorMessage, actionSetInfoMessage } from "@viamap/viamap2-common";
import { useState, useContext, useEffect } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { Utils } from "@viamap/viamap2-common";
import { DawaAddressResult, DawaQuality, LayerHierarchy, LayerInfo, LayerType, LayerVariabilityType, PredictionResult, SheetAnalysisResult, ViewerState } from "src/common/managers/Types";
import { PredictionLogic } from "src/managers/PredictionLogic";
import { importMenuStructure } from "src/managers/ConfigData";


import { ExcelSheetDataAccessor, Parser } from "../managers/Parser";
import * as XLSX from 'xlsx';

import { ColumnMapping, MitDataType, RowDataQuality } from "src/common/managers/Types";
import { ExifParserFactory } from "ts-exif-parser";
import { Persistence } from "../managers/Persistence";
import { SheetFunc } from "../managers/SheetFunc";
import { ImportDataProcessor } from "../managers/ImportDataProcessor";
import { MapitStateActionType, MapitStateContext, MapitWindowId, actionAddDataLayer, actionAddExternalFeatureLayers, actionSelectBackgroundLayer, actionSetLayerHierarchy, actionSetShowWindow, actionUpdateMapInfo } from "src/states/MapitState";
import { OldHierarchy } from "src/managers/OldHierarchy";
import { AddressInterface } from "src/managers/AddressInterface";
import { ImportResultDetails } from "./ImportResultDetails";
import { ImportResults } from "./ImportResults";
import { CadasterGeoCodeResult, CadasterInterface } from "src/managers/CadasterInterface";
import { LayerImport } from "./LayerImport"
import { LayerFunc } from "src/managers/LayerFunc";
import { AWSUserAdmin } from "src/common/managers/AWSUserAdmin";
import AWS from "aws-sdk";
import { restoreMapFromLink } from "src/managers/RestoreMapLink";
import { FiSettings } from "react-icons/fi";
import LoadingScreen from "./LoadingScreen";
import * as turf from '@turf/turf';
import { MapitLayerFactory } from "./MapitLayerFactory";
import { MapitUtils } from "src/managers/MapitUtils";
import { ReferenceGeomComposite } from "src/managers/ReferenceGeomComposite";
import { setState } from "src/states/ProjectState";

function CallInfo(msg: any) {
  document.body.dispatchEvent(new CustomEvent('mit-info-handler', {detail: msg?.message || msg}))
}

function CallError(msg: any) {
  document.body.dispatchEvent(new CustomEvent('mit-error-handler', {detail: msg?.message || msg}))
}



export function FileLoader(props:{fileImportOptions:FileImportOptions | null, finishCallback: () => void}) {


  const { state: sessionState} = useContext(SessionContext);
  const { state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder} = useContext(MapitStateContext);
  const {dispatch:appMessageDispatch} = useContext(AppMessagesContext);
  
  const [filesizesTooBigDiaglog, setFilesizesTooBigDiaglog] = useState<number>(0)
  const [showImportResultDetailsWindow,setShowImportResultDetailsWindow] = useState<boolean>(false);
  const [importResultDetailsWindowLayerInfo,setImportResultDetailsWindowLayerInfo] = useState<LayerInfo | undefined>(undefined);
  const [showImportResultsWindow, setShowImportResultsWindow] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("")

  const [showImportWindow,setShowImportWindow] = useState<boolean>(false)
  const [sheetToImport,setSheetToImport] = useState<XLSX.Sheet>()
  const [defaultLayerName,setDefaultLayerName] = useState<string>("")
  const [defaultImportSelections,setDefaultImportSelections] = useState<any[]>()
  const [sheetAnalysisResult,setSheetAnalysisResult] = useState<SheetAnalysisResult>()

  const [fileStatus, setFileStatus] = useState<{[name:string]:{status:number, info:string}} | null>(null)


  useEffect(() => {
    if (props.fileImportOptions === null) {
      return
    }
    if (props.fileImportOptions.fileImportType === FileImportType.FileFetch) {
      if (props.fileImportOptions.url) {
        fetchFromUrl(props.fileImportOptions.url)
      } else {
        return 
      }
    }
    fileSizeImportHandler({}, true)
    return () => LoadingScreen.hide()
    props.finishCallback()
  }, [props.fileImportOptions])

  function fetchFromUrl(url: string) {
    try {
      restoreMapFromLink(
        {ShortLink:url},
      {
        callbackToCreateHierarchy: (layerHierarchy, layerRenumberingMap) => callbackToCreateHierarchy(layerHierarchy, layerRenumberingMap),
        callbackOnMapStyling: () => {},
        callbackOnLayerChanges: (a,layerInfo,b) => {
          const index = Math.round(Math.random() * 10000000) + 1000;
          layerInfo.layerId = index
          mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo, zoomToFocus:false}})
            return index
          },
        callbackOnViewerStateChanges: (viewerState:ViewerState) => {
          (mapitState.map.getMapPop() as maplibregl.Map).flyTo({
          duration: 1000,
          zoom: (viewerState.zoom || viewerState.zoomFactor) ?? 12,
          center: viewerState.center ? [viewerState.center.lng,viewerState.center.lat] : [0,0],
          pitch: viewerState.pitch ?? 0,
          bearing: viewerState.bearing ?? 0,
        })},
        callbackonNewRestrictions: (newRestrictions) => {
          console.log(newRestrictions)
          const map = mapitState.map.getMapPop() as maplibregl.Map
          newRestrictions.maxZoom && map.setMaxZoom(newRestrictions.maxZoom)
          newRestrictions.minZoom && map.setMinZoom(newRestrictions.minZoom)
          newRestrictions.bounds && map.setMaxBounds(newRestrictions.bounds)
          newRestrictions.maxPitch && map.setMaxPitch(newRestrictions.maxPitch)
          if (newRestrictions.allowRotation === false) {
            map.dragRotate.disable();
            map.touchZoomRotate.disableRotation();
          }
        },
        callbackOnActiveFeatureLayerSpecs : (a) => {mapitStateDispatch(actionAddExternalFeatureLayers(a))},
        callbackOnError: CallError,
        callbackExtras: (json) => {
          mapitStateDispatch(actionSelectBackgroundLayer(json.selectedBackgroundLayerKey))
          mapitState.map.once('idle', () => {
            mapitState.map.setBackgroundLayer(json.selectedBackgroundLayerKey);
          })
        }
      }
      )
    } catch (err: any) {
      CallError(err)
    }
  }

  function fileSizeImportHandler(evt: any, dropped: boolean) {
    if (!props.fileImportOptions?.files) {
      return 
    }
    // Todo: warning and user accept when importing multiple...
    let size = 0;
    Object.keys(props.fileImportOptions.files).forEach((elem) => {
      const currentFile:File = props.fileImportOptions!.files?.[elem];
      let pathfilename = currentFile.name;
      let filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
      let ext = filenameextension.split('.').pop()!.toLowerCase();
      if (ext === "jpg" || ext === "jpeg" || ext === "tiff") {
        return;
      }
      size += currentFile.size;
    });
    if (size > SettingsManager.getSystemSetting("importFilesizeWarningSize", 5500000) && SettingsManager.getSystemSetting("enableFileSizeWarning", true)) {
      setFilesizesTooBigDiaglog(1)
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.FileTooBig, true));
    } else {
      loadFiles(evt, dropped);
    }
  }

  // IMPORT:
  async function loadFiles(evt: any, dropped: boolean) {
    const timing = SettingsManager.getSystemSetting("timeBetweenLoadingMultipleFilesMS",0)
    if (!props.fileImportOptions?.files) {
      return 
    }
    let fileKeys = Object.keys(props.fileImportOptions.files)
    for (let i = 0; i <= fileKeys.length; i++) {
      const currentFile:File = props.fileImportOptions!.files?.[fileKeys[i]];
      if (currentFile) {  
        loadOneFile(currentFile, props.fileImportOptions?.fileImportType === FileImportType.Drop);
        await new Promise((a, b) => {
          setTimeout(() => a(1), timing)
        })

      } else {
        // Ignore dropping of other objects than files.
      }
    } 
  }

  function loadOneFile(currentFile: File, dropped: boolean) {
    try {
      let fileName = currentFile.name;
      LoadingScreen.show(Localization.getText("Loading..."))

      Logger.logAction("LayerList", "Import file " + fileName, dropped ? "dropped" : "filechooser");

      // Clear the "files" value such that the same file can be imported again/twice
      let e: HTMLElement | null = document.getElementById("files");
      if (e) {
        (e as HTMLInputElement).value = "";
      }

      // Choose import method base on file name
      let pathfilename = currentFile.name;
      let filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
      let filename = filenameextension.substring(0, filenameextension.lastIndexOf('.'));
      let ext = filenameextension.split('.').pop()!.toLowerCase();
      
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "tiff":
          return loadPicture(filenameextension, filename, ext, currentFile);
        case "geojson":
        case "json":
          return SettingsManager.getSystemSetting("enableImportOfGeoJSONFiles", false) && loadGeoJSON(filename, currentFile);
        case "estateexplorer":
        case "mapit":
          return loadMapitFile(currentFile);
        case "xls":
        case "xlsx":
          return loadExcelFile(currentFile, dropped);
        default :
          LoadingScreen.hide()
          props.finishCallback()
          Utils.createErrorEventObject(Localization.getFormattedText("Unexpected file extention {file}.", { file: filenameextension }));
      }
    } catch (error:any) {
      CallError(error.message);
      LoadingScreen.hide()
      props.finishCallback()
    }
  }

  let ShowFilesizeWindow = filesizesTooBigDiaglog && props.fileImportOptions ? (
    <ConfirmationDialog
      showWindow={windowToBeShownOrder(MapitWindowId.FileTooBig)}
      onCancel={(e) => {setFilesizesTooBigDiaglog(0)
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.FileTooBig, false));
      }
        
        }
      onSubmit={(e) => {
        setFilesizesTooBigDiaglog(0)
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.FileTooBig, false));
        loadFiles({},false)
      }}
      >
        <h4 style={{marginTop: "10px"}}>
          {Localization.getFormattedText("You are currently importing {size} of data", {size: () => {
            let size = 0;
            if (props.fileImportOptions?.files) {
              Object.keys(props.fileImportOptions?.files).forEach((elem) => {
                let currentFile:File = props.fileImportOptions?.files![elem];
                let pathfilename = currentFile.name;
                let filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
                let ext = filenameextension.split('.').pop()!.toLowerCase();
                if (ext === "jpg" || ext === "jpeg" || ext === "tiff") {
                  return;
                }
                size += currentFile.size;
              });
            }
            return FormatUtils.formatFileSize(size);
          }})}<br/>
          {Localization.getText("Cause you won't be able to generate link to the map")}<br/>
          {Localization.getText("Are you sure want you to continue")}<br/>
        </h4>
      </ConfirmationDialog>
  ) : null

  
function loadExcelFile(currentFile: File, dropped: boolean) {
  // IMPORT:

  // ToDo: Validate file and types, etc.
  let reader = new FileReader();
  reader.onload = () => {
    Localization.getText("Loading...")
    try {
      let data = reader.result;
      let workbook = XLSX.read(data, {
        type: 'binary',
        cellNF: true,
        cellDates: true
      });

      if (!(workbook && validateWorkBook(workbook))) {
        LoadingScreen.hide()
        props.finishCallback()
        throw Utils.createErrorEventObject("Error reading XLS file: " + currentFile);
      }
      // ToDo: Error handling if sheet not available

      let firstSheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[firstSheetName];

      // Validate Sheet
      if (!(worksheet && validateSheet(worksheet))) {
        throw Utils.createErrorEventObject("Error reading XLS sheet: " + firstSheetName);
      }

      // layer name is file name without extension
      let layerName = currentFile.name.replace(/\.[^/.]+$/, "");

      // See if we can bypass fieldselection and create the map automatically
      let sa: SheetAnalysisResult = generateSheetAnalysis(worksheet);

      let json = SheetFunc.sheetToJson(worksheet)
      if (sa) {
        sa.noOfRows = json.length
      }

      let pr: PredictionResult | null = PredictionLogic.predictImportSelections(sa, importMenuStructure);

      Logger.logInfo("LayerList", "Analyze Sheet", sa ? JSON.stringify(sa) : "No analysis result");
      let ua = SettingsManager.getSystemSetting("unattendedImport", false);

      let noUaForAddresses = SettingsManager.getSystemSetting("noUnattendedImportOfAddresses", true);
      if (ua && pr && pr.layerType === LayerType.GeoCode && noUaForAddresses) {
        ua = false;
      }

      // console.log(json)
      if (dropped && ua && pr) {
        // Unattended import

        const layerInfo: LayerInfo = {
          layerId: -1,
          datasetname: layerName,
          filename: currentFile.name,
          type: pr.layerType,
          readonly: false,
          columnMapping: pr.columnMapping,
          analysisResult: sa,
          styling: pr.layerType.includes("Area") ? { color: SettingsManager.getSystemSetting("areaMapDefaultFillColor", "teal"), lineOpacity: 0}:{},
          crs: pr.layerType === LayerType.PointUTM32 ? 25832 : 4326,
          layerVariability: LayerVariabilityType.NormalLayer,
        };

        Logger.logAction("LayerList", "Unattended Import", "Type " + pr.layerType);


        // // ERROR: Missing
        // this.setState({
        //   showWindow: true,
        //   showImportWindow: false,
        //   defaultLayerName: layerName,
        //   sheetToImport: worksheet,
        //   sheetAnalysisResult: sa
        // });

        onNewLayerImport(layerInfo, currentFile.name, worksheet);

      } else {
        // Preinstantiate choices if document type could be predicted
        if (pr) {

          Logger.logAction("LayerList", "Semi-manual Import", "Type " + pr.layerType);

            LoadingScreen.hide()
            setShowImportWindow(true)
            setDefaultLayerName(layerName)
            setFileName(currentFile.name)
            setSheetToImport(worksheet)
            setSheetAnalysisResult(sa)
            setDefaultImportSelections(pr.menuSelections)


        } else {

          Logger.logAction("LayerList", "Manual Import", "Type not recognized");

          LoadingScreen.hide()
          setShowImportWindow(true)
          setDefaultLayerName(layerName)
          setFileName(currentFile.name)
          setSheetAnalysisResult(sa)
          setSheetToImport(worksheet)
          
        }
      }
    } catch (error:any) {
      // Clear states
      // // ERROR: Missing
      // this.setState({
      //   showImportWindow: false,
      //   isImporting: false
      // });
      // LoadingScreen.hide();

      // Show error message
      CallError(error)
      LoadingScreen.hide()
      props.finishCallback()
      // appMessageDispatch(actionSetErrorMessage(error.message);
    }
  };
  reader.readAsBinaryString(currentFile);
}


function callbackToCreateHierarchy (layerHierarchy: LayerHierarchy, layerRenumberingMap: any): void {
  // Remove layers who have been assigned new ids.
  let oldGroupIdList = OldHierarchy.getAllGroupIds(mapitState.layerHierarchy);
  let newGroupIdList = OldHierarchy.getAllGroupIds(layerHierarchy);

  let lowestNumber = oldGroupIdList.length ? Math.min(...oldGroupIdList) : 0;

  let groupRenumberingMap = {};

  newGroupIdList.forEach((currentId, index) => { // Create group remapping object.
      groupRenumberingMap[currentId] = lowestNumber - index - 1;
  });

  // Assign new ids to both groups and layers.
  layerHierarchy = OldHierarchy.mapNumbersToIds(layerHierarchy, layerRenumberingMap, groupRenumberingMap);
  layerHierarchy = OldHierarchy.combineHierarchies(layerHierarchy, mapitState.layerHierarchy, layerRenumberingMap);

  mapitStateDispatch(actionSetLayerHierarchy(layerHierarchy));
}

  function loadPicture(fileNameExtension:string, fileName:string, extension:string, currentFile: File) {
    // Clear the "files" value such that the same file can be imported again/twice

    let e:HTMLElement | null = document.getElementById("loadjson");
    if (e) { 
      (e as HTMLInputElement).value="";
    }
    
    let reader = new FileReader();
    reader.onerror = (ex /*:FileReaderProgressEvent */) => {
      LoadingScreen.hide()
      props.finishCallback()
      throw Utils.createErrorEventObject(Localization.getFormattedText("Could not read file {file}. Lines read {number}",{file:currentFile.name,error:ex.loaded}));
    };
    reader.onload = async (ex) => {
      function addMinutes(date:Date, minutes:number) {
        return new Date(date.getTime() + minutes*60000);
      }
      try {

        let buffer = reader.result as ArrayBuffer;
        try {
          const data = ExifParserFactory.create(buffer).parse();
          // NOTE: Timestamp is in local time. Date expects UTC so we get double offset.
          // Adding the offset to correct this.
          let timeStampInLocalTime = new Date(data.tags!.DateTimeOriginal!*1000);
          let offset = timeStampInLocalTime.getTimezoneOffset();
          let ts = addMinutes(timeStampInLocalTime, offset).toLocaleString();
          ts === "Invalid Date" ? Localization.getText("No recorded date") : ts;

          if (data.tags && data.tags.GPSLatitude && data.tags.GPSLongitude) {
            LoadingScreen.showProgress(35)
            let AWSMetaData: { [key: string]: string } = {
              filename: encodeURIComponent(currentFile.name),
              lat: data.tags.GPSLatitude.toString(),
              lon: data.tags.GPSLongitude.toString(),
            };
            if(sessionState.userSession) {
              AWSMetaData.userId = sessionState.userRef;
              AWSMetaData.userName = encodeURIComponent(sessionState.userName);
              let userRef = sessionState.userRef;
              let customerRef = sessionState.customerRef;
              if (sessionState.userSession.getAccessToken()) {
              let persistenceLayer = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket", ""));
              const fileNameSplit = currentFile.name.split(".");
              const fileExtension = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : "";
              let objectRef: ObjectRef = persistenceLayer.generateUniqueObjectRef(fileExtension);
              customerRef && persistenceLayer.uploadObject(PersistenceScope.User, PersistenceObjectType.Image, customerRef, userRef, currentFile, objectRef, AWSMetaData, true)
                  .then(awsData => {
                    LoadingScreen.showProgress(50)
                    let exifData: { lat: number, lon: number, timestamp: string, imageType: string, img: string } = {
                      lat: data.tags!.GPSLatitude!,
                      lon: data.tags!.GPSLongitude!,
                      timestamp: ts,
                      imageType: extension,
                      img: (awsData as any).Location
                    };
                    let layerName = fileName;
                    let layerInfo = LayerFunc.createLayerInfoForOnePhoto(layerName, fileNameExtension, exifData);
                    let countLoaded = 1;
                    mapitStateDispatch(actionAddDataLayer(layerInfo, true));
                    LoadingScreen.hide()
                    props.finishCallback()
                    appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Successfully loaded {count} elements from {file}", { count: countLoaded, file: currentFile.name })));
                  })
                  .catch(error => {
                    // Show error message
                    LoadingScreen.hide()
                    props.finishCallback()
                    appMessageDispatch(actionClearInfoMessage());
                    appMessageDispatch(actionSetErrorMessage(Localization.getText("Failed to upload image. Please contact support if this issue persists.")));
                  });
              }
          }

          } else {
            LoadingScreen.hide()
            props.finishCallback()
            throw Utils.createErrorEventObject(Localization.getText("Image contains no coordinates and cannot be mapped"));
          }

        } catch (ex:any) {
          LoadingScreen.hide()
          props.finishCallback()
          throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Not correct file format {error}",{file:currentFile.name,error:ex.message}));
        }

      } catch (error:any) {
        // Show error message
        // appMessageDispatch(actionClearInfoMessage(;
        LoadingScreen.hide()
        props.finishCallback()
        CallError(error);
      }
    };
    LoadingScreen.show(Localization.getText("Loading..."))
    reader.readAsArrayBuffer(currentFile);
    // props.finishCallback()
  }

  // ------------------------------------------------------------------------------
function loadGeoJSON(fileName: string, currentFile: File) {
    // Clear the "files" value such that the same file can be imported again/twice
    let e: HTMLElement | null = document.getElementById("loadjson");
    if (e) {
      (e as HTMLInputElement).value = "";
    }
    // appMessageDispatch(actionSetInfoMessage(Localization.getText("Loading..."));

    let reader = new FileReader();
    reader.onerror = (ex /*:FileReaderProgressEvent */) => {
      LoadingScreen.hide()
      props.finishCallback()
      throw Utils.createErrorEventObject(Localization.getFormattedText("Could not read file {file}. Lines read {number}", { file: currentFile.name, error: ex.loaded }));
    };
    reader.onload = () => {
      LoadingScreen.showProgress(25)
      try {
        let json = reader.result;
        let ls;
        try {
          ls = json && JSON.parse(json.toString());
          LoadingScreen.showProgress(60)
        } catch (ex) {
          LoadingScreen.hide()
          props.finishCallback()
          throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Not correct file format {error}", { file: currentFile.name, error: (ex as Error).message }));
        }
        let currentDate = new Date();
        let countLoaded = Persistence.createLayersFromGeoJSON(
          fileName,
          ls,
          currentDate,
          (layerInfo) => {
            const index = Math.round(Math.random() * 10000000) + 1000;
            layerInfo.layerId = index
            mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo, zoomToFocus:true}})
              return index
            },
          );
          LoadingScreen.hide()
          props.finishCallback()
        appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Successfully loaded {count} elements from {file}", { count: countLoaded, file: currentFile.name })));

      } catch (error : any) {
        // Show error message
        // appMessageDispatch(actionClearInfoMessage(;
        LoadingScreen.hide()
        props.finishCallback()
        CallError(error);
      }
    };
    LoadingScreen.show(Localization.getText("Loading..."))
    reader.readAsText(currentFile);
    // props.finishCallback()
  }

  // ------------------------------------------------------------------------------

  function loadMapitFile(currentFile: File) {
    
    const reader = new FileReader();
    reader.onerror = (ex /*:FileReaderProgressEvent */) => {
      LoadingScreen.hide()
      props.finishCallback()
      throw Utils.createErrorEventObject(Localization.getFormattedText("Could not read file {file}. Lines read {number}", { file: currentFile.name, error: ex.loaded }));
    };
    reader.onload = async () => {
      LoadingScreen.showProgress(25)
      try {

        let json = reader.result;
        let ls;

        try {
          ls = json && Utils.parseJSONPreserveDates(json.toString());
        } catch (ex) {
          throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Not correct file format {error}", { file: currentFile.name, error: (ex as Error).message }));
        }
        let currentDate = new Date();

        // ERROR:
        mapitStateDispatch(actionSelectBackgroundLayer(ls.selectedBackgroundLayerKey))
        mapitState.map.once('idle', () => {
          mapitState.map.setBackgroundLayer(ls.selectedBackgroundLayerKey);
        })

        let countLoaded = await Persistence.createLayersFromJSON(
          ls,
          currentDate,
          (layerHierarchy, layerRenumberingMap) => callbackToCreateHierarchy(layerHierarchy, layerRenumberingMap),
          (mapInfo) => mapitStateDispatch(actionUpdateMapInfo(mapInfo)), // TODO:
          (layerInfo) => {
            const index = Math.round(Math.random() * 10000000) + 1000;
            layerInfo.layerId = index
            layerInfo.hasNoDataBehind = true
            mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo, zoomToFocus:false}})
              return index
            },
            (viewerState:ViewerState) => {
              (mapitState.map.getMapPop() as maplibregl.Map).flyTo({
              duration: 1000,
              zoom: (viewerState.zoom || viewerState.zoomFactor) ?? 12,
              center: viewerState.center ? [viewerState.center.lng,viewerState.center.lat] : [0,0],
              pitch: viewerState.pitch ?? 0,
              bearing: viewerState.bearing ?? 0,
            })},
        ).catch((err) => {throw err})
        // Success create onfo popup.
        appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Successfully loaded {count} layers from {file}", { count: countLoaded, file: currentFile.name })));
        LoadingScreen.hide()
        props.finishCallback()
      } catch (error: any) {
        
        LoadingScreen.hide()
        props.finishCallback()
        CallError(error);
      }
    };
    LoadingScreen.show(Localization.getText("Loading..."))
    reader.readAsText(currentFile);
  }

  function addNewImportedLayer(layerInfo: LayerInfo) {
    const index = Math.round(Math.random() * 10000000) + 1000;
    layerInfo.layerId = index;

    if (MapitUtils.isAreaLayerRequireingGeoData(layerInfo.type) && (!ReferenceGeomComposite.isRequiredGeomDataLoaded(layerInfo.type))) {
      // Load required geo data
      ReferenceGeomComposite.ensureRequiredGeomData(layerInfo.type)
      .then(data => {
        // loopback then loading is done.

        layerInfo.geometryReferenceFromMapit = 
        {
          geoType: layerInfo.type
        }
        // convert 'data' to geoJSON
        let geo = MapitLayerFactory.getGeometryFromLayerInfo(layerInfo);

        layerInfo.propertiesInGeoJson = Object.keys(geo.features.find((a) => a.properties["VIAMAP_NO_DATA_FOR_AREA"] == undefined)?.properties ?? {})
        layerInfo.geoJson = geo;
        layerInfo.type = LayerType.GeoJSON;

        mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo, zoomToFocus:true}})
      })
      .catch(error => { 
        throw new Error("Loading failed "+(error.message || error))
      })
    } else {
      // convert 'data' to geoJSON
      if (MapitUtils.isAreaLayerRequireingGeoData(layerInfo.type)) {
          layerInfo.geometryReferenceFromMapit = 
          {
            geoType: layerInfo.type
          }
      }
      let geo = MapitLayerFactory.getGeometryFromLayerInfo(layerInfo);
      layerInfo.propertiesInGeoJson = Object.keys(geo.features.find((a) => a.properties["VIAMAP_NO_DATA_FOR_AREA"] == undefined)?.properties ?? {})
      layerInfo.geoJson = geo;
      layerInfo.type = LayerType.GeoJSON;
  
      mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo, zoomToFocus:true}})
    }
  }

    
  function onNewLayerImport(layerInfo: LayerInfo, name:string, worksheet?:any) {
    // IMPORT:
    let sheet = worksheet ?? sheetToImport // ?? this.state.sheetToImport;
    try {
      
      LoadingScreen.show(Localization.getText("Loading..."))
      LoadingScreen.showProgress(5)

      // Automatically switch on clustering for large data sets
      var da: ExcelSheetDataAccessor = new ExcelSheetDataAccessor(sheet);
      let noOfRows = da.getRowMax();
      let limit = SettingsManager.getSystemSetting("noOfRowsToTriggerDefaultClustering", 501);
      if (noOfRows > limit && !MapitUtils.isAreaLayer(layerInfo.type)) {
        layerInfo = { ...layerInfo, styling: { ...layerInfo.styling, useClustering: true } };
      }

      // Load the data for the columns of the sheet which is (to be) shown on the map
      let dataValues = copyNeededDataValues(layerInfo.columnMapping, sheet);

      switch (layerInfo.type) {

      // Perform geocoding if needed
      case LayerType.GeoCode: {
        // Check that the data set is not too big for geocoding - in a reasonable time
        let limitGeo = SettingsManager.getSystemSetting("importMaxNumberOfGeoCodings", 1000);
        let rows = layerInfo.analysisResult!.noOfRows - 1; // header row is not an address
        if (rows > limitGeo) {
          throw Utils.createErrorEventObject(Localization.getFormattedText("This version is limited to {limit} adresses. {rows} found.", { limit: limitGeo, rows: rows }));
        }

        // Do any adress lookups
        if (layerInfo.columnMapping && layerInfo.columnMapping[MitDataType.Address]) {
          // create address lookup column
          //  and concatenate if more than one adress column is used
          let seperator = SettingsManager.getSystemSetting("addressElementWashSeparator", ", ");
          let lookup: string[] = Array<string>();
          for (let i = 0; i < dataValues[MitDataType.Address].length; i++) {
            let adr = dataValues[MitDataType.Address][i];
            adr += dataValues[MitDataType.Address2] ? seperator + dataValues[MitDataType.Address2][i] : "";
            adr += dataValues[MitDataType.Address3] ? seperator + dataValues[MitDataType.Address3][i] : "";
            adr += dataValues[MitDataType.Address4] ? seperator + dataValues[MitDataType.Address4][i] : "";
            lookup.push(adr);
          }
          dataValues = { ...dataValues, [MitDataType.AddressesConcatenated]: lookup };
        }

        if (dataValues[MitDataType.AddressesConcatenated]) {
          let numberOfAddresses = dataValues[MitDataType.AddressesConcatenated].length;
          LoadingScreen.show(Localization.getFormattedText("Geocoding {noOf} addresses...", { noOf: numberOfAddresses }));
          // this.setState({ showImportResultsWindow: false, noGeoCodeStarted: 0, noGeoCodeCompleted: 0 });
          LoadingScreen.showProgress(10)
          AddressInterface.geocode(
            dataValues[MitDataType.AddressesConcatenated],
            (response: DawaAddressResult[]) => {
              let lat: number[] = [];
              let lon: number[] = [];
              let washed: string[] = [];
              let dawaquality: DawaQuality[] = [];

              // Note that the response array may have 'holes'. But the idx is the right index.
              response && response.map((obj, idx) => {
                // ToDo: error handling if coordinates are out of bounds
                lat[idx] = (obj.latlng ? obj.latlng[0] : -1);
                lon[idx] = (obj.latlng ? obj.latlng[1] : -1);

                // Save quality data
                washed[idx] = (obj.washed ? obj.washed : (obj.errormessage ? obj.errormessage : ""));
                dawaquality[idx] = (obj.quality);
              });
              // Map Dawa Quality to RowDataQuality flags.
              let { rowDataQuality, rowDataQualityNote } = ImportDataProcessor.evalDawaQuality(dawaquality);
              dataValues = {
                ...dataValues, [MitDataType.Coord_WGS84_Lat]: lat, [MitDataType.Coord_WGS84_Lon]: lon,
                [MitDataType.GeoCode_Result_Address]: washed, [MitDataType.GeoCode_Result_Quality]: dawaquality,
                [MitDataType.Row_Data_Quality]: rowDataQuality, [MitDataType.Row_Data_Quality_Note]: rowDataQualityNote
              };
              layerInfo.type = LayerType.PointWGS84;
              // Remove any datamappings Coord_WGS84_Lat or Coord_WGS84_Lon...
              //  such that any lat/lon in the sheets are not used instead of the geocoded.
              delete layerInfo.columnMapping[MitDataType.Coord_WGS84_Lat];
              delete layerInfo.columnMapping[MitDataType.Coord_WGS84_Lon];
              let layerInfoNew2: LayerInfo = { ...layerInfo, datasheet: sheet, data: dataValues };
              addNewImportedLayer(layerInfoNew2);
              LoadingScreen.hide()
              props.finishCallback()

              if (SettingsManager.getSystemSetting("showImportDetailsWindow", false)) {
                // Show results details
                
                setShowImportResultDetailsWindow(true)
                setImportResultDetailsWindowLayerInfo(layerInfoNew2)
              }
            },
            (noGeoCodeStarted: number) => {
              // this.setState({ showImportResultsWindow: false, noGeoCodeStarted: noGeoCodeStarted });
            },
            (noGeoCodeCompleted: number) => {
              // this.setState({ showImportResultsWindow: false, noGeoCodeCompleted: noGeoCodeCompleted, geoCodeStats: stats });
              // let prog = 100 * noGeoCodeCompleted / numberOfAddresses;
              // LoadingScreen.showProgress(prog);
              LoadingScreen.showProgress(10 + Math.round(noGeoCodeCompleted / numberOfAddresses * 80))
      
            });
        }
        break;
      }
      case LayerType.PointCadaster: 
      case LayerType.AreaCadaster: 
      case LayerType.AreaProperty: {
          // Check that the data set is not too big for geocoding - in a reasonable time
          let limitGeo = SettingsManager.getSystemSetting("importMaxNumberOfGeoCodings", 1000);
          let rows = layerInfo.analysisResult!.noOfRows - 1; // header row is not an address
          if (rows > limitGeo) {
            throw Utils.createErrorEventObject(Localization.getFormattedText("This version is limited to {limit} cadasters. {rows} found.", { limit: limitGeo, rows: rows }));
          }
          try {
            if (dataValues[MitDataType.Ejerlavskode] && dataValues[MitDataType.MatrikelNr]) {
              // do geocoding
              let numberOfElements = dataValues[MitDataType.Ejerlavskode].length;
              LoadingScreen.show(Localization.getFormattedText("Geocoding {noOf} cadasters...", { noOf: numberOfElements }));
              // this.setState({ showImportResultsWindow: false, noGeoCodeStarted: 0, noGeoCodeCompleted: 0 });
              CadasterInterface.geocode(
                dataValues[MitDataType.Ejerlavskode],
                dataValues[MitDataType.MatrikelNr],
                (layerInfo.type === LayerType.AreaCadaster) || (layerInfo.type === LayerType.AreaProperty),
                layerInfo.type === LayerType.AreaProperty,
                (response: CadasterGeoCodeResult[]) => {
                  let lat: number[] = [];
                  let lon: number[] = [];
                  let easting: number[] = [];
                  let northing: number[] = [];
                  let dataquality: RowDataQuality[] = [];
                  let dataqualityNote: string[] = [];
                  let input: string[] = [];
                  let sfeejendomsnr: number[] = [];
                  let geoJson: string[] = [];

                  // Note that the response array may have 'holes'. But the idx is the right index.
                  response && response.map((obj, idx) => {
                    lat[idx] = obj.lat;
                    lon[idx] = obj.lon;
                    easting[idx] = obj.easting;
                    northing[idx] = obj.northing;
                    dataquality[idx] = obj.dataquality;
                    dataqualityNote[idx] = obj.dataqualityNote;
                    input[idx] = obj.input;
                    sfeejendomsnr[idx] = obj.sfeejendomsnr;
                    geoJson[idx] = obj.geoJson;
                  });
                  if (layerInfo.type === LayerType.AreaCadaster) {
                    layerInfo.type = LayerType.AreaCadaster;
                    dataValues = {
                      ...dataValues, [MitDataType.Coord_WGS84_Lat]: lat, [MitDataType.Coord_WGS84_Lon]: lon,
                      [MitDataType.GeoCode_Result_Address]: dataqualityNote, [MitDataType.GeoCode_Result_Quality]: dataquality,
                      [MitDataType.Row_Data_Quality]: dataquality, [MitDataType.Row_Data_Quality_Note]: dataqualityNote,
                      [MitDataType.Coord_UTM32_X]: easting, [MitDataType.Coord_UTM32_Y]: northing,
                      [MitDataType.GeoJSON_Features]: geoJson, [MitDataType.SfeEjendomsNr]: sfeejendomsnr,
                      [MitDataType.AddressesConcatenated]: input
                    };
                  } else {
                    if (layerInfo.type === LayerType.AreaProperty) {
                      layerInfo.type = LayerType.AreaProperty;
                      dataValues = {
                        ...dataValues, [MitDataType.Coord_WGS84_Lat]: lat, [MitDataType.Coord_WGS84_Lon]: lon,
                        [MitDataType.GeoCode_Result_Address]: dataqualityNote, [MitDataType.GeoCode_Result_Quality]: dataquality,
                        [MitDataType.Row_Data_Quality]: dataquality, [MitDataType.Row_Data_Quality_Note]: dataqualityNote,
                        [MitDataType.Coord_UTM32_X]: easting, [MitDataType.Coord_UTM32_Y]: northing,
                        [MitDataType.GeoJSON_Features]: geoJson, [MitDataType.SfeEjendomsNr]: sfeejendomsnr,
                        [MitDataType.AddressesConcatenated]: input
                      };
                    } else {
                    layerInfo.type = LayerType.PointWGS84;
                    dataValues = {
                       ...dataValues, [MitDataType.Coord_WGS84_Lat]: lat, [MitDataType.Coord_WGS84_Lon]: lon,
                      [MitDataType.GeoCode_Result_Address]: dataqualityNote, [MitDataType.GeoCode_Result_Quality]: dataquality,
                      [MitDataType.Row_Data_Quality]: dataquality, [MitDataType.Row_Data_Quality_Note]: dataqualityNote,
                      [MitDataType.Coord_UTM32_X]: easting, [MitDataType.Coord_UTM32_Y]: northing,
                      [MitDataType.AddressesConcatenated]: input
                    };
                  }
                }
                  // Remove any datamappings Coord_WGS84_Lat or Coord_WGS84_Lon...
                  //  such that any lat/lon in the sheets are not used instead of the geocoded.
                  delete layerInfo.columnMapping[MitDataType.Coord_WGS84_Lat];
                  delete layerInfo.columnMapping[MitDataType.Coord_WGS84_Lon];

                  let layerInfoNew2: LayerInfo = { ...layerInfo, datasheet: sheet, data: dataValues };
                  addNewImportedLayer(layerInfoNew2);
                  LoadingScreen.hide()
                  props.finishCallback()
                  if (SettingsManager.getSystemSetting("showImportDetailsWindow", false)) {
                    // Show results details
                    setShowImportResultDetailsWindow(true)
                    setImportResultDetailsWindowLayerInfo(layerInfoNew2)
                    // this.setState({ showImportResultDetailsWindow: true, importResultDetailsWindowLayerInfo: layerInfoNew2 });
                  }
                },
                (noGeoCodeStarted: number) => {
                  // this.setState({ showImportResultsWindow: false, noGeoCodeStarted: noGeoCodeStarted });
                },
                (noGeoCodeCompleted: number) => {
                  LoadingScreen.showProgress(noGeoCodeCompleted / numberOfElements * 80)
                  // this.setState({ showImportResultsWindow: false, noGeoCodeCompleted: noGeoCodeCompleted, geoCodeStats: stats });
                });
                
              }
              
            } catch (error:any) {
              LoadingScreen.hide()
              props.finishCallback()
              CallError(error);
          }
          break;
          } 
          
          default: {
          // Validate that the key fields are valid.
          let { rowDataQuality, rowDataQualityNote } = ImportDataProcessor.evaluateRowDataQuality({ ...layerInfo, data: dataValues });
          dataValues = { ...dataValues, [MitDataType.Row_Data_Quality]: rowDataQuality, [MitDataType.Row_Data_Quality_Note]: rowDataQualityNote };
          let layerInfoNew2: LayerInfo = { ...layerInfo, datasheet: sheet, data: dataValues };
          addNewImportedLayer(layerInfoNew2);
          LoadingScreen.hide()
          props.finishCallback()
          let hasError: boolean = Boolean(rowDataQuality.find((val) => val !== RowDataQuality.Good));
          if (SettingsManager.getSystemSetting("showImportDetailsWindow", false)
            && hasError) {
            // Show results details
            setShowImportResultDetailsWindow(true)
            setImportResultDetailsWindowLayerInfo(layerInfoNew2)
          } else {
            props.finishCallback()
          }
        }
      }
      // this.setState({ showImportWindow: false });
    } catch (error: any) {
      // Clean up
      // this.setState({ showImportWindow: false });
      // Display the error
      LoadingScreen.hide()
      props.finishCallback()
      CallError(error);
    }
  }


  return (
    <>
    {ShowFilesizeWindow}
    {/* IMPORT: */}
    <MultiLoading loadingStatus={fileStatus} />
    {showImportWindow ? 
    <LayerImport
      showWindow={showImportWindow}
      sheetToImport={sheetToImport}
      datasetname={defaultLayerName || ""}
      onFormSubmit={(l: LayerInfo) => {onNewLayerImport(l, fileName); setShowImportWindow(false)}}
      onFormCancel={(l: LayerInfo) => {setShowImportWindow(false); props.finishCallback()}}
      defaultSelections={defaultImportSelections}
      analysisResult={sheetAnalysisResult}
    /> : null
    }
    {/* IMPORT: */}
    <ImportResults
      showWindow={showImportResultsWindow}
      onFormClose={() => {setShowImportResultsWindow(false); props.finishCallback()}}
      noOfStarted={0}
      noOfCompleted={0}
      stats={{ "nodata": 0 }}
    />
    {/* IMPORT: */}
    <ImportResultDetails
      showWindow={showImportResultDetailsWindow}
      onFormClose={() => {setShowImportResultDetailsWindow(false); props.finishCallback()}}
      layerInfo={importResultDetailsWindowLayerInfo!}
      dataUpdate={(a) => setImportResultDetailsWindowLayerInfo((value) => ({...value!, "data": a}))}
    />
    </>
  )
}

function MultiLoading(props:{loadingStatus:{[filename:string]:{status:number, info:string}} | null}) {
  
  if (props.loadingStatus === null) {
    return null
  }

  if (Object.keys(props.loadingStatus).filter((a) => props.loadingStatus![a].status !== 100 && props.loadingStatus![a].status !== -1 ).length === 0) {
    return null
  }

  return (<div style={{position:"absolute", top:"5%",left:"5%", background:"white", zIndex:10000}}>
    {Object.keys(props.loadingStatus).sort().map((a) => {
      const elem = props.loadingStatus![a];
      return (
        <div key={a} > 
          <div>{a} : {elem.info}</div>
          <div style={{width:"200px", background:"grey"}}><div style={{background:"green",width:(2*elem.status)+"px", height:"12px"}}></div></div>
        </div>
      )
    })}
  </div>)
} 


function generateSheetAnalysis(sheet: any): SheetAnalysisResult {
  try {
    let analysisResult = Parser.analyzeInputSheet(sheet);
    return (analysisResult);
  } catch (e:any) {
    throw Utils.createErrorEventObject("LayerImport" + " analyze sheet " + e.message);
  }
}

function validateWorkBook(workbook: XLSX.WorkBook) {
  return workbook.SheetNames.length > 0;
}

function validateSheet(sheet: XLSX.WorkSheet) {
  var da: ExcelSheetDataAccessor = new ExcelSheetDataAccessor(sheet);

  const noOfColumns: number = (1 + da.getColumnMax() - da.getColumnMin());
  const noOfRows: number = (1 + da.getRowMax() - da.getRowMin());
  let limit = SettingsManager.getSystemSetting("importMinNumberOfColumns", 2);
  if (noOfColumns < limit) {
    throw Utils.createErrorEventObject(Localization.getFormattedText("Too few columns", { count: noOfColumns, limit: limit }));
  }
  limit = SettingsManager.getSystemSetting("importMaxNumberOfColumns", 30);
  if (noOfColumns > limit) {
    throw Utils.createErrorEventObject(Localization.getFormattedText("Too many columns", { count: noOfColumns, limit: limit }));
  }
  limit = SettingsManager.getSystemSetting("importMinNumberOfRows", 2);
  if (noOfRows < limit) {
    throw Utils.createErrorEventObject(Localization.getFormattedText("Too few rows", { count: noOfRows, limit: limit }));
  }
  limit = SettingsManager.getSystemSetting("importMaxNumberOfRows", 2000);
  if (noOfRows > limit) {
    throw Utils.createErrorEventObject(Localization.getFormattedText("Too many rows", { count: noOfRows, limit: limit }));
  }

  // Check that first row contains a header
  let row = 0;
  for (let col = 0; col < noOfColumns; col++) {
    let { val } = da.getCellValue(col, row);
    if (val === undefined || val === null || val === "") {
      let colLetter = Utils.toColumnNameZeroBased(col);
      throw Utils.createErrorEventObject(Localization.getFormattedText("Empty cell in header row. Col: {col}", { col: colLetter }));
    }
  }
  return true;
}

  // IMPORT:
  function _copyNeededDataValues(columnMapping: ColumnMapping, columnAccessorFunc: (column: number, m: MitDataType) => any[]) {
    let dataValues = {};

    columnMapping &&
      Object.keys(columnMapping).forEach((c: string) => {
        let m = c as MitDataType;
        let column = columnMapping[m];
        // ToDo: optimization: don't copy columns already there
        // ToDo: Create a flag for geocoded lat/lon such that they will not be overwritten
        let dataArr = columnAccessorFunc(column, m);
        dataValues = { ...dataValues, [m]: dataArr };
      });

    return dataValues;
  }

    // IMPORT:
function copyNeededDataValues(columnMapping: ColumnMapping, sheet: any) {
      return _copyNeededDataValues(columnMapping, (column: number, m: MitDataType) => {
        return SheetFunc.getColumn(sheet, column, m);
      });
    }
  

