import React from "react";

type inputProps<T> = {
   value: T;
   onChange: (val: T) => any;
};

/**
 * onChange is called with the value, 250ms after last change to prevent excessive callbacks.
 * @param props 
 * @returns A touch friendly Slider
 */
export function Slider(
   props: inputProps<number> & { min: number; max: number; step?: number }
) {
   let [curVal, setCurVal] = React.useState(props.value);
   let valRef = React.useRef(props.value);
   let timerRef = React.useRef<NodeJS.Timeout | null>(null);
   
   function SetDelayedVal(val: number) {
      setCurVal(val);
      valRef.current = val;
      if (timerRef.current == null) {
         timerRef.current = setTimeout(() => {
            props.onChange(valRef.current);
            timerRef.current = null;
         }, 250);
      }
   }
   
   let step = props.step ?? "0.5";
   return (
      <input
      type="range"
      value={curVal}
      onChange={(evt) =>
         SetDelayedVal(parseFloat(evt.currentTarget.value) ?? props.min)
      }
      min={props.min}
      max={props.max}
      step={step}
      />
   );
}

export function OpacityAdjuster(props: inputProps<number>) {
   return (
      <Slider
      min={0}
      max={1}
      step={0.01}
      value={props.value}
      onChange={props.onChange}
      />
   );
}