import * as React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import ReactDOM from "react-dom";
import { MitDialog, MitBody, MitInfo } from "./ComponentUtils";

/**
 * Component for displaying status when loading data files.
 */
type Props = {
  progressPct?: number;
  progressMessage?: string;
  visible?: boolean;
};



 function LoadingScreen(props: Props) {
  const [state, setState] = React.useState<Props>(props);

  function RenderProgressBar() {
    const now = state.progressPct || 0;
    return (
      <ProgressBar now={now} label={`${now}%`} visuallyHidden={true} style={{borderRadius:"0px", ...{"--bs-progress-bar-bg":"#3ae580", "--bs-progress-height":"2rem"}}} />
    );
  }

  React.useEffect(() => {
    const show = (e: any) => {
      let message: string | undefined = e.detail;
      setState((pre) => {
        return {
          ...pre,
          visible: true,
          progressMessage: message,
          progressPct: -1,
        };
      });
    };

    const hide = (e) => {
      setState((pre) => {
        return { ...pre, visible: false };
      });
    };

    const progress = (e: any) => {
      setState((pre) => {
        return { ...pre, progressPct: e.detail, visible: true };
      });
    };

    document.body.addEventListener("mit-loading-show", show);
    document.body.addEventListener("mit-loading-hide", hide);
    document.body.addEventListener("mit-loading-progress", progress);
    return () => {
      document.body.removeEventListener("mit-loading-show", show);
      document.body.removeEventListener("mit-loading-hide", hide);
      document.body.removeEventListener("mit-loading-progress", progress);
    };
  }, []);

  if (state.visible) {
    return ReactDOM.createPortal(
          <MitDialog show={999999} style={{minWidth:"350px", maxWidth:"100%", width:"50%", top:"15px", left:"50%", transform:"translateX(-50%)", minHeight:"50px"}}>
            <MitBody style={{paddingBottom:"0"}}>
              <MitInfo>
              {state.progressMessage}
              </MitInfo>
            </MitBody>
              {RenderProgressBar()}
          </MitDialog>
    ,document.getElementById("Mit-MapOverlay") || document.body);
  }

  return null;
}

LoadingScreen.show = show
LoadingScreen.hide = hide
LoadingScreen.showProgress = showProgress


function show(message: string) {
    document.body.dispatchEvent(
      new CustomEvent("mit-loading-show", { detail: message })
    );
  }

 function hide() {
    document.body.dispatchEvent(new Event("mit-loading-hide"));
  }

 function showProgress(progressPct: number) {
    document.body.dispatchEvent(
      new CustomEvent("mit-loading-progress", { detail: progressPct })
    );
}

export default LoadingScreen