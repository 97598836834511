import { LayerHierarchy, LayerHierarchyEndNode } from "src/common/managers/Types";

export const OldHierarchy = {
  getParentGroup:getParentGroup,
  setAllGroupsReadonly:setAllGroupsReadonly,
  combineHierarchies:combineHierarchies,
  getAllGroupIds:getAllGroupIds,
  extractLowestId:extractLowestId,
  mapNumbersToIds:mapNumbersToIds,
  removeLayerInHierarchy:removeLayerInHierarchy,
  elementExistsInHierarchy:elementExistsInHierarchy,
  getAllIdOrderedTopBottom:getAllIdOrderedTopBottom,
}

/**
   * Searches for the parent of an element in a given layer hierarchy.
   * @param id Id of the element you wish to find the parent of.
   * @param layerHierarchy The layer hierarchy to search.
   * @returns The LayerHierarchy parent of the element.
   */
export function getParentGroup(id: number, layerHierarchy: LayerHierarchy): LayerHierarchy | undefined {
  let matchFound = layerHierarchy.children.some((element) => {
    if (id === element.id) {
      return true;
    }
    if ("isCollapsed" in element) {
      let parent = getParentGroup(id, element);
      layerHierarchy = parent ? parent : layerHierarchy;
      return Boolean(parent);
    }
    return false;
  });
  return matchFound ? layerHierarchy : undefined;
}

/**
 * Sets the readonly property of all groups in a layer hierarchy.
 * @param readOnly The readonly value to set all groups to.
 * @param layerHierarchy The layer hierarchy to change.
 * @returns The changed layer hierarchy.
 */
export function setAllGroupsReadonly(readOnly: boolean, layerHierarchy: LayerHierarchy): LayerHierarchy {
  let newChildren = layerHierarchy.children.map(element => {
    if ("isCollapsed" in element) {
      let newElement = { ...element, isReadonly: readOnly };
      // element.isReadonly = readOnly;
      return setAllGroupsReadonly(readOnly, newElement);
    } else {
      return element;
    }
  });
  layerHierarchy.children = newChildren;
  return layerHierarchy;
}

/**
 * Removes all top level elements that are not to be renumbered from layerHierarchy2.
 * Then concatenates layerHierarchy2 and layerHierarchy1's children together, 
 * resulting in a top level hierarchy combined of the two hierarchies.
 * @param jsonHierarchy First layerHierarchy. (LayerHierarchy)
 * @param stateHierarchy Second layerHierarchy. (State.LayerHierarchy)
 * @param layerRenumberingMap Map of which layers are to be renumbered.
 * @returns The combined layer hierarchy.
 */
export function combineHierarchies(jsonHierarchy: LayerHierarchy, stateHierarchy: LayerHierarchy, layerRenumberingMap: { [key: string]: number }): LayerHierarchy {

  stateHierarchy.children = stateHierarchy.children.filter((element) => {
    return !Object.values(layerRenumberingMap).includes(element.id); // Return elements that are not renumbered
  });

  jsonHierarchy.children = stateHierarchy.children.concat(jsonHierarchy.children);

  return jsonHierarchy;
}

export function getAllIdOrderedTopBottom(layerHierarchy: LayerHierarchy): number[] {
  return layerHierarchy.children.filter((a) => typeof a.id === "number").flatMap((child) => {
    if (child.id > 0) {
      return child.id
    }
    return getAllIdOrderedTopBottom(child as LayerHierarchy)
  })
}

/**
 * Returns a list of all group ids contained in a layer hierarchy.
 * @param layerHierarchy The layerhierarchy to get group ids from.
 * @returns A list of each group id in the hierarchy.
 */
export function getAllGroupIds(layerHierarchy: LayerHierarchy): number[] {
  let childGroupIds: number[] = [];
  layerHierarchy.children.forEach((element) => {
    if ("isCollapsed" in element) {
      childGroupIds = childGroupIds.concat(getAllGroupIds(element));
      childGroupIds.push(element.id);
    }
  });
  return childGroupIds;
}

/**
 * Finds the lowest id in a layer hierarchy. Group id's are negative so therefore a group id is always returned.
 * @param layerHierarchy The layer hierarchy to extract the lowest id from.
 * @returns Number of the lowest id.
 */
export function extractLowestId(layerHierarchy: LayerHierarchy): number {
  let lowest = layerHierarchy.id;
  if (layerHierarchy.name && layerHierarchy.children) {
    lowest = layerHierarchy.children.reduce((prev: number, current: LayerHierarchy | LayerHierarchyEndNode) => {
      let res;
      if ((current as LayerHierarchy).name) {
        res = extractLowestId(current as LayerHierarchy);
      } else {
        res = current.id;
      }
      return Math.min(prev, res);
      // tslint:disable-next-line: align
    }, lowest);
  }
  return lowest;
}

/**
 * Applies an ID transformation to each subelement of a layer hierarchy based on the given mapping objects.
 * @param layerHierarchy The layerhierarchy to apply the transformation on.
 * @param newLayerIdsMap The mapping object of which layer ID's to transform from and to. Layer ID's are positive integers.
 * @param newGroupIdsMap The mapping object of which group ID's to transform from and to. Group ID's are negative integers.
 * @returns The layerhierarchy with updated ID's.
 */
export function mapNumbersToIds(layerHierarchy: LayerHierarchy, newLayerIdsMap: { [key: string]: number }, newGroupIdsMap: { [key: string]: number }): LayerHierarchy {

  let newIdChildren = layerHierarchy.children.map((element) => {
    if ("isCollapsed" in element) {
      if (newGroupIdsMap[element.id]) {
        element.id = newGroupIdsMap[element.id];
      }
      return mapNumbersToIds(element, newLayerIdsMap, newGroupIdsMap);
    } else {
      if (newLayerIdsMap[element.id]) {
        element.id = newLayerIdsMap[element.id];
      }
      return element;
    }
  });
  layerHierarchy.children = newIdChildren;

  return layerHierarchy;
}

/**
 * Removes a layer from a layer hierarchy.
 * @param layerIdToRemove Id of the layer to remove from the hierarchy.
 * @param layerHierarchy The hierarchy to remove the layer from.
 * @returns The hierarchy with the removed element.
 */
export function removeLayerInHierarchy(layerIdToRemove: number, layerHierarchy: LayerHierarchy): LayerHierarchy {
  let filteredChildren: (LayerHierarchy | LayerHierarchyEndNode)[] = [];

  layerHierarchy.children.forEach((element) => {
    if ("isCollapsed" in element) {
      filteredChildren.push(removeLayerInHierarchy(layerIdToRemove, element));
    } else {
      if (!(element.id === layerIdToRemove)) {
        filteredChildren.push(element);
      }
    }
  });
  layerHierarchy.children = filteredChildren;
  return layerHierarchy;
}
/**
 * Determines whether or not an element exists in the given layer hierarchy.
 * @param id Id of the element you wish to check.
 * @param layerHierarchy The layer hierarchy to search in.
 * @returns True if element exists in hierarchy otherwise false.
 */
export function elementExistsInHierarchy(id: number, layerHierarchy: LayerHierarchy): boolean {
  let found = layerHierarchy.children.find((element) => {
    if (!("isCollapsed" in element)) {
      return id === element.id;
    } else {
      return id === element.id ? true : elementExistsInHierarchy(id, element);
    }
  });
  return Boolean(found);
}