import { Localization } from "@viamap/viamap2-common"
import { Modal, Col, Row, Card } from "react-bootstrap"
import { usePanWindow } from "src/common/components/CustomHooks"

export default function Dashboard(props:{show:boolean}) {
  let [Eventer, ref,clickEventer] = usePanWindow(40)

  if (!props.show) {
    return (null)
  }

  return (
    <Modal.Dialog id="Dashboard" className='nModal SearchWindow' ref={ref}>
    <Modal.Header onMouseDown={Eventer}>
    <div className='GrabLines' onMouseDown={clickEventer} />
      {/* <Modal.Title> */}
      <h4>{Localization.getText("Welcome")}</h4>
      {/* </Modal.Title> */}
    </Modal.Header>
    <Modal.Body className='bodyLimitHeight' style={{height:"100%"}}>
      <Col sm={12}>
      <Row style={{marginInline:"-10px"}}><Col md="7"><SenesteProjekter /></Col><Col md="5"><Profile /></Col></Row>
      <Row><SensteVisteEjendomme /></Row>
      <Row><FulgteEjendomme /></Row>
      <Row><Reservationer /></Row>
      </Col>
      </Modal.Body>
      </Modal.Dialog>
  )
}

function SenesteProjekter() {
  return (
    <Card style={{height:"298px"}}>
      <Card.Header>{Localization.getText("Recent Projects")}</Card.Header>
      <Card.Body>
        <div className='stable'>
            <div>{Localization.getText("Project name")}</div>
            <div>Grunde over 15.000 kvm</div>
            <div>Grunde under 15.000 kvm</div>
            <div>Tæt på hovedvej</div>
        </div>
      </Card.Body>
    </Card>
  )
}

function Profile() {
  return (
    <Card style={{height:"298px"}}>
      <Card.Body >
        <div style={{backgroundImage:"var(--bgimg)" ,backgroundSize:"cover", height:"94px", margin:"-16px", borderRadius:"9px 9px 0px 0px", backgroundPosition:"center"}} />
        <div style={{position:"relative", marginTop:"50px"}}><h3>Søren Andersen</h3>
        <span style={{fontWeight:"300"}}>MøbelKæden.dk</span>
        </div>
        {/* <div style={{lineHeight:"106px",position:"absolute", top:"10px", left:"50%",transform:"translate(-50%,0)", width:"106px",height:"106px", background:"white", borderRadius:"106px"}}>{Localization.getText("Add Picture")}</div> */}
      </Card.Body>
    </Card>
  )
}

function SensteVisteEjendomme() {
  return (
    <Card>
      <Card.Header>{Localization.getText("Recent Viewed Project")}</Card.Header>
      <Card.Body>
        <div className='stable'>
            <div>{Localization.getText("Address")}</div>
            <div>Vestre Kongevej 4A-E, 8260 Viby J</div>
            <div>Østergade 30, 7600 Struer</div>
        </div>
      </Card.Body>
    </Card>
  )
}

function FulgteEjendomme() {
  return (
    <Card>
      <Card.Header>{Localization.getText("Followed Property")}</Card.Header>
      <Card.Body>
        <div className='stable'>
            <div>{Localization.getText("Address")}</div>
            <div>Naundrupvej 13, Roum Bæk, 9632 Møldrup</div>
            <div>Brunbjergvej 2, 8240 Risskov</div>
        </div>
      </Card.Body>
    </Card>
  )
}

function Reservationer() {
  return (
    <Card>
      <Card.Header>
        {Localization.getText("Reservations")}
      </Card.Header>
      <Card.Body>
        <div className='stable gr4'>

            <div>{Localization.getText("Site ID")}</div>
            <div>{Localization.getText("Reservation expires")}</div>
            <div>{Localization.getText("Address")}</div>
            <div>{Localization.getText("Contact")}</div>

            <div>136</div>
            <div>01-06-2023</div>
            <div>Kæiplev Industriparken 6, 6200</div>
            <div>Bahar</div>

            <div>155</div>
            <div>01-06-2023</div>
            <div>Gestenvej 36, 6600</div>
            <div>Bahar</div>
        </div>
      </Card.Body>
    </Card>
  )
}