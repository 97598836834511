import { Localization, Logger, SettingsManager } from "@viamap/viamap2-common";
import { Persistence } from "./Persistence";
import { Utils } from "@viamap/viamap2-common";
import { MapCommand, MapCommandType, MapRestrictions } from "src/common/managers/Types";
import { LayerChangeAction } from "src/components/MapScreen";
import { CadasterInterface } from "./CadasterInterface";
import { LayerFunc } from "./LayerFunc";
import { MitLatLng } from "./MapFacade";
import { LngLatBoundsLike } from "maplibre-gl";




type restoreCallbacks = {
  callbackToCreateHierarchy: (layerHierarchy, layerRenumberingMap) => void
  callbackOnMapStyling: (mapInfo) => void
  callbackOnLayerChanges: (LayerChangeAction, layerInfo, change) => number
  callbackOnViewerStateChanges: (viewerState) => void
  callbackonNewRestrictions: (mapRestrictions:MapRestrictions) => void
  callbackOnActiveFeatureLayerSpecs: (layerSpecs) => void,
  callbackOnError: (msg) => void
  callbackExtras:(jsObject) => void
}

export function restoreMapFromLink(mapReferenceData: any, callBacks: restoreCallbacks) {
  let currentDate = new Date();
  if (mapReferenceData?.["ShortLink"]) {
    /* tslint:disable-next-line */
    let ref = mapReferenceData?.["ShortLink"];
    Logger.logAction("LayerList", "restoreMapFromLink", "short link ref=" + ref);
    Persistence.lookupStateByReference(ref).then((json) => {
      try {
        // Set features for user features to match when created
        // todo: create a method for doing this change to featureSet
        // todo: This is too late to do this. Components are already created...
        // let us:UserSession = AccessManager.getUserSession();
        // let newUs:UserSession = {id:us.id, featureSet:json && json.enabledFeatures || [], userName:us.userName, name:us.name, email:us.email, groups:us.groups};
        // AccessManager.setUserSession(newUs);
        Logger.logAction("LayerList", "restoreMapFromLink", "json=" + JSON.stringify(mapReferenceData));
        Persistence.createLayersFromJSON(
          json!,
          currentDate,
          (layerHierarchy, layerRenumberingMap) => callBacks.callbackToCreateHierarchy(layerHierarchy, layerRenumberingMap),
          (mapInfo) => callBacks.callbackOnMapStyling(mapInfo),
          (layerInfo) => callBacks.callbackOnLayerChanges("Add", layerInfo, false),
          (viewerState) => callBacks.callbackOnViewerStateChanges(viewerState)
        ).catch(callBacks.callbackOnError).then(() => {
          (json.mapRestrictions && callBacks.callbackonNewRestrictions({
              maxZoom: json.maxZoom, 
              minZoom: json.minZoom,
              ...json.mapRestrictions
            })) || callBacks.callbackonNewRestrictions({
            maxZoom: json.maxZoom, 
            minZoom: json.minZoom,
          })

          
          const testedFeatueLag = FeatureLayerTest(json!.activeFeatureLayerSpecs)
          json!.activeFeatureLayerSpecs && callBacks.callbackOnActiveFeatureLayerSpecs(testedFeatueLag);
          json && callBacks.callbackExtras(json)
        })
      } catch (e:any) {
        throw new Error(Localization.getFormattedText("Error restoring link ({error})", { error: e.message }));
      }
    }).catch((err:any) => {
      throw new Error(Localization.getFormattedText("Error restoring link ({error})", { error: err }));
    });
  } else {
    Logger.logAction("LayerList", "restoreMapFromLink", "full state");
    Logger.logAction("LayerList", "restoreMapFromLink", "json=" + mapReferenceData);
    let countLoaded = Persistence.createLayersFromJSON(
      mapReferenceData,
      currentDate,
      (layerHierarchy, layerRenumberingMap) => callBacks.callbackToCreateHierarchy(layerHierarchy, layerRenumberingMap),
      (mapInfo) => callBacks.callbackOnMapStyling(mapInfo),
      (layerInfo) => callBacks.callbackOnLayerChanges("Add", layerInfo, false),
      (viewerState) => callBacks.callbackOnViewerStateChanges(viewerState)).catch(callBacks.callbackOnError)
  }
  // Success create onfo popup.
  //      appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Successfully loaded {count} layers from {file}",{count: countLoaded, file:"URL"}));

}


export function executeCommand(command: MapCommand[], callbackOnLayerChanges: (a,b,c) => void) {
  let [firstCommand, ...rest] = command
  try {
    switch (firstCommand.cmd) {
      case (MapCommandType.DisplayCadaster): {
        lookupAndShowMatrikel(firstCommand.params[0], firstCommand.params[1], callbackOnLayerChanges);
        break;
      }
      default:
        throw Utils.createErrorEventObject("Unknown or unsupported command: " + firstCommand.cmd);
    }
  } catch (error:any) {
    throw new Error(error.message);
  }
  if (rest.length) {
    executeCommand(rest, callbackOnLayerChanges)
  }
}

export function lookupAndShowMatrikel(ejerlavkode: string, matrikelnr: string, callbackOnLayerChanges: (a,b,c) => void) {
  if (!(ejerlavkode && matrikelnr && ejerlavkode.length > 0 && matrikelnr.length > 0)) {
    throw Utils.createErrorEventObject("lookupMatrikel. Missing parameters " + ejerlavkode + " " + matrikelnr);
  }
  CadasterInterface.searchMatrikel(ejerlavkode, matrikelnr)
    .then((result) => {
      console.log("Got: " + JSON.stringify(result));
      if (result.error || !result.data || (result.data && result.data.length === 0)) {
        let message = Localization.getFormattedText("Cadaster not found ({ejerlavkode} {matrikelnr})", { ejerlavkode: ejerlavkode, matrikelnr: matrikelnr });
        throw new Error(message);
      } else {
        let item = result.data[0];
        let lat = item.visueltcenter[1];
        let lng = item.visueltcenter[0];
        let ejerlavnavn = item.ejerlav && item.ejerlav.navn || "";
        let text: string = Utils.formatString("{ejerlav}({ejerlavkode}) matnr {matrikelnr}", { ejerlavkode, ejerlav: ejerlavnavn, matrikelnr });
        let properties = {
          Ejerlavkode: item.ejerlav && item.ejerlav.kode || "",
          Ejerlav: item.ejerlav && item.ejerlav.navn || "",
          Matrikelnr: item.matrikelnr,
          Kommune: item.kommune && item.kommune.navn || "",
          Registreretareal: item.registreretareal,
          Vejareal: item.vejareal,
          // Region:item.region.navn,
          // Sogn:item.sogn.navn,
          Retskreds: item.retskreds && item.retskreds.navn || "",
          /* tslint:disable-next-line */
          "ESR ejendomsnr": item.esrejendomsnr,
          // Udvidet_esrejendomsnr:item.udvidet_esrejendomsnr,
          /* tslint:disable-next-line */
          "SFE ejendomsnr": item.sfeejendomsnr,
        };
        let layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(lat, lng), text, properties);
        callbackOnLayerChanges("Add", layerInfo, true);
      }
    })
    .catch((err) => {
      throw new Error("Got error looking up matrikel " + err);
    });

}

function FeatureLayerTest(layers: any) {
  let invalidLayers:string[] = []
  Object.keys(layers).forEach((a) => {
    const layer = layers[a]
    if (testLayer(layer)) {
      return
    }
    invalidLayers.push(a)
  })
  invalidLayers.forEach((a) => {
    delete layers[a]
  })
  return layers
}

function testLayer(layer) {
  return layer.label && layer.host && layer.path && layer.layers
}