import * as react from 'react';

// ToDo: Where to configure icons to use (by design style/template)
import IconDashboard from '../svg/NewSecSVG/Dashboard.svg'
import IconDiscover from '../svg/NewSecSVG/Discover.svg'
import Explore from '../svg/NewSecSVG/Explore.svg'
import IconProjects from '../svg/NewSecSVG/Projects.svg'
import Ejerskab from '../svg/NewSecSVG/Ejerskab.svg'
import Layers from '../svg/NewSecSVG/Layers.svg'
import Kort from '../svg/NewSecSVG/Kort.svg'
import Poi from '../svg/NewSecSVG/Poi.svg'
import ShareMap from '../svg/NewSecSVG/Del_kort.svg'
import Download from '../svg/NewSecSVG/Download.svg'
import GetLink  from '../svg/NewSecSVG/send_link.svg'
import IconDemography from '../svg/NewSecSVG/Demografi.svg'
import IconTravelTime from '../svg/NewSecSVG/Rejsetid.svg'
import { Localization, Cookies, MitCookies, actionSetInfoMessage, AppMessagesContext } from '@viamap/viamap2-common';
import { RightPaneContext, RightPaneModus, actionGoToModus, actionToggleModus } from 'src/states/RightPaneState';
import { ProtectedFeature } from './ProtectedFeature';
import { ApplicationStateContext, DeviceType, Feature, hasAccessToFeature } from 'src/states/ApplicationState';
import Dashboard from './Dashboard';
import OwnershipDiagram from 'src/compentsModus/OwnershipDiagram';
import { SearchWindow } from 'src/compentsModus/SearchWindow';
import { Projects } from './Projects';
// import { CompleteMultipleCatchmentDialog } from './MultipleCatchmentDialog';
import { MapInteractionState, MapitStateContext, MapitWindowId, actionAddDataLayer, actionSetMapInteractionState, actionSetMapInteractionStateDefault, actionSetPoiTypesToShow, actionSetShowWindow } from 'src/states/MapitState';
import React from 'react';
import { BsFillPostcardFill, BsFillCameraFill, BsFillCarFrontFill, BsSend, BsFillSignpost2Fill } from 'react-icons/bs'; 
import { BiCross } from 'react-icons/bi'
import { GiPentarrowsTornado} from 'react-icons/gi'
import { PoiSelector } from './PoiSelector';
import { DrawingTools } from './DrawingToolsV2';
import { MdDraw, MdQueryStats } from 'react-icons/md';
import { DialogMode, MultiPointTravelDialog } from './MultiPointTravelDialog';
import { FiTarget, FiPrinter  } from 'react-icons/fi';
import { PiMapPinFill } from 'react-icons/pi';
import { PlotScreen } from './PlotScreen';
import { LayerFunc } from 'src/managers/LayerFunc';
import { DynamicDataDrivenLayerPOI } from 'src/managers/DynamicDataDrivenLayer';
import { POIS } from 'src/HLED/managers/PoiManager';
import { MarkerType } from 'src/common/managers/Types';
import { createPortal } from 'react-dom';
import { DeviceScreenSize } from 'src/states/ApplicationStateFeatureMapping';

export default function RightPane(props: {children:react.ReactNode}) {
  const {state: rightPaneState, dispatch: rightPaneDispatch} = react.useContext(RightPaneContext)
  const {state: applicationState,hasAccessToFeature} = React.useContext(ApplicationStateContext)
  const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);

  const [width, setWidth] = React.useState<number>(0)

  const [showGrap, setShowGrap] = React.useState(0);
  const [showSideMenu, setShowSideMenu] = React.useState(1);

  const overlayRef = react.useRef<HTMLDivElement|null>(null)

  React.useEffect(() => {
    const ScrollTop = (e) => {
      if (overlayRef.current) 
        overlayRef.current.scrollTop = 0;
    }
    overlayRef.current?.addEventListener("scroll", ScrollTop)
    return overlayRef.current?.removeEventListener("scroll", ScrollTop)
  },[overlayRef.current])
  
  function isActive(a:RightPaneModus) {
    let splited = rightPaneState.current.split("_")
    if (splited.map((a,b,c) => c.slice(0,(b+1)).join("_")).includes(a)) {
      return true
    }
    return false
  }

  let activeFeatures = Object.keys(FeatureList).filter((a) => hasAccessToFeature(a))
  function FirstTierWidth():React.CSSProperties {
    if (activeFeatures.length < 2 || applicationState.deviceScreenSize === DeviceScreenSize.Phone) {
      return {"--sidebarW": "0px"} as React.CSSProperties
    } else {
      return {}
    }
  }

  react.useEffect(() => {
    let elements = Array.from(document.getElementsByClassName("CalcSideBar"))
    if (elements.length === 0) {
      setWidth(0)
    } else {
      const width = (e:Element) => (window?.innerWidth || 0) - (e.getBoundingClientRect().left || (window?.innerWidth || 0))
      setTimeout(() => setWidth(elements.map((a) => width(a)).filter((a) => a).reduce((a,b) => a > b ? a : b ,0)) ,100) //Timeout to wait after any DOM changes
      let Orbservers:ResizeObserver[] = []
      elements.forEach((calcBox) => {
        const resize = new ResizeObserver((a) => {
          setTimeout(() => setWidth(elements.map((a) => width(a)).filter((a) => a).reduce((a,b) => a > b ? a : b ,0)) ,200)
        })
        resize.observe(calcBox)
        Orbservers.push(resize)
      })
      return () => Orbservers.forEach((a) => (a.disconnect()))
    }
  },[rightPaneState.current, showSideMenu])

  function isMobile():boolean {
    return false
  }

  react.useEffect(() => {
    if (applicationState.deviceScreenSize !== DeviceScreenSize.Normal) {
      setShowGrap(1)
      const ss = setTimeout(() => setShowSideMenu(0) , 3000)
      return () => {clearTimeout(ss)}
    } else {
      setShowGrap(0)
      setShowSideMenu(1)
    }
  },[applicationState.deviceScreenSize])



  //Used cause css Variable
  const ss = !showSideMenu ? {'--subsidebar':'0px'} : {}
  const CustomStyle = {...FirstTierWidth(),display:"flex",flexDirection:"row",position:"relative",'--spacingRightBar':width+"px" , '--subSideWidth': showSideMenu ? '80px' : '0px', ...ss} as unknown as React.CSSProperties
  return (
    <div ref={overlayRef} id='Mit-ApplicationOverlay' className={'mapApplication ' + "S_" + rightPaneState.current} style={CustomStyle} >
      {props.children}
      {showGrap ? <GrabButton onClick={() => setShowSideMenu((a) => a?0:1)} showLayerList={false} observeClass={"Sidebar-Right"} /> : <></>}
      {Object.keys(RightPaneModusSelector).map((key, id) => <react.Fragment key={key} >{!showSideMenu && ((key == "") || (key == "Map")) ? "" : RightPaneModusSelector[key]((isActive(key as RightPaneModus) && "active") || "")}</react.Fragment>)}
    </div>
  )

}

const RenderPointDistanceTableWindow = (props:{show:boolean}) => {
  const {state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters} = React.useContext(MapitStateContext);
  const { state: rightPaneState, dispatch: rightPaneDispatch } = React.useContext(RightPaneContext);

  React.useEffect(() => {
    if (props.show) {
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, true, {dialogMode:"Polygons"}))
    } else {
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, false))
    }
  },[props.show])

  return (null);
}

const RenderGenerateReport = (props:{show:boolean}) => {
  const {state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters} = React.useContext(MapitStateContext);
  const { state: rightPaneState, dispatch: rightPaneDispatch } = React.useContext(RightPaneContext);

  React.useEffect(() => {
    if (props.show) {
      mapitStateDispatch(actionSetShowWindow( MapitWindowId.DemographyReportDialog, true));
    } else {
      mapitStateDispatch(actionSetShowWindow( MapitWindowId.DemographyReportDialog, false));
    }
  },[props.show])

  return (null);
}

const RenderOwnerShipDiagram = (props:{show:boolean}) => {
  const {state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder} = React.useContext(MapitStateContext);
  const {hasAccessToFeature} = React.useContext(ApplicationStateContext)
  const { state: rightPaneState, dispatch: rightPaneDispatch } = React.useContext(RightPaneContext);

  React.useEffect(() => {
    if (props.show) {
      mapitStateDispatch(actionSetShowWindow( MapitWindowId.OwnerShipDiagram, true));
    } else {
      mapitStateDispatch(actionSetShowWindow( MapitWindowId.OwnerShipDiagram, false));
    }
  },[props.show])

  React.useEffect(() => {
    if (mapitState.showWindow[MapitWindowId.OwnerShipDiagram]?.stackingOrder) {
      if (hasAccessToFeature(Feature.ThemeNewSec)) {
        rightPaneDispatch(actionGoToModus(RightPaneModus.Ejerskab))
      } else {
        rightPaneDispatch(actionGoToModus(RightPaneModus.Map_Ownership))
      }
    }
  },[mapitState.showWindow])

  return (null);
}
const RightPaneModusSelector : {[modus: string]:{(show:any) : react.ReactNode}} = {
  'Dashboard': ((show) => <Dashboard show={show != ""} />),
  'Map': ((show) => <SubSubMenuMap show={show != ""} />),
  'Search': ((show) => <SearchWindow show={show != ""} />),
  'Ejerskab': ((show) => <RenderOwnerShipDiagram show={show != ""} />),
  'GeoDataRapport': ((show) => <RenderGenerateReport show={!!show} />),
  'Map_Measure': ((show) => <DrawingTools show={show != ""} />),
  'Projects': ((show) => <Projects show={show != ""} />),
  'Map_POI': ((show) => <POI show={show != ""} />),
  'Map_Discover': ((show) => <Discover show={show != ""} />),
  'Map_CatchmentMultiple': ((show) => <RenderPointDistanceTableWindow show={!!show} />),
  'Map_GeoDataRapport': ((show) => <RenderGenerateReport show={!!show} />),
  'Map_Share': ((show) => <ShareMapBar show={!!show} />),
  'Map_Explorer': ((show) => <SearchWindow show={show != ""} />),
  'Map_Ownership': ((show) => <RenderOwnerShipDiagram show={show != ""} />),
  'Map_Plot': ((show) => <MapPLot show={show != ""} />),
  'Ejendom': ((show) => null),
  "": ((show) => <SubMenu />),
}

const FeatureList = {
  [Feature.ExplorerDashboard]:RightPaneModus.Dashboard,
  [Feature.BasicMap]:RightPaneModus.Map,
  [Feature.ThemeNewSec]:RightPaneModus.Search,
}

function SubMenu() {
  const {state: rightPaneState, dispatch: rightPaneDispatch} = react.useContext(RightPaneContext)
  const {state: applicationState,hasAccessToFeature} = React.useContext(ApplicationStateContext)
  
  let activeFeatures = Object.keys(FeatureList).filter((a) => hasAccessToFeature(a))
  React.useEffect(() => {
    if (activeFeatures.length === 1) {
      rightPaneDispatch(actionGoToModus(FeatureList[activeFeatures[0]]))
    }
    if (activeFeatures.length < 1) {
      rightPaneDispatch(actionGoToModus(RightPaneModus.Map))
    }
  },[applicationState])
  
  function isActive(a:RightPaneModus) {
    let splited = rightPaneState.current.split("_")
    if (splited.map((a,b,c) => c.slice(0,(b+1)).join("_")).includes(a)) {
      return true
    }
    return false
  }

  if (applicationState.deviceScreenSize === DeviceScreenSize.Phone) {
    return <></>
  }

  if (activeFeatures.length < 2) {
    return <></>
  }

  return (
      <div className="SubMenu CalcSideBar" >
        <ProtectedFeature feature={Feature.ExplorerDashboard} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>
      <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Dashboard))} className={isActive(RightPaneModus.Dashboard) ? "active":""}  type-title={Localization.getText("Dashboard")}><img src={IconDashboard} /></div> {/* <FiGrid /> */}
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.BasicMap} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>
       <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map))} className={isActive(RightPaneModus.Map) ? "active":""} type-title={Localization.getText("Map")}><img src={Kort} /></div> {/* <MdMap /> */}
       </ProtectedFeature>
        <ProtectedFeature feature={[Feature.ExplorerExplore, Feature.ThemeNewSec]} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>       
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Search))} className={isActive(RightPaneModus.Search) ? "active":""} type-title={Localization.getText("Explore")}><img src={Explore} /></div> {/* <MdSearch /> */}
        </ProtectedFeature>
        <ProtectedFeature feature={[Feature.ExplorerOwnership, Feature.ThemeNewSec]} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>
           <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Ejerskab))} className={isActive(RightPaneModus.Ejerskab) ? "active":""} type-title={Localization.getText("Ownership")}><img src={Ejerskab} /></div> {/* <FaProjectDiagram /> */}
       </ProtectedFeature>
        <ProtectedFeature feature={[Feature.ExplorerProjects, Feature.ThemeNewSec]} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>       
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Projects))} className={isActive(RightPaneModus.Projects) ? "active":""} type-title={Localization.getText("Projects")}><img src={IconProjects} /></div> {/* <MdTraffic /> */}
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>       
        <ProtectedFeature feature={Feature.GeodataReport} contentsIfNoAccess={
          <div style={{fontSize:"11px"}} onClick={() => {console.error("NotImplementedYet")}} className={"LockedFeature " + (isActive(RightPaneModus.GeoDataRapport) ? "active" : "")} type-title={Localization.getText("Demography")}><img src={IconDemography} /></div>
          }>
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.GeoDataRapport))} className={"UnlockedFeature " + (isActive(RightPaneModus.GeoDataRapport) ? "active" : "")} type-title={Localization.getText("Demography")}><img src={IconDemography} /></div>
        </ProtectedFeature>
      </ProtectedFeature>
    </div>
  )
}

function ShareMapBar(props:{show:boolean}) {
  const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
  // const { state: helpState, dispatch: helpStateDispatch} = React.useContext(UserHelpContext);

  if (!props.show) 
  return null;

  return (
    <div className="ExtendedSideBar SubSub Discover CalcSideBar" style={{minWidth:"75px"}} >
        <ProtectedFeature feature={Feature.SaveMap} contentsIfNoAccess={<></>}>
        <div className='DiscoverIcons' style={{marginTop: "15px"}} onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveMapDialog, true))} type-title={Localization.getText("Download")} ><img style={{ height:"100%", background:"#f4f4f4", cursor:"pointer" ,borderRadius:"5pt"}} src={Download} /></div>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.SaveLink} contentsIfNoAccess={<></>}>
        <div className='DiscoverIcons' style={{marginTop: "15px"}} onClick={(e) => {
          Cookies.getCookieBoolean(MitCookies.RememberMapLinkGDPRAccept) ? mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, true)) : mapitStateDispatch(actionSetShowWindow(MapitWindowId.GDPRNoticeApproval, true, {
            cookie: MitCookies.RememberMapLinkGDPRAccept,
            acceptCallback: () => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, true))
          }));
          }} type-title={Localization.getText("Get Link")} ><img style={{ height:"100%", background:"#f4f4f4", cursor:"pointer" ,borderRadius:"5pt"}} src={GetLink} /></div>
      </ProtectedFeature>
    </div>
  )
}

function SubSubMenuMap(props:{show:boolean}) {
  const {state: rightPaneState, dispatch: rightPaneDispatch} = react.useContext(RightPaneContext)

  if (!props.show) {return null}
  
  function isActive(a:RightPaneModus) {
    let splited = rightPaneState.current.split("_")
    if (splited.map((a,b,c) => c.slice(0,(b+1)).join("_")).includes(a)) {
      return true
    }
    return false
  }

  return (
    <div className="SubSubMenu CalcSideBar" >
      <ProtectedFeature feature={Feature.BasicMap} contentsIfNoAccess={<></>}>
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Layer))} className={isActive(RightPaneModus.Map_Layer) ? "active" : ""} type-title={Localization.getText("Layers")}><img src={Layers} /></div>
      </ProtectedFeature>
    <ProtectedFeature feature={Feature.POI} contentsIfNoAccess={<></>}>
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_POI))} className={isActive(RightPaneModus.Map_POI) ? "active" : ""} type-title={"POI"}><img src={Poi} /></div>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ExplorerDiscover} contentsIfNoAccess={<></>}>
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Discover))} className={isActive(RightPaneModus.Map_Discover) ? "active" : ""} type-title={Localization.getText("Discover")}><img src={IconDiscover} /></div>
      </ProtectedFeature>
      {/* <ProtectedFeature feature={Feature.TravelTime} contentsIfNoAccess={<></>}>
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Catchment))} className={isActive(RightPaneModus.Map_Catchment) ? "active" : ""} type-title={Localization.getText("Travel time")}><img src={Catchment} /></div>
      </ProtectedFeature> */}
      <ProtectedFeature feature={Feature.TravelTimeMultiple} contentsIfNoAccess={
        <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
        <div onClick={() => {console.error("NotImplementedYet")}} className={"LockedFeature " + (isActive(RightPaneModus.Map_CatchmentMultiple) ? "active" : "")} type-title={Localization.getText("abbreviation:Generate combined catchment area")}><img src={IconTravelTime} /></div>  
        </ProtectedFeature>
      }>
        <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_CatchmentMultiple))} className={isActive(RightPaneModus.Map_CatchmentMultiple) ? "active" : ""} type-title={Localization.getText("abbreviation:Generate combined catchment area")}><img src={IconTravelTime} /></div>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.GeodataReport} contentsIfNoAccess={<></>} >
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_GeoDataRapport))} className={"NewSecNone "+ (isActive(RightPaneModus.Map_GeoDataRapport) ? "active" : "")} type-title={Localization.getText("Demography")}><img src={IconDemography} /></div>
      </ProtectedFeature>
            {/* <ProtectedFeature feature={Feature.MeasurementTool} contentsIfNoAccess={(<span style={{display:"none"}}></span>)}>        */}
            <div onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Measure))} className={isActive(RightPaneModus.Map_Measure) ? "active":""} type-title={Localization.getText("Draw")}><MdDraw /></div> {/* <MdTraffic /> */}
      {/* </ProtectedFeature> */}
      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Share))} className={(isActive(RightPaneModus.Map_Share) ? "active" : "")} type-title={Localization.getText("Share Map")}><img src={ShareMap} /></div>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<>
        <ProtectedFeature feature={Feature.ExplorerExplore} contentsIfNoAccess={<></>}>
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Explorer))} className={(isActive(RightPaneModus.Map_Explorer) ? "active" : "")} type-title={Localization.getText("Explore")}><img src={Explore} /></div>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Ownership))} className={(isActive(RightPaneModus.Map_Ownership) ? "active" : "")} type-title={Localization.getText("Ownership")}><img src={Ejerskab} /></div>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.MapPlot} contentsIfNoAccess={<></>}>
        <div style={{fontSize:"11px"}} onClick={() => rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Plot))} className={(isActive(RightPaneModus.Map_Plot) ? "active" : "")} type-title={Localization.getText("Plot")} ><FiPrinter /></div>
        </ProtectedFeature>
      </>} >
        <></>
      </ProtectedFeature>
      </div>
      )
}




function Discover(props:{show:boolean}) {
  let {state, dispatch} = React.useContext(MapitStateContext)

  React.useEffect(() => {
    return () => dispatch(actionSetMapInteractionStateDefault())
  },[props.show])

  if (!props.show) {
    return null
  }

  function handleClick(x: MapInteractionState) {
    if (x !== state.mapInteractionState) {
      dispatch(actionSetMapInteractionState(x)) 
    } else {
      dispatch(actionSetMapInteractionStateDefault())
    }
  }

  const Buttons = [
    {text:"Add Pin",           icon: <PiMapPinFill />,       action: MapInteractionState.ClickToSetMarker,              feature: undefined},
    {text:"Info",              icon: <BsFillPostcardFill />, action: MapInteractionState.ClickToShowPropertyInfo,       feature: Feature.PropertyInfo},
    {text:"Oblique Photo",     icon: <BsFillCameraFill />  , action: MapInteractionState.ClickToShowObliquePhotos,      feature: Feature.ObliquePhoto},
    {text:"Street Viewer",     icon: <BsFillSignpost2Fill />,action: MapInteractionState.ClickToShowStreetView,         feature: Feature.StreetView},
    {text:"Travel Time",       icon: <BsFillCarFrontFill />, action: MapInteractionState.ClickToShowTravelTimePolygons, feature: Feature.TravelTime},
    {text:"Real estate",       icon: <div>"V"</div>,         action: MapInteractionState.HentVurderingsEjendom,         feature: Feature.VisVurderingsejendom},
    {text:"Total real estate", icon: <div>"S"</div>,         action: MapInteractionState.HentSamletFastEjendom,         feature: Feature.VisSamletFastEjendom},
    {text:"Dot Info",          icon: <BiCross />   ,         action: MapInteractionState.EnforceFeatureLayerPopup,      feature: undefined},
    {text:"Point Distance",    icon: <GiPentarrowsTornado />,action: MapInteractionState.ClickToShowPointDistanceTable, feature: Feature.TravelTimeFromPoint},
    {text:"QueryFeatures",     icon: <MdQueryStats />,       action: MapInteractionState.QueryFeatures,                 feature: Feature.Debugging}
    // TODO: Add https://maplibre.org/maplibre-gl-js-docs/example/queryrenderedfeatures/
  ]

  return (
    <div className='ExtendedSideBar SubSub Discover CalcSideBar' style={{minWidth:"75px",minHeight:"100px"}}>
      {Buttons.map((a) => {
        if (a.feature == undefined) {
          return (
            <div key={a.text} className={"DiscoverIcons" + (a.action === state.mapInteractionState ? " active" : "")}  onClick={(e) => handleClick(a.action)} type-title={Localization.getText(a.text)}>
              {a.icon}
            </div>
          )
        }
        return (
        <ProtectedFeature key={a.text} feature={a.feature} contentsIfNoAccess={<></>} >
          <div className={"DiscoverIcons" + (a.action === state.mapInteractionState ? " active" : "")}  onClick={(e) => handleClick(a.action)} type-title={Localization.getText(a.text)}>
            {a.icon}
          </div>
        </ProtectedFeature>
      )})}
    </div>
  )
}



function POI(props:{show:boolean}) {
  const { state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  const {dispatch: appMessageDispatch} = React.useContext(AppMessagesContext);
  const {state: rightPaneState, dispatch: rightPaneDispatch} = react.useContext(RightPaneContext)
  
  if (!!!props.show) {
    return <></>
  }

  // function mapitStateDispatch(arg0: any) {
  //   throw new Error('Function not implemented.');
  // }

  return (<div className='ExtendedSideBar SubSub POIList CalcSideBar' style={{minWidth:"75px",minHeight:"100px"}}>
      <div className='CustomScroll' style={{overflowY:"auto", height:"100%"}}>
        <PoiSelector 
                onSavePoisAsLayer={async (poiTypes:string[]) => {
                  const createAsSingleLayer:boolean = true;
                  if (createAsSingleLayer) {
                    let bounds:any = mapitState.map.getBounds();
                    let bboxMap:any = bounds.toArray();
                    let bbox=[...bboxMap[0], ...bboxMap[1]];
                    let featureCollection = await (new DynamicDataDrivenLayerPOI({label:"pois",layers:JSON.stringify(poiTypes)})).getDataForBBOX(bbox);
                    let title = poiTypes.reduce((result, poi, idx) => {
                      let label=POIS[poi][Localization.getLanguage()];
                      return result + (idx > 0 ? ", ":"")+ label;
                    }, "")
                    let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(featureCollection, title);
                    newLayerInfo.styling = {...newLayerInfo.styling, "markerType": MarkerType.Icon, "iconByProperty": "icon"};
                    mapitStateDispatch(actionAddDataLayer(newLayerInfo));
                    // Hide the POI's that was currenly shown - and now shown as a data layer.
                    mapitStateDispatch(actionSetPoiTypesToShow([]));
                    // Show layer list such that new layer can be seen
                    rightPaneDispatch(actionToggleModus(RightPaneModus.Map_Layer))
                    appMessageDispatch(actionSetInfoMessage(Localization.getFormattedText("Pois has been copied to a new layer '{title}'", {title})));
                  }
                }}
          />
      </div>
    </div>
  )
}

function GrabButton(props: {showLayerList, onClick, observeClass, text?}) {
  const [UpdateState, sUS] = react.useState(0)

  react.useEffect(() => {
    const observer = new ResizeObserver(() => {
      sUS((a) => a + 1)
      setTimeout(() => sUS((a) => a + 1), 200)
    })
    observer.observe(document.getElementsByClassName("maplibregl-control-container")?.[0] ?? document.body)
    return () => {
      observer.disconnect()
    }
  })

  return (
    createPortal(
    <button className='GrabButton' style={{position: "absolute", right: "var(--spacingRightBar, 0px)" , top: "20%", zIndex: 1}} onClick={(() => props.onClick((a) => !a))}>
      <div className='GrabButton-text' >{Localization.getText("SideBar")}</div>
      <div>||</div>
    </button>
    , document.getElementById("Mit-ApplicationOverlay") || document.body)
  )
}


function MapPLot(props:{show:boolean}) {
  if (!!!props.show) {
    return <></>
  }

  return (
    <PlotScreen />
  )
}