import {PredictionResult, MitDataType, LayerType, ColumnMapping, SelectionOptions, SheetAnalysisResult} from '../common/managers/Types';
import { Layer } from 'leaflet';


export class PredictionLogic {

    // Returns columnindex (0 is first) of -1 if not found;
    static findColumnOfType(analysisResults:SheetAnalysisResult, dataType:MitDataType):number {
        var result:number = -1;
        analysisResults && analysisResults.columns.map((obj,idx) => {
            if (obj.mitDataType === dataType) {
                result = idx;
            }
        });
        return result;
    }

    static searchForSolutions(
        thisLevelNo:number, 
        currentValues:any[], 
        currentLayerType:LayerType,
        currentWeight:number,
        optionsStructure:SelectionOptions, 
        analysisResults:SheetAnalysisResult,
        callbackOnFoundSolution:(p:PredictionResult)=>void):void {
            if (optionsStructure.fieldselectionlist) {
                // Is at leaf level.
                // Check that all needed data types are present and create columnmapping
                var columnMapping:ColumnMapping= {};
                var failed:boolean = false;
                optionsStructure.fieldselectionlist.map((obj,idx) => {
                    // find and map field in analysisresults
                    var foundAt = PredictionLogic.findColumnOfType(analysisResults, obj.mitDataType);
                    if (foundAt >= 0) {
                        // Add 1 for oneBased column index. 1=A, 2=B, ...
                        columnMapping = {...columnMapping, [obj.mitDataType]:foundAt +1};
                    } else {
                        if (obj.mandatory === true) {
                            failed=true;
                        }
                    }
                });
                if (!failed) {
                    callbackOnFoundSolution({
                        layerType:currentLayerType, 
                        weight:currentWeight,
                        columnMapping:columnMapping,
                        menuSelections:currentValues
                    });
                }
            } else {
                if (optionsStructure.options) {
                    optionsStructure.options.map((obj,idx) => {
                        // var cv:any[] = currentValues;
                        // cv.push(obj.value);
                        currentLayerType = obj.value as LayerType;
                        currentWeight = obj.weight;
                        if (obj.children) {
                            this.searchForSolutions(
                                thisLevelNo+1, 
                                [...currentValues, obj.value], 
                                currentLayerType, 
                                currentWeight,
                                obj.children, 
                                analysisResults, 
                                callbackOnFoundSolution);
                        }
                    });
                }
            }
        }

static sorterByWeightDesc = (p1:PredictionResult, p2:PredictionResult) => { 
            return p1.weight === p2.weight ? 0: p1.weight < p2.weight ? 1: -1; }

static predictImportSelections(analysisResults:SheetAnalysisResult, optionsStruct:SelectionOptions):PredictionResult|null { 

    // ToDo: input: field type descriptions, options hierarchy
    // ToDo: exhaustive search (or branch and bounds) of the 
    // hierarchy to find the most likely mapping

    var solutions:PredictionResult[] = [];

    PredictionLogic.searchForSolutions(
        0,
        [],
        LayerType.Unknown,
        0,
        optionsStruct,
        analysisResults,
        (result:PredictionResult)=> {
            solutions.push(result);
        });
    var numberOfSolutionsFound = solutions.length;

    // ToDo: Analyse which solution is best
    if (numberOfSolutionsFound>0) {
        solutions.sort(this.sorterByWeightDesc);
        return (solutions[0]);
    } else {
        return(null);
    }
    
}

}