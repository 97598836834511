import {ReferenceGeomTypeWorld, ReferenceGeomFeatureCountry} from '../common/managers/Types';
import {Utils} from '@viamap/viamap2-common';


export class ReferenceGeomWorld {

    // Cache for reference informartion
    private static refGeom:any = null;
    private static isLoading:boolean = false;

    private static dataUrl = "https://s3.eu-central-1.amazonaws.com/mapit2-data/GeoJSONReferencedFromMapit/ReferenceGeomWorld.json";

    private static countryTranslationTable = [
        {code:"AW",en:"Aruba",da:"Aruba"},
        {code:"AF",en:"Afghanistan",da:"Afghanistan"},
        {code:"AO",en:"Angola",da:"Angola"},
        {code:"AI",en:"Anguilla",da:"Anguilla"},
        {code:"AL",en:"Albania",da:"Albanien"},
        {code:"AX",en:"Aland",da:"Aruba"},
        {code:"AD",en:"Andorra",da:"Andorra"},
        {code:"AE",en:"United Arab Emirates",da:"De Forenede Arabiske Emirater"},
        {code:"AR",en:"Argentina",da:"Argentina"},
        {code:"AM",en:"Armenia",da:"Armenien"},
        {code:"AS",en:"American Samoa",da:"Amerikansk Samoa"},
        {code:"TF",en:"French Southern and Antarctic Lands",da:"De Franske Besiddelser i Det Sydlige Indiske Ocean"},
        {code:"AG",en:"Antigua and Barbuda",da:"Antigua og Barbuda"},
        {code:"AU",en:"Australia",da:"Australien"},
        {code:"AT",en:"Austria",da:"Østrig"},
        {code:"AZ",en:"Azerbaijan",da:"Aserbajdsjan"},
        {code:"BI",en:"Burundi",da:"Burundi"},
        {code:"BE",en:"Belgium",da:"Belgien"},
        {code:"BJ",en:"Benin",da:"Benin"},
        {code:"BF",en:"Burkina Faso",da:"Burkina Faso"},
        {code:"BD",en:"Bangladesh",da:"Bangladesh"},
        {code:"BG",en:"Bulgaria",da:"Bulgarien"},
        {code:"BH",en:"Bahrain",da:"Bahrain"},
        {code:"BS",en:"The Bahamas",da:"Bahamas"},
        {code:"BA",en:"Bosnia and Herzegovina",da:"Bosnien-Hercegovina"},
        {code:"BL",en:"Saint Barthelemy",da:"Benin"},
        {code:"BY",en:"Belarus",da:"Belarus"},
        {code:"BZ",en:"Belize",da:"Belize"},
        {code:"BM",en:"Bermuda",da:"Bermuda"},
        {code:"BO",en:"Bolivia",da:"Bolivia"},
        {code:"BR",en:"Brazil",da:"Brasilien"},
        {code:"BB",en:"Barbados",da:"Barbados"},
        {code:"BN",en:"Brunei",da:"Brunei"},
        {code:"BT",en:"Bhutan",da:"Bhutan"},
        {code:"BW",en:"Botswana",da:"Botswana"},
        {code:"CF",en:"Central African Republic",da:"Den Centralafrikanske Republik"},
        {code:"CA",en:"Canada",da:"Canada"},
        {code:"CH",en:"Switzerland",da:"Schweiz"},
        {code:"CL",en:"Chile",da:"Chile"},
        {code:"CN",en:"China",da:"Kina"},
        {code:"CI",en:"Ivory Coast",da:"Côte d'Ivoire"},
        {code:"CM",en:"Cameroon",da:"Cameroun"},
        {code:"CD",en:"Democratic Republic of the Congo",da:"Cocosøerne (Keelingøerne)"},
        {code:"CG",en:"Republic of Congo",da:"Congo"},
        {code:"CK",en:"Cook Islands",da:"Cookøerne"},
        {code:"CO",en:"Colombia",da:"Colombia"},
        {code:"KM",en:"Comoros",da:"Comorerne"},
        {code:"CV",en:"Cape Verde",da:"Kap Verde"},
        {code:"CR",en:"Costa Rica",da:"Costa Rica"},
        {code:"CU",en:"Cuba",da:"Cuba"},
        {code:"CW",en:"Cura�ao",da:"Kap Verde"},
        {code:"KY",en:"Cayman Islands",da:"Caymanøerne"},
        {code:"CY",en:"Cyprus",da:"Cypern"},
        {code:"CZ",en:"Czech Republic",da:"Tjekkiet"},
        {code:"DE",en:"Germany",da:"Tyskland"},
        {code:"DJ",en:"Djibouti",da:"Djibouti"},
        {code:"DM",en:"Dominica",da:"Dominica"},
        {code:"DK",en:"Denmark",da:"Danmark"},
        {code:"DO",en:"Dominican Republic",da:"Den Dominikanske Republik"},
        {code:"DZ",en:"Algeria",da:"Algeriet"},
        {code:"EC",en:"Ecuador",da:"Ecuador"},
        {code:"EG",en:"Egypt",da:"Egypten"},
        {code:"ER",en:"Eritrea",da:"Eritrea"},
        {code:"ES",en:"Spain",da:"Spanien"},
        {code:"EE",en:"Estonia",da:"Estland"},
        {code:"ET",en:"Ethiopia",da:"Etiopien"},
        {code:"FI",en:"Finland",da:"Finland"},
        {code:"FJ",en:"Fiji",da:"Fiji"},
        {code:"FK",en:"Falkland Islands",da:"Falklandsøerne"},
        {code:"FR",en:"France",da:"Frankrig"},
        {code:"FO",en:"Faroe Islands",da:"Færøerne (Føroyar)"},
        {code:"FM",en:"Federated States of Micronesia",da:"Mikronesien"},
        {code:"GA",en:"Gabon",da:"Gabon"},
        {code:"GB",en:"United Kingdom",da:"Det Forenede Kongerige"},
        {code:"GE",en:"Georgia",da:"Georgien"},
        {code:"GG",en:"Guernsey",da:"Fransk Guyana"},
        {code:"GH",en:"Ghana",da:"Ghana"},
        {code:"GN",en:"Guinea",da:"Guinea"},
        {code:"GM",en:"Gambia",da:"Gambia"},
        {code:"GW",en:"Guinea Bissau",da:"Guinea-Bissau"},
        {code:"GQ",en:"Equatorial Guinea",da:"Ækvatorialguinea"},
        {code:"GR",en:"Greece",da:"Grækenland"},
        {code:"GD",en:"Grenada",da:"Grenada"},
        {code:"GL",en:"Greenland",da:"Grønland (Kalaallit Nunaat)"},
        {code:"GT",en:"Guatemala",da:"Guatemala"},
        {code:"GU",en:"Guam",da:"Guam"},
        {code:"GY",en:"Guyana",da:"Guyana"},
        {code:"HK",en:"Hong Kong S.A.R.",da:"Hongkong"},
        {code:"HM",en:"Heard Island and McDonald Islands",da:"Heard- og McDonaldøerne"},
        {code:"HN",en:"Honduras",da:"Honduras"},
        {code:"HR",en:"Croatia",da:"Kroatien"},
        {code:"HT",en:"Haiti",da:"Haiti"},
        {code:"HU",en:"Hungary",da:"Ungarn"},
        {code:"ID",en:"Indonesia",da:"Indonesien"},
        {code:"IM",en:"Isle of Man",da:"Israel"},
        {code:"IN",en:"India",da:"Indien"},
        {code:"IO",en:"British Indian Ocean Territory",da:"Det Britiske Territorium i Det Indiske Ocean"},
        {code:"IE",en:"Ireland",da:"Irland"},
        {code:"IR",en:"Iran",da:"Iran"},
        {code:"IQ",en:"Iraq",da:"Irak"},
        {code:"IS",en:"Iceland",da:"Island"},
        {code:"IL",en:"Israel",da:"Israel"},
        {code:"IT",en:"Italy",da:"Italien"},
        {code:"JM",en:"Jamaica",da:"Jamaica"},
        {code:"JE",en:"Jersey",da:"Italien"},
        {code:"JO",en:"Jordan",da:"Jordan"},
        {code:"JP",en:"Japan",da:"Japan"},
        {code:"KZ",en:"Kazakhstan",da:"Kasakhstan"},
        {code:"KE",en:"Kenya",da:"Kenya"},
        {code:"KG",en:"Kyrgyzstan",da:"Kirgisistan"},
        {code:"KH",en:"Cambodia",da:"Cambodja"},
        {code:"KI",en:"Kiribati",da:"Kiribati"},
        {code:"KN",en:"Saint Kitts and Nevis",da:"Saint Kitts og Nevis"},
        {code:"KR",en:"South Korea",da:"Sydkorea"},
        {code:"XV",en:"Kosovo",da:"Samoa"},
        {code:"KW",en:"Kuwait",da:"Kuwait"},
        {code:"LA",en:"Laos",da:"Laos"},
        {code:"LB",en:"Lebanon",da:"Libanon"},
        {code:"LR",en:"Liberia",da:"Liberia"},
        {code:"LY",en:"Libya",da:"Libyen"},
        {code:"LC",en:"Saint Lucia",da:"Saint Lucia"},
        {code:"LI",en:"Liechtenstein",da:"Liechtenstein"},
        {code:"LK",en:"Sri Lanka",da:"Sri Lanka"},
        {code:"LS",en:"Lesotho",da:"Lesotho"},
        {code:"LT",en:"Lithuania",da:"Litauen"},
        {code:"LU",en:"Luxembourg",da:"Luxembourg"},
        {code:"LV",en:"Latvia",da:"Letland"},
        {code:"MO",en:"Macao S.A.R",da:"Macao"},
        {code:"MF",en:"Saint Martin",da:"Moldova"},
        {code:"MA",en:"Morocco",da:"Marokko"},
        {code:"MC",en:"Monaco",da:"Monaco"},
        {code:"MD",en:"Moldova",da:"Moldova"},
        {code:"MG",en:"Madagascar",da:"Madagaskar"},
        {code:"MV",en:"Maldives",da:"Maldiverne"},
        {code:"MX",en:"Mexico",da:"Mexico"},
        {code:"MH",en:"Marshall Islands",da:"Marshalløerne"},
        {code:"MK",en:"Macedonia",da:"Den Tidligere Jugoslaviske Republik Makedonien"},
        {code:"ML",en:"Mali",da:"Mali"},
        {code:"MT",en:"Malta",da:"Malta"},
        {code:"MM",en:"Myanmar",da:"Myanmar"},
        {code:"ME",en:"Montenegro",da:"Moldova"},
        {code:"MN",en:"Mongolia",da:"Mongoliet"},
        {code:"MP",en:"Northern Mariana Islands",da:"Nordmarianerne"},
        {code:"MZ",en:"Mozambique",da:"Mozambique"},
        {code:"MR",en:"Mauritania",da:"Mauretanien"},
        {code:"MS",en:"Montserrat",da:"Montserrat"},
        {code:"MU",en:"Mauritius",da:"Mauritius"},
        {code:"MW",en:"Malawi",da:"Malawi"},
        {code:"MY",en:"Malaysia",da:"Malaysia"},
        {code:"NA",en:"Namibia",da:"Namibia"},
        {code:"NC",en:"New Caledonia",da:"Ny Kaledonien"},
        {code:"NE",en:"Niger",da:"Niger"},
        {code:"NF",en:"Norfolk Island",da:"Norfolk Island"},
        {code:"NG",en:"Nigeria",da:"Nigeria"},
        {code:"NI",en:"Nicaragua",da:"Nicaragua"},
        {code:"NU",en:"Niue",da:"Niue"},
        {code:"NL",en:"Netherlands",da:"Nederlandene"},
        {code:"NO",en:"Norway",da:"Norge"},
        {code:"NP",en:"Nepal",da:"Nepal"},
        {code:"NR",en:"Nauru",da:"Nauru"},
        {code:"NZ",en:"New Zealand",da:"New Zealand"},
        {code:"OM",en:"Oman",da:"Oman"},
        {code:"PK",en:"Pakistan",da:"Pakistan"},
        {code:"PA",en:"Panama",da:"Panama"},
        {code:"PN",en:"Pitcairn Islands",da:"Pitcairn"},
        {code:"PE",en:"Peru",da:"Peru"},
        {code:"PH",en:"Philippines",da:"Filippinerne"},
        {code:"PW",en:"Palau",da:"Palau"},
        {code:"PG",en:"Papua New Guinea",da:"Papua Ny Guinea"},
        {code:"PL",en:"Poland",da:"Polen"},
        {code:"PR",en:"Puerto Rico",da:"Puerto Rico"},
        {code:"KP",en:"North Korea",da:"Nordkorea"},
        {code:"PT",en:"Portugal",da:"Portugal"},
        {code:"PY",en:"Paraguay",da:"Paraguay"},
        {code:"PS",en:"Palestine",da:"Puerto Rico"},
        {code:"PF",en:"French Polynesia",da:"Fransk Polynesien"},
        {code:"QA",en:"Qatar",da:"Qatar"},
        {code:"RO",en:"Romania",da:"Rumænien"},
        {code:"RU",en:"Russia",da:"Rusland"},
        {code:"RW",en:"Rwanda",da:"Rwanda"},
        {code:"EH",en:"Western Sahara",da:"Vestsahara"},
        {code:"SA",en:"Saudi Arabia",da:"Saudi-Arabien"},
        {code:"SD",en:"Sudan",da:"Sudan"},
        {code:"SS",en:"South Sudan",da:"Surinam"},
        {code:"SN",en:"Senegal",da:"Senegal"},
        {code:"SG",en:"Singapore",da:"Singapore"},
        {code:"GS",en:"South Georgia and South Sandwich Islands",da:"South Georgia og De Sydlige Sandwichøer"},
        {code:"SH",en:"Saint Helena",da:"Saint Helena"},
        {code:"SB",en:"Solomon Islands",da:"Salomonøerne"},
        {code:"SL",en:"Sierra Leone",da:"Sierra Leone"},
        {code:"SV",en:"El Salvador",da:"El Salvador"},
        {code:"SM",en:"San Marino",da:"San Marino"},
        {code:"SO",en:"Somalia",da:"Somalia"},
        {code:"PM",en:"Saint Pierre and Miquelon",da:"Saint Pierre og Miquelon"},
        {code:"RS",en:"Republic of Serbia",da:"Rumænien"},
        {code:"ST",en:"Sao Tome and Principe",da:"São Tomé og Príncipe"},
        {code:"SR",en:"Suriname",da:"Surinam"},
        {code:"SK",en:"Slovakia",da:"Slovakiet"},
        {code:"SI",en:"Slovenia",da:"Slovenien"},
        {code:"SE",en:"Sweden",da:"Sverige"},
        {code:"SZ",en:"Swaziland",da:"Swaziland"},
        {code:"SX",en:"Sint Maarten",da:"El Salvador"},
        {code:"SC",en:"Seychelles",da:"Seychellerne"},
        {code:"SY",en:"Syria",da:"Syrien"},
        {code:"TC",en:"Turks and Caicos Islands",da:"Turks- og Caicosøerne"},
        {code:"TD",en:"Chad",da:"Tchad"},
        {code:"TG",en:"Togo",da:"Togo"},
        {code:"TH",en:"Thailand",da:"Thailand"},
        {code:"TJ",en:"Tajikistan",da:"Tadsjikistan"},
        {code:"TM",en:"Turkmenistan",da:"Turkmenistan"},
        {code:"TL",en:"East Timor",da:"Østtimor"},
        {code:"TO",en:"Tonga",da:"Tonga"},
        {code:"TT",en:"Trinidad and Tobago",da:"Trinidad og Tobago"},
        {code:"TN",en:"Tunisia",da:"Tunesien"},
        {code:"TR",en:"Turkey",da:"Tyrkiet"},
        {code:"TW",en:"Taiwan",da:"Taiwan"},
        {code:"TZ",en:"United Republic of Tanzania",da:"Tanzania"},
        {code:"UG",en:"Uganda",da:"Uganda"},
        {code:"UA",en:"Ukraine",da:"Ukraine"},
        {code:"UY",en:"Uruguay",da:"Uruguay"},
        {code:"US",en:"United States of America",da:"USA"},
        {code:"UZ",en:"Uzbekistan",da:"Usbekistan"},
        {code:"VA",en:"Vatican",da:"Vatikanstaten"},
        {code:"VC",en:"Saint Vincent and the Grenadines",da:"Saint Vincent og Grenadinerne"},
        {code:"VE",en:"Venezuela",da:"Venezuela"},
        {code:"VG",en:"British Virgin Islands",da:"De Britiske Jomfruøer"},
        {code:"VI",en:"United States Virgin Islands",da:"De Amerikanske Jomfruøer"},
        {code:"VN",en:"Vietnam",da:"Vietnam"},
        {code:"VU",en:"Vanuatu",da:"Vanuatu"},
        {code:"WF",en:"Wallis and Futuna",da:"Wallis og Futunaøerne"},
        {code:"WS",en:"Samoa",da:"Samoa"},
        {code:"YE",en:"Yemen",da:"Yemen"},
    ];

    static selectCodeElement(feature:ReferenceGeomFeatureCountry):string {
        return feature && feature.properties && feature.properties.iso_a2;
    }

    static selectNameElement(feature:ReferenceGeomFeatureCountry):string {
        return feature && feature.properties && feature.properties.admin;
    }

    static lookupByCountryCode(code:string) {
        var dataSet = ReferenceGeomWorld.countryTranslationTable;
        var result = dataSet.find((feature) => {
            return ReferenceGeomWorld.equalityFuncCountryCode(feature.code, code); 
            });
        return(result);
    }

    static equalityFuncCountryCode(prop: string, code:string) {
        return (prop.toLowerCase() === code.toLowerCase());
    }

    static lookupByCountryName(name:string) {
        var dataSet = ReferenceGeomWorld.countryTranslationTable;
        var result = dataSet.find((feature) => {
            return ReferenceGeomWorld.equalityFuncCountryName(feature.en, name); 
            });
        return(result);
    }

    static equalityFuncCountryName(prop: string, code:string) {
        return (prop.toLowerCase() === code.toLowerCase() || Boolean(ReferenceGeomWorld.countryTranslationTable.find(
                (obj)=> { return Boolean(obj.da && obj.en && obj.da.toLowerCase() === code.toLowerCase() && obj.en.toLowerCase() === prop.toLowerCase());})
            ));
    }

    static getAllCountryCodeNamePairs():{code:string,name:string}[] {
        const refGeo:ReferenceGeomTypeWorld = ReferenceGeomWorld.getReferenceGeoWorld();
        let result:{code:string,name:string}[] = [];
        refGeo.features.forEach(feature => {
            result.push({code:feature.properties.iso_a2, name:feature.properties.admin});
        });
        return result;
    }

    /**
     * returns english name if found; otherwise undefined
     */
    static lookupCountryTranslation(danish:string):string|undefined {
        let result = ReferenceGeomWorld.countryTranslationTable.find(({en, da}) => {
            return ReferenceGeomWorld.equalityFuncCountryName(da,danish);
        });
        return result && result.en;
    }

    static hasRefGeoBeenLoaded():boolean {
        return ReferenceGeomWorld.refGeom && !ReferenceGeomWorld.isLoading;
    }

    static isLoadingReferenceGeo():boolean {
        return ReferenceGeomWorld.isLoading;
    }

    static loadReferenceGeo():Promise<void> {
        let res = new Promise<void>((resolve, reject) => {
            if (ReferenceGeomWorld.hasRefGeoBeenLoaded()) {
                resolve();
            } else {
                Utils.asyncFetchJsonFromUrl(ReferenceGeomWorld.dataUrl)
                .then((data) => {
                    ReferenceGeomWorld.refGeom = data;
                    ReferenceGeomWorld.isLoading = false;
                    resolve();
                }).catch((err) => {
                    ReferenceGeomWorld.isLoading = false;
                    reject("Error loading geojson: "+err.message);
                });
                ReferenceGeomWorld.isLoading=true;    
            }
        });
        return res;
    }

    static getReferenceGeoWorld():ReferenceGeomTypeWorld {
        // var ref_geom={
        //     "type": "FeatureCollection",
        //     "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        //     "features": []
        // };
        return(ReferenceGeomWorld.refGeom);
    }

}