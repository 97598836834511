import { Localization, Logger, SettingsManager } from "@viamap/viamap2-common";
import * as M from 'maplibre-gl';
import React, { DOMElement } from "react";
import {  LayerHierarchy, LayerHierarchyEndNode, LayerInfo, MapInfo, PictureLayoutType, PictureSize, PopupLayout, SessionInfo, ViewerState } from "src/common/managers/Types";

import * as turf from '@turf/turf';
import { MapFacadeAbstract, MitLatLng, MitLayerHandle, MitMap } from "src/managers/MapFacade";
import { MapFacadeMaplibre, MitLayerHandleMaplibre } from "src/managers/MapFacadeMaplibre";
import { MapitLayerFactory } from "src/components/MapitLayerFactory";
import { OldHierarchy } from "src/managers/OldHierarchy";
import { Utils } from "@viamap/viamap2-common";
import { ReferenceGeomComposite } from "src/managers/ReferenceGeomComposite";
import { DemographyReportMetaData } from "src/components/DemographyReport";
import { MapitUtils } from "src/managers/MapitUtils";
import { CatchmentSelectionState } from "src/components/CatchmentSelector";
import { Feature, FeatureType } from "./ApplicationState";
import { FeatureLayer, FeatureLayerList } from "src/managers/WmsLayerFunc";
import { LayerSpecs, MapState } from "src/compentsModus/EmbeddedVectorMapEnhanced";
import { DynamicDataDrivenLayer, DynamicDataDrivenLayerFunc, parseFeatureLayer } from "src/managers/DynamicDataDrivenLayer";
import { VectorLayer } from "src/managers/VectorLayerFunc";

export enum MessageType {
   Error = "Error",
   Success = "Success"
}

// --------------------------------- Map Types --------------------------------------------
export type MitEvent = any;
// export type MitMap = any;
export enum MapType { Leaflet, Mapbox, Maplibre, Undefined }
export type MitBackgroundLayerHandle = string;
export type MitFeatureLayerHandle = string;
export type MitLayerId = number;
export type MitPopup = {
   popup: M.Popup,
   container: HTMLDivElement,
   latlng: MitLatLng,
   content: React.ReactNode,
   key:string
}
// export type MitLayerHandleMaplibre = MitLayerHandle;
export type MitId = string;


export type PopUpFeatureAvailability = { [featureNumber: string]: { 
   available: boolean, 
   stateActions?: {state:string, action: MapitStateActions | {type:any, payload:{name:string, pos:[number,number] ,tags?:string[], key?:string}}},
   callback?: any  // reverse compatibility. ToDo: must be removed.
} 
};

export type DataDrivenBackgroundLayer = {
   title:string,
   onMove: (map: MapFacadeAbstract, newLocation: MapState) => any
}

export enum MapitWindowId {
  ShowSearchField, InformationWindow, GettingStarted, ShowReleases, Legend, ShowLegendButton, DemographyReportDialog, StreetView,
  SelectCircle, GenerateReportDialog, GenerateReportResultDialog, PropertyInformationDialog, CompanyInformationDialog, ObliqueViewer, SaveMapDialog, SaveLinkDialog, SavedLinkDialog,
  CatchmentSelector, SpatialSelection, CustomerSettingsEditor, UserSettingsEditor, MapSettingsEditor, PointDistanceTable, ConfirmLayerList, FileTooBig, NameOnCreation, DownloadLayerDialog, GDPRNoticeApproval,
  CloudSaveDialog, ViewCloudSaveDialog , AdminCloudSaveDialog, PersonInformationDialog, OwnerShipDiagram, CondominiumDialog, MapLinkOverview
};

export enum MapInteractionState { Normal, ClickToSetMarker, ClickToShowPropertyInfo, ClickToShowTravelTimePolygons, ClickToShowObliquePhotos, ClickToShowStreetView, QueryFeatures, ClickToShowPointDistanceTable, Override, EnforceFeatureLayerPopup, HentVurderingsEjendom, HentSamletFastEjendom, ZoomedIn, NSZoomedIn };


// ---------------------------------------------------- STATE --------------------------------------------------------------
/*
Baggrundskortlag:
Lag som Viamap, Ortophoto, Dark etc.

Temalag:
Lag som Matrikel, Ejerlav, lokalplaner

Datalag:
Lag importeret fra Excel, Gjson osv.
*/

export interface MapitState {
   // ------- housekeepeing
   activeTransactions: { [guid: string]: any },
   message: string,
   messageType: MessageType,

   mapInfo: MapInfo,
   mapInteractionState: MapInteractionState,
   mapInteractionStateDefault: MapInteractionState,
   viewerState?: ViewerState, // zoom, tilt, ...

   // colorRanges: any[],
   mapObjectRef?: MitMap,
   map: MitMap,
   addedAttributions: number,

   backgroundLayers: { [layerId: MitId]: string },
   selectedBackgroundLayerKey: string,
   showBackgroundLayer: { [layerId: MitId]: boolean },

   featureLayers: { [layerId: MitId]: FeatureLayer },
   selectedFeatureLayerKeys: string[],

   layers: LayersType,
   layerHierarchy: LayerHierarchy,

   // ------- window states by id
   showWindow: ShowWindowType;
   mapPopout: MitPopup[];

   // ------- special states
   poiTypesToShow:string[];
   poiLayerHandle:MitLayerHandleMaplibre;
   viewPoi: boolean;
   // catchmentState: CatchmentState;

   // ------- data driven layers
   EsDrivenLayers: DataDrivenBackgroundLayer[],

   showUserLocation: boolean;
   demographyReport?: {metadata:DemographyReportMetaData, result:any}

   // ---------------------------- utility functions (read only) --------------------------
   getNextLayerId: (mapitState: MapitState) => MitLayerId;
}

export type ShowWindowType = { 
   [windowId: string]: {
      stackingOrder: number,
      parameters?: any 
   }
};

export type LayersType = { [layerId: MitId]: LayerInfo };

// --------------------------------- Utils --------------------------------------------

export function getNewId(): MitId {
   return MapitUtils.getNewUuid();
}

// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialMapitState(legend?:boolean): MapitState {
   const DefaultMapInfo: MapInfo = {
      popUpPictureLayout: PictureLayoutType.Center,
      popUpPictureSize: PictureSize.Medium,
      popUpLayout: PopupLayout.ShowAll,
      customerIconURL:  "", // SettingsManager.getSystemSetting("customerIconURL", "", true),
      customerIconLink: "", // SettingsManager.getSystemSetting("customerIconLink", "", true)
   };

   return {
      activeTransactions: {},
      message: "",
      messageType: MessageType.Success,
      mapPopout: [],

      showBackgroundLayer: {},
      layers: {},
      backgroundLayers: { },
      featureLayers: {},
      selectedBackgroundLayerKey: 'Map',
      selectedFeatureLayerKeys: [],
      layerHierarchy: { id: 0, name: "Top Level", isCollapsed: false, children: [] },
      addedAttributions: 0,

      // colorRanges: [],
      // sessionInfo: 1 as unknown as SessionInfo,
      mapInfo: DefaultMapInfo,
      mapObjectRef: undefined,
      map: 1 as unknown as MitMap,
      mapInteractionState: MapInteractionState.Normal,
      mapInteractionStateDefault: MapInteractionState.Normal,

      showWindow: legend ? {[MapitWindowId.Legend]:{stackingOrder:1} } : {},
      showUserLocation: false,

      poiTypesToShow:[],
      poiLayerHandle: new MitLayerHandleMaplibre(),
      viewPoi: true,
      // catchmentState: defaultCatchmentState,

      EsDrivenLayers:[],

      // ---------------------------- utility functions (read only) --------------------------
      getNextLayerId() {
         // Assign a unique layerid
         // find max existing layerId. First layerId will be 1.
         let maxVal = 0;
         Object.keys(this.layers).forEach((key) => {
            let val = this.layers[key].layerId;
            console.assert(!isNaN(val), "Array element is not a number");
            if (val > maxVal) { maxVal = val; }
         });
         return maxVal + 1;

      }
   }
}

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------

export enum MapitStateActionType {
   TxStarted,
   TxCompleted,
   TxFailed,
   
   Restore,
   InitializeStandardLayers,
   AddExternalFeatureLayers,

   MapHasMoved, // To allow dynamic fetching of data for the viewport. E.g. POI's

   SetMessage,
   SetMapObjectRef, // Store the map object, to allow setting data directly.
   SetShowUserLocation,
   SetShowWindow,
   SetMapInteractionState,
   SetMapInteractionStateDefault,
   SetPoiTypesToShow,
   SetViewPoi,
   UpdateMapInfo,
   SetMapViewerState,

   // -------- styling FeatureLayer -----
   StyleFeature,
   // --------- what to show -------------------
   SelectBackgroundLayer,
   SetFeatureLayerVisibility,
   SetDataLayerVisibility,
   // ---------- update layer list -------------
   AddDataLayer,
   UpdateDataLayer,
   RemoveDataLayer,
   ReserveLayerId,
   // ---------- update layer hierarchy --------
   AddLayerToHierarchyAtTopLevel,
   UpdateLayerHierarchy,
   RemoveLayerInHierarchy,
   SetLayerHierarchy,
   UpdateNameInLayerHierarchy,
   // ----------- Popup functions --------------
   AddPopup, ClosePopups,
   // ----------- demography report ------------
   GenerateDemographyReportSuccess,
   
   // ----------- catchment areas --------------
   // SetCatchmentState,
   // GenerateCatchment
}

export interface TxStarted {
   type: MapitStateActionType.TxStarted;
   payload: { guid: string, action: MapitStateActions };
}

export interface TxCompleted {
   type: MapitStateActionType.TxCompleted;
   payload: { guid: string, action: MapitStateActions };
}

export interface TxFailed {
   type: MapitStateActionType.TxFailed;
   payload: { guid: string, action: MapitStateActions, error: any };
}

export interface InitializeStandardLayers {
   type: MapitStateActionType.InitializeStandardLayers;
   payload: { hasAccessToFeatureFunction: (feat: FeatureType) => boolean };
}

export interface Restore {
   type: MapitStateActionType.Restore;
}

export interface AddExternalFeatureLayers {
   type: MapitStateActionType.AddExternalFeatureLayers;
   payload: { layers: FeatureLayerList}
}
export interface SetMessage {
   type: MapitStateActionType.SetMessage;
   payload: { item: string };
}

export interface SetMapInteractionState {
   type: MapitStateActionType.SetMapInteractionState;
   payload: { value: MapInteractionState };
}

export interface SetMapInteractionStateDefault {
   type: MapitStateActionType.SetMapInteractionStateDefault;
}

export interface SelectBackgroundLayer {
   type: MapitStateActionType.SelectBackgroundLayer;
   payload: { key: MitBackgroundLayerHandle };
}

export interface SetFeatureLayerVisibility {
   type: MapitStateActionType.SetFeatureLayerVisibility;
   payload: { key: MitFeatureLayerHandle, visible: boolean, layer: FeatureLayer | undefined};
}

export interface AddDataLayer {
   type: MapitStateActionType.AddDataLayer;
   payload: { layerInfo: LayerInfo, zoomToFocus?: boolean, showAsDefault?:boolean, featureAvailability?: PopUpFeatureAvailability};
}

export interface RemoveDataLayer {
   type: MapitStateActionType.RemoveDataLayer;
   payload: { id: MitLayerId, keepInHierarchy?: boolean };
}


export interface ReserveLayerId {
   type: MapitStateActionType.ReserveLayerId;
   payload: { id: MitLayerId };
}
export interface UpdateDataLayer {
   type: MapitStateActionType.UpdateDataLayer;
   payload: { id: MitLayerId, layerInfo: LayerInfo };
}

export interface SetDataLayerVisibility {
   type: MapitStateActionType.SetDataLayerVisibility;
   payload: { id: MitLayerId, visible: boolean };
}

export interface SetMapObjectRef {
   type: MapitStateActionType.SetMapObjectRef;
   payload: { item: MitMap };
}

export interface SetShowWindow {
   type: MapitStateActionType.SetShowWindow;
   payload: { item: MapitWindowId, show: boolean, parameters?:any };
}

export interface StyleFeature {
   type: MapitStateActionType.StyleFeature;
   payload: { key: string, value: any};
}

export interface SetShowUserLocation {
   type: MapitStateActionType.SetShowUserLocation;
   payload: { show: boolean };
}

export interface SetPoiTypesToShow {
   type: MapitStateActionType.SetPoiTypesToShow;
   payload: { types:string[] };
}

export interface SetViewPoi {
   type: MapitStateActionType.SetViewPoi;
   payload: { active:boolean };
}

export interface UpdateMapInfo {
   type: MapitStateActionType.UpdateMapInfo;
   payload: { mapInfo:MapInfo };
}

export interface SetMapViewerState {
   type: MapitStateActionType.SetMapViewerState;
   payload: { state:ViewerState };
}

// export interface SetLayers {
//    type: MapitStateActionType.SetLayers;
//    payload: { item: LayersType };
// }
export interface SetLayerHierarchy {
   type: MapitStateActionType.SetLayerHierarchy;
   payload: { hierarchy: LayerHierarchy };
}
export interface AddLayerToHierarchyAtTopLevel {
   type: MapitStateActionType.AddLayerToHierarchyAtTopLevel;
   payload: { id: number };
}
export interface RemoveLayerInHierarchy {
   type: MapitStateActionType.RemoveLayerInHierarchy;
   payload: { id: number };
}
export interface UpdateLayerHierarchy {
   type: MapitStateActionType.UpdateLayerHierarchy;
   payload: { id: number };
}
export interface UpdateNameInLayerHierarchy {
   type: MapitStateActionType.UpdateNameInLayerHierarchy;
   payload: { hierarchy: LayerHierarchy}
}
export interface AddPopup {
   type: MapitStateActionType.AddPopup;
   payload: {latlng: MitLatLng; content: React.ReactNode, closeOthers: boolean}
}

export interface ClosePopups {
   type: MapitStateActionType.ClosePopups;
   payload: {closeAll: boolean}
}


export interface GenerateDemographyReportSuccess {
   type: MapitStateActionType.GenerateDemographyReportSuccess;
   payload: { metadata: DemographyReportMetaData, result:any };
}

// export interface SetCatchmentState {
//    type: MapitStateActionType.SetCatchmentState;
//    payload: { state: CatchmentState };
// }

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: MapitStateActions): TxStarted => ({
   type: MapitStateActionType.TxStarted,
   payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: MapitStateActions): TxCompleted => ({
   type: MapitStateActionType.TxCompleted,
   payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: MapitStateActions, error: any): TxFailed => ({
   type: MapitStateActionType.TxFailed,
   payload: { guid, action, error }
});

export const actionSetMessage = (item: string): SetMessage => ({
   type: MapitStateActionType.SetMessage,
   payload: { item }
});

// export const actionSetSessionInfo = (item: SessionInfo): SetSessionInfo => ({
//    type: MapitStateActionType.SetSessionInfo,
//    payload: { item }
// });

export const actionStyleFeature = (key: string, value: any):StyleFeature => ({
   type: MapitStateActionType.StyleFeature,
   payload: { key, value},
})

export const actionSetShowWindow = (item: MapitWindowId, show: boolean, parameters?:any): SetShowWindow => ({
   type: MapitStateActionType.SetShowWindow,
   payload: { item, show, parameters }
});

export const actionSetMapInteractionState = (value: MapInteractionState): SetMapInteractionState => ({
   type: MapitStateActionType.SetMapInteractionState,
   payload: { value }
});

export const actionSetMapInteractionStateDefault = (): SetMapInteractionStateDefault => ({
   type: MapitStateActionType.SetMapInteractionStateDefault
})

export const actionSetShowUserLocation = (show: boolean): SetShowUserLocation => ({
   type: MapitStateActionType.SetShowUserLocation,
   payload: { show }
});

export const actionSetPoiTypesToShow = (types: string[]): SetPoiTypesToShow => ({
   type: MapitStateActionType.SetPoiTypesToShow,
   payload: { types }
});

export const actionSetViewPoi = (active: boolean): SetViewPoi => ({
   type: MapitStateActionType.SetViewPoi,
   payload: { active }
});

export const actionUpdateMapInfo = (mapInfo: MapInfo): UpdateMapInfo => ({
   type: MapitStateActionType.UpdateMapInfo,
   payload: { mapInfo:mapInfo }
});

export const actionSetMapViewerState = (state: ViewerState): SetMapViewerState => ({
   type: MapitStateActionType.SetMapViewerState,
   payload: { state }
});

export const actionAddDataLayer = (layerInfo: LayerInfo, zoomToFocus?: boolean, showAsDefault:boolean=true, featureAvailability?:PopUpFeatureAvailability): AddDataLayer => ({
   type: MapitStateActionType.AddDataLayer,
   payload: { layerInfo, zoomToFocus, showAsDefault, featureAvailability }
});

export const actionUpdateDataLayer = (id: MitLayerId, layerInfo: LayerInfo): UpdateDataLayer => ({
   type: MapitStateActionType.UpdateDataLayer,
   payload: { layerInfo, id }
});

export const actionRemoveDataLayer = (id: MitLayerId, keepInHierarchy?: boolean): RemoveDataLayer => ({
   type: MapitStateActionType.RemoveDataLayer,
   payload: { id , keepInHierarchy}
});

export const actionSetFeatureLayerVisibility = (key: MitFeatureLayerHandle, visible: boolean, layer?:FeatureLayer): SetFeatureLayerVisibility => ({
   type: MapitStateActionType.SetFeatureLayerVisibility,
   payload: { key, visible, layer}
});

export const actionSetDataLayerVisibility = (id: MitLayerId, visible: boolean): SetDataLayerVisibility => ({
   type: MapitStateActionType.SetDataLayerVisibility,
   payload: { id, visible }
});

export const actionSelectBackgroundLayer = (key: MitBackgroundLayerHandle): SelectBackgroundLayer => ({
   type: MapitStateActionType.SelectBackgroundLayer,
   payload: { key }
});

export const actionSetLayerHierarchy = (hierarchy: LayerHierarchy): SetLayerHierarchy => ({
   type: MapitStateActionType.SetLayerHierarchy,
   payload: { hierarchy }
});

export const actionUpdateNameInLayerHierarchy = (hierarchy: LayerHierarchy) : UpdateNameInLayerHierarchy => ({
   type: MapitStateActionType.UpdateNameInLayerHierarchy,
   payload: { hierarchy }
});

export const actionAddPopup = (latlng: MitLatLng, content: React.ReactNode, closeOthers: boolean) : AddPopup => ({
   type: MapitStateActionType.AddPopup,
   payload: { latlng, content, closeOthers }

})
export const actionClosePopups = (closeAll:boolean): ClosePopups => ({
   type: MapitStateActionType.ClosePopups,
   payload: { closeAll }
})

export const actionAddExternalFeatureLayers = (layers: FeatureLayerList) : AddExternalFeatureLayers => ({
   type: MapitStateActionType.AddExternalFeatureLayers,
   payload: { layers }
})

// export const actionSetCatchmentState = (state: CatchmentState) : SetCatchmentState => ({
//    type: MapitStateActionType.SetCatchmentState,
//    payload: { state }

// })

export const actionGenerateDemographyReportSuccess = (metadata: DemographyReportMetaData, result:any) : GenerateDemographyReportSuccess => ({
   type: MapitStateActionType.GenerateDemographyReportSuccess,
   payload: { metadata, result }

})

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------

export type MapitStateActionsTransActional = SetMessage | AddDataLayer;
export type MapitStateActionsNonTransActional = TxStarted | TxCompleted | TxFailed |
   SetMessage | SelectBackgroundLayer |
   SetFeatureLayerVisibility |
   ReserveLayerId |
   AddDataLayer |
   UpdateDataLayer |
   RemoveDataLayer |
   SetMapObjectRef |
   StyleFeature |
   // SetSessionInfo |
   SetDataLayerVisibility |
   SetShowWindow |
   SetMapInteractionState | SetMapInteractionStateDefault |
   // SetLayers | 
   SetLayerHierarchy |
   SetShowUserLocation |
   SetMapViewerState |
   AddLayerToHierarchyAtTopLevel |
   UpdateLayerHierarchy |
   RemoveLayerInHierarchy |
   UpdateNameInLayerHierarchy |
   AddPopup | ClosePopups |
   AddExternalFeatureLayers |
   InitializeStandardLayers | GenerateDemographyReportSuccess | SetPoiTypesToShow | SetViewPoi | UpdateMapInfo | Restore
   // | SetCatchmentState
   ;
export type MapitStateActions = MapitStateActionsTransActional | MapitStateActionsNonTransActional;


// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalMapitStateReducer(action: MapitStateActionsTransActional, dispatch: any) {

   switch (action.type) {
      // case MapitStateActionType.SetMessage: {
      //    }
      //    break;
      case MapitStateActionType.AddDataLayer: {

    //   // check to see that all needed ReferenceGeom has loaded.
    //   // otherwise async 
      if (MapitUtils.isAreaLayer(action.payload.layerInfo.type)) {
        if (!ReferenceGeomComposite.isRequiredGeomDataLoaded(action.payload.layerInfo.type)) {
         let txId=Date.now().toString();
         dispatch(actionTxStarted(txId, action));
         // ToDo: show a 'Loading...' message to the user.

         //  appMessageDispatch(actionSetInfoMessage(Localization.getText("Loading..."));
          // Create placeholder for pending layer creation - otherwise next layer will overwrite this position.
         dispatch({type:MapitStateActionType.ReserveLayerId, payload:{id:action.payload.layerInfo.layerId, layerInfo:action.payload.layerInfo}});
          ReferenceGeomComposite.ensureRequiredGeomData(action.payload.layerInfo.type)
          .then(data => {
            // loopback then loading is done.
            transactionalMapitStateReducer(action, dispatch);
          })
          .catch(error => {"Loading failed "+error.message
            dispatch(actionTxFailed(txId, action, "Loading failed "+error.message));
            // appMessageDispatch(actionSetErrorMessage();
          }).finally(()=> {
            dispatch(actionTxCompleted(txId, action));
            // appMessageDispatch(actionClearInfoMessage(;
          });
          return;
        }
         }
         dispatch(action);
         break;
      }

      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
   }
}

// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function MapitStateReducer(state: MapitState, action: MapitStateActions): MapitState {
   function enum2String(type: any, value: number): string {
      return Object.values<string>(type)[value];
   }

   switch (action.type) {

      case MapitStateActionType.TxStarted:
         return {
            ...state,
            activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action }
         };

      case MapitStateActionType.TxFailed: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         console.log(action.payload.error);
         let msg = Utils.formatString(Localization.getTextSafeMode("Error. Transaction {txname} returned with {error}"), {
            txname: Localization.getTextSafeMode(MapitStateActionType[action.payload.action.type]),
            error: Localization.getTextSafeMode(action.payload.error)
         });
         // Logger.logError("viamapLiceningState", MapitStateActionType[action.payload.action.type], msg);
         return {
            ...state,
            message: msg,
            messageType: MessageType.Error
         };
      }

      case MapitStateActionType.TxCompleted: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         return {
            ...state,
            activeTransactions: tmp,
            message: ""
         };
      }

      // ---------------------------------- initialization ---------------------------------------------------

      case MapitStateActionType.InitializeStandardLayers: {
         function getFeatureLayerList():FeatureLayer[] {
               let customLayerSetting = SettingsManager.getSystemSetting("customBackgroundLayers");
               let standardLayerSetting = SettingsManager.getSystemSetting("standardBackgroundLayers");
               let dynamicLayerSetting = SettingsManager.getSystemSetting("dynamicBackgroundLayers");
               let vectorLayerSetting = SettingsManager.getSystemSetting("vectorBackgroundLayers");
               return [...customLayerSetting,...standardLayerSetting,...dynamicLayerSetting,...vectorLayerSetting].map((a) => {
                  return parseFeatureLayer(a)
               }).filter((a) => a) as FeatureLayer[]
            }

         let selectableLayers: FeatureLayerList = {};
         let featureLayers = getFeatureLayerList();
         featureLayers && featureLayers.forEach((a, idx) => {
            a.layer.priority = a.layer.priority === 999 ? idx + 10 : a.layer.priority
         })
         featureLayers && featureLayers.sort((a, b) => a.layer.priority - b.layer.priority).forEach((featureLayer: FeatureLayer, idx) => {
            const ftl = featureLayer.layer
            const inActive = ("isActive" in ftl && ftl.isActive === false)
            if (action.payload.hasAccessToFeatureFunction(ftl.licenseFeature) && !inActive) {
               selectableLayers = {
                  ...selectableLayers,
                  [ftl.label]:featureLayer
               };
            }
         });

         let backgroundLayers:{[key:string]:string} = { 'Map': 'Viamap', 'Orthophoto': 'Aerial' /*, 'd':'GeoDanmark', 's':'Støj' */ };
         if (action.payload.hasAccessToFeatureFunction(Feature.MapStyleAerialNoMask)) {
            backgroundLayers = {...backgroundLayers, 'OrthophotoNoWaterMask': 'Areal not masked'};
         }
         if (action.payload.hasAccessToFeatureFunction(Feature.MapStyleBlank)) {
            backgroundLayers = {...backgroundLayers, 'BlankMap': 'Blank Map'};
         }

         return {
            ...state,
            backgroundLayers: backgroundLayers,
            featureLayers: selectableLayers
         };
      }

      case MapitStateActionType.Restore: {
         const orderedList = OldHierarchy.getAllIdOrderedTopBottom(state.layerHierarchy)
         orderedList.toReversed().forEach((key) => {
            const layerInfo = state.layers[key];
            if (!layerInfo) {
               return
            }
            layerInfo.handle.addTo(state.map)
            state.map.setLayerVisibility(layerInfo.handle, (layerInfo.visible) ?? true)
         })

         state.selectedFeatureLayerKeys.map((a) => {
            let layerSpec = state.featureLayers[a]
            if (layerSpec instanceof VectorLayer) {
               state.map.addVectorLayer(layerSpec);
               return
            }
            if (layerSpec) {
               state.map.addFeatureLayer(a, layerSpec);
            }
         })
         return {
            ...state
         }
      }

      case MapitStateActionType.AddExternalFeatureLayers: {
         let selectableLayers: FeatureLayerList = {};
         let featureLayers = Object.keys(action.payload.layers).map((a) => parseFeatureLayer(action.payload.layers[a])).filter((a) => a) as FeatureLayer[];
         featureLayers && featureLayers.forEach((a, idx) => {
            a.layer.priority = a.layer.priority === 999 ? idx + 10 : a.layer.priority
         })
         featureLayers && featureLayers.sort((a, b) => a.layer.priority - b.layer.priority).forEach((featureLayer: FeatureLayer, idx) => {
            const ftl = featureLayer.layer
            selectableLayers = {
               ...selectableLayers,
               [ftl.label]:featureLayer
            };
         });

         return {
            ...state,
            featureLayers: {...state.featureLayers, ...selectableLayers},
         };
      }

      // --------------------------------- setters -----------------------------------------------------
      case MapitStateActionType.SetMessage: {
         return {
            ...state, message: action.payload.item
         };
      }


      case MapitStateActionType.SetMapInteractionState: {

         if ([MapInteractionState.Override, MapInteractionState.Normal, MapInteractionState.ZoomedIn, MapInteractionState.NSZoomedIn].includes(action.payload.value)) { 
            return {
               ...state,
               mapInteractionStateDefault: action.payload.value,
               mapInteractionState: action.payload.value
            };
         }

         return {
            ...state,
            mapInteractionState: action.payload.value
         };
      }

      case MapitStateActionType.SetMapInteractionStateDefault: {
         return {
            ...state,
            mapInteractionState: state.mapInteractionStateDefault
         };
      }

      case MapitStateActionType.SetMapObjectRef: {
         return {
            ...state,
            mapObjectRef: action.payload.item,
            map: action.payload.item
         };
      }

      case MapitStateActionType.SetPoiTypesToShow: {
         return {
            ...state,
            poiTypesToShow: action.payload.types
         };
      }

      case MapitStateActionType.SetViewPoi: {
         return {
            ...state,
            viewPoi: action.payload.active
         }
      }

      case MapitStateActionType.SetShowUserLocation: {
         state.map.showUserLocation(action.payload.show);
         return {
            ...state,
            showUserLocation: action.payload.show
         };
      }

      case MapitStateActionType.SetShowWindow: {
         const maxZIndex = Object.keys(state.showWindow).reduce((a,b) => {return (state.showWindow[b].stackingOrder > a ? state.showWindow[b].stackingOrder : a)} ,0)
         return {
            ...state,
            showWindow: { ...state.showWindow, [action.payload.item]: {stackingOrder:action.payload.show ? maxZIndex + 1 : 0, parameters:action.payload.parameters } }
         };
      }

      // if (layerSpec instanceof DynamicDataDrivenLayer) {
      //    const newEsLayer:DataDrivenBackgroundLayer = {
      //       title: "Test",
      //       layerHandle: new MitLayerHandleMaplibre(),
      //       onMove: layerSpec.onMove
      //    }
      //    return {
      //       ...state,
      //       EsDrivenLayers: [
      //          ...state.EsDrivenLayers, newEsLayer
      //       ]
      //    }
      // } else {

      // if (layerSpec instanceof DynamicDataDrivenLayer) {
      //    return {
      //       ...state,
      //       selectedFeatureLayerKeys: [...state.selectedFeatureLayerKeys.filter((val) => { return val !== action.payload.key; })],
      //       EsDrivenLayers: []
      //    }
      // } else {

      case MapitStateActionType.SetFeatureLayerVisibility: {
         let layerSpec = parseFeatureLayer(action.payload.layer?.layer) || state.featureLayers[action.payload.key];
         if (layerSpec instanceof VectorLayer) {
            if (action.payload.visible) {
               state.map.addVectorLayer(layerSpec)
               return { 
                  ...state,
                  selectedFeatureLayerKeys: [...state.selectedFeatureLayerKeys.filter((val) => { return val !== action.payload.key; }), action.payload.key],
               }
            } else {
               state.map.removeVectorLayer(layerSpec)
               return {
                  ...state,
                  selectedFeatureLayerKeys: [...state.selectedFeatureLayerKeys.filter((val) => { return val !== action.payload.key; })],
               }
            }
         }
         if (!layerSpec) {
            return {...state}
         }
         let override = {};
         if (action.payload.layer?.layer) {
            if (!Object.keys(state.featureLayers).includes(action.payload.key) || state.featureLayers[action.payload.key].layer.group == "hidden") {
               layerSpec.layer.group = "hidden"
            }
            override = {featureLayers: {...state.featureLayers, [action.payload.key]: layerSpec}}
         }
         let NewEsDrivenLayers = [...state.EsDrivenLayers];
         const isDataDriven = layerSpec instanceof DynamicDataDrivenLayer
         if (isDataDriven) {
            NewEsDrivenLayers = NewEsDrivenLayers.filter((a) => a.title !== action.payload.key)
            if (action.payload.visible) { 
               NewEsDrivenLayers.push({
                  title: action.payload.key,
                  onMove: (a,b) => (layerSpec as DynamicDataDrivenLayer).onMove(a,b)
               });
               (new DynamicDataDrivenLayerFunc()).OnMoveHandler(state.map,(a,b) => (layerSpec as DynamicDataDrivenLayer).onAdd(a,b));
            } else {
               const old = state.EsDrivenLayers.filter((a) => a.title == action.payload.key)
               old.forEach((a) => {
                  let custom = (layerSpec as DynamicDataDrivenLayer).customHandleMaplibre
                  custom && state.map.removeLayerByHandle(custom)
               })
            }
         }
         if (action.payload.visible) {
            if (!state.selectedFeatureLayerKeys.includes(action.payload.key)) {
               (!isDataDriven) && state.map.addFeatureLayer(action.payload.key, layerSpec);
            }
            return {
               ...state,
               EsDrivenLayers: [...NewEsDrivenLayers],
               selectedFeatureLayerKeys: [...state.selectedFeatureLayerKeys.filter((val) => { return val !== action.payload.key; }), action.payload.key],
               ...override
            }
         } else {
            (!isDataDriven) && state.map.removeFeatureLayer(action.payload.key);
               return {
                  ...state,
                  EsDrivenLayers: [...NewEsDrivenLayers],
                  selectedFeatureLayerKeys: [...state.selectedFeatureLayerKeys.filter((val) => { return val !== action.payload.key; })],
                  ...override
               }
         };
      }

      // ------------------------------------------------- background layers -----------------------------------------
      
      case MapitStateActionType.SelectBackgroundLayer: {
         return {
            ...state,
            selectedBackgroundLayerKey: action.payload.key
         }
      }
      // ------------------------------------------------- feature layers -----------------------------------------------

      // ------------------------------------------------- data layers -----------------------------------------------
      case MapitStateActionType.AddLayerToHierarchyAtTopLevel: {
         let layerHierarchy = state.layerHierarchy;
         layerHierarchy.children.push({ id: action.payload.id });
         return {
            ...state,
            layerHierarchy: layerHierarchy
         };
      }

      case MapitStateActionType.UpdateMapInfo: {
         return {
            ...state,
            mapInfo: {...state.mapInfo, ...action.payload.mapInfo}
         }
      }

      
      case MapitStateActionType.SetMapViewerState: {
         return {
            ...state,
            viewerState: action.payload.state
         }
      }

      case MapitStateActionType.SetLayerHierarchy: {
         let ids = OldHierarchy.getAllIdOrderedTopBottom(action.payload.hierarchy).reverse()
         ids.map((a) => state.layers[a]?.handle.moveLayer(state.map));
         return {
            ...state,
            layerHierarchy: action.payload.hierarchy
         }
      }
      case MapitStateActionType.UpdateNameInLayerHierarchy: {
         const changes = action.payload.hierarchy;
         const parent = OldHierarchy.getParentGroup(changes.id, state.layerHierarchy)
         const id = parent?.children.findIndex((a) => a.id === changes.id)
         if (parent && (id !== undefined)) {
            parent.children[id] = {...changes}
         }
         return {
            ...state,
            layerHierarchy: {...state.layerHierarchy}
         }
      }

      case MapitStateActionType.ReserveLayerId: {
         return {
            ...state,
            layers: {...state.layers, [action.payload.id]:{}}
         }
      }

      case MapitStateActionType.SetDataLayerVisibility: {
         state.map.setLayerVisibility(state.layers[action.payload.id].handle , action.payload.visible)
         return {
            ...state, layers: {...state.layers, [action.payload.id]:{...state.layers[action.payload.id], visible: action.payload.visible}}
         }
      }

      case MapitStateActionType.AddPopup: {
         const popup = new M.Popup()
         const newCon = document.createElement('div');
         newCon.style.textAlign = "left";
         newCon.className = "MapitStatePopup";
         popup.setLngLat(action.payload.latlng);
         popup.setDOMContent(newCon);
         popup.setOffset({'bottom':[0,-20]} as any)
         popup.addTo(state.map.getMapPop() as any);
         if (action.payload.closeOthers) {
            state.mapPopout.forEach((a) => {
               a.popup.remove()
            })
         }
         return {...state, mapPopout: [...state.mapPopout.filter((a) => a.container.isConnected), {container: newCon, popup:popup, latlng: action.payload.latlng, content: action.payload.content, key: "id"+state.mapPopout.length}]}
      }

      case MapitStateActionType.ClosePopups: {
         if (action.payload.closeAll) {
            state.mapPopout.forEach((a) => {
               a.popup.remove()
            })
         }
         return {...state, mapPopout: [...state.mapPopout.filter((a) => a.container.isConnected)]}
      }

      case MapitStateActionType.StyleFeature: {
         const key = action.payload.key
         const layer = state.featureLayers[key]
         const layerCopy = layer.copy()
         const value = action.payload.value
         layer.layer.variantValues = value

         layer.setStyling(value)
         const newUrl = layerCopy.getTileURL() !== layer.getTileURL()
         const isDataDriven = layer instanceof DynamicDataDrivenLayer
         if (layer instanceof VectorLayer) {
            state.map.updateVectorLayer(layer, value)
            return {...state, featureLayers: {...state.featureLayers}}
         }
         if ((!isDataDriven) && state.selectedFeatureLayerKeys.includes(key)) {
            if (newUrl) {
               state.map.removeFeatureLayer(key)
               state.map.addFeatureLayer(key, layer)
            } else {
               state.map.updateFeatureLayer(key, layer)
            }
         }
         if ((isDataDriven) && state.selectedFeatureLayerKeys.includes(key)) {
            (layer as DynamicDataDrivenLayer).setDynStyling(state.map, value)
         }
         return {...state, featureLayers: {...state.featureLayers}}
      }

      case MapitStateActionType.AddDataLayer: {
         let _layerInfo:LayerInfo = {...action.payload.layerInfo}

         if (_layerInfo.layerId === -1) {
            _layerInfo.layerId = state.getNextLayerId(state);
         }

         // console.log("Adding layer id:",_layerInfo.layerId);
         _layerInfo.visible = _layerInfo.visible ?? action.payload.showAsDefault ?? true;
         let { layerHandle, layerInfo: newLayerInfo } = MapitLayerFactory.createADataLayer(state.map, _layerInfo, state.mapInfo, action.payload.featureAvailability || {});
         // console.log("action", JSON.stringify(action), "layerHandle", JSON.stringify(layerHandle));
         if (layerHandle) {
            
            const orderedList = OldHierarchy.getAllIdOrderedTopBottom(state.layerHierarchy)
            const beforeHandles = [...orderedList].splice(0,orderedList.findIndex((a) => a == newLayerInfo.layerId) + 1 || 0).filter((a) => a !== newLayerInfo.layerId)
            layerHandle.addTo(state.map, beforeHandles.map((a) => state.layers[a]?.handle).filter((a) => a));
            state.map.setLayerVisibility(layerHandle, (newLayerInfo.visible ?? _layerInfo.visible) ?? true)
            if (action.payload.zoomToFocus) {
               state.map.zoomToFeature(layerHandle);
            }
            
            // Store new layer and handle in state
            const TestInHierarchy = (a : LayerHierarchy | LayerHierarchyEndNode , id:number, idx?:number) => {
               let finished = false
               let _idx = idx ?? 0;
               if (_idx < 0) {
                  return true
               }
               let nextChildren:(LayerHierarchy | LayerHierarchyEndNode)[] = []
               if (a.id === newLayerInfo.layerId) {
                  return true
               }
               if (a.children) {
                  for (let i = 0; i < a.children.length; i++) {
                     const element = a.children[i];
                     let ReturnB = TestInHierarchy(element, id, _idx + 1)
                     if (ReturnB) {
                        return true
                     } 
                  }
               }
               return false
            } 

            let newLayerhi:LayerHierarchy = {...state.layerHierarchy}
            if (!TestInHierarchy(state.layerHierarchy, newLayerInfo.layerId)) {
               newLayerhi.children = ([{id: newLayerInfo.layerId}, ...newLayerhi.children]);
            }


            return {
               ...state,
               layers: { ...state.layers, [_layerInfo.layerId]: {...newLayerInfo} },
               layerHierarchy: newLayerhi as LayerHierarchy
            }
         } else {
            return state;
         }
      }

      case MapitStateActionType.RemoveDataLayer: {
         let handle = state.layers[action.payload.id].handle;
         handle.clearLayers(state.map)
         // clone to ensure that a changed object is returned to trigger effects
         let newLayers = {...state.layers};
         delete newLayers[action.payload.id];
         let newHierarchy;
         if (! action.payload.keepInHierarchy) {
            newHierarchy = OldHierarchy.removeLayerInHierarchy(action.payload.id, state.layerHierarchy);
         }
         return {
            ...state,
            layers: newLayers,
            layerHierarchy: newHierarchy || state.layerHierarchy
         };
      }

      case MapitStateActionType.UpdateDataLayer: {
         const handle = (action.payload.layerInfo.handle as MitLayerHandleMaplibre)
         let { layerHandle, layerInfo: newLayerInfo } = MapitLayerFactory.createADataLayer(state.map, action.payload.layerInfo, state.mapInfo,{});
         if (layerHandle) {
            handle.updateSource(
               state.map as MapFacadeMaplibre,
               layerHandle.source.data
            )
         }
         return {
            ...state, layers: {...state.layers, [action.payload.id]:action.payload.layerInfo}
         }
      }

      // ----------------------------------------- catchments ----------------------------------------------------------------------

      // case MapitStateActionType.SetCatchmentState: {
      //    return {
      //       ...state,
      //       catchmentState: action.payload.state
      //    }

      // }
      // ----------------------------------------- demography report ---------------------------------------------------------------
      case MapitStateActionType.GenerateDemographyReportSuccess: {
         return {
            ...state,
            demographyReport: {metadata:action.payload.metadata, result:action.payload.result}
         }
      }

      default:
         throw new Error("Unknown action " + (action as MapitStateActions).type);
   }
}

export const windowToBeShownImpl = (mapitState:MapitState, windowId:MapitWindowId) => {
   return (mapitState.showWindow && mapitState.showWindow[windowId] && mapitState.showWindow[windowId].stackingOrder) || 0;
}

export const windowToBeShownParametersImpl = (mapitState:MapitState, windowId:MapitWindowId) => {
   return mapitState.showWindow && mapitState.showWindow[windowId] && mapitState.showWindow[windowId].parameters;
}

// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const MapitStateContext = React.createContext<{
   state: MapitState,
   dispatch: React.Dispatch<MapitStateActions>,
   /** 
    * Returns the stacking order of the window or 0 if hidden
    * @example <caption>Hide inactive Window</caption>
    * if (!returnedValue) { return null }
    * @example <caption>Usage in zIndex</caption>
    * style={{zIndex:1000 + returnedValue , isolation:"isolate"}}
    * @returns 0 if Hidden, else return the stacking order of window
    */
   windowToBeShownOrder: (windowId: MapitWindowId) => number,
   windowToBeShownParameters: (windowId: MapitWindowId) => any
}>({
   state: initialMapitState(),
   dispatch: () => undefined,
   windowToBeShownOrder: (windowId: MapitWindowId) => 0,
   windowToBeShownParameters: (windowId: MapitWindowId) => undefined
});
export class GenericTransactionManager {

   static dispatchMiddleware<ActionTypes>(dispatch: any, transactionalReducer: (action: any, dispatch: any) => any) {

      return (action: ActionTypes) => {
         transactionalReducer(action, dispatch);
      };

   }
}
