import * as React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  Localization,
  AppMessagesContext,
  actionSetInfoMessage,
  actionSetProgressMessage,
} from "@viamap/viamap2-common";
import "./PropertyInformationDialog.css";
import { Cookies } from "@viamap/viamap2-common";
import { MdClose } from "react-icons/md";
import { usePanWindow } from "src/common/components/CustomHooks";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { MitButton, MitCheckBox } from "./ComponentUtils";
import {
  ProjectContext,
  handleToBeAdded,
  setInfo,
} from "src/states/ProjectState";
import { AddressInterface } from "src/managers/AddressInterface";
import { ProtectedFeature } from "./ProtectedFeature";
import { PropertyInfoView } from "src/propertyInfoTemplates/PropertyInfoView";
import { FiCopy, FiPrinter } from "react-icons/fi";
import { MitStorage } from "src/managers/MitStorage";
import { ViewButtonCheck, ViewButtonIcon, ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { ExportData } from "src/managers/ExportData";
import { PropertyData } from "src/propertyInfoTemplates/PropertyInfoCollectorTypes";
import { SheetFunc } from "src/managers/SheetFunc";

type Props = {
  showWindow: number;
  callBackOnClose: () => void;
  labelLatLng?: { latlng?: { lat: number; lng: number } };
  allowExtendedInformation: boolean;
  cols?: number;
};

export function PropertyInformationDialog(props: Props) {
  const [propertyDefaultCollapsed, setPropertyDefaultCollapsed] =
    React.useState(MitStorage.getValue<Boolean>("PropertyAutoColapsed"));
  const { hasAccessToFeature } = React.useContext(ApplicationStateContext);
  const { dispatch: appMessageDispatch } = React.useContext(AppMessagesContext);
  const { state: projState, dispatch: projDispatch } =
    React.useContext(ProjectContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [propertyData, setPropertyData] = React.useState<any>();

  React.useEffect(() => {
    setIsLoading(true);
  }, [props.labelLatLng]);

  if (!props.showWindow) {
    return;
  }

  function RemberCollapseSettings(e: any, val) {
    MitStorage.setValue<Boolean>("PropertyAutoColapsed", !val);
  }

  async function handleExportToExcel() {
    let wb = await (new ExportData()).createPropertyDataExcelExport(
      "Property Export",
      new Date(), 
      propertyData,         
      (progress:number) => {
        appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/1)));
     });
     let blob = SheetFunc.createBlob(wb);
     let fileName = "PropertyData"+(propertyData?.BBRRel?.bfeNummer || "");
     fileName += ".xlsx";
     ExportData.downloadBlob(fileName, blob);
  }

  let fold = (
      <ViewButtonCheck
        checked={!propertyDefaultCollapsed}
        className="no-print"
        onCheck={(e, val) => {
          RemberCollapseSettings(e, val);
          setPropertyDefaultCollapsed(val);
        }}
      >
        {Localization.getText("Expanded")}
      </ViewButtonCheck>
  );

  let closeButton = (
    <MdClose
      className="CloseButton no-print"
      onClick={() => props.callBackOnClose()}
    />
  );

  return (
    <AdvancedDragModal
      show={props.showWindow}
      style={{ zIndex: 1000 + props.showWindow, isolation: "isolate" }}
      className="mit-developer-information Flat-Modal unsetWidth Flat-PropertyView scrollbarHidden"
      headerContent={
        <>
          {/* <div style={{marginBottom:"120px"}}> */}
          <h4 className="no-print">Ejendomsinformation</h4>
          {/* <PrintHeader/> */}
          {/* </div> */}
          <ProtectedFeature
            feature={Feature.AddToProjectUnassignedList}
            contentsIfNoAccess={<></>}
          >
            <ViewButtonText
              className="no-print"
              onClick={async (e) => {
                if (projState.isEmpty) {
                  appMessageDispatch(
                    actionSetInfoMessage(
                      Localization.getText(
                        "Please visit the Project tabs, an action is needed!"
                      )
                    )
                  );
                  return;
                }
                if (
                  props.labelLatLng?.latlng?.lat &&
                  props.labelLatLng?.latlng?.lng
                ) {
                  let resp = await AddressInterface.reverseGeocodeFull(
                    props.labelLatLng?.latlng.lat,
                    props.labelLatLng?.latlng.lng
                  );
                  let addrData = await resp.json();
                  let jordstykke = await (
                    await fetch(addrData.jordstykke.href)
                  ).json();

                  let fullAddress = addrData.adressebetegnelse;
                  let tmp = fullAddress.split(",");
                  let streetAndNumber = tmp[0] || fullAddress;
                  let zipAndCity =
                    addrData.postnummer.nr + " " + addrData.postnummer.navn;

                  projDispatch(
                    handleToBeAdded([{bfeNr:jordstykke.bfenummer,name:streetAndNumber + ", " + zipAndCity ,latLng:[props.labelLatLng?.latlng.lat,props.labelLatLng?.latlng.lng]}])
                  );
                  return;
                }
              }}
            >
              {Localization.getText("Add to Project")}
            </ViewButtonText>
          </ProtectedFeature>
          <ProtectedFeature feature={Feature.ExplorerToExcel} contentsIfNoAccess={<></>} >
            {isLoading ? <></> 
            :
            <ViewButtonIcon 
            disabled={false}
            title={Localization.getText("Export to Excel")} className="no-print" onClick={(e) => handleExportToExcel()} ><img src="./images/NewSecSVG/excel.svg"></img></ViewButtonIcon>
            }
         </ProtectedFeature>
          <ProtectedFeature
            feature={Feature.OpenPropetyInfoDocument}
            contentsIfNoAccess={<></>}
          >
            <ViewButtonIcon 
              className="no-print"
              onClick={() =>
                window.open(
                  `/propertyinfo/?latLng=${props.labelLatLng?.latlng?.lat},${props.labelLatLng?.latlng?.lng}`,
                  "_blank"
                )
              }
              title={Localization.getText("Popout")}
            >
              <FiCopy />
            </ViewButtonIcon>
          </ProtectedFeature>
          <ProtectedFeature
            feature={Feature.PrintPropertyInfo}
            contentsIfNoAccess={<></>}
          >
            <>
              {!isLoading ? (
                <>
                <ViewButtonIcon
                  className="no-print"
                  onClick={() => {
                    print();
                  }}
                  title={Localization.getText("Print")}
                >
                <FiPrinter />
                </ViewButtonIcon>
                </>
              ) : (
                <Spinner size="sm" />
              )}
            </>
          </ProtectedFeature>
          {fold}
          {closeButton}
        </>
      }
    >
      {/* <div className="mit-printable-modal" > */}
      {/* <PrintHeader/> */}

      {(props.labelLatLng?.latlng && (
        <Modal.Body
          style={{
            overflow: "hidden",
            display: "grid",
            alignContent: "start",
          }}
        >
          <PropertyInfoView
            key={props.labelLatLng.latlng.lat.toString()}
            foldetUd={!propertyDefaultCollapsed}
            cols={hasAccessToFeature(Feature.ThemeNewSec) ? 2 : 1}
            labelLatLng={props.labelLatLng}
            callBackOnLoaded={(data:PropertyData) => {
              setIsLoading(false);
              setPropertyData(data);
            }}
            allowExtendedInformation={true}
          />
        </Modal.Body>
      )) ||
        "Missing Dot"}
    </AdvancedDragModal>
  );
}

function AdvancedDragModal(props: {
  show;
  className;
  headerContent;
  children;
  style;
}) {
  let [eventer, setRef, clickEventer, setOpen] = usePanWindow(0, {localStorName:"PropertyInfoPos"});

  React.useEffect(() => {
    setOpen({});
  }, [props.show]);

  return (
    <Modal.Dialog style={props.style} className={props.className} ref={setRef}>
      <Modal.Header onMouseDown={eventer} onTouchStart={eventer}>
        <div className="GrabLines" onMouseDown={clickEventer} />
        {props.headerContent}
      </Modal.Header>
      {props.children}
    </Modal.Dialog>
  );
}
