import * as React from 'react';
import { FormControl, FormGroup, Button, Stack, Card, Modal, Spinner } from 'react-bootstrap';
import { SketchPicker, RGBColor } from 'react-color';
import { Utils } from '@viamap/viamap2-common';
import {SheetAnalysisResult, SheetAnalysisResultColumn} from '../common/managers/Types';
import {Localization} from "@viamap/viamap2-common";
import { MdClose } from 'react-icons/md';
import { BiCheck, BiLeftArrow, BiMinus, BiRightArrow } from 'react-icons/bi'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';



export function PopBtn({...props} : React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
   return <button {...props} className={"PopBtn " + (props.className || "")} tabIndex={0} onClick={(e)=> {
      (e.target as HTMLButtonElement).blur?.();
      props.onClick?.(e)
      const fakeBlur = new PointerEvent("pointerdown")
      document.body.dispatchEvent(fakeBlur)
   }}>{props.children}</button>
}

export function PopExtendSide(props:{direction:"right"|"left", header:string, children:React.ReactNode}, {...rest}) {
   const [open, setOpen] = React.useState(false);
   const thisElem = React.useRef<HTMLDivElement>(null)
   const btnElem = React.useRef<HTMLButtonElement>(null)

   React.useEffect(() => {
      if (open) {
         const listenForClosing = (ev) => {
            const target = ev.target as Node
            if (thisElem.current?.contains(target)) {
               return
            } else {
               setOpen(false)
            }
         }
         document.body.addEventListener("pointerdown", listenForClosing);
         return () => document.body.removeEventListener("pointerdown", listenForClosing);
      }
   },[open])

   function handleOpenClose(e: any) {
      if (!open) {
      (e.target as HTMLButtonElement).focus()
      }
      setOpen(!open);
      e.stopPropagation();
      e.preventDefault();
   }

   let x = btnElem.current?.getBoundingClientRect()[props.direction]
   
   return (
   <div style={{position:"relative"}}>
      <button tabIndex={0} ref={btnElem} onClick={(e) => {
         handleOpenClose(e)
      }} className="PopBtn PopBtnPreIcon">{props.direction === "right" ? <BiRightArrow /> : <BiLeftArrow/>}{props.header}</button>
      <div className='TopBarPopupSide' data-open={open} ref={thisElem} style={{[`${props.direction === "right" ? "left":"right"}`]:Math.min((x||200) - 200 / 2, 100)+"%", alignItems:(props.direction === "right" ? "flex-start":"flex-end"), display:"flex", flexDirection:"column"}} >
         {props.children}
      </div>
   </div>
   )
}

export function PopDown(props:{direction:"right"|"left",children:React.ReactNode, icon:React.ReactNode, hoverText?:string, className?:string}) {
   const [open, setOpen] = React.useState(false);
   const thisElem = React.useRef<HTMLDivElement>(null)

   React.useEffect(() => {
      if (open) {
         const listenForClosing = (ev) => {
            const target = ev.target as Node
            if (thisElem.current?.contains(target)) {
               return
            } else {
               setOpen(false)
            }
         }
         document.body.addEventListener("pointerdown", listenForClosing);
         return () => document.body.removeEventListener("pointerdown", listenForClosing);
      }
   },[open])

   function handleOpenClose(e) {
      if (!open) {
      (e.target as HTMLButtonElement).focus()
      }
      setOpen(!open);
      
   }
   
   return (
     <div className={"TopBarPopoutBut "+props.className} data-open={open} style={{position:"relative"}}>
       <button type-title={props.hoverText} tabIndex={0} onClick={(e) => handleOpenClose(e)} >{props.icon}</button>
       <div className="blocker" />
       <div ref={thisElem} className="TopBarPopout" style={{[`${props.direction === "right" ? "left":"right"}`]:"0px", alignItems:(props.direction === "right" ? "flex-start":"flex-end"), display:"flex", flexDirection:"column"}} >
         {props.children}
       </div>
     </div>
   )
 }

   function concatVariant(name:string, variant?: string|string[]) : string {
      if (variant === undefined) {
         return ""
      }
      return name + " " + (Array.isArray(variant) ? variant.map((a) => name+"-"+a).join(" ") : name+"-"+variant);
   }

   export function MitExample() {
      return (
      <MitDialog show={true} style={{top:"0px"}} >
        <MitHeader onClose={() => console.log("TEST")} position="start">Mit Modal</MitHeader>
        <MitBody >
         <MitInfo>
            Dette er en text i info Lagsdawdawjiwjd lawjd awjdli awjoid awd iwodj oiawdawh doj d dhwa
         </MitInfo>
         Dette er en text i body
        </MitBody>
        <MitFooter>
        <MitCheckBox variant='normal' indeterminate={true}>Remember ME!</MitCheckBox>
        <MitCheckBox variant='dark' indeterminate={true} />
        <MitButton disabled={true} variant='normal'>DNormal</MitButton><MitButton disabled={true} variant='close'>DClose</MitButton><MitButton  variant='normal'>Normal</MitButton><MitButton variant='close'>Close</MitButton>
        </MitFooter>
      </MitDialog>
      )
   }

   type MitCountProps = {
      show:boolean,
      count:number
   }

   export function MitCount({show, count}:MitCountProps) {
      return show ? (
      <span className='MitShowCountElement' >{count}
      </span>
      ) : null

   }


   type MitButtonVariants = "normal" | "close" | "nsNormal" | "nsDark";
   type MitButtonProps = {
      variant: MitButtonVariants|MitButtonVariants[],
      children:React.ReactNode,
      size?: "sm" | "md" | "lg",
   } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

   export function MitButton({variant, children, size = "md", ...props}:MitButtonProps) {
      return (<button {...props} type={props.type ? props.type : 'button'} className={[props.className,concatVariant("MitButton",variant), size].filter(a => a).join(" ")} >
         {children}
      </button>)

   }

   
   type MitCheckBoxVariant = "normal" | "dark" | "nsFoldUd";
   type MitCheckBoxProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,"onClick"> & {
      variant: MitCheckBoxVariant|MitCheckBoxVariant[],
      children?: React.ReactNode,
      checked?: boolean,
      indeterminate?: boolean,
      onClick?: (evt:React.MouseEvent<HTMLInputElement, MouseEvent>, checked:boolean) => any,
   } 

   export function MitRadioBox({variant, children, checked, onClick, style, ...props}:Omit<MitCheckBoxProps,"indeterminate">) {
      const id = React.useId()

      function handleOnClick(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) {
         onClick?.(evt, !true)
         return false
      }

      function handleOnChange(evt) {
         return
      }

      return (
         <div className={"MitRadioBox "+concatVariant("MitCheckBox",variant)} style={{...style}}>
         <input className={"MitRadioBox_Input "+concatVariant("MitCheckBox_Input",variant)} disabled={props.disabled} onChange={handleOnChange} onClick={handleOnClick} checked={checked} {...props} type='checkbox' id={props.id ? props.id : id}></input>
         <span className={"MitRadioBox_Mark "+concatVariant("MitCheckBox_Mark",variant)}>
            {(checked ? <BiCheck /> : null)}
         </span>
         {children ? <label className={"MitRadioBox_Label "+concatVariant("MitCheckBox_Label",variant)} htmlFor={props.id ? props.id : id} >{children}</label> : null}
      </div>
      )
   }

   export function MitCheckBox({variant, children, checked, indeterminate, onClick, style, ...props}:MitCheckBoxProps) {
      const [checkedS, setCheckedS] = React.useState<boolean>(!!checked)
      const [indeterminateS, setIndeterminateS] = React.useState<boolean>(!!indeterminate)
      const id = React.useId()
      const ref = React.useRef<HTMLInputElement>(null)

      React.useEffect(() => {
         setCheckedS(!!checked || false)
         setIndeterminateS(!!indeterminate || false)
         if (ref.current) {
            ref.current.indeterminate = indeterminateS ? true : false
         }

      },[checked, indeterminate])
      
      function handleOnClick(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) {
         setCheckedS(!checkedS)
         setIndeterminateS(false)
         onClick?.(evt, !checkedS)
         return false
      }

      function handleOnChange(evt) {
         return
      }

      return (
         <div className={concatVariant("MitCheckBox",variant)} style={{...style}}>
            <input ref={ref} className={concatVariant("MitCheckBox_Input",variant)} disabled={props.disabled} onChange={handleOnChange} onClick={handleOnClick} checked={!!checkedS} {...props} type='checkbox' id={props.id ? props.id : id}></input>
            <span className={concatVariant("MitCheckBox_Mark",variant)}>
               {indeterminateS ? <BiMinus /> : (checkedS ? <BiCheck /> : null)}
            </span>
            {children ? <label className={concatVariant("MitCheckBox_Label",variant)} htmlFor={props.id ? props.id : id} >{children}</label> : null}
         </div>
      )
   }

   
   type MitDialogOptions = {
      dragAble?:boolean,
   }

   type MitDialogProps = {
      show:number | boolean,
      children:React.ReactNode, 
      options?:MitDialogOptions,
   } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

   export function MitDialog({show, children, options ,...props}: MitDialogProps) {
      if (!show) {
         return <></>
      }

      let heightStyle:React.CSSProperties = {
         maxHeight: `calc( 100% - ${props.style?.top || "0px"})`
      }

      props.style?.top

      return (
         <div {...props} className={"MitDialog " + (props.className || "")} style={{...props.style || {}, ...heightStyle, zIndex: (1000 + Number(show))}} >
            {children}
         </div>
      )

   }

   type MitHeaderCloseOptions = {
      onClose: () => void,
   }

   type MitHeaderProps = {
      children:React.ReactNode,
      position: "start" | "center" | "end",
      onClose?: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
   } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

   export function MitHeader({children, position, onClose, ...props}:MitHeaderProps) {

      return (
         <div {...props} className={"MitHeader " + (props.className || "")} style={{...props.style, alignContent:position}}>
            <h4>{children}</h4>{onClose ? (<button className='MitHeaderCloseButton' onClick={(e) => onClose(e)}><MdClose /></button>) : <></>}
         </div>
      )
   }

   type MitCommon = {
      children?:React.ReactNode,
   } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

   type MitCommonVariant<T> = {
      variant?: T | T[],
      children?:React.ReactNode,
   } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

   export function MitFooter({children, ...props}:MitCommon) {
      return (
         <div {...props} className={"MitFooter " + (props.className || "")} >
            {children}
         </div>
      )
   }

   type InfoVariant = "" | "dark"
   type MitInfo = MitCommonVariant<InfoVariant>


   
   
   export function MitInfo({children, variant, ...props}:MitInfo) {


      return (
         <div {...props} className={["MitInfo", concatVariant("MitInfo", variant) ,props.className].join(" ")} >
            {children}
         </div>
      )
   }

   export function MitBody({children, ...props}:MitCommon) {
      return (
         <div {...props} className={"MitBody " + (props.className || "")} >
            {children}
         </div>
      )
   }

   type optionsArray = {
      color?: string
      label: string
      value?: string | number | readonly string[]
   }
   export function SelectWithOptionsV2(optionsArray: optionsArray[], name: String, onChange: (e: any) => void, caption: String, currentValue: string, isDisabled: () => boolean, className?: string) {
      let unselectedText = !currentValue ?
         Localization.getText("please select") + " " + caption : Localization.getText("select to reset");
      return (
         <FormGroup
            className={className}
            controlId={"formControlsSelect" + name}
         >
            <FormControl
               as="select"
               size="sm"
               placeholder={Localization.getText("please select") + " " + caption}
               onChange={(e) => onChange(e)}
               value={currentValue}
               disabled={isDisabled()}
            >
               <option key="#Unselected#" className="small" value=''>{unselectedText}</option>
               {optionsArray && optionsArray.map((option, i) => (
                  <option key={caption + i.toString()} style={{ background: option.color }} className="small" value={option.value}>
                     {option.label}
                  </option>
               ))}
            </FormControl>
         </FormGroup>
      );
   }

   type MitLoadingMessages = {
      style?: React.CSSProperties
      messages: {
         timeSeconds: number,
         message: string,
      }[]
   }
   
   export function MitLoading(props: MitLoadingMessages) {
      let [msgIdx, setMsgIdx] = React.useState(props.messages[0]?.timeSeconds === 0 ? 0 : -1)

      React.useEffect(() => {
         let stopped = false
         if (props.messages.length > msgIdx + 1) {
            setTimeout(() => {
               if (stopped) {
                  return
               }
               setMsgIdx((a) =>  a + 1)
            },1000 * props.messages[msgIdx + 1].timeSeconds)
         }
         return () => {stopped = true}
      },[msgIdx])

      return (
         <div className='MitLoading' style={props.style} >
            <Spinner animation="border" />
            <div>{msgIdx !== -1 ? props.messages[msgIdx].message : ""}</div>
         </div>
      )
   }


export type ColorPickerProps = {
   color: string;
   onCommit: (color:string) => void;
   onCancel?: () => void;
};

export function ColorPicker(props:ColorPickerProps) {
   const [colorObject, setColorObject] = React.useState<RGBColor>(convertStringToRGBA(props.color))

   React.useEffect(() => {
      setColorObject(convertStringToRGBA(props.color))
   },[props.color])

   // from https://www.w3schools.com/colors/colors_names.asp
   function colourNameToHex(colour:string) {  
    var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
    "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
    "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
    "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
    "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
    "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
    "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
    "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","grey":"#808080","green":"#008000","greenyellow":"#adff2f",
    "honeydew":"#f0fff0","hotpink":"#ff69b4",
    "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
    "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
    "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
    "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
    "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
    "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
    "navajowhite":"#ffdead","navy":"#000080",
    "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
    "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
    "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
    "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
    "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
    "violet":"#ee82ee",
    "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
    "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colours[colour.toLowerCase()] !== undefined) {
        return colours[colour.toLowerCase()];
    }

    return false;
   }

   // From https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
   function convertHexToRGBA(hexCode:string, opacity:number) {
      let hex = hexCode.replace('#', '');
      
      if (hex.length === 3) {
          hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
      }    
      
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
  
      return {r, g, b, a: opacity};
   }

   function convertStringToRGBA(color:string) {
      // Example "#2E21B2"
      if (color.startsWith("#")) {
         return (convertHexToRGBA(color,1));
      } else {
         // Example "rgba(123,22,43,0.7)"
         if (color.toLowerCase().startsWith("rgba")) {
            try {
               let numbers=color.substring(5,color.length-1);
               let elems=numbers.split(',');
               let result= {r:parseInt(elems[0],10),
                  g:parseInt(elems[1],10),
                     b:parseInt(elems[2],10),
                        a: elems.length>3 ? parseFloat(elems[3]) : 1.0};
               return result;
            } catch (err) {
               console.error("Unexpected rgba color format (err, color):"+err+","+color);
               return {r:0,g:0,b:0,a:1}

            }
         } else {
            // Example "rgb(123,22,43)"
            if (color.toLowerCase().startsWith("rgb")) {  
               try {
                  let numbers=color.substring(4,color.length-1);
                  let elems=numbers.split(',');
                  let result= {r:parseInt(elems[0],10),
                     g:parseInt(elems[1],10),
                        b:parseInt(elems[2],10),
                           a: elems.length>3 ? parseFloat(elems[3]) : 1.0};
                  return result;
               } catch (err) {
                  console.error("Unexpected rgb color format (err, color):"+err+","+color);
                  return {r:0,g:0,b:0,a:1}
               }
            } else {
               // Example "AliceBlue"
               let hex=colourNameToHex(color);
               if (hex) {
                  return convertHexToRGBA(hex,1);
               } else {
                  return {r:0,g:0,b:0,a:1}
                  throw new Error("Unexpected color format:"+color);
               }
         }
      }
      }
   }

   function convertRGBAtoString(color:RGBColor):string {
      return Utils.formatString("rgba({r},{g},{b},{a})", {r:color.r,
         g:color.g,
         b:color.b,
         a:color.a});
   }

   function onColorChanged(c:RGBColor) {
      setColorObject(c)
   }

   function onColorChooserBlurred(event: any) {
      // Prevent event if new focus is inside.
      if (event.currentTarget && event.currentTarget.contains(event.relatedTarget)) {
         return;
      }
      let rgbaString = convertRGBAtoString(colorObject);
      props.onCommit(rgbaString);
   }

   function onColorChooserCommit() {
      let rgbaString = convertRGBAtoString(colorObject);
      props.onCommit(rgbaString);
   }

   function onCancel() {
      props.onCancel?.();
   }

   
      let conditionallyRenderCancelButton = props.onCancel ? (
         <MitButton style={{flex:"auto"}} variant='normal' onClick={(e) => onCancel()}>
         {Localization.getText("Cancel")}
         </MitButton>
      ) : null;
      return (
         // tabIndex make the Panel Focusable.
         <Card tabIndex={0} className="mit-color-chooser-panel" onBlur={(e) => onColorChooserBlurred(e)} >
         <SketchPicker 
             color={colorObject} 
             disableAlpha={false}
             presetColors={[]}
             onChange={(color) => onColorChanged(color.rgb)}
         />
         <MitFooter style={{padding: "0px", boxShadow:"none"}}>
         <MitButton style={{flex:"auto"}} variant='normal' onClick={(e) => onColorChooserCommit()}>
         {Localization.getText("Commit")}
         </MitButton>
         {conditionallyRenderCancelButton}
         </MitFooter>
         </Card>
      );
   
}

type ColorBoxEditableProps = {
   color: string;
   onCommit: (color:string) => void;
   onCancel?: () => void;
};

export function ColorBoxEditable(props:ColorBoxEditableProps) {
   const [color, setColor] = React.useState(props.color)
   const [inEditMode, setInEditMode] = React.useState(false)

   React.useEffect(() => {
      setColor(props.color)
   },[props.color])

   function onColorChanged(c:any) {
      setColor(c)
      setInEditMode(false)
      props.onCommit(c);
   }

   function onCancel() {
      setInEditMode(false)                
      props.onCancel?.();
   }

   function showColorChooser() {
      setInEditMode(true)                
   }


      return inEditMode ? (
         <ColorPicker
            color={color}
            onCommit={(c) => onColorChanged(c)}
            onCancel={() => onCancel()}
         />
      ) : (
         <div 
            className="int-bot mit-color-box mit-color-box-control" 
            style={{background: color}} 
            onClick={() => showColorChooser()} 
            data-toggle="tooltip" 
            title={Localization.getText("Click to edit color")}
         />
      );
}


type TextBoxEditableProps = {
   value: string;
   onCommit: (color:string) => void;
   placeholder: string;
};

export function TextBoxEditable(props:TextBoxEditableProps) {
   const [value,setValue] = React.useState(props.value)

   React.useEffect(() => {
      setValue(props.value)
   },[props.value])


   function valueOnChange(evt:any) {
      let val= evt.target.value;
      setValue(val)                
   }

   function valueOnBlur(evt:any) {
      let val= evt.target.value;
      setValue(val)                
      props.onCommit(val);
   }

   return (
      <FormControl
         size="sm"
         type="text"
         value={value}
         placeholder={props.placeholder}
         onChange={(e) => valueOnChange(e)}
         onBlur={(e) => valueOnBlur(e)}
      />
   );
}


type ColorBoxProps = {
   color: string;
};

export function ColorBox(props:ColorBoxProps) {
      return (
         <div 
            className="int-bot mit-color-box mit-color-box-control" 
            style={{background:props.color}} 
         />
      );
      }


type ExpandableListProps = {
   renderers: (JSX.Element | null)[];
   headerExpanded?:JSX.Element;
   headerCollapsed?:JSX.Element;
};

export function ExpandableList(props:ExpandableListProps) {
   const [isExpanded, setIsExpanded] = React.useState(false)

   function onClick(e:any) {
      e.preventDefault();
      setIsExpanded((a) => !a)                
   }

      let contents:JSX.Element[]=[];
      props.renderers.forEach((r,idx) => {
         if (r) {
            contents.push(r);
         }
      });
      return contents && contents.length>0 ? (
         <Stack className="mit-settings-panel">
            <div className="mit-settings-panel-header" onClick={(e) => onClick(e)}>
               {isExpanded ? props.headerExpanded : props.headerCollapsed}
            </div>
            {isExpanded ? (
            <div className="mit-settings-panel-collapsible">
               {contents}
            </div>
            ) : null}
         </Stack>
      ) : null;

}

export function RandomColorHex() {
   const randomColor = Math.floor(Math.random()*16777215).toString(16);
   return "#"+randomColor+"aa"
}

export function RandomColorRGBA(opacity:number) {
   return `rgba(${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${opacity})`
}

export function HeaderWithOpenCloseChevron(props:{onClick:React.MouseEventHandler<HTMLSpanElement>, isOpen:boolean, title:string, style?:any}) {
   return (
      <span onClick={(e)=>props.onClick(e)} style={{cursor:"pointer", ...(props.style ||{})}} >{props.title}<span>{props.isOpen ? (<BsChevronDown />):(<BsChevronRight />)}</span> </span>
   )
}