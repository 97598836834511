export type LicenseVariantToRolesMapping = { [idx: string]: string[] };

export const licenseVariantToRoles: LicenseVariantToRolesMapping= {
   "Visualize" : [
      "Visualize"
   ],
   "Lookup" : [
      "Lookup",
   ],
   "Surveyor" : [
      "Surveyor"
   ],
   "Explorer" : [
      "Explorer"
   ],
   "ExplorerPro" : [
      "ExplorerPro"
   ]
}