import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MaplibreMapFunc } from './MaplibreMapFunc';
import * as M from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { SessionContext, SettingsManager } from '@viamap/viamap2-common';
import { GenerateGeom } from 'src/managers/GenerateGeom';
import * as turf from '@turf/turf';
import { FeatureLayer } from 'src/managers/WmsLayerFunc';
import { Legend } from 'src/components/Legend';
import { OtherLegendSpec } from 'src/common/managers/Types';

const zoomLevelShowingItemsInsteadOfGroups = 8;
const zoomLevelClusterMax = 12;
export type BBOXTURF = number[];
export type ITEMKEY = number;

export type MapState = {
  center: [number, number],
  pitch: number,
  zoom: number,
  bearing: number,
  bbox?: BBOXTURF
}

export type SourceSpecs = { [id: string]: any };
export type LayerSpecs = { [id: string]: any };

type Props = {
  orthoPhoto: boolean,

  sources?: SourceSpecs,
  layers?: LayerSpecs,
  height?: string,
  width?: string,
  fullScreenControl?: boolean,
  drawControls?: boolean,
  navigationControls?: boolean,
  hideBuildings?: boolean,
  mapState?: MapState,
  beforeId?: string,
  showLegend?:boolean,
  otherLegends?: {[legendId:string]: OtherLegendSpec},

  onMove?: (newMapState: MapState) => void,
  // onCircle?: (center: MitLatLng, radiusMeters: number) => void,
  onPolygon?: (polygon: any) => void,
  onMapInitialization?: (mapControler: MapInstanceController) => void,
  
  // featureLayers?: { [key: string]: FeatureLayer },
  // cadasters?: boolean,
  // dataSourceGeoJSON?: any,
  // dataSourceItemsJSON?: any,
  // dataSourceFilterJSON?: any,
  // selectBFE?: (bfeNumber: number, coord: [number, number]) => void,
    // selectedItems?: ITEMKEY[]
}

export abstract class MapInstanceController {
  abstract zoomToFeatureJSON(data: any, maxZoom?: number): void;
}

export class MapInstanceControllerMaplibre implements MapInstanceController {
  private map: M.Map;
  constructor(map: M.Map) {
    this.map = map;
  }

  public zoomToFeatureJSON(data: any, maxZoom?: number) {
    try {
      let boundingBox = turf.bbox(data.data);
      this.map.fitBounds(
        ((boundingBox.length === 4) ? boundingBox : boundingBox.splice(0,4)) as [number,number,number,number],
        {
          // linear:true,
          padding: { top: 25, bottom: 25, left: 25, right: 25 },
          maxZoom: maxZoom || 19
        }
      );
    } catch (err) {
      console.log("Got error in fitbounds", err);
      throw new Error("Got error in fitbounds" + JSON.stringify(err));
    }
  }

}

export const EmbeddedVectorMapEnhanced = (props: Props) => {

  const [map, setMap] = useState<M.Map>();
  const [draw, setDraw] = useState<any>();
  const [featureLayers, setFeatureLayers] = useState<{ [key: string]: FeatureLayer }>({});
  const [bfe, setBFE] = useState<number>(0)
  const [showLegend, setShowLegend] = useState<boolean>(true)

  let blockMouseEvents: boolean = false;
  function setBlockMouseEvents(value: boolean) { blockMouseEvents = value; }

  function getBbox(map: M.Map): BBOXTURF {
    let bounds: M.LngLatBounds = map.getBounds();
    let bboxMap: any = bounds.toArray();
    let bbox = [...bboxMap[0], ...bboxMap[1]];
    return bbox;
  }

  function updateArea(e: any, draw: any) {
    if (e.features.length > 0) {
      props.onPolygon && props.onPolygon(e);

      // Remove drawn element such that drawing the area can be controlled from caller.
      if (draw) {
        draw.deleteAll();
      } else {
        console.error("Draw not set");
      }
    }
  }

  function deleteArea(e: any) {
    props.onPolygon && props.onPolygon(undefined);
  }
  
  const mapContainer = useRef(null);
  const standardMarker = useMemo(() => {
    constructMarkerForColor("red");
  }, []);

  const dataSource = "hitCounts";
  const itemSource = "items";
  const filterAreaSource = "filterArea";
  const useClustering = true;
  let emptyJSONData = {
    "type": "FeatureCollection",
    "features": []
  };

  function constructMarkerForColor(color: string): {} | null {
    let markerObj = new M.Marker({
      'color': color
    });

    return markerObj;
  }


  // MAP INITIALIZATION
  useEffect(() => {
    let options = {};
    let vectorStylePath = SettingsManager.getSystemSetting("vectorMapStylePath", undefined)
    if (vectorStylePath) {
      // ToDo: Get from AWS S3 library
      options = { 
        style: vectorStylePath,
        hash: false,
        zoom:(props.mapState?.zoom || 7),
        pitch:(props.mapState?.pitch || 0),
        bearing:(props.mapState?.bearing || 0),
        center:(props.mapState?.center || [11.428477,55.375788])
       };
      // Note: Uses viamap default style if not set.
    }
    let cleaning: M.Map 
    MaplibreMapFunc.initMap(mapContainer.current, options)
      .then(map => {
        // Save map object in state
        setMap(map);
        cleaning = map;

        // ----------------------------------- load icons ----------------------------------
        let scale = 1;
        map.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png`, function (error, image) {
          if (error) throw error;
          map.addImage('blue-pin', image as any, { pixelRatio: scale }); // 38x55px, shadow adds 5px (for scale eq 1)
        });
        map.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png`, function (error, image) {
          if (error) throw error;
          map.addImage('red-pin', image as any, { pixelRatio: scale }); // 38x55px, shadow adds 5px (for scale eq 1)
        });

        if (props.navigationControls) {
          map.addControl(new M.NavigationControl({ showZoom: true }), 'top-left');
        }

        if (props.fullScreenControl) {
          map.addControl(new M.FullscreenControl({}));
        }

        if (props.drawControls) {

          var draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
              polygon: true,
              trash: false,
            },
            clickBuffer: 20,
            // defaultMode: "draw_circle",
            // userProperties: true,
            // modes: {
            //   ...MapboxDraw.modes,
            //   draw_circle  : CircleMode,
            //   drag_circle  : DragCircleMode,
            //   direct_select: DirectMode,
            //   simple_select: SimpleSelectMode
            // }
          });
          setDraw(draw);
          map.addControl(draw as any);

          map.on('draw.create', (e) => updateArea(e, draw));
          map.on('draw.delete', deleteArea);
          map.on('draw.update', (e) => updateArea(e, draw));

        }

        function postMapStateToParent() {
          if (props.onMove && !blockMouseEvents) {
            let bbox = getBbox(map);
            console.log("Moveevent to be sent");
            props.onMove({
              bearing: map.getBearing(),
              pitch: map.getPitch(),
              zoom: map.getZoom(),
              center: (map.getCenter().toArray() as [number, number]),
              bbox: bbox
            })
          }
        }
        if (props.onMove && map) {
          map.on('moveend', () => {
            postMapStateToParent();
          })
          map.on('zoomend', () => {
            postMapStateToParent();
          })
          map.on('load', () => {
            postMapStateToParent();
          })
          setBlockMouseEvents(false);
        }

        props.onMapInitialization && props.onMapInitialization(new MapInstanceControllerMaplibre(map));
      }).catch(
        err => { throw new Error("Cannot initialize map. err=" + err) }
      );

      return () => cleaning?.remove()
  }, []); // Run only once

  useEffect(() => {
    if (map && props.mapState && !blockMouseEvents) {
      setBlockMouseEvents(true);

      map.setZoom(props.mapState.zoom || 7);
      map.setPitch(props.mapState.pitch || 0);
      map.setBearing(props.mapState.bearing || 0);
      map.setCenter(props.mapState.center || [
        11.428477,
        55.375788
      ]);

      setBlockMouseEvents(false);
      map.resize();
    }
  }, [props.mapState]);

  useEffect(() => {
    map && map.setLayoutProperty('orthophoto', 'visibility', props.orthoPhoto ? 'visible' : 'none');
  }, [props.orthoPhoto, map]); // Run when map becomes available or showOrtoPhoto is changed

  useEffect(() => {
    map && map.setLayoutProperty('building-3d', 'visibility', props.hideBuildings ? 'none' : 'visible');
  }, [props.hideBuildings, map]); // Run when map becomes available or showBuildings is changed

  useEffect(() => {
    if (map && props.sources && props.layers && Object.keys(props.sources).length > 0) {
      Object.keys(props.layers).forEach((layerId) => {
        let lr = props.layers && props.layers[layerId];
        if (!map.getLayer(lr.id)) {
          if (!map.getSource(lr.source)) {
            let data = props.sources && props.sources[lr.source];
            // console.log("INITIAL Data for layer", JSON.stringify(data));
            map.addSource(lr.source, data);
          }
          map.addLayer(lr, props.beforeId);

        } else {
          let src = props.sources && props.sources[lr.source];
          if (src && src.type === "geojson") {
            map && (map as any).getSource(lr.source).setData(src.data);
          }
        }

      })
    }
  }, [props.layers, map]); // Run when map becomes available or showOrtoPhoto is changed

  // Map render function
  return (
    <>
    { (props.otherLegends && showLegend) ?
      <Legend
      showWindow={1} 
      layers={[]}
      sizeRanges={GenerateGeom.sizeRanges}
      colorRanges={GenerateGeom.colorRanges}
      callBackOnStyleChange={(layerInfo) => {console.log(layerInfo); return 0}}
      callBackToHideWindow={() => {setShowLegend(false)}}
      otherLegends={props.otherLegends ?? {}}
      />: null
    }
    <div ref={mapContainer} style={{ height: props.height || "100%", width: props.width || "" }} className="map-container" />
    </>
  );
}
