import * as React from 'react';
import {Helmet} from "react-helmet";
import {AppMode, UserSession, SessionInfo, DeploymentMode} from './common/managers/Types';
import { ErrorBoundary, LicensingContext } from '@viamap/viamap2-common';

import './App.css';
import 'react-data-grid/lib/styles.css';
import FileImporter from './components/FileImporter';
import { SessionContext } from '@viamap/viamap2-common';
import { SessionActionType } from '@viamap/viamap2-common';
import NavigationBar from './components/NavigationBar';
import RightPane from './components/RightPane';
import { ProjectManager } from './components/Projects';

import { MapitButtonsAndOverlays } from './components/MapitButtonsAndOverlays';
import { GenericTransactionManager, MapitStateActions, MapitStateContext, MapitStateReducer, MapitWindowId, initialMapitState, transactionalMapitStateReducer, windowToBeShownImpl, windowToBeShownParametersImpl } from './states/MapitState';
import { RightPaneContext, useSetupRightPane } from './states/RightPaneState';
import MapScreen from './components/MapScreen';
import { MitButton } from './components/ComponentUtils';


export function Application(props:{tab?:string}) {
  const [sessionInfo, setSessionInfo] = React.useState<SessionInfo>();
  const {state : newUserSession, dispatch: sessionDispatch} = React.useContext(SessionContext);
  const {state:licensingState} = React.useContext(LicensingContext);
  const [restoring, setRestoring] = React.useState(false)

  const [mapitState, mapitStateDispatch] = React.useReducer(MapitStateReducer, initialMapitState(true));

  const modusState = useSetupRightPane()
  React.useEffect(() => {
    if (restoring) {
      setTimeout(() => setRestoring(false), 100)
    }
  },[restoring])

  React.useEffect(() => {
    mapitState.map?.once?.("webglcontextlost", () => {
      console.log('%c Map Content Lost ', 'color: #bada55')
      setRestoring(true)
    })
  },[mapitState.map])

  React.useEffect(() => {

    let us:UserSession  = {
      id: newUserSession.userName || "",
      userName: newUserSession.userName,
      email: newUserSession.userRef,
      jwtToken: newUserSession.userSession?.getAccessToken().getJwtToken() || "",
      name: newUserSession.userName,
      userRoles: [], // [...(newUserSession.userRoles ? newUserSession.userRoles : []),...license.valid.map((a) => a.productVariant)],
      deploymentMode: DeploymentMode.Testing,
      isAnonymousSession: false,
    }

    setSessionInfo({ 
      userSession: us,
      mapReferenceData: undefined,
      appMode: AppMode.Normal,
      commandList: undefined
    })

  },[newUserSession, licensingState])


  
  function LogOut() {
    sessionDispatch({
      type: SessionActionType.Logoff,
      payload: {
          userName: sessionInfo?.userSession?.userName ?? newUserSession.userName
      }})
  }

  if (sessionInfo !== undefined)
  return (
      <MapitStateContext.Provider
      value={{
        state: mapitState,
        dispatch: GenericTransactionManager.dispatchMiddleware<MapitStateActions>(mapitStateDispatch, transactionalMapitStateReducer),
        windowToBeShownOrder: (windowId: MapitWindowId) => {
          return windowToBeShownImpl(mapitState, windowId);
        },
        windowToBeShownParameters: (windowId: MapitWindowId) => {
          return windowToBeShownParametersImpl(mapitState, windowId);
        }
      }}

    >
    <ErrorBoundary>
    <RightPaneContext.Provider value={modusState} >
    <Helmet>
      <meta charSet="utf-8" />
      <title>{licensingState.currentlicense ? (licensingState.currentlicense.productVariant.includes("Explorer") ? 'Estate Explorer' : `${licensingState.currentlicense.product} ${licensingState.currentlicense.productVariant}`): "Unlicensed"}</title>
    </Helmet>
    <ProjectManager >
    <FileImporter>
      {/* <MitButton variant={"normal"} onClick={() => (setRestoring(true))} >Restore</MitButton> */}
      <NavigationBar>
      <RightPane> 
      <MapitButtonsAndOverlays/>
      { restoring ?
        <></>
      :
      <MapScreen 
      callbackToLogout={()=> {LogOut()}} 
      showTrialSignupDialog={false} 
      callBackOnTrialSignup={(name, company, phoneNo, termsApproved) => console.log(name, company, phoneNo, termsApproved)}
      callBackMap={(a) => {}}
      />
      }
        </RightPane>
      </NavigationBar>
    </FileImporter>
    </ProjectManager>
    </RightPaneContext.Provider>
    </ErrorBoundary>
    </MapitStateContext.Provider>
  );

  return (null)
}


