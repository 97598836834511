import * as React from 'react';
import { FormControl, FormGroup, Button } from 'react-bootstrap';
import {
    ColorByValueStyling, ValidationType, DataDivision, DataDivisionList,
    ColorRangeList, DataDivisionType,
    SpecialValueStyling} from '../common/managers/Types';
import {ColorPicker, MitCheckBox, MitCount} from './ComponentUtils';

import {Utils} from '@viamap/viamap2-common';
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {Logger} from  '@viamap/viamap2-common';
import distinctColors from 'distinct-colors';
import * as chroma from 'chroma-js';
import Glyphicon from '@strongdm/glyphicon';
import { DivisionCalculator } from 'src/managers/DivisionCalculator';
// import '../css/mit-controls.css';

type State= {
    allowEdit:boolean;
    style:ColorByValueStyling;
    colorRanges: ColorRangeList;
    callBackOnStyleChange:(newStyle:ColorByValueStyling)=>void;
    editMode?:boolean;
    label?:string;
    styleEdited?:ColorByValueStyling;
    displayColorPickerForIdx?:number;
    otherValue?: SpecialValueStyling;
    setOtherValue?: (a: SpecialValueStyling) => void
    callBackOnEditStateChange?:(isEditing:boolean)=>void;
    dataValues?: any[];
    maxDivisions?: number;
    showElementCounts: boolean;
};

type Props = State;

export function LegendScale (props:Props) {
    const [editMode,setEditMode] = React.useState(false)
    const [displayColorPickerForIdx,setDisplayColorPickerForIdx] = React.useState<number | undefined>(undefined)
    const [style,setStyle] = React.useState(props.style)
    const [styleEdited, setStyleEdited] = React.useState(props.style)

    React.useEffect(() => {
        setStyle(props.style)
    }, [props.style]) 

    function getValidationStateForLabel():ValidationType {
        return "success";
    }

    function getValidationStateForPositiveNumber(idx:number, minimum:number):ValidationType {
        let val = Utils.parseNumber(styleEdited!.divisions!.list[idx].value+"");
        if (!val || isNaN(val) || val < minimum) {
            return "error";
        } else {
            return "success";
        }
    }

    function getValidationStateForFrom(idx:number):ValidationType {
        let val = Utils.parseNumber(styleEdited!.divisions!.list[idx].from+"");
        if (isNaN(val!)) {
            return "error";
        } else {
            return "success";
        }
    }

    function getValidationStateForTo(idx:number):ValidationType {
        let val = Utils.parseNumber(styleEdited!.divisions!.list[idx].to+"");
        if (isNaN(val!)) {
            return "error";
        } else {
            return "success";
        }
    }

    function clickToEnterEditModeHandler(e:any) {
        if (props.allowEdit) {
            let clonedStyle:ColorByValueStyling = JSON.parse(JSON.stringify(style));
            setStyleEdited(clonedStyle)
            setEditMode(true)
            
            props.callBackOnEditStateChange && props.callBackOnEditStateChange(true);
        }
    }

    function onClickRemove(e:any, idx:number) {
        // Don't remove the last one
        if (getCurrentNumberOfDivisions() > 1) {
            let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
            clonedStyleEdited!.divisions!.list.splice(idx, 1);        
            setStyleEdited(clonedStyleEdited)
        }
    }
    
    function labelOnChange(e:any, idx:number) {
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list[idx].label = e.target.value;
        setStyleEdited(clonedStyleEdited)        
    }

    function fromOnChange(e:any, idx:number) {
        _fromToOnChange(e, (value:any) => {
            let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
            clonedStyleEdited!.divisions!.list[idx].from = value;
            setStyleEdited(clonedStyleEdited);
        });
    }

    function _fromToOnChange(e:any, setterFunc:(value:any)=>void) {
        let val = e.target.value;
    
        // set to null for *
        if (val ==="*" || val ===">" || val ==="<") {
            setterFunc(null);
        } else {
            setterFunc(val);
        }
        //     if (val[0] === '-') {
        //         setterFunc(val);
        //     } else {
        //         // Only allow numbers    
        //         let num = Utils.parseNumber(val);
        //         if (num !== undefined) {
        //             setterFunc(val);
        //         }       
        //     }
        // }
    }

    function onClickFromInfinity(e:any, idx:number) {
        let val = e.target.value;
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list[idx].from = 0;
        setStyleEdited(clonedStyleEdited)
    }

    function toOnChange(e:any, idx:number) {
        _fromToOnChange(e, (value:any) => {
            let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
            clonedStyleEdited!.divisions!.list[idx].to = value;
            setStyleEdited(clonedStyleEdited)
        });
        // let val = e.target.value;

        // // set to null for *
        // if (val ==="*" || val ===">") {
        //     this.state.styleEdited!.divisions!.list[idx].to = null;
        //     this.setState({styleEdited:this.state.styleEdited});        
        // } else {
        //     // Only allow numbers    
        //     let num = Utils.parseNumber(val);
        //     if (num !== undefined) {
        //         this.state.styleEdited!.divisions!.list[idx].to = num;
        //         this.setState({styleEdited:this.state.styleEdited});        
        //     }       
        // }
    }

    function onClickToInfinity(e:any, idx:number) {
        let val = e.target.value;
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list[idx].to = 0;
        setStyleEdited(clonedStyleEdited)
    }

    function valueOnChange(e:any, idx:number) {
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list[idx].value = e.target.value;
        setStyleEdited(clonedStyleEdited)
    }

    // valuePositiveNumberOnChange(e:any, idx:number, minimumValue: number) {
    //     // only accepts positive numbers
    //     let value = e.target.value && Number.parseInt(e.target.value, 10) || 0;
    //     if (value < minimumValue) {
    //         value = minimumValue;
    //     }
    //     if (value >= 0) {
    //         this.state.styleEdited!.divisions!.list[idx].value = value ? ""+value : "";
    //         this.setState({styleEdited:this.state.styleEdited});        
    //     }
    // }

    function showColorChooser(e:any, idx:number) {
        setDisplayColorPickerForIdx(idx)
    }

    function isAddDivisionEnabled(): boolean {
        return props.maxDivisions === undefined || (getCurrentNumberOfDivisions() < props.maxDivisions);
    }

    function isRemoveDivisionEnabled():boolean {
        // Don't remove the last one
        return (getCurrentNumberOfDivisions() > 1);
    }

    function onColorChanged(c:any, idx:number) {
        console.log("ss", idx)
        if ( idx == -1) {
            props.setOtherValue?.({...props.otherValue,show:true, color: c})
            setDisplayColorPickerForIdx(undefined)
            return
        }
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list[idx].color = c;
        // Hide color chooser
        setStyleEdited(clonedStyleEdited)
        setDisplayColorPickerForIdx(undefined)
    }

    function onClickAddLevel(e:any) {
        let clonedStyleEdited:ColorByValueStyling = JSON.parse(JSON.stringify(styleEdited));
        clonedStyleEdited!.divisions!.list.push({from:0, to:0, value:"", label:"",color:"#ffffff", count:0});        
        setStyleEdited(clonedStyleEdited)
        // give focus to add button - to enable easy adding of additional elements
        e.target.focus();   
        e.target.scrollIntoView();             
    }

    function getCurrentNumberOfDivisions() {
        return styleEdited!.divisions!.list.filter((a) => a).length;
    }

    function onClickRecalculate(e:any) {

        // Calculate divisions
        let value = props.dataValues || [];
        let numberOfDivs = getCurrentNumberOfDivisions();
        let divisionsColor:DataDivisionList;
        if (isNaN(value.filter(a => a !== undefined)[0])) {
            divisionsColor = DivisionCalculator.calculateDataDivisionsDiscrete(value, numberOfDivs);
        } else {
            divisionsColor = DivisionCalculator.calculateDataDivisions(value, numberOfDivs);
        }

        // If few colors are generated use the selected color scale hues. Otherwise generate distinct colors.
        let autocolorMaxDivisionsUsingSelectedRangeColors= SettingsManager.getSystemSetting("autocolorMaxDivisionsUsingSelectedRangeColors", 8);
        if (numberOfDivs <= autocolorMaxDivisionsUsingSelectedRangeColors) {
            // Generate similar colors to selected range.

            let firstColorOfSelectedRange = props.colorRanges[style.useColorRange!].colors[0];
            let colorRangeLength = props.colorRanges[style.useColorRange!].colors.length;
            let lastColorOfSelectedRange = props.colorRanges[style.useColorRange!].colors[colorRangeLength-1];
            // Alternative way if generating a color scale. This is linear and returns coloar hex values directly
            let colors = chroma.scale([firstColorOfSelectedRange, lastColorOfSelectedRange]).mode('lab').colors(numberOfDivs);

            // colors are chroma-js objects
            // Store colors on division
            divisionsColor.list.forEach((obj,idx) => {
                let c = colors[idx];
                divisionsColor.list[idx].color = c;
            });

        } else {
            // Generate for max distinct colors.

            let colors = distinctColors({count:numberOfDivs});
            // colors are chroma-js objects
            // Store colors on division
            divisionsColor.list.forEach((obj,idx) => {
                let c = colors[idx].hex() || "#ffffff";
                divisionsColor.list[idx].color = c || "#ffffff";
            });
        }
        setStyleEdited((a) => ({...a, divisions:divisionsColor}));
    }

    function onClickCancel(e:any) {
        setEditMode(false)
        setStyleEdited(style);
        props.callBackOnEditStateChange && props.callBackOnEditStateChange(false);
    }

    function onClickCommit(e:any) {
        Logger.logAction("LegendScale", "Edit scale","Committed");

        setEditMode(false)
        setStyle(styleEdited);
        props.callBackOnStyleChange(styleEdited);
        props.callBackOnEditStateChange && props.callBackOnEditStateChange(false);
    }

    function toggleDivVisible(e:any, idx:number, div?:DataDivision) {
        if (div && idx !== -1) {
        div.hide = !div.hide;
        let newDivs = style.divisions!;
        newDivs.list[idx] = div;
        let newStyle = {...style, divisions:newDivs };
        props.callBackOnStyleChange(newStyle);
        setStyle(newStyle)
        } else if (props.otherValue?.show !== undefined) {
            props.setOtherValue?.({...props.otherValue, show: !props.otherValue.show})
        }
        
    }

    function showOnlyThisDiv(e:any, idx:number) {
        let newDivs = style.divisions!;
        newDivs.list.forEach((div, idx2) => {
            div.hide = (idx2 !== idx);
        });
        let newStyle = {...style, divisions:newDivs };

        props.callBackOnStyleChange(newStyle);
        setStyle(newStyle)
        if (props.otherValue?.show !== undefined) {
            props.setOtherValue?.({...props.otherValue, show: idx == -1})
        }
    }

    function showAllDivs(e:any, idx:number) {
        let newDivs = style.divisions!;
        newDivs.list.forEach((div, idx2) => {
            div.hide = false;
        });
        let newStyle = {...style, divisions:newDivs };
        props.callBackOnStyleChange(newStyle);
        setStyle(style)
        if (props.otherValue?.show !== undefined) {
            props.setOtherValue?.({...props.otherValue, show: true})
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    function verticalColorLegend(divisions:DataDivisionList, styling:ColorByValueStyling, allowHideIndividualDivisions:boolean, showElementCounts:boolean) {

        let countVisible = allowHideIndividualDivisions ? style.divisions!.list.filter((a) => a).filter((d) => { return !(d.hide || false); }).length + (props.otherValue?.show ? 1 : 0) : 0;
        let toolTip = props.allowEdit ? Localization.getText("Click to edit scale"): "";
        let renderDivRows = styling.divisions && styling.divisions.list.filter((a) => a).map((div,idx) => {
            let color = div.color;
            if (!color) {
                color = props.colorRanges[styling.useColorRange!].colors[idx] || "#ddd";
            }
            let op;
            let text;
            let label = div.label;
            let visible = !div.hide;
            
            if(div.value || (div.from === null && div.to === null)) {
                // Discrete values
                text = div.value || <b>{Localization.getText("No value")}</b>;
                op="";
                } else {
                // Numeric Values
                if (div.from === null) {
                    op = "<=";
                    text = div.to;
                } else {
                    if (div.to === null) {
                        op = ">";
                        text = div.from;
                    } else {
                        op = "<=";
                        text = div.to;
                    }
                }
            }
            let descriptionColumns;
            if (label) {
                descriptionColumns=[(
                    <div key={"21"}/>
                ),(
                    <div key={"31"} className={"divSpan-2"} style={{textAlign:"left"}}><strong>{label}</strong></div>
                )];
            } else {
                descriptionColumns=[(
                    <div key={"2"}>{op}</div>
                ),(
                    <div key={"3"} className={"divSpan-2"} style={{textAlign:"left"}}>{text}</div>
                ),(
                    <div key={"4"} style={{textAlign:"left"}}><strong>{label}</strong></div>
                )];
            }
            if (showElementCounts) {
                descriptionColumns.push((
                    <div key={"22"}><MitCount show={true} count={div.count}/></div>
                ))
            }
            let renderToggleVisibilityCheckbox = allowHideIndividualDivisions ? (
                <div className="mit-legend-showhide-division-hoverwrapper">
                <MitCheckBox
                    variant='dark'
                    checked={visible}
                    disabled={visible && countVisible < 2}
                    onClick={(e) => {(!visible || countVisible > 1) &&  toggleDivVisible(e, idx, div)}}
                    data-toggle="tooltip" 
                    title={visible ? ( countVisible > 1 ? Localization.getText("Hide division") : Localization.getText("Cannot hide the last division") ) : Localization.getText("Show division")}
                />
                {visible && countVisible > 1 ? (
                    <div
                        id="mit-legend-showhide-division-only" 
                        onClick={(e)=> showOnlyThisDiv(e, idx)}
                        data-toggle="tooltip" 
                        title={Localization.getText("Show only this division")}
                    >
                        {Localization.getText("only")}
                    </div>
                ) : (
                    <div
                        id="mit-legend-showhide-division-only" 
                        onClick={(e)=> showAllDivs(e, idx)}
                        data-toggle="tooltip" 
                        title={Localization.getText("Show all divisions")}
                    >
                        {Localization.getText("all")}
                    </div>
                )}
                </div>
            ) : null;
            return (
                <div key={"sx"+idx} className='LegendRow' style={{fontSize:"7pt", borderBottom:"solid transparent 2px"}}>
                    
                    <div>
                    {/* <div style={{float:"left"}}> */}
                    {renderToggleVisibilityCheckbox}
                    {/* </div> */}
                    </div>
                    <div>
                    <div 
                        className="int-bot mit-color-box" 
                        style={{background:color}} 
                        onClick={(e) =>  clickToEnterEditModeHandler(e)} 
                        data-toggle="tooltip" 
                        title={toolTip}
                    />
                    </div>
                    {descriptionColumns}
                </div>
            );
        });

        let renderOtherValue = () => {
            let visible = props.otherValue?.show;
            let renderToggleVisibilityCheckbox = allowHideIndividualDivisions ? (
                <div className="mit-legend-showhide-division-hoverwrapper">
                <MitCheckBox
                    variant='dark'
                    checked={visible}
                    disabled={visible && countVisible < 2}
                    onClick={(e) => {(!visible || countVisible > 1) &&  toggleDivVisible(e, -1, undefined)}}
                    data-toggle="tooltip" 
                    title={visible ? ( countVisible > 1 ? Localization.getText("Hide division") : Localization.getText("Cannot hide the last division") ) : Localization.getText("Show division")}
                />
                {visible && countVisible > 1 ? (
                    <div
                        id="mit-legend-showhide-division-only" 
                        onClick={(e)=> showOnlyThisDiv(e, -1)}
                        data-toggle="tooltip" 
                        title={Localization.getText("Show only this division")}
                    >
                        {Localization.getText("only")}
                    </div>
                ) : (
                    <div
                        id="mit-legend-showhide-division-only" 
                        onClick={(e)=> showAllDivs(e, -1)}
                        data-toggle="tooltip" 
                        title={Localization.getText("Show all divisions")}
                    >
                        {Localization.getText("all")}
                    </div>
                )}
                </div>
            ) : null;

            return (
            <>
            <div className='LegendRow'>
            <div>
            {renderToggleVisibilityCheckbox}
            </div>
            <div>
            <div 
                className="int-bot mit-color-box" 
                style={{background:props.otherValue?.color}} 
                onClick={(e) =>  clickToEnterEditModeHandler(e)} 
                data-toggle="tooltip" 
                title={toolTip}
            />
            </div>
            <div className='divSpan-2' style={{textAlign:"left"}}>
                {Localization.getText("Other data")}
            </div>
                {showElementCounts ? <div><MitCount show={true} count={styling.divisions?.otherValuesCount || 0}/></div> : null}
            </div>
            <div>
            </div>
            </>
            )
        }

        return <>{renderDivRows}
            {styling.divisions?.otherValuesCount && styling.divisions.type == DataDivisionType.Discrete ? renderOtherValue(): null}
        </>;
    }    

    function validateForm():boolean {
        if (styleEdited!.divisions!.type === DataDivisionType.Discrete && styleEdited!.divisions!.valuesAreNumbers) {
            return styleEdited!.divisions!.list.reduce(
                (previousValue, currentValue) => {
                    let v = currentValue.value && Number.parseInt(currentValue.value, 10) || 0;
                    return previousValue && v > 0;
                }, 
                true
            );
        } else {
            return true;
        }
    }

    function verticalColorLegendEditor(styling:ColorByValueStyling) {

        let renderDivRows = styling.divisions && styling.divisions.list.map((div,idx) => {
            if (!div) {
                return null;
            }
            let color = div?.color || "#ffffff";
            let textInputStyle = {width:"100px"};
            let numInputStyle = {width:"50px"}; 

            let renderCriteriaSection;
            if (styling.divisions!.type === DataDivisionType.Discrete) {
                // Discrete values
                if (styling.divisions!.valuesAreNumbers) {
                    // Discrete nummeric values
                    renderCriteriaSection = (
                        <div key={"s_value"+idx} className={"divSpan-2"} style={{textAlign:"left"}}>
                        <FormGroup 
                            // size="sm"
                            controlId="formBasicText"
                            style={numInputStyle}
                            // validationState={this.getValidationStateForPositiveNumber(idx,1)}
                        >
                            <FormControl
                                className='LgScaleInput'
                                size="sm"
                                type="number"
                                autoComplete="off"
                                // bsClass="form-control MitLegendScaleInput"
                                value={styling.divisions!.list[idx].value || ""}
                                placeholder={Localization.getText("Enter value")}
                                min="1"
                                step="1"
                                onChange={(e) => valueOnChange(e,idx)}
                            />
                        </FormGroup>                    
                        </div>
                    );    
                } else {
                    // Discrete text values
                    renderCriteriaSection = (
                        <div key={"s_value"+idx} className={"divSpan-2"} style={{textAlign:"left"}}>
                        <FormGroup 
                            // size="sm"
                            controlId="formBasicText"
                            style={textInputStyle}
                            // validationState={this.getValidationStateForLabel()}
                        >
                            <FormControl
                                className='LgScaleInput'
                                size="sm"
                                type="text"
                                autoComplete="off"
                                // bsClass="form-control MitLegendScaleInput"
                                value={styling.divisions!.list[idx].value || ""}
                                placeholder={Localization.getText("Enter value")}
                                onChange={(e) => valueOnChange(e,idx)}
                            />
                        </FormGroup>                    
                        </div>
                    );    
                }
            } else {
                // Numeric Values
//                value={Number(styling.divisions!.list[idx].to).toLocaleString("da-DK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}

                let renderFrom = styling.divisions!.list[idx].from === null ? (
                    <Glyphicon
                        bsClass="mit-legend-scale-infinity-marker glyphicon" 
                        glyph="chevron-left" 
                        onClick={(e) => onClickFromInfinity(e, idx)}
                        data-toggle="tooltip" 
                        title={Localization.getText("tooltip-from-infinity")}
                    />
                ) : (
                    <FormGroup 
                        // size="sm"
                        controlId="formBasicText"
                        style={numInputStyle}
                        // validationState={this.getValidationStateForFrom(idx)}
                    >
                        <FormControl
                            size="sm"
                            className='LgScaleInput'
                            type="number"
                            autoComplete="off"
                            // bsClass="form-control MitLegendScaleInput"
                            value={Utils.formatNumber(styling.divisions!.list[idx].from!)}
                            placeholder={Localization.getText("Enter from")}
                            onChange={(e) => fromOnChange(e,idx)}
                        />
                    </FormGroup>   
                );
                let renderTo = styling.divisions!.list[idx].to === null ? (
                    <Glyphicon
                        bsClass="mit-legend-scale-infinity-marker glyphicon" 
                        glyph="chevron-right" 
                        onClick={(e) => onClickToInfinity(e, idx)}
                        data-toggle="tooltip" 
                        title={Localization.getText("tooltip-to-infinity")}
                    />
                ) : (
                    <FormGroup 
                        // size="sm"
                        controlId="formBasicText"
                        style={numInputStyle}
                        // validationState={this.getValidationStateForTo(idx)}
                    >
                        <FormControl
                            className='LgScaleInput'
                            size="sm"
                            type="number"
                            autoComplete="off"
                            // bsClass="form-control MitLegendScaleInput"
                            value={Utils.formatNumber(styling.divisions!.list[idx].to!)}
                            placeholder={Localization.getText("Enter to")}
                            onChange={(e) => toOnChange(e,idx)}
                        />
                    </FormGroup>   
                );

                renderCriteriaSection = [(
                    <div className='divSpan-1' key={"s_from"+idx} style={{textAlign:"center"}}>
                        {renderFrom}
                    </div>
                ), (
                    <div className='divSpan-1' key={"s_to"+idx} style={{textAlign:"center"}}>
                        {renderTo}
                    </div>
                )];
            }
            let iconToRemove = isRemoveDivisionEnabled() ? (
                <Glyphicon 
                    bsClass="mit-legend-scale-delete-marker glyphicon" 
                    glyph="remove" 
                    onClick={(e) => onClickRemove(e, idx)}
                    data-toggle="tooltip" 
                    title={Localization.getText("Remove Division")}
                />
            ) : null;
            let renderLabelEditor = (
                <FormGroup 
                    // size="sm"
                    controlId="formBasicText"
                    style={textInputStyle}
                    // validationState={getValidationStateForLabel()}
                >
                <FormControl
                    className='LgScaleInput'
                    size="sm"
                    type=""
                    autoComplete="off"
                    // bsClass="form-control MitLegendScaleInput"
                    value={styling.divisions!.list[idx].label || ""}
                    placeholder={Localization.getText("Enter text")}
                    onChange={(e) => labelOnChange(e, idx)}
                />
                </FormGroup>   
            );
            
            return (
                // ToDo: possible problem when deleting array elements. Key should be something else than array index
                <div key={"s2x"+idx} className='LegendRow' style={{fontSize:"7pt", borderBottom:"solid transparent 2px"}}>
                    <div>
                        <div 
                            className="int-bot mit-color-box" 
                            style={{background:color || "#fff"}} 
                            onClick={(e) => showColorChooser(e, idx)} 
                            data-toggle="tooltip" 
                            title={Localization.getText("Click to edit color")}
                        />
                    </div>
                    {renderCriteriaSection}
                    <div style={{textAlign:"left"}}>
                    {renderLabelEditor}                 
                    </div>
                    <div style={{textAlign:"right"}}>                    
                    {iconToRemove}
                    </div>
                </div>
            );
        });
        let textHeader= Localization.getText("Text");
        let renderHeaders = (styling.divisions!.type === DataDivisionType.Discrete) ? (
            <div className='LegendRow' key={"s_22"} >
                
                <div className={"divSpan-2"} ><div className="mit-legend-editor-header">{Localization.getText("Value")}</div></div>
                <div><div className="mit-legend-editor-header">{textHeader}</div></div>
                <div />
            </div>
        ) : (
            <div className='LegendRow' key={"s_223"}>
            
            <div><div className="mit-legend-editor-header">{Localization.getText("From")}</div></div>
            <div><div className="mit-legend-editor-header">{Localization.getText("To")}</div></div>
            <div><div className="mit-legend-editor-header">{textHeader}</div></div>
            
            </div>
        );
        let renderColorChooser = displayColorPickerForIdx !== undefined ? (
            <div style={{position:"absolute", left:"10px", bottom:"10px"}}>
            <ColorPicker
                color={styling.divisions!.list[displayColorPickerForIdx!]?.color || props.otherValue?.color || ""}
                onCommit={(color) => onColorChanged(color, displayColorPickerForIdx!)}
            />
            </div>
        ) : null;

        let conditionallyRenderRecalculateButton = props.dataValues && props.dataValues.length > 0 ? (
            <Button 
                size="sm" 
                // bsClass="MitLegendScaleActionButton btn btn-sm btn-default" 
                onClick={(e) => onClickRecalculate(e)}>{Localization.getText("Recalculate")}
            </Button>
        ) : null;

        let renderOutside = styling.divisions?.otherValuesCount ? (
            <div className='LegendRow' style={{fontSize:"7pt", borderBottom:"solid transparent 2px"}}>
                <div>
                    <div 
                        className="int-bot mit-color-box" 
                        style={{background:props.otherValue?.color || SettingsManager.getSystemSetting("mapColorForOtherDataValue", "cyan")}} 
                        onClick={(e) => showColorChooser(e, -1)} 
                        data-toggle="tooltip" 
                        title={Localization.getText("Click to edit color")}
                    />
                </div>
                <div className='divSpan-2' style={{textAlign:"left", paddingLeft:"0.5em"}}>
                    {Localization.getText("Other data")}
                </div>
                <div></div>
                <div className='divSpan-2'>
                </div>
            </div>
        ) : null

        return (<>
            {renderHeaders}
            {renderDivRows}
            {styling.divisions?.otherValuesCount && styling.divisions.type == DataDivisionType.Discrete ? renderOutside : null}
            <div className='LegendRow' key={"sxyt"} style={{fontSize:"7pt", borderBottom:"solid transparent 2px"}}>
            <div className={"divSpan-5"} style={{textAlign:"left"}}>
            <Button 
                size="sm" 
                variant="primary" 
                // bsClass="MitLegendScaleActionButton btn btn-sm btn-primary" 
                disabled={!validateForm()}
                onClick={(e) => onClickCommit(e)}
            >
            {Localization.getText("Commit")}
            </Button>
            <Button 
                size="sm" 
                // bsClass="MitLegendScaleActionButton btn btn-sm btn-default" 
                onClick={(e) => onClickCancel(e)}>{Localization.getText("Cancel")}
            </Button>
            <Button 
                disabled={!isAddDivisionEnabled()} 
                size="sm" 
                // bsClass="MitLegendScaleActionButton btn btn-sm btn-default" 
                onClick={(e) => onClickAddLevel(e)}>{Localization.getText("Add Division")}
            </Button>
            {conditionallyRenderRecalculateButton}
            {renderColorChooser}
            </div>
            </div>
            </>);
    }    
    

        if (Boolean(editMode)) {
            return <React.Fragment key={"test"} >{verticalColorLegendEditor(styleEdited!)}</React.Fragment>
        }
        return <React.Fragment key={"tests"}>{verticalColorLegend(style.divisions!,style,SettingsManager.getSystemSetting("allowHideIndividualDivisions",false) && Boolean(style.allowHideIndividualDivisions), props.showElementCounts)}</React.Fragment>
    }
