import { Localization, PersistenceObjectType, PersistenceScope, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import {MapitServicesInterface} from '../common/managers/MapitServicesInterface';

// class https {

//     public static request(options:any, callBack:(res:any)=>void) {

//     }
// }

export class AWSAPIGatewayInterface {

    /**
     * Get the report data once authenticated
     * @param hostName 
     * @param bearerToken 
     * @param northing 
     * @param easting 
     * @param radius 
     * @returns object containing report data or undefined
     */
    

    static changeAnyEmptyStrings(obj:any):any {
        function isObject(val) {
            if (val === null) { return false;}
            return ( (typeof val === 'function') || (typeof val === 'object') );
        }

        if (Array.isArray(obj)) {
            let res:any[] = [];
            obj.forEach((val) => { res.push(this.changeAnyEmptyStrings(val));});
            return res;
        } else {
            if (isObject(obj)) {
                let res = {};
                Object.keys(obj).forEach((key) => {
                    res = {...res, [key]:this.changeAnyEmptyStrings(obj[key])};
                });
                return res;
            } else {
                if (obj === "") {
                    obj=null;
                }
                return obj;
            }
        }
    }

    // static async ping() {
        
    //     let promise = invokeApig({
    //         endpoint: AWSAPIGatewayInterface.getHostName(), 
    //         path: "/ping",
    //         method: "GET",
    //         headers: { "Content-Type": "application/json"},
    //         queryParams:{},
    //         body:null
    //     }).then(
    //         (ok) => {
    //             alert("1: "+ok);
    //         }, 
    //         (error2)=> {
    //             alert("2: "+error2);
    //         }
    //     );

    // }



    static async persistStateDynamoDb(ref:string, state:any /* LayerExportImportFormat*/) {
        // Dynamo db does not support empty string attributes (e.g. name="")
        let newState = this.changeAnyEmptyStrings(state);

        let qs = {
            "TableName":"ShortLinks",
            "Item":{
                "ShortLink": ref,
                "Link": newState 
            },
        };

        let promise = new Promise<any>((resolve, reject) => {
            const options = {
                host: AWSAPIGatewayInterface.getHostName(), 
                path: "/linkMicroservice",
                method: "POST",
                body: JSON.stringify(qs),
                headers: { 
                    "Content-Type": "application/json",
                    "X-API-KEY": SettingsManager.getSystemSetting("mapitServicesAPIKey")
//                    "Authorization": " Bearer "+AccessManager.getUserSession().jwtToken 
                }
            }; 

            fetch("https://"+AWSAPIGatewayInterface.getHostName()+"/linkMicroservice=", options).then((a) => {
                return a.json()
            }).then((json) => {
                if (json?.Item?.Link)
                resolve(json.Item.Link);
                resolve(json)
            }).catch((err) => {
                reject(err)
            })
        });
        return promise;        
    }

    static loadStateDynamoDb(ref:string) {
        let qs = {
            "TableName":"ShortLinks",
            "Key":{
                "ShortLink": ref
            },
        };
        let promise = new Promise<any>((resolve, reject) => {
            const options = {
                method: "GET",
                body: JSON.stringify(qs),
                headers: { 
                    "Content-Type": "application/json",
                    "X-API-KEY": SettingsManager.getSystemSetting("mapitServicesAPIKey")
                }
                }; 
                fetch("https://"+AWSAPIGatewayInterface.getHostName()+"/linkMicroservice/?TableName=ShortLinks&ShortLink="+ref, options).then((a) => {
                    return a.json()
                }).then((json) => {
                    if (json?.Item?.Link)
                    resolve(json.Item.Link);
                    resolve(json)
                }).catch((err) => {
                    reject(err)
                })
        });
        return promise;
    }
 

    static async deleteState(ref:string) {
        let qs = {
            "TableName":"ShortLinks",
            "Key":{
                "ShortLink": ref
            },
        };
        let result;
        let promise = new Promise<any>((resolve, reject) => {
            const options = {
                host: AWSAPIGatewayInterface.getHostName(), 
                path: "/linkMicroservice/?TableName=ShortLinks&ShortLink="+ref,
                method: "DELETE",
                body: JSON.stringify(qs),
                headers: { 
                    "Content-Type": "application/json",
                    "X-API-KEY": SettingsManager.getSystemSetting("mapitServicesAPIKey")
                }
                }; 

                fetch("https://"+AWSAPIGatewayInterface.getHostName()+"/linkMicroservice/?TableName=ShortLinks&ShortLink="+ref, options).then((a) => {
                    return a.json()
                }).then((json) => {
                    resolve(json)
                }).catch((err) => {
                    reject(err)
                })

        });
        return result;        
    }

    static loadState(ref:string) {
        if (ref.includes("/")) {
            let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
            let [domain,mapKey,err] = ref.split("/")
            if (err !== undefined) {
                throw new Error("Can't understand mapref:" + ref)
            }
            let scope = domain.includes("@") ? PersistenceScope.User : PersistenceScope.Customer
            let customer = domain.split("@").at(-1) || ""
            persit.createPrefix(scope, PersistenceObjectType.MapitMaps, customer, domain)
            return persit.getObject(scope, PersistenceObjectType.MapitMaps, customer, domain, mapKey)
        }
        if (SettingsManager.getSystemSetting("mapLinkOnS3", false)) {
            let savedMapPath=SettingsManager.getSystemSetting("mapLinkOnS3Path", "savedmaps/");
            return AWSAPIGatewayInterface.loadStateS3(savedMapPath+ref).then((result) => {
                return result;
            }).catch((err1) => {
                return AWSAPIGatewayInterface.loadStateDynamoDb(ref).then((result2) => {
                    if (Object.keys(result2).length > 0) {
                        return result2;
                    }
                    throw err1.message || err1;
                }).catch((err) => {
                    throw err.message || err;
                });
            });
        } else {
            return AWSAPIGatewayInterface.loadStateDynamoDb(ref);
        }
    }

    static loadStateS3(ref:string) {
        let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
        let configBucket = SettingsManager.getSystemSetting("mapitS3Bucket");
        let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
        return MapitServicesInterface.getDataS3(serviceHost, apiKey, configBucket, ref)
        .then((data) => { 
            return (data);
        }).catch((err) => {
            throw (err.message || err);
        });
    }

    static persistState(ref:string, state:any /* LayerExportImportFormat*/, path: string, s3metadata?: { [key: string]: string }) {
        if (SettingsManager.getSystemSetting("mapLinkOnS3", false)) {
            return AWSAPIGatewayInterface.persistStateS3(path+ref, state, s3metadata);
        } else {
            return AWSAPIGatewayInterface.persistStateDynamoDb(ref, state);
        }
    }

    static persistStateS3(ref:string, state:any /* LayerExportImportFormat*/, metadata?: { [key: string]: string }, makePublic: boolean = false) {
        let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
        let configBucket = SettingsManager.getSystemSetting("mapitS3Bucket");
        let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");


        return MapitServicesInterface.putDataS3(serviceHost, apiKey, configBucket, ref, state, metadata, makePublic);
    }

    /**
     * Deletes a file from the given S3 path. This function handles credentials
     * @param path AWS S3 path, including file name, but excluding bucket name
     * @returns A boolean promise, returning true if deletion is successful, otherwise false
     */
    static deleteFileS3(path: string) {
        let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
        let configBucket = SettingsManager.getSystemSetting("mapitS3Bucket");
        let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
        return MapitServicesInterface.deleteFileFromS3(serviceHost, apiKey, configBucket, path);
    }

    /**
     * Lists the contents of a folder in the given S3 path. This function handles credentials
     * @param path AWS S3 path, but excluding bucket name
     * @returns A promise of a list of complete file paths as strings for each file in the given path.
     */
    static listFolderS3(prefix: string, bucket?:string) {
        let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
        let configBucket = SettingsManager.getSystemSetting("mapitS3Bucket");
        let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
        return MapitServicesInterface.listFolderS3(serviceHost, apiKey, bucket || configBucket, prefix);
    }

    static getPois(bbox:string, poitypes:string) {

        let qs = { 
            "body": {
                bbox:bbox,
                poitypes:poitypes
            }
        };
        
        const options = {
            host: AWSAPIGatewayInterface.getHostName(), 
            path: "/getpoi",
            method: "POST",
            body: JSON.stringify(qs),
            headers: { 
                "Content-Type": "application/json",
                "X-API-KEY": SettingsManager.getSystemSetting("mapitServicesAPIKey")
            }
        }; 

        const createErrorResponse = (statusCode:number, body:any) => {
            let errorResponse="";
            switch (statusCode) {
                // todo: add more custom error descriptions
                default:
                    errorResponse="Err:Internal error ({statusCode}): {body}";
            }
            errorResponse = Localization.getFormattedText(errorResponse, {statusCode:statusCode, body:body});
            return errorResponse;
        };

        let promise = new Promise<any>((resolve, reject) => {
            
            fetch("https://"+AWSAPIGatewayInterface.getHostName()+"/getpoi", options).then((a) => {
                return a.json()
            }).then((json) => {
                if (json?.body)
                resolve(json.body);
                resolve(json)
            }).catch((err) => {
                reject(err)
            })
        });
        return promise;
    }

    // ------------------------------------------- VERSION2 INTERFACES ------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------------    
    static ambitionGetReportDataV2(authHost:string, clientId:string, clientSecret:string, apiHost:string, easting:number, northing:number, radius:number, dataGroup:number) {

        let qs = { 
            "body": {
                "authHost":authHost,
                "clientId":clientId,
                "clientSecret":clientSecret,
                "apiHost":apiHost,
                "northing":northing,
                "easting":easting,
                "radius":radius,
                "variableConfigurationId":dataGroup 
            }
        };
        const options = {
            host: AWSAPIGatewayInterface.getHostName(), 
            path: "/get-statistics-for-circle-v2",
            method: "POST",
            body: JSON.stringify(qs),
            headers: { 
                "Content-Type": "application/json",
                "X-API-KEY": SettingsManager.getSystemSetting("mapitServicesAPIKey")
            }
        }; 

        const createErrorResponse = (statusCode:number, body:any) => {
            let errorResponse="";
            switch (statusCode) {
                // todo: add more custom error descriptions
                case 404:
                    errorResponse="Err:No data for location ({statusCode})";
                    break;
                case 401:
                    errorResponse="Err:Insufficient permissions ({statusCode})";
                    break;
                case 400:
                case 405:
                case 500:
                case 501:
                case 502:
                    errorResponse="Err:Internal error ({statusCode}): {body}";
                    break;
                case 504:
                    errorResponse="Err:Timeout ({statusCode}): {body}";
                    break;
                default:
                    errorResponse="Err:Internal error ({statusCode}): {body}";
            }
            errorResponse = Localization.getFormattedText(errorResponse, {statusCode:statusCode, body:body});
            return errorResponse;
        };

        let promise = new Promise<any>((resolve, reject) => {
            fetch("https://"+AWSAPIGatewayInterface.getHostName()+"/get-statistics-for-circle-v2", options).then((a) => {
                return a.json()
            }).then((json) => {
                if (json.statusCode != 200) 
                    reject(json.body);
                if (json?.body)
                    resolve(json.body);
                resolve(json)
            }).catch((err) => {
                reject(err)
            })
        });

        return promise;
    }

    /**
     * Returns the proper API to use staging or production
     */
    private static getHostName():string {
        return SettingsManager.getSystemSetting("mapitServicesHost");
    }

}
