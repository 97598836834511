import { ColorList, ColorRange, DataDivision, DataDivisionList, DataDivisionType } from "src/common/managers/Types";

  // ------------------- division calculations --------------------------
 export class DivisionCalculator {

   static sortNumber(a:number ,b:number):number {
   return a - b;
  }
 
  // ensure that the number is returned nummeric (and not string)
  static toN(nbr:string):number { return (Number.parseInt(nbr,10));}
 
  static getCopyOfArray(array:any[]):any[] {
   let result:any[] = [];
   array && array.map((value, index) => {
     result.push(value);
   } );
   return (result);
 }
 
  /**
   * Calculates divisions for descrete strings.
   * Only the first 'numberOfDivs' unique elements will get their own bucket.
   * Bucket names will be sorted alphabatically.
   * @param columnDataInput a single array of strings
   * @param numberOfDivs number of divisions/buckets to create
   */
  static calculateDataDivisionsDiscrete(columnDataInput:string[], numberOfDivs:number = 4):DataDivisionList {
    const uniqueValues = [...new Set(columnDataInput.map((a) => a ? a : ""))]
    uniqueValues.sort((a,b) => (a).localeCompare(b, "da-dk"));
    let result:DataDivisionList = {
      type:DataDivisionType.Discrete, 
      list:[], 
      otherValuesCount: uniqueValues.length > numberOfDivs ? uniqueValues.length - numberOfDivs : 0
    };

    uniqueValues.length = numberOfDivs

    let divList = uniqueValues.map((val) => {
      const count = columnDataInput.filter((a) => a == val || (val == "" && a == undefined)).length
      return {from:null, to:null, value:val, count:count}
    }) as DataDivision[];

    result.list = divList;
    return(result);
 }

 static calculateCountForDivisions(columnDataInput, divisionList: DataDivisionList):DataDivisionList {
  let isDiscrete = false;
  if (divisionList.type == DataDivisionType.Discrete) {
    isDiscrete = true
  }
  return {
    ...divisionList,
    list: divisionList.list.filter((a) => a).map((a) => {
    if (isDiscrete) {
      let count = columnDataInput.filter((val) => val == a.value || (a.value == "" && a == undefined)).length
      return {
        ...a, count: count
      }
    } else {
      let count = columnDataInput.filter((val) => (a.from === null || val > a.from) && (a.to === null || val <= a.to)).length
      return {
        ...a, count: count
      }
    }
  }) || []}
 }
 
 
  /**
   * Calculates divisions such that each division/bucket contains the approx the same number of data elements
   * Each division has a 'from' value (exclusive - or null for the first division)
   * and a 'to' value (inclusive - or null for the last division)
   * @param columnDataInput a single array of numbers
   * @param numberOfDivs number of divisions/buckets to create
   */
  static calculateDataDivisions(columnDataInput:any[], numberOfDivs:number):DataDivisionList {
     console.assert(columnDataInput,"Data must be supplied");
     if (!numberOfDivs) { numberOfDivs=4; }
 
     // Sort the array numerically;
     let columnData:any[] = columnDataInput.filter(a => a);
     columnData.sort(this.sortNumber);
 
     let result:DataDivisionList = {type:DataDivisionType.Continous, list:[]};
     let count = 0;
     let minVal=Number.MAX_VALUE;
     let maxVal=Number.MIN_VALUE;
     columnData.forEach((val) => {
         // ToDo: Better error handling on non-nummeric data
         // if (!isNaN(val)) {
         //   throw Utils.createErrorEventObject(Localization.getText("Dataelement is not nummeric. Cannot style"));
         // }
         console.assert(!isNaN(val), "Array element is not a number");
         count++;
         val = this.toN(val);
         if (val>maxVal) {maxVal=val;}
         if (val<minVal) {minVal=val;}
     });
 
     if (count === 1) {
       result.list.push({from:null, to:this.toN(columnData[0]), value:null, count:1});
     } else {
       if (count < numberOfDivs) { numberOfDivs = count; }
       let obsPerDiv = count/numberOfDivs; // This must not be an integer division
       for (let div = 0; div < numberOfDivs; div++) {
         let lastIndexBeforeDiv = Math.floor(div*obsPerDiv)-1;
         let firstIndexforDiv = Math.floor(div*obsPerDiv);
         let lastIndexforDiv = Math.floor((div+1)*obsPerDiv)-1;
         let firstIndexAfterDiv = Math.floor((div+1)*obsPerDiv);
         let divfrom:number|null;  
 
         if (lastIndexBeforeDiv < 0) {
           divfrom=null;
         } else {
           divfrom= (this.toN(columnData[lastIndexBeforeDiv])+this.toN(columnData[firstIndexforDiv]))/2;
         }
         let divto:number|null;
         if (firstIndexAfterDiv >= count) {
           divto=null;
         } else {
           divto= (this.toN(columnData[lastIndexforDiv])+this.toN(columnData[firstIndexAfterDiv]))/2; 
         }
         // count the number of elements
         let countInDivision = columnDataInput.reduce((result,val) => {
          return ((divfrom === null || val > divfrom) && (divto === null || val <= divto)) ? result + 1 : result;
          }, 0)
         let newDiv:DataDivision = {from:divfrom, to:divto, value:null, count:countInDivision};
         result.list.push(newDiv);
       }
     }
     return(result);
   }
 
   /**
    * Finds division for number. Returns -1 if not found.
    * @param divisions 
    * @param value 
    * @returns index or -1 if not found
    */
   static getDivisionId(divisions:DataDivisionList, value:number) {
     let divIdx = -1;
     console.assert(divisions && divisions.list.length, "getDivisionId, Unexpected empty divisions");
 
     for (let i=0; i<divisions.list.length; i++) {
       if (
         (divisions.list[i].from === null && value <= divisions.list[i].to!)
         || (value > divisions.list[i].from! && value <= divisions.list[i].to!)
         || (value > divisions.list[i].from! && divisions.list[i].to === null )
       ) {
       divIdx = i;
       break;
     }
     }
     return (divIdx);
   }      
 
   /**
    * Finds division for string. Returns -1 if not found.
    * @param divisions 
    * @param value 
    * @returns index or -1 if not found
    */
   static getDivisionIdDiscrete(divisions:DataDivisionList, value:string) {
     let divIdx = -1;
     console.assert(divisions && divisions.list.length, "getDivisionIdDiscrete, Unexpected empty divisions");
     if (value == undefined) {
      return 0
     }
     divIdx = divisions.list.findIndex((val,idx) => { return val.value === value});
 
     return (divIdx);
   } 

     /**
   * Creates a ColorRange from the colors used in a DataDivision
   */
  static extractColorRangeFromDivision(divisions:DataDivisionList):ColorRange {
    let colors:ColorList=[];
    divisions.list.forEach((obj,idx) => {
      if (obj) {
        console.assert(obj.color,"Expected color");
        colors.push(obj.color || "#000");
      }
    });
    let result:ColorRange = {
        name:"Constructed from custom divisions",
        order:1,
        colors:colors,
        isCustom:true,
    };
    return result;
  }
  
}