import { Localization } from "@viamap/viamap2-common";
import { useContext } from "react";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { Feature } from "src/states/ApplicationStateFeatures";

import {MapInteractionState, MapitStateContext} from 'src/states/MapitState';


const InteractionFeatures = {
  [MapInteractionState.Normal]: null,
  [MapInteractionState.NSZoomedIn]: null,
  [MapInteractionState.ZoomedIn]: Feature.PropertyInfo,
  [MapInteractionState.ClickToSetMarker]: null,
  [MapInteractionState.ClickToShowPropertyInfo]: Feature.PropertyInfo,
  [MapInteractionState.ClickToShowTravelTimePolygons]: Feature.TravelTime,
  [MapInteractionState.ClickToShowObliquePhotos]: Feature.ObliquePhoto,
  [MapInteractionState.ClickToShowPointDistanceTable]: Feature.TravelTimeFromPoint,
  [MapInteractionState.Override]: null,
  [MapInteractionState.EnforceFeatureLayerPopup]: null,
  [MapInteractionState.HentVurderingsEjendom]: Feature.VisVurderingsejendom,
  [MapInteractionState.HentSamletFastEjendom]: Feature.VisSamletFastEjendom,
  [MapInteractionState.ClickToShowStreetView]: Feature.StreetView,
}

const InteractionMessages = {
  [MapInteractionState.Normal]: null,
  [MapInteractionState.ZoomedIn]: "Click to show PropertyInformation",
  [MapInteractionState.NSZoomedIn]: null,
  [MapInteractionState.ClickToSetMarker]: "Click to add a new Point",
  [MapInteractionState.ClickToShowPropertyInfo]: "Click to show PropertyInformation",
  [MapInteractionState.ClickToShowTravelTimePolygons]: "Click to show Travel Time Polygons",
  [MapInteractionState.ClickToShowObliquePhotos]: "Click to Show Oblique Photos",
  [MapInteractionState.ClickToShowPointDistanceTable]: "Click to show Point Distance Table",
  [MapInteractionState.Override]: null,
  [MapInteractionState.EnforceFeatureLayerPopup]: "Click to show background layer info",
  [MapInteractionState.HentVurderingsEjendom]: "Click to fetch valued Estate",
  [MapInteractionState.HentSamletFastEjendom]: "Click to fetch Estate",
  [MapInteractionState.ClickToShowStreetView]: "Go to street view",
}

export function MapInteractionStateInfoBox() {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const {state:mapitState} = useContext(MapitStateContext)

  let acces = InteractionFeatures[mapitState.mapInteractionState]
  if (acces && !hasAccessToFeature(acces)) {
    return <></>
  }
  let message = InteractionMessages[mapitState.mapInteractionState]
  if (message) {
    return (
      <div className="MapInteractionStateInfoBox">
      {Localization.getText(message)}
    </div>
  )
  }
  return <></>
}