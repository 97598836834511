import React from "react"

export type RightPaneState = {
  current: RightPaneModus,
  history: (RightPaneModus)[],
}

enum RightPaneStateActions {
  GoToModus,
  ToggleModus,
  StepOut
}

type RightPaneStateActionals = ToggleModus | GoToModus | StepOut

export const enum RightPaneModus {
  none = "",
  Map_POI = "Map_POI",
  Map_Layer = "Map_Layer",
  Map_Discover = "Map_Discover",
  Map_Catchment = "Map_Catchment",
  Map_CatchmentMultiple = "Map_CatchmentMultiple",
  Map_GeoDataRapport = "Map_GeoDataRapport",
  Map_Measure = "Map_Measure",
  Dashboard = "Dashboard",
  Map = "Map",
  Search = "Search",
  Map_Explorer = "Map_Explorer",
  Map_Ownership = "Map_Ownership",
  Ejerskab = "Ejerskab",
  Projects = "Projects",
  GeoDataRapport = "GeoDataRapport",
  Map_Share = "Map_Share",
  Map_Plot = "Map_Plot",
}

function RightPaneReducer(state: RightPaneState, action: RightPaneStateActionals) {
  switch (action.type) {
    case RightPaneStateActions.GoToModus:
      return {...state, history: [...state.history, state.current], current: action.payload.modus}
    case RightPaneStateActions.ToggleModus:
      let current = state.current;
      if (current == action.payload.modus) {
        let splitted = current.split("_")
        let next = splitted.splice(0,splitted.length-1).join("_")
        return {...state, history: [...state.history, current], current: next}
      }
      return {...state, history: [...state.history, current], current: action.payload.modus}
    case RightPaneStateActions.StepOut: {
      let current = state.current;
      let splitted = current.split("_")
      let next = splitted.splice(0,splitted.length-1).join("_")
      return {...state, history: [...state.history, current], current: next}}
    default: 
      throw new Error("Method doesn't exists in RightPaneReducer")
  }
}

export const actionGoToModus = (modus: RightPaneModus):GoToModus => ({
  type: RightPaneStateActions.GoToModus,
  payload: {modus : modus}
})

export const actionToggleModus = (modus: RightPaneModus):ToggleModus => ({
  type: RightPaneStateActions.ToggleModus,
  payload: {modus : modus}
})

export const actionStepOut = ():StepOut => ({
  type: RightPaneStateActions.StepOut
})

export interface GoToModus {
  type: RightPaneStateActions.GoToModus,
  payload: {modus : RightPaneModus}
}

export interface ToggleModus {
  type: RightPaneStateActions.ToggleModus,
  payload: {modus : RightPaneModus}
}

export interface StepOut {
  type: RightPaneStateActions.StepOut
}

const initialState : RightPaneState = ({
  current: RightPaneModus.none, //Return to Dashboard, in Mapit Explore
  history: []
})

export const RightPaneContext = React.createContext<{
  state: RightPaneState;
  dispatch: React.Dispatch<RightPaneStateActionals>;
}>({
  state: initialState,
  dispatch: () => null
});

export function useSetupRightPane() {
  const [state, dispatch] = React.useReducer<any, RightPaneState>(RightPaneReducer, initialState, (a) => a)
  return {state: state as RightPaneState,dispatch: dispatch as React.Dispatch<RightPaneStateActionals>}
}