import { Button, Card } from "react-bootstrap";
import { Localization } from "@viamap/viamap2-common";
import { MitDialog, MitButton, MitHeader, MitBody, MitFooter, MitInfo } from "./ComponentUtils";

type Props = {
  showWindow: boolean;
  onFormClose: () => void;
  noOfStarted: number;
  noOfCompleted: number;
  stats: { [stat: string]: number };
};

export function ImportResults(props: Props) {
  if (props.showWindow) {
    let RenderStats =
      props.stats &&
      Object.keys(props.stats).map((key, idx) => {
        const localizedKey = Localization.getText(key);
        return (
          <tr key={"stats" + idx}>
            <td>{localizedKey}</td>
            <td style={{ textAlign: "right" }}>{props.stats[key]}</td>
          </tr>
        );
      });

    return (
      <MitDialog show={props.showWindow ? 1 : 0} style={{margin:"auto", width: "640px", top:"10px"}} >
        <MitHeader position="center">
          {Localization.getText("Import Status")}
        </MitHeader>
        <MitBody>
        <table cellPadding={5}>
              <tbody style={{ textAlign: "left", color: "darkslategray" }}>
                <tr>
                  <td>{Localization.getText("Started")}</td>
                  <td>{props.noOfStarted}</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ height: "5pt" }}>
                    <hr />
                  </td>
                </tr>
                {RenderStats}
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr
                  style={{
                    background:
                      props.noOfStarted === props.noOfCompleted
                        ? "lightblue"
                        : "",
                  }}
                >
                  <td>{Localization.getText("Completed")}</td>
                  <td>{props.noOfCompleted}</td>
                </tr>
              </tbody>
            </table>
        </MitBody>
        <MitFooter>
        <MitButton
              variant="close"
              onClick={() => props.onFormClose()}
            >
              {Localization.getText("Close")}
            </MitButton>
        </MitFooter>
      </MitDialog>
    );
  } else {
    return null;
  }
}
