import * as React from 'react';
import { AppMessagesContext, Localization, PersistenceObjectType, PersistenceScope, SessionContext, SettingsManager, ViamapPersistenceLayer, actionSetInfoMessage } from "@viamap/viamap2-common";
import { AiOutlineMinusSquare, AiOutlineCheckSquare, AiOutlineBorder, AiFillAppstore } from 'react-icons/ai';
import { BsEject, BsCheckCircle, BsCircle, BsChevronRight, BsHeartFill, BsXLg, BsX, BsPenFill, BsPlus, BsPlusLg, BsGearFill } from 'react-icons/bs';
import { useState } from 'react';
import { FeatureLayer, getFeatureLayerGroupTranslation } from 'src/managers/WmsLayerFunc';
import { MapitStateContext } from 'src/states/MapitState';
import { MitCheckBox, MitButton } from './ComponentUtils';
import { ProtectedFeature } from './ProtectedFeature';
import { Feature } from 'src/states/ApplicationStateFeatures';
import { ApplicationStateContext } from 'src/states/ApplicationState';
import { LayerInfo, LayerVariabilityType } from 'src/common/managers/Types';
import { AWSS3Interface } from 'src/managers/AWSS3Interface';
import uuid from 'uuid';
import { MitStorage } from 'src/managers/MitStorage';
import { SearchBarGeneral } from './SearchBarGeneral';
import { CustomLayerAutoComplete, FavoriteLayerAutoComplete, FeatureLayerAutoComplete } from 'src/managers/SearchBarGeneralPlugin';


type Props<T> = {
   callBackonSettingsFeatureLayer(key: string, show: boolean): unknown;
   addedAttributes: number;
   backgroundLayers: {[name:string]:string};
   featureLayers: {[name:string]:T};
   selectedBackgroundLayerKey: string;
   selectedFeatureLayerKeys: string[];
   customFeatureLayers: T[];
   editFeatureAvailable: boolean;
   onCustomFeatureLayerToggle: (customFeatureLayers: {}) => void,
   callBackOnSelectBackgroundLayer: (key: string) => void;
   callBackOnSelectFeatureLayer: (key: string, selected: boolean, layer?: FeatureLayer) => void;
   callbackToConvertCustomFeatureLayerToNormal: (featureLayer: any) => void;
};

export function GroupedLayerControl(props:Props<any>) {
   const isReady = React.useRef(false)
   const {state} = React.useContext(SessionContext)
   const [myCustomGroups, setMyCustomGroups] = React.useState<CustomGroup[]>([])
   const {hasAccessToFeature} = React.useContext(ApplicationStateContext)

   React.useEffect(() => {
      if (isReady.current && SettingsManager.getSystemSetting("SaveOpendedFeatureLayers", true)) {
         SaveSelectedLayers(props.selectedFeatureLayerKeys)
      }
      if (isReady.current && ! SettingsManager.getSystemSetting("SaveOpendedFeatureLayers", true)) {
         SaveSelectedLayers([])
      }
      isReady.current = true
   }, [props.selectedFeatureLayerKeys])

   const CFLGFileName = "CutomFeatureLayersGroups.json"
   React.useEffect(() => {
      if (!hasAccessToFeature(Feature.FavoriteBackgroundLayers)) {
         return;
      }

      const abort = new AbortController() 
      let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
      persit.getObject(PersistenceScope.User, PersistenceObjectType.CustomLayers, state.customerRef, state.userRef, CFLGFileName)
      .then((a) => {
         if (abort.signal.aborted) return;
         let str = a.Body.toString("utf-8")
         setMyCustomGroups((a) => [...JSON.parse(str)])
      }).catch((a) => {

         return null //Do Nothing
      })
      return () => abort.abort()
   },[state.userRef, state.customerRef])

   function saveCustomFeatureLayerGroups(myCustomGroups:CustomGroup[]) {
      if (!hasAccessToFeature(Feature.FavoriteBackgroundLayers)) {
         return;
      }
      let persist = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
      persist.uploadObject(PersistenceScope.User, PersistenceObjectType.CustomLayers, state.customerRef, state.userRef, JSON.stringify(myCustomGroups),CFLGFileName)
   }

   function GroupReducer(action, value:{uuid: string} & Partial<CustomGroup>) {
      let X = myCustomGroups
      switch (action) {
         case "Remove":
            X = X.filter((a) => a.uuid !== value.uuid)
         break;
         case "Rename":
            X = X.map((a) => {return a.uuid === value.uuid ? {...a, title: value.title || ""} : a })
         break;
         case "Add": 
            X = [...(X.filter((a) => a.uuid !== value.uuid)), (value as CustomGroup)]            
      }
      setMyCustomGroups([...X])
      saveCustomFeatureLayerGroups([...X])
   }

   function onBackgroundLayerChange(newKey: string) {
      props.callBackOnSelectBackgroundLayer(newKey);
   }

   function onFeatureLayerChange(key: string, checked: boolean, layer?:FeatureLayer) {
      props.callBackOnSelectFeatureLayer(key, checked, layer);
   }

   function onSettingClick(key:string, show: boolean) {
      props.callBackonSettingsFeatureLayer(key, show);
   }

   function turnAllLayersOff() {
      props.customFeatureLayers.forEach((val) => {
         if (val.visible) {
            props.onCustomFeatureLayerToggle(val)
         }
      }) 

      props.selectedFeatureLayerKeys.forEach(key => {
         props.callBackOnSelectFeatureLayer(key, false);
      });
   }

   
   return (
      
      <div className='Wrap' >
   <div className='Head FeaturelayerHead'>
      <MitButton variant='normal' disabled={!(props.selectedFeatureLayerKeys.length || props.customFeatureLayers.filter(a => a.visible).length)} size='sm' onClick={() => turnAllLayersOff()} >{Localization.getText("Reset")}</MitButton>
      {/* <MitButton variant='normal' size="sm" onClick={() => {saveAsCustomFeatureLayerGroup()}} >{Localization.getText("Save")}</MitButton> */}
      <SearchBarGeneral
          showWindow={true}
          autocompleters={[
            new CustomLayerAutoComplete(props.customFeatureLayers, (selected) => {
               props.onCustomFeatureLayerToggle(selected)
            }),
            new FavoriteLayerAutoComplete(myCustomGroups, (selected) => {
               let layersInGroup = selected.layers.filter((a) => selected.layerSpecs || props.featureLayers[a])
               let activeInGroup = layersInGroup.filter((a) => props.selectedFeatureLayerKeys.includes(a))

               function toggleGroup() {
                  if (activeInGroup.length) {
                     activeInGroup.forEach((a) => {
                        props.callBackOnSelectFeatureLayer(a, false)
                     })
                  } else {
                     layersInGroup.forEach((a) => {
                        props.callBackOnSelectFeatureLayer(a, true, selected.layerSpecs?.[a])
                     })
                  }
               }
               toggleGroup()
            }),
            new FeatureLayerAutoComplete(Object.values(props.featureLayers), props.selectedFeatureLayerKeys , (selected) => {
              const show = !props.selectedFeatureLayerKeys.includes(selected.layer.label)
              props.callBackOnSelectFeatureLayer(selected.layer.label, show, selected)
            })
          ]}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
   </div>
   <div className='Body'>
      <BackgroundLayerGrouped 
         backgroundLayers={props.backgroundLayers}
         onBackgroundLayerChange={(a) => onBackgroundLayerChange(a)}
         selectedBackgroundLayerKey={props.selectedBackgroundLayerKey}
      />
      <div className='Splitter' />
      <ProtectedFeature feature={Feature.FavoriteBackgroundLayers} contentsIfNoAccess={<></>} >
      <CutomFeatureLayersGroups 
         layers={props.featureLayers}
         groupedLayer={myCustomGroups}
         onGroupChange={GroupReducer}
         backgroundLayers={props.backgroundLayers}
         onChange={(a,b,c?) => onFeatureLayerChange(a,b,c)}
         selectedFeatureLayerKeys={props.selectedFeatureLayerKeys}
         onBackgroundLayerChange={(a) => onBackgroundLayerChange(a)}
         selectedBackgroundLayerKey={props.selectedBackgroundLayerKey}
         />
      </ProtectedFeature>
      <SavedMapLayerGroup
         customFeatureLayers={props.customFeatureLayers}
         editFeatureAvailable={props.editFeatureAvailable}
         callbackToConvertCustomFeatureLayerToNormal={(a) => props.callbackToConvertCustomFeatureLayerToNormal(a)}
         onCustomFeatureLayerToggle={(a) => props.onCustomFeatureLayerToggle(a)}
      />
      <FeatureLayers 
         layers={props.featureLayers}
         onChange={(a,b) => onFeatureLayerChange(a,b)}
         settingClick={(a,b) => onSettingClick(a,b)}
         selectedFeatureLayerKeys={props.selectedFeatureLayerKeys}
      />
      </div>
      </div>
   );
}




type PropsCustomFeatureLayersGroups = {
   layers: {[key:string]:any},
   groupedLayer: CustomGroup[]
   backgroundLayers: {[key:string]:string},
   onChange: (a,b,c?) => any,
   onGroupChange(action, value),
   selectedFeatureLayerKeys: string[],
   onBackgroundLayerChange: (a) => any
   selectedBackgroundLayerKey: string,
}
export type CustomGroup = {
   uuid: string,
   title: string,
   bgLayer: string,
   layers: string[]
   customLayers: {layerVariability: number ,datasetname:string, group:string[]}[]
   layerSpecs: {[key:string]:FeatureLayer}
}


function CutomFeatureLayersGroups(props:PropsCustomFeatureLayersGroups) {
   let {dispatch: appMessageDispatch} = React.useContext(AppMessagesContext)
   let [collapsed, setCollapsed] = useState(false)

   // if (!(props.groupedLayer?.length)) return null

   function onRemove(uuid:string) {
      props.onGroupChange("Remove",{uuid})
   }

   function onEdit(obj) {
      props.onGroupChange("Rename",obj)
   }

   function onAdd() {
      function checkIfAnyActive() {
         return props.selectedFeatureLayerKeys?.length ||
            Object.values(props.layers).filter((a) => (a.layerVariability == 1 && a.visible)).length
      }

      if (!(checkIfAnyActive())) {
         appMessageDispatch(actionSetInfoMessage(Localization.getText("Please activate layers to be included, before adding a group")))
         return
      }
      let layerSpecs = {}
      props.selectedFeatureLayerKeys.forEach((a) => {
         layerSpecs = {...layerSpecs, [a]: props.layers[a].copy()}
      })
      const NewGroup:CustomGroup = {
         uuid: uuid.v4(),
         title: "",
         bgLayer: props.selectedBackgroundLayerKey,
         layers: [...props.selectedFeatureLayerKeys],
         customLayers: [], // TODO:
         layerSpecs: layerSpecs
      }
      props.onGroupChange("Add",NewGroup)
   }

   return (
      <div className='GroupWrap'>
         <div onClick={() => setCollapsed((a) => (!a))} className='GroupHead'>
         <BsHeartFill color='#0000' />
         {Localization.getText("Favorite layers")}
         
         <div style={{marginLeft:"auto",marginRight:"5px"}}><BsPlusLg title={Localization.getText("Add Layer")} className='ShowOnHover' style={{marginRight:"5px"}} onClick={(e) => {onAdd();e.stopPropagation()}} />
      <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(!collapsed?"90deg":"0deg")+")"}}><BsChevronRight /></span>
      </div></div>
      <div className='layerInGroup' >
      {!collapsed && props.groupedLayer.map((a) => {
         return (
            <React.Fragment key={a.uuid} >
               <SingleCustomLayer group={a} remove={onRemove} edit={onEdit} featureLayers={props.layers}  selectedLayers={props.selectedFeatureLayerKeys} onChange={props.onChange} />
            </React.Fragment>
         )
      })}
      </div>
      </div>
   )
}

function SingleCustomLayer(props:{group:CustomGroup,featureLayers:any, selectedLayers:string[], onChange:(a,b,c?) => any, remove, edit}) {
   // let [collapsed, setCollapsed] = useState(true)
   let [edit, setEdit] = useState(!(props.group.title))

   let layersInGroup = props.group.layers.filter((a) => props.group.layerSpecs || props.featureLayers[a])
   let activeInGroup = layersInGroup.filter((a) => props.selectedLayers.includes(a))

   function toggleGroup(e) {
      e.preventDefault()
      e.stopPropagation();
      if (activeInGroup.length) {
         activeInGroup.forEach((a) => {
            props.onChange(a, false)
         })
      } else {
         layersInGroup.forEach((a) => {
            props.onChange(a, true, props.group.layerSpecs?.[a])
         })
      }
   }

   function Blur(val) {
      if (val) {
         props.edit({...props.group, title:val})
         setEdit(false)
      }
   }

   return (<>
   {/* // <div className='GroupWrap'> */}
      {/* <div className='GroupHead'> */}
      <div className='layer'>

         <MitCheckBox onClick={toggleGroup} variant='dark'  checked={!!activeInGroup.length} indeterminate={!!(activeInGroup.length && activeInGroup.length < layersInGroup.length || false)} />
         { !edit ? <span onDoubleClick={() => setEdit(true)} title={Localization.getText("2xClick to Edit Name")} >{props.group.title}</span> :
            <input autoFocus={true} placeholder={Localization.getText("Enter name")} defaultValue={props.group.title} onBlur={((e) => {Blur(e.target.value)})} />
         }
         <div className='ShowOnHover' style={{marginLeft:"auto",marginRight:"5px"}}>
      
      {/* <BsPenFill onClick={() => {props.edit(props.group.uuid)}} /> */}
      <BsX title={Localization.getText("Delete layer")} onClick={() => (props.remove(props.group.uuid))} />
      </div>
      {/* <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(!collapsed?"90deg":"0deg")+")"}}><BsChevronRight /></span> */}
      
      {/* </div></div> */}
      {/* {!collapsed ? <div className='layerInGroup' >
      {props.group.layers.map((key) => {
         return (
         <div key={key} className='layer'> 
         <MitCheckBox variant='dark' onClick={(evt, val) => props.onChange(key, val)} checked={activeInGroup.includes(key)} >{(props.featureLayers[key] as any)?.translationTable ? Localization.getTextSpecificTable(key, (props.featureLayers[key] as any).translationTable) : key}</MitCheckBox>
         </div>
         )
      })}
      </div>
      : null} */}

      </div>
      </>)
}

type FastLagGroup<T> = {
   customFeatureLayers?: T[],
   callbackToConvertCustomFeatureLayerToNormal: (layer:T) => void,
   onCustomFeatureLayerToggle: (layer:T) => void,
   editFeatureAvailable?: boolean,
}

function FasteLagInGroup(key:string[], layers:LayerInfo[]):LayerInfo[] {
   return layers.filter((layer) => key.every((a,idx) => a == layer.group?.[idx]))
}

function FasteLagAtLevel(key: string[], layers:LayerInfo[]):LayerInfo[] {
   return layers.filter((layer) => layer.group?.length === key.length && key.every((a,idx) => a == layer.group?.[idx] ))
}

function uniqueSubGroup(layers:LayerInfo[], cur:string[], filter?:string[]):string[][] {
   const SEPERATOR = "⛄"
   let curLeng = cur.length || 1
   let x = Array.from(new Set(layers.map((layer) => {
      let splitted = [...layer.group!]
      if ((splitted?.length) > curLeng) {
         return splitted.splice(0,curLeng + 1).join(SEPERATOR)
      }
      return ""
   })))
   return x.filter((a) => a).map((a) => a.split(SEPERATOR))
}



function SavedMapLayerGroup(props:FastLagGroup<LayerInfo>) {
   const {dispatch: AppMessageDispatch} = React.useContext(AppMessagesContext)

   if (props.customFeatureLayers === undefined || props.customFeatureLayers.length === 0) {
      return (<></>)
   }
   // props.editFeatureAvailable
   // props.callbackToConvertCustomFeatureLayerToNormal
   
   function returnToNormal(evt, layerToSwitch) {
      evt.stopPropagation();
      AppMessageDispatch(actionSetInfoMessage(Localization.getText("Permanent layer converted to normal layer")))
      props.callbackToConvertCustomFeatureLayerToNormal(layerToSwitch)
   }

   function clickHandler(evt, layerToSwitch, boolean) {
      evt.stopPropagation();
      layerToSwitch.forEach(layer => {
         if (layer.visible != boolean) {
            props.onCustomFeatureLayerToggle(layer)
         }
      });
   }

   if (props.customFeatureLayers) {
      let customLayer = props.customFeatureLayers!


   let uniqueGroups = uniqueSubGroup(customLayer, []).filter((a) => a)

   return (
      <div className='GroupWrap'>
         {uniqueGroups.map(group => 
            <React.Fragment key={group.join()} >
            <SavedMapLayerNester group={group} layersInGroup={FasteLagInGroup(group,customLayer)} onCustomFeatureLayerToggle={props.onCustomFeatureLayerToggle} />
            </React.Fragment>
         )}

      </div>
   )
   }
   return(<></>)
}

function SavedMapLayerNester(props: {group:string[], layersInGroup:LayerInfo[], onCustomFeatureLayerToggle}) {
   const [collapsed, setCollapsed] = useState(true)

   let activeInGroup = props.layersInGroup.filter((a) => a.visible)
   function toggleGroup(e) {
      e.preventDefault()
      e.stopPropagation();
      if (activeInGroup.length) {
         activeInGroup.forEach((a) => {
            props.onCustomFeatureLayerToggle(a)
         })
      } else {
         props.layersInGroup.forEach((a) => {
            props.onCustomFeatureLayerToggle(a)
         })
      }
   }

   if (collapsed) {
      return <div className='GroupWrap'>
      <div onClick={() => setCollapsed((a) => (!a))} className='GroupHead'>
         <MitCheckBox onClick={toggleGroup} variant='dark'  checked={!!activeInGroup.length} indeterminate={!!(activeInGroup.length && activeInGroup.length < props.layersInGroup.length || false)} />
         {props.group.at(-1) ?? props.group}<div style={{marginLeft:"auto",marginRight:"5px"}}>
      <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(!collapsed?"90deg":"0deg")+")"}}><BsChevronRight /></span>
      </div></div></div>
   }

   let uniqueGroups = uniqueSubGroup(props.layersInGroup, props.group, props.group).filter((a) => a)
   

   return (<div className='GroupWrap'>
      <div onClick={() => setCollapsed((a) => (!a))} className='GroupHead'>
         <MitCheckBox onClick={toggleGroup} variant='dark'  checked={!!activeInGroup.length} indeterminate={!!(activeInGroup.length && activeInGroup.length < props.layersInGroup.length || false)} />
         {props.group.at(-1) ?? props.group}<div style={{marginLeft:"auto",marginRight:"5px"}}>
      <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(!collapsed?"90deg":"0deg")+")"}}><BsChevronRight /></span>
      </div></div>
      
      <div className='layerInGroup' >
      
      {uniqueGroups.map(group => 
            <React.Fragment key={group.join()} >
            <SavedMapLayerNester group={group} layersInGroup={FasteLagInGroup(group,props.layersInGroup)} onCustomFeatureLayerToggle={props.onCustomFeatureLayerToggle} />
            </React.Fragment>
      )}
      <SavedMapLayer layers={FasteLagAtLevel(props.group, props.layersInGroup)} onCustomFeatureLayerToggle={props.onCustomFeatureLayerToggle} />
      </div></div>
   )

   // return (
   //    <div className='GroupWrap'>
   //    <div onClick={props.collapseHandler} className='GroupHead'>{props.buttonCheck} {getFeatureLayerGroupTranslation(Localization.getlanguage()||"da",props.name)}<div style={{marginLeft:"auto",marginRight:"5px"}}>
   //    <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(props.collapse?"90deg":"0deg")+")"}}><BsChevronRight /></span>
   //    </div></div>
   //    <div className='layerInGroup' >
   //    {props.children}
   //    </div></div>
   // )
}

function SavedMapLayer(props: {layers: LayerInfo[], onCustomFeatureLayerToggle}) {
   function toggleChilde(idx) {
      props.onCustomFeatureLayerToggle(props.layers[idx])
   }

   return (
      props.layers.map((layer, idx) => {
         // onClick={(evt, val) => layer.onChange(key, val)}
         return (<div key={layer.datasetname} className='layer'> 
         <MitCheckBox onClick={(evt, e) => toggleChilde(idx)} variant='dark' checked={layer.visible} >{layer.datasetname}</MitCheckBox>
         </div>)
      })
   )
}

type BackgroundLayerGroupedP = {
   backgroundLayers: {[name:string]:string}
   selectedBackgroundLayerKey: string
   onBackgroundLayerChange: (a:string) => void
}

function BackgroundLayerGrouped(props:BackgroundLayerGroupedP) {
   let {hasAccessToFeature} = React.useContext(ApplicationStateContext)
   let [collapse, setCollapse] = useState(true);

   React.useEffect(() => {
      if (hasAccessToFeature(Feature.ThemeNewSec)) {
         setCollapse(false)
      }
   },[hasAccessToFeature])

   let keys: any = [];
   if (props.backgroundLayers) {
      keys = Object.keys(props.backgroundLayers);
   }

   


   let showLayerInGroup = keys.map((bgKey, idx) => {
      let checkedLayer = bgKey === props.selectedBackgroundLayerKey || (props.selectedBackgroundLayerKey === "v" && idx === 0);
      return (
         <div key={bgKey} onClick={(evt) => props.onBackgroundLayerChange(bgKey)} className='layer'> 
         {checkedLayer ? <BsCheckCircle  />:<BsCircle />} {Localization.getText(bgKey)}
      </div>
      )
   })

   function collapseHandler(ev: React.MouseEvent<HTMLDivElement, MouseEvent> ) {
      if (hasAccessToFeature(Feature.ThemeNewSec)) {
         setCollapse(false)
         return
      }
      setCollapse((a) => !a);
   }

   return (
      <div className='GroupWrap'>
      <div onClick={(ev) => {collapseHandler(ev)}} className='GroupHead'>
         <AiFillAppstore />
         {Localization.getText("Map")}<div style={{marginLeft:"auto",marginRight:"5px"}}>
         <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={
            <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(!collapse?"90deg":"0deg")+")"}}><BsChevronRight /></span>
         } >
            <></>
         </ProtectedFeature>
         </div></div>
      <div className='layerInGroup' >
         {!collapse ? showLayerInGroup : <></>}
      </div>
   </div>
   )
}


export function FeatureLayers(props:any) {
   let [nestedTreeStruct, setNeststedTreeStruct] = useState({})

   React.useEffect(() => {
      let k = {}
      const getP = (a) => props.layers[a].layer.priority || 999;
      Object.keys(props.layers).filter((a) => (props.layers[a]?.layer?.group !== "hidden")).sort((a,b) => getP(a)-getP(b)).forEach(key => {
         let group = props.layers[key]?.layer.group;
         let path = group ? group.split(".") : ["Andet"];
         extendNestedGroups(k, path, props.layers[key], key)
      });
      setNeststedTreeStruct({...k});
   }, [props.layers])

   return (
      <>
         <FeatureLayersNester onChange={props.onChange} settingClick={props.settingClick} selectedFeatureLayerKeys={props.selectedFeatureLayerKeys} curPos={nestedTreeStruct} maxLayersToActivate={props.maxLayersToActivate} />
      </>
   )


}

type FeatureLayersNesterType = {
   onChange: (a,b) => void,
   settingClick: (a,b) => void,
   selectedFeatureLayerKeys: string[],
   curPos: {[data:string]:{}},
   name?: string,
   maxLayersToActivate?: number
}
function FeatureLayersNester(props:FeatureLayersNesterType) {
   let [collapse, setCollapse] = useState(false)
   let curPos = props.curPos

   if (props.curPos === undefined || Object.keys(props.curPos).length < 1) {
      return (<></>);
   }

   function clickHandler(evt,a:string[], boolean) {
      console.log(a)
      evt.stopPropagation();
      evt.preventDefault();
      a.forEach(key => {
         props.onChange(key, boolean)
      });
   }

   function settingClick(a:string, boolean) {
      props.settingClick?.(a, boolean)
   }

   let maxCheckedAtATime = props.maxLayersToActivate ?? SettingsManager.getSystemSetting("LayerListMaxLayertoActivate", 12, true);

   let layerInGroup = LayerInGroup(curPos);
   let activeInGroup = layerInGroup.filter((a) => props.selectedFeatureLayerKeys.find((b) => a === b))
   
   let buttonCheck = <MitCheckBox variant='dark' disabled={activeInGroup.length === 0 && layerInGroup.length > maxCheckedAtATime} checked={!!activeInGroup.length} indeterminate={!!(activeInGroup.length && activeInGroup.length < layerInGroup.length || false)} 
      title={activeInGroup.length === 0 && layerInGroup.length > maxCheckedAtATime ? Localization.getText("Too many layers to turn everything on") : undefined}
      onClick={(evt, val) => clickHandler(evt, activeInGroup.length ? activeInGroup : layerInGroup , val)}
   />

   function collapseHandler() {
      setCollapse((a) => !a);
   }

   return (
      <Wrapper name={props.name} collapseHandler={collapseHandler} buttonCheck={buttonCheck} collapse={collapse}>
      {collapse || props.name == undefined ? Object.keys(curPos).map((key) => {
         if (key.startsWith("_g_")) {
            return (
                  <React.Fragment key={key}>
                  <FeatureLayersNester settingClick={settingClick} name={key.slice(3)} onChange={props.onChange} selectedFeatureLayerKeys={props.selectedFeatureLayerKeys} curPos={curPos[key]} maxLayersToActivate={maxCheckedAtATime} />
                  </React.Fragment>
            )
         } else {
            return (
               <div key={key} className='layer'> 
                  <MitCheckBox variant='dark' onClick={(evt, val) => props.onChange(key, val)} checked={activeInGroup.includes(key)} >{(props.curPos[key] as any)?.layer?.translationTable ? Localization.getTextSpecificTable(key, (props.curPos[key] as any).layer.translationTable) : key}</MitCheckBox>
                  <SettingsButton onClick={() => settingClick(key, true)} />
               </div>
            )
         }
      }) : <></> }
      </Wrapper>
   )
}


type WrapperType = {
   name?: string,
   collapse: boolean,
   collapseHandler: () => void,
   buttonCheck: JSX.Element,
   children: React.ReactNode
}
function Wrapper(props:WrapperType) {
   if (props.name) {
      return (
         <div className='GroupWrap'>
         <div onClick={props.collapseHandler} className='GroupHead'>{props.buttonCheck} {getFeatureLayerGroupTranslation(Localization.getlanguage()||"da",props.name)}<div style={{marginLeft:"auto",marginRight:"5px"}}>
         <span className='mit-transformer' style={{float:"right", transform:"Rotate("+(props.collapse?"90deg":"0deg")+")"}}><BsChevronRight /></span>
         </div></div>
         <div className='layerInGroup' >
         {props.children}
         </div></div>
      )
   } else {
      return (
         <>{props.children}</>
      )
   }
}



// HelperFunction
function extendNestedGroups( nested:{}, path: string[], obj:any, label:string) {
   let curPos = nested;
 
   let len = path.length;
   for (let i = 0; i < len; i++) {
     if (curPos["_g_"+ path[i]]) {
       curPos = curPos["_g_"+ path[i]];
       continue;
     } else {
       curPos["_g_"+ path[i]] = {};
       curPos = curPos["_g_"+ path[i]];
     }
   }
   curPos[label] = obj
 }

 function LayerInGroup(nested:{}):string[] {
   let list:string[] = [];
   Object.keys(nested).map((key) => {
      if (key.startsWith("_g_")) {
         list = list.concat(LayerInGroup(nested[key]))
      } else {
         list.push(key)
      }
   })
   return list
 }

 function reOpenLastLayers(layer:string[], Changer) {
   function GetSavedSelectedLayers():string[] {
     let raw = localStorage.getItem("MIT-SelectedLayers");
     if (raw) {
        return JSON.parse(raw) || []
     }
     return []
   }
   let savedSelectedLayers = GetSavedSelectedLayers()
   let savedExistingSelectedLayers = savedSelectedLayers?.filter?.((a) => {
      layer.includes(a)
   }) || []
   savedExistingSelectedLayers.map((key) => Changer(key, true))
 }

 function SaveSelectedLayers(layer:string[]) {
   return MitStorage.setValue("MIT-SelectedLayers", layer)
 }

 function SettingsButton(props:{onClick: () => any}) {
   
   return (
   <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={

      <BsGearFill
      className={"mit-layerlist-rightside ClickAble SettingsButton"}
      onClick={() => props.onClick()}
      data-toggle="tooltip"
      title={Localization.getText("Edit layer")}
      />
   } >
      <img 
      className='NewSecLayerSettingsCog'
      onClick={() => props.onClick()}
      src='/images/NewSecSVG/Settings.svg' alt='c' />
   </ProtectedFeature>
   )
 }