

import { MapitStateContext, actionSelectBackgroundLayer } from "src/states/MapitState";
import OrthoPhoto from "/images/OrthoView.png"
import React from "react";
import { LuLayers } from "react-icons/lu";

type props = ({
  current: string
  onSetBgLayer: (e: "Viamap" | "Orthophoto") => void
} | {}) & {
  anchor?: "normal" | "bottomLeft"
}

export function QuickOrthoPhoto(props: props) {
  const { state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  let anchor = props.anchor ?? "normal"

  function handleOnClick() {
    if ("current" in props) {
      const next = props.current === "Orthophoto" ? "Viamap" : "Orthophoto"; 
      props.onSetBgLayer(next)
    } else {
      const next = mapitState.selectedBackgroundLayerKey === "Orthophoto" ? "Viamap" : "Orthophoto"; 
      mapitStateDispatch(actionSelectBackgroundLayer(next))
      mapitState.map.setBackgroundLayer(next);
    }
  }

  return (
  <div onClick={handleOnClick} className={"QuickOrthoPhoto " + anchor}>
    <div className="text">
    <LuLayers className="ico" color="white" />
    {/* {mapitState.selectedBackgroundLayerKey === "Orthophoto" ? 
      Localization.getText("Viamap"):
      Localization.getText("Orthophoto")
    } */}
    </div>
    <img className="bg" src={OrthoPhoto} alt="ortho" />
  </div>
  )
}