import {Debuglog, ObliqueFunc, UtmCoords, LoadedImageSpec, ObliqueFootprint2, CameraCalibration, FootPrintCamaraData, ImageMetaData} from './ObliqueFunc';
import {Utils} from '@viamap/viamap2-common';
import { SettingsManager } from '@viamap/viamap2-common';

// import URLmodule from 'url';
// import sizeOf from 'image-size';
// import { ObliqueCache } from './ObliqueCache';
// import { curveCardinal } from 'd3-shape';

export type ObliqueImageResult= {
   image: HTMLImageElement;
   height: number;
   width: number;
   x: number;
   y: number;
   timeutc: string;
 };

export class ObliqueInterfaces {

  static async getObliqueImage(    
      utmCoords:UtmCoords, 
      direction:string, 
      zoomFactor:number, 
      shallImageBeCentered: boolean,
      year?:string): Promise<ObliqueImageResult> 
    {
         let parameters:{
            interfaceVersion: string,
            easting: number,
            northing: number,
            direction: string,
            zoomFactor: number,
            center: boolean,
            debug?: string,
            year?: string,
            sizeFactor?:number
            nocacheRead?: string
         } = {
            interfaceVersion:"1.0",
            easting: utmCoords.x,
            northing: utmCoords.y,
            direction: direction,
            zoomFactor: zoomFactor,
            center: shallImageBeCentered,
            year:year,
            // debug: "true",
            // nocacheRead: "true",
            // sizeFactor:2
         };
      
         return new Promise<any>((resolve, reject) => {
            let serviceHost = SettingsManager.getSystemSetting("obliqueServiceHost");
            // let servicePath = "/get-oblique-image";
            let servicePath = "/get-oblique-image-v4";
            let APIKey = SettingsManager.getSystemSetting("obliqueServiceAPIKey");
            let reqbody = JSON.stringify(parameters); 
            const options = {
                method: "POST",
                body: reqbody,
                headers: { 
                  "Content-Type": "application/json",
                  "X-API-KEY":APIKey
                }
            };
            fetch("https://"+serviceHost+servicePath, options).then((response) => {
              return response.json()
            }).then((json) => {
              if (json.error) {
                reject(json.error)
              }
              const img = new Image();
              img.src = "data:image/gif;base64,"+json.image
              img.onload = function() {
                resolve({...json, image: img} as ObliqueImageResult)
              }
            }).catch((e) => {
              reject(e)
            })
   
   });
  }

}