import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactDataGrid, { Row } from "react-data-grid";
import { AWSAPIGatewayInterface } from "src/managers/AWSAPIGatewayInterface";
import { FileTable, mapFile } from "./FileTable";
import { Localization, PersistenceObjectType, PersistenceScope, SessionContext, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { BsXLg } from "react-icons/bs";
import { FileImportContext } from "./FileImporter";
import { FileImportType } from "./FileImportHooks";


type fileAction = "Open"|"Override"|"Delete"


/// Used for support for old React Coponents
export const CloudLayerList = (prop: { show: boolean, close: ()=> void, prefix?:string}) => {
  const { state: sessionState, dispatch: sessionStateDispatch } = React.useContext(SessionContext);
  const { importCallBack } = useContext(FileImportContext)
  const [fileList, setFileList] = useState<any[]>([])

  function onClickClose() {
    prop.close();
  }



  const closeButton = (
    <BsXLg 
      id="mit-demography-report-close-button"
      onClick={() => onClickClose()}
      title={Localization.getText("Close")}
    />
  );

  async function readFile(file: string) {
    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    let x = await persit.headObjectS3(file)
    console.log(x)
    return null;
  }

  
  function fileAction(action:fileAction, key) {
    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    switch (action) {
      case "Open":
        importCallBack({
          fileImportType: FileImportType.FileFetch,
          url: sessionState.userRef+"/"+key,
        })
        return
      case "Override": {
        return
      }
      case "Delete": {
        persit.deleteObject(PersistenceScope.User, PersistenceObjectType.MapitMaps, sessionState.customerRef, sessionState.userRef, key)
        setFileList((a) => {
          return a.filter((b) => b.key !== key)
        })
        return
      }
    }
  }

  async function getFileList(setList: (a:any[]) => void) {
    let domainName = sessionState.customerRef;
    let userId = sessionState.userRef;
    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    let prefixPath = persit.createPrefix(PersistenceScope.User, PersistenceObjectType.MapitMaps, domainName, userId)
    let prefix = prop.prefix ? prop.prefix : prefixPath
    let listing = (await persit.getListingS3(prefix) as any[]).map((a) => a.Key).filter((e) => e !== prefix).map((a) => a.split("/").at(-1))
    
    let heads:any[] = []
    for (let i of listing) {
      let k = await persit.headObjectS3(prefix+"/"+i);
      heads.push({value:k, ref:i});
    }
    setList(heads)
  }

  useEffect(() => {
    getFileList(setFileList)
  },[sessionState.userRef])

  if (!prop.show) {
    return (null);
  }

  return (
    <Modal.Dialog className="mit-report-dialog" >
      <Modal.Header>
        <Modal.Title>
        {closeButton}
        Fil oversigt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { fileList.map((a) => {
          return (
            <div key={a.ref}>
              {a.value.Metadata.maptitle}
              <button onClick={(e) => fileAction("Open", a.ref)} >Open</button>
              <button onClick={(e) => fileAction("Override", a.ref)} >Override (TODO)</button>
              <button onClick={(e) => fileAction("Delete", a.ref)} >Delete</button>
            </div>
          )
        })
        }
      </Modal.Body>
    </Modal.Dialog>
  );
};



/// Optional, but might give help indicate what you get from AwsS3
function MapitDataParser(data: any) {
    let resData = {
      userName: data.userName,
      createDate: (data.timeGenerated && data.timeGenerated.toDateString()) || "Unknown",
      expiraDate: (data.endDate && data.endDate.toDateString()) || "Unknown",
      status: (data.endDate) ? ((new Date(data.endDate as string).getTime() - new Date().getTime()) > 0 ? "Active" : "Expired") : "Unkown",
      layers: data.layers? data.layers.length : 0,
      layerName:  (data.layers && data.layers[0] && "("+data.layers.length+")"+ data.layers[0].datasetname) || "(0)Empty",
      createDateSort: data.timeGenerated && new Date(data.timeGenerated).getTime() || 0,
      expiraDateSort: data.endDate && new Date(data.endDate).getTime() || 0,
    };
    return resData;
  }
  
  /// Optional, but usefull if you want to Sort after another value than key of Column
  function sortMapper(elem: mapFile, idx: number, sortColumn: string) {
    let returnValue = {id: idx, value: (elem.data && elem.data[sortColumn]) || undefined};
    switch (sortColumn) {
      case "layerName":
        returnValue.value = elem.data && elem.data.layers;
        break;
      case "createDate":
        returnValue.value = elem.data && elem.data.createDateSort;
        break;
      case "expiraDate":
        returnValue.value = elem.data && elem.data.expiraDateSort;
        break;
      default:
        break;
    }
    return returnValue;
  }
    
    // Change Name with Translationskey
  const mapitFileColumns: any[] = [
    {key: 'fileName', name: 'Filnavn', width: 300, sortable: false, resizable: false},
    {key: 'status', name: 'Status', width: 80, sortable: true, resizable: false},
    {key: 'userName', name: 'User', width: 180, sortable: true, resizable: true},
    {key: 'createDate', name: 'oprettet', width: 135, sortable: true, resizable: false},
    {key: 'expiraDate', name: 'udløbsdato', width: 135, sortable: true, resizable: false},
    {key: 'layerName', name: 'Lag navn', width: 180, sortable: true, resizable: true},
  ];