import * as React from 'react';
import { Image, Card, Button } from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import { CatchmentInterface } from '../managers/CatchmentInterface';
import Glyphicon from '@strongdm/glyphicon';
import { CalculationMode, CatchmentStateContext } from 'src/states/CatchmentState';
import { MapitStateContext } from 'src/states/MapitState';
import { BsArrowUpRightSquareFill } from 'react-icons/bs';
import { LuSigma } from 'react-icons/lu';
import { MitLoading } from './ComponentUtils';

export type CatchmentSelectionState = string | null;

type Props = {
  showWindow: boolean;
  isCollapsed: boolean;
  showHideButton: boolean;
  horizontal?: boolean;
  hideCatchments: () => void;
  showDownloadButton: boolean;
  showRushHourSelector: boolean;
  showCombinedBikeAndTransit:boolean;
  onChange:(modeOfTransport:string, rushHourMode:boolean) => void
  onDownloadCatchments?: ()=>void;
  onSaveCatchments?: ()=>void;
  onToggleShowItemCounts?: () => void;
};

export const CatchmentSelector = (props: Props) => {
  const {state:catchmentState} = React.useContext(CatchmentStateContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);

  function onClickApply() {
  }
  function onClickHideCatchment() {
    props.hideCatchments && props.hideCatchments();
  }

  function onClickDownloadCatchment() {
    props.onDownloadCatchments && props.onDownloadCatchments();
  }

  function onClickSigma() {
    props.onToggleShowItemCounts && props.onToggleShowItemCounts();
  }

  function onClickCreateLayerForCatchment() {
    props.onSaveCatchments && props.onSaveCatchments();
  }

  if (props.showWindow) {
    let renderToggleButtons = props.isCollapsed
      ? null
      : CatchmentInterface.transportModes.filter((key) => {
          // Only show 'transit of bike' icon if enabled.
          return props.showCombinedBikeAndTransit || key.type !== 'transitorbike';
        }).map((key, idx) => {
          let icon = key.file;
          let label = Localization.getText("ModeOfTransport:" + key.type);
          let imageClassSelected =
            catchmentState.selectedModeOfTransport === key.type ? " selected" : "";
          return (
            <Image
              className={"mit-catchment-selector-image " + imageClassSelected}
              src={new URL("/images/" + icon, import.meta.url).href}
              key={"img" + idx}
              data-toggle="tooltip"
              title={label}
              onClick={() => {
                props.onChange && props.onChange(key.type, catchmentState.rushHourMode);
              }
              }
            />
          );
        });
    let img = props.isCollapsed ? "car" : "car_grey";
    let imageUrl2 = new URL("/images/" + img + ".svg", import.meta.url).href
    let label2 = Localization.getText("Click to hide travel times");
    let hideButton = props.showHideButton ? (
      <div>
        <div className="mit-catchment-selector-glyph">
          <Glyphicon
            style={{
              fontSize: "20px",
              fontWeight: "lighter",
              margin: "auto",
              marginTop: "5px",
              color: "grey",
            }}
            glyph="remove"
            onClick={() => onClickHideCatchment()}
            title={label2}
          />
        </div>
        <hr />
      </div>
    ) : null;
    let saveAsNewLayersButton = props.onSaveCatchments ? (
      <div>
        <hr />
        <div className="mit-catchment-selector-glyph">
          <BsArrowUpRightSquareFill
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              margin: "auto",
              marginTop: "5px",
              color: "grey",
            }}
            onClick={() => onClickCreateLayerForCatchment()}
            title={Localization.getText("Click to save travel times as new layer")}
          />
        </div>
      </div>
    ) : null;
    let sigmaButton = props.onToggleShowItemCounts ? (
      <div>
        <hr />
        <div className={"mit-catchment-selector-glyph" + (catchmentState.showSpatialItemsCounts ? " selected" : "")}>
          <LuSigma
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              margin: "auto",
              color: "grey",
            }}
            onClick={() => onClickSigma()}
            title={Localization.getText("Click to toggle area item counts")}
          />
        </div>
      </div>
    ) : null;
    let downloadButton = props.showDownloadButton ? (
      <div>
        <hr />
        <div className="mit-catchment-selector-glyph">
          <Glyphicon
            style={{
              fontSize: "20px",
              fontWeight: "lighter",
              margin: "auto",
              marginTop: "5px",
              color: "grey",
            }}
            glyph="download"
            onClick={() => onClickDownloadCatchment()}
            title={Localization.getText("Click to download travel times")}
          />
        </div>
      </div>
    ) : null;
    let imageUrlRh = new URL("/images/rushHour2.png", import.meta.url).href
    let isActive = catchmentState.selectedModeOfTransport === "car";
    let imageClassSelectedRh =
      isActive && catchmentState.rushHourMode ? " selected" : "";
    let imageClassInactiveRh = isActive ? "" : " inactive";
    let rushHourModeCheckBox = props.showRushHourSelector ? (
      <div>
        <hr />
        <Image
          className={
            "mit-catchment-selector-image" +
            imageClassSelectedRh +
            imageClassInactiveRh
          }
          src={imageUrlRh}
          data-toggle="tooltip"
          title={
            isActive
              ? Localization.getText("Click to toggle Rush Hour mode")
              : ""
          }
          onClick={() => {
            if (isActive)  {
              props.onChange && props.onChange(catchmentState.selectedModeOfTransport!, !catchmentState.rushHourMode);
            }
          }}
        />
      </div>
    ) : null;
    return (
      <>
      <div
        className="mit-catchment-selector no-print"
        id="mit-catchment-selector"
      >
        <Card style={{zIndex: props.showHideButton ? 150 : "revert"}}>
          <Card.Body>
            {hideButton}
            {renderToggleButtons}
            {rushHourModeCheckBox}
            {sigmaButton}
            {saveAsNewLayersButton}
            {downloadButton}
            <Button
              style={{
                display: "none",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={() => onClickApply()}
            >
              Apply
            </Button>
          </Card.Body>
        </Card>
      </div>
      </>
    );
  } else {
    return null;
  }
}
