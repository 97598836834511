import { SettingsManager } from '@viamap/viamap2-common';
import * as M from 'maplibre-gl';

export type MitLatLng = {
  lat: number;
  lng: number;
};

export type MitLayerHandle = string;

export class MapFunc {

  private static currentMouseoverPopup = new M.Popup({
    closeButton: false,
    closeOnClick: false,
    className: "mit-mouseover-popup"
  });
  private static hasActiveOnClickPopup = false;
  private static map: M.Map;
  private static listenerCache: { [key: string]: any } = {};

  public static mapIsInitialized = false;
  public readonly ControlPosition = {
    TopRight: "top-right",
    TopLeft: "top-left",
    BottomRight: "bottom-right",
    BottomLeft: "bottom-left"
  };
  
  public static remMap() {
    if (MapFunc.map)
    MapFunc.map.remove()
    MapFunc.map = (null as any)
  }

  public static initMap(id: any, isOrthophotoGlobal?: boolean):Promise<M.Map> {
    if (MapFunc.map)
    MapFunc.map.remove()
    MapFunc.map = (null as any)

    return new Promise<M.Map>((resolve, reject) => {
      try {
        let token = SettingsManager.getSystemSetting("viamapAPIToken");
        let userinfo = "mapit";
        
        let style = "https://" + userinfo + ".tiles.viamap.net/v1/style.json?token=" + token;
        
        let map2 = new M.Map({
          center: [56.012,11.673],
          zoom: 8,
          container: id,
          style: style,
          hash: false,
          transformRequest: (url, resourceType) => {
            if (resourceType === 'Tile' && url.startsWith('http://13.48.91.12:8080/geoserver')) {
              return {
                url: url,
                headers: { 'Origin': '*' }
              };
            } else {
              return {
                url: url,
              };
            }
          }
        });
        MapFunc.map = map2;
        MapFunc.map.once('idle', () => {
          this.mapIsInitialized = true;
          // reject(new Error("test error"));
          resolve(map2);
        });
      } catch (err) {
        reject(err);
      }
  
    });
  }

  public static getMap(): M.Map {
    return MapFunc.map;
  }

  public static setHasActiveOnClickPopup(value: boolean): void {
    this.hasActiveOnClickPopup = value;
  }

  /**
   * @link https://maplibre.org/maplibre-gl-js-docs/example/popup-on-hover/
   */
  static markerMouseEnterFunction(e: any) {
    const map = this.getMap();
    map.getCanvas().style.cursor = 'pointer';

    var coordinates = e.features[0].geometry.coordinates.slice();
    var name = e.features[0].properties.name;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    !this.hasActiveOnClickPopup && this.currentMouseoverPopup.setLngLat(coordinates).setHTML(name).addTo(map);
  }

  static markerMouseLeaveFunction(e: any) {
    const map = this.getMap();
    map.getCanvas().style.cursor = '';
    this.currentMouseoverPopup.remove();
  }

  public static setupEventListeners(layerName: string, listener: (e: any) => void) {
    const map = this.getMap();
    // store anonomous function such that we can unset it later.
    let key = 'click' + layerName;
    MapFunc.listenerCache[key] = listener;
    map.on('click', layerName, listener);
    map.on('mouseenter', layerName, (e) => { this.markerMouseEnterFunction(e); });
    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layerName, (e) => { this.markerMouseLeaveFunction(e); });
  }

  public static removeEventListeners(layerName: string) {
    const map = this.getMap();
    // find listener from cache
    let key = 'click' + layerName;
    let listener = MapFunc.listenerCache[key];
    key && delete MapFunc.listenerCache[key];
    listener && map.off('click', layerName, listener);
    map.off('mouseenter', layerName, (e) => { this.markerMouseEnterFunction(e); });
    // Change it back to a pointer when it leaves.
    map.off('mouseleave', layerName, (e) => { this.markerMouseLeaveFunction(e); });
  }

  public static getJsonfromPoint(lat: number, lng:number, symbol:string): any {
    return ({
      type:"geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lng,lat]
        },
        properties: {
          "symbol": symbol
        }
      }
    })
  }

  public static getJsonfromListPoints(poi : any, symbol:string, type:string): any {
    if (poi.poiArr === undefined || poi.poiArr.length === 0) {
      return ({
        "type": "FeatureCollection",
        "features": []
      })
    }
    let x = poi.poiArr.map((e:any, i:number) => {return (
        {
          "type": "Feature",
          "id":e.poi_rep_id,
          "properties": {
            "name": e.name,
            "symbol": symbol,
            "type": type,
            "index": i,
          },
          "geometry": {
            "type": "Point",
            "coordinates": [
              e.poilatlng[1],
              e.poilatlng[0]
            ]
          }
        }
      )
    });

    return ({
      "type": "FeatureCollection",
      "features": x
    })
  }

  public static getJsonfromListLine(list : any): any {
    return ({
      type:"geojson",
      data: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: list.map((e:any) => [e[1],e[0]])
        },
        properties: {
          "symbol": "train1"
        }
      }
    })
  }
  
}