import React, { useEffect } from "react"
import { MaplibreMapFunc } from "src/compentsModus/MaplibreMapFunc"
import { MitButton, MitCheckBox, MitLoading } from "./ComponentUtils"
import MapLibreGL, { ImageSource, Map } from "maplibre-gl"
import { MapInteractionState, MapitStateContext, actionSetMapInteractionState } from "src/states/MapitState"
import "../css/plotSCreen.css"
import { MapFacadeMaplibre, MitLayerHandleMaplibre } from "src/managers/MapFacadeMaplibre"
import { FeatureLayer } from "src/managers/WmsLayerFunc"
import * as Turf from "@turf/turf";
import { MitLatLng, MitLayerHandle } from "src/managers/MapFacade"
import jsPDF from "jspdf"
import { usePanWindow } from "src/common/components/CustomHooks"
import { Localization, SettingsManager, SimpleTranslationTable } from "@viamap/viamap2-common"
import { ProgressBar, Spinner } from "react-bootstrap"
import { MdBlock, MdCenterFocusStrong, MdEdit } from "react-icons/md"
import { FiMove } from "react-icons/fi"
import { TbRotate } from "react-icons/tb"
import { VectorLayer } from "src/managers/VectorLayerFunc"
import { PiCaretRightLight } from "react-icons/pi"
import { FaEraser, FaEye, FaEyeSlash } from "react-icons/fa"
import { IgnoreBlock, LegendEditorPlot } from "./LegendEditorPlot"
import { MapitUtils } from "src/managers/MapitUtils"
import { DataDivisionType, LayerInfo, LayerType } from "src/common/managers/Types"
import { GenerateGeom } from "src/managers/GenerateGeom"
import { ProtectedFeature } from "./ProtectedFeature"
import { Feature } from "src/states/ApplicationStateFeatures"

const moduses = ["Standard Plot", "Seperate Layer Plot", "Matrikel Info Plot"] as const;

type PlotScreenState = {
  modus: typeof moduses[number];
  format: string;
  fold: boolean;
  foldFormat: string;
  center: number[];
  bearing: number;
  scale: number;
  margin: number;
  title: string;
  description: string;
  showLegend: boolean;
  legendIgnoreBlocks: IgnoreBlock[]
  logoImg: Promise<"" | HTMLImageElement>;
  arrowImg: Promise<"" | HTMLImageElement>;
  imgCache: {[key:string]: Promise<"" | HTMLImageElement>};
  dataLayer: LayerInfo[]
  legendImg: {
    img: Promise<"" | HTMLImageElement>;
    ftl: FeatureLayer;
    label?: string;
  }[];
  attrib: string[];
}

type Props = {
  
}


const mm = 8 //pixels

//Global Feature to hold Square feature
let GB_feature: null|any = null


// MARK: Component Plotscreen
export const PlotScreen = (props:Props) => {
  
  let [moreSettings, setMoreSettings] = React.useState(false);
  let [editLegend, setEditLegend] = React.useState(false);
  
  let [loading, setLoading] = React.useState(true);
  let [loadingBar, setLoadingBar] = React.useState({taskDone:0, TaskToDo:0})
  let [showCustomScale, setShowCustomScale] = React.useState(false);
  let [tempScaleValue, setTempScaleValue] = React.useState("0");
  
  let [activeTool, setActiveTool] = React.useState("none")
  let activeToolRef = React.useRef("none")
  
  useEffect(() => {
    activeToolRef.current = activeTool
  },[activeTool])
  
  
  let [eventer, setRef, clickEventer, setOpen] = usePanWindow(
    0,
    {startTop: document.body.offsetHeight / 50 +"px"}
  );
  
  let {state:mapState,dispatch:mapDispatch} = React.useContext(MapitStateContext);
  
  let uniqueMapID = React.useId()
  let uniqueCanvasID = React.useId()
  let uniqueLegendID = React.useId();
  let mapRef = React.useRef<MapFacadeMaplibre | null>(null)
  let downLoadRef = React.useRef<HTMLAnchorElement | null>(null)
  
  let [lState, setLState] = React.useState<PlotScreenState>({
    modus: "Standard Plot",
    
    //force Reload
    format: "A4P",
    
    //force Map update
    center: mapState.map.getCenter().get2dArr().toReversed(),
    bearing: mapState.map.getBearing(),
    scale: 10_000,
    
    //force Canvas update
    fold: false,
    foldFormat: "A4",
    margin: 6, //in mm, - 1 mm = 8pixels
    title: "Default Map Title",
    description: "Default Description",
    showLegend: true,
    legendIgnoreBlocks:[],
    dataLayer: Object.values(mapState.layers),
    logoImg: PromiseImage(new URL("/Mapit/mapitLogo.svg", import.meta.url)), //Cache img
    arrowImg: PromiseImage(new URL("/Mapit/north.svg", import.meta.url)), //Cache img
    
    // LegendImg cache
    legendImg: mapState.selectedFeatureLayerKeys.toReversed().flatMap((a) => {
      let ftl =  mapState.featureLayers[a]
      if ((ftl.layer as any)?.type === "vectorTile") {
        if ((ftl.layer as any)?.legend) {
          return {ftl: ftl, img: PromiseImage((ftl.layer as any)?.legend)}
        }
        return []
      }
      if (ftl.layer.layers.split(",").length > 1) {
        let url = ftl.getLegendURL();
        return ftl.layer.layers.split(",").map((a) => url.replace(ftl.layer.layers, a)).filter((a,b) => !ftl.layer.legendExclude?.includes(b)).map((imgUrl, idx) => ({
          ftl: ftl, img: PromiseImage(imgUrl),
          label: ftl.layer.legendLabels?.["da"]?.[idx]
        }))
      }
      return {ftl: ftl, img: PromiseImage(ftl.getLegendURL()),
        label: ftl.layer.legendLabels?.["da"]?.[0]
      }
    }),
    
    imgCache: {},
    
    // Attributions:
    attrib: [...new Set([...["viamap ApS", mapState.selectedBackgroundLayerKey !== "BlankMap" ? "OpenStreetMap": ""].filter((a) => a) ,...mapState.selectedFeatureLayerKeys.toReversed().map((a) => {
      let ftl =  mapState.featureLayers[a]
      return ftl.layer?.["attrib"] || ""
    })])],
    
  })
  
  useEffect(() => {
    mapDispatch(actionSetMapInteractionState(MapInteractionState.Override))
    return () => mapDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
  },[])
  
  useEffect(() => {
    GenerateLegend(
      document.getElementById(uniqueLegendID) as HTMLCanvasElement,
      lState
    )
    function DrawCanvas() {
      const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
      const legend = document.getElementById(uniqueLegendID) as HTMLCanvasElement;
      const ctx = canvas && canvas.getContext("2d");
      const img = (!loading && mapRef.current!.getCanvas()) || new Image();
      ctx && drawDrawing(
        ctx,
        canvas,
        legend,
        img, 
        lState
      )
    }
    DrawCanvas()
  },[lState, loading])
  
  // Update CanvasMap
  useEffect(() => {
    let rMap = mapState.map.getMapPop();
    (rMap.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(PlotLayerCornerArr(lState.center, lState.bearing, lState.scale, lState.format).filter((a, idx) => idx < 4) as any);
    GB_feature = Turf.polygon([PlotLayerCornerArr(lState.center, lState.bearing, lState.scale, lState.format)]);
    (rMap.getSource("Square")?.setData(GB_feature));
    if (mapRef.current) {
      const Corners = PlotLayerCornerArr(lState.center,0, lState.scale, lState.format) // Zero Rotation, Happens in two steps
      
      mapRef.current.getMapPop().fitBounds(
        Corners.filter((a, idx) => idx % 2 == 0) as any,
      )
      mapRef.current.once("moveend",() => { //Make Sure FitBounds happens
        mapRef.current?.getMapPop().setBearing(lState.bearing) // Seconds step
        mapRef.current?.once("idle",async () => {
          setLoading(false)
        })
      })
    }
    return () => {setLoading(true)}
  }, [lState.bearing,lState.center,lState.scale])
  
  useEffect(() => {
    const CornersUpRight = PlotLayerCornerArr(lState.center,0,lState.scale, lState.format)
    const Corners = PlotLayerCornerArr(lState.center,lState.bearing,lState.scale, lState.format)
    let x = PaperToSize(lState.format)
    let max = maxWebGLSize()
    let options = {
      preserveDrawingBuffer: true,
      interactive: false,
      hash:false,
      maxCanvasSize: [max, max] as [number, number],
      bounds: CornersUpRight.filter((a, idx) => idx % 2 == 0) as any,
      bearing: lState.bearing,
      pixelRatio: 2,
    }
    let MapFac = new MapFacadeMaplibre(uniqueMapID, false, options)
    let rMap = mapState.map.getMapPop()
    MapFac.getMapPop().setBearing(lState.bearing)
    rMap.addSource("CanvasPlotSource", {...CanvasSource(lState.center, lState.bearing,lState.scale,lState.format, uniqueCanvasID) as any})
    let squareSource = PlotSource(lState.center, lState.bearing,lState.scale,lState.format)
    GB_feature = (squareSource as any).data
    rMap.addSource("Square", squareSource)
    rMap.addLayer(CanvasStyle())
    // rMap.addLayer(PlotStyleLine("SquareL","Square"))
    rMap.addLayer(PlotStyleSpec("SquareF","Square"))
    const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    
    const moveEnter = (e) => MouseEnter(e, "SquareF" ,activeToolRef, {move: (a,b) => setLState((c) => ({...c, center: [c.center[0]+a,c.center[1]+b]})),rotate: (ang) => setLState((c) => ({...c, bearing: c.bearing + ang})),setLoading: () => setLoading(true)})
    rMap.on("mouseenter", "SquareF", moveEnter)
    mapRef.current = MapFac
    mapRef.current.getMapPop().setPixelRatio(2)
    mapRef.current.once('load', () => {
      mapRef.current?.setBackgroundLayer(mapState.selectedBackgroundLayerKey)
      mapState.selectedFeatureLayerKeys.forEach((a) => {
        if (mapState.featureLayers[a] instanceof VectorLayer) {
          mapRef.current?.addVectorLayer(mapState.featureLayers[a] as VectorLayer);
          return 
        }
        let layer = (mapState.featureLayers[a] as FeatureLayer);
        layer && mapRef.current?.addFeatureLayer(a, layer)
      })
      Object.keys(mapState.layers).forEach((a) => {
        let layer = mapState.layers[a].visible && mapState.layers[a].handle
        layer && mapRef.current?.addLayer(layer)
      })
      
    })
    mapRef.current.once("idle",async () => {
      setLoading(false)
    })
    
    return () => {
      setLoading(true)
      const rMap = mapState.map.getMapPop() as maplibregl.Map
      rMap.getLayer("CanvasPlot") && rMap.removeLayer("CanvasPlot")
      rMap.getSource("CanvasPlotSource") && rMap.removeSource("CanvasPlotSource")
      rMap.getLayer("SquareL") && rMap.removeLayer("SquareL")
      rMap.getLayer("SquareF") && rMap.removeLayer("SquareF")
      rMap.getSource("Square") && rMap.removeSource("Square")
      rMap.off("mouseenter", "SquareF", moveEnter);
      mapRef?.current?.getMapPop()?.remove?.()
    }
  }, [lState.format])
  
  function SavePDF() {
    let paper = (""+lState.format).toLowerCase()
    
    const doc = new jsPDF({
      format: paper.slice(0, - 1),
      orientation: paper.at(-1) as any
    });
    
    let paperS = PaperToSize(lState.format)
    doc.addImage(document.getElementById(uniqueCanvasID) as HTMLCanvasElement, 0 ,0, paperS.width, paperS.height)
    doc.save(SaveWithTimeStampt("mapit-Plot")+".pdf");
  }
  
  function setText(key, value:string) {
    let matches = value.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm)
    console.log(matches)
    let x = {}
    matches?.forEach((a) => {
      if (!lState.imgCache[a])
        x = {...x,[a]:PromiseImage(a)}
    })
    setLState((a) => ({...a, imgCache: {...a.imgCache,...x}}))
    
    setLState((a) => ({...a, [key]: value}))
  }
  
  
  async function printPDF() {
    let paper = (""+lState.format).toLowerCase()
    
    const doc = new jsPDF({
      format: paper.slice(0, - 1),
      orientation: paper.at(-1) as any
    });
    
    let paperS = PaperToSize(lState.format)
    doc.addImage(document.getElementById(uniqueCanvasID) as HTMLCanvasElement, 0 ,0, paperS.width, paperS.height)
    doc.autoPrint()
    window.open(doc.output('bloburl'), '_blank');
  }
  
  function waitIdle() {
    return new Promise<boolean>((resolve) => {
      if (mapRef.current) {
        mapRef.current.once("idle", () => resolve(true))
      } else {
        resolve(false)
      }
    });
  }
  
  async function SuperPDF() {
    if (mapState.selectedFeatureLayerKeys.length === 0) {
      SavePDF() 
      return
    }
    setLoadingBar({taskDone:0, TaskToDo: mapState.selectedFeatureLayerKeys.length})
    let paper = (""+lState.format).toLowerCase()
    
    const doc = new jsPDF({
      format: paper.slice(0, - 1),
      orientation: paper.at(-1) as any
    });
    
    let paperS = PaperToSize(lState.format)
    mapState.selectedFeatureLayerKeys.forEach((a) => {
      if (mapState.featureLayers[a] instanceof VectorLayer) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[a] as VectorLayer, "none");
        return 
      }
      let layer = (mapState.featureLayers[a] as FeatureLayer);
      layer && mapRef.current?.changeFeatureVisibility(a, "none")
      
    })
    let keys = [...mapState.selectedFeatureLayerKeys]
    console.log("2")
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      console.log(key)
      if (mapState.featureLayers[key] instanceof VectorLayer) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[key] as VectorLayer, "visible"); 
      } else {
        let layer = (mapState.featureLayers[key] as FeatureLayer);
        layer && mapRef.current?.changeFeatureVisibility(key, "visible")
      }
      const translatedLabel = () => {
        if (mapState.featureLayers[key].layer.translationTable) {
          return Localization.getTextSpecificTable(mapState.featureLayers[key].layer.label, mapState.featureLayers[key].layer.translationTable as SimpleTranslationTable)
        }
        return mapState.featureLayers[key].layer.label
      }


      await waitIdle()
      await CompletedDrawing({
        description: lState.description + "\n"+Localization.getText("Layer")+": "+ translatedLabel(),
        attrib: [...new Set([...["viamap ApS", mapState.selectedBackgroundLayerKey !== "BlankMap" ? "OpenStreetMap": ""].filter((a) => a) ,...[key].map((a) => {
          let ftl =  mapState.featureLayers[a]
          return ftl.layer?.["attrib"] || ""
        })])],
        legendImg: [key].toReversed().flatMap((a) => {
          let ftl =  mapState.featureLayers[a]
          if ((ftl.layer as any)?.type === "vectorTile") {
            if ((ftl.layer as any)?.legend) {
              return {ftl: ftl, img: PromiseImage((ftl.layer as any)?.legend)}
            }
            return []
          }
          if (ftl.layer.layers.split(",").length > 1) {
            let url = ftl.getLegendURL();
            return ftl.layer.layers.split(",").map((a) => url.replace(ftl.layer.layers, a)).filter((a,b) => !ftl.layer.legendExclude?.includes(b)).map((imgUrl, idx) => ({
              ftl: ftl, img: PromiseImage(imgUrl),
              label: ftl.layer.legendLabels?.["da"]?.[idx]
            }))
          }
          return {ftl: ftl, img: PromiseImage(ftl.getLegendURL()),
            label: ftl.layer.legendLabels?.["da"]?.[0]
          }
        }),
      })
      i !== 0 && doc.addPage(
        (paper.slice(0, - 1).toString() as any),
        paper.at(-1) as any
      )
      doc.addImage(document.getElementById(uniqueCanvasID) as HTMLCanvasElement, 0 ,0, paperS.width, paperS.height)
      setLoadingBar({taskDone:i + 1, TaskToDo: mapState.selectedFeatureLayerKeys.length})
      if (mapState.featureLayers[key] instanceof VectorLayer) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[key] as VectorLayer,"none");
        
      } else {
        let layer = (mapState.featureLayers[key] as FeatureLayer);
        layer && mapRef.current?.changeFeatureVisibility(key, "none")
      }
    }
    // doc.autoPrint()
    doc.save(SaveWithTimeStampt("mapit-Plot")+".pdf");
    mapRef.current?.setBackgroundLayer(mapState.selectedBackgroundLayerKey)
    mapState.selectedFeatureLayerKeys.forEach((a) => {
      if (mapState.featureLayers[a] instanceof VectorLayer) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[a] as VectorLayer, "visible");
        return 
      }
      let layer = (mapState.featureLayers[a] as FeatureLayer);
      layer && mapRef.current?.changeFeatureVisibility(a, "visible")
    })
    await waitIdle()
    await CompletedDrawing({})
    setLoadingBar({taskDone:0, TaskToDo: 0})
  }
  
  function SaveImg() {
    (downLoadRef.current as HTMLAnchorElement).download = SaveWithTimeStampt("mapit-Plot")+".png";
    (downLoadRef.current as HTMLAnchorElement).href = (document.getElementById(uniqueCanvasID) as HTMLCanvasElement).toDataURL();
    (downLoadRef.current as HTMLAnchorElement).click()
  }
  
  function UpdateLocation() {
    mapState.map
    setLState((a) => ({
      ...a,
      center: mapState.map.getCenter().get2dArr().toReversed(),
      bearing: mapState.map.getBearing()
    }))
  }

  
async function CompletedDrawing(overWritelState) {
  await GenerateLegend(
    document.getElementById(uniqueLegendID) as HTMLCanvasElement,
    {...lState, ...overWritelState}
  )
  async function DrawCanvas() {
    const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
    const legend = document.getElementById(uniqueLegendID) as HTMLCanvasElement;
    const ctx = canvas && canvas.getContext("2d");
    const img = (!loading && mapRef.current!.getCanvas()) || new Image();
    if (ctx) {
    await drawDrawing(
      ctx,
      canvas,
      legend,
      img, 
      {...lState, ...overWritelState}
    )
    }
  } 
  await DrawCanvas()
}
  
  
  
  // MARK: Plotscreen return
  return (
    <div ref={setRef} className="PlotScreen" style={{position:"absolute", zIndex: 500, left:"32px", minWidth:"300px"}}>
    {editLegend && lState.modus === "Standard Plot" ?
    <LegendEditorPlot canvasId={uniqueLegendID} callBack={(next) => setLState((pre) => ({...pre, legendIgnoreBlocks: next}))} />
    : <></>}
    <div className="DragBar" onMouseDown={eventer} />
    <div><h2>{Localization.getText("Plot")}</h2></div>
    <div className="Setting_Group two_Split modernBox" >
    {/* <div className="Setting_Label">{Localization.getText("Plot modus")}</div> */}
    {/* <div className="Setting_Input"> */}
    {/* <select value={lState.modus} onChange={((e) => setLState((a) => ({...a, modus: (e.target.value as PlotScreenState["modus"])})))} > */}
    {/* <option value={"Standard Plot"}>{"Standard Plot"}</option> */}
    {/* <option value={"Seperate Layer Plot"}>{"Seperate Layer Plot"}</option> */}
    {/* <option value={"Matrikel Info Plot"}>{"Matrikel Info Plot"}</option> */}
    {/* </select> */}
    {/* </div> */}
    
    <div className="Setting_Label">{Localization.getText("Paper")}</div>
    <div className="Setting_Input">
    <select value={lState.format} onChange={((e) => setLState((a) => ({...a, format: e.target.value})))}>
    <option value={"A0L"}>{Localization.getFormattedText("{size} Landscape",{size:"A0"})}</option>
    <option value={"A0P"}>{Localization.getFormattedText("{size} Potrait",{size:"A0"})}</option>
    <option value={"A1L"}>{Localization.getFormattedText("{size} Landscape",{size:"A1"})}</option>
    <option value={"A1P"}>{Localization.getFormattedText("{size} Potrait",{size:"A1"})}</option>
    <option value={"A2L"}>{Localization.getFormattedText("{size} Landscape",{size:"A2"})}</option>
    <option value={"A2P"}>{Localization.getFormattedText("{size} Potrait",{size:"A2"})}</option>
    <option value={"A3L"}>{Localization.getFormattedText("{size} Landscape",{size:"A3"})}</option>
    <option value={"A3P"}>{Localization.getFormattedText("{size} Potrait",{size:"A3"})}</option>
    <option value={"A4L"}>{Localization.getFormattedText("{size} Landscape",{size:"A4"})}</option>
    <option value={"A4P"}>{Localization.getFormattedText("{size} Potrait",{size:"A4"})}</option>
    <option value={"A5L"}>{Localization.getFormattedText("{size} Landscape",{size:"A5"})}</option>
    <option value={"A5P"}>{Localization.getFormattedText("{size} Potrait",{size:"A5"})}</option>
    <option value={"A6L"}>{Localization.getFormattedText("{size} Landscape",{size:"A6"})}</option>
    </select>
    </div>
    <div className="Setting_Label">{Localization.getText("Scale")}</div>
    <div className="Setting_Input">
    
    <button className={"Setting_toogle"} onClick={() => {
      setShowCustomScale((a) => {
        return !a
      })
      if (!showCustomScale) {
        setTempScaleValue(lState.scale.toLocaleString())
      }
    }}><MdEdit /></button>
    {!showCustomScale ? 
      <select value={lState.scale} onChange={((e) => setLState((a) => ({...a, scale: parseInt(e.target.value)})))}>
      <option value={500}>1:{(500).toLocaleString()}</option>
      <option value={1000}>1:{(1000).toLocaleString()}</option>
      <option value={2000}>1:{(2000).toLocaleString()}</option>
      <option value={2500}>1:{(2500).toLocaleString()}</option>
      <option value={5000}>1:{(5000).toLocaleString()}</option>
      <option value={7500}>1:{(7500).toLocaleString()}</option>
      <option value={10000}>1:{(10000).toLocaleString()}</option>
      <option value={12000}>1:{(12000).toLocaleString()}</option>
      <option value={12500}>1:{(12500).toLocaleString()}</option>
      <option value={15000}>1:{(15000).toLocaleString()}</option>
      <option value={20000}>1:{(20000).toLocaleString()}</option>
      <option value={25000}>1:{(25000).toLocaleString()}</option>
      <option value={50000}>1:{(50000).toLocaleString()}</option>
      </select>
      :
      <>
      <input value={tempScaleValue} onBlur={(e) => {setLState((a) => ({...a, scale: parseInt(tempScaleValue.replace(".",""))}))}} onChange={(e) => setTempScaleValue(e.target.value)} ></input>
      </>
    }
    </div>
    <div className="Setting_Label">
    {Localization.getText("Title")}
    </div>
    <div className="Setting_Input">
    <textarea onChange={(e) => setText("title", e.target.value)} value={lState.title}></textarea>
    </div>
    <div className="Setting_Label">
    {Localization.getText("Text")}
    </div>
    <div className="Setting_Input">
    <textarea onChange={(e) => setText("description", e.target.value)} value={lState.description}></textarea>
    </div>
    <div className="Setting_Label">
    {Localization.getText("Legend")}
    </div>
    <div className="Horizontal_Radio_Button_group">
    <button data-info={Localization.getText("Show")} className={lState.showLegend ? "active":""} onClick={() => setLState((a) => ({...a, showLegend:true}))} ><FaEye /></button>
    <button data-info={Localization.getText("Hide")} className={!lState.showLegend ? "active":""} onClick={() => setLState((a) => ({...a, showLegend:false}))} ><FaEyeSlash /></button>
    
    <button data-info={Localization.getText("Edit")} style={{marginInline:"auto 10px"}} onClick={() => setEditLegend((a) => !a)} ><FaEraser /></button>
    </div>
    
    <div style={{gridColumn:"1 / 3", fontWeight:"bold", textAlign:"left", marginInline:"20px", borderBottom:"2px dashed #7c77", marginBlock:"0.3em"}} ></div>
    
    <div className="Setting_Label">
    {Localization.getText("Action")}
    </div>
    <div className="Horizontal_Radio_Button_group">
    <button data-info={Localization.getText("Inactive")} className={activeTool == "none" ? "active":""} onClick={() => setActiveTool("none")} ><MdBlock /></button>
    <button data-info={Localization.getText("Move")} className={activeTool == "move" ? "active":""} onClick={() => setActiveTool("move")} ><FiMove /></button>
    <button data-info={Localization.getText("Rotate")} className={activeTool == "rota" ? "active":""} onClick={() => setActiveTool("rota")} ><TbRotate /></button>
    
    <button data-info={Localization.getText("Capture")} style={{marginInline:"auto 10px"}} onClick={() => UpdateLocation()} ><MdCenterFocusStrong /></button>
    </div>
    <div style={{gridColumn:"1 / 3", fontWeight:"bold", textAlign:"left", marginInline:"20px", borderBottom:"2px dashed #7c77", marginBlock:"0.3em"}} ></div>
    <div 
    onClick={() => setMoreSettings((a) => !a)} 
    style={{gridColumn:"1 / 3", fontWeight:"bold", textAlign:"left", marginLeft:"48px", cursor:"pointer"}}
    className={(moreSettings ? " open" : "")}
    >
    
    {Localization.getText("More Settings")}
    <div className="FoldCaret">
    <PiCaretRightLight />
    </div>
    </div>
    { moreSettings ?
      <> <div className="Setting_Label">{Localization.getText("Margin")}</div>
      <div className="Setting_Input">
      <span></span>
      <input value={lState.margin} min={0} max={50} type="number" onChange={((e) => setLState((a) => ({...a, margin: parseInt(e.target.value)})))} ></input>
      <span>mm</span>
      </div>
      
      
      
      <div className="Setting_Label">
      {Localization.getText("Fold Markers")}
      </div>
      <div className="Horizontal_Radio_Button_group">
      <button data-info={Localization.getText("Show")} className={lState.fold ? "active":""} onClick={() => setLState((a) => ({...a, fold:true}))} ><FaEye /></button>
      <button data-info={Localization.getText("Hide")} className={!lState.fold ? "active":""} onClick={() => setLState((a) => ({...a, fold:false}))} ><FaEyeSlash /></button>
      </div>
      
      <div className="Setting_Label">{Localization.getText("Fold to")}</div>
      <div className="Setting_Input">
      <select value={lState.foldFormat} onChange={((e) => setLState((a) => ({...a, foldFormat: e.target.value})))}>
      <option value={"A0"}>A0</option>
      <option value={"A1"}>A1</option>
      <option value={"A2"}>A2</option>
      <option value={"A3"}>A3</option>
      <option value={"A4"}>A4</option>
      <option value={"A5"}>A5</option>
      </select>
      </div>
      
      </>
      : <></> }
      
      </div>
      <div className="modernBtnGroup" >
      {loadingBar.TaskToDo ? <ProgressBar now={loadingBar.taskDone/loadingBar.TaskToDo*100} label={`${loadingBar.taskDone} / ${loadingBar.TaskToDo}`} style={{flex:1,borderRadius:"5px", ...{"--bs-progress-bar-bg":"#3ae580", "--bs-progress-height":"2rem"}}} /> :
       loading ? <Spinner size="sm" />:
      <>
      <ProtectedFeature feature={Feature.MapPlotSuper} contentsIfNoAccess={<></>}>
      <button title={Localization.getText("Seperate layer plot")} className="modernBtn" onClick={() => {SuperPDF()}} >S-PDF</button>
      </ProtectedFeature>
      <button className="modernBtn" onClick={() => {SaveImg()}} >PNG</button>
      <button className="modernBtn" onClick={() => {SavePDF()}} >PDF</button>
      <button className="modernBtn" onClick={() => {printPDF()}} >PRINT</button>
      </>
    }
    </div>
    
    
    
    <a ref={downLoadRef} style={{display:"none"}} href="" download >Click</a>
    <div className="CanvasCon" style={{width:"420px", height:"594px", position:"absolute", backgroundColor:"white", zIndex:-1, pointerEvents:"none", opacity:0}} >
    <canvas id={uniqueCanvasID} width={PaperToSize(lState.format).width*8} height={PaperToSize(lState.format).height*8} style={{width:"100%"}} />
    </div>
    <div className="CanvasCon" style={{position:"absolute", backgroundColor:"white", zIndex:-1, pointerEvents:"none", opacity:0}} >
    <canvas id={uniqueLegendID} width={PaperToSize(lState.format).width*8} height={PaperToSize(lState.format).height*8} style={{width:"100%"}} />
    </div>
    <div className="PlotScreen" id={uniqueMapID} style={{width:PaperToSize(lState.format).width*4+"px", height:PaperToSize(lState.format).height*4+"px", position:"absolute", backgroundColor:"white", zIndex:-1, pointerEvents:"none", opacity:0}} />
    </div>
  );
}

// MARK: Paper Projections
function PlotLayerCornerArr(center, rot, scale, paperFormat) {
  const RadToDeg = 57.2957795
  const sideLength = PaperToSize(paperFormat)
  
  let angle = RadToDeg * Math.atan(sideLength.width / sideLength.height)
  let hypo = Math.sqrt(sideLength.width**2 + sideLength.height**2)
  
  let rotation = rot;
  function calcProjectedSideLength(c, scale, rot) {
    return [
      Turf.destination(c, (hypo/2)*scale/1_000_000 , rot - angle).geometry.coordinates[0] - c[0],
      Turf.destination(c, (hypo/2)*scale/1_000_000 , rot - angle).geometry.coordinates[1] - c[1],
    ]
  }
  
  function transpose(c, sl) {
    return [
      c[0] + sl[0], c[1] + sl[1]
    ]
  }
  
  return [
    transpose(center,calcProjectedSideLength(center, scale, rotation) as [number,number]),
    transpose(center,calcProjectedSideLength(center, scale, rotation + (2 * angle)) as [number,number]),
    transpose(center,calcProjectedSideLength(center, scale, rotation + 180) as [number,number]),
    transpose(center,calcProjectedSideLength(center, scale, rotation + 180 + (2 * angle)) as [number,number]),
    transpose(center,calcProjectedSideLength(center, scale, rotation) as [number,number]),
  ]
}

function PlotSource(center, rot, scale, format):maplibregl.SourceSpecification {
  
  return {
    type: "geojson",
    data: Turf.polygon([PlotLayerCornerArr(center, rot, scale, format)])
  }
}

function CanvasSource(center, rot, scale, format, canvas):maplibregl.CanvasSourceSpecification {
  return {
    type: "canvas",
    canvas: canvas,
    coordinates: PlotLayerCornerArr(center, rot, scale, format).filter((a, idx) => idx < 4) as any,
    animate: true
  }
}

function CanvasStyle():maplibregl.LayerSpecification {
  return {
    id: "CanvasPlot",
    source: "CanvasPlotSource",
    type: "raster"
  }
}

function PlotSquare(center, rot, scale, format):maplibregl.SourceSpecification {
  let corner = PlotLayerCornerArr(center, rot, scale, format)
  
  return {
    type: "geojson",
    data: Turf.lineString([center, corner[0]]),
  }
}

function PlotStyleSpec(id, source):maplibregl.LayerSpecification {
  return {
    id: id,
    type: "fill",
    source:source,
    paint: {
      "fill-color":"#fff0",
      "fill-outline-color":"grey",
    }
  }
}

function PlotStyleLine(id,source):maplibregl.LayerSpecification {
  return {
    id: id,
    type: "line",
    source:source,
    paint: {
      "line-width":2,
      "line-color":"blue",
    }
  }
}

function wrapText(ctx:CanvasRenderingContext2D, text:string, maxWidth: number) {
  const spaceWidth = ctx.measureText(" ").width
  const words = text.split(" ").map((a) => {
    const newLine = (a !== "\n")
    return newLine ? {
      word: a,
      width: ctx.measureText(a).width,
      newLine: false 
    } : {word:"",width:0,newLine:true}
  })
  
  let lines:string[] = [];
  let currentLine:string = "";
  let currentLineWidth = 0
  words.forEach((a, idx) => {
    if (a.newLine || currentLineWidth + spaceWidth + a.width > maxWidth) {
      lines.push(currentLine)
      currentLine = a.word
      currentLineWidth = a.width
    } else {
      currentLine += currentLine !== "" ? " " + a.word : a.word;
      currentLineWidth += a.width + spaceWidth
    }
  })
  currentLine && lines.push(currentLine)
  return lines
}


// MARK: Compose drawing
async function drawDrawing(
  ctx:CanvasRenderingContext2D, 
  canvas: HTMLCanvasElement, 
  legendCanvas: HTMLCanvasElement,
  map: HTMLCanvasElement | HTMLImageElement,
  drawingOptions: PlotScreenState
) {
  const Corners = PlotLayerCornerArr(drawingOptions.center,drawingOptions.bearing, drawingOptions.scale, drawingOptions.format)
  const CW = canvas.width;
  const CH = canvas.height;
  ctx.clearRect(0, 0, CW, CH);
  ctx.drawImage(map,0,0);
  ctx.fillStyle = "white";
  
  const mm = 8 // ?
  
  const Margin = drawingOptions.margin * mm
  ctx.fillRect(0,0, CW, Margin);
  ctx.fillRect(0,0, Margin, CH);
  ctx.fillRect(0,CH-Margin, CW, Margin);
  ctx.fillRect(CW-Margin,0, Margin, CH);
  
  let Bottom = Corners.map((a) => a).splice(2, 2)
  const BottomLengthKM = Turf.length(Turf.lineString(Bottom));
  const estW = BottomLengthKM*1000*0.3;
  let restW = estW % 100;
  if (estW < 100) {
    restW = estW % 20;
  }
  if (estW < 20) {
    restW = estW % 5;
  }
  
  const optimalW = estW - restW;
  const ScalaToOptimal = optimalW/estW;
  
  
  ctx.strokeStyle = "#000"
  ctx.lineWidth = 1;
  ctx.beginPath()
  ctx.moveTo(Margin,Margin)
  ctx.lineTo(CW-Margin,Margin)
  ctx.lineTo(CW-Margin,CH-Margin)
  ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin);
  ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin-60);
  ctx.lineTo(Margin, CH-Margin-60);
  ctx.lineTo(Margin,CH-Margin)
  ctx.lineTo(Margin,Margin)
  ctx.lineTo(Margin+10,Margin)
  ctx.stroke()
  
  
  
  const ImgNArrow = await drawingOptions.arrowImg
  if (ImgNArrow) {
    const ImgNArrowScale = 1/3
    const IW = ImgNArrow.width * ImgNArrowScale;
    const IH = ImgNArrow.height * ImgNArrowScale;
    const IBig = IW > IH ? IW : IH;
    const IOFF = Margin + 20 + (IBig/2)
    ctx.save()
    ctx.translate(IOFF,IOFF)
    ctx.rotate(((-(drawingOptions.bearing + 360) % 360) * Math.PI/180))
    ctx.drawImage(ImgNArrow, -IW/2, -IH/2, IW, IH )
    ctx.restore()
  }
  
  
  let totalImgHeight = 0;
  let sigWidth = (210*mm)*3/10;
  ctx.font = "45px Arial";
  let titleWrap = wrapText(ctx, drawingOptions.title.trim() , sigWidth)
  ctx.font = "30px Arial";
  let lines = wrapText(ctx, (ParseNewlinesAndSpaces(drawingOptions.description).trim() + " \n " + new Date().toLocaleDateString()).trim(), sigWidth)
  titleWrap.forEach(async (a:string) => {
    let x = a.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm);
    let img = drawingOptions.imgCache?.[a || "a"] || null;
    if (img) {
      let awaited = (await img)
      if (awaited) {
        totalImgHeight += (awaited.height * sigWidth/awaited.width) - 45;
      }
    }
  })
  lines.forEach(async (a:string) => {
    let x = a.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm);
    let img = drawingOptions.imgCache?.[a || "a"] || null;
    if (img) {
      let awaited = (await img)
      if (awaited) {
        totalImgHeight += (awaited.height * sigWidth/awaited.width) - 30;
      }
    }
  })
  
  
  
  let legendScaling = 1.5
  const IgnBlock = drawingOptions.legendIgnoreBlocks
  let dIgnBlock = [...IgnBlock, {start: legendCanvas.height, end: legendCanvas.height}]
  let legendHeight = (drawingOptions.showLegend && dIgnBlock.reduce((a,b) => a - (b.end - b.start), legendCanvas.height)) || 0;
  let box = titleWrap.length * 45 + lines.length * 30 + 15  + totalImgHeight;
  
  // Attrib
  ctx.strokeStyle = "#fff";
  ctx.fillStyle = "#000";
  ctx.font = "18px Arial";
  let attrib = drawingOptions.attrib.map((a) => "© " + a);
  attrib.sort((a,b) => {return ctx.measureText(b).width - ctx.measureText(a).width});
  attrib.forEach((str,idx) => {
    let strW = ctx.measureText(str).width
    ctx.strokeText(str, (CW - Margin - 7) - strW, CH-(2*Margin+ legendHeight + 10 + box + idx*19));
    ctx.fillText(str, (CW - Margin - 7) - strW, CH-(2*Margin+ legendHeight + 10 + box + idx*19));
  })
  
  ctx.strokeStyle = "#000";
  ctx.fillStyle = "#fff"
  ctx.fillRect(CW - ((sigWidth)+Margin+30), CH-(Margin+legendHeight + 30 + box+ 15) , sigWidth + 10+20 + 15, legendHeight + 20 + box + 15 + 15)
  ctx.beginPath()
  ctx.moveTo(CW - Margin, CH-(Margin+legendHeight + 30 + box+ 20))
  ctx.lineTo(CW - Margin, CH-(Margin+legendHeight + 30 + box+ 15))
  ctx.lineTo(CW - ((sigWidth)+Margin+30), CH-(Margin+legendHeight + 30 + box+ 15))
  ctx.lineTo(CW - ((sigWidth)+Margin+30) , CH-Margin)
  ctx.stroke()
  
  let currTTop = CH-(2*Margin+ legendHeight + 20 + box + 15);
  ctx.fillStyle = "#000"
  ctx.font = "45px Arial";
  for (let i = 0; i < titleWrap.length; i++) {
    let text = titleWrap[i];
    let img = drawingOptions.imgCache?.[text || "a"] || null;
    if (img) { 
      let awaited = (await img)
      if (awaited) {
        ctx.drawImage(awaited, CW - ((sigWidth)+Margin+10), Margin + currTTop, sigWidth, awaited.height * sigWidth/awaited.width)
        currTTop += (awaited.height * sigWidth/awaited.width);
      }
    } else {
      ctx.fillText(text, CW - ((sigWidth)+Margin+10), 35 + Margin + currTTop)
      currTTop += 45
    }
  }
  ctx.font = "24px Arial";
  for (let i = 0; i < lines.length; i++) {
    let text = lines[i];
    let img = drawingOptions.imgCache?.[text || "a"] || null;
    if (img) { 
      let awaited = (await img)
      if (awaited) {
        ctx.drawImage(awaited, CW - ((sigWidth)+Margin+10), Margin + currTTop, sigWidth, awaited.height * sigWidth/awaited.width)
        currTTop += (awaited.height * sigWidth/awaited.width);
      }
    } else {
      ctx.fillText(text, CW - ((sigWidth)+Margin+10), 35 + Margin + currTTop)
      currTTop += 30
    }
  }
  
  let legendBlockPadding = 20
  let legendInlinePadding = 20
  
  let currTop = 0 + legendBlockPadding + currTTop + 20;
  let lIP = legendInlinePadding
  let legendWidth = sigWidth // awaitedImages.reduce((a,b) => b !== "" ? (b.width > a ? b.width : a) : a , 0) * legnedScaling
  if (legendHeight) {
    ctx.fillStyle = "#fff";
    ctx.fillRect(CW-Margin - legendWidth - lIP, Margin+currTop-legendBlockPadding, legendWidth + lIP, legendHeight+legendBlockPadding*2)
    
    let lOff = 0;
    let inOff = 0;
    const IgnBlock = drawingOptions.legendIgnoreBlocks
    let dIgnBlock = [...IgnBlock, {start: legendCanvas.height, end: legendCanvas.height}]
    
    dIgnBlock.forEach((a, idx) => {
      ctx.drawImage(legendCanvas, 
        0, inOff, legendCanvas.width , a.start - inOff, 
        CW-Margin - legendWidth - lIP/2, Margin+currTop+lOff, legendCanvas.width , a.start - inOff ) 
        lOff += a.start - inOff;
        inOff = a.end;
      });
      
    }
    
    
    const ImgLogo = await drawingOptions.logoImg;
    if (ImgLogo) {
      const IScale = 1/10
      const IW = ImgLogo.width * IScale;
      const IH = ImgLogo.height * IScale;
      const IBig = IW > IH ? IW : IH;
      const IOFF = Margin + 20 + (IBig/2)
      const IOFFW = Margin - 5 + (IW/2)
      const IOFFH = Margin - 20 + (IH/2)
      
      ctx.save()
      ctx.translate(CW-(IOFFW + sigWidth + 30),CH-IOFFH)
      ctx.drawImage(ImgLogo, -IW/2, -IH/2, IW, IH )
      ctx.restore()
    }
    
    
    
    
    // Ruler
    // let Bottom = Corners.map((a) => a).splice(2, 2)
    // const BottomLengthKM = Turf.length(Turf.lineString(Bottom));
    
    ctx.font = "30px Arial";
    
    // const estW = BottomLengthKM*1000*0.3;
    // let restW = estW % 100;
    // if (estW < 100) {
    //   restW = estW % 20;
    // }
    // if (estW < 20) {
    //   restW = estW % 5;
    // }
    
    // const optimalW = estW - restW;
    // const ScalaToOptimal = optimalW/estW;
    
    
    
    
    const visualW = Math.round(optimalW);
    ctx.beginPath();
    // ctx.moveTo(Margin-5, CH-Margin-100);
    // ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal / 2 + 5, CH-Margin-25 - 10);
    // ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 5, CH-Margin-10 - 10);
    ctx.lineTo(Margin-5, CH-Margin-60);
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin-60);
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal + 100, CH-Margin+5);
    ctx.lineTo(Margin-5, CH-Margin+5);
    ctx.fillStyle = "#fff";
    ctx.fill();
    
    ctx.fillStyle = "#ff5722";
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 5;
    // ctx.fillRect(0, CH-10, CW / 2, 10);
    ctx.fillRect(Margin, CH-Margin-10, CW * 0.3 * ScalaToOptimal, 10);
    ctx.fillRect(Margin + CW * 0.3 * ScalaToOptimal, CH-Margin-10, -5, -5)
    ctx.lineTo(Margin + CW * 0.3 * ScalaToOptimal, CH-Margin-10)
    ctx.fillRect(Margin, CH-Margin-15, 5, 12);
    ctx.fillStyle = "#000";
    ctx.strokeText(visualW+ " m", Margin + CW * 0.3 * ScalaToOptimal + 7, CH-Margin-5);
    ctx.fillText(visualW+ " m", Margin + CW * 0.3 * ScalaToOptimal + 7, CH-Margin-5);
    ctx.fillRect(Margin, CH-Margin-25, CW * 0.3 * ScalaToOptimal / 2, 10);
    ctx.fillRect(Margin + CW * 0.3 * ScalaToOptimal / 2, CH-Margin-25, -5, -5);
    ctx.fillRect(Margin, CH-Margin-30, 5, 15);
    ctx.strokeText(visualW/2+ " m", Margin + (CW * 0.3 * ScalaToOptimal) /2 + 7, CH-Margin-17);
    ctx.fillText(visualW/2+ " m", Margin + (CW * 0.3 * ScalaToOptimal) /2 + 7, CH-Margin-17);
    
    let paper = (drawingOptions.format as any)
    paper = paper.slice(0, -1);
    
    
    ctx.fillStyle = "#000"
    ctx.strokeText("1:"+drawingOptions.scale+" ("+(paper)+")", Margin + 7, CH-Margin-34);
    ctx.fillText("1:"+drawingOptions.scale+" ("+(paper)+")", Margin + 7, CH-Margin-34);
    ctx.fillStyle = "#ff5722";
    ctx.fillText("1:", Margin + 7, CH-Margin-34);
    ctx.fillStyle = "#000";
    ctx.fillText("1", Margin + 7, CH-Margin-34);
    
    ctx.fillStyle = "#ff5722";
    
    ctx.fillStyle = "#000";
    
    drawFoldMarkers(ctx, canvas, drawingOptions)
    
  }
  
  function PromiseImage(url:URL|string) {
    return new Promise<HTMLImageElement | "">(async (resolve) => {
      const img = new Image();
      if (url === "") {
        resolve("")
        return
      }
      img.src = url as unknown as string //Cause Url is a string
      img.crossOrigin = "anonymous"
      img.onload = function () {
        resolve(img)
      }
      img.onerror = async function () {
        const corsProxy = SettingsManager.getSystemSetting("ImageCorsProxyUrl");
        const imgProx = new Image();
        let x = await fetch(corsProxy + `https://${(url as string).split("://")[1]}` as unknown as string, {
        headers: {
          accept:"image/png"
        }
      })
      let blobUrl = URL.createObjectURL(await x.blob())
      imgProx.src = blobUrl //Cause Url is a string
      imgProx.crossOrigin = "anonymous";
      imgProx.onload = function () {
        resolve(imgProx)
      }
      imgProx.onerror = function () {
        resolve("")
      }
      
    }
  })
}

function ParseNewlinesAndSpaces(text: string) {
  return text.replaceAll(/[ ]*[\n]/g," \n ")
}


function PaperToSize(paper:string): {height:number, width:number} {
  const PaperSizes = {
    "4A0L":{height:1682, width:2378},
    "4A0P":{width: 1682, height:2378},
    "2A0L":{height:1189, width:1682},
    "2A0P":{width: 1189, height:1682},
    "A0L":{height:841, width:1189},
    "A0P":{width: 841, height:1189},
    "A1L":{height:594, width:841},
    "A1P":{width: 594, height:841},
    "A2L":{height:420, width:594},
    "A2P":{width: 420, height:594},
    "A3L":{height:297, width:420},
    "A3P":{width: 297, height:420},
    "A4L":{height:210, width:297},
    "A4P":{width: 210, height:297},
    "A5L":{height:148, width:210},
    "A5P":{width: 148, height:210},
    "A6L":{height:105, width:148},
    "A6P":{width: 105, height:148},
    "A7L":{height:74, width:105},
    "A7P":{width: 74, height:105},
    "A8L":{height:52, width:74},
    "A8P":{width: 52, height:74},
    "A9L":{height:37, width:52},
    "A9P":{width: 37, height:52},
    "A10L":{height:26, width:37},
    "A10P":{width: 26, height:37},
  }
  return PaperSizes[paper] || PaperSizes["A4P"]
}

function numberFixedWidth(number:number, width:number = 2) {
  let padding = ("0".repeat(width))
  return (padding+number).slice(-width)
}

function SaveWithTimeStampt(name: string) {
  let now = new Date();
  
  let yyyy = numberFixedWidth(now.getFullYear(),4);
  let MM = numberFixedWidth(now.getMonth()+1);
  let dd = numberFixedWidth(now.getDate());
  let hh = numberFixedWidth(now.getHours());
  let mm = numberFixedWidth(now.getMinutes());
  let ss = numberFixedWidth(now.getSeconds());
  
  return yyyy+MM+dd+"_"+name+"_"+hh+mm+ss;
}

function MouseEnter(e, layer ,action, callBack) {
  let feature:undefined|any = undefined
  let center:undefined| any = undefined
  let start:(number|undefined)[] = [undefined, undefined];
  let end:(number|undefined)[] = [undefined, undefined];
  let startAngle:number|undefined = undefined;
  let endAngle:number|undefined = undefined;
  
  if (action.current == "move") {
    e.target.getCanvas().style.cursor = "move";
  }
  if (action.current == "rota") {
    let url = `url('${new URL("/cursor/rotate.svg",import.meta.url)}') 10 10 , auto`;
    e.target.getCanvas().style.cursor = url;
  }
  
  
  const mouseUp = (e) => {
    end = [e.lngLat.lng,e.lngLat.lat]
    endAngle = center && Turf.bearing(center, end as [number,number])
    if (action.current == "move" && end[0] && start[0] && end[1] && start[1]) {
      callBack.move(end[0]-start[0], end[1]-start[1])
    }
    if (action.current == "rota" && endAngle && startAngle) {
      callBack.rotate(endAngle-startAngle)
    }
    e.target.off("mouseup", mouseUp);
    e.target.off("mousemove", mouseMove)
  }
  
  const mouseMove = (e) => {
    let cur = [e.lngLat.lng,e.lngLat.lat]
    let curAngle = center && Turf.bearing(center, cur as [number,number])
    
    if (action.current == "move" && cur[0] && start[0] && cur[1] && start[1]) {
      let lngC = cur[0] - start[0]
      let latC = cur[1] - start[1]
      let newFeature = GB_feature && {
        ...feature,
        geometry:{ ...feature.geometry,
          coordinates: [feature.geometry.coordinates[0].map((a) => [a[0]+ lngC, a[1]+latC])]}
        }
        GB_feature = newFeature
        e.target.getSource("Square")?.setData(GB_feature);
        (e.target.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(newFeature.geometry.coordinates[0].filter((a,idx) => idx < 4))
      }
      if (action.current == "rota" && curAngle && startAngle) {
        let newFeature = feature && Turf.transformRotate(feature, curAngle-startAngle);
        GB_feature = newFeature
        e.target.getSource("Square")?.setData(GB_feature);
        (e.target.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(newFeature.geometry.coordinates[0].filter((a,idx) => idx < 4))
      }
      
      
    }
    
    const mouseDown = (e) => {
      feature = GB_feature
      // feature = e.features[0];
      center = Turf.center(feature);
      start = [e.lngLat.lng,e.lngLat.lat]
      startAngle = center && Turf.bearing(center, start as [number,number])
      e.preventDefault();
      let points = e.points
      callBack.setLoading?.()
      e.target.on("mouseup", mouseUp);
      e.target.on("mousemove", mouseMove)
    }
    
    //LAST cleaning
    const mouseLeave = () => {
      e.target.getCanvas().style.cursor = ""
      e.target.off("mouseleave", layer, mouseLeave);
      e.target.off("mousedown", layer, mouseDown);
    }
    
    if (action.current !== "none") {
      e.target.on("mousedown", layer, mouseDown);
      e.target.on("mouseleave", layer, mouseLeave);
    }
  }
  
  
  function Move(e:any) {
    e.preventDefault();
  }
  
  function Rotate(e:any) {
    e.preventDefault();
  }
  
  function maxWebGLSize() {
    let OffScreenCanvas = new OffscreenCanvas(1,1)
    let gl = OffScreenCanvas.getContext("webgl")
    let max = gl!.getParameter(gl!.MAX_TEXTURE_SIZE) as number
    return max
  }
  
  // MARK: LEGEND
  async function GenerateLegend(
    canvas: HTMLCanvasElement, 
    drawingOptions: PlotScreenState
  ) {
    
    let dataL = await dataLayerLegend(drawingOptions)
    
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "30px Arial";
    }
    const awaitedImages = await Promise.all(drawingOptions.legendImg.map(async(a) => ({...a, img: await a.img})))
    const legendWidth = Math.max(Math.max(...awaitedImages.map((a) => (a.img ? a.img.width : 0))), ctx?.measureText("Signaturforklaring").width || 0) 
    let legendScaling = 1.5;
    let legendHeight = 55 + awaitedImages.reduce((a,b) => (b.img !== "" && !("noLegend" in b.ftl.layer && b.ftl.layer.noLegend === true)) ? ((b.ftl.layer.legendCrop||b.img.height)*legendScaling + a) : a , 0)
    legendHeight = legendHeight ? legendHeight + 0 : legendHeight
    
    canvas.height = legendHeight + dataL.reduce((pre, cur) => pre + cur.height,0);
    canvas.width = legendWidth * legendScaling;
    
    if (!ctx) {
      return
    }
    
    ctx.fillStyle = "#fff";
    ctx.fillRect(0,0,legendWidth,legendHeight)
    
    let currTop = 20
    ctx.fillStyle = "#000";
    
    ctx.font = "30px Arial";
    ctx.fillText("Signaturforklaring", 5, currTop+20)
    
    // DATA Legend color
    currTop += 35
    dataL.forEach((a) => {
      currTop += DrawDataIcon(ctx, currTop, a.icon, a.layerInfo)
      currTop += DrawColorByValue(ctx, currTop, a.valueColorLegend, a.layerInfo)
      currTop += DrawSizeByValue(ctx, currTop, a.valueSizeLegend, a.layerInfo)
    })
    
    // FEATURELAYER
    currTop = dataL.reduce((pre, cur) => pre + cur.height,0) + 55;
    ctx.font = "20px Arial";
    
    awaitedImages.forEach((a) => {
      if (a.img == "" || ("noLegend" in a.ftl.layer && a.ftl.layer.noLegend === true)) {
        return
      }
      ctx.filter = `hue-rotate(${a.ftl.layer.paint?.["raster-hue-rotate"] || 0}deg) saturate(${(a.ftl.layer.paint?.["raster-saturation"] || 0)+1})`;
      ctx.drawImage(a.img, 
        0, a.ftl.layer.legendOffset || 0, a.img.width, a.ftl.layer.legendCrop || a.img.height,
        5, currTop, a.img.width * legendScaling, (a.ftl.layer.legendCrop || a.img.height) * legendScaling
      )
      if (a.label) {
        
        ctx.fillText(a.label, 5 + a.img.width * legendScaling + 2, currTop + ((a.ftl.layer.legendCrop || a.img.height) * legendScaling / 2) + 5)
      }
      currTop += (a.ftl.layer.legendCrop || a.img.height) * legendScaling
    })
  }
  
  function DrawSizeByValue(ctx:CanvasRenderingContext2D, currTop:number, colorByValue:any, layerInfo:LayerInfo) {
    if (!layerInfo.styling.sizeByProperty || !layerInfo.styling.sizeByValue?.divisions) {
      return 0
    }
    ctx.fillText(layerInfo.styling.sizeByProperty, 55, currTop + 20)
    let x = 25
    const increas = (layerInfo.styling.sizeByValue.useSizeRange || 0) % 2
    layerInfo.styling.sizeByValue?.divisions.list.forEach((a, idx, list) => {
      ctx.fillStyle = layerInfo.styling.color || "#000";
      ctx.beginPath()
      if (!increas) {
        let radius = (4*(idx) + 7) / 2;
        ctx.arc(50+radius, x + currTop+12, radius, 0, 2*Math.PI);
        ctx.fill();
      } else {
        let radius = (4*(list.length - idx) + 7) / 2
        ctx.arc(50+radius, x + currTop+12, radius, 0, 2*Math.PI);
        ctx.fill();
      }
      ctx.fillStyle = "#000"
      ctx.fillText(a.to?.toString() ? "<=" : " >", 90, currTop + 20 + x)
      ctx.fillText(a.to?.toString() || a.from?.toString() || "", 120 ,currTop + 20 + x)
      x += 25
    })
    return x
  }
  
  function DrawColorByValue(ctx:CanvasRenderingContext2D, currTop:number, colorByValue:any, layerInfo:LayerInfo) {
    if (!layerInfo.styling.colorByProperty || !layerInfo.styling.colorByValue?.divisions) {
      return 0
    }
    ctx.fillText(layerInfo.styling.colorByProperty, 55, currTop + 20)
    let x = 25
    layerInfo.styling.colorByValue?.divisions.list.forEach((a, idx, list) => {
      ctx.fillStyle = a.color || "#000"
      ctx.fillRect(50, currTop + x, 20, 20)
      ctx.fillStyle = "#000"
      if (a.label) {
        ctx.fillText(a.label, 90, currTop + 20 + x)
      } else if (a.value) {
        ctx.fillText(a.value, 90, currTop + 20 + x)
      } else {
        ctx.fillText(a.to?.toString() ? "<=" : " >", 90, currTop + 20 + x)
        ctx.fillText(a.to?.toString() || a.from?.toString() || "", 120 ,currTop + 20 + x)
      }
      x += 25
    })
    return x
  }
  
  function DrawDataIcon(ctx:CanvasRenderingContext2D, currTop:number, icon:HTMLImageElement | "", layerInfo:LayerInfo) {
    if (icon == "") {
      return 0
    }
    ctx.font = "20px Arial"
    ctx.drawImage(icon, 15, currTop+2, 16, 22)
    ctx.fillText(layerInfo.datasetname, 50, 20 + currTop)
    return 25
  }
  
  async function dataLayerLegend(drawingOptions: PlotScreenState) {
    async function svgToHtmlImage(svgString):Promise<HTMLImageElement | ""> {
      return new Promise((res) => {
        var svg = new Blob([svgString], {
          type: "image/svg+xml;charset=utf-8"
        });
        
        var url = URL.createObjectURL(svg);
        
        var img = new Image();
        img.onload = () => {
          res(img)
          URL.revokeObjectURL(url);
        }
        img.onerror = () => {
          res("")
        }
        img.width = 50;
        img.height = 82;
        img.src = url
      });
    } 
    
    const sizePLine = 20;
    const layer = drawingOptions.dataLayer.filter((a) => a.visible)
    
    return await Promise.all(layer.map(async (layerInfo) => {
      
      
      
      const layerIcon =
      layerInfo.type === LayerType.GeoJSON_Point ||
      layerInfo.type === LayerType.PointWGS84 ? (
        await svgToHtmlImage(GenerateGeom.MitPinSvg(layerInfo!.styling!.color))
      ) : (
        await svgToHtmlImage(GenerateGeom.MitAreaSvg(layerInfo!.styling!.color))
      );
      let valueColorLegend = layerInfo!.styling!.colorByProperty
      let valueSizeLegend = layerInfo!.styling!.sizeByProperty
      let noDataLegend =
      MapitUtils.isAreaLayer(layerInfo.type) &&
      !layerInfo.styling.hideAreasWithNoData
      let areaFillColorLegendValue =
      MapitUtils.isAreaLayer(layerInfo.type) &&
      !layerInfo!.styling!.colorByProperty
      let otherValueDataLegendValue =
      layerInfo!.styling!.colorByValue &&
      layerInfo!.styling!.colorByValue!.divisions &&
      layerInfo!.styling!.colorByValue!.divisions!.type ===
      DataDivisionType.Discrete &&
      (layerInfo!.styling!.colorByValue!.divisions!.otherValuesCount || 0) > 0
      return {
        icon: layerIcon,
        name: layerInfo.datasetname,
        layerInfo: layerInfo,
        valueColorLegend: valueColorLegend,
        valueSizeLegend: valueSizeLegend,
        noDataLegend: noDataLegend,
        areaFillColorLegendValue: areaFillColorLegendValue,
        otherValueDataLegendValue: otherValueDataLegendValue,
        height: 30 + 
        (layerInfo.styling.colorByValue?.divisions?.list?.length || 0)*25 + (layerInfo.styling.colorByValue?.divisions?.list?.length ? 25 : 0) +
        (layerInfo.styling.sizeByValue?.divisions?.list?.length || 0)*25 +  (layerInfo.styling.sizeByValue?.divisions?.list?.length ? 25 : 0) 
      }
    }))
  }
  
  
  // MARK: FOLD MARKER
  function drawFoldMarkers(
    ctx:CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    drawingOptions: PlotScreenState
  ) {
    
    if (drawingOptions.fold !== true) {
      return
    }
    
    const {height:fHeight, width:fWidth} = PaperToSize(drawingOptions.format)
    const fSize = Number(drawingOptions.format.split("A")[1][0])
    const fSizeA0 = Number(drawingOptions.format.split("A")[0] || 1)
    
    const ffSize = Number(drawingOptions.foldFormat.split("A")[1][0])
    const ffSizeA0 = Number(drawingOptions.foldFormat.split("A")[0] || 1)
    const distance = (ffSize - Math.log2(ffSizeA0)) - (fSize - Math.log2(fSizeA0))
    if (distance < 1) {
      return null
    }
    
    const direction = canvas.height < canvas.width   
    
    const lxfolds = Math.floor(distance / 2) + (distance % 2)
    const sxfolds = Math.floor(distance / 2)
    
    const lxTfolds = 2**lxfolds; 
    const sxTfolds = 2**sxfolds; 
    
    
    // ctx.save()
    // ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.fillStyle = "#000"
    
    let xSize = canvas.width / ((direction && lxTfolds) || sxTfolds);
    let ySize = canvas.height / ((direction && sxTfolds) || lxTfolds);
    
    ctx.save()
    
    for(let i = 0; i < 2; i++) {
      for (let x = 0; x <= lxTfolds; x++) {
        const xcpos = xSize * x;
        ctx.fillRect(xcpos-1, drawingOptions.margin*4, 2, drawingOptions.margin*4 + 25)
      }
      
      for (let y = 0; y <= lxTfolds; y++) {
        const ycpos = ySize * y;
        ctx.fillRect(drawingOptions.margin*4, ycpos-1, drawingOptions.margin*4 + 25, 2)
      }
      
      ctx.translate(canvas.width, canvas.height);
      ctx.rotate(Math.PI);
    }
    ctx.restore()
  }