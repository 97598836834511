// import * as XMLJS from 'xml-js';
import * as https from 'https';
import { Utils } from '@viamap/viamap2-common';
import * as querystring from 'querystring';
import { UtmCoords } from './ObliqueFunc';
import { GenerateGeom } from './GenerateGeom';
import { MitLatLng } from './MapFacade';
import { SettingsManager } from '@viamap/viamap2-common';
import { FeatureLayer } from './WmsLayerFunc';
import { stringify as wktStringify } from 'wkt';

import { PropertyInfoInterface as PropertyInfoInterfaceCVR} from 'src/compentsModus/PropertyInfoInterface';
import { ESInterface } from 'src/compentsModus/ESInterface';
import { FilterType } from 'src/states/ExploreSearchState';
import { FeatureCollection, featureCollection, polygon } from '@turf/helpers';


type sendRequestType = {
   timeout?: number,
   path: string,
   method: string|"POST"|"GET",
   headers?: {[key:string]:string}
} & ({hostname: string} | {host: string}) & ({protocol: string} | {port: number})
export class PropertyInfoInterface {
   static API_KEY = "QnSGmQ8Icf4aMf7SA41485ZbbfuMCuOgF23GjaYd";

   

   static async sendRequest(options: sendRequestType, body?: any) {
      return new Promise((resolve, reject) => {
         let params = options;
         let url;
         if ("hostname" in params) {
            url = "https://"+params.hostname+params.path
         } else {
            url = "https://"+params.host+params.path
         }
         if (body) {
            params["body"] = JSON.stringify(body)
         }
         let timeout = options.timeout ?? 10000;
         const controller = new AbortController()
         setTimeout(() => controller.abort(), timeout)
         fetch(url, {...params, signal:controller.signal}).then((a) => {
            if (a.headers['Content-Type']?.toLowerCase().startsWith("application/json")) {
               return a.json()
            }
            return a.json()
         }).then((result) => {
            resolve(result)
         }).catch((err) => {
            reject(err)
         })
      });
   }

   static async getMatrikelEjerlavkode(latlng: MitLatLng): Promise<{ ejerlavskode: string; matrikelNr: string; }> {
      return new Promise<{ ejerlavskode: string, matrikelNr: string }>(async (resolve, reject) => {
         let utmCoords = GenerateGeom.convertFromLatLngToUTM(latlng);
         await PropertyInfoInterface.getMatrikelData(utmCoords)
            .then(matrikelData => {
               let properties = matrikelData.features[0].properties;
               if (Object.keys(properties).length) {
                  resolve({ ejerlavskode: properties.ejerlavskode, matrikelNr: properties.matrikelnummer });
               } else {
                  reject("No cadaster data for this point.");
               }
            })
            .catch(() => reject("Could not retrieve cadaster data for this point"));
      });
   }

   static async getEjendomData(ejerlavskode: string, matrikelnr: string): Promise<any> {
      let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
      let servicePath = "/getEjendomDataFormated";

      let parameters = {
         "Matrikler": [
            {
               "ejerlavskode": ejerlavskode,
               "ejerlavsnavn": "",
               "matrikelnummer": matrikelnr
            }
         ],
         "SagsNr": "ASM_1"
      };
      // const qstr = querystring.stringify(qs);
      let path = servicePath;
      let options = {
         protocol: "https:",
         hostname: serviceHost,
         // port: 443,
         path: path,
         method: 'POST',
         headers: {
            "Content-Type": "application/json",
            "X-API-KEY": this.API_KEY
         }
      };

      let res = await this.sendRequest(options, parameters);

      return res;
   }

   static async getEjendomStats(kommuneNr: string, postNr: string, kategori: number): Promise<any> {
      let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
      let servicePath = "/getEjendomStats";

      let qs = {
         "kommuneNr": kommuneNr,
         "postNr": postNr,
         "kategori": kategori
      };
      const qstr = querystring.stringify(qs);
      let path = servicePath + "?" + qstr;
      let options = {
         hostname: serviceHost,
         protocol: "https:",
         // port: 443,
         path: path,
         method: 'GET',
         headers: {
            "Content-Type": "application/json",
            "X-API-KEY": this.API_KEY
         }
      };

      let res = await this.sendRequest(options);

      return res;
   }

   static async getHandelsOplysning(bfeNummer: string): Promise<any> {
      let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
      let servicePath = "/getHandelsOplysning";

      let qs = {
         "BFEnr": bfeNummer
      };
      const qstr = querystring.stringify(qs);
      let path = servicePath + "?" + qstr;
      let options = {
         hostname: serviceHost,
         protocol: "https:",
         // port: 443,
         path: path,
         method: 'GET',
         headers: {
            "Content-Type": "application/json",
            "X-API-KEY": this.API_KEY
         }
      };

      let res = await this.sendRequest(options);

      return res;
   }

   
   static async getDataTingbog(ejerlavkode: string, matrikelnr: string): Promise<any> {
      let parameters = {
         "landsejerlavid": "" + ejerlavkode,
         "matrikelnr": matrikelnr
      };
      let serviceHost = "eymh7y652k.execute-api.eu-north-1.amazonaws.com/production"; // Utils.getSystemSettingByEnvironment("obliqueServiceHost");
      let servicePath = "/getTinglysningsData";
      const options = {
         protocol: "https:",
         host: serviceHost,
         path: servicePath,
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "X-API-KEY": this.API_KEY
         }
      };

      let res = await this.sendRequest(options, parameters);

      return res;
   }

   static async Ejerlejligheder(SFEBFEnr: string | number) {
      let qs2 = {
         "SFEBFEnr": SFEBFEnr,
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/Matriklen2/Matrikel/2.0.0/rest/Ejerlejlighed?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }

   static async EjerlejlighederEnkelt(ELBFEnr: string | number) {
      let qs2 = {
         "ELBFEnr": ELBFEnr,
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/Matriklen2/Matrikel/2.0.0/rest/Ejerlejlighed?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }
   
   static async getMatrikelData(loc: UtmCoords) {
      const ftl = new FeatureLayer({
         "version":"1.1.1",
         "label": "t",
         "host": "services.datafordeler.dk",
         "path": "/MATRIKLEN2/MatGaeldendeOgForeloebigWMS/1.0.0/WMS",
         "layers": "Jordstykke_Gaeldende"
         })
      let {BBOX2, HEIGHT2, WIDTH2, X2, Y2} = this.createSmallBox(loc);
      let URL = ftl.getinformationURL(BBOX2, HEIGHT2, WIDTH2, X2, Y2, {
         ...this.getDatafordelerCredentials(),
         "STYLES": "Jordstykke_Gaeldende_Roed",
         "FORMAT": "image/png",
         "INFO_FORMAT": "text/plain",
         "SRS": "EPSG:25832",
     });

      // let res = await this.sendRequest(options, parameters);
      let res = await this.getFeatureInfo(URL);

      return res;
   }

   static async getLokalplanDataInsideGeom(layer: string, geom: any) {
      let wkt = wktStringify(geom.features[0])

      let parameters = {
         service:"wfs",
         request:"getFeature",
         version:"2.0.0",
         outputFormat:"json",
         crs:"EPSG:4326",
         typenames: layer,
         CQL_FILTER:`overlaps(geometri, ${wkt}) or within(geometri, ${wkt})`
      };
      let response = fetch("https://geoserver.plandata.dk/geoserver/wfs", {
         method: "POST",
         headers: {
            "Content-Type":"application/x-www-form-urlencoded;charset=UTF-8"
         },
         body: querystring.stringify(parameters)
      });
      return await (await response).json();
   }

   static async getLokalplanData(layer: string, loc: UtmCoords) {
      let parameters = {
         service:"wfs",
         request:"getFeature",
         version:"1.1.0",
         outputFormat:"json",
         typenames: layer,
         CQL_FILTER:"Intersects(geometri,POINT("+loc.x+" "+loc.y+"))"
      };
      let response = fetch("https://geoserver.plandata.dk/geoserver/wfs", {
         method: "POST",
         headers: {
            "Content-Type":"application/x-www-form-urlencoded;charset=UTF-8"
         },
         body: querystring.stringify(parameters)
      }) ;
      return await (await response).json();
   }

   static async fetchMat2CadasterWFSForSFE(SFEBFEnr: number|string): Promise<any> {
      const creds = this.getDatafordelerCredentials()

      const url = "https://services.datafordeler.dk/MATRIKLEN2/MatGaeldendeOgForeloebigWFS/1.0.0/Wfs?SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&srsName=EPSG:4326&COUNT=500&TYPENAMES=Jordstykke_Gaeldende"
      const credentials = `&username=${creds.username}&password=${creds.password}`
      const fesFilter = `&FILTER=<fes:Filter xmlns:fes="http://www.opengis.net/fes/2.0" xmlns:gml="http://www.opengis.net/gml/3.2">
       <fes:PropertyIsEqualTo xmlns:fes="http://www.opengis.net/fes/2.0">
        <fes:ValueReference>samletFastEjendomLokalId</fes:ValueReference>
        <fes:Literal xmlns:fes="http://www.opengis.net/fes/2.0">${SFEBFEnr}</fes:Literal>
       </fes:PropertyIsEqualTo>
   </fes:Filter>`;

      // Ejerlavsnavne er ikke retuneret fra WFS
      let EjerlavsNavne = {}
      let x = await (await this.sfeLookupByBfeNr(Number(SFEBFEnr))).json()
      x.features[0].properties.jordstykke.forEach((jordstykke) => {
         EjerlavsNavne = {...EjerlavsNavne, [jordstykke.properties.ejerlavskode]:jordstykke.properties.ejerlavsnavn}
      })

      function getProperty(element: Element, tag:string) {
         return Array.from(element.getElementsByTagName(tag))[0].innerHTML
      }
      
      try {
         const response = await fetch(encodeURI(url + credentials + fesFilter))
         .catch((a) => {throw new Error(a)})
         let parser = new DOMParser();
         let doc = parser.parseFromString(await response.text(), "text/xml")
         let Polygons = Array.from(doc.getElementsByTagName("wfs:member"))?.map((member) => {
            let posLists = Array.from(member.getElementsByTagName("gml:posList"))?.map((a) => {
               const numList = a.innerHTML.split(" ").map((strVal) => {return Number.parseFloat(strVal)});
               let lng = numList.filter((a,b) => b % 2 == 0)
               let lat = numList.filter((a,b) => b % 2 == 1)
               return lat.map((a,b) => [a,lng[b]])
            })
            let ejerlavskode = getProperty(member, "mat:ejerlavskode")
            return polygon(posLists as any,{
               Matrikelnummer: getProperty(member, "mat:matrikelnummer"),
               Ejerlavskode: ejerlavskode,
               Ejerlavsnavn: EjerlavsNavne[ejerlavskode] || "unknown"
            })
         })
         return featureCollection(Polygons)
      } catch (e) {
         console.error(e)
         return featureCollection([])
      }
   }

   static async fetchMat2CadasterWFS(ejerlavKode: string, matrikelnr: string): Promise<any> {
      const creds = this.getDatafordelerCredentials()

      const url = "https://services.datafordeler.dk/MATRIKLEN2/MatGaeldendeOgForeloebigWFS/1.0.0/Wfs?SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&srsName=EPSG:4326&COUNT=100&TYPENAMES=Jordstykke_Gaeldende"
      const credentials = `&username=${creds.username}&password=${creds.password}`
      const fesFilter = `&FILTER=<fes:Filter xmlns:fes="http://www.opengis.net/fes/2.0" xmlns:gml="http://www.opengis.net/gml/3.2">
         <fes:And xmlns:fes="http://www.opengis.net/fes/2.0">
          <fes:PropertyIsEqualTo xmlns:fes="http://www.opengis.net/fes/2.0">
           <fes:ValueReference>ejerlavskode</fes:ValueReference>
           <fes:Literal xmlns:fes="http://www.opengis.net/fes/2.0">${ejerlavKode}</fes:Literal>
          </fes:PropertyIsEqualTo>
          <fes:PropertyIsEqualTo xmlns:fes="http://www.opengis.net/fes/2.0">
           <fes:ValueReference>matrikelnummer</fes:ValueReference>
           <fes:Literal xmlns:fes="http://www.opengis.net/fes/2.0">${matrikelnr}</fes:Literal>
          </fes:PropertyIsEqualTo>
         </fes:And>
      </fes:Filter>`;
      

      try {
         const response = await fetch(encodeURI(url + credentials + fesFilter))
         .catch((a) => {throw new Error(a)})
         let parser = new DOMParser();
         let doc = parser.parseFromString(await response.text(), "text/xml")
         let posLists = Array.from(doc.getElementsByTagName("gml:posList"))?.map((a) => {
            const numList = a.innerHTML.split(" ").map((strVal) => {return Number.parseFloat(strVal)});
            let lng = numList.filter((a,b) => b % 2 == 0)
            let lat = numList.filter((a,b) => b % 2 == 1)
            return lat.map((a,b) => [a,lng[b]])
         })
         return polygon(posLists as any)
      } catch (e) {
         return (e)
      }
   }

   /**
    * @deprecated Outdated data
    */
   static async searchCadaster(ejerlavkode: string, matrikelnr: string): Promise<any> {
      let serviceUrl = "https://dawa.aws.dk/jordstykker";
      let query = serviceUrl + "?ejerlavkode=" + ejerlavkode + "&matrikelnr=" + matrikelnr;
      try {
         const response = await fetch(query);
         if (response.status !== 200) {
            throw new Error("Http Error status code received: " + response.status);
         }
         const data = await response.json();
         return { data: data };
      } catch (err:any) {
         return { error: err.toString() };
      }
   }

   static getDatafordelerCredentials():{username:string,password:string} {
      return {
         username: SettingsManager.getSystemSetting("datafordelerUsername"),
         password: SettingsManager.getSystemSetting("datafordelerPassword")
      };
   }

   static async EBRadresseForBFE(BFEnr: string):Promise<any> {
      let qs2 = {
         "BFEnr": BFEnr,
         "format": "json"
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/EBR/Ejendomsbeliggenhed/1/REST/Ejendomsbeliggenhed?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }
   static async BBRgrundForBFE(BFEnr: string):Promise<any> {
      let qs2 = {
         "BFEnummer": BFEnr,
         "status": "7",
         "format": "json"
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/BBR/BBRPublic/1/rest/grund?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }
   
   static async BBRsamletFastEjendomBFE(ejerlavsKode: string, matrikelNr: string):Promise<any> {
      let qs2 = {
         "Ejerlavskode ": ejerlavsKode,
         "matrikelNr": matrikelNr,
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/Matriklen2/Matrikel/1.0.0/REST/SamletFastEjendom?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }

   /**
    * @link https://datafordeler.dk/dataoversigt/ejendomsvurdering-vur/ejendomsvurdering-vurderingsejendom-id/
    */
   static async vurdLookupByVurEjendomsId  (VURejendomsid: number, year?: number): Promise<any> {
      let qs2: any = {
         "VURejendomsid": VURejendomsid,
      };

      if (year !== undefined) {qs2.År = year;}

      let qs = { ...qs2, ...this.getDatafordelerCredentials() };
      const qstr = querystring.stringify(qs);
      let path = "/Ejendomsvurdering/Ejendomsvurdering/1/rest/HentEjendomsvurderingerForVurderingsejendomID?" + qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }

   /**
    * @link https://datafordeler.dk/dataoversigt/ejendomsvurdering-vur/ejendomsvurdering-bestemt-fast-ejendom/
    */
   static async vurdLookupByBfeNr(BFEnummer: string, year?: number): Promise<any> {
      let qs2: any = {};

      if (BFEnummer === undefined) {
         return [];
      } else {
         qs2.BFEnummer = BFEnummer;
      }
      if (year !== undefined) { qs2.År = year; }

      let qs = { ...qs2, ...this.getDatafordelerCredentials() };
      const qstr = querystring.stringify(qs);
      let path = "/Ejendomsvurdering/Ejendomsvurdering/1/rest/HentEjendomsvurderingerForBFE?" + qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }

   /**
    * @link https://datafordeler.dk/dataoversigt/matriklen-mat/samlet-fast-ejendom/
    */
   static async sfeLookupByBfeNr(bfeNr: number) {
      const { username, password } = this.getDatafordelerCredentials();
      return await fetch("https://services.datafordeler.dk/Matriklen2/Matrikel/1.0.0/REST/SamletFastEjendom?username=" + username + "&password=" + password +
         "&Format=JSON&SFEBFEnr=" + bfeNr);
   }

   static async BBRejendomsrelationForBFE(BFEnr: string): Promise<any> {
      let qs2 = {
         "BFEnummer": BFEnr,
         "format": "json"
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials() };
      const qstr = querystring.stringify(qs);
      let path = "/BBR/BBRPublic/1/rest/ejendomsrelation?" + qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
   }
   
   static async BBRbygningForGrundId(grundId: string):Promise<any> {
      let qs2 = {
         "grund": grundId,
         "format": "json"
      };
      let qs = { ...qs2, ...this.getDatafordelerCredentials()};
      const qstr = querystring.stringify(qs);
      let path = "/BBR/BBRPublic/1/rest/bygning?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);
      return res;
   }


   static async BBRTekniskAnlaeg<bbrValue extends string>(params: {[bbrKey:string]:bbrValue}) {
      const urlParams = {
         "format": "json",
         ...params,
         ...this.getDatafordelerCredentials()
      }
      
      let qstr = (new URLSearchParams(urlParams)).toString()
      let path = "/BBR/BBRPublic/1/rest/tekniskanlaeg?"+qstr;
      let options = {
         hostname: 'services.datafordeler.dk',
         path: path,
         port: 443,
         method: 'GET',
      };
      let res = await this.sendRequest(options);
      return res;
   }

   static async BBRteknikForGrundId(grundId: string):Promise<any> {
      let qs2 = {
         "grund": grundId,
      };
      return await PropertyInfoInterface.BBRTekniskAnlaeg(qs2);
   }

   static async BBRteknikForBFENR(bfeNr: string):Promise<any> {
      // Kun for BygningPåFremmedGrund og Ejerlejlighed
      let qs2 = {
      "BFENummer": bfeNr,
   };
   return await PropertyInfoInterface.BBRTekniskAnlaeg(qs2);
}

   static async BBRteknikForBygId(bygId: string):Promise<any> {
         let qs2 = {
         "bygning": bygId,
      };
      return await PropertyInfoInterface.BBRTekniskAnlaeg(qs2);
   }

   static async BBRteknikForEnhedId(enhedId: string):Promise<any> {
         let qs2 = {
         "enhed": enhedId,
      };
      return await PropertyInfoInterface.BBRTekniskAnlaeg(qs2);
   }

   static async BBRenhedForEtageId(etageId: string):Promise<any> {
      let qs2 = {
         "etage": etageId,
      };
      return await PropertyInfoInterface.BBR_Datafordeler("enhed",qs2);
   }

   // Bygning er UUID, fra BBR lokalID
   static async DAR_BygningTilAdgang(bygning: string) {
      let qs2 = {
         AdgangTilBygning : bygning
      }
      return await PropertyInfoInterface.DAR_DatafordelerGaeldende("husnummer", qs2);
   }

   // Husnummer er UUID, fra AdgangsAdresser lokalID
   static async DAR_AdresserFraHusnummer(husnummer:string) {
      let qs2 = {
         husnummer : husnummer
      }
      return await PropertyInfoInterface.DAR_DatafordelerGaeldende("adresse", qs2);
   } 

   static async DAR_DatafordelerGaeldende(endpoint, query: {[key:string]:any}) {
      const params = new URLSearchParams({
         format:"json",
         status:"3",
         ...query,
         ...PropertyInfoInterface.getDatafordelerCredentials()
      }).toString();

      return await PropertyInfoInterface.sendRequest({
         hostname: 'services.datafordeler.dk',
         path: `/DAR/DAR/3.0.0/rest/${endpoint}?${params}`,
         port: 443,
         method: 'GET',
      });
   }

   
   static async BBR_Datafordeler(endpoint, query: {[key:string]:any}) {
      const params = new URLSearchParams({
         format:"json",
         ...query,
         ...PropertyInfoInterface.getDatafordelerCredentials()
      }).toString();

      return await PropertyInfoInterface.sendRequest({
         hostname: 'services.datafordeler.dk',
         path: `/BBR/BBRPublic/1/rest/${endpoint}?${params}`,
         port: 443,
         method: 'GET',
      });
   }

   static async BBREnhedForBFE(bfe_nr) {
      // Kun for Ejerlejligheds BFE_nr
      let qs2 = {
         BFENummer:bfe_nr
      }
      return await PropertyInfoInterface.BBR_Datafordeler("enhed", qs2) 

   }

   static async BBRBygningForBFE(bfe_nr) {
      // Kun for BygningPåFremmedGrund og Ejerlejlighed bygning?
      let qs2 = {
         BFENummer:bfe_nr
      }
      return await PropertyInfoInterface.BBR_Datafordeler("bygning", qs2) 

   }

   static async PEnhederpaaEjendom(matrikler: any[]):Promise<any> {
      let resMatrikler:any;

      resMatrikler = await Promise.all(matrikler.map(async (mat) => {
         let matrikelTekst = `${mat.matrikelnr} ${mat.ejerlavsnavn} (${mat.ejerlavkode})`;
         let adresseResponse = await this.AdresserFraEjerlavOgMatrikel(mat.ejerlavkode,mat.matrikelnr);
         let resAdresser = await Promise.all(adresseResponse.map(async (addr) => {
            let adresseTekst = addr.adressebetegnelse;
            let adresseId = addr.id;
            let pEnhederResponse = await PropertyInfoInterfaceCVR.getCVRSearch({
               "size":20,
               "_source": [
                   "VrproduktionsEnhed.produktionsEnhedMetadata"
               ],
               "query": {
                        "term": {
                            "VrproduktionsEnhed.produktionsEnhedMetadata.nyesteBeliggenhedsadresse.adresseId.keyword": adresseId
                        }
               }
               }, "produktionsenhed");
               let pEnheder = pEnhederResponse?.hits?.hits;
               let res = pEnheder && pEnheder.reduce((result, pe) => {
                  let item = pe._source.VrproduktionsEnhed.produktionsEnhedMetadata;
                  if (item.sammensatStatus === "Aktiv") {
                  return [...result, {
                     navn: item.nyesteNavn.navn,
                     cvrNr: item.nyesteCvrNummerRelation,
                     branchetekst: item.nyesteHovedbranche.branchetekst,
                     branchekode: item.nyesteHovedbranche.branchekode,
                     kontaktoplysninger: item.nyesteKontaktoplysninger,
                     beskaeftigelseKvt: item.nyesteKvartalsbeskaeftigelse,
                     beskaeftigelseAar: item.nyesteAarsbeskaeftigelse
                  }]
               } else {
                  return result;
               }
               }, []);
               return {
                  adresseData: {navn: adresseTekst, id:adresseId},
                  pEnheder: res
               };
            }));
            return {
               matrikelData: {navn: matrikelTekst},
               adresser: resAdresser
            }
      })
      );

      // ToDo: Prune empty branches (not done above due to complexities with promises and 'reduce')

      return resMatrikler;
   }

   static async PEnhederforCVR(cvrNr:number) :Promise<any> {

      let pEnhederResponse = await PropertyInfoInterfaceCVR.getCVRSearch({
         "size":500,
         "_source": [
               "VrproduktionsEnhed.produktionsEnhedMetadata"
         ],
         "query": {
                  "term": {
                        "VrproduktionsEnhed.produktionsEnhedMetadata.nyesteCvrNummerRelation": cvrNr
                  }
         }
         }, "produktionsenhed");
         let pEnheder = pEnhederResponse?.hits?.hits;
         let res = pEnheder && pEnheder.reduce((result, pe) => {
            let item = pe._source.VrproduktionsEnhed.produktionsEnhedMetadata;
            if (item.sammensatStatus === "Aktiv") {
            return [...result, {
               id : pe._id,
               navn: item.nyesteNavn.navn,
               cvrNr: item.nyesteCvrNummerRelation,
               branchetekst: item.nyesteHovedbranche.branchetekst,
               branchekode: item.nyesteHovedbranche.branchekode,
               kontaktoplysninger: item.nyesteKontaktoplysninger,
               beskaeftigelseKvt: item.nyesteKvartalsbeskaeftigelse,
               beskaeftigelseAar: item.nyesteAarsbeskaeftigelse,
               adresse: PropertyInfoInterfaceCVR.formatAddress(item.nyesteBeliggenhedsadresse)
            }]
         } else {
            return result;
         }
         }, []);
         return res;
   }

   static async CVRPersonDeltagerByEnhedsNummer(enhedsNummer:number) :Promise<any> {
      let deltagerResponse = await PropertyInfoInterfaceCVR.getCVRSearch({
         "size":10,
         "query": {
            "bool": {
               "must": [
                  {
                     "match": {
                        "Vrdeltagerperson.enhedstype": "PERSON"
                     }
                  },
                  {
                     "term": {
                        "Vrdeltagerperson.enhedsNummer": enhedsNummer
                     }
                  }
               ]
            }
         }
         }, "deltager");
         let deltagere = deltagerResponse?.hits?.hits;
         if (!deltagere || deltagere.length != 1) {
            throw new Error("Unexpected result count. 1 expected. Got: "+deltagere?.length);
         }
         let res = deltagere && deltagere.reduce((result, del) => {
            return del._source.Vrdeltagerperson;
         }, []);
         return res;
   }

   static async CVRPersonDeltagerByNameAndAddress(name:string, street:string, houseNo:number, zipCode:number, countryCode:string="DK") :Promise<any> {
      let deltagerResponse = await PropertyInfoInterfaceCVR.getCVRSearch({
         "size":10,
          "query": {
          "bool": {
          "must": [
             {
          "match": {
          "Vrdeltagerperson.enhedstype" : "PERSON"
          }
          },
          {
          "match_phrase": {
             "Vrdeltagerperson.navne.navn" : name
          }
          },
          {
          "match": {
             "Vrdeltagerperson.deltagerpersonMetadata.nyesteBeliggenhedsadresse.vejnavn" : street
          }
          },
          {
          "term": {
             "Vrdeltagerperson.deltagerpersonMetadata.nyesteBeliggenhedsadresse.husnummerFra" : houseNo
          }
          },
          {
          "term": {
             "Vrdeltagerperson.deltagerpersonMetadata.nyesteBeliggenhedsadresse.postnummer" : zipCode
          }
          },
          {
          "match": {
             "Vrdeltagerperson.deltagerpersonMetadata.nyesteBeliggenhedsadresse.landekode" : countryCode
          }
          }
          ],
          "must_not": [
          {
              "exists": {
               "field": "Vrdeltagerperson.navne.periode.gyldigTil"
             }
          }
          ]
          }
          }
         }, "deltager");
         let deltagere = deltagerResponse?.hits?.hits;
         if (!deltagere || deltagere.length != 1) {
            console.error("Unexpected result count. 1 expected. Got: "+deltagere?.length);
            return [];
         }
         let res = deltagere && deltagere.reduce((result, del) => {
            return del._source.Vrdeltagerperson;
         }, []);
         return res;
   }

   /**
    * Returns all adresses for a property
    * 
    * @param ejerlavkode 
    * @param matrikelNr 
    * @see https://docs.dataforsyningen.dk/#get__adresser
    * @returns 
    */
   static async AdresserFraEjerlavOgMatrikel(ejerlavkode:number, matrikelNr:string):Promise<any> {
      let qs = {
         "ejerlavkode": ejerlavkode,
         "matrikelnr": matrikelNr,
         "status": 1, // gældende. 
         "format": "json"
      };

      const qstr = querystring.stringify(qs);

      let path = "/adresser?"+qstr;
      let options = {
         hostname: 'api.dataforsyningen.dk',
         port: 443,
         path: path,
         method: 'GET',
      };

      let res = await this.sendRequest(options);

      return res;
  }

   private static createMatrikelGSearchUrl(ejerlavMatrikelSetObject: { ejerlavKode: number, matrikelNr: string }): string {
      const token = SettingsManager.getSystemSetting("dataforsyningenToken", "");
      let url = "https://api.dataforsyningen.dk/rest/gsearch/v2.0/matrikel?token=" + token +
         `&q=${ejerlavMatrikelSetObject.matrikelNr}`+
         `&limit=20`+
         `&filter=ejerlavskode='${ejerlavMatrikelSetObject.ejerlavKode}'`;
      return url;

   }

   static async getGeojsonOfCadasters(ejerlavMatrikelSetList: { ejerlavKode: number, matrikelNr: string }[]): Promise<any> {
      const gSearchUrlList = ejerlavMatrikelSetList.map(ejerlavMatrikelSet => this.createMatrikelGSearchUrl(ejerlavMatrikelSet));
      let gSearchResults = await Promise.all(gSearchUrlList.map(async gSearchUrl => {
         let tries = 3;
         while (tries > 0) {
            let resp = await fetch(gSearchUrl);
            if (resp.status === 200) {
               return resp.json();
            }
            await new Promise(aa => setTimeout(aa, 200));
            tries--;
         }
         throw new Error("Cound't get matrikel");
      }));
      
      const validResults = trimmedGSearchResult(gSearchResults)
      if (validResults.length) {
         return convertGsearchToGeoJson(validResults);
      } else {
         throw new Error("Invalid geosearch result");
      }


      function trimmedGSearchResult(gSearchResult: any): any[] {
         return gSearchResult.map((PossibleCadaster, idx) => {
            return (PossibleCadaster).find((SingleCadaster) => {
               return (ejerlavMatrikelSetList[idx].ejerlavKode.toString() === SingleCadaster.ejerlavskode.toString()
                  && ejerlavMatrikelSetList[idx].matrikelNr.toString() === SingleCadaster.matrikelnummer.toString());
            })
         }).filter(a => a) 
      }

      function convertGsearchToGeoJson(gSearchResults:any[]) {
         let features = gSearchResults.map((feature) => {
            let geometry = feature.geometri.coordinates.map((poly) => {
               return poly.map(coordList => coordList.map(coord => GenerateGeom.convertFromUTMtoLatLng2(coord).reverse()))
            })
            return {
               "type": "Feature",
               "properties": {
                  "Matrikelnummer": feature.matrikelnummer,
                  "Ejerlavskode": feature.ejerlavskode,
                  "Ejerlavsnavn": feature.ejerlavsnavn,
               },
               "geometry": {...feature.geometri, "coordinates": geometry}
            };
         });
         return {
            "type":"FeatureCollection",
            "features": features
         }; ;
      }
   }

   static gSearch(type:string, queryString:string, limit?:number, filter?:string):Promise<any[]> {
      return new Promise<any[]>(async(resolve, reject) => {
         if (!(['adresse','husnummer','kommune','matrikel','matrikel_udgaaet', 'navngivenvej', 'opstillingskreds','politikreds','postnummer','region','retskreds','sogn','stednavn'].includes(type))) {
            reject(`Unsupported gsearch type: ${type}`);
         }
         const token = SettingsManager.getSystemSetting("dataforsyningenToken", "");

         let qString = queryString.replace(/\s*,/,",").replace(/^,\s?/,"")
         let gSearchUrl = `https://api.dataforsyningen.dk/rest/gsearch/v2.0/${type}?token=${token}&q=${qString}&srid=4326`;
         if (limit) {
            gSearchUrl += `&limit=${limit}`;
         }
         if (filter) {
            gSearchUrl += `&filter=${filter}`;
         }
         try {
            let resp = await fetch(gSearchUrl);
            if (resp.status === 200) {
               let json = await resp.json();
               resolve(json);
            } else {
               reject(`Error getting adresses. Code ${resp.status}: ${resp.statusText}`);
            }
         } catch (err: any) {
            reject(err?.msg ?? err)
         }
      })
   }



   static async getGeojsonOfSFE(bfeNr: number) {
      return await PropertyInfoInterface.fetchMat2CadasterWFSForSFE(bfeNr);
   }

   static async getGeojsonOfVurd(bfeNr: number): Promise<any> {
      const ejendomsVurderingList = await this.vurdLookupByBfeNr(bfeNr.toString());

      if (ejendomsVurderingList.length) {
         const latestEjendomsVurdering = ejendomsVurderingList.reduce((prev, current) => (prev.år > current.år) ? prev : current);
         return getGeojsonOfSeveralSFE(latestEjendomsVurdering.BFEnummerList);
      } else {
         return this.getGeojsonOfSFE(bfeNr);
      }


      async function getGeojsonOfSeveralSFE(bfeNrList: number[]) {
         let geojson = {
            features: []
         };
         await Promise.all(bfeNrList.map(async bfeNummer => {
            return PropertyInfoInterface.getGeojsonOfSFE(bfeNummer)
               .then(geojsonResult => {
                  geojson.features = geojson.features.concat(geojsonResult.features);
               });
         }));
         return geojson;
      }
   }

   /**
    * Autocomplete funktion til søgning i ejerlav.
    * @param ejerlav Ejerlav som enten navn eller kode. 
    * @returns En liste over foreslag af ejerlav for det givne ejerlavsnavn eller kode.
    * @documentation https://dawadocs.dataforsyningen.dk/dok/api/ejerlav#s%C3%B8gning
    * @eksempel https://api.dataforsyningen.dk/ejerlav?&autocomplete&q=381
    */
   static async ejerlavAutocomplete(ejerlav: string | number): Promise<any[]> {

      if (typeof ejerlav === "number") {
         ejerlav = ejerlav.toString();
      }



      return await fetch("https://api.dataforsyningen.dk/ejerlav?autocomplete&q=" + ejerlav)
         .then(result => result.json())
         .catch(error => {
            console.log("Error", error);
            return [];
         });
   }


   static async getEjerlavMedKode(ejerlavskode:number): Promise<any> {
      if (ejerlavskode) {
         return await fetch("https://api.dataforsyningen.dk/ejerlav?kode=" + ejerlavskode)
         .then(result => result.json())
         .catch(error => {
            console.log("Error", error);
            return [];
         });
      } else {
         throw new Error("Ejerlavskode parameter is missing");
      }
      
   }

   /**
 * Henter en liste over alle matrikler (max 1000) for det givne ejerlav ved opslag i søgedatabasen
 * @param ejerlavkode Ejerlavskode
 * @param matrikelNr Evt. matrikel nummer for at indsnævre resultater
 * @returns Liste af matrikelinformation
 * @documentation https://dawadocs.dataforsyningen.dk/dok/api/BBR%20matrikelreference#s%C3%B8gning
 */
   static async getMatrikelListe(ejerlavkode: string | number, matrikelNr?: string): Promise<any> {
      let index = SettingsManager.getSystemSetting("indexNamePropertySearch");
      let es = new ESInterface(index);
      let res = await es.doQuery({
         "_source": [
            "jords_matrikelnr",
            "jords_ejerlavskode"
         ],
         "query": {
            "match_phrase": {
              "jords_ejerlavskode": ""+ejerlavkode
            }
          },
          size:1000
      });
      let hits = res?.hits?.hits;
      let res2 = hits?.map((r) => {
         return {
            MatrNr: r._source.jords_matrikelnr,
               LandsejerlavKode: r._source.jords_ejerlavskode
         }
      });
      return res2;
   }

/**
 * Henter en liste over matrikler med et bestemt prefix for det givne ejerlav.
 * @param ejerlavkode Ejerlavskode
 * @param matrikelNr Matrikel nummer prefix for at indsnævre resultater
 * @returns Liste af matrikelinformation
 */
   static async getMatrikelListeByPrefix(ejerlavkode: number, matrikelNrPrefix: string): Promise<any> {
      let url = this.createMatrikelGSearchUrl({ejerlavKode:ejerlavkode, matrikelNr:matrikelNrPrefix})

      return await fetch(url).then(
         async result => {
            let json = await result.json();
            return json;
         }
      );
   }

   static async getFeatureInfo(url:string) {
      let query = url;
      try {
          let result;
          const response:Response = await fetch(query,{
            mode: "cors", // no-cors, cors, *same-origin
            headers: {
            },
          });
          const resultCode = await response.status;
          if (resultCode === 200) {
            // If needed convert data to json structure.
            const contentTypeStr = response.headers.get("Content-type");
            let contentTypeList = contentTypeStr && contentTypeStr.split(";");
            let contentType = contentTypeList && contentTypeList.length > 0 && contentTypeList[0];
            switch (contentType) {
              case 'text/xml':
                let xml = await response.text();
                let jsonStr = "{}" //TODO:Fix XMLJS.xml2json(xml, {compact: true, spaces: 4});
                let json = JSON.parse(jsonStr);
                let attrs = json.FeatureInfoResponse && json.FeatureInfoResponse.FIELDS && json.FeatureInfoResponse.FIELDS._attributes;
                result = {"features":[{"properties":attrs}]};
                break;
              case 'text/plain':
                // Todo: tris works for matrikelkort. Need to be changed for other map types returning text.
                let text = await response.text();
                let lines = text.split(/\r?\n/);
                let attrs2 = {};
                lines.forEach(line => {
                  if (line.includes("=")) {
                    let parts = line.split("=");
                    let key = parts[0].trim();
                    // Strings are quoted with '. For presentation remove ' from strings
                    let val = parts[1] && parts[1].trim().split("'").join("");
                    attrs2[key]=val;
                  }
                });
                result = {"features":[{"properties":attrs2}]};
                break;
            case 'application/json':
                result = await response.json();
                break;
            default:
                let text2 = await response.text();
                throw Utils.createErrorEventObject("unexpected: "+contentTypeStr);
            }  
          } else {
            throw Utils.createErrorEventObject("result code: "+resultCode);
          }
          return result;
      } catch (err:any) {
          throw Utils.createErrorEventObject("MapScreen getFeatureInfo. Error"+err);
      }
    }

   static async ejerAfEjerlejlighed(bfeNummer: number | string) {
      let elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
      let ifx = new ESInterface(elasticIndex);

      try {
         return await ifx.doSearch({"bfe_nr": { title:"BFEnr", filterType:FilterType.String, default:["6"], translationTable:{}}},{"bfe_nr": [bfeNummer.toString()]},1)
         || undefined
      } catch (err) {
         console.error("PropertyInfoInterfaces","ejerAfEjerlejlighed", err)
         return undefined
      }
   }

   static async getPropertiesOwnedByPerson(name:string, zipCode:number, address:string):Promise<any> {
      
      let elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");

      let ifx = new ESInterface(elasticIndex);
      return ifx.doQuery({
          "size":100,
          "query": {
           "bool": {
          "must": [
               {
                "nested": {
                   "path": "ejf_ejere_liste",
                   "query": {
                      "bool": {
                         "must": [
                            {
                               "match_phrase": {
                                  "ejf_ejere_liste.navn":name
                               }
                            },
                            {
                               "term": {
                                  "ejf_ejere_liste.postnr":zipCode
                               }
                            },
                            {
                               "term": {
                                  "ejf_ejere_liste.adresse.keyword":address
                               }
                            }
                         ]
                      }
                   }
                }
             }
             ]
          }
        }
        
      });

   }
  
   private static createSmallBox(loc: UtmCoords) {
      // create a small box around the point (as required by service)
      let offset = 1; // meters (UTM32)
      let sw = { x: loc.x - offset, y: loc.y - offset };
      let ne = { x: loc.x + offset, y: loc.y + offset };
      let BBOX2 = sw.x + "," + sw.y + "," + ne.x + "," + ne.y;
      let WIDTH2 = 1000;
      let HEIGHT2 = 1000;
      let X2 = 500;
      let Y2 = 500;
      return {BBOX2, HEIGHT2, WIDTH2, X2, Y2};
   }


}
