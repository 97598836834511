import { ReferenceGeomFeature_DK_And_Schleswig, ReferenceGeomType_DK_And_Schleswig } from '../common/managers/Types';
import { Utils } from '@viamap/viamap2-common';
import * as RemoveAccents from 'remove-accents';

export class ReferenceGeomSchleswig {

    /**
     * Some municipalities have alias names
     */
    private static municipalityNameAliasList = {
    };

 
    // Cache for reference informartion
    private static refGeom: any = null;
    private static isLoading: boolean = false;

    private static dataUrl = "https://s3.eu-central-1.amazonaws.com/mapit2-data/GeoJSONReferencedFromMapit/ReferenceGeomSchleswig.json";

    // -------------------------- Municipalities -----------------------------------
    static dumpMunicpalityLookupList(): any[] {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.map((feature) => {
            return (
                { 
                    "Code": this.selectCodeElement(feature), 
                    "Name": this.selectNameElement(feature) 
                }
            );
        });
        return (result);
    }

    static selectCodeElement(feature:ReferenceGeomFeature_DK_And_Schleswig):number {
        return feature && feature.properties && feature.properties.Komnr;
    }

    static selectNameElement(feature:ReferenceGeomFeature_DK_And_Schleswig):string {
        return feature && feature.properties && feature.properties.KOMNAVN;
    }

    static lookupByMunicipalityCode(code: number) {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.find((feature) => {
            return this.equalityFuncMunicipalityCode(this.selectCodeElement(feature), code);
        });
        return (result);
    }

    static equalityFuncMunicipalityCode(prop: number, code: number) {
        return (prop.toString() === code.toString());
    }

    static lookupByMunicipalityName(name: string) {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.find((feature) => {
            return this.equalityFuncMunicipalityName(this.selectNameElement(feature), name);
        });
        return (result);
    }

    static equalityFuncMunicipalityName(prop: string, namePossiblyWithAccents: string): boolean {
        let name = RemoveAccents.remove(namePossiblyWithAccents);
        let result = prop.toString().toLowerCase() === name.toString().toLowerCase();
        // Check alias list if not found
        if (!result) {
            let foundKey = Object.keys(ReferenceGeomSchleswig.municipalityNameAliasList).find((key, idx) => {
                let found = key.toString().toLowerCase() === name.toString().toLowerCase();
                if (found) {
                    let alias = ReferenceGeomSchleswig.municipalityNameAliasList[key];
                    return (prop.toString().toLowerCase() === alias.toString().toLowerCase());
                } else {
                    return false;
                }
            });
            result = (foundKey !== undefined);
        }
        return result;
    }

    static hasRefGeoBeenLoaded():boolean {
        return ReferenceGeomSchleswig.refGeom && !ReferenceGeomSchleswig.isLoading;
    }

    static isLoadingReferenceGeo():boolean {
        return ReferenceGeomSchleswig.isLoading;
    }

    static loadReferenceGeo():Promise<void> {
        let res = new Promise<void>((resolve, reject) => {
            if (ReferenceGeomSchleswig.hasRefGeoBeenLoaded()) {
                resolve();
            } else {
                Utils.asyncFetchJsonFromUrl(ReferenceGeomSchleswig.dataUrl)
                .then((data) => {
                    ReferenceGeomSchleswig.refGeom = data;
                    ReferenceGeomSchleswig.isLoading = false;
                    resolve();
                }).catch((err) => {
                    ReferenceGeomSchleswig.isLoading = false;
                    reject("Error loading geojson: "+err.message);
                });
                ReferenceGeomSchleswig.isLoading=true;    
            }
        });
        return res;
    }

    static getReferenceGeo(): ReferenceGeomType_DK_And_Schleswig {
        return this.refGeom;
    }

}
