import * as M from 'mapbox-gl';

export class MapboxCustomControl {
   protected map:M.Map|undefined;
   protected container:HTMLElement|undefined = undefined;
   protected className:string;

   constructor(className:string) {
      this.className=className;
   }

   onAdd(map:M.Map) {
     this.map = map;
     this.container = document.createElement('div');
     this.container.className = this.className;
     this.container.textContent = 'My custom control';
     return this.container;
   }

   onRemove() {
      this.container && this.container.parentNode && this.container.parentNode.removeChild(this.container);
      this.map = undefined;
   }
}

export class MapboxCustomIcon extends MapboxCustomControl {
   protected element:HTMLElement|undefined;
   protected clickHandler:()=>void;

   constructor(className:string, clickHandler:any) {
      super(className);
      this.clickHandler = clickHandler;
   }

   onAdd(map:M.Map) {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = "mit-mapscreen-icon";
      this.element = document.createElement('span');
      this.element.className = "glyphicon glyphicon-menu-hamburger";
      this.element.textContent = '';
      this.container.textContent = '';
      this.container.appendChild(this.element);
      this.container.onclick = this.clickHandler;
      return this.container;
    }
}

//  const myCustomControl = new MapboxCustomControl('mit-background-layer-control'); 
//  map.addControl(myCustomControl,"top-left");
 