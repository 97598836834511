import { useContext, useEffect, useState, Fragment } from "react"
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExploreSearchContext, removeFilter, removeAllFilters, addFilter, FilterSpec, FilterType, FilterValue } from "../states/ExploreSearchState";

import { FaRegTimesCircle} from 'react-icons/fa';
import './ActiveFilters.css';
import { BsCheck, BsChevronRight, BsFillPencilFill, BsTrashFill, BsX } from "react-icons/bs";
import { Localization } from "@viamap/viamap2-common";

export const ActiveFilters = (props:any) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let [open, setOpen] = useState(false)

   function doClear() {
      dispatch(removeAllFilters());
   }

   return (
      <Card style={{maxHeight: (open ? "33%" : "50px"), marginBottom:"9px"}}>
         <Card.Header>
         <span onClick={() => setOpen(!open)} style={{cursor:"pointer"}} >{Localization.getText("Active filters")}<span className='mit-transformer' style={{position:"absolute" ,marginLeft:"8px", transform:"Rotate("+(open?"90deg":"0deg")+")"}}><BsChevronRight /></span> </span>
         <Button className="NSButton" onClick={doClear}>{Localization.getText("Clear filters")}</Button>
         </Card.Header>
         <Card.Body className="ActiveFilterList">
         {/* <div style={{display:"block"}}>{refresh ? "x":"y"}</div> */}
         {Object.keys(state.activeFilters).map((item) => {
            let value = state.activeFilters[item];
            return (
               <Fragment key={item}>
               <ActiveFilterItem
                  item={item}
                  value={value}
               />
               </Fragment>
            )
         })
         }
         </Card.Body>
      </Card>
   )
}

function ActiveFilterItem(props:{item:string, value:FilterValue}): JSX.Element | null {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let [open, setOpen] = useState(false);

   function handleRemovethis(e:any, all:any) {
      if (all === null) {
         dispatch(removeFilter(props.item))
         return
      }
      let active = props.value
      if (active!.length <= 1) {
         dispatch(removeFilter(props.item))
      }
      dispatch(addFilter(props.item, active!.filter((a) => a != all)));
   }

   let specs:FilterSpec = state.selectableFilterList[props.item]
   if (!specs) {
      // Filter item spec not found. Inconsistency. Bail out.
      console.log(`ERROR: ActiveFilterItem() Active filter spec not found for ${props.item}`)
      return null;
   }
   let selected: boolean = Object.keys(state.activeFilters).includes(props.item);

   
   function ValueFormatter(specs:FilterSpec, value:FilterValue) {
      switch (specs.filterType) {
            case FilterType.SingleSelect:
            case FilterType.Select:
            case FilterType.SelectText:
         return (value || [])?.length > 1 ? Localization.getFormattedText("{nr} Active",{nr:value?.length}) :
            (specs.translateOptions ? Localization.getTextSpecificTable(specs.options?.[value?.[0]] || "undefined",specs.translationTable) :
            (specs.options?.[value?.[0]] ?? (value?.[0])))
         // return value.map((a) =>) new Intl.NumberFormat().format(Number(value))
      case FilterType.IntegerRange:
      case FilterType.DateRange:
         return value?.join(" - ");
      case FilterType.String:
         case FilterType.Integer:
         return value
      case FilterType.StaticValue:
      case FilterType.BoundingBox:
      case FilterType.Polygon:
         return "Polygon";
      case FilterType.PolygonFromLayer:
         return "Map layer";
      default:
         return value?.join(" - ")
   }
   }
   return (
      <div className="active-filter-item">
         <div className="Title">{Localization.getTextSpecificTable(specs.title, specs.translationTable)}</div>
         <div className="Value">{ValueFormatter(specs, props.value)}</div>
         {
            !open ? <div className="IconsView"><BsFillPencilFill onClick={() => (setOpen(!open))} /><BsX onClick={(e) => handleRemovethis(e, null)} /></div> :
            <div className="IconsView"><BsCheck onClick={() => (setOpen(!open))} /></div>
         }
         {open ? <div className="Editor FilterList">
            <div className="card-body" style={{paddingTop:"5px"}}>
            <FilterItemS item={props.item} value={props.value} selected={selected} active={state.activeFilters[props.item]} activeOnly={false} />
            </div>
         </div> : null}
      </div>
   )
}



function FilterItem(props:{item:string, selected:boolean, value:FilterSpec, active:FilterValue}) {
   let [open, setOpen] = useState(false)
   let { state, dispatch } = useContext(ExploreSearchContext);

   function handleTrash(e) {
      e.preventDefault();
      e.stopPropagation()
      dispatch(removeFilter(props.item))
   }

   return props.value.hideOnFilterList ? null : (
      <Card>
         <Card.Header onClick={() => setOpen(!open)} >
            {props.value.title}
            <span className='mit-transformer' style={{paddingLeft:"1px", transform:"Rotate("+(open?"90deg":"0deg")+")"}}><BsChevronRight /></span>
            {/* <span ><BsTrashFill onClick={handleTrash} /></span> */}
         </Card.Header>
         <Card.Body>
            <FilterItemS {...props} activeOnly={!open} />
         </Card.Body>
      </Card>
      // <button key={props.item} data-active={props.selected}
      //    onClick={() => {
      //       if (props.selected) {
      //          dispatch(removeFilter(props.item));
      //       } else {
      //          // let spec = state.selectableFilterList[props.item];  
      //          dispatch(addFilter(props.item, undefined));
      //       };
      //    }}
      // >
      //    {props.value.title} 
      // </button>
   );
}

function FilterItemS(props:{ item, value, selected, active, activeOnly }) {
   let { item, value, selected, active, activeOnly } = props; // as {key:string, value:string}
   let [textValue, setTextValue] = useState<string>(value);
   let { state, dispatch } = useContext(ExploreSearchContext);

   let spec: FilterSpec = state.selectableFilterList[item];
   // check the type of the filter
   switch(spec.filterType) {
      case FilterType.SingleSelect:
         return (
            <FilterItemSSelect item={item} value={value} spec={spec} active={active} activeOnly={activeOnly} />
         )
      case FilterType.Select:
      case FilterType.SelectText:
         return (
            <FilterItemSelect item={item} value={value} spec={spec} active={active} activeOnly={activeOnly} />
         );
      case FilterType.IntegerRange:
         return (
            <FilterItemIntegerRange item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.DateRange:
         return (
            <FilterItemDateRange item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.String:
      case FilterType.Integer:
            return (
            <FilterItemSingleValue item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.StaticValue:
      case FilterType.BoundingBox:
            return (
            <>
            <FilterTitleClickable item={item} title={spec.title} />
            {JSON.stringify(value).substring(0,50)}
            </>
         );
      case FilterType.Polygon:
      case FilterType.PolygonFromLayer:
            return( <></> );
      // case FilterType.String:            
      //    return (
      //          <Form>
      //             <Form.Group>
      //                <Form.Label onClick={() => {
      //                   dispatch(removeFilter(item));
      //                }}>{item}</Form.Label>{" "}
      //                <Form.Control size="sm" value={textValue} style={{ display: "inline-block", maxWidth: "5vw" }} onChange={(e) => { setTextValue( e.target.value) }} onBlur={(e) => { setValue(item, e.target.value) }} />
      //             </Form.Group>
      //          </Form>
      //       );
      //    }
      default:
         return (
            <div>Undexpected filter type {spec.filterType}</div>
         );
   }
}



const FilterTitleClickable = (props:{item:string, title:string}) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   const {item, title} = props;
   let placement="top";
   return (
      <div style={{display:"inline-block"}}>
         <OverlayTrigger
            key={placement}
          overlay={
         <Tooltip id={`tooltip-${placement}`}>
           Tryk for at fjerne dette filter
         </Tooltip>
       }
     >
   <Button size="sm" variant="light" onClick={() => {dispatch(removeFilter(item));}}>
            <FaRegTimesCircle style={{verticalAlign: "baseline"}}/>{' '}{title}
   </Button>
     </OverlayTrigger>
   </div>
   );
}


const FilterItemSSelect = (props:{item:string, value:string, spec:FilterSpec, active:FilterValue, activeOnly?:boolean}) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, active, activeOnly} = props;
   let val = state.activeFilters[item] || [];

   function setValue(item: string, value: any) {
      if ((active as string[])?.includes(value)) {
         dispatch(removeFilter(item))
      } else {
         dispatch(addFilter(item, [value]))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}> 
         <Form.Group className="long">
         {spec.options && Object.keys(spec.options).map((key) => {
               let text = spec.options && spec.options[key];
               if (activeOnly && !((active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" checked={!!((active as string[])?.includes(key))} onChange={(e) => {setValue(item, key)}} label={text} />
                  );
               })}
         </Form.Group>
      </Form>
   );
}


const FilterItemSelect = (props:{item:string, value:string, spec:FilterSpec, active:FilterValue, activeOnly?:boolean}) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, active, activeOnly} = props;
   let val = state.activeFilters[item] || [];

   const groups = {
      "0":"Helårsbeboelse", //"Bygninger til Helårsbeboelse",
      "210":"Landbrug, gartneri, skovbrug og fiskeri", //"Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, skovbrug og fiskeri",
      "220":"Industri og værksteder", //"Bygninger til erhvervsmæssig produktion vedrørende industri og værksteder",
      "230":"Energiproduktion og energidistribution", //"Bygninger vedrørende energiproduktion og energidistribution",
      "310":"Transport og parkering", //"Bygninger i forbindelse med transport og parkering",
      "320":"Kontor, handel og lager", //"Bygninger i forbindelse med kontor, handel og lager",
      "330":"Hotel, restaurant og øvrige serviceerhverv", //"Bygninger i forbindelse med hotel, restaurant og øvrige serviceerhverv",
      "410":"Biograf, bibliotek og trosudøvelse", //"Bygninger i forbindelse med biograf, bibliotek og trosudøvelse",
      "420":"Undervisning og forskning", //"Bygninger i forbindelse med undervisning og forskning",
      "430":"Hospitaler, hospicer og lægehuse", //"Bygninger i forbindelse med hospitaler, hospicer og lægehuse",
      "440":"Dag- og døgninstitutioner", //"Bygninger i forbindelse med dag- og døgninstitutioner",
      "510":"Fritidsformål og idræt", //"Bygninger til fritidsformål og idræt",
      "910":"Garageformål, ophold og opbevaring samt faldefærdig bygning", //"Mindre bygninger til garageformål, ophold og opbevaring samt faldefærdig bygning",
      "1000":"test"
   } as const
   let groupList = Object.keys(groups).map((a) => parseInt(a))

   function setValue(item: string, value: any) {

      if ((active as string[])?.includes(value)) {
         if (active!.length <= 1) {
            dispatch(removeFilter(item))
         }
         dispatch(addFilter(item, active!.filter((a) => a != value)));
      } else {
         dispatch(addFilter(item, [...(active || []), value]))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   if (spec.title === "Building Usage") {
      return (<>{Object.keys(groups).map((a, i) => {
         if (a === "1000") {
            return null
         }
         return (
            <Fragment key={a}>
               <BuildingUsageGroup item={props.item} value={value} spec={props.spec} setValue={setValue} active={props.active} activeOnly={props.activeOnly} min={groupList[i]} max={groupList[i+1]} groups={groups} />
            </Fragment>
         )
      }) }</>)
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}> 
         <Form.Group className="long">
         {spec.options && Object.keys(spec.options).map((key) => {
               let text = spec.options && spec.options[key];
               if ((!open || props.activeOnly) && !((props.active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" checked={!!((props.active as string[])?.includes(key))} onChange={(e) => {setValue(props.item, key)}} label={text} />
                  );
               })}
         </Form.Group>
      </Form>
   );
}

function BuildingUsageGroup (props:{item:string, value:string, spec:FilterSpec, setValue:(a,b) => void ,active:FilterValue, activeOnly?:boolean, min:number, max:number, groups:any}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let [open,setOpen] = useState(false)

   let allInGroup = (Object.keys((props.spec?.options || {})).filter((a) => parseInt(a) >= props.min && parseInt(a) < props.max) || [])
   let activeInGroup = (props.active?.filter((a) => a >= props.min && a < props.max) || [])
   let inActiveInGroup = (allInGroup.filter((a) => !!!activeInGroup.includes(a)))
   if (props.activeOnly && activeInGroup.length === 0) {
      return null
   }
   let spec = props.spec

   function ClickCheck() {
      if (inActiveInGroup.length > 0) {
         dispatch(addFilter(props.item, [...(props.active || []), ...inActiveInGroup]))
      } else {
         dispatch(addFilter(props.item, [...(props.active || []).filter((a) => !!!allInGroup.includes(a))]))
      }
   }


   return (
      <Card>
         <Card.Header style={{marginTop:"2px", fontFamily:"var(--readingFont)",fontWeight:"400", textTransform:"unset" ,fontSize:"14px", zIndex:"0"}}>
         <Form.Check type="checkbox" checked={activeInGroup.length > 0} onChange={(e) => {ClickCheck()}} />
         <div onClick={() => setOpen(!open)}>{props.groups[props.min.toString()]}<span className='mit-transformer' style={{position:"absolute" ,marginLeft:"8px", transform:"Rotate("+(open?"90deg":"0deg")+")"}}><BsChevronRight /></span></div>
         </Card.Header>
         { ((!open || props.activeOnly) && activeInGroup.length < 0) ? null :
         <Card.Body style={{paddingLeft:"12px"}}>
            {spec.options && Object.keys(spec.options).filter((a) => parseInt(a) >= props.min && parseInt(a) < props.max).map((key) => {
               let text = spec.options && spec.options[key];
               if ((!open || props.activeOnly) && !((props.active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" style={{fontWeight:"300"}} checked={!!((props.active as string[])?.includes(key))} onChange={(e) => {props.setValue(props.item, key)}} label={text} />
                  );
               })}
         </Card.Body>
         }
      </Card>
   )
}

const FilterItemIntegerRange = (props:{item:string, value:string, spec:FilterSpec, activeOnly:boolean}) => {

   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [minValue, setMinValue] = useState<string>((val && val[0]) || "");
   let [maxValue, setMaxValue] = useState<string>((val && val[1]) || "");

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   function handleTrash(item, value) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
      if (value[0] || value[1]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }
   
   function handleMinValue(item: string, value:any) {
      console.log(value)
      setValue(item, [value, maxValue])
      setMinValue(value)
   }

   function handleMaxValue(item: string, value:any) {
      console.log(value)
      setValue(item, [minValue, value])
      setMaxValue(value)
   }

   if (activeOnly && (!!!val || val.length < 1 || !val.some((a) => !!a))) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         {activeOnly && !!!minValue ? null : <Form.Group className={!!!minValue ?"":"hV"}>
            <Form.Control  size="sm" value={minValue} style={{ display: "inline-block", maxWidth: "5vw" }} onChange={(e) => { handleMinValue(props.item ,e.target.value) }}  />
         <BsTrashFill onClick={(e) => handleTrash(item,["",maxValue])} />
         </Form.Group>}
            {/* <span style={{float:"left"}}>{" < "}</span> */}
            {activeOnly && !!!maxValue ? null : <Form.Group className={!!!maxValue ?"":"hV"}>
            <Form.Control  size="sm" value={maxValue} style={{ display: "inline-block", maxWidth: "5vw" }} onChange={(e) => { handleMaxValue(props.item ,e.target.value) }}  />
         <BsTrashFill onClick={(e) => handleTrash(item,[minValue,""])} />
         </Form.Group>}
      </Form>
   );
}

const FilterItemDateRange = (props:{item:string, value:string, spec:FilterSpec, activeOnly}) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [minValue, setMinValue] = useState<string>((val && val[0]) || "");
   let [maxValue, setMaxValue] = useState<string>((val && val[1]) || "");

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   function handleMinValue(item: string, value:any) {
      console.log(value)
      setValue(item, [value, maxValue])
      setMinValue(value)
   }

   function handleTrash(item, value) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
      if (value[0] || value[1]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }

   function handleMaxValue(item: string, value:any) {
      console.log(value)
      setValue(item, [minValue, value])
      setMaxValue(value)
   }

   if (activeOnly && (!!!val || val.length < 1 || !val.some((a) => !!a))) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         {activeOnly && !!!minValue ? null: <Form.Group className={!!!minValue ? "":"hV"}>
            <Form.Control className={!!!minValue ? "":"hV"} type="date" size="sm" value={minValue} style={{ display: "inline-block", maxWidth: "10vw" }} onChange={(e) => { handleMinValue(item, e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,["",maxValue])} />
            </Form.Group>}
            {/* <span style={{float:"left"}}>{" < "}</span> */}
         {activeOnly && !!!maxValue ? null: <Form.Group className={!!!maxValue ? "":"hV"}>
            <Form.Control className={!!!maxValue ? "":"hV"} type="date" size="sm" value={maxValue} style={{ display: "inline-block", maxWidth: "10vw" }} onChange={(e) => { handleMaxValue(item, e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,[minValue,""])} />
         </Form.Group>}
      </Form>
   );
}

const FilterItemSingleValue= (props:{item:string, value:string, spec:FilterSpec, activeOnly:boolean}) => {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [fieldValue, setFieldValue] = useState<string>((val && val[0]) || "");

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   function handleTrash(item, value) {
      setFieldValue(value[0]);
      if (value[0]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         <Form.Group className={!!!val ? "":"hV"} onBlur={(e) => { setValue(item, [fieldValue]) }}>
            <Form.Control size="sm" value={fieldValue} style={{ display: "inline-block", maxWidth: "8vw" }} onChange={(e) => { setFieldValue( e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,[""])} />
         </Form.Group>
      </Form>
   );
}
