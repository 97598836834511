import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import "../css/PersonInfoStyle.css"
import { AppMessagesContext, Cookies, Localization, actionSetInfoMessage } from "@viamap/viamap2-common";
import { FiCopy, FiPrinter } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { usePanWindow } from "src/common/components/CustomHooks";
import { AddressInterface } from "src/managers/AddressInterface";
import { ProjectContext, setInfo } from "src/states/ProjectState";
import { ProtectedFeature } from "./ProtectedFeature";
import { PersonInfoView } from "./PersonInformationView";
import { ViewButtonCheck, ViewButtonIcon } from "src/propertyInfoTemplates/PropertyComponents";
import { PiGraphFill } from "react-icons/pi";
import { actionSetShowWindow, MapitStateContext, MapitWindowId } from "src/states/MapitState";

type props = {
  showWindow: number;
  callBackOnClose: () => void;
  personInfo: any
}

export function PersonInformationDialog(props: props) {
  const [personDefaultCollapsed, setPersonDefaultCollapsed] =
  React.useState(Cookies.getCookieBoolean("PersonAutoColapsed"));
const { dispatch: mapitStateDispatch } = React.useContext(MapitStateContext)
const { hasAccessToFeature } = React.useContext(ApplicationStateContext);
const { dispatch: appMessageDispatch } = React.useContext(AppMessagesContext);
const { state: projState, dispatch: projDispatch } =
  React.useContext(ProjectContext);

const [isLoading, setIsLoading] = React.useState(true);

React.useEffect(() => {
  setIsLoading(true);
}, [props.personInfo]);

if (!props.showWindow) {
  return;
}

function RemberCollapseSettings(val) {
  Cookies.setCookieBoolean("PersonAutoColapsed", !val);
}

let fold = (
  <ViewButtonCheck
  className={"no-print"}
  checked={!personDefaultCollapsed} 
  onCheck={(e, val) => {RemberCollapseSettings(val); setPersonDefaultCollapsed(!val)}}
>
  {Localization.getText("Expanded")}
</ViewButtonCheck>
);

let closeButton = (
  <MdClose
    className="CloseButton no-print"
    onClick={() => props.callBackOnClose()}
  />
);

return (
  <AdvancedDragModal
    show={props.showWindow}
    style={{ zIndex: 1000 + props.showWindow, isolation: "isolate" }}
    className="mit-person-information Flat-Modal MitNotInfoFix"
    headerContent={
      <>
        {/* <div style={{marginBottom:"120px"}}> */}
        <h4 className="no-print">Personinformation</h4>
        {/* <PrintHeader/> */}
        {/* </div> */}
        <ProtectedFeature
          feature={Feature.OpenPersonInfoDocument}
          contentsIfNoAccess={<></>}
        >
          <ViewButtonIcon
            className="no-print"
            onClick={() => {
              // ToDo:
            }
            //   window.open(
            //     `/Personinfo/?name=${props.props.name},dateOfBirth=${props.props.dateOfBirth}`,
            //     "_blank"
            //   )
            }
            title={Localization.getText("Popout")}
          >
            <FiCopy />
          </ViewButtonIcon>
        </ProtectedFeature>
        <ProtectedFeature
          feature={Feature.PrintPersonInfo}
          contentsIfNoAccess={<></>}
        >
          <>
            {!isLoading ? (
              <ViewButtonIcon
                className="no-print"
                onClick={() => {
                  print();
                }}
                title={Localization.getText("Print")}
              >
                <FiPrinter />
                {/* {Localization.getText("Print")} */}
              </ViewButtonIcon>
            ) : (
              <Spinner size="sm" />
            )}
          </>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
          <ViewButtonIcon 
          title={Localization.getText("Show ownership graph")}
          className="no-print"
          onClick={() => {
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.OwnerShipDiagram, true, {personInfo: props.personInfo}))
          }}
          >
            <PiGraphFill />
          </ViewButtonIcon>
        </ProtectedFeature>
        {fold}
        {closeButton}
      </>
    }
  >
    {/* <div className="mit-printable-modal" > */}
    {/* <PrintHeader/> */}

    {(props.personInfo && (
      <Modal.Body
        style={{
          overflow: "hidden",
          display: "grid",
          alignContent: "start",
        }}
      >
        <PersonInfoView
          key={"peView"}
          foldetUd={!personDefaultCollapsed}
          cols={hasAccessToFeature(Feature.ThemeNewSec) ? 2 : 1}
          personInfo={props.personInfo}
          callBackOnLoaded={(a) => {
            setIsLoading(false);
          }}
        />
      </Modal.Body>
    )) ||
      "Missing Info"}
  </AdvancedDragModal>
);
}

function AdvancedDragModal(props: {
show;
className;
headerContent;
children;
style;
}) {
let [eventer, setRef, clickEventer, setOpen] = usePanWindow(0);

React.useEffect(() => {
  setOpen({});
}, [props.show]);

return (
  <Modal.Dialog style={props.style} className={props.className} ref={setRef}>
    <Modal.Header onMouseDown={eventer} onTouchStart={eventer}>
      <div className="GrabLines" onMouseDown={clickEventer} />
      {props.headerContent}
    </Modal.Header>
    {props.children}
  </Modal.Dialog>
);
}