import {ReferenceGeomType} from '../common/managers/Types';
import { Utils } from '@viamap/viamap2-common';
import { ReferenceGeomRomania } from './ReferenceGeomRomania';
import { ReferenceGeomWorld } from './ReferenceGeomWorld';

export class ReferenceGeomDenmark {
    // Cache for reference informartion
    private static refGeom:any = null;
    private static isLoading:boolean = false;
    private static dataUrl = "https://s3.eu-central-1.amazonaws.com/mapit2-data/GeoJSONReferencedFromMapit/ReferenceGeomWCopenhagen.json";

    /**
     * Some municipalities have alias names
     */
    private static municipalityNameAliasList = {
        "Høje-Taastrup":"Høje Taastrup", 
        "Vesthimmerland": "Vesthimmerlands"
    };
     
    /**
     * This is the list of municipalities in the ReferenceGeom. 
     * Used to be able to validate names without having to import the polygons
     * This list can be re-generated by the 'dumpMunicpalityLookupList()' function.
     */
    public static municipalityLookupList =       
    [ { Komnr: 101, KOMNAVN: 'København' },

    { Komnr: 147, KOMNAVN: 'Frederiksberg' },

    { Komnr: 151, KOMNAVN: 'Ballerup' },

    { Komnr: 153, KOMNAVN: 'Brøndby' },

    { Komnr: 155, KOMNAVN: 'Dragør' },

    { Komnr: 157, KOMNAVN: 'Gentofte' },

    { Komnr: 159, KOMNAVN: 'Gladsaxe' },

    { Komnr: 161, KOMNAVN: 'Glostrup' },

    { Komnr: 163, KOMNAVN: 'Herlev' },

    { Komnr: 165, KOMNAVN: 'Albertslund' },

    { Komnr: 167, KOMNAVN: 'Hvidovre' },

    { Komnr: 169, KOMNAVN: 'Høje Taastrup' },
    { Komnr: 169, KOMNAVN: 'Høje-Taastrup' }, // Manually added alias

    { Komnr: 173, KOMNAVN: 'Lyngby-Taarbæk' },

    { Komnr: 175, KOMNAVN: 'Rødovre' },

    { Komnr: 183, KOMNAVN: 'Ishøj' },

    { Komnr: 185, KOMNAVN: 'Tårnby' },

    { Komnr: 187, KOMNAVN: 'Vallensbæk' },

    { Komnr: 190, KOMNAVN: 'Furesø' },

    { Komnr: 201, KOMNAVN: 'Allerød' },

    { Komnr: 210, KOMNAVN: 'Fredensborg' },

    { Komnr: 217, KOMNAVN: 'Helsingør' },

    { Komnr: 219, KOMNAVN: 'Hillerød' },

    { Komnr: 223, KOMNAVN: 'Hørsholm' },

    { Komnr: 230, KOMNAVN: 'Rudersdal' },

    { Komnr: 240, KOMNAVN: 'Egedal' },

    { Komnr: 250, KOMNAVN: 'Frederikssund' },

    { Komnr: 253, KOMNAVN: 'Greve' },

    { Komnr: 259, KOMNAVN: 'Køge' },

    { Komnr: 260, KOMNAVN: 'Halsnæs' },

    { Komnr: 265, KOMNAVN: 'Roskilde' },

    { Komnr: 269, KOMNAVN: 'Solrød' },

    { Komnr: 270, KOMNAVN: 'Gribskov' },

    { Komnr: 306, KOMNAVN: 'Odsherred' },

    { Komnr: 316, KOMNAVN: 'Holbæk' },

    { Komnr: 320, KOMNAVN: 'Faxe' },

    { Komnr: 326, KOMNAVN: 'Kalundborg' },

    { Komnr: 329, KOMNAVN: 'Ringsted' },

    { Komnr: 330, KOMNAVN: 'Slagelse' },

    { Komnr: 336, KOMNAVN: 'Stevns' },

    { Komnr: 340, KOMNAVN: 'Sorø' },

    { Komnr: 350, KOMNAVN: 'Lejre' },

    { Komnr: 360, KOMNAVN: 'Lolland' },

    { Komnr: 370, KOMNAVN: 'Næstved' },

    { Komnr: 376, KOMNAVN: 'Guldborgsund' },

    { Komnr: 390, KOMNAVN: 'Vordingborg' },

    { Komnr: 400, KOMNAVN: 'Bornholm' },

    { Komnr: 410, KOMNAVN: 'Middelfart' },

    { Komnr: 411, KOMNAVN: 'Christiansø' },

    { Komnr: 420, KOMNAVN: 'Assens' },

    { Komnr: 430, KOMNAVN: 'Faaborg-Midtfyn' },

    { Komnr: 440, KOMNAVN: 'Kerteminde' },

    { Komnr: 450, KOMNAVN: 'Nyborg' },

    { Komnr: 461, KOMNAVN: 'Odense' },

    { Komnr: 479, KOMNAVN: 'Svendborg' },

    { Komnr: 480, KOMNAVN: 'Nordfyns' },

    { Komnr: 482, KOMNAVN: 'Langeland' },

    { Komnr: 492, KOMNAVN: 'Ærø' },

    { Komnr: 510, KOMNAVN: 'Haderslev' },

    { Komnr: 530, KOMNAVN: 'Billund' },

    { Komnr: 540, KOMNAVN: 'Sønderborg' },

    { Komnr: 550, KOMNAVN: 'Tønder' },

    { Komnr: 561, KOMNAVN: 'Esbjerg' },

    { Komnr: 563, KOMNAVN: 'Fanø' },

    { Komnr: 573, KOMNAVN: 'Varde' },

    { Komnr: 575, KOMNAVN: 'Vejen' },

    { Komnr: 580, KOMNAVN: 'Aabenraa' },

    { Komnr: 607, KOMNAVN: 'Fredericia' },

    { Komnr: 615, KOMNAVN: 'Horsens' },

    { Komnr: 621, KOMNAVN: 'Kolding' },

    { Komnr: 630, KOMNAVN: 'Vejle' },

    { Komnr: 657, KOMNAVN: 'Herning' },

    { Komnr: 661, KOMNAVN: 'Holstebro' },

    { Komnr: 665, KOMNAVN: 'Lemvig' },

    { Komnr: 671, KOMNAVN: 'Struer' },

    { Komnr: 706, KOMNAVN: 'Syddjurs' },

    { Komnr: 707, KOMNAVN: 'Norddjurs' },

    { Komnr: 710, KOMNAVN: 'Favrskov' },

    { Komnr: 727, KOMNAVN: 'Odder' },

    { Komnr: 730, KOMNAVN: 'Randers' },

    { Komnr: 740, KOMNAVN: 'Silkeborg' },

    { Komnr: 741, KOMNAVN: 'Samsø' },

    { Komnr: 746, KOMNAVN: 'Skanderborg' },

    { Komnr: 751, KOMNAVN: 'Aarhus' },

    { Komnr: 756, KOMNAVN: 'Ikast-Brande' },

    { Komnr: 760, KOMNAVN: 'Ringkøbing-Skjern' },

    { Komnr: 766, KOMNAVN: 'Hedensted' },

    { Komnr: 773, KOMNAVN: 'Morsø' },

    { Komnr: 779, KOMNAVN: 'Skive' },

    { Komnr: 787, KOMNAVN: 'Thisted' },

    { Komnr: 791, KOMNAVN: 'Viborg' },

    { Komnr: 810, KOMNAVN: 'Brønderslev' },

    { Komnr: 813, KOMNAVN: 'Frederikshavn' },

    { Komnr: 820, KOMNAVN: 'Vesthimmerlands' },
    { Komnr: 820, KOMNAVN: 'Vesthimmerland' }, // Manually added alias

    { Komnr: 825, KOMNAVN: 'Læsø' },

    { Komnr: 840, KOMNAVN: 'Rebild' },

    { Komnr: 846, KOMNAVN: 'Mariagerfjord' },

    { Komnr: 849, KOMNAVN: 'Jammerbugt' },

    { Komnr: 851, KOMNAVN: 'Aalborg' },

    { Komnr: 860, KOMNAVN: 'Hjørring' } ];

    /**
     * This is the list of regions in the ReferenceGeom. 
     * Used to be able to validate names without having to import the polygons
     * This list can be re-generated by the 'dumpRegionLookupList()' function.
     */
    private static regionLookupList =    [ { REGIONKODE: '1081', REGIONNAVN: 'Region Nordjylland' },

    { REGIONKODE: '1082', REGIONNAVN: 'Region Midtjylland' },

    { REGIONKODE: '1083', REGIONNAVN: 'Region Syddanmark' },

    { REGIONKODE: '1084', REGIONNAVN: 'Region Hovedstaden' },

    { REGIONKODE: '1085', REGIONNAVN: 'Region Sjælland' } ]
    ;

    /**
     * This is the list of zipCodes in the ReferenceGeom. 
     * Used to be able to validate names without having to import the polygons
     * This list can be re-generated by the 'dumpZipCodeLookupList()' function.
     */
    private static zipCodeLookupList = 
    [
        {
            "PostNummer": 1050,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1051,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1052,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1053,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1054,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1055,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1056,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1057,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1058,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1059,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1060,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1061,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1062,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1063,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1064,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1065,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1066,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1067,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1068,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1069,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1070,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1071,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1072,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1073,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1074,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1100,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1101,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1102,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1103,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1104,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1105,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1106,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1107,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1110,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1111,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1112,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1113,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1114,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1115,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1116,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1117,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1118,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1119,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1120,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1121,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1122,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1123,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1124,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1125,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1126,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1127,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1128,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1129,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1130,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1131,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1150,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1151,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1152,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1153,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1154,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1155,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1156,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1157,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1158,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1159,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1160,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1161,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1162,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1164,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1165,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1166,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1167,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1168,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1169,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1170,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1171,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1172,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1173,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1174,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1175,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1200,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1201,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1202,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1203,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1204,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1205,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1206,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1207,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1208,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1209,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1210,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1211,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1212,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1213,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1214,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1215,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1216,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1218,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1219,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1220,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1221,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1250,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1251,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1252,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1253,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1254,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1255,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1256,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1257,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1259,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1260,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1261,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1263,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1264,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1265,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1266,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1267,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1268,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1270,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1271,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1300,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1301,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1302,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1303,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1304,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1306,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1307,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1308,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1309,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1310,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1311,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1312,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1313,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1314,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1315,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1316,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1317,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1318,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1319,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1320,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1321,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1322,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1323,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1324,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1325,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1326,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1327,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1328,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1329,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1350,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1352,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1353,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1354,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1355,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1356,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1357,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1358,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1359,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1360,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1361,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1362,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1363,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1364,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1365,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1366,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1367,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1368,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1369,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1370,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1371,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1400,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1401,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1402,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1403,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1406,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1407,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1408,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1409,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1410,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1411,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1412,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1413,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1414,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1415,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1416,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1417,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1418,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1419,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1420,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1421,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1422,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1423,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1424,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1425,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1426,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1427,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1428,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1429,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1430,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1432,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1433,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1434,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1435,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1436,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1437,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1438,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1439,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1440,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1441,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1450,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1451,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1452,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1453,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1454,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1455,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1456,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1457,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1458,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1459,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1460,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1461,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1462,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1463,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1464,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1465,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1466,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1467,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1468,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1470,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1471,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1472,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1473,
            "POSTBYNAVN": "København K"
        },
        {
            "PostNummer": 1550,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1551,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1552,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1553,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1554,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1555,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1556,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1557,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1558,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1559,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1560,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1561,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1562,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1563,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1564,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1567,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1568,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1569,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1570,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1571,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1572,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1573,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1574,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1575,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1576,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1577,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1600,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1601,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1602,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1603,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1604,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1605,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1606,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1607,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1608,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1609,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1610,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1611,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1612,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1613,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1614,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1615,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1616,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1617,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1618,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1619,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1620,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1621,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1622,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1623,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1624,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1631,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1632,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1633,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1634,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1635,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1650,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1651,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1652,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1653,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1654,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1655,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1656,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1657,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1658,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1659,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1660,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1661,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1662,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1663,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1664,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1665,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1666,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1667,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1668,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1669,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1670,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1671,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1672,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1673,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1674,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1675,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1676,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1677,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1699,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1700,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1701,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1702,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1703,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1704,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1705,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1706,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1707,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1708,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1709,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1710,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1711,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1712,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1714,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1715,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1716,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1717,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1718,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1719,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1720,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1721,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1722,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1723,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1724,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1725,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1726,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1727,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1728,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1729,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1730,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1731,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1732,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1733,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1734,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1735,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1736,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1737,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1738,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1739,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1749,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1750,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1751,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1752,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1753,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1754,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1755,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1756,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1757,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1758,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1759,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1760,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1761,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1762,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1763,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1764,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1765,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1766,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1770,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1771,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1772,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1773,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1774,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1775,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1777,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1799,
            "POSTBYNAVN": "København V"
        },
        {
            "PostNummer": 1800,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1801,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1802,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1803,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1804,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1805,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1806,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1807,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1808,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1809,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1810,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1811,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1812,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1813,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1814,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1815,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1816,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1817,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1818,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1819,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1820,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1822,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1823,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1824,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1825,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1826,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1827,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1828,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1829,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1850,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1851,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1852,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1853,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1854,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1855,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1856,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1857,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1860,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1861,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1862,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1863,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1864,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1865,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1866,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1867,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1868,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1870,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1871,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1872,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1873,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1874,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1875,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1876,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1877,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1878,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1879,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1900,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1901,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1902,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1903,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1904,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1905,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1906,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1908,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1909,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1910,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1911,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1912,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1913,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1914,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1915,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1916,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1917,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1920,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1921,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1922,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1923,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1924,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1925,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1926,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1927,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1928,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1950,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1951,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1952,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1953,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1954,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1955,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1956,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1957,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1958,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1959,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1960,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1961,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1962,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1963,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1964,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1965,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1966,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1967,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1970,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1971,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1972,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1973,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 1974,
            "POSTBYNAVN": "Frederiksberg C"
        },
        {
            "PostNummer": 2000,
            "POSTBYNAVN": "Frederiksberg"
        },
        {
            "PostNummer": 2100,
            "POSTBYNAVN": "København Ø"
        },
        {
            "PostNummer": 2150,
            "POSTBYNAVN": "Nordhavn"
        },
        {
            "PostNummer": 2200,
            "POSTBYNAVN": "København N"
        },
        {
            "PostNummer": 2300,
            "POSTBYNAVN": "København S"
        },
        {
            "PostNummer": 2400,
            "POSTBYNAVN": "København NV"
        },
        {
            "PostNummer": 2450,
            "POSTBYNAVN": "København SV"
        },
        {
            "PostNummer": 2500,
            "POSTBYNAVN": "Valby"
        },
        {
            "PostNummer": 2600,
            "POSTBYNAVN": "Glostrup"
        },
        {
            "PostNummer": 2605,
            "POSTBYNAVN": "Brøndby"
        },
        {
            "PostNummer": 2610,
            "POSTBYNAVN": "Rødovre"
        },
        {
            "PostNummer": 2620,
            "POSTBYNAVN": "Albertslund"
        },
        {
            "PostNummer": 2625,
            "POSTBYNAVN": "Vallensbæk"
        },
        {
            "PostNummer": 2630,
            "POSTBYNAVN": "Taastrup"
        },
        {
            "PostNummer": 2635,
            "POSTBYNAVN": "Ishøj"
        },
        {
            "PostNummer": 2640,
            "POSTBYNAVN": "Hedehusene"
        },
        {
            "PostNummer": 2650,
            "POSTBYNAVN": "Hvidovre"
        },
        {
            "PostNummer": 2660,
            "POSTBYNAVN": "Brøndby Strand"
        },
        {
            "PostNummer": 2665,
            "POSTBYNAVN": "Vallensbæk Strand"
        },
        {
            "PostNummer": 2670,
            "POSTBYNAVN": "Greve"
        },
        {
            "PostNummer": 2680,
            "POSTBYNAVN": "Solrød Strand"
        },
        {
            "PostNummer": 2690,
            "POSTBYNAVN": "Karlslunde"
        },
        {
            "PostNummer": 2700,
            "POSTBYNAVN": "Brønshøj"
        },
        {
            "PostNummer": 2720,
            "POSTBYNAVN": "Vanløse"
        },
        {
            "PostNummer": 2730,
            "POSTBYNAVN": "Herlev"
        },
        {
            "PostNummer": 2740,
            "POSTBYNAVN": "Skovlunde"
        },
        {
            "PostNummer": 2750,
            "POSTBYNAVN": "Ballerup"
        },
        {
            "PostNummer": 2760,
            "POSTBYNAVN": "Måløv"
        },
        {
            "PostNummer": 2765,
            "POSTBYNAVN": "Smørum"
        },
        {
            "PostNummer": 2770,
            "POSTBYNAVN": "Kastrup"
        },
        {
            "PostNummer": 2791,
            "POSTBYNAVN": "Dragør"
        },
        {
            "PostNummer": 2800,
            "POSTBYNAVN": "Kongens Lyngby"
        },
        {
            "PostNummer": 2820,
            "POSTBYNAVN": "Gentofte"
        },
        {
            "PostNummer": 2830,
            "POSTBYNAVN": "Virum"
        },
        {
            "PostNummer": 2840,
            "POSTBYNAVN": "Holte"
        },
        {
            "PostNummer": 2850,
            "POSTBYNAVN": "Nærum"
        },
        {
            "PostNummer": 2860,
            "POSTBYNAVN": "Søborg"
        },
        {
            "PostNummer": 2870,
            "POSTBYNAVN": "Dyssegård"
        },
        {
            "PostNummer": 2880,
            "POSTBYNAVN": "Bagsværd"
        },
        {
            "PostNummer": 2900,
            "POSTBYNAVN": "Hellerup"
        },
        {
            "PostNummer": 2920,
            "POSTBYNAVN": "Charlottenlund"
        },
        {
            "PostNummer": 2930,
            "POSTBYNAVN": "Klampenborg"
        },
        {
            "PostNummer": 2942,
            "POSTBYNAVN": "Skodsborg"
        },
        {
            "PostNummer": 2950,
            "POSTBYNAVN": "Vedbæk"
        },
        {
            "PostNummer": 2960,
            "POSTBYNAVN": "Rungsted Kyst"
        },
        {
            "PostNummer": 2970,
            "POSTBYNAVN": "Hørsholm"
        },
        {
            "PostNummer": 2980,
            "POSTBYNAVN": "Kokkedal"
        },
        {
            "PostNummer": 2990,
            "POSTBYNAVN": "Nivå"
        },
        {
            "PostNummer": 3000,
            "POSTBYNAVN": "Helsingør"
        },
        {
            "PostNummer": 3050,
            "POSTBYNAVN": "Humlebæk"
        },
        {
            "PostNummer": 3060,
            "POSTBYNAVN": "Espergærde"
        },
        {
            "PostNummer": 3070,
            "POSTBYNAVN": "Snekkersten"
        },
        {
            "PostNummer": 3080,
            "POSTBYNAVN": "Tikøb"
        },
        {
            "PostNummer": 3100,
            "POSTBYNAVN": "Hornbæk"
        },
        {
            "PostNummer": 3120,
            "POSTBYNAVN": "Dronningmølle"
        },
        {
            "PostNummer": 3140,
            "POSTBYNAVN": "Ålsgårde"
        },
        {
            "PostNummer": 3150,
            "POSTBYNAVN": "Hellebæk"
        },
        {
            "PostNummer": 3200,
            "POSTBYNAVN": "Helsinge"
        },
        {
            "PostNummer": 3210,
            "POSTBYNAVN": "Vejby"
        },
        {
            "PostNummer": 3220,
            "POSTBYNAVN": "Tisvildeleje"
        },
        {
            "PostNummer": 3230,
            "POSTBYNAVN": "Græsted"
        },
        {
            "PostNummer": 3250,
            "POSTBYNAVN": "Gilleleje"
        },
        {
            "PostNummer": 3300,
            "POSTBYNAVN": "Frederiksværk"
        },
        {
            "PostNummer": 3310,
            "POSTBYNAVN": "Ølsted"
        },
        {
            "PostNummer": 3320,
            "POSTBYNAVN": "Skævinge"
        },
        {
            "PostNummer": 3330,
            "POSTBYNAVN": "Gørløse"
        },
        {
            "PostNummer": 3360,
            "POSTBYNAVN": "Liseleje"
        },
        {
            "PostNummer": 3370,
            "POSTBYNAVN": "Melby"
        },
        {
            "PostNummer": 3390,
            "POSTBYNAVN": "Hundested"
        },
        {
            "PostNummer": 3400,
            "POSTBYNAVN": "Hillerød"
        },
        {
            "PostNummer": 3450,
            "POSTBYNAVN": "Allerød"
        },
        {
            "PostNummer": 3460,
            "POSTBYNAVN": "Birkerød"
        },
        {
            "PostNummer": 3480,
            "POSTBYNAVN": "Fredensborg"
        },
        {
            "PostNummer": 3490,
            "POSTBYNAVN": "Kvistgård"
        },
        {
            "PostNummer": 3500,
            "POSTBYNAVN": "Værløse"
        },
        {
            "PostNummer": 3520,
            "POSTBYNAVN": "Farum"
        },
        {
            "PostNummer": 3540,
            "POSTBYNAVN": "Lynge"
        },
        {
            "PostNummer": 3550,
            "POSTBYNAVN": "Slangerup"
        },
        {
            "PostNummer": 3600,
            "POSTBYNAVN": "Frederikssund"
        },
        {
            "PostNummer": 3630,
            "POSTBYNAVN": "Jægerspris"
        },
        {
            "PostNummer": 3650,
            "POSTBYNAVN": "Ølstykke"
        },
        {
            "PostNummer": 3660,
            "POSTBYNAVN": "Stenløse"
        },
        {
            "PostNummer": 3670,
            "POSTBYNAVN": "Veksø Sjælland"
        },
        {
            "PostNummer": 3700,
            "POSTBYNAVN": "Rønne"
        },
        {
            "PostNummer": 3720,
            "POSTBYNAVN": "Aakirkeby"
        },
        {
            "PostNummer": 3730,
            "POSTBYNAVN": "Nexø"
        },
        {
            "PostNummer": 3740,
            "POSTBYNAVN": "Svaneke"
        },
        {
            "PostNummer": 3751,
            "POSTBYNAVN": "Østermarie"
        },
        {
            "PostNummer": 3760,
            "POSTBYNAVN": "Gudhjem"
        },
        {
            "PostNummer": 3770,
            "POSTBYNAVN": "Allinge"
        },
        {
            "PostNummer": 3782,
            "POSTBYNAVN": "Klemensker"
        },
        {
            "PostNummer": 3790,
            "POSTBYNAVN": "Hasle"
        },
        {
            "PostNummer": 4000,
            "POSTBYNAVN": "Roskilde"
        },
        {
            "PostNummer": 4030,
            "POSTBYNAVN": "Tune"
        },
        {
            "PostNummer": 4040,
            "POSTBYNAVN": "Jyllinge"
        },
        {
            "PostNummer": 4050,
            "POSTBYNAVN": "Skibby"
        },
        {
            "PostNummer": 4060,
            "POSTBYNAVN": "Kirke Såby"
        },
        {
            "PostNummer": 4070,
            "POSTBYNAVN": "Kirke Hyllinge"
        },
        {
            "PostNummer": 4100,
            "POSTBYNAVN": "Ringsted"
        },
        {
            "PostNummer": 4130,
            "POSTBYNAVN": "Viby Sjælland"
        },
        {
            "PostNummer": 4140,
            "POSTBYNAVN": "Borup"
        },
        {
            "PostNummer": 4160,
            "POSTBYNAVN": "Herlufmagle"
        },
        {
            "PostNummer": 4171,
            "POSTBYNAVN": "Glumsø"
        },
        {
            "PostNummer": 4173,
            "POSTBYNAVN": "Fjenneslev"
        },
        {
            "PostNummer": 4174,
            "POSTBYNAVN": "Jystrup Midtsj"
        },
        {
            "PostNummer": 4180,
            "POSTBYNAVN": "Sorø"
        },
        {
            "PostNummer": 4190,
            "POSTBYNAVN": "Munke Bjergby"
        },
        {
            "PostNummer": 4200,
            "POSTBYNAVN": "Slagelse"
        },
        {
            "PostNummer": 4220,
            "POSTBYNAVN": "Korsør"
        },
        {
            "PostNummer": 4230,
            "POSTBYNAVN": "Skælskør"
        },
        {
            "PostNummer": 4241,
            "POSTBYNAVN": "Vemmelev"
        },
        {
            "PostNummer": 4242,
            "POSTBYNAVN": "Boeslunde"
        },
        {
            "PostNummer": 4243,
            "POSTBYNAVN": "Rude"
        },
        {
            "PostNummer": 4244,
            "POSTBYNAVN": "Agersø"
        },
        {
            "PostNummer": 4245,
            "POSTBYNAVN": "Omø"
        },
        {
            "PostNummer": 4250,
            "POSTBYNAVN": "Fuglebjerg"
        },
        {
            "PostNummer": 4261,
            "POSTBYNAVN": "Dalmose"
        },
        {
            "PostNummer": 4262,
            "POSTBYNAVN": "Sandved"
        },
        {
            "PostNummer": 4270,
            "POSTBYNAVN": "Høng"
        },
        {
            "PostNummer": 4281,
            "POSTBYNAVN": "Gørlev"
        },
        {
            "PostNummer": 4291,
            "POSTBYNAVN": "Ruds Vedby"
        },
        {
            "PostNummer": 4293,
            "POSTBYNAVN": "Dianalund"
        },
        {
            "PostNummer": 4295,
            "POSTBYNAVN": "Stenlille"
        },
        {
            "PostNummer": 4296,
            "POSTBYNAVN": "Nyrup"
        },
        {
            "PostNummer": 4300,
            "POSTBYNAVN": "Holbæk"
        },
        {
            "PostNummer": 4305,
            "POSTBYNAVN": "Orø"
        },
        {
            "PostNummer": 4320,
            "POSTBYNAVN": "Lejre"
        },
        {
            "PostNummer": 4330,
            "POSTBYNAVN": "Hvalsø"
        },
        {
            "PostNummer": 4340,
            "POSTBYNAVN": "Tølløse"
        },
        {
            "PostNummer": 4350,
            "POSTBYNAVN": "Ugerløse"
        },
        {
            "PostNummer": 4360,
            "POSTBYNAVN": "Kirke Eskilstrup"
        },
        {
            "PostNummer": 4370,
            "POSTBYNAVN": "Store Merløse"
        },
        {
            "PostNummer": 4390,
            "POSTBYNAVN": "Vipperød"
        },
        {
            "PostNummer": 4400,
            "POSTBYNAVN": "Kalundborg"
        },
        {
            "PostNummer": 4420,
            "POSTBYNAVN": "Regstrup"
        },
        {
            "PostNummer": 4440,
            "POSTBYNAVN": "Mørkøv"
        },
        {
            "PostNummer": 4450,
            "POSTBYNAVN": "Jyderup"
        },
        {
            "PostNummer": 4460,
            "POSTBYNAVN": "Snertinge"
        },
        {
            "PostNummer": 4470,
            "POSTBYNAVN": "Svebølle"
        },
        {
            "PostNummer": 4480,
            "POSTBYNAVN": "Store Fuglede"
        },
        {
            "PostNummer": 4490,
            "POSTBYNAVN": "Jerslev Sjælland"
        },
        {
            "PostNummer": 4500,
            "POSTBYNAVN": "Nykøbing Sj"
        },
        {
            "PostNummer": 4520,
            "POSTBYNAVN": "Svinninge"
        },
        {
            "PostNummer": 4532,
            "POSTBYNAVN": "Gislinge"
        },
        {
            "PostNummer": 4534,
            "POSTBYNAVN": "Hørve"
        },
        {
            "PostNummer": 4540,
            "POSTBYNAVN": "Fårevejle"
        },
        {
            "PostNummer": 4550,
            "POSTBYNAVN": "Asnæs"
        },
        {
            "PostNummer": 4560,
            "POSTBYNAVN": "Vig"
        },
        {
            "PostNummer": 4571,
            "POSTBYNAVN": "Grevinge"
        },
        {
            "PostNummer": 4572,
            "POSTBYNAVN": "Nørre Asmindrup"
        },
        {
            "PostNummer": 4573,
            "POSTBYNAVN": "Højby"
        },
        {
            "PostNummer": 4581,
            "POSTBYNAVN": "Rørvig"
        },
        {
            "PostNummer": 4583,
            "POSTBYNAVN": "Sjællands Odde"
        },
        {
            "PostNummer": 4591,
            "POSTBYNAVN": "Føllenslev"
        },
        {
            "PostNummer": 4592,
            "POSTBYNAVN": "Sejerø"
        },
        {
            "PostNummer": 4593,
            "POSTBYNAVN": "Eskebjerg"
        },
        {
            "PostNummer": 4600,
            "POSTBYNAVN": "Køge"
        },
        {
            "PostNummer": 4621,
            "POSTBYNAVN": "Gadstrup"
        },
        {
            "PostNummer": 4622,
            "POSTBYNAVN": "Havdrup"
        },
        {
            "PostNummer": 4623,
            "POSTBYNAVN": "Lille Skensved"
        },
        {
            "PostNummer": 4632,
            "POSTBYNAVN": "Bjæverskov"
        },
        {
            "PostNummer": 4640,
            "POSTBYNAVN": "Faxe"
        },
        {
            "PostNummer": 4652,
            "POSTBYNAVN": "Hårlev"
        },
        {
            "PostNummer": 4653,
            "POSTBYNAVN": "Karise"
        },
        {
            "PostNummer": 4654,
            "POSTBYNAVN": "Faxe Ladeplads"
        },
        {
            "PostNummer": 4660,
            "POSTBYNAVN": "Store Heddinge"
        },
        {
            "PostNummer": 4671,
            "POSTBYNAVN": "Strøby"
        },
        {
            "PostNummer": 4672,
            "POSTBYNAVN": "Klippinge"
        },
        {
            "PostNummer": 4673,
            "POSTBYNAVN": "Rødvig Stevns"
        },
        {
            "PostNummer": 4681,
            "POSTBYNAVN": "Herfølge"
        },
        {
            "PostNummer": 4682,
            "POSTBYNAVN": "Tureby"
        },
        {
            "PostNummer": 4683,
            "POSTBYNAVN": "Rønnede"
        },
        {
            "PostNummer": 4684,
            "POSTBYNAVN": "Holmegaard"
        },
        {
            "PostNummer": 4690,
            "POSTBYNAVN": "Haslev"
        },
        {
            "PostNummer": 4700,
            "POSTBYNAVN": "Næstved"
        },
        {
            "PostNummer": 4720,
            "POSTBYNAVN": "Præstø"
        },
        {
            "PostNummer": 4733,
            "POSTBYNAVN": "Tappernøje"
        },
        {
            "PostNummer": 4735,
            "POSTBYNAVN": "Mern"
        },
        {
            "PostNummer": 4736,
            "POSTBYNAVN": "Karrebæksminde"
        },
        {
            "PostNummer": 4750,
            "POSTBYNAVN": "Lundby"
        },
        {
            "PostNummer": 4760,
            "POSTBYNAVN": "Vordingborg"
        },
        {
            "PostNummer": 4771,
            "POSTBYNAVN": "Kalvehave"
        },
        {
            "PostNummer": 4772,
            "POSTBYNAVN": "Langebæk"
        },
        {
            "PostNummer": 4773,
            "POSTBYNAVN": "Stensved"
        },
        {
            "PostNummer": 4780,
            "POSTBYNAVN": "Stege"
        },
        {
            "PostNummer": 4791,
            "POSTBYNAVN": "Borre"
        },
        {
            "PostNummer": 4792,
            "POSTBYNAVN": "Askeby"
        },
        {
            "PostNummer": 4793,
            "POSTBYNAVN": "Bogø By"
        },
        {
            "PostNummer": 4800,
            "POSTBYNAVN": "Nykøbing F"
        },
        {
            "PostNummer": 4840,
            "POSTBYNAVN": "Nørre Alslev"
        },
        {
            "PostNummer": 4850,
            "POSTBYNAVN": "Stubbekøbing"
        },
        {
            "PostNummer": 4862,
            "POSTBYNAVN": "Guldborg"
        },
        {
            "PostNummer": 4863,
            "POSTBYNAVN": "Eskilstrup"
        },
        {
            "PostNummer": 4871,
            "POSTBYNAVN": "Horbelev"
        },
        {
            "PostNummer": 4872,
            "POSTBYNAVN": "Idestrup"
        },
        {
            "PostNummer": 4873,
            "POSTBYNAVN": "Væggerløse"
        },
        {
            "PostNummer": 4874,
            "POSTBYNAVN": "Gedser"
        },
        {
            "PostNummer": 4880,
            "POSTBYNAVN": "Nysted"
        },
        {
            "PostNummer": 4891,
            "POSTBYNAVN": "Toreby L"
        },
        {
            "PostNummer": 4892,
            "POSTBYNAVN": "Kettinge"
        },
        {
            "PostNummer": 4894,
            "POSTBYNAVN": "Øster Ulslev"
        },
        {
            "PostNummer": 4895,
            "POSTBYNAVN": "Errindlev"
        },
        {
            "PostNummer": 4900,
            "POSTBYNAVN": "Nakskov"
        },
        {
            "PostNummer": 4912,
            "POSTBYNAVN": "Harpelunde"
        },
        {
            "PostNummer": 4913,
            "POSTBYNAVN": "Horslunde"
        },
        {
            "PostNummer": 4920,
            "POSTBYNAVN": "Søllested"
        },
        {
            "PostNummer": 4930,
            "POSTBYNAVN": "Maribo"
        },
        {
            "PostNummer": 4941,
            "POSTBYNAVN": "Bandholm"
        },
        {
            "PostNummer": 4942,
            "POSTBYNAVN": "Askø"
        },
        {
            "PostNummer": 4943,
            "POSTBYNAVN": "Torrig L"
        },
        {
            "PostNummer": 4944,
            "POSTBYNAVN": "Fejø"
        },
        {
            "PostNummer": 4945,
            "POSTBYNAVN": "Femø"
        },
        {
            "PostNummer": 4951,
            "POSTBYNAVN": "Nørreballe"
        },
        {
            "PostNummer": 4952,
            "POSTBYNAVN": "Stokkemarke"
        },
        {
            "PostNummer": 4953,
            "POSTBYNAVN": "Vesterborg"
        },
        {
            "PostNummer": 4960,
            "POSTBYNAVN": "Holeby"
        },
        {
            "PostNummer": 4970,
            "POSTBYNAVN": "Rødby"
        },
        {
            "PostNummer": 4983,
            "POSTBYNAVN": "Dannemare"
        },
        {
            "PostNummer": 4990,
            "POSTBYNAVN": "Sakskøbing"
        },
        {
            "PostNummer": 5000,
            "POSTBYNAVN": "Odense C"
        },
        {
            "PostNummer": 5200,
            "POSTBYNAVN": "Odense V"
        },
        {
            "PostNummer": 5210,
            "POSTBYNAVN": "Odense NV"
        },
        {
            "PostNummer": 5220,
            "POSTBYNAVN": "Odense SØ"
        },
        {
            "PostNummer": 5230,
            "POSTBYNAVN": "Odense M"
        },
        {
            "PostNummer": 5240,
            "POSTBYNAVN": "Odense NØ"
        },
        {
            "PostNummer": 5250,
            "POSTBYNAVN": "Odense SV"
        },
        {
            "PostNummer": 5260,
            "POSTBYNAVN": "Odense S"
        },
        {
            "PostNummer": 5270,
            "POSTBYNAVN": "Odense N"
        },
        {
            "PostNummer": 5290,
            "POSTBYNAVN": "Marslev"
        },
        {
            "PostNummer": 5300,
            "POSTBYNAVN": "Kerteminde"
        },
        {
            "PostNummer": 5320,
            "POSTBYNAVN": "Agedrup"
        },
        {
            "PostNummer": 5330,
            "POSTBYNAVN": "Munkebo"
        },
        {
            "PostNummer": 5350,
            "POSTBYNAVN": "Rynkeby"
        },
        {
            "PostNummer": 5370,
            "POSTBYNAVN": "Mesinge"
        },
        {
            "PostNummer": 5380,
            "POSTBYNAVN": "Dalby"
        },
        {
            "PostNummer": 5390,
            "POSTBYNAVN": "Martofte"
        },
        {
            "PostNummer": 5400,
            "POSTBYNAVN": "Bogense"
        },
        {
            "PostNummer": 5450,
            "POSTBYNAVN": "Otterup"
        },
        {
            "PostNummer": 5462,
            "POSTBYNAVN": "Morud"
        },
        {
            "PostNummer": 5463,
            "POSTBYNAVN": "Harndrup"
        },
        {
            "PostNummer": 5464,
            "POSTBYNAVN": "Brenderup Fyn"
        },
        {
            "PostNummer": 5466,
            "POSTBYNAVN": "Asperup"
        },
        {
            "PostNummer": 5471,
            "POSTBYNAVN": "Søndersø"
        },
        {
            "PostNummer": 5474,
            "POSTBYNAVN": "Veflinge"
        },
        {
            "PostNummer": 5485,
            "POSTBYNAVN": "Skamby"
        },
        {
            "PostNummer": 5491,
            "POSTBYNAVN": "Blommenslyst"
        },
        {
            "PostNummer": 5492,
            "POSTBYNAVN": "Vissenbjerg"
        },
        {
            "PostNummer": 5500,
            "POSTBYNAVN": "Middelfart"
        },
        {
            "PostNummer": 5540,
            "POSTBYNAVN": "Ullerslev"
        },
        {
            "PostNummer": 5550,
            "POSTBYNAVN": "Langeskov"
        },
        {
            "PostNummer": 5560,
            "POSTBYNAVN": "Aarup"
        },
        {
            "PostNummer": 5580,
            "POSTBYNAVN": "Nørre Aaby"
        },
        {
            "PostNummer": 5591,
            "POSTBYNAVN": "Gelsted"
        },
        {
            "PostNummer": 5592,
            "POSTBYNAVN": "Ejby"
        },
        {
            "PostNummer": 5600,
            "POSTBYNAVN": "Faaborg"
        },
        {
            "PostNummer": 5601,
            "POSTBYNAVN": "Lyø"
        },
        {
            "PostNummer": 5602,
            "POSTBYNAVN": "Avernakø"
        },
        {
            "PostNummer": 5603,
            "POSTBYNAVN": "Bjørnø"
        },
        {
            "PostNummer": 5610,
            "POSTBYNAVN": "Assens"
        },
        {
            "PostNummer": 5620,
            "POSTBYNAVN": "Glamsbjerg"
        },
        {
            "PostNummer": 5631,
            "POSTBYNAVN": "Ebberup"
        },
        {
            "PostNummer": 5642,
            "POSTBYNAVN": "Millinge"
        },
        {
            "PostNummer": 5672,
            "POSTBYNAVN": "Broby"
        },
        {
            "PostNummer": 5683,
            "POSTBYNAVN": "Haarby"
        },
        {
            "PostNummer": 5690,
            "POSTBYNAVN": "Tommerup"
        },
        {
            "PostNummer": 5700,
            "POSTBYNAVN": "Svendborg"
        },
        {
            "PostNummer": 5750,
            "POSTBYNAVN": "Ringe"
        },
        {
            "PostNummer": 5762,
            "POSTBYNAVN": "Vester Skerninge"
        },
        {
            "PostNummer": 5771,
            "POSTBYNAVN": "Stenstrup"
        },
        {
            "PostNummer": 5772,
            "POSTBYNAVN": "Kværndrup"
        },
        {
            "PostNummer": 5792,
            "POSTBYNAVN": "Årslev"
        },
        {
            "PostNummer": 5800,
            "POSTBYNAVN": "Nyborg"
        },
        {
            "PostNummer": 5853,
            "POSTBYNAVN": "Ørbæk"
        },
        {
            "PostNummer": 5854,
            "POSTBYNAVN": "Gislev"
        },
        {
            "PostNummer": 5856,
            "POSTBYNAVN": "Ryslinge"
        },
        {
            "PostNummer": 5863,
            "POSTBYNAVN": "Ferritslev Fyn"
        },
        {
            "PostNummer": 5871,
            "POSTBYNAVN": "Frørup"
        },
        {
            "PostNummer": 5874,
            "POSTBYNAVN": "Hesselager"
        },
        {
            "PostNummer": 5881,
            "POSTBYNAVN": "Skårup Fyn"
        },
        {
            "PostNummer": 5882,
            "POSTBYNAVN": "Vejstrup"
        },
        {
            "PostNummer": 5883,
            "POSTBYNAVN": "Oure"
        },
        {
            "PostNummer": 5884,
            "POSTBYNAVN": "Gudme"
        },
        {
            "PostNummer": 5892,
            "POSTBYNAVN": "Gudbjerg Sydfyn"
        },
        {
            "PostNummer": 5900,
            "POSTBYNAVN": "Rudkøbing"
        },
        {
            "PostNummer": 5932,
            "POSTBYNAVN": "Humble"
        },
        {
            "PostNummer": 5935,
            "POSTBYNAVN": "Bagenkop"
        },
        {
            "PostNummer": 5943,
            "POSTBYNAVN": "Strynø"
        },
        {
            "PostNummer": 5953,
            "POSTBYNAVN": "Tranekær"
        },
        {
            "PostNummer": 5960,
            "POSTBYNAVN": "Marstal"
        },
        {
            "PostNummer": 5965,
            "POSTBYNAVN": "Birkholm"
        },
        {
            "PostNummer": 5970,
            "POSTBYNAVN": "Ærøskøbing"
        },
        {
            "PostNummer": 5985,
            "POSTBYNAVN": "Søby Ærø"
        },
        {
            "PostNummer": 6000,
            "POSTBYNAVN": "Kolding"
        },
        {
            "PostNummer": 6040,
            "POSTBYNAVN": "Egtved"
        },
        {
            "PostNummer": 6051,
            "POSTBYNAVN": "Almind"
        },
        {
            "PostNummer": 6052,
            "POSTBYNAVN": "Viuf"
        },
        {
            "PostNummer": 6064,
            "POSTBYNAVN": "Jordrup"
        },
        {
            "PostNummer": 6070,
            "POSTBYNAVN": "Christiansfeld"
        },
        {
            "PostNummer": 6091,
            "POSTBYNAVN": "Bjert"
        },
        {
            "PostNummer": 6092,
            "POSTBYNAVN": "Sønder Stenderup"
        },
        {
            "PostNummer": 6093,
            "POSTBYNAVN": "Sjølund"
        },
        {
            "PostNummer": 6094,
            "POSTBYNAVN": "Hejls"
        },
        {
            "PostNummer": 6100,
            "POSTBYNAVN": "Haderslev"
        },
        {
            "PostNummer": 6200,
            "POSTBYNAVN": "Aabenraa"
        },
        {
            "PostNummer": 6210,
            "POSTBYNAVN": "Barsø"
        },
        {
            "PostNummer": 6230,
            "POSTBYNAVN": "Rødekro"
        },
        {
            "PostNummer": 6240,
            "POSTBYNAVN": "Løgumkloster"
        },
        {
            "PostNummer": 6261,
            "POSTBYNAVN": "Bredebro"
        },
        {
            "PostNummer": 6270,
            "POSTBYNAVN": "Tønder"
        },
        {
            "PostNummer": 6280,
            "POSTBYNAVN": "Højer"
        },
        {
            "PostNummer": 6300,
            "POSTBYNAVN": "Gråsten"
        },
        {
            "PostNummer": 6310,
            "POSTBYNAVN": "Broager"
        },
        {
            "PostNummer": 6320,
            "POSTBYNAVN": "Egernsund"
        },
        {
            "PostNummer": 6330,
            "POSTBYNAVN": "Padborg"
        },
        {
            "PostNummer": 6340,
            "POSTBYNAVN": "Kruså"
        },
        {
            "PostNummer": 6360,
            "POSTBYNAVN": "Tinglev"
        },
        {
            "PostNummer": 6372,
            "POSTBYNAVN": "Bylderup-Bov"
        },
        {
            "PostNummer": 6392,
            "POSTBYNAVN": "Bolderslev"
        },
        {
            "PostNummer": 6400,
            "POSTBYNAVN": "Sønderborg"
        },
        {
            "PostNummer": 6430,
            "POSTBYNAVN": "Nordborg"
        },
        {
            "PostNummer": 6440,
            "POSTBYNAVN": "Augustenborg"
        },
        {
            "PostNummer": 6470,
            "POSTBYNAVN": "Sydals"
        },
        {
            "PostNummer": 6500,
            "POSTBYNAVN": "Vojens"
        },
        {
            "PostNummer": 6510,
            "POSTBYNAVN": "Gram"
        },
        {
            "PostNummer": 6520,
            "POSTBYNAVN": "Toftlund"
        },
        {
            "PostNummer": 6534,
            "POSTBYNAVN": "Agerskov"
        },
        {
            "PostNummer": 6535,
            "POSTBYNAVN": "Branderup J"
        },
        {
            "PostNummer": 6541,
            "POSTBYNAVN": "Bevtoft"
        },
        {
            "PostNummer": 6560,
            "POSTBYNAVN": "Sommersted"
        },
        {
            "PostNummer": 6580,
            "POSTBYNAVN": "Vamdrup"
        },
        {
            "PostNummer": 6600,
            "POSTBYNAVN": "Vejen"
        },
        {
            "PostNummer": 6621,
            "POSTBYNAVN": "Gesten"
        },
        {
            "PostNummer": 6622,
            "POSTBYNAVN": "Bække"
        },
        {
            "PostNummer": 6623,
            "POSTBYNAVN": "Vorbasse"
        },
        {
            "PostNummer": 6630,
            "POSTBYNAVN": "Rødding"
        },
        {
            "PostNummer": 6640,
            "POSTBYNAVN": "Lunderskov"
        },
        {
            "PostNummer": 6650,
            "POSTBYNAVN": "Brørup"
        },
        {
            "PostNummer": 6660,
            "POSTBYNAVN": "Lintrup"
        },
        {
            "PostNummer": 6670,
            "POSTBYNAVN": "Holsted"
        },
        {
            "PostNummer": 6682,
            "POSTBYNAVN": "Hovborg"
        },
        {
            "PostNummer": 6683,
            "POSTBYNAVN": "Føvling"
        },
        {
            "PostNummer": 6690,
            "POSTBYNAVN": "Gørding"
        },
        {
            "PostNummer": 6700,
            "POSTBYNAVN": "Esbjerg"
        },
        {
            "PostNummer": 6705,
            "POSTBYNAVN": "Esbjerg Ø"
        },
        {
            "PostNummer": 6710,
            "POSTBYNAVN": "Esbjerg V"
        },
        {
            "PostNummer": 6715,
            "POSTBYNAVN": "Esbjerg N"
        },
        {
            "PostNummer": 6720,
            "POSTBYNAVN": "Fanø"
        },
        {
            "PostNummer": 6731,
            "POSTBYNAVN": "Tjæreborg"
        },
        {
            "PostNummer": 6740,
            "POSTBYNAVN": "Bramming"
        },
        {
            "PostNummer": 6752,
            "POSTBYNAVN": "Glejbjerg"
        },
        {
            "PostNummer": 6753,
            "POSTBYNAVN": "Agerbæk"
        },
        {
            "PostNummer": 6760,
            "POSTBYNAVN": "Ribe"
        },
        {
            "PostNummer": 6771,
            "POSTBYNAVN": "Gredstedbro"
        },
        {
            "PostNummer": 6780,
            "POSTBYNAVN": "Skærbæk"
        },
        {
            "PostNummer": 6792,
            "POSTBYNAVN": "Rømø"
        },
        {
            "PostNummer": 6800,
            "POSTBYNAVN": "Varde"
        },
        {
            "PostNummer": 6818,
            "POSTBYNAVN": "Årre"
        },
        {
            "PostNummer": 6823,
            "POSTBYNAVN": "Ansager"
        },
        {
            "PostNummer": 6830,
            "POSTBYNAVN": "Nørre Nebel"
        },
        {
            "PostNummer": 6840,
            "POSTBYNAVN": "Oksbøl"
        },
        {
            "PostNummer": 6851,
            "POSTBYNAVN": "Janderup Vestj"
        },
        {
            "PostNummer": 6852,
            "POSTBYNAVN": "Billum"
        },
        {
            "PostNummer": 6853,
            "POSTBYNAVN": "Vejers Strand"
        },
        {
            "PostNummer": 6854,
            "POSTBYNAVN": "Henne"
        },
        {
            "PostNummer": 6855,
            "POSTBYNAVN": "Outrup"
        },
        {
            "PostNummer": 6857,
            "POSTBYNAVN": "Blåvand"
        },
        {
            "PostNummer": 6862,
            "POSTBYNAVN": "Tistrup"
        },
        {
            "PostNummer": 6870,
            "POSTBYNAVN": "Ølgod"
        },
        {
            "PostNummer": 6880,
            "POSTBYNAVN": "Tarm"
        },
        {
            "PostNummer": 6893,
            "POSTBYNAVN": "Hemmet"
        },
        {
            "PostNummer": 6900,
            "POSTBYNAVN": "Skjern"
        },
        {
            "PostNummer": 6920,
            "POSTBYNAVN": "Videbæk"
        },
        {
            "PostNummer": 6933,
            "POSTBYNAVN": "Kibæk"
        },
        {
            "PostNummer": 6940,
            "POSTBYNAVN": "Lem St"
        },
        {
            "PostNummer": 6950,
            "POSTBYNAVN": "Ringkøbing"
        },
        {
            "PostNummer": 6960,
            "POSTBYNAVN": "Hvide Sande"
        },
        {
            "PostNummer": 6971,
            "POSTBYNAVN": "Spjald"
        },
        {
            "PostNummer": 6973,
            "POSTBYNAVN": "Ørnhøj"
        },
        {
            "PostNummer": 6980,
            "POSTBYNAVN": "Tim"
        },
        {
            "PostNummer": 6990,
            "POSTBYNAVN": "Ulfborg"
        },
        {
            "PostNummer": 7000,
            "POSTBYNAVN": "Fredericia"
        },
        {
            "PostNummer": 7080,
            "POSTBYNAVN": "Børkop"
        },
        {
            "PostNummer": 7100,
            "POSTBYNAVN": "Vejle"
        },
        {
            "PostNummer": 7120,
            "POSTBYNAVN": "Vejle Øst"
        },
        {
            "PostNummer": 7130,
            "POSTBYNAVN": "Juelsminde"
        },
        {
            "PostNummer": 7140,
            "POSTBYNAVN": "Stouby"
        },
        {
            "PostNummer": 7150,
            "POSTBYNAVN": "Barrit"
        },
        {
            "PostNummer": 7160,
            "POSTBYNAVN": "Tørring"
        },
        {
            "PostNummer": 7171,
            "POSTBYNAVN": "Uldum"
        },
        {
            "PostNummer": 7173,
            "POSTBYNAVN": "Vonge"
        },
        {
            "PostNummer": 7182,
            "POSTBYNAVN": "Bredsten"
        },
        {
            "PostNummer": 7183,
            "POSTBYNAVN": "Randbøl"
        },
        {
            "PostNummer": 7184,
            "POSTBYNAVN": "Vandel"
        },
        {
            "PostNummer": 7190,
            "POSTBYNAVN": "Billund"
        },
        {
            "PostNummer": 7200,
            "POSTBYNAVN": "Grindsted"
        },
        {
            "PostNummer": 7250,
            "POSTBYNAVN": "Hejnsvig"
        },
        {
            "PostNummer": 7260,
            "POSTBYNAVN": "Sønder Omme"
        },
        {
            "PostNummer": 7270,
            "POSTBYNAVN": "Stakroge"
        },
        {
            "PostNummer": 7280,
            "POSTBYNAVN": "Sønder Felding"
        },
        {
            "PostNummer": 7300,
            "POSTBYNAVN": "Jelling"
        },
        {
            "PostNummer": 7321,
            "POSTBYNAVN": "Gadbjerg"
        },
        {
            "PostNummer": 7323,
            "POSTBYNAVN": "Give"
        },
        {
            "PostNummer": 7330,
            "POSTBYNAVN": "Brande"
        },
        {
            "PostNummer": 7361,
            "POSTBYNAVN": "Ejstrupholm"
        },
        {
            "PostNummer": 7362,
            "POSTBYNAVN": "Hampen"
        },
        {
            "PostNummer": 7400,
            "POSTBYNAVN": "Herning"
        },
        {
            "PostNummer": 7430,
            "POSTBYNAVN": "Ikast"
        },
        {
            "PostNummer": 7441,
            "POSTBYNAVN": "Bording"
        },
        {
            "PostNummer": 7442,
            "POSTBYNAVN": "Engesvang"
        },
        {
            "PostNummer": 7451,
            "POSTBYNAVN": "Sunds"
        },
        {
            "PostNummer": 7470,
            "POSTBYNAVN": "Karup J"
        },
        {
            "PostNummer": 7480,
            "POSTBYNAVN": "Vildbjerg"
        },
        {
            "PostNummer": 7490,
            "POSTBYNAVN": "Aulum"
        },
        {
            "PostNummer": 7500,
            "POSTBYNAVN": "Holstebro"
        },
        {
            "PostNummer": 7540,
            "POSTBYNAVN": "Haderup"
        },
        {
            "PostNummer": 7550,
            "POSTBYNAVN": "Sørvad"
        },
        {
            "PostNummer": 7560,
            "POSTBYNAVN": "Hjerm"
        },
        {
            "PostNummer": 7570,
            "POSTBYNAVN": "Vemb"
        },
        {
            "PostNummer": 7600,
            "POSTBYNAVN": "Struer"
        },
        {
            "PostNummer": 7620,
            "POSTBYNAVN": "Lemvig"
        },
        {
            "PostNummer": 7650,
            "POSTBYNAVN": "Bøvlingbjerg"
        },
        {
            "PostNummer": 7660,
            "POSTBYNAVN": "Bækmarksbro"
        },
        {
            "PostNummer": 7673,
            "POSTBYNAVN": "Harboøre"
        },
        {
            "PostNummer": 7680,
            "POSTBYNAVN": "Thyborøn"
        },
        {
            "PostNummer": 7700,
            "POSTBYNAVN": "Thisted"
        },
        {
            "PostNummer": 7730,
            "POSTBYNAVN": "Hanstholm"
        },
        {
            "PostNummer": 7741,
            "POSTBYNAVN": "Frøstrup"
        },
        {
            "PostNummer": 7742,
            "POSTBYNAVN": "Vesløs"
        },
        {
            "PostNummer": 7752,
            "POSTBYNAVN": "Snedsted"
        },
        {
            "PostNummer": 7755,
            "POSTBYNAVN": "Bedsted Thy"
        },
        {
            "PostNummer": 7760,
            "POSTBYNAVN": "Hurup Thy"
        },
        {
            "PostNummer": 7770,
            "POSTBYNAVN": "Vestervig"
        },
        {
            "PostNummer": 7790,
            "POSTBYNAVN": "Thyholm"
        },
        {
            "PostNummer": 7800,
            "POSTBYNAVN": "Skive"
        },
        {
            "PostNummer": 7830,
            "POSTBYNAVN": "Vinderup"
        },
        {
            "PostNummer": 7840,
            "POSTBYNAVN": "Højslev"
        },
        {
            "PostNummer": 7850,
            "POSTBYNAVN": "Stoholm Jyll"
        },
        {
            "PostNummer": 7860,
            "POSTBYNAVN": "Spøttrup"
        },
        {
            "PostNummer": 7870,
            "POSTBYNAVN": "Roslev"
        },
        {
            "PostNummer": 7884,
            "POSTBYNAVN": "Fur"
        },
        {
            "PostNummer": 7900,
            "POSTBYNAVN": "Nykøbing M"
        },
        {
            "PostNummer": 7950,
            "POSTBYNAVN": "Erslev"
        },
        {
            "PostNummer": 7960,
            "POSTBYNAVN": "Karby"
        },
        {
            "PostNummer": 7970,
            "POSTBYNAVN": "Redsted M"
        },
        {
            "PostNummer": 7980,
            "POSTBYNAVN": "Vils"
        },
        {
            "PostNummer": 7990,
            "POSTBYNAVN": "Øster Assels"
        },
        {
            "PostNummer": 8000,
            "POSTBYNAVN": "Aarhus C"
        },
        {
            "PostNummer": 8200,
            "POSTBYNAVN": "Aarhus N"
        },
        {
            "PostNummer": 8210,
            "POSTBYNAVN": "Aarhus V"
        },
        {
            "PostNummer": 8220,
            "POSTBYNAVN": "Brabrand"
        },
        {
            "PostNummer": 8230,
            "POSTBYNAVN": "Åbyhøj"
        },
        {
            "PostNummer": 8240,
            "POSTBYNAVN": "Risskov"
        },
        {
            "PostNummer": 8250,
            "POSTBYNAVN": "Egå"
        },
        {
            "PostNummer": 8260,
            "POSTBYNAVN": "Viby J"
        },
        {
            "PostNummer": 8270,
            "POSTBYNAVN": "Højbjerg"
        },
        {
            "PostNummer": 8300,
            "POSTBYNAVN": "Odder"
        },
        {
            "PostNummer": 8305,
            "POSTBYNAVN": "Samsø"
        },
        {
            "PostNummer": 8310,
            "POSTBYNAVN": "Tranbjerg J"
        },
        {
            "PostNummer": 8320,
            "POSTBYNAVN": "Mårslet"
        },
        {
            "PostNummer": 8330,
            "POSTBYNAVN": "Beder"
        },
        {
            "PostNummer": 8340,
            "POSTBYNAVN": "Malling"
        },
        {
            "PostNummer": 8350,
            "POSTBYNAVN": "Hundslund"
        },
        {
            "PostNummer": 8355,
            "POSTBYNAVN": "Solbjerg"
        },
        {
            "PostNummer": 8361,
            "POSTBYNAVN": "Hasselager"
        },
        {
            "PostNummer": 8362,
            "POSTBYNAVN": "Hørning"
        },
        {
            "PostNummer": 8370,
            "POSTBYNAVN": "Hadsten"
        },
        {
            "PostNummer": 8380,
            "POSTBYNAVN": "Trige"
        },
        {
            "PostNummer": 8381,
            "POSTBYNAVN": "Tilst"
        },
        {
            "PostNummer": 8382,
            "POSTBYNAVN": "Hinnerup"
        },
        {
            "PostNummer": 8400,
            "POSTBYNAVN": "Ebeltoft"
        },
        {
            "PostNummer": 8410,
            "POSTBYNAVN": "Rønde"
        },
        {
            "PostNummer": 8420,
            "POSTBYNAVN": "Knebel"
        },
        {
            "PostNummer": 8444,
            "POSTBYNAVN": "Balle"
        },
        {
            "PostNummer": 8450,
            "POSTBYNAVN": "Hammel"
        },
        {
            "PostNummer": 8462,
            "POSTBYNAVN": "Harlev J"
        },
        {
            "PostNummer": 8464,
            "POSTBYNAVN": "Galten"
        },
        {
            "PostNummer": 8471,
            "POSTBYNAVN": "Sabro"
        },
        {
            "PostNummer": 8472,
            "POSTBYNAVN": "Sporup"
        },
        {
            "PostNummer": 8500,
            "POSTBYNAVN": "Grenaa"
        },
        {
            "PostNummer": 8520,
            "POSTBYNAVN": "Lystrup"
        },
        {
            "PostNummer": 8530,
            "POSTBYNAVN": "Hjortshøj"
        },
        {
            "PostNummer": 8541,
            "POSTBYNAVN": "Skødstrup"
        },
        {
            "PostNummer": 8543,
            "POSTBYNAVN": "Hornslet"
        },
        {
            "PostNummer": 8544,
            "POSTBYNAVN": "Mørke"
        },
        {
            "PostNummer": 8550,
            "POSTBYNAVN": "Ryomgård"
        },
        {
            "PostNummer": 8560,
            "POSTBYNAVN": "Kolind"
        },
        {
            "PostNummer": 8570,
            "POSTBYNAVN": "Trustrup"
        },
        {
            "PostNummer": 8581,
            "POSTBYNAVN": "Nimtofte"
        },
        {
            "PostNummer": 8585,
            "POSTBYNAVN": "Glesborg"
        },
        {
            "PostNummer": 8586,
            "POSTBYNAVN": "Ørum Djurs"
        },
        {
            "PostNummer": 8592,
            "POSTBYNAVN": "Anholt"
        },
        {
            "PostNummer": 8600,
            "POSTBYNAVN": "Silkeborg"
        },
        {
            "PostNummer": 8620,
            "POSTBYNAVN": "Kjellerup"
        },
        {
            "PostNummer": 8632,
            "POSTBYNAVN": "Lemming"
        },
        {
            "PostNummer": 8641,
            "POSTBYNAVN": "Sorring"
        },
        {
            "PostNummer": 8643,
            "POSTBYNAVN": "Ans By"
        },
        {
            "PostNummer": 8653,
            "POSTBYNAVN": "Them"
        },
        {
            "PostNummer": 8654,
            "POSTBYNAVN": "Bryrup"
        },
        {
            "PostNummer": 8660,
            "POSTBYNAVN": "Skanderborg"
        },
        {
            "PostNummer": 8670,
            "POSTBYNAVN": "Låsby"
        },
        {
            "PostNummer": 8680,
            "POSTBYNAVN": "Ry"
        },
        {
            "PostNummer": 8700,
            "POSTBYNAVN": "Horsens"
        },
        {
            "PostNummer": 8721,
            "POSTBYNAVN": "Daugård"
        },
        {
            "PostNummer": 8722,
            "POSTBYNAVN": "Hedensted"
        },
        {
            "PostNummer": 8723,
            "POSTBYNAVN": "Løsning"
        },
        {
            "PostNummer": 8732,
            "POSTBYNAVN": "Hovedgård"
        },
        {
            "PostNummer": 8740,
            "POSTBYNAVN": "Brædstrup"
        },
        {
            "PostNummer": 8751,
            "POSTBYNAVN": "Gedved"
        },
        {
            "PostNummer": 8752,
            "POSTBYNAVN": "Østbirk"
        },
        {
            "PostNummer": 8762,
            "POSTBYNAVN": "Flemming"
        },
        {
            "PostNummer": 8763,
            "POSTBYNAVN": "Rask Mølle"
        },
        {
            "PostNummer": 8765,
            "POSTBYNAVN": "Klovborg"
        },
        {
            "PostNummer": 8766,
            "POSTBYNAVN": "Nørre Snede"
        },
        {
            "PostNummer": 8781,
            "POSTBYNAVN": "Stenderup"
        },
        {
            "PostNummer": 8783,
            "POSTBYNAVN": "Hornsyld"
        },
        {
            "PostNummer": 8789,
            "POSTBYNAVN": "Endelave"
        },
        {
            "PostNummer": 8799,
            "POSTBYNAVN": "Tunø"
        },
        {
            "PostNummer": 8800,
            "POSTBYNAVN": "Viborg"
        },
        {
            "PostNummer": 8830,
            "POSTBYNAVN": "Tjele"
        },
        {
            "PostNummer": 8831,
            "POSTBYNAVN": "Løgstrup"
        },
        {
            "PostNummer": 8832,
            "POSTBYNAVN": "Skals"
        },
        {
            "PostNummer": 8840,
            "POSTBYNAVN": "Rødkærsbro"
        },
        {
            "PostNummer": 8850,
            "POSTBYNAVN": "Bjerringbro"
        },
        {
            "PostNummer": 8860,
            "POSTBYNAVN": "Ulstrup"
        },
        {
            "PostNummer": 8870,
            "POSTBYNAVN": "Langå"
        },
        {
            "PostNummer": 8881,
            "POSTBYNAVN": "Thorsø"
        },
        {
            "PostNummer": 8882,
            "POSTBYNAVN": "Fårvang"
        },
        {
            "PostNummer": 8883,
            "POSTBYNAVN": "Gjern"
        },
        {
            "PostNummer": 8900,
            "POSTBYNAVN": "Randers C"
        },
        {
            "PostNummer": 8920,
            "POSTBYNAVN": "Randers NV"
        },
        {
            "PostNummer": 8930,
            "POSTBYNAVN": "Randers NØ"
        },
        {
            "PostNummer": 8940,
            "POSTBYNAVN": "Randers SV"
        },
        {
            "PostNummer": 8950,
            "POSTBYNAVN": "Ørsted"
        },
        {
            "PostNummer": 8960,
            "POSTBYNAVN": "Randers SØ"
        },
        {
            "PostNummer": 8961,
            "POSTBYNAVN": "Allingåbro"
        },
        {
            "PostNummer": 8963,
            "POSTBYNAVN": "Auning"
        },
        {
            "PostNummer": 8970,
            "POSTBYNAVN": "Havndal"
        },
        {
            "PostNummer": 8981,
            "POSTBYNAVN": "Spentrup"
        },
        {
            "PostNummer": 8983,
            "POSTBYNAVN": "Gjerlev J"
        },
        {
            "PostNummer": 8990,
            "POSTBYNAVN": "Fårup"
        },
        {
            "PostNummer": 9000,
            "POSTBYNAVN": "Aalborg"
        },
        {
            "PostNummer": 9200,
            "POSTBYNAVN": "Aalborg SV"
        },
        {
            "PostNummer": 9210,
            "POSTBYNAVN": "Aalborg SØ"
        },
        {
            "PostNummer": 9220,
            "POSTBYNAVN": "Aalborg Øst"
        },
        {
            "PostNummer": 9230,
            "POSTBYNAVN": "Svenstrup J"
        },
        {
            "PostNummer": 9240,
            "POSTBYNAVN": "Nibe"
        },
        {
            "PostNummer": 9260,
            "POSTBYNAVN": "Gistrup"
        },
        {
            "PostNummer": 9270,
            "POSTBYNAVN": "Klarup"
        },
        {
            "PostNummer": 9280,
            "POSTBYNAVN": "Storvorde"
        },
        {
            "PostNummer": 9293,
            "POSTBYNAVN": "Kongerslev"
        },
        {
            "PostNummer": 9300,
            "POSTBYNAVN": "Sæby"
        },
        {
            "PostNummer": 9310,
            "POSTBYNAVN": "Vodskov"
        },
        {
            "PostNummer": 9320,
            "POSTBYNAVN": "Hjallerup"
        },
        {
            "PostNummer": 9330,
            "POSTBYNAVN": "Dronninglund"
        },
        {
            "PostNummer": 9340,
            "POSTBYNAVN": "Asaa"
        },
        {
            "PostNummer": 9352,
            "POSTBYNAVN": "Dybvad"
        },
        {
            "PostNummer": 9362,
            "POSTBYNAVN": "Gandrup"
        },
        {
            "PostNummer": 9370,
            "POSTBYNAVN": "Hals"
        },
        {
            "PostNummer": 9380,
            "POSTBYNAVN": "Vestbjerg"
        },
        {
            "PostNummer": 9381,
            "POSTBYNAVN": "Sulsted"
        },
        {
            "PostNummer": 9382,
            "POSTBYNAVN": "Tylstrup"
        },
        {
            "PostNummer": 9400,
            "POSTBYNAVN": "Nørresundby"
        },
        {
            "PostNummer": 9430,
            "POSTBYNAVN": "Vadum"
        },
        {
            "PostNummer": 9440,
            "POSTBYNAVN": "Aabybro"
        },
        {
            "PostNummer": 9460,
            "POSTBYNAVN": "Brovst"
        },
        {
            "PostNummer": 9480,
            "POSTBYNAVN": "Løkken"
        },
        {
            "PostNummer": 9490,
            "POSTBYNAVN": "Pandrup"
        },
        {
            "PostNummer": 9492,
            "POSTBYNAVN": "Blokhus"
        },
        {
            "PostNummer": 9493,
            "POSTBYNAVN": "Saltum"
        },
        {
            "PostNummer": 9500,
            "POSTBYNAVN": "Hobro"
        },
        {
            "PostNummer": 9510,
            "POSTBYNAVN": "Arden"
        },
        {
            "PostNummer": 9520,
            "POSTBYNAVN": "Skørping"
        },
        {
            "PostNummer": 9530,
            "POSTBYNAVN": "Støvring"
        },
        {
            "PostNummer": 9541,
            "POSTBYNAVN": "Suldrup"
        },
        {
            "PostNummer": 9550,
            "POSTBYNAVN": "Mariager"
        },
        {
            "PostNummer": 9560,
            "POSTBYNAVN": "Hadsund"
        },
        {
            "PostNummer": 9574,
            "POSTBYNAVN": "Bælum"
        },
        {
            "PostNummer": 9575,
            "POSTBYNAVN": "Terndrup"
        },
        {
            "PostNummer": 9600,
            "POSTBYNAVN": "Aars"
        },
        {
            "PostNummer": 9610,
            "POSTBYNAVN": "Nørager"
        },
        {
            "PostNummer": 9620,
            "POSTBYNAVN": "Aalestrup"
        },
        {
            "PostNummer": 9631,
            "POSTBYNAVN": "Gedsted"
        },
        {
            "PostNummer": 9632,
            "POSTBYNAVN": "Møldrup"
        },
        {
            "PostNummer": 9640,
            "POSTBYNAVN": "Farsø"
        },
        {
            "PostNummer": 9670,
            "POSTBYNAVN": "Løgstør"
        },
        {
            "PostNummer": 9681,
            "POSTBYNAVN": "Ranum"
        },
        {
            "PostNummer": 9690,
            "POSTBYNAVN": "Fjerritslev"
        },
        {
            "PostNummer": 9700,
            "POSTBYNAVN": "Brønderslev"
        },
        {
            "PostNummer": 9740,
            "POSTBYNAVN": "Jerslev J"
        },
        {
            "PostNummer": 9750,
            "POSTBYNAVN": "Østervrå"
        },
        {
            "PostNummer": 9760,
            "POSTBYNAVN": "Vrå"
        },
        {
            "PostNummer": 9800,
            "POSTBYNAVN": "Hjørring"
        },
        {
            "PostNummer": 9830,
            "POSTBYNAVN": "Tårs"
        },
        {
            "PostNummer": 9850,
            "POSTBYNAVN": "Hirtshals"
        },
        {
            "PostNummer": 9870,
            "POSTBYNAVN": "Sindal"
        },
        {
            "PostNummer": 9881,
            "POSTBYNAVN": "Bindslev"
        },
        {
            "PostNummer": 9900,
            "POSTBYNAVN": "Frederikshavn"
        },
        {
            "PostNummer": 9940,
            "POSTBYNAVN": "Læsø"
        },
        {
            "PostNummer": 9970,
            "POSTBYNAVN": "Strandby"
        },
        {
            "PostNummer": 9981,
            "POSTBYNAVN": "Jerup"
        },
        {
            "PostNummer": 9982,
            "POSTBYNAVN": "Ålbæk"
        },
        {
            "PostNummer": 9990,
            "POSTBYNAVN": "Skagen"
        }
    ];

    // -------------------------- Municipalities -----------------------------------
    static dumpMunicpalityLookupList():any[] {
        var dataSet = ReferenceGeomDenmark.getReferenceGeo().municipality;
        var result = dataSet.features.map((feature) => {
            return ({"Komnr": feature.properties.Komnr, "KOMNAVN":feature.properties.KOMNAVN}); 
            });
        return(result);
    }

    // static lookupByMunicipalityCode(code:number) {
    //     var dataSet = getReferenceGeo().municipality;
    //     var result = dataSet.features.find((feature) => {
    //         return equalityFuncMunicipalityCode(feature.properties.Komnr, code); 
    //         });
    //     return(result);
    // }
    static lookupByMunicipalityCode(code:number) {
        var dataSet = ReferenceGeomDenmark.municipalityLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncMunicipalityCode(feature.Komnr, code); 
            });
        return(result);
    }

    static equalityFuncMunicipalityCode(prop: number, code:number) {
        return (prop.toString() === code.toString());
    }

    // static lookupByMunicipalityName(name:string) {
    //     var dataSet = getReferenceGeo().municipality;
    //     var result = dataSet.features.find((feature) => {
    //         return equalityFuncMunicipalityName(feature.properties.KOMNAVN, name); 
    //         });
    //     return(result);
    // }
    static lookupByMunicipalityName(name:string) {
        var dataSet = ReferenceGeomDenmark.municipalityLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncMunicipalityName(feature.KOMNAVN, name); 
        });
        return(result);
    }

    static equalityFuncMunicipalityName(prop: string, code:string):boolean {
        let result = prop.toString().toLowerCase() === code.toString().toLowerCase();
        // Check alias list if not found
        if (!result) {
            let foundKey = Object.keys(ReferenceGeomDenmark.municipalityNameAliasList).find((key,idx) => {
                let found = key.toString().toLowerCase() === code.toString().toLowerCase();
                if (found) {
                    let alias = ReferenceGeomDenmark.municipalityNameAliasList[key];
                    return (prop.toString().toLowerCase() === alias.toString().toLowerCase());    
                } else {
                    return false;
                }
            });
            result = (foundKey !== undefined);
        }
        return result;
    }

    // -------------------------- Regions -----------------------------------

    static dumpRegionLookupList():any[] {
        var dataSet = ReferenceGeomDenmark.getReferenceGeo().region;
        var result = dataSet.features.map((feature) => {
            return ({"REGIONKODE": feature.properties.REGIONKODE, "REGIONNAVN":feature.properties.REGIONNAVN}); 
            });
        return(result);
    }

    static lookupByRegionCode(code:string) {
        var dataSet = ReferenceGeomDenmark.regionLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncRegionCode(feature.REGIONKODE, code); 
            });
        return(result);
    }

    static equalityFuncRegionCode(prop: string, code:string) {
        return (prop.toString() === code.toString());
    }

    static lookupByRegionName(name:string) {
        var dataSet = ReferenceGeomDenmark.regionLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncRegionName(feature.REGIONNAVN, name); 
            });
        return(result);
    }

    static equalityFuncRegionName(prop: string, code:string) {
        return (prop.toString().toLowerCase() === code.toString().toLowerCase()
            || prop.toString().toLowerCase() === "region "+code.toString().toLowerCase()
            || "region "+prop.toString().toLowerCase() === code.toString().toLowerCase()
        );
    }

    // -------------------------- Zip Codes -----------------------------------

    static dumpZipCodeLookupList():any[] {
        var dataSet = ReferenceGeomDenmark.getReferenceGeo().zipcodes;
        var result = dataSet.features.map((feature) => {
            return ({"Postnummer": feature.properties.Postnummer, "POSTBYNAVN":feature.properties.POSTBYNAVN}); 
            });
        return(result);
    }

    static lookupByZipCode(code:number) {
        var dataSet = ReferenceGeomDenmark.zipCodeLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncZipCode(feature.PostNummer, code); 
            });
        return(result);
    }

    static equalityFuncZipCode(prop: number, code:number) {
        return (prop.toString() === code.toString());
    }

    static lookupByZipCodeName(name:string) {
        var dataSet = ReferenceGeomDenmark.zipCodeLookupList;
        var result = dataSet.find((feature) => {
            return ReferenceGeomDenmark.equalityFuncZipCodeName(feature.POSTBYNAVN, name); 
            });
        return(result);
    }

    static equalityFuncZipCodeName(prop: string, code:string) {
        return (prop.toString().toLowerCase() === code.toString().toLowerCase());
    }

    // -------------------------------------------------------------------------------------------------------

    static hasRefGeoBeenLoaded():boolean {
        return ReferenceGeomDenmark.refGeom && !ReferenceGeomDenmark.isLoading;
    }

    static isLoadingReferenceGeo():boolean {
        return ReferenceGeomDenmark.isLoading;
    }

    static loadReferenceGeo():Promise<void> {
        let res = new Promise<void>((resolve, reject) => {
            if (ReferenceGeomDenmark.hasRefGeoBeenLoaded()) {
                resolve();
            } else {
                Utils.asyncFetchJsonFromUrl(ReferenceGeomDenmark.dataUrl)
                .then((data) => {
                    ReferenceGeomDenmark.refGeom = data;
                    ReferenceGeomDenmark.isLoading = false;
                    resolve();
                }).catch((err) => {
                    ReferenceGeomDenmark.isLoading = false;
                    reject("Error loading geojson: "+err.message);
                });
                ReferenceGeomDenmark.isLoading=true;    
            }
        });
        return res;
    }

    static getReferenceGeo():ReferenceGeomType {
        /* tslint:disable */
        // var ref_geom= {
        //     region: {
        //         type: "",
        //         crs: {
        //             type: "",
        //             properties: {},
        //         },
        //         features: [],
        //     },
        //     municipality: {
        //         type: "",
        //         crs: {
        //             type: "",
        //             properties: {},
        //         },
        //         features: [],
        //     },
        //     zipcodes: {
        //         type: "",
        //         crs: {
        //             type: "",
        //             properties: {},
        //         },
        //         features: [],
        //     },
        // };
        /* tslint:enable */

        // const waitMilliSeconds = 500;
        // const maxRetries = 10;
        // let retries=0;

        // function pauseComp(ms:number) {
        //     var curr = new Date().getTime();
        //     ms += curr;
        //     while (curr < ms) {
        //         curr = new Date().getTime();
        //     }
        // } 

        // while (!refGeom && retries < maxRetries) {
        //     // Not done loading...
        //     appMessageDispatch(actionSetInfoMessage("Loading Reference Geometries..."+retries);
        //     pauseComp(waitMilliSeconds);
        //     retries ++;
        // }
        // if (!refGeom) {
        //     appMessageDispatch(actionSetErrorMessage("Error loading geojson for Reference Geometries");
        // } else {
        //     appMessageDispatch(actionClearInfoMessage(;
        // }

        return(ReferenceGeomDenmark.refGeom);
    }
}