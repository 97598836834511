import * as React from 'react';

import { Button, Form, FormControl, Card, Modal, Alert } from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import {MapInfo, PictureLayoutType, PictureSize, PopupLayout, ValidationType} from '../common/managers/Types';
import { MitBody, MitButton, MitCheckBox, MitDialog, MitFooter, MitHeader, MitInfo, SelectWithOptionsV2} from './ComponentUtils';
import ReactDOM from 'react-dom';
// import { SketchPicker } from 'react-color';

type Props = {
    showWindow: number;
    mapInfo: MapInfo;
    callbackOnMapStyling: (mapInfo:MapInfo)=>void;
    callbackOnDismiss: ()=>void;
};

export type OptionType = {
    [value:string]:string;
};

export function MapStyling(props:Props) {
    const [mapInfo, setMapInfo] = React.useState<MapInfo>(props.mapInfo)
    const [changeTable, setChangeTable] = React.useState<{[id:string]:any}>({})
    
    function handleChange(event:any) {
        setChangeTable((current) => ({...current, [event.target.id as string]: event.target.value}))
    }

    function validateForm() { return true;}
    function handleClose(e:any) { e.preventDefault(); props.callbackOnDismiss(); }

// ===================================== EVENTS ===================================
    function nameOnChange(evt:any) {
        nameOnBlur(evt);
    }

    function nameOnBlur(evt:any) {
        evt.preventDefault();
        let newMapInfo = {...mapInfo, mapTitle:evt.target.value}
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function pictureLayoutTypeOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpPictureLayout: evt.target.value || PictureLayoutType.Center};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function pictureSizeOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpPictureSize: evt.target.value || PictureSize.Medium};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function popupLayoutOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpLayout:evt.target.value || PopupLayout.ShowAll};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function generateSelectionList(list:string[]):{label:string, value:string}[] {
        return list.map((item) => {
            return {value: item, label: Localization.getText(item)};
        });
    }

    // generatePictureLayoutList(list:any[]):any[] {
    //     function getLabel(val:PictureLayoutType) { 
    //         return Localization.getText(val);
    //     }

    //     return list.map((item) => {
    //         return {value: item, label: getLabel(item)};
    //     });

    //     // return [ 
    //     //     {value: PictureLayoutType.Center, label: getLabel(PictureLayoutType.Center)},
    //     //     {value: PictureLayoutType.Left, label: getLabel(PictureLayoutType.Left)},
    //     //     {value: PictureLayoutType.Right, label: getLabel(PictureLayoutType.Right)}
    //     // ];
    // }

    // generatePictureSizeList(list:any[]):any[] {
    //     function getLabel(val:PictureSize) { 
    //         return Localization.getText(val);
    //     }

    //     return list.map((item) => {
    //         return {value: item, label: getLabel(item)};
    //     });
        
    //     return [ 
    //         {value: PictureSize.Small, label: getLabel(PictureSize.Small)},
    //         {value: PictureSize.Medium, label: getLabel(PictureSize.Medium)},
    //         {value: PictureSize.Large, label: getLabel(PictureSize.Large)}
    //     ];
    // }

    function getValidationStateForNumber(value:number):ValidationType {
        let val = Utils.parseNumber(value+"");
        if (isNaN(val!)) {
            return "error";
        } else {
            return "success";
        }
    }

    function numberOnChange(e:any, setterFunc:(value:number)=>void) {
        let value = e.target.value;
        if (getValidationStateForNumber(value) === "success" || value==="") {
            setterFunc(value);
        }
    }

    // let MapSettingsButton = true ? (
    //     <Glyphicon 
    //         style={{float:"left"}}
    //         glyph="cog" 
    //         onClick={(e) => this.onClickMapSettings(e)}
    //         title={Localization.getText("Map Settings")}
    //     />
    //   ) : null;
  
    let labelColumn:JSX.Element|null=null;
    labelColumn = true ? (
        <div className="row">
            <div className="col-sm-4" >                        
                {Localization.getText("Display fields")}
            </div>
            <div className="col-sm-7 mit-form-control" > 
            XXX
            </div>
            </div>
    ): null;

    let checkItem:JSX.Element|null=null;
    checkItem = true ? (
        <div className="row">
        <div className="col-sm-4" >                        
            {Localization.getText("Show in Legend")}
        </div>
        <div className="col-sm-7 mit-form-control" > 
        {
            <MitCheckBox variant='normal'
                checked={true}
                onChange={(e) => {return null;}}
            />              
        }
        </div>
        </div>
    ) : null;

    // const keys = Object.keys(PictureLayoutType); // .filter(k => typeof PictureLayoutType[k as any] === "number"); // ["A", "B"]
//    let list = this.generatePictureLayoutList(keys);
    let selectPictureLayoutType = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PictureLayoutType)), 
        "PictureLayoutType", 
        (evt) => pictureLayoutTypeOnChange(evt),
        Localization.getText("Picture Layout"),
        mapInfo && mapInfo.popUpPictureLayout ? mapInfo.popUpPictureLayout.toString() : "",
        () => {return false;}
    );
    let RenderPictureLayoutType = (
        <div className="row">
        <Form.Label column sm={5}>                        
            {Localization.getText("Picture Layout")}
            </Form.Label>
        <div className="col-sm-7 mit-form-control" >{selectPictureLayoutType}</div>
        </div>
    );

    let selectPopupLayout = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PopupLayout)), 
        "PopupLayout", 
        (evt) => popupLayoutOnChange(evt),
        Localization.getText("Popup layout"),
        mapInfo && mapInfo.popUpLayout,
        () => {return false;}
    );
    let RenderPopupLayout = (
        <div className="row">
        <Form.Label column sm={5}>                        
            {Localization.getText("Popup layout")}
            </Form.Label>
        <div className="col-sm-7 mit-form-control" >{selectPopupLayout}</div>
        </div>
    );

    // const keys2 = Object.keys(PictureSize); // .filter(k => typeof PictureSize[k as any] === "number"); // ["A", "B"]
    // let list2 = this.generatePictureSizeList(keys2);
    let selectPictureSize = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PictureSize)),
        "PictureSize", 
        (evt) => pictureSizeOnChange(evt),
        Localization.getText("Picture Size"),
        mapInfo && mapInfo.popUpPictureSize ? mapInfo.popUpPictureSize.toString() : "",
        () => {return false;}
    );
    let RenderPictureSize = (
        <div className="row">
        <Form.Label column sm={5}>                       
            {Localization.getText("Picture Size")}
        </Form.Label>
        <div className="col-sm-7 mit-form-control" >{selectPictureSize}</div>
        </div>
    );

    let InputMapTitle = (
        <FormControl
            size="sm"
            style={{marginBottom:"20px"}}
            placeholder={Localization.getText("Savemap:defaultFileName")}
            type='text'
            value={(mapInfo && mapInfo.mapTitle) || ""}
            onChange={(evt) => nameOnBlur(evt)}
        
        />
    );

    let RenderMapTitle = (
        <div className="row">
        <Form.Label column sm={5}>
            {Localization.getText("Name")}
        </Form.Label>
        <div className="col-sm-7 mit-form-control" >{InputMapTitle}</div>
        </div>
    );

    // let renderColorChooser = this.state.displayColorPickerForIdx !== undefined ? (
    //     <Panel className="mit-color-chooser-panel" onBlur={(e) => this.onColorChooserBlurred(this.state.displayColorPickerForIdx!)}>
    //     <SketchPicker 
    //         color={styling.divisions!.list[this.state.displayColorPickerForIdx!].color} 
    //         disableAlpha={true}
    //         presetColors={[]}
    //         onChange={(color) => this.onColorChanged(color.hex, this.state.displayColorPickerForIdx!)}
    //     />
    //     <Button onClick={(e) => this.onColorChooserBlurred(this.state.displayColorPickerForIdx!)}>Ok</Button>
    //     </Panel>
    // ) : null;
    // let selectColor = (
    //     <div></div>
    // );
    // let RenderColorX = (
    //     <div className="row">
    //     <div className="col-sm-4" >                        
    //         {Localization.getText("Picture Size")}
    //     </div>
    //     <div className="col-sm-7 mit-form-control" >{selectColor}</div>
    //     </div>
    // );

    // leSizenputStyle = {width:"80px", marginBottom:"5px"}; 
    // let RenderPictureHeight = (
    //     <div className="row">
    //     <div className="col-sm-4" >                        
    //         {Localization.getText("Picture Max Height")}
    //     </div>
    //     <div className="col-sm-7 mit-form-control" >
    //     <FormGroup 
    //         bsSize="small"
    //         controlId="formBasicText"
    //         style={numInputStyle}
    //         validationState={this.getValidationStateForNumber(mapInfo.popUpPictureMaxHeight)}
    //     >
    //         <FormControl
    //             bsSize="small"
    //             type="number"
    //             autoComplete="off"
    //             bsClass="form-control XX_MitLegendScaleInput"
    //             value={Utils.formatNumber(mapInfo.popUpPictureMaxHeight)}
    //             placeholder={Localization.getText("Enter number")}
    //             onChange={(e) => this.numberOnChange(e, (value) => {
    //                 let newMapInfo = {...mapInfo, popUpPictureMaxHeight:value};
    //                 this.state.callbackOnMapStyling(newMapInfo);        
    //             })}
    //         />
    //     </FormGroup>   
    //     </div>
    //     </div>
    // );
    // let RenderPictureWidth = (
    //     <div className="row">
    //     <div className="col-sm-4" >                        
    //         {Localization.getText("Picture Max Height")}
    //     </div>
    //     <div className="col-sm-7 mit-form-control" >
    //     <FormGroup 
    //         bsSize="small"
    //         controlId="formBasicText"
    //         style={numInputStyle}
    //         validationState={this.getValidationStateForNumber(mapInfo.popUpPictureMaxWidth)}
    //     >
    //         <FormControl
    //             bsSize="small"
    //             type="number"
    //             autoComplete="off"
    //             bsClass="form-control XX_MitLegendScaleInput"
    //             value={Utils.formatNumber(mapInfo.popUpPictureMaxWidth)}
    //             placeholder={Localization.getText("Enter number")}
    //             onChange={(e) => this.numberOnChange(e, (value) => {
    //                 let newMapInfo = {...mapInfo, popUpPictureMaxWidth:value};
    //                 this.state.callbackOnMapStyling(newMapInfo);        
    //             })}
    //         />
    //     </FormGroup>   
    //     </div>
    //     </div>
    // );

    return ReactDOM.createPortal(
            <MitDialog show={props.showWindow} style={{right: "15px",top:"5px",width: "400px"}}>
                <MitHeader position="start">
                    {Localization.getText("Map Settings")}
                </MitHeader>
                <MitBody style={{fontSize:"12px", textAlign:"left"}}>
                    <MitInfo style={{paddingBlock:"4px"}}>
                    </MitInfo>
                    {/* <div className="row">
                        <div className="col-sm-4 col-md-4" >                        
                            {Localization.getText("Map title")}
                        </div>
                        <div className="col-sm-7 col-md-8" >
                        <FormGroup
                            style={{paddingBottom:"5pt"}}
                        >
                            <FormControl
                                bsSize="small"
                                type="text"
                                disabled={false}
                                value={mapInfo.mapTitle}
                                placeholder={Localization.getText("Enter title of map")}
                                onChange={(e) => this.nameOnChange(e)}
                                onBlur={(e) => this.nameOnBlur(e)}
                            />
                        </FormGroup>
                        </div>
                    </div> */}
                    {RenderMapTitle}
                    {RenderPictureSize}
                    {RenderPictureLayoutType}
                    {RenderPopupLayout}
                    {/* {labelColumn}
                    {checkItem} */}
                    <div className="row">
                        <div className="col-sm-6" >{' '}</div>
                        <div className="col-sm-2" >
                            
                        </div>
                        <div className="col-sm-6" >{' '}</div>
                    </div>
                </MitBody>
                <MitFooter>
                <MitButton
                                variant="close"
                                onClick={(e) => handleClose(e)}
                            >
                                {Localization.getText("Close")}
                            </MitButton>
                </MitFooter>
            </MitDialog>
            , document.getElementById("Mit-MapOverlay") || document.body)
}
