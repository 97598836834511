import { LicensingContext, Logger, SessionContext } from "@viamap/viamap2-common"
import React from "react";
import { ApplicationStateContext, actionUpdateAvailableFeatures } from "src/states/ApplicationState";

/** This componennt is added to application three at places where ither user session or license may change. This component forces a refresh of access rights. 
 * ToDo: Move to viamap2-common
*/
export const AccessRightsUpdater = (props:{children:any}) => {
   const {state:sessionState} = React.useContext(SessionContext);
   const {state:licensingState} = React.useContext(LicensingContext);
   const {state:applicationState, dispatch:applicationStateDispatch} = React.useContext(ApplicationStateContext);

   React.useEffect(() => {
      applicationStateDispatch(actionUpdateAvailableFeatures(sessionState.userRoles, licensingState?.currentlicense));
      Logger.initialize(licensingState.product, sessionState.userRef, sessionState.userName);
   }, [sessionState.userRoles, licensingState.currentlicense]);

   return (
      <>
      {props.children}
      </>
   );
}