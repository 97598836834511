import { Localization } from "@viamap/viamap2-common"
import { EC2 } from "aws-sdk"
import React from "react"
import { BsList, BsMenuApp, BsTrash } from "react-icons/bs"
import { MitButton } from "src/components/ComponentUtils"

type favBoxes = {
  [favName:string]:string[]
}

const BoxList = [
  "Ortofoto",
  "Område",
  "Arealer",
  "Højder",
  "Handelsoplysninger",
  "Ejerforhold",
  "Samlet Fast Ejendom",
  "Mat Oversigt",
  "Matrikler",
  "Matrikler Seperat",
  "BBR Oversigt",
  "BBR",
  "BBR Teknik",
  "Tingbog - Ejendom",
  "Tingbog - Hæftelser",
  "Tingbog - Servitutter",
  "Tingbog - Adkomster",
  "Virksomheder på Ejendom",

  "Vurd 2020",
  "Vurd 2019",
  "Vurd 2018",
  "Vurd 2017",
  "Vurd 2012",
  "Lokalplaner",
  "Kommuneplan",
]

export function FavEditor(props:{
  show: boolean,
  favBoxes: favBoxes,
  setFavBoxes: (favBoxes:favBoxes) => void,
  onClose: () => void
}) {
  const conRef = React.useRef<HTMLDivElement>(null)

  function dragElement(key: string, idx:number) {
    let x = window.structuredClone(props.favBoxes);
    let lastIDX = x["minFavorit"].findIndex((a) => a == key)
    x["minFavorit"] = x["minFavorit"].filter((a) => a !== key)
    x["minFavorit"].splice(lastIDX > idx ? idx : Math.max(0, idx - 1), 0, key)
    props.setFavBoxes(x)
  }

  function deleteBox(key:string) {
    let x = window.structuredClone(props.favBoxes);
    x["minFavorit"] = x["minFavorit"].filter((a) => a !== key)
    props.setFavBoxes(x)
  }

  function addBox(key:string) {
    let x = window.structuredClone(props.favBoxes);
    x["minFavorit"] = [...x["minFavorit"], key]
    props.setFavBoxes(x)
  }

  if (!props.show) {
    return <></>
  }

  return (
    <div className="ModernBox" style={{left:"50%",transform:"translateX(-50%)", top:"25%", position:"absolute", backgroundColor:"White", borderRadius:"7px", minWidth:"200px", minHeight:"300px", boxShadow:"0px 0px 3px 3px #0002", zIndex:"3"}}>
      <h4>{Localization.getText("Favorite")}</h4>
      <div ref={conRef} className="FavItemCon"
        style={{display:"flex", flexDirection:"column"}}
      >
        {props.favBoxes["minFavorit"].map((a,idx) => {
          return (
            
            <React.Fragment key={a}>
              <DropArea areaNr={idx} droppedKey={(key) => dragElement(key, idx)} />
              <FavItem key={a} favKey={a} onDelete={() => deleteBox(a)} />
            </React.Fragment>
          )
        })}
        <DropArea areaNr={-1} droppedKey={(key) => dragElement(key, props.favBoxes["minFavorit"].length)} />
      </div>

      <select style={{textAlign: "center", marginInline:"2px", borderWidth:"2px"}} value={""} onChange={(ev) => addBox(ev.target.value)} >
        <option disabled selected value={""}> -- {Localization.getText("Add")} -- </option>
        {BoxList.filter((a) => !props.favBoxes["minFavorit"].includes(a)).map((val) => {
            return <option value={val} key={val} >{val}</option>
          })
        }
      </select>
      <MitButton onClick={() => props.onClose()} variant="normal">{Localization.getText("Close")}</MitButton>
    </div>
  )
}

function DropArea(props: {
  areaNr: number,
  droppedKey: (key:string) => void,
}) {
  const [drag, setDrag] = React.useState(false)

  return <div onDragEnter={() => setDrag(true)}
    onDragLeave={() => setDrag(false)}
    onDrop={(ev) => {
      let favKey = ev.dataTransfer.getData("text/plain")
      favKey && props.droppedKey(favKey)
      setDrag(false)
    }}
  className={"DropArea "+(drag?" Act":"")} >

  </div>
}

function FavItem(props: {
  favKey:string;
  onDelete?: () => void;
  onDrag?:() => void;
}) {
  const [mouseOver, setMouseOver] = React.useState<boolean>(false);

  return (
    <>
    <div draggable={mouseOver} className={"FavItem"} onDragStart={(ev) => 
        ev.dataTransfer.setData("text/plain",props.favKey)
      }>
      <div onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}  className={"ItemHandle"}><BsList/></div>
      <div>{props.favKey}</div>
      <div onClick={() => props.onDelete?.()}><BsTrash /></div>
    </div>
    </>
  )
}