import { SettingsManager } from "@viamap/viamap2-common/dist/managers/SettingsManager"
import { FilterSpec, FilterValue } from "src/states/ExploreSearchState";
import { DynamicDataDrivenLayer } from "./DynamicDataDrivenLayer";

export type FeatureLayerList = {
  [key: string]: FeatureLayer;
};

export type FeatureProperties = {
  [key: string]: any
}

type WMSversions = "1.0.0" | "1.1.0" | "1.1.1" | "1.3.0" 
export type CustomLayerSpecs = {
  "version": WMSversions
  "label": string,
  "group"?: string,
  "useProxy"?: boolean,
  "prefix"?: "https://" | "http://",
  "host": string,
  "path": string,
  "port"?: 443 | 8080,
  "layers": string,
  "serviceURL"?: string,
  "service"?: string,
  "maxZoom": 24 | number,
  "minZoom": 12 | number,
}

export type LayerVariantSelectors = {[id:string]:FilterSpec};
export type LayerVariantValues = {[id:string]:FilterValue};

export type StandardLayerSpecs = CustomLayerSpecs & {
  "priority": number,
  "licenseFeature": string,
  "layerOptions": {
      "format": string,
      "transparent": boolean
  },
  "hideAttributes": boolean,
  "paint"?: {}
  "legendCrop"?:number,
  "legendOffset"?:number,
  "legendLabels"?:{da:string[],en:string[]},
  "legendExclude"?:number[],
  "propertiesToDisplay"?: string[],
  "translationTable"?: {[languCode: string] : {[translation: string]: string}},
  "activeByDefault"?: boolean,
  "variantSelectors"?: LayerVariantSelectors,
  "variantValues"?: LayerVariantValues,
  "dataOptions"?: {[key:`data-${string}`]:any[]},
  "privateUseOnly"?: boolean,
  "before"?:string
  }

export const customLayerDefault: CustomLayerSpecs = {
  "version": "1.3.0",
  "label": "",
  "group": "Other",
  "useProxy": false,
  "prefix": "https://",
  "host": "",
  "path": "",
  "port": 443,
  "layers": "",
  "maxZoom": 24,
  "minZoom": 12,
} as const

function CustomLayerSpecsChecker(layer: {}) {
  let defaults = {}
  Object.keys(customLayerDefault).forEach((key) => {
    defaults = {...defaults ,[key]:(layer[key] == undefined ? customLayerDefault[key] : layer[key])}
  }) 

  const resultingLayer = {...layer, ...(defaults as CustomLayerSpecs)}

  const check = Object.keys(customLayerDefault).every((key) => {
    return resultingLayer[key] !== ""
  })

  if (check) {
    return resultingLayer
  }
  if (resultingLayer.label !== "") {
    throw new Error(resultingLayer.label + "isn't following custom layerSpecs")
  }
  throw new Error("Layer without label isn't following custom layerSpecs")
}

const onlyStardardLayersSpecsDefaults = {
  "licenseFeature": "",
  "layerOptions": {
      "format": "image/png",
      "transparent": true
  },
  "hideAttributes": true,
  "propertiesToDisplay": [],
  "priority":999,
}

function CustomLayerToStandard(layer: CustomLayerSpecs):StandardLayerSpecs {
  let layerOptions = onlyStardardLayersSpecsDefaults.layerOptions
  if ("layerOptions" in layer) {
    layerOptions = {...layerOptions, ...(layer.layerOptions as any)}
  }
  let hideAttributes:boolean = onlyStardardLayersSpecsDefaults.hideAttributes
  if ("hideAttributes" in layer) {
    hideAttributes = (layer.hideAttributes) === false ? false : true  
  }
  let licenseFeature = (("licenseFeature" in layer) && layer.licenseFeature as string) || onlyStardardLayersSpecsDefaults.licenseFeature
  let priority = (("priority" in layer) && layer.priority as number) || onlyStardardLayersSpecsDefaults.priority
  return {...layer, layerOptions, hideAttributes, licenseFeature, priority}
}

export function getFeatureLayerGroupTranslation(languageCode:string, groupName: string) {
  let groupTranslationTable:{[language:string]:{[name:string]:string}} = SettingsManager.getSystemSetting("layerGroupTranslations")
  return groupTranslationTable[languageCode][groupName] || groupName;
}

export const FEATURELAYERTYPE_WMS="WMS";
export class FeatureLayer {
  layer:StandardLayerSpecs;
  
  constructor(layer: {}) {
    let Currlayer = CustomLayerSpecsChecker(layer)
    this.layer = CustomLayerToStandard(Currlayer)
  }

  public copy() {
    return new FeatureLayer(window.structuredClone(this.layer))
  }

  public getType() {
    return "WMS";
  }

  public getWithKey() {
    return {[this.layer.label] : this}
  }

  public getUrl() : string {
    const layer = this.layer
    return layer.prefix + layer.host + ":" + layer.port + layer.path
  }

  
  public get minZoom() : number {
    return this.layer.minZoom
  }

  public setStyling(value: any) {
    let newPaint = {}
    Object.keys(value).filter((a) => a.includes("raster")).forEach((a) => {
      newPaint = {...newPaint, [a]:value[a]?.[0]}
    })
    this.layer.paint = {...this.layer.paint, ...newPaint}
    if (value["style"]) {
      this.layer.layerOptions = {...this.layer.layerOptions, ...{styles: value["style"]?.[0]}}
    }

    let newdataOptions = {}
    Object.keys(value).filter((a) => a.includes("data-")).forEach((a) => {
      newdataOptions = {...newdataOptions, [a]:value[a]}
    })
    this.layer.dataOptions = {...this.layer.dataOptions, ...newdataOptions}
    this.layer.variantValues = {...this.layer.variantValues, ...value}
  }

  public getTileURL() {
    let urlParams = {
      "VERSION":this.layer.version,
      "SERVICE":"WMS",
      "REQUEST": "GetMap",
      "STYLES" : ((this.layer.layerOptions && "styles" in this.layer.layerOptions) ? this.layer.layerOptions.styles : ''),
      "LAYERS" : this.layer.layers,
      "BBOX": "{bbox-epsg-3857}",
      "TRANSPARENT":"TRUE",
      "HEIGHT": 256,
      "WIDTH": 256,
      "FORMAT": (this.layer.layerOptions && this.layer.layerOptions.format) || "image/png",
      "TILED": "TRUE",
      ...(GetVersionDepended(this.layer.version, {"SRS": "EPSG:3857"})),
      ...("urlParams" in this.layer ? (this.layer.urlParams as any) : {}),
    }
    const url = this.layer.useProxy ? this.getUrl().replace(this.layer.prefix || "https://", "proxy://") : this.getUrl()
    let test = constructPathFromURLParams(url, urlParams, ["BBOX"])
    return test
  }

  public getinformationURL (BBOX:string, HEIGHT:number, WIDTH:number, X:number, Y:number, extraParameters?:{}) {
    let urlParams = {
        "SERVICE": "WMS",
        "VERSION": this.layer.version,
        "REQUEST": "GetFeatureInfo",
        "LAYERS" : this.layer.layers,
        "QUERY_LAYERS": this.layer.layers,
        "BBOX": BBOX,
        "FEATURE_COUNT": 10,
        "STYLES": ((this.layer.layerOptions && "styles" in this.layer.layerOptions) ? this.layer.layerOptions.styles : ''),
        "HEIGHT": HEIGHT,
        "WIDTH": WIDTH,
        "INFO_FORMAT": "text/plain",
        "FORMAT": (this.layer.layerOptions && this.layer.layerOptions.format) || "image/png",
        "TILED": "FALSE",
        ...(GetVersionDepended(this.layer.version, {"SRS": "EPSG:3857","X": X,"Y": Y})),
        ...("urlParams" in this.layer ? (this.layer.urlParams as any) : {}),
        ...("getInfoOptions" in this.layer ? (this.layer.getInfoOptions as any) : {}),
        ...(extraParameters || {})
    };
    return constructPathFromURLParams(this.getUrl(), urlParams)
  }
  
  public getLegendURL(extraParameters?:{}) {
    let urlParams = {
      "SERVICE":"WMS",
      "REQUEST": "GetLegendGraphic",
      "VERSION": this.layer.version,
      "FORMAT": "image/png",
      "SLD_VERSION":"1.1.0",
      "LAYER" : this.layer.layers,
      "STYLE" : ("styles" in this.layer.layerOptions ? this.layer.layerOptions.styles : ''),
      ...("urlParams" in this.layer ? (this.layer.urlParams as any) : {}),
      ...(extraParameters || {})
    };
    return constructPathFromURLParams(this.getUrl(), urlParams);
  }

  public toString() {
    JSON.stringify(this.layer)
  }

  public valueOf() {
    return this.layer
  }
}

export function DefaultExtractInformationResponseProperties (response: any, propertiesToDisplay:string[]) {
  let feature: FeatureProperties[] = [];
  if (response && response.features && response.features.length > 0) {
      for (let i = 0; i < response.features.length; i++) {
          const prop = response.features[i].properties;
          if (prop) {
              let res: FeatureProperties = {};
              if (propertiesToDisplay.length === 0) {
                  // means that all props should be displayed
                  propertiesToDisplay = Object.keys(prop);
              }
              let isEmpty = true;
              propertiesToDisplay.forEach(element => {
                  let value = prop[element];
                  if (value !== undefined) {
                      isEmpty = false;
                  }
                  let y = String(value);
                  // Format dates to local format
                  if (y.match(/[0-9]*-[0-9]*-[0-9]*T[0-9]*:[0-9]*:[0-9]*Z/)) {
                      let x = new Date(y);
                      value = x.toLocaleString().split(" ")[0];
                  }
                  res[element] = value;
              });
              if (! isEmpty) {
                  feature.push(res);
              }
          }
      }
  }
  return feature || [];
}

function constructPathFromURLParams(path:string, urlParams:{}, keep: string[] = []):string {
  let result = Object.keys(urlParams).reduce<string>(
      (prev, curr, idx) => {
      let next = prev + (idx === 0 && !path.includes("?") ? "?":"&") + curr + "=" + (keep.includes(curr) ? urlParams[curr] : encodeURI(urlParams[curr])) ;
      return next;
      }, 
      path
  );
  return result;
}

function GetVersionDepended(version: WMSversions, obj) {
  const v130map = {
    "SRS": "CRS",
    "X": "I",
    "Y": "J",
  }

  switch (version) {
    case "1.3.0":
      let newObj = {}
      Object.keys(obj).forEach((k) => {
        newObj = {...newObj, [v130map[k]] : obj[k]}
      })
      return newObj
    default: 
      return obj
  }
}

