import React from "react";
import { Modal } from "react-bootstrap";
import ReactDataGrid, { Row } from "react-data-grid";
import { AWSAPIGatewayInterface } from "src/managers/AWSAPIGatewayInterface";
import { FileTable, mapFile } from "./FileTable";
import { Localization } from "@viamap/viamap2-common";
import { BsXLg } from "react-icons/bs";





/// Optional, but might give help indicate what you get from AwsS3
function MapitDataParser(data: any) {
  let resData = {
    userName: data.userName,
    createDate: (data.timeGenerated && data.timeGenerated.toDateString()) || "Unknown",
    expiraDate: (data.endDate && data.endDate.toDateString()) || "Unknown",
    status: (data.endDate) ? ((new Date(data.endDate as string).getTime() - new Date().getTime()) > 0 ? "Active" : "Expired") : "Unkown",
    layers: data.layers? data.layers.length : 0,
    layerName:  (data.layers && data.layers[0] && "("+data.layers.length+")"+ data.layers[0].datasetname) || "(0)Empty",
    createDateSort: data.timeGenerated && new Date(data.timeGenerated).getTime() || 0,
    expiraDateSort: data.endDate && new Date(data.endDate).getTime() || 0,
  };
  return resData;
}

/// Optional, but usefull if you want to Sort after another value than key of Column
function sortMapper(elem: mapFile, idx: number, sortColumn: string) {
  let returnValue = {id: idx, value: (elem.data && elem.data[sortColumn]) || undefined};
  switch (sortColumn) {
    case "layerName":
      returnValue.value = elem.data && elem.data.layers;
      break;
    case "createDate":
      returnValue.value = elem.data && elem.data.createDateSort;
      break;
    case "expiraDate":
      returnValue.value = elem.data && elem.data.expiraDateSort;
      break;
    default:
      break;
  }
  return returnValue;
}
  
  // Change Name with Translationskey
const mapitFileColumns: any[] = [
  {key: 'fileName', name: 'Filnavn', width: 300, sortable: false, resizable: false},
  {key: 'status', name: 'Status', width: 80, sortable: true, resizable: false},
  {key: 'userName', name: 'User', width: 180, sortable: true, resizable: true},
  {key: 'createDate', name: 'oprettet', width: 135, sortable: true, resizable: false},
  {key: 'expiraDate', name: 'udløbsdato', width: 135, sortable: true, resizable: false},
  {key: 'layerName', name: 'Lag navn', width: 180, sortable: true, resizable: true},
];

/// Used for support for old React Coponents
export const MaplinkOverview = (prop: { show: boolean, close: ()=> void}) => {

  function onClickClose() {
    prop.close();
  }

  if (!prop.show) {
    return (null);
  }

  const closeButton = (
    <BsXLg 
      id="mit-demography-report-close-button"
      onClick={() => onClickClose()}
      title={Localization.getText("Close")}
    />
  );

  return (
    <Modal.Dialog className="mit-report-dialog" >
      <Modal.Header>
        <Modal.Title>
        {closeButton}
        Fil oversigt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <FileTable 
            fileList={async () => (await AWSAPIGatewayInterface.listFolderS3("savedmaps/") as any[]).filter((e) => e !== "savedmaps/")} 
            fileGetter={AWSAPIGatewayInterface.loadStateS3} 
            columns={mapitFileColumns} 
            fileType={"mapit"} 
            dataParser={MapitDataParser} 
            LockupMapper={sortMapper} 
          />
        </form>
      </Modal.Body>
    </Modal.Dialog>
  );
};