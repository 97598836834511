import {SelectionOptions, LayerType,MitDataType} from '../common/managers/Types';

export var importMenuStructure:SelectionOptions = {
    heading: "Map Type",
    options: [
    {
        label: LayerType.GeoCode,
        value: LayerType.GeoCode,
        weight: 20,
        children: {
            heading: "Address",
            fieldselectionlist: [            {
                name: MitDataType.Address,
                mandatory:true,
                mitDataType:MitDataType.Address,
            },
            {
                name: MitDataType.Address2,
                mandatory:false,
                mitDataType:MitDataType.Address2,
            },
            // ToDo: If reintroduced then need logic in to include ZipCode in AdressConcatenated data value
            // {
            //     name: MitDataType.AdmReg_DK_ZipCodeId,
            //     mandatory:false,
            //     mitDataType:MitDataType.AdmReg_DK_ZipCodeId,
            // },
            {
                name: MitDataType.Address3,
                mandatory:false,
                mitDataType:MitDataType.Address3,
            }
            ]
        }
    },
    {
        label: LayerType.Point,
        value: LayerType.Point,
        weight: 50,
        children:   {
            heading: "Coordinate System",
            options: [      {
            label: LayerType.PointWGS84,
            value: LayerType.PointWGS84,
            weight: 50,
            children: {
                heading: "Lat/Lon",
                fieldselectionlist: [{
                    name: MitDataType.Coord_WGS84_Lat,
                    mandatory:true,
                    mitDataType:MitDataType.Coord_WGS84_Lat,
                },
                {
                    name: MitDataType.Coord_WGS84_Lon,
                    mitDataType:MitDataType.Coord_WGS84_Lon,
                    mandatory:true,
                },
            ],
            }
        },
        {
            label: LayerType.PointUTM32,
            value: LayerType.PointUTM32,
            weight: 50,
            children: {
                heading: "UTM32",
                fieldselectionlist: [        {
                    name: MitDataType.Coord_UTM32_X,
                    mandatory:true,
                    mitDataType:MitDataType.Coord_UTM32_X,
                },
                {
                    name: MitDataType.Coord_UTM32_Y,
                    mitDataType:MitDataType.Coord_UTM32_Y,
                    mandatory:true,
                },],
            }
        },
        {
            label: LayerType.PointCadaster,
            value: LayerType.PointCadaster,
            weight: 50,
            children: {
                heading: "Matrikelimport",
                fieldselectionlist: [        {
                    name: MitDataType.Ejerlavskode,
                    mandatory:true,
                    mitDataType:MitDataType.Ejerlavskode,
                },
                {
                    name: MitDataType.MatrikelNr,
                    mitDataType:MitDataType.MatrikelNr,
                    mandatory:true,
                },],
            }
        }]},
    },
    {
        label: LayerType.Area,
        value: LayerType.Area, 
        weight: 20,
        children: {
            heading: "Org Unit",
            options: [        {
            label: LayerType.AreaMunicipality,
            value: LayerType.AreaMunicipality,
            weight: 30,
            children:   {
                heading: "Designator",
                options: [      {
                    label: LayerType.AreaMunicipalityId,
                    value: LayerType.AreaMunicipalityId,
                    weight: 30,
                    children: {
                        heading: "Municipality Id",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_MunicipalityId,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_MunicipalityId,
                        },],
                    }
                    },
                    {
                    label: LayerType.AreaMunicipalityName,
                    value: LayerType.AreaMunicipalityName,
                    weight: 30,
                    children: {
                        heading: "Municipality Name",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_MunicipalityName,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_MunicipalityName,
                        },],
                    }
                    }]},
            },
            {
                label: LayerType.AreaRegion,
                value: LayerType.AreaRegion,
                weight: 30,
                children:   {
                    heading: "Designator",
                    options: [      {
                    label: LayerType.AreaRegionId,
                    value: LayerType.AreaRegionId,
                    weight: 30,
                    children: {
                        heading: "Region Id",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_RegionId,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_RegionId,
                        },],
                    }
                },
                {
                    label: LayerType.AreaRegionName,
                    value: LayerType.AreaRegionName,
                    weight: 30,
                    children: {
                        heading: "Region Name",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_RegionName,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_RegionName,
                        },],
                    }
                }]},
                },{
                label: LayerType.AreaZipcodes,
                value: LayerType.AreaZipcodes,
                weight: 10,
                children:   {
                    heading: "Designator",
                    options: [      {
                    label: LayerType.AreaZipcodesId,
                    value: LayerType.AreaZipcodesId,
                    weight: 10,
                    children: {
                        heading: "Zipcodes Id",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_ZipCodeId,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_ZipCodeId,
                        },],
                    }
                },
                {
                    label: LayerType.AreaZipcodesName,
                    value: LayerType.AreaZipcodesName,
                    weight: 10,
                    children: {
                        heading: "Zipcodes Name",
                        fieldselectionlist: [            {
                            name: MitDataType.AdmReg_DK_ZipCodeName,
                            mandatory:true,
                            mitDataType:MitDataType.AdmReg_DK_ZipCodeName,
                        },],
                    }
                }]},
                },
                {
                    label: LayerType.AreaParish,
                    value: LayerType.AreaParish,
                    weight: 30,
                    children:   {
                        heading: "Designator",
                        options: [      {
                        label: LayerType.AreaParishId,
                        value: LayerType.AreaParishId,
                        weight: 30,
                        children: {
                            heading: "Parish Id",
                            fieldselectionlist: [            {
                                name: MitDataType.AdmReg_DK_ParishId,
                                mandatory:true,
                                mitDataType:MitDataType.AdmReg_DK_ParishId,
                            },],
                        }
                    },
                    {
                        label: LayerType.AreaParishName,
                        value: LayerType.AreaParishName,
                        weight: 30,
                        children: {
                            heading: "Parish Name",
                            fieldselectionlist: [            {
                                name: MitDataType.AdmReg_DK_ParishName,
                                mandatory:true,
                                mitDataType:MitDataType.AdmReg_DK_ParishName,
                            },],
                        }
                    }]},
                    },
                {
                    label: LayerType.AreaCountry,
                    value: LayerType.AreaCountry,
                    weight: 30,
                    children:   {
                        heading: "Designator",
                        options: [      {
                        label: LayerType.AreaCountryId,
                        value: LayerType.AreaCountryId,
                        weight: 30,
                        children: {
                            heading: "Country Code",
                            fieldselectionlist: [            {
                                name: MitDataType.AdmReg_INT_CountryId,
                                mandatory:true,
                                mitDataType:MitDataType.AdmReg_INT_CountryId,
                            },],
                        }
                    },
                    {
                        label: LayerType.AreaCountryName,
                        value: LayerType.AreaCountryName,
                        weight: 30,
                        children: {
                            heading: "Country Name",
                            fieldselectionlist: [            {
                                name: MitDataType.AdmReg_INT_CountryName,
                                mandatory:true,
                                mitDataType:MitDataType.AdmReg_INT_CountryName,
                            },],
                        }
                    }]},
                    },
                    {
                        label: LayerType.AreaMunicipality_RO,
                        disabled: "ImportEnableRomaniaGeom",
                        value: LayerType.AreaMunicipality_RO,
                        weight: 30,
                        children:   {
                            heading: "Designator",
                            options: [      {
                                label: LayerType.AreaMunicipalityId_RO,
                                value: LayerType.AreaMunicipalityId_RO,
                                weight: 30,
                                children: {
                                    heading: "Municipality Id",
                                    fieldselectionlist: [            {
                                        name: MitDataType.AdmReg_RO_MunicipalityId,
                                        mandatory:true,
                                        mitDataType:MitDataType.AdmReg_RO_MunicipalityId,
                                    },],
                                }
                                },
                                {
                                label: LayerType.AreaMunicipalityName_RO,
                                value: LayerType.AreaMunicipalityName_RO,
                                weight: 30,
                                children: {
                                    heading: "Municipality Name",
                                    fieldselectionlist: [            {
                                        name: MitDataType.AdmReg_RO_MunicipalityName,
                                        mandatory:true,
                                        mitDataType:MitDataType.AdmReg_RO_MunicipalityName,
                                    },],
                                }
                                }]},
                        },
                        {
                            label: LayerType.AreaMunicipality_DK_And_SydSlesvig,
                            disabled: "ImportEnableSydslesvigGeom",
                            value: LayerType.AreaMunicipality_DK_And_SydSlesvig,
                            weight: 30,
                            children:   {
                                heading: "Designator",
                                options: [      {
                                    label: LayerType.AreaMunicipalityId_DK_And_SydSlesvig,
                                    value: LayerType.AreaMunicipalityId_DK_And_SydSlesvig,
                                    weight: 30,
                                    children: {
                                        heading: "Municipality Id",
                                        fieldselectionlist: [            {
                                            name: MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId,
                                            mandatory:true,
                                            mitDataType:MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId,
                                        },],
                                    }
                                    },
                                    {
                                    label: LayerType.AreaMunicipalityName_DK_And_SydSlesvig,
                                    value: LayerType.AreaMunicipalityName_DK_And_SydSlesvig,
                                    weight: 30,
                                    children: {
                                        heading: "Municipality Name",
                                        fieldselectionlist: [            {
                                            name: MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityName,
                                            mandatory:true,
                                            mitDataType:MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityName,
                                        },],
                                    }
                                    }]},
                            },
                            {
                                label: LayerType.AreaCadaster,
                                value: LayerType.AreaCadaster,
                                weight: 50,
                                children: {
                                    heading: "Matrikelimport",
                                    fieldselectionlist: [        {
                                        name: MitDataType.Ejerlavskode,
                                        mandatory:true,
                                        mitDataType:MitDataType.Ejerlavskode,
                                    },
                                    {
                                        name: MitDataType.MatrikelNr,
                                        mitDataType:MitDataType.MatrikelNr,
                                        mandatory:true,
                                    },],
                                }
                            },
                            {
                                label: LayerType.AreaProperty,
                                value: LayerType.AreaProperty,
                                weight: 50,
                                children: {
                                    heading: "MatrikelimportSFE",
                                    fieldselectionlist: [        {
                                        name: MitDataType.Ejerlavskode,
                                        mandatory:true,
                                        mitDataType:MitDataType.Ejerlavskode,
                                    },
                                    {
                                        name: MitDataType.MatrikelNr,
                                        mitDataType:MitDataType.MatrikelNr,
                                        mandatory:true,
                                    },],
                                }
                            }        
                        ]}
    },
    {
        label: LayerType.Grid,
        disabled: "ImportEnableGrid",
        value: LayerType.Grid,
        weight: 20,
        children: {
            heading: LayerType.Grid,
            fieldselectionlist: [            {
                name: MitDataType.Coord_UTM32_X,
                mandatory:true,
                mitDataType:MitDataType.Coord_UTM32_X,
            },
            {
                name: MitDataType.Coord_UTM32_Y,
                mitDataType:MitDataType.Coord_UTM32_Y,
                mandatory:true,
            },
            {
                name: MitDataType.GridSizeMeters,
                mandatory:true,
                mitDataType:MitDataType.GridSizeMeters,
            }
            ]
        }
    }
]
};
