import { useContext, useEffect, useState } from "react";
import { MitBody, MitButton, MitDialog, MitHeader, MitFooter, MitInfo } from "./ComponentUtils";
import { LayerInfo } from "src/common/managers/Types";
import { LayerFunc } from "src/managers/LayerFunc";
import { Localization } from "@viamap/viamap2-common";
import React from "react";
import { SheetFunc } from "src/managers/SheetFunc";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { ExportData } from "src/managers/ExportData";
import { stringify } from 'wkt';
import { GenerateGeomUtils } from "src/managers/GenerateGeomUtils";

type exportType = "xlsx" | "geojson" | "layerInfo"
type callbackEvent = "cancel" | "accept"


type Props= {
  showWindow:number;
  layers?: LayerInfo | LayerInfo[]
  callBack: (evt: callbackEvent) => void
};

function defaultType(layer:LayerInfo) : exportType {
  if (layer.type.includes("Point")) {
    return "xlsx"
  }
  return "geojson"
}

function availableTypes(layer:LayerInfo, debugFeature?: boolean) : exportType[] {
  let debug = (debugFeature ? ["layerInfo"] : []) as exportType[] 
  if (layer.type.includes("Point")) {
    return (["xlsx","geojson"] as exportType[]).concat(debug)
  }
  return (["geojson"] as exportType[]).concat(debug)
}

function nextFormat(layer: LayerInfo, current:exportType, debugFeature?: boolean): exportType {
  const exportTypes = availableTypes(layer, debugFeature)
  const curIdx = exportTypes.findIndex((a) => a === current)
  return exportTypes[curIdx + 1] || exportTypes[0]
}

export function DownloadDialog(props: Props) {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const layers = !props.layers ? [] : "type" in props.layers ? [props.layers] : props.layers
  const [name, setName] = useState(layers.map((a) => a.datasetname))
  const [format, setFormat] = useState(layers.map((a) => defaultType(a)))

  useEffect(() => {
    setName(layers.map((a) => a.datasetname))
    setFormat(layers.map((a) => defaultType(a)))
  },[props.layers])

  function updateName(value:string, idx: number) {
    setName((name) => name.map((a, idy) => idx === idy ? (value ?? "") : a))
  }

  function Accept() {
    layers.map((a,idx) => {
      const exportlayer: (LayerInfo & {export: exportType}) = {...a, datasetname: name[idx], export:format[idx]}
      downloadSingleLayer(exportlayer)
    })
    props.callBack("accept")
  }

  function Cancel() {
    props.callBack("cancel")
  }

  function changeFormat(idx) {
    setFormat((format) => format.map((format,idy) => idx === idy ? nextFormat(layers[idy], format, hasAccessToFeature(Feature.Debugging)): format))
  }
   

  return <MitDialog show={props.showWindow} style={{top:"10px", left:"50%", transform:"TranslateX(-50%)", backgroundColor:"#f0f1f1"}}>
    <MitHeader position="center" >
      {Localization.getText("Export")}
    </MitHeader>
    <MitBody>

      {layers.map((a,idx) => {
        return (<React.Fragment key={a.layerId ?? "Error"}>
          <div style={{color:"grey", textAlign:"left", fontSize:"11px"}}>{Localization.getText("Layer")+": "+(idx+1)}</div>
          <div className="ExportFileName" style={{width:"100%", position:"relative"}}>
            <input style={{color:"black", paddingLeft:"6px"}} type="text" value={name[idx]} placeholder={a.datasetname ?? "Error"} onChange={(ev) => updateName(ev.target.value, idx)} />
            <div style={{position:"absolute", right:"0px", top:"0px", zIndex:"1"}} onClick={() => changeFormat(idx)}>{"."+format[idx]}</div>
          </div>
        </React.Fragment>) 
      })}
    </MitBody>
    <MitFooter>
      <MitButton variant="normal" onClick={Accept}>{Localization.getText("Export")}</MitButton>
      <MitButton variant="close" onClick={Cancel}>{Localization.getText("Cancel")}</MitButton>
    </MitFooter>
  </MitDialog>
}


function downloadSingleLayer(layerInfo: (LayerInfo & {export: exportType})) {
  let blob: Blob|null = null
  let extension = ".xlsx"
  
  switch (layerInfo.export) {
    case "layerInfo":
      blob = new Blob([JSON.stringify(layerInfo)], {type: "application/json"})
      extension = ".layerinfo"
      break;
    case "geojson":
      blob = new Blob([JSON.stringify(validGeoJson(layerInfo.geoJson))], {type: "application/json"})
      extension = ".geojson"
    break;
    case "xlsx":
      let sheet = SheetFunc.jsonToSheet(layerInfo.geoJson.features.map((a) => {
        if (a.geometry.type) {
          if (a.geometry.type === "Point") {
            return {... a.properties, "Lat":a.geometry.coordinates[0], "Lng":a.geometry.coordinates[1], "Wkt":stringify(a.geometry)}
          } else {
            return {... a.properties, "Wkt":stringify(a.geometry)}
          }
        } else {
          return a.properties
        }
      }))
      blob = SheetFunc.createBlob(SheetFunc.sheetToDownload(sheet));
    break;
  }
  ExportData.downloadBlob((layerInfo.datasetname ?? "MapitDownload")+extension, blob)
}

// Currently only converts coords to numbers
function validGeoJson(geojson: any) {
  return GenerateGeomUtils.geoJsonCoordsTransformer(geojson, (coord) => {
    return coord.map((a) => Number(a)) as [number, number]
  })
}