import * as React from 'react';
import { Modal, Form, Alert } from 'react-bootstrap';
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import {Logger} from '@viamap/viamap2-common';
import {Cookies, MitCookies} from '@viamap/viamap2-common';
import { MitBody, MitCheckBox, MitDialog, MitFooter, MitInfo } from './ComponentUtils';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';

type Props = {
    showWindow: number;
    callbackOnCancel: () => void;
    callbackOnCommit: () => void;
    rememberMyAccept?:boolean;
    currentCookie?: MitCookies;
};

export function GDPRNoticeApproval(props:Props) {
   const [rememberMyAccept,setRemeberMyAccept] = React.useState(props.currentCookie ? Cookies.getCookieBoolean(props.currentCookie) : false)
   const {hasAccessToFeature} = React.useContext(ApplicationStateContext)
   

function onClickApply() {
   // ToDo: Refactor to make this Form stateless and move Logging and Cookies up to calling function.
   Logger.logLegalAction("GDPRNoticeApproval","onClickApply","User asked to remember GDPR notice accept of cookie: " + props.currentCookie);
   rememberMyAccept && props.currentCookie && Cookies.setCookieBoolean(props.currentCookie, rememberMyAccept);
   props.callbackOnCommit();
}

function onClickCancel() {
    props.callbackOnCancel();
}

function onChangeRememberMyAccept(e: React.MouseEvent<any, any>) {
   let show=(e.target as HTMLInputElement).checked;
   setRemeberMyAccept(show)
}

function validateForm():boolean {
    return (true);
}

function getGDPRtextContent(cookie: MitCookies | undefined) {

   if (cookie === undefined) {
      return null;
   } 

   let htmlString: string;

   switch (cookie) {
      case MitCookies.RememberMapLinkGDPRAccept:
                   htmlString = hasAccessToFeature(Feature.ThemeNewSec) ?  Localization.getText("GDPRgenererLinkExplorer") : Localization.getText("GDPRgenererLink");
                   break;

      case MitCookies.RememberSaveCustomLayerGDPRAccept:
                  htmlString = hasAccessToFeature(Feature.ThemeNewSec) ?  Localization.getText("GDPRgemFastLagExplorer") : Localization.getText("GDPRgemFastLag");
                   break;
      default:
         htmlString = "";
   }
   let htmlContent = <div dangerouslySetInnerHTML={{__html: htmlString}}/>;
   return htmlContent;
}

    if (props.showWindow) {
      let commitbutton:JSX.Element|null=null;
      let cancelbutton:JSX.Element|null=null;
      commitbutton = <button type="submit" onClick={onClickApply} disabled={!validateForm()} className="btn btn-primary">{Localization.getText("I accept")}</button>;
      cancelbutton = <button type="button" onClick={onClickCancel} className="btn btn-default">{Localization.getText("Cancel")}</button>;

      let OptionallyRenderRememberAcceptanceCheckBox = SettingsManager.getSystemSetting("allowRememberGDPRAcceptance",false) ? (
         <MitCheckBox variant='normal' style={{marginRight:"auto"}} checked={rememberMyAccept} onClick={onChangeRememberMyAccept}>{Localization.getText("Remember my accept")}</MitCheckBox>
      ) : null;
      
      let text = getGDPRtextContent(props.currentCookie);

      return (
        <MitDialog show={props.showWindow} className="mit-gdpr-dialog Flat-Modal">
            {/* <Modal.Header>
               <strong>
               </strong>
            </Modal.Header> */}
            <MitBody className='Flat-LiSpacing'>
               <MitInfo>
                  <h4>
                  {Localization.getText("GDPR Statement")}
                  </h4>
               </MitInfo>
               {text}
            </MitBody>
            <MitFooter className='Flat-ButtonWrap'>
               {OptionallyRenderRememberAcceptanceCheckBox}
                  {commitbutton}{cancelbutton}
            </MitFooter>
        </MitDialog>
      );
    } else {
      return(null);
    }
}