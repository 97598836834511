import React from "react";
import { useState, useEffect, useRef } from "react";
import { Button, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";

import {dawaAutocomplete} from "dawa-autocomplete2"

type pospickerprops = {
  row: any;
  onRowChange: (changes:any, save?:boolean) => void;
  onClose: (save?: boolean) => void; 
};

export function PosPicker(props:pospickerprops) {
  let [inputValue, setInputValue] = useState(props.row.resultAdr)
  let elem = useRef<HTMLInputElement | null>(null);

  function callbackOnSelectedAddress(a:any) {
    if (a.tekst && a.data) {
      props.onRowChange({...props.row, resultAdr: a.tekst, lng: a.data.x, lat: a.data.y}, false);
      setInputValue((old) => {return a.tekst})
    }
    
  }

  useEffect(() => {
    const dawaInput = dawaAutocomplete(elem.current, {
        select: (selected: any) => {
            callbackOnSelectedAddress(selected);
        },
        adgangsadresserOnly: true,
    });
    return () => {dawaInput.destroy()};
  },[])
  
  return (
    <div style={{background:"#000a", isolation:"isolate", position:"fixed",inset:"0px", zIndex:10000}}>
    <div style={{background:"white", position:"absolute", left:"50vw", top:"50vh", transform:"translate(-50%,-50%)", padding:"20px"}}>
      <FormLabel>Søg Adresse</FormLabel><br/>
      <FormLabel>Input: {props.row.adr}</FormLabel>
      <FormControl ref={elem} style={{fontSize:"0.8em", width:"320px"}} value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} />
      <hr/>
      <Button onClick={() => {props.onClose();}} >Close</Button><Button onClick={() => {props.onClose(true);}} >Save</Button>
    </div>
    </div>
  );

}

// export function PosPickerA(props:any) {

//   let [pos, setPos] = useState<any>(props.value);
//   let [input, setInput] = useState<string>(pos.adr || (pos.lat && pos.lng && pos.lat+" , "+pos.lng) || "")
//   let [dawa, setDawa] = useState({} as any)
//   let [dawaState, setDawaState] = useState(false)
//   let [mode, setMode] = useState("LatLng" as "Dawa" | "LatLng" | "None")
//   const elem = useRef({} as HTMLInputElement)
//   const secondFocus = useRef({} as HTMLInputElement);

//   function _disableDawa() {
//     if (dawaState) {
//       dawa.destroy()
//       setDawaState(false);
//     }
//   }
//   function _enableDawa() {
//     if (!dawaState) {
//       initDawaSearchbar();
//       setDawaState(true);
//     }
//   }

//   useEffect(() => {
//     if (props.show && mode === "None") {
//       setMode("LatLng")
//       setPos(props.value)
//       setInput(props.value.adr || (props.value.lat && props.value.lng && props.value.lat+" , "+props.value.lng) || "")
//     }
//     if (!props.show && mode !== "None") {
//       setMode("None");
//       _disableDawa();
//     }
// 
//   }, [props.show, mode, props.value]);

//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   function CallAdvancedChange(e : any) {
    
//     let x = (e.target.value as string);
//     if (x.length > 0) {
//       if (!isNaN((x as any)[0] ) && !isNaN(parseFloat((x as any)[0]))) {
//         _disableDawa()
//         console.log(e.target.value)
//         LatOnChange(e, true);
//         setMode("LatLng")
//         setInput(e.target.value)
//       } else {
//         _enableDawa()
//         console.log(elem.current)
//         elem.current.value = e.target.value;
//         elem.current.onchange !== null && elem.current.onchange(e)
//         setInput(e.target.value)
//         setMode("Dawa")
//       }
//     } else {
//       _disableDawa()
//       setInput("")
//       setMode("LatLng")
//     }
//   }

//   function LatOnChange(e : any ,test?: boolean) {
//     let valueS = e.target.value as string
//     valueS = valueS.replace(/ /g,"")
//     let stringArr = valueS.split(/[,.]/)
//     switch(stringArr.length) {
//       case 0:
//         valueS = "";
//         break
//       case 1:
//         valueS = stringArr[0]
//         break
//       case 2: 
//         valueS = stringArr[0]+"."+stringArr[1]
//         break;
//       case 3:
//         valueS = stringArr[0]+"."+stringArr[1]+", "+stringArr[2]
//         break
//       default:
//         valueS = stringArr[0]+"."+stringArr[1]+", "+stringArr[2]+"."+stringArr[3]
//     }
//     let x = valueS.split(", ");
//     if (x.length > 1) {
//       setPos({...pos, lat:x[0], lng:x[1]});
//       if (!test) {
//         secondFocus.current.focus();
//       }
//     }
//     else {
//       setPos({...pos, lat:x[0]})
//     }

//   }

//   function getPos(): React.CSSProperties {
//     // console.log(props.cell.current)
//     if (props.cell.current !== null) {
//       let x = (props.cell.current).parentElement.getBoundingClientRect()
//       let y = (props.cell.current).parentElement.nextSibling.getBoundingClientRect()
//       // console.log(x);
//       return {
//         width: (y.right - x.left)-10+"px",
//         top: x.top+"px",
//         left: x.left+"px",
//         height: (x.bottom - x.top)+"px"
//       }
//     }
//     return {}
//   }

//   if (!props.show) {
//     return null
//   }

//   function accept(e:any) {
//     props.setValue(pos);
//   }



// function initDawaSearchbar() {


// async function callbackOnSelectedAddress(address: any): Promise<void> {
//     if (address.data.x) {
//       setPos({lat: address.data.y, lng: address.data.x, adr: address.tekst})
//     }
// }

//   return (
//       <div style={{...props.style, ...getPos(), height:"", border:"1px solid black"}}>
//         {/* <div id="mit-searchbar-wrapper">
//                 <div className="autocomplete-container">
                    // <FormControl 
                    //   style={{borderRadius:"0", ...getPos(), left:0,top:0}} 
                    //   ref={elem} 
                    //   value={input} 
                    //   onChange={CallAdvancedChange} 
                    //   placeholder={"søg adresse"} 
                    //   id="dawa-autocomplete-input" />
//                 </div>
//         </div>
//        */}
       
//       <Col>
//       <div id="mit-searchbar-wrapper">
//                 <div className="autocomplete-container">
//                     <input 
//                       ref={elem} 
//                       style={{borderRadius:"3px 3px 0px 0px", marginBottom:"5px", height:"2.0em"}} 
//                       value={input || ""} onChange={CallAdvancedChange} 
//                       placeholder={Localization.getText("Search Address")} 
//                       id="dawa-autocomplete-input" />
//                 </div>
//       </div>
//       <Form className="mb-3">
//     <div id="basic-addon1">{Localization.getText("Latitude")}</div>
//     <FormControl
//       type=""
//       onChange={LatOnChange}
//       value={pos.lat}
//       placeholder={Localization.getText("Latitude")}
//       aria-label="Latitude"
//       aria-describedby="basic-addon1"
//     />
//   </Form>
//   <Form className="mb-3">
//     <div id="basic-addon1">{Localization.getText("Longitude")}</div>
//     <FormControl
//       type="text"
//       onChange={(e:any) => setPos({...pos, lng:e.target.value ? e.target.value : ""})}
//       value={pos.lng}
//       placeholder={Localization.getText("Longitude")}
//       aria-label="Longitude"
//       aria-describedby="basic-addon1"
//     />
//   </Form>

            

//     <Row>
//       <Col><Button onClick={accept} >{Localization.getText("Confirm")}</Button></Col><Col></Col>
//     </Row>
//     </Col>
//     </div>
//   )
// };