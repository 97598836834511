import * as React from 'react';

import {Alert, FormGroup, FormControl, Stack, Card } from 'react-bootstrap';

import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {Parser} from '../managers/Parser';
import {Logger} from '@viamap/viamap2-common';
import {Utils} from '@viamap/viamap2-common';
import {importMenuStructure} from '../managers/ConfigData';
import {SelectionOptions, LayerInfo, ColumnMapping, ProjectionType, LayerType, MitDataType, 
    MappingPreference, MappingSpec, MappingSpecElement, SheetAnalysisResult, SelectionListWithPrefs, ImportTransformation} from '../common/managers/Types';
import {MitButton} from './ComponentUtils';

// import '../css/mit-controls.css';
import { FormLabel } from 'react-bootstrap';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import ReactDOM from 'react-dom';
import { LayerImportDuplicatesForm } from './LayerImportDuplicatesForm';
import { BsChevronRight } from 'react-icons/bs';
import { ProtectedFeature } from './ProtectedFeature';
import { MapitUtils } from 'src/managers/MapitUtils';
// import { ENOTEMPTY } from 'constants';

/*
    Component to show columns in imported sheet and allow column selection

    Modes:  showthiswindow y/n
            errorMode
            validSheet -> show main form

    Buttons:
            ok -> enabled when form is valid
            cancel
*/
type ValidationType = "success" | "warning" | "error" | null | undefined;

type Props = {
    showWindow: boolean;
    sheetToImport: any;
    showStylingWindow?: boolean;
    analysisResult?: SheetAnalysisResult;
    defaultSelections: any;
    onFormSubmit: (layerInfo:LayerInfo)=>void;
    onFormCancel: (info:any)=>void;
    datasetname: string;
};

export function LayerImport(props: Props) {
    const {hasAccessToFeature} = React.useContext(ApplicationStateContext)
    const [alertMessage,setAlertMessage] = React.useState<string | null>(null)
    const [datasetname,setDatasetname] = React.useState(props.datasetname)
    const [importTransformation,setImportTransformation] = React.useState<ImportTransformation>();
    const [columnMapping,setColumnMapping] = React.useState<ColumnMapping>(props.defaultSelections && {...props.defaultSelections})
    const [currentMaxLevel,setCurrentMaxLevel] = React.useState(props.defaultSelections ? props.defaultSelections.length : 0)
    const [currentSelections,setCurrentSelections] = React.useState(props.defaultSelections ? [...props.defaultSelections] : [])

    let _FormValidationState = {}

    const columnSelectionList = props.showWindow ? generateColumnSelectionList(props.sheetToImport) : null;
    const defaultSelections = props.showWindow && props.analysisResult ? generateDefaultSelections(props.analysisResult!) : null;

    const layerType = props.defaultSelections?.at?.(-1) || null
        // Copy default selections
    let colMap:ColumnMapping;
        colMap = {};
        defaultSelections && Object.keys(defaultSelections).forEach((m:string) => { 
                let dflt = defaultSelections[m];
                if (dflt === undefined) {
                    throw Utils.createErrorEventObject("Unexpectedly no default selection for item:"+m);
                }
                colMap = {...colMap, [m]:dflt};
        });

    React.useEffect(() => {
        if (columnSelectionList == null ||
            props.analysisResult == null) {
                // ToDo: sheet error handling is done elsewhere. Should not be needed.
                // ToDo: does not seem to be used. Use global error message scheme in Utils.dispatchToErrorHandler
            setAlertMessage("Sheet could not be read")
        }
    },[])

    
    
    function generateDefaultSelections(ar:SheetAnalysisResult): ColumnMapping {

        // Create default mapping.
        let defaultSelections:ColumnMapping= {};
        
        let ms:MitDataType[] = [
        MitDataType.Coord_WGS84_Lat,
        MitDataType.Coord_WGS84_Lon,
        MitDataType.Coord_UTM32_X,
        MitDataType.Coord_UTM32_Y,
        MitDataType.AdmReg_DK_MunicipalityId,
        MitDataType.AdmReg_DK_MunicipalityName,
        MitDataType.AdmReg_DK_RegionId,
        MitDataType.AdmReg_DK_RegionName,
        MitDataType.AdmReg_DK_ZipCodeId,
        MitDataType.AdmReg_DK_ZipCodeName,
        MitDataType.AdmReg_DK_ParishId,
        MitDataType.AdmReg_DK_ParishName,
        MitDataType.AdmReg_INT_CountryId,
        MitDataType.AdmReg_INT_CountryName,
        MitDataType.Address,
        MitDataType.Address2,
        MitDataType.Address3,
        MitDataType.Address4];

        ms  && ms.map((obj2:MitDataType, col) => {
            if (true) {
                let preferredColumnId = -1;
                let outputType = obj2;
                // Loop though columns of the input sheet
                let dummy = ar && ar.columns.map((item, row) => {
                    let inputType=item.mitDataType;
                    let pref = mappingValidity(inputType, outputType);
                    if (pref === MappingPreference.Preferred) { 
                        preferredColumnId = row; 
                    }
                });    
                // Set initial selection for row
                if (preferredColumnId >= 0) {
                    defaultSelections[outputType]=preferredColumnId+1; // one based column indexes
                }
            }
        });
        return (defaultSelections);
    }

    function generateColumnSelectionList(sheet:any) {
      try {
        let result = Parser.generateColumnSelectionList(sheet);
        return (result);
    } catch (e:any) {
        Logger.logError("LayerImport", "analyze sheet", e.message);
        return(null);
      }
    }

    function generateColumListWithPreferences(mappingSpecElement:MappingSpecElement, analysisResult:SheetAnalysisResult):SelectionListWithPrefs {
        // Determine the applicability of each sheet column to the needed information element
        let result:SelectionListWithPrefs = [];
        analysisResult && analysisResult.columns.map((item, idx) => {
            let inputType=item.mitDataType; 
            let outputType = mappingSpecElement.mitDataType;
            let pref = mappingValidity(inputType, outputType);
            result.push({
                value: (idx+1).toString(), // one based column index
                label: item.columnLetter+" "+item.name,  
                    // display value
                comment: "",
                preference: pref
            });
        });
        return (result);
    }

    function mappingValidity(inputType:MitDataType, outPutType:MitDataType):MappingPreference {
        if (inputType === outPutType) {
            return(MappingPreference.Preferred);
        }
        if (inputType === MitDataType.Unknown) {
            return(MappingPreference.Unsuited);
        }
        return(MappingPreference.Neutral);     
    }


    function nameOnChange(event:any) {
        setDatasetname(event.target.value)
    }

    function addtoColumnMap(columnMappingId:string, value:string) {
        // ToDo: refactor to send integer instead
        const intVal=Number.parseInt(value, 10);
        setColumnMapping({...columnMapping, [columnMappingId]:intVal});
    }

    function fieldSelectionChange(evt:any, columnMappingId:string) {
        let value = evt.target.value;
        addtoColumnMap(columnMappingId, value);
    }

    function onFormSubmit(e:any) {

        // hide this window
        e && e.preventDefault();
    
        // Figure out what the selected map type is. It is found in the leaf note in the options tree.
        let layerType = getLayerType();

        // Check that the type has a value (from user selection). Otherwise use default
        let colMap:ColumnMapping = columnMapping || {};
        columnMapping && defaultSelections && Object.keys(defaultSelections).forEach((m:any) => { 
            if (!columnMapping[m]) {
                let dflt = defaultSelections[m];
                if (dflt === undefined) {
                    throw Utils.createErrorEventObject("Unexpectedly no default selection for item:"+m);
                }
                colMap = {...colMap, [m]:dflt};
            }
        });

        // callback to parent
        const layerInfo:LayerInfo = {   
            layerId: -1,
            datasetname: datasetname,
            type: layerType,
            readonly: false,
            columnMapping: colMap,
            analysisResult: props.analysisResult!,
            styling: {},
            crs: layerType === LayerType.PointUTM32 ? 25832 : 4326,
            importTransformation: importTransformation
        };
        props.onFormSubmit(layerInfo);
    }

    function getLayerType() {
        return currentSelections.filter((a) => a).at(-1);
    }

    function getSelectWithOptions(defaultValue:any, optionsArray:{value:any, label:any, disabled: any}[], name:string, onChange:any, caption:string, emptyValue:string, validationFunc:()=>ValidationType) {
        return(
            <FormGroup 
                controlId="formControlsSelect"
                style={{paddingLeft:"10%", paddingRight:"10%"}}
            >        
                <FormLabel>{Localization.getText(caption)}</FormLabel>
                <FormControl 
                    as="select" 
                    placeholder={Localization.getText("please select")+" "+caption} 
                    onChange={(e) => onChange(e)}
                    value={defaultValue}
                >
                    <option key="#Unselected#" className="small" value=''>{emptyValue.split("|").map((a) => Localization.getText(a)).join(" ")}</option>
                    { optionsArray && optionsArray.filter(( option) => {
                        return (option.disabled === undefined || hasAccessToFeature(option.disabled))
                    }).map( ( option, i ) => (
                        <option key={option.label} className="small" value={option.value}>{Localization.getText(option.label)}</option>
                    )) }
                </FormControl>
            </FormGroup>
        );
    }

    function getSelectWithOptionsAndPrefs(optionsArray:SelectionListWithPrefs, name:string, getValue:any, onChange:any, caption:string, emptyValue:string, validationFunc:()=>ValidationType) {
        let selectedValue = getValue();
        if (!selectedValue) {
            let defaultOption = optionsArray && optionsArray.find( ( option, i ) => { return (option.preference === MappingPreference.Preferred); });
            selectedValue = defaultOption && defaultOption.value;
        }
        return(
            <FormGroup 
                controlId="formControlsSelect"
                style={{paddingLeft:"10%", paddingRight:"10%"}}
            >
                <FormLabel>{}</FormLabel>
                <FormControl 
                    name={name}
                    as="select" 
                    placeholder={Localization.getText("please select")+" "+Localization.getText(caption)} 
                    onChange={(e) => onChange(e)}
                    value={selectedValue}
                >
                    <option key="#Unselected#" className="small" value=''>{emptyValue.split("|").map((a) => Localization.getText(a)).join(" ")}</option>
                    { optionsArray && optionsArray.map( ( option, i ) => (
                        <option 
                            key={option.label} 
                            className="small" 
                            value={option.value}
                            style={{backgroundColor: option.preference === MappingPreference.Preferred ? "green" : 
                                option.preference === MappingPreference.Unsuited ? "red" :
                                "lightblue"}}
                            // selected={option.preference === MappingPreference.Preferred ? true : false}        
                        >
                            {option.label}
                        </option>
                    )) }
                </FormControl>
            </FormGroup>
        );
    }


    function clearFormValidationState() {
        _FormValidationState= {};
    }

    function addFormValidationElementState(tag:any, value:ValidationType) {
        _FormValidationState[tag]=value;
    }

    function getValidationStateForName():ValidationType {
        let result = _getValidationStateForName();
        addFormValidationElementState("Name", result);
        return result;
    }

    function _getValidationStateForName():ValidationType {
        const length = datasetname.length;
        if (length >= 1) { 
            return 'success'; 
        } else {
            // if (length >= 5) { 
            //     return 'warning'; 
            // } else {
            //     if (length >= 0) { 
                    return 'error'; 
            //     }
            // }
        }
    }

    function getValidationStateForMandatoryDropdown(dt:MitDataType):ValidationType {
        let result = _getValidationStateForMandatoryDropdown(dt);
        addFormValidationElementState(dt.toString(), result);
        return result;
    }
    function _getValidationStateForMandatoryDropdown(dt:MitDataType):ValidationType {
        let val = columnMapping?.[dt];
        if (val && val !== 0) {
            return 'success';
        } else {
            return 'error';
        }
    }
 
    function getValidationStateForNavigatorField(thisLevelNo:number):ValidationType {
        let result = _getValidationStateForNavigatorField(thisLevelNo);
        addFormValidationElementState(thisLevelNo.toString(), result);
        return result;
    }
    function _getValidationStateForNavigatorField(thisLevelNo:number):ValidationType {
        let val=currentSelections[thisLevelNo];
        if (val && val !== "") {
            return 'success';
        } else {
            return 'error';
        }
    }
/* tslint:disable */
    // recurseTree(t:any, result:any[]):void {
    //     if (t && 'validationState' in t) {
    //         result.push(t['validationState']);
    //     }
    //     if (t && 'children' in t) {
    //         let cx= t['children'];
    //         Object.keys(cx).map(
    //             (nm, index) => {
    //                 let t2 = cx[nm];
    //                 recurseTree(t2, result);
    //         });
    //     }
    // }
/* tslint:enable */

    function validateForm():boolean {
        // ToDo: Make validation
        let result = true;
        Object.keys(_FormValidationState).map((key, idx) => {
            let val=_FormValidationState[key];
            if (val === "error") {
                result=false;
            }
        });

        return result;
    }

    // ------------------------------------------------------------------


    function handleDismiss() {
        let zap=1;   
    }


    // -------------------------------------
    function handleCancel() {
        setAlertMessage(null)

        // callback to parent
        props.onFormCancel(
            { message:"Cancelled by user"}
        );
        }



    function getDefaultDatasetName() {
        return("");
    }

    // -------------------------------------
    // ToDo: does not seem to be used. Use global error message scheme in Utils.dispatchToErrorHandler
    function showAlerts() {
        return(alertMessage && (
            <Alert 
                        variant="danger" 
                        onClose={handleDismiss}
                    >
                <h4 style={{width:"100%"}}>
                    {alertMessage}
                </h4>
            </Alert>
        ));
    }

    function selectionOnChange(evt:any , thisLevelNo:number, hasChildren:boolean) {
        let value=evt.target.value;
        let newValues;
        let nextMax = thisLevelNo;
        if (value==="") {
            //  unset this and any values on following levels 
            newValues = [...currentSelections];
            newValues[thisLevelNo] = value;
            newValues.length = nextMax
        } else {
            // store the value;
            // increase the levelNo
            newValues = [...currentSelections];
            newValues[thisLevelNo] = value;
            if (hasChildren) {
                newValues[thisLevelNo+1] = "";
                nextMax = thisLevelNo+1;
            }
            newValues.length = nextMax
        }

        setCurrentMaxLevel(nextMax)
        setCurrentSelections(newValues)
    }

    function renderSelectLevel(thisLevelNo:number, currentValues:any[], optionsStructure:SelectionOptions) {
        console.assert(optionsStructure, "optionsStructure expected");
        let heading = optionsStructure.heading;
        let optionsAtTopLevel = optionsStructure.options && optionsStructure.options.map((obj) => {
            return {label:obj.label, value:obj.value, disabled: obj.disabled};
        });
        let renderNextLevel:JSX.Element|null=null;
        let renderFieldSelections;
        let childStructure:SelectionOptions|undefined;
        let hasChildren:boolean = 
            optionsStructure.options !== undefined 
            || optionsStructure.fieldselectionlist !== undefined;
        if (currentMaxLevel >= thisLevelNo) {
            let sel = currentSelections[thisLevelNo];
            let f = optionsStructure.options && optionsStructure.options.find((obj, index) => {
                return (obj.value === sel);
            });
            childStructure = f && f.children;
            if (childStructure && childStructure.options && childStructure.options.length > 0) {
                renderNextLevel= renderSelectLevel(thisLevelNo+1, currentValues, childStructure);
            }
            if (childStructure && childStructure.fieldselectionlist && childStructure.fieldselectionlist.length > 0) {
                hasChildren = true;
                childStructure && childStructure.fieldselectionlist.map((obj, index) => {
                    let columnPref = generateColumListWithPreferences(
                        obj, 
                        props.analysisResult!);
                    let heading2 = obj.mitDataType;
                    let emptyValue2 = (obj.mandatory ? "please select":"optionally select")+"|"+heading2;
                    let validator = obj.mandatory ? () => getValidationStateForMandatoryDropdown(obj.mitDataType) : () => { return null;};
                    let column1 = getSelectWithOptionsAndPrefs(
                        columnPref, heading2,
                        () => {
                            return columnMapping && columnMapping[obj.mitDataType];
                        },
                        (evt) => {
                            fieldSelectionChange(evt, obj.mitDataType)
                        },
                        heading2, emptyValue2, validator);
                    renderFieldSelections = (
                        <div>
                        {renderFieldSelections}
                        {column1}
                        </div>
                    ); 
                });
            } 

        }

        let emptyValue = "please select"+"|"+heading;
        let lvl = optionsAtTopLevel && getSelectWithOptions(
            currentValues[thisLevelNo],   
            optionsAtTopLevel, heading, 
            (evt) => {
                selectionOnChange(evt, thisLevelNo, hasChildren)
            }, heading, emptyValue, 
            ()=>getValidationStateForNavigatorField(thisLevelNo));
        return (
            <div>
                {lvl}
                {renderFieldSelections}
                {renderNextLevel}
            </div>
            );
    }

        if (props.showWindow) {
            clearFormValidationState();

            let allSelects = renderSelectLevel(0, currentSelections, importMenuStructure);

            let commitbutton:JSX.Element|null=null;
            let cancelbutton:JSX.Element|null=null;

            commitbutton = <button type="submit" onClick={onFormSubmit} disabled={!validateForm()} className="btn btn-primary">{Localization.getText("Submit")}</button>;
            cancelbutton = <button type="button" onClick={handleCancel} className="btn btn-default">{Localization.getText("Cancel")}</button>;

            return ReactDOM.createPortal(
            <div id="mit-layer-import" style={{top:"75px"}} className="small layer-import Flat-Card Flat-Modal">
                <Card style={{borderRadius: "0px", margin:"0px"}} >
                    <Card.Header>
                    <Card.Title as="h4">{Localization.getText("Choose import fields")}
                    {showAlerts()}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <form>
                    <FormGroup 
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        // validationState={getValidationStateForName()}
                    >
                        <FormLabel>{Localization.getText("Dataset name")}</FormLabel>
                        <FormControl
                            size="sm"
                            type="text"
                            value={datasetname ? datasetname : getDefaultDatasetName()}
                            placeholder={Localization.getText("Enter name of dataset")}
                            onChange={(e:any) => nameOnChange(e)}
                        />
                    </FormGroup>
                    {allSelects}
                    <div className="row">
                        <div className="col-sm-3" >{' '}</div>
                        <div className="col-sm-3" >{}</div>
                        <div className="col-sm-3" >{}</div>
                        <div className="col-sm-3" >{' '}</div>
                    </div>
                    <ProtectedFeature feature={Feature.AllowConsolidateDuplicates} contentsIfNoAccess={<></>}>
                        { MapitUtils.isAreaLayer(getLayerType()) ?
                        (
                            <AdvancedSettings>
                            <LayerImportDuplicatesForm
                                analysisResult={props.analysisResult!}
                                onChange={(newState) => 
                                    LayerImportDuplicatesHandler(newState)
                                }
                                />
                        </AdvancedSettings>
                        ) : null }
                    </ProtectedFeature>
                    <hr/>
                    </form>
            </Card.Body>
            <Card.Footer className='Flat-ButtonWrap'>
                {commitbutton}
                {cancelbutton}
            </Card.Footer>
            </Card>
                    
            </div>
            , document.getElementById("Mit-MapOverlay") || document.body); 
            } else {
                return (null);
            }

            function LayerImportDuplicatesHandler(newTransformation:ImportTransformation) {
                // alert("Transformation" + JSON.stringify(newTransformation, undefined, 3));
                setImportTransformation(newTransformation);
            }
            
        }
        
        function AdvancedSettings({children}) {
            let [foldUd, setFoldUd] = React.useState(false) 
        
            return (
                <>
                    <MitButton 
                        variant="normal" 
                        // style={{width:"100%", marginInline:"0px"}} 
                        onClick={() => 
                            setFoldUd(!foldUd)
                        }
                    >
                        {Localization.getText("Expander:AdvancedSettings")}<span className='mit-transformer' style={{float:"right", transform:"Rotate("+(foldUd?"90deg":"0deg")+")"}}><BsChevronRight /></span>
                    </MitButton>
                    {foldUd ? children : null}
                </>
            )
        }
export default LayerImport;


   
