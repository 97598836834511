import { AppMessagesContext, Localization, Logger, SimpleTranslationTable, Utils, actionClearInfoMessage, actionSetErrorMessage, actionSetInfoMessage } from "@viamap/viamap2-common";
import React, { Fragment, useContext, useEffect, useReducer, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { LabelButton, LayerInfo, LayerType, LayerVariabilityType, MapInfo, PictureLayoutType } from "src/common/managers/Types";
import { MitLatLng } from "src/managers/MapFacade";
import { Feature } from "src/states/ApplicationStateFeatures";
import { MapitStateActionType, MapitStateContext, MapitWindowId, actionAddDataLayer, actionSetShowWindow, actionUpdateDataLayer } from "src/states/MapitState";
import { ProjectContext, handleToBeAdded, setInfo } from "src/states/ProjectState";
import { ProtectedFeature } from "./ProtectedFeature";
import { CatchmentInterface } from "src/managers/CatchmentInterface";
import { Button, Spinner, Stack } from "react-bootstrap";
import { AiFillProject, AiFillCamera } from "react-icons/ai";
import { BsFillHouseAddFill, BsFillCarFrontFill, BsFillSignpost2Fill } from "react-icons/bs";
import { FaGlobeEurope } from "react-icons/fa";
import { GenerateGeom, SpatialReturnType } from "src/managers/GenerateGeom";
import { SheetFunc } from "src/managers/SheetFunc";
import { MitButton, RandomColorRGBA } from "./ComponentUtils";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { Persistence } from "src/managers/Persistence";
import { TbFilter, TbFilterMinus } from "react-icons/tb";
import { DialogMode } from "./MultiPointTravelDialog";
import { GiPentarrowsTornado } from "react-icons/gi";
import { CatchmentStateContext, actionGenerateCatchmentSimple } from "src/states/CatchmentState";
import { ReferenceGeomComposite } from "src/managers/ReferenceGeomComposite";
import * as Turf from '@turf/turf';
import ReactDOM from "react-dom";
import { LayerFunc } from "src/managers/LayerFunc";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { MapitUtils } from "src/managers/MapitUtils";
import { AddressInterface } from "src/managers/AddressInterface";
import { PropertyInformation } from "src/managers/PropertyInformation";
import { VectorLayer } from "src/managers/VectorLayerFunc";
import { MdEditNote } from "react-icons/md";
import { MapPinPopup } from "./MapPinPopup";
import { DefaultExtractInformationResponseProperties, FeatureLayer } from "src/managers/WmsLayerFunc";
import { GenerateGeomUtils } from "src/managers/GenerateGeomUtils";
import { LngLat } from "maplibre-gl";

// MARK: POINT INFO POPUP
export function PointInfoPopup(props: {lngLat:{lat:number, lng:number},layers:FeatureLayer[]}) {
  const [dataToShow, setDataToShow] = React.useState<Record<string, Partial<{data:any, title:string, translator:any}>>>({})

  useEffect(() => {
    const coords = GenerateGeomUtils.convertProjection(undefined,undefined,[props.lngLat.lng, props.lngLat.lat])
    let BBox = (coords[0] - 50) + ',' + (coords[1] - 50) + ',' + (coords[0] + 50) + ',' + (coords[1] + 50)
    async function getDataToShow() {
    let x = dataToShow
    props.layers.filter((a) => a).forEach(async (SLS) => {
      let NLS = SLS.layer;
      let url = SLS.getinformationURL?.(BBox, 101,101,50,50)

      if (!url) {
        return
      }

      let response;
      
      if (NLS.useProxy) {
        response = await MapPinPopup.getFeatureInfoViaProxy(NLS.host, url);
      } else {
        response = await MapPinPopup.getFeatureInfo(url);
      }

      const formatResponseProvider = (e) => DefaultExtractInformationResponseProperties(e, NLS.propertiesToDisplay || [])

      let featuresToDisplay = (formatResponseProvider ? formatResponseProvider(response): response && response.features && 
        response.features.length > 0 && response.features[0].properties && 
        response.features.map((feature) => {return feature.properties;})).filter((properties) => {return !Utils.isEmptyObject(properties);});
        
        const translator = (textsTranslate) => NLS?.translationTable ? Localization.getTextSpecificTable(textsTranslate, NLS.translationTable) : textsTranslate;
        x = {...x, [NLS.label]: {data: featuresToDisplay,title: NLS.label,translator: translator}}
        setDataToShow(x)
      });
    }
    getDataToShow()
  }
  ,[])

  // MapPinPopup.formatFeatureInfoResponse([{"Lat":lngLat.lat, "Lng":lngLat.lng}], (t)=>t) as JSX.Element[])
  
  return (
    <div className="mit-popup-scroll-wrap">
    <table className="mit-popup-scroll">
      <tr ><td><strong>Lat</strong></td><td>{props.lngLat.lat}</td></tr>
      <tr ><td><strong>Lng</strong></td><td>{props.lngLat.lng}</td></tr>
      {Object.keys(dataToShow).map((a) => {
        if (!dataToShow[a].data.length) {
          return <></>
        }
        return (
          <React.Fragment key={a}>
            <tr ><td style={{textAlign:"left", borderTop: "1px solid #ddd"}} colSpan={2}><strong style={{fontSize:"16px"}}>
              {dataToShow[a].translator(a)}
              </strong></td></tr>
            {MapPinPopup.formatFeatureInfoResponse(dataToShow[a].data, dataToShow[a].translator)}
          </React.Fragment>
        )
      })}
    </table>
    </div>
  )
}

export function LinePopup(props: {layerInfo, feature}) {
  const {state:mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);

  let stateLayerInfo = mapitState.layers[props.layerInfo.layerId]
  let stateFeature = ({...stateLayerInfo.geoJson.features?.[props.feature.id], id:props.feature.id} || stateLayerInfo.geoJson || {});

  return <AnyLinePopup layerInfo={stateLayerInfo} feature={stateFeature || {}} />
}

// MARK: Line Popup
export function AnyLinePopup(props: {layerInfo, feature}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  const [editable, setEditable] = React.useState(false); 
  
  return (<>
    <div className="mit-popup-label-menu NewSecNone">
    <ProtectedFeature feature={Feature.EditDataLayer} contentsIfNoAccess={<></>} >
    <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={() => setEditable((a) => !a)} title={Localization.getText("Edit")} >
    <MdEditNote />
    </Button>
    </ProtectedFeature>
    </div> 
    <div className="MapitPopupScroll">
    {
      editable ? <LabelEditorConstructor feature={props.feature} labels={props.feature.properties} mapInfo={mapitState.mapInfo} layerInfo={props.layerInfo} />:
      <LabelConstructorVerbatim labels={props.feature.properties} mapInfo={mapitState.mapInfo}  layerInfo={props.layerInfo} />
    }
    </div>
    </>
  )
}



export function VectorLayerPopup(props: {vectorFeatures: any[]}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  
  function VectorLayerFrom(sourceId: string) {
    return Object.values(mapitState.featureLayers).find((a) => ("getSourceID" in a && typeof a.getSourceID == "function" && a.getSourceID?.() === sourceId))
  }
  
  const toDisplay = props.vectorFeatures.map((a, idx) => {
    const vectorLayer = VectorLayerFrom(a.layer.source)
    if (!(vectorLayer && vectorLayer instanceof VectorLayer)) {
      return <></>
    }
    const showAllProperties = !(vectorLayer.vectorHandle.propertiesToDisplay)
    a.layer.id
    let fakeLayerInfo = {
      propertiesToDisplay : (vectorLayer.vectorHandle.propertiesToDisplay || []).map((a) => ({name:a}))
    }
    
    if (vectorLayer.vectorHandle.translationTable) {
      return (
        <React.Fragment key={idx} >
        <h5 style={{marginTop: (idx !== 0 ? "15px":"")}}>{(vectorLayer.vectorHandle.translationTable && Localization.getTextSpecificTable(vectorLayer.vectorHandle.label, vectorLayer.vectorHandle.translationTable)) || vectorLayer.vectorHandle.label}</h5>
        <LabelConstructorTranslatedWithTable  labels={a.properties} mapInfo={mapitState.mapInfo} layerInfo={fakeLayerInfo as any} showAll={showAllProperties} transTable={vectorLayer.vectorHandle.translationTable} />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment key={idx} >
      <h5 style={{marginTop: (idx !== 0 ? "15px":"")}}>{(vectorLayer.vectorHandle.translationTable && Localization.getTextSpecificTable(vectorLayer.vectorHandle.label, vectorLayer.vectorHandle.translationTable)) || vectorLayer.vectorHandle.label}</h5>
      <LabelConstructorVerbatim  labels={a.properties} mapInfo={mapitState.mapInfo} layerInfo={fakeLayerInfo as any} showAll={showAllProperties} />
      </React.Fragment>
    )
  })
  
  return <div className="MapitPopupScroll">{toDisplay}</div>
}

export function AreaPopup(props: {layerInfo, feature}) {
  const {state:mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);

  let stateLayerInfo = mapitState.layers[props.layerInfo.layerId]
  let stateFeature = window.structuredClone({...stateLayerInfo.geoJson.features?.[props.feature.id], id:props.feature.id} || stateLayerInfo.geoJson || {});

  return <AnyAreaPopup layerInfo={stateLayerInfo} feature={{...stateFeature, source: props.feature.source} || {}} />
}

// MARK: Area Popup
export function AnyAreaPopup(props: {layerInfo, feature}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  const {hasAccessToFeature} = React.useContext(ApplicationStateContext);
  const {dispatch: appMessageDispatch} = React.useContext(AppMessagesContext);
  const [editable, setEditable] = React.useState(false); 
  // const type = props.feature.geometry.type
  // const {dispatch: projDispatch} = React.useContext(ProjectContext);
  
  
  let funcAreaLabel = () => ReferenceGeomComposite.getLabelHeader(props.layerInfo.type, props.feature, mapitState.mapInfo.popUpLayout);
  
  function SpatialSelectionToNewLayers(spatialSelection: SpatialReturnType) {
    let layersToAdd = Object.keys(spatialSelection).map((layerKey) => {
      let layer: LayerInfo = mapitState.layers[layerKey]
      if (!layer) {
        return
      }
      
      let IdsToInclude = spatialSelection[layerKey]
      if (layer.datasheet) {
        let linesToInclude = IdsToInclude.map((obj) => obj + 1);  // correct for 1 header row.
        let sheet = SheetFunc.filterWorkSheet(layer.datasheet!, linesToInclude);
        let data = SheetFunc.sheetToJson(sheet)
        
        const featuresToInclude = IdsToInclude.map((id) => layer.geoJson.features[id])
        const features = data.map((a,idx) => {
          const [lng, lat, ..._] = (featuresToInclude[idx].geometry.coordinates as number[])
          return Turf.point([lng,lat], a)
        })
        const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(features, layer.datasetname)
        return newLayerInfo
      }
      
      const featuresToInclude = IdsToInclude.map((id) => layer.geoJson.features[id])
      const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(featuresToInclude, layer.datasetname)
      return newLayerInfo
    })
    let layersToRename = layersToAdd.filter(a => a)
    if (layersToRename.length) {
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, true, {layers: layersToAdd}))
    }
  }
  
  function SpatialSelection(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, inverse:boolean=false) {
    let handle = Object.keys(mapitState.layers).map((a) => mapitState.layers[a].handle).find((a) => a.sourceId === props.feature.source)
    let feature = handle?.source.data.features[props.feature.id]
    if (feature) {
      let spatialReturn = GenerateGeom.spatialSelectionGeoJSON(mapitState, feature.geometry, inverse);
      SpatialSelectionToNewLayers(spatialReturn);
    }
  }
  
  function SpatialCheck() {
    if (!hasAccessToFeature(Feature.SpatialExportGeoJsonPreview)) {
      return ""
    }
    let handle = Object.keys(mapitState.layers).map((a) => mapitState.layers[a].handle).find((a) => a.sourceId === props.feature.source)
    let feature = handle?.source.data.features[props.feature.id]
    if (feature) {
      let spatialReturn = GenerateGeom.spatialSelectionGeoJSON(mapitState, feature.geometry);
      return Object.keys(spatialReturn).reduce((b,a) => {return spatialReturn[a].length + b},0)
    }
    return "?"
  }
  
  let child: JSX.Element | undefined = undefined;
  if (MapitUtils.isAreaLayer(props.layerInfo.type)) {
    child = <><div>{funcAreaLabel()}</div><div dangerouslySetInnerHTML={{__html:props.feature.labelHTML}}></div></>
  } else {
    child = editable ? <LabelEditorConstructor feature={props.feature} labels={props.feature.properties} mapInfo={mapitState.mapInfo} layerInfo={props.layerInfo} />
    :
    <LabelConstructorVerbatim labels={props.feature.properties} mapInfo={mapitState.mapInfo}  layerInfo={props.layerInfo} />
  }
  
  return (<>
    <div className="mit-popup-label-menu NewSecNone">
    <ProtectedFeature feature={Feature.SpatialExportGeoJSON} contentsIfNoAccess={<></>} >
    <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => SpatialSelection(e)} title={Localization.getText("Spatial Export")}>
    {SpatialCheck()} <TbFilter />
    </Button>
    </ProtectedFeature>
    <ProtectedFeature feature={Feature.SpatialExportGeoJSONInverse} contentsIfNoAccess={<></>} >
    <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => SpatialSelection(e, true)} title={Localization.getText("Spatial Export Inverse")}>
    <TbFilterMinus />
    </Button>
    </ProtectedFeature>
    {MapitUtils.isAreaLayer(props.layerInfo.type) ? <></> :
    <ProtectedFeature feature={Feature.EditDataLayer} contentsIfNoAccess={<></>} >
    <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={() => setEditable((a) => !a)} title={Localization.getText("Edit")} >
    <MdEditNote />
    </Button>
    </ProtectedFeature>
  }
  
  </div>
  <div className="MapitPopupScroll">
  {child}
  </div>
  </>)
}

export function PointPopup(props: {layerInfo:LayerInfo, feature:any, lat: number, lng: number}) {
  const {state:mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  let UsingNewsecTheme = document.body.classList.contains("NewSecTheme")

  let stateLayerInfo = mapitState.layers[props.layerInfo.layerId] || props.layerInfo
  let stateFeature = ({...stateLayerInfo.geoJson?.features?.[props.feature.id], id:props.feature.id} || stateLayerInfo.geoJson || {});
  console.log(props.feature)
  
  if (UsingNewsecTheme) {
    return <NewSecPopup {...props} layerInfo={stateLayerInfo} feature={stateFeature || {}} />
  }
  return <ViamapPopup {...props} layerInfo={stateLayerInfo} feature={stateFeature || {}} />
  
}


export function NewSecPopup(props: {layerInfo:LayerInfo, feature:any, lat: number, lng: number,}) {
  const {state:mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  const {state:projState ,dispatch: projDispatch} = React.useContext(ProjectContext);
  const [hasBeenAdded, setHasBeenAdded] = React.useState<boolean>(false)
  const {dispatch: appMessageDispatch} = React.useContext(AppMessagesContext)
  const [matData, setMatData] = React.useState<{mat?:string, ejerlav?:string, err?:boolean}>({});
  const [data, setData] = React.useState({
    // ${matData.matrikelnummer} / ${matData.ejerlavskode}
    "Building Area":     {key:"Building area",value:"", pending:true},
    "Ground Area":       {key:"Total property area",value:"", pending:true},
    "Transaction value": {key:"Last Transaction Amt",value:"", pending:true},
    "Transaction date":  {key:"Last Transaction Date",value:"", pending:true},
    "Owner":             {key:"Owner",value:"", pending:true},
    "Usage":             {key:"Usage",value:"", pending:true},
  })
  
  useEffect(() => {
    const signal = new AbortController()
    if ((props.layerInfo.propertiesToDisplay || []).length === 0) {
      CollectData(signal)
    }
    return () => signal.abort()
  },[]);
  
  async function CollectData(signal: AbortController) {
    function IsAborted() {
      return signal.signal.aborted
    }
    
    function setInfo<T extends keyof typeof data>(key:T, value:any) {
      setData((a) => {
        let x = {...a}
        x[key] = {key:x[key].key, value:value, pending:false}
        return x;
      })
    }
    let formatter = new Intl.NumberFormat("da-dk")
    
    
    let latLng = new MitLatLng(props.lat, props.lng)
    let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
    let matData = (await PropertyInfoInterface.getMatrikelData(utmCoords))?.features[0]?.properties;
    matData.matrikelnummer && setMatData({mat: matData.matrikelnummer, ejerlav:matData.ejerlavskode})
    if (!matData.matrikelnummer) {
      setMatData({err:true})
      appMessageDispatch(actionSetInfoMessage(Localization.getText("No property for location")))
    }
    let ejdData = matData && await PropertyInfoInterface.getEjendomData(matData.ejerlavskode, matData.matrikelnummer)
    setInfo("Ground Area", ejdData?.totalAreal)
    let ejerer = ejdData?.ejere.map((a) => a.navn + (a.CVRNummer ? ` '${a.CVRNummer}'` : "")).join("\n")
    setInfo("Owner",ejerer)
    let BBRData = ejdData && (await PropertyInfoInterface.BBRgrundForBFE(ejdData.sag.BFEnr))?.[0]
    let HanDataAll = ejdData && (await PropertyInfoInterface.getHandelsOplysning(ejdData.sag.BFEnr))?.handel?.features
    let HanData = HanDataAll?.toSorted((a,b) => a.properties.virkningFra > b.properties.virkningFra ? -1 : 1)?.[0]?.properties
    
    let lastTransactionDato = HanData?.virkningFra || ""
    let lastTransactionSum = HanData?.samletKoebesum || ""
    setInfo("Transaction value",formatter.format(lastTransactionSum))
    setInfo("Transaction date",lastTransactionDato.split("T")[0] || lastTransactionDato)
    
    let bygData = BBRData && await PropertyInfoInterface.BBRbygningForGrundId(BBRData.id_lokalId)
    setInfo("Building Area", formatter.format(PropertyInformation.beregnSamletBebyggetAreal(bygData)))
    setInfo("Usage", PropertyInformation.bygningsAnvendelsesTekst(
      bygData.filter((a) => ["6","7"].includes(a.status.toString()))?.toSorted((a,b) => a.byg007Bygningsnummer > b.byg007Bygningsnummer? 1 : -1)?.[0].byg021BygningensAnvendelse
    ))
  }
  
  if (matData.err) {
    return 
  }
  
  return (
    <>
    <h4 style={{fontSize: "17px", fontWeight:"bold"}}>{props.layerInfo.datasetname}</h4>
    {matData.mat && <h4 style={{fontSize: "17px", fontWeight:"bold"}}>{matData.mat} / {matData.ejerlav}</h4>}
    <div className="MapitPopupScroll">
    {
      props.layerInfo.propertiesToDisplay?.length ? 
      <LabelConstructorVerbatim labels={props.feature.properties} mapInfo={mapitState.mapInfo}  layerInfo={props.layerInfo} />
      :
      <LabelConstructorTranslated labels={data} mapInfo={mapitState.mapInfo}  layerInfo={props.layerInfo} showAll={true} />
    }
    </div>
    <div style={{display:"flex", flexDirection:"column", gap:"7px", marginTop:"7px"}}> 
    <ProtectedFeature feature={Feature.PropertyInfo} contentsIfNoAccess={<span style={{display:"none"}}></span>} >
    <MitButton variant="nsDark" onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog ,true, {latlng: MitLatLng.fromM({lat:props.lat, lng:props.lng})}))} >{Localization.getText("Lookup property information")}</MitButton>
    </ProtectedFeature>
    <ProtectedFeature feature={Feature.AddToProjectUnassignedList} contentsIfNoAccess={<span style={{display:"none"}}></span>} >
    {projState.isLoaded ? <>
      <MitButton variant="nsDark" onClick={async (e) => {
        if (hasBeenAdded) {
          return
        }
        if (projState.isEmpty) {
          appMessageDispatch(actionSetInfoMessage(Localization.getText("Please visit the Project tabs, an action is needed!")))
          return
        }
        let resp = await AddressInterface.reverseGeocodeFull(props.lat,props.lng);
        let addrData = await resp.json();
        let jordstykke = await (await fetch(addrData.jordstykke.href)).json()
        let fullAddress = addrData.adressebetegnelse;
        let tmp = fullAddress.split(',');
        let streetAndNumber = tmp[0] || fullAddress;
        let zipAndCity = addrData.postnummer.nr+" "+addrData.postnummer.navn;
        
        projDispatch(handleToBeAdded([{bfeNr:jordstykke.bfenummer,name:streetAndNumber + ", " + zipAndCity ,latLng:[props.lat,props.lng], tags:[]}]))
        setHasBeenAdded(true)
        return
      }} >{hasBeenAdded ? Localization.getText("Already added") : Localization.getText("Add to Project")}
      </MitButton>
      
      </> : null }
      
      </ProtectedFeature>
      </div>
      </>
    )
  }
  
  // MARK: Viamap Point Popup
  export function ViamapPopup(props: {layerInfo:LayerInfo, feature:any, lat: number, lng: number}) {
    const [editable, setEditable] = React.useState(false); 
    const {state:mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
    const {dispatch: appMessageDispatch} = React.useContext(AppMessagesContext)
    const {dispatch: projDispatch} = React.useContext(ProjectContext);
    const {state:catchmentState, dispatch:catchmentStateDispatch} = React.useContext(CatchmentStateContext);
    
    async function visSamletFastEjendom(latLng: MitLatLng, name: string): Promise<void> {
      try {
        let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
        appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving cadaster data")));
        let matrikelData = await PropertyInfoInterface.getMatrikelData(utmCoords)
        .then(result => {
          let props = result.features[0].properties;
          return {
            sfeEjdNr: props.samletfastejendomlokalid,
          };
        }).catch(() => {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
          return undefined;
        });
        if (matrikelData && matrikelData.sfeEjdNr) {
          appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving geometry data")));
          let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(matrikelData.sfeEjdNr)
          .then(result => result)
          .catch(() => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("No geometry data for this property")));
            return undefined;
          });
          geoJson.type = "FeatureCollection"
          Persistence.createLayersFromGeoJSON(name + " (sfe)", geoJson, new Date(), (li: LayerInfo) => {mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:{...li, styling: {color: RandomColorRGBA(0.5)}}}})}, undefined, 0.25);
          appMessageDispatch(actionClearInfoMessage());
        } else {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("No cadaster data for this point")));
        }
      } catch (error:any) {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
      }
    }
    
    async function visVurderingsEjendom(latLng: MitLatLng, name: string): Promise<void> {
      try {
        let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
        appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving cadaster data")));
        let matrikelData = await PropertyInfoInterface.getMatrikelData(utmCoords)
        .then(result => {
          let props = result.features[0].properties;
          return {
            sfeEjdNr: props.samletfastejendomlokalid,
          };
        })
        .catch(() => {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
          return undefined;
        });
        if (matrikelData && matrikelData.sfeEjdNr) {
          appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving geometry data")));
          let geoJson = await PropertyInfoInterface.getGeojsonOfVurd(matrikelData.sfeEjdNr!)
          .then(result => result)
          .catch(() => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("No geometry data for this property")));
            return undefined;
          });
          geoJson.type = "FeatureCollection"
          Persistence.createLayersFromGeoJSON(name + " (vurd)", geoJson, new Date(), (li: LayerInfo) => {mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:{...li, styling: {color: RandomColorRGBA(0.5)}}}})}, undefined, 0.25);
          appMessageDispatch(actionClearInfoMessage());
        } else {
          appMessageDispatch(actionSetErrorMessage(Localization.getText("No cadaster data for this point")));
        }
      } catch (error:any) {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
      }
    }
    
    return (
      <>
      <div className="mit-popup-label-menu">
      <ProtectedFeature feature={Feature.PropertyInfo} contentsIfNoAccess={<></>} >
      <Button size="sm" variant="outline" className="mit-map-popup-button" onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog ,true, {latlng: MitLatLng.fromM({lat:props.lat, lng:props.lng})}))} title={Localization.getText("Lookup property information")}>
      <AiFillProject />
      </Button>
      </ProtectedFeature>
      <ProtectedFeature feature={Feature.TravelTime} contentsIfNoAccess={<></>} >
      <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {
        CatchmentInterface.clearCache(); // clear cache as new point is selected
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, false));
        catchmentStateDispatch(actionGenerateCatchmentSimple(catchmentState, mapitState, new MitLatLng(props.lat, props.lng)));
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.CatchmentSelector, true, {latlng:new MitLatLng(props.lat, props.lng)}));}} title={Localization.getText("Travel Time")}>
        <BsFillCarFrontFill />
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.TravelTimeFromPoint} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, true, {dialogMode:"PointToPoint", latlng:new MitLatLng(props.lat, props.lng), layerId:props.feature.layerId}))}} title={Localization.getText("Point Distance")}>
        <GiPentarrowsTornado />
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.ObliquePhoto} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.ObliqueViewer, true, {latlng:new MitLatLng(props.lat, props.lng)}))} title={Localization.getText("Oblique Photo")}>
        <AiFillCamera />
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.StreetView} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.StreetView, true, {...new MitLatLng(props.lat, props.lng)}))} title={Localization.getText("Go to street view")}>
        <BsFillSignpost2Fill />
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.Kortviser} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {throw new Error("Not Implemented")}} title={Localization.getText("Lookup in Kortviser")}>
        <FaGlobeEurope />
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.LifaOIS} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {throw new Error("Not Implemented")}} title={Localization.getText("Lookup in Lifa OIS")}>
        L
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.VisSamletFastEjendom} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {visSamletFastEjendom(new MitLatLng(props.lat, props.lng), props.layerInfo.datasetname || "Ukendt")}} title={Localization.getText("Show total real estate on map")}>
        S
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.VisVurderingsejendom} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={(e) => {visVurderingsEjendom(new MitLatLng(props.lat, props.lng), props.layerInfo.datasetname || "Ukendt")}} title={Localization.getText("Show real estate estimate on map")}>
        V
        </Button>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.EditDataLayer} contentsIfNoAccess={<></>} >
        <Button size="sm" variant="outline" className="mit-map-popup-button " onClick={() => setEditable((a) => !a)} title={Localization.getText("Edit")} >
        <MdEditNote />
        
        </Button>
        </ProtectedFeature>
        </div> 
        <div className="MapitPopupScroll">
        {
          editable ? <LabelEditorConstructor feature={props.feature} labels={props.feature.properties} mapInfo={mapitState.mapInfo} layerInfo={props.layerInfo} />:
          <LabelConstructorVerbatim labels={props.feature.properties} mapInfo={mapitState.mapInfo}  layerInfo={props.layerInfo} />
        }
        </div>
        </>
      )
    }
    
    type TranslatedPendingUpdated = {
      key: string,
      value: string | number,
      pending?: boolean
    }
    
    type LabelConstructorProps = {
      labels : {[key:string]:string | number | TranslatedPendingUpdated},
      mapInfo: MapInfo,
      layerInfo: LayerInfo,
      showAll?:boolean
    }
    
    /**
    * ## Labelconstructor  
    * Parses following things
    * - Urls
    * - Images
    * - Html
    * - Strings
    * 
    * @param labels Object with keyvalue pair 
    * @returns React.Component with formatted strings
    */
    export function LabelConstructorVerbatim({labels, mapInfo, layerInfo, showAll = false}:LabelConstructorProps) {
      const viewedLabels = includeDatasetName(labels, layerInfo.datasetname)
      const id = React.useId();
      let listToDisplay = layerInfo.propertiesToDisplay?.map((p) => p.name) || [];
      if (showAll) {
        listToDisplay = Object.keys(viewedLabels)
      }
      const lKeys = Object.keys(viewedLabels).filter((l) => listToDisplay.includes(l));
      
      return (<PopupGrid Pid={id} mapInfo={mapInfo} >{lKeys.map((lKey) => {
        const value = viewedLabels[lKey]?.toString().trim() || "";
        const valKey = (lKey == "datasetname") ? Localization.getText("Dataset name") : lKey;
        // Images or Link
        if (value && (value.toLowerCase().startsWith("http") || value.toLowerCase().startsWith("blob:http"))  ) {
          return <ImageLinkFallback key={valKey} lKey={valKey} value={value} mapInfo={mapInfo} Pid={id} />
        }
        // HTML
        if (value && value[0]==="<" && value[value.length-1] === ">") {
          return <div key={valKey} style={{gridColumn: "1 / span 2"}} dangerouslySetInnerHTML={{__html:value}}></div>
        }
        return <React.Fragment key={valKey}><div className="PopupKey">{valKey}</div><div className="PopupValue">{value}</div></React.Fragment>
      })}</PopupGrid>);
    }
    
    export function LabelConstructorTranslatedWithTable({labels, mapInfo, layerInfo, showAll = false, transTable}:LabelConstructorProps & {transTable: SimpleTranslationTable}) {
      let translatedLabels = {}
      let propertiesToDisplay = layerInfo.propertiesToDisplay?.map((a) => a.name)
      let keys = Object.keys(labels).filter((a) => showAll || propertiesToDisplay?.includes(a));
      
      keys.forEach((key) => {
        const value = labels[key];
        translatedLabels[Localization.getTextSpecificTable(key, transTable)] = value as any;
      })
      
      return LabelConstructorVerbatim({labels:translatedLabels, mapInfo, layerInfo, showAll: true})
    }
    
    export function LabelConstructorTranslated({labels, mapInfo, layerInfo, showAll = false}:LabelConstructorProps) {
      let translatedLabels = {}
      let keys = Object.keys(labels)
      keys.forEach((key) => {
        let value = labels[key]
        if (typeof value === "object") {
          translatedLabels[Localization.getText(value.key)] = value.value
        } else {
          translatedLabels[key] = value
        }
      })
      
      return LabelConstructorVerbatim({labels:translatedLabels, mapInfo, layerInfo, showAll:true})
    }
    
    export function PopupGrid({children, Pid, mapInfo}) {
      let layout = mapInfo.popUpPictureLayout || "Center";
      return (
        <>
        <div className="PopupFlex" >
        {layout === "Left" ? <div className="popupImgContainer" id={Pid} /> : null}
        <div className="PopupGrid">
        {layout === "Center" ? <div className="popupImgContainer" id={Pid} style={{gridColumn: "1 / span 2"}} /> : null}
        {children}
        </div>
        {layout === "Right" ? <div className="popupImgContainer" id={Pid} /> : null}
        </div>
        </>
      )
    }
    
    export function ImageLinkFallback(props: {lKey, value, mapInfo, Pid}) {
      const [failed, setFailed] = React.useState(false);
      const [loaded, setLoaded] = React.useState(false);
      
      if (failed) {
        return <><div style={{gridColumn: "1"}}>{props.lKey}</div>{props.value.split(" ").map((val) => <a key={val} style={{paddingLeft:"10px", gridColumn:"2", wordBreak: "break-all"}} target="_blank" href={val}>{val}</a>)}</>
      }
      
      let container = document.getElementById(props.Pid) 
      const imgSize = {"Small":"mit-label-image-small","Medium":"mit-label-image-medium","Large":"mit-label-image-large"}
      return ReactDOM.createPortal(<div className={"mit-label-image-border"} >
      <img onLoad={() => setLoaded(true)} onError={() => setFailed(true)} className={loaded ? imgSize[props.mapInfo.popUpPictureSize || "Medium"] : null} src={props.value} onDragStart={(e) => false} onClick={(e) => {props.value && window.open(props.value, '_blank')}}  alt="from Excel" />
      </div>
      ,container || document.body)
      
      
    }
    
    // MARK: In Popup Editor
    function LabelEditorConstructor({labels, feature, mapInfo, layerInfo, showAll = false}:LabelConstructorProps & {feature: any}) {
      const {state, dispatch} = useContext(MapitStateContext);
      const [editProp, setEditProp] = useState({...feature.properties});
      const [statLabel, setStatLabel] = useState([...(layerInfo.propertiesInGeoJson || []) ]);
      const [editLabel, setEditLabel] = useState([...(layerInfo.propertiesInGeoJson || []) ]);
      const [nextField, setNextField] = useState("")
      
      
      function handleChangeProp(a, newValue) {
        setEditProp((pre) => ({
          ...pre,
          [a]:newValue
        }))
      }
      
      function handleChangeLabel(a, newValue) {
        setEditLabel((pre) => 
          pre.map((oldValue, idx) => a==idx ? newValue : oldValue )
      )
    }
    
    function updateLayer() {
      if (new Set(editLabel.filter((a) => a)).size != editLabel.filter((a) => a).length) {
        return
      }

      let nextProp = {}
      let nGjson = layerInfo.geoJson.features.map((ftl, ftlId) => {
        let newProp = {}
        statLabel.forEach((a, idx) => {
          if (editLabel[idx] && feature.id == ftlId) {
            newProp = {...newProp,[editLabel[idx]]:editProp[a]}
          } else if (editLabel[idx]) {
            newProp = {...newProp,[editLabel[idx]]:ftl.properties[a]}
          }
        })
        if (feature.id == ftlId) {
          nextProp = newProp
        }
        return {...ftl,id:ftlId, properties: newProp}
      })
      
      let convName = {}
      statLabel.forEach((a, idx) => {
        if (editLabel[idx])
        convName = {...convName,
          [a]:editLabel[idx]
        }
      })
      

      let nextByProperty = {}
      Object.keys(layerInfo.styling).forEach((a) => {
        if (a.includes("ByProperty")) {
          nextByProperty = {...nextByProperty, [a]:convName[layerInfo.styling[a]]}
        }
      })
      setEditProp((a) => ({...nextProp}))
      setStatLabel((a) => editLabel.filter((a) => a))
      setEditLabel((a) => a.filter((a) => a))
      dispatch(actionUpdateDataLayer(
        layerInfo.layerId,
        {...layerInfo,
          geoJson: {...layerInfo.geoJson, features: nGjson},
          propertiesInGeoJson: editLabel.filter((a) => a),
          styling: {...layerInfo.styling, ...nextByProperty},
          propertiesToDisplay: layerInfo.propertiesToDisplay?.flatMap((a) => (convName[a.name] && {name: convName[a.name]} || []))
        }
      )
    )
  }
  
  function handleNewField() {
    if (editLabel.includes(nextField)) {
      return
    }

    setEditProp((pre) => ({...pre, [nextField]:""}))
    setEditLabel((pre) => ([...pre, nextField]))
    setStatLabel((pre) => ([...pre, nextField]))
    setNextField("")
    setTimeout(function () {
      let x = (document.getElementsByClassName("PropsEditors") as any) as HTMLInputElement[]
      x[x.length-1]?.focus()
    },200)
  }
  
  return (<div className="DataEditor">
    {
      statLabel?.map((a, idx) => {
        return (
          <Fragment key={a}>
          <div><input onChange={(e) => handleChangeLabel(idx, e.target.value)} value={editLabel[idx] ?? ""}
            data-invalid={editLabel[idx] && checkIfDuplicates(editLabel, editLabel[idx] , idx)}
            data-placeholdervariant="warning"
            placeholder={Localization.getText("Will be deleted")}
          /></div>
          <div><textarea className="PropsEditors" onChange={(e) => handleChangeProp(a, e.target.value)} style={{textAlign:"right", width:"100%"}}  value={editProp[a] ?? ""} /></div>
          </Fragment>
        )
      })
    }
    <div style={{display:"flex"}}>
    <input placeholder={Localization.getText("Add field")} onChange={(e) => setNextField(e.target.value)} onKeyDown={
      (e) => {
        ((e.code).toLowerCase() == "enter" && handleNewField())
      }
    } 
      onBlur={(e) => {
        nextField && handleNewField()
      }}
    value={nextField}
      data-invalid={nextField && checkIfDuplicates(editLabel, nextField ,-1)}
    ></input>
    {(CompareArr(Object.keys(feature.properties), editLabel) || (CompareArr(Object.values(editProp), Object.values(feature.properties)))) ? <div className="UnsavedIndicator" style={{marginLeft:"auto"}}>{Localization.getText("Unsaved Changes")}</div> : <div style={{marginLeft:"auto"}}></div>} 
    <button onClick={() => {
      updateLayer()
      }}>{Localization.getText("Save")}</button>
    </div>
    </div>)
    
  }

  function checkIfDuplicates(propeties: any[], key, idx) {
    return propeties.some((a,id) => {
      return (a == key && idx != id)
    })
  }
  
  function includeDatasetName<T extends object>(properties:T, datasetname?:string) {
    if (datasetname) {
      return {...properties, datasetname: datasetname}
    }
    return properties
    
  }


  function CompareArr(arr1:any[], arr2:any[]):boolean {
    return arr1.some((element,idx) => {
       return (arr2[idx] != element)
    });
  }