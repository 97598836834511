export class FormatUtils {

   static toNumber(num:string):number {
      return parseInt(num, 10);
    }

    static zeroPadStart(num: number, totalLength:number):string {
      return num.toString().padStart(totalLength,"0");
    }

    static formatDate(dt:Date):string {
      // Note getMonth() is zero indexed!!)
      // Any year before 1800 is set to 1800.
      return ""+Math.max(1800,dt.getFullYear())+"-"+FormatUtils.zeroPadStart(dt.getMonth()+1,2)+"-"+FormatUtils.zeroPadStart(dt.getDate(),2);
    }

    static formatDateTime(dt:Date):string {
      // Note getMonth() is zero indexed!!)
      return dt && ""+dt.getFullYear()+"-"+FormatUtils.zeroPadStart(dt.getMonth()+1,2)
        +"-"+FormatUtils.zeroPadStart(dt.getDate(),2)+"T"+FormatUtils.zeroPadStart(dt.getHours(),2)+":"
        +FormatUtils.zeroPadStart(dt.getMinutes(),2)+":"+FormatUtils.zeroPadStart(dt.getSeconds(),2);
    }

    static formatNumberStringDate(dt:number) {
      return dt?.toString().replace(/(\d{4})(\d{2})(\d{2})/,"$3.$2.$1") || "Ukendt"
    }

    static formatTimeFromSeconds(seconds:number) {
      if (isNaN(seconds)) {
        return "";
      } else {
        let minutes = Math.floor(seconds / 60);
        let hours= Math.floor(minutes/60);
        minutes = Math.floor(minutes - hours*60);
        let secs = Math.floor(seconds - (minutes*60 + hours*60*60));
        return hours.toFixed(0).padStart(2,"0")+":"+minutes.toFixed(0).padStart(2,"0"); // +":"+secs.toFixed(0).padStart(2,"0");
      }
    }
  
    static formatDistanceMeters(meters:number) {
      return Math.floor(meters);
    }

    static formatThousandsSeparatedNumber(value:number): string {
      
       return value.toLocaleString();
    }

    /**
     * @param value file size in bytes
     * @returns A human readable file size
     */
    static formatFileSize(value:number): string {
      const affix = ["B","KB","MB","GB","TB"];
      let i = 0;
      let size = value;
      while (i < affix.length - 1 && size > 1000) {
        size = Math.round(size) / 1000;
        i++;
      }
      return size.toLocaleString() + affix[i];
    }
    
}