import { useCallback, useRef, useState } from "react";
import { MitStorage } from "src/managers/MitStorage";

export function usePanWindow(topOffset, options?:{startTop?:string,localStorName?:string}) {
  const dragRef = useRef(null);
  const [counter, setCounter] = useState(0); //use to update return value
  const setRef = useCallback((node) => {
    if (dragRef.current) {
      dragRef.current = null;
    }
    if (node) {
      dragRef.current = node;
      node.style.top = options?.startTop ?? "40px";
      const saved = (options?.localStorName && MitStorage.getValue(options?.localStorName))
      if (saved && typeof saved == "object") {
        Object.keys(saved).forEach((a) => {
          node.style[a] = saved[a]
        })
      }
      setCounter((a) => a + a);
    }
  }, []);

  

  const dragEvent = useCallback(
    (e) => {
      if (dragRef.current !== null) {
        dragPanHandlerClosure(dragRef.current, topOffset, options)(e);
      }
    },
    [topOffset]
  );

  const setfold = useCallback(
    (e) => {
      if (dragRef.current !== null) {
        clickPanHandlerClosure(dragRef.current, topOffset)(e);
      }
    },
    [topOffset]
  );

  const setOpen = useCallback(
    (e) => {
      if (dragRef.current !== null) {
        let ele = dragRef.current as HTMLElement;
        let top = parseInt((ele.style.top || "0px").split("px")[0]);
        if (top / (ele.parentElement?.clientHeight || 1) > 0.6) {
          ele.style.top = options?.startTop ?? "40px";
        }
      }
    },
    [topOffset]
  );

  return [dragEvent, setRef, setfold, setOpen];
}

function clickPanHandlerClosure(ele: HTMLElement, topOffset) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseUpHandler = function (e) {
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: parseInt((ele.style.top || "0px").split("px")[0]),
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    let topss = 0;
    if (pos.top / (ele.parentElement?.clientHeight || 1) < 0.5) {
      topss = document.body.clientHeight;
    }
    const clampedTop = Math.min(
      Math.max(0, topss),
      ele.offsetHeight - (40 + topOffset)
    );
    ele.style.top = clampedTop + "px";

    document.removeEventListener("mouseup", mouseUpHandler);
  };

  return function (e) {
    document.addEventListener("mouseup", mouseUpHandler);
    setTimeout((a) => {
      document.removeEventListener("mouseup", mouseUpHandler);
    }, 150);
  };
}

function dragPanHandlerClosure(ele: HTMLElement, topOffset, options?:{startTop?:string,localStorName?:string}) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const mouseDownHandler = function (e) {
    pos = {
      // The current scroll
      left:
        parseInt((ele.style.left || (ele.offsetLeft+"px")).split("px")[0]),
      top: parseInt((ele.style.top || "0px").split("px")[0]),
      // Get the current mouse position
      x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("touchmove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
    document.addEventListener("touchend", mouseUpHandler);
    document.addEventListener("touchcancel", mouseUpHandler);
    document.body.style.userSelect = "none";
    document.body.style.cursor = "resize-row";
  };

  const mouseMoveHandler = function (e) {
    if (e.type !== "touchmove") {
      e.preventDefault();
    }
    // How far the mouse has been moved
    const dx = (e.clientX || e.touches[0].clientX) - pos.x;
    const dy = (e.clientY || e.touches[0].clientY) - pos.y;

    const clampedLeft = Math.min(
      Math.max(0, pos.left + dx),
      (ele.parentElement?.offsetWidth || 1000) - ele.offsetWidth
    );
    const clampedTop = Math.min(
      Math.max(0, pos.top + dy),
      (ele.parentElement ?? ele)?.offsetHeight - (40 + topOffset)
    );

    ele.style.top = clampedTop + "px";
    ele.style.left = clampedLeft + "px";
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("touchmove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
    document.removeEventListener("touchend", mouseUpHandler);
    document.removeEventListener("touchcancel", mouseUpHandler);

    ele.style.cursor = "unset";
    document.body.style.removeProperty("user-select");
    document.body.style.removeProperty("cursor");

    options?.localStorName && MitStorage.setValue(options.localStorName, {
      top: ele.style.top,
      left: ele.style.left,
      width: ele.style.width,
      height: ele.style.height
    }) 
  };

  return mouseDownHandler;
}

export function useFileDropArea() {
  const dragRef = useRef(null);
  const [counter, setCounter] = useState(0); //use to update return value
  const setRef = useCallback((node) => {
    if (dragRef.current) {
      dragRef.current = null;
    }
    if (node) {
      dragRef.current = node;

      setCounter((a) => a + a);
    }
  }, []);

  const dragEvent = useCallback((e) => {
    if (dragRef.current !== null) {
      dropHandlerClosure(dragRef.current)(e);
    }
  }, []);

  return [dragEvent, setRef];
}

function dropHandlerClosure(ele: HTMLElement) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const mouseDownHandler = function (e) {
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: parseInt((ele.style.top || "0px").split("px")[0]),
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
    document.body.style.userSelect = "none";
    document.body.style.cursor = "resize-row";
  };

  const mouseMoveHandler = function (e) {
    if (e.type !== "touchmove") {
      e.preventDefault();
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    const clampedTop = Math.min(
      Math.max(0, pos.top + dy),
      ele.offsetHeight - 40
    );

    ele.style.top = clampedTop + "px";
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    ele.style.cursor = "unset";
    document.body.style.removeProperty("user-select");
    document.body.style.removeProperty("cursor");
  };

  return mouseDownHandler;
}
