import { Logger } from "@viamap/viamap2-common";
import { DawaQuality, RowDataQuality } from "src/common/managers/Types";
import { GenerateGeom } from "./GenerateGeom";
import { Localization } from "@viamap/viamap2-common";
import { PropertyInfoInterface } from "./PropertyInfoInterface";

export type CadasterGeoCodeResult = any;

export class CadasterInterface {

    static async searchMatrikel(ejerlavkode: string, matrikelnr: string): Promise<any> {
        let serviceUrl = "https://dawa.aws.dk/jordstykker";
        let query = serviceUrl + "?ejerlavkode=" + ejerlavkode + "&matrikelnr=" + matrikelnr;
        try {
            const response = await fetch(query);
            if (response.status !== 200) {
                throw new Error("Http Error status code received: " + response.status);
            }
            const data = await response.json();
            return { data: data };
        } catch (err:any) {
            return { error: err.toString() };
        }
    }

    static async geocode(
        elavskode: string[],
        matrikelnr: string[],
        withPolygon: boolean,
        polygonForFullSFE: boolean,
        callback: (result: CadasterGeoCodeResult[]) => void,
        statusStarted: (noGeoCodeStarted: number) => void,
        statusCompleted: (noGeoCodeCompleted: number, stats: any) => void) {
        let completed = 0;
        let stats = {
            [DawaQuality.dawa_matched]: 0,
            [DawaQuality.dawa_washed]: 0,
            [DawaQuality.fuzzy_washed]: 0,
            [DawaQuality.unwashable]: 0,
            [DawaQuality.dawa_error]: 0,
        };
        let results: CadasterGeoCodeResult[] = [];
        let queryToIndexMap = {};


        function startIterationWLimitedConcurrency(i: number, activeRequestsLimit:number) {
            let elav = elavskode[i];
            let matrnr = matrikelnr[i];

            let activeRequests = (i + 1) - completed;
            if (activeRequests > activeRequestsLimit) {
                // Delay starting new requests before the queue is shorter. Test again in 1 sek.
                let delay = 1000 * 1; 
                setTimeout(
                    () => {
                        startIterationWLimitedConcurrency(i, activeRequestsLimit);
                    },
                    delay);
            } else {
                statusStarted(i + 1);
                CadasterInterface.washFunction(elav, matrnr, withPolygon, polygonForFullSFE, i).then((response) => {

                    completed += 1;
                    const q = response.dataquality;
                    stats = { ...stats, [q]: stats[q] + 1 };
                    statusCompleted(completed, stats);

                    let index = response.idx;
                    // Store result in the right index
                    results[index] = response;

                    // Done?
                    if (completed === elavskode.length) {
                        Logger.logInfo("CadasterInterface", "geocode", "Completed. Stats: " + JSON.stringify(stats));
                        callback(results);
                    }
                });
                // Start next request, if any
                if (i + 1 < elavskode.length) {
                    startIterationWLimitedConcurrency(i + 1, activeRequestsLimit);
                }
            }
        }
        startIterationWLimitedConcurrency(0, 50);
    }

    private static async washFunction(elav: string, matr: string, withPolygon: boolean, polygonForFullSFE:boolean, idx: number): Promise<CadasterGeoCodeResult> {
        let singleResult: CadasterGeoCodeResult = {};
        singleResult.input = `${elav} - ${matr}`;
        singleResult.idx = idx;

        let res = await CadasterInterface.searchMatrikel(elav, matr).catch((err) => {
            console.log("Got error getting cadaster: " + err);
        });
        if (res && res.data && res.data.length > 0) {
            if (res.data.length > 1) {
                throw new Error("Unexpected number of cadaster informations returned (>1): " + res.data.length);
            }
            let info = res.data[0];
            singleResult.dataquality = RowDataQuality.Good;
            singleResult.sfeejendomsnr = info.sfeejendomsnr;
            let latitude = info.visueltcenter[1];
            let longitude = info.visueltcenter[0];
            singleResult.lat = latitude;
            singleResult.lon = longitude;
            let { x, y } = GenerateGeom.convertFromLatLngToUTM({ lat: latitude, lng: longitude });
            singleResult.easting = x;
            singleResult.northing = y;
            singleResult.dataqualityNote = `Ok`;
            if (withPolygon) {
                if (polygonForFullSFE) {
                singleResult.geoJson = await PropertyInfoInterface.getGeojsonOfSFE(info.sfeejendomsnr)
                    .catch((error) => {
                        console.log(error, "No geometry data for this property: " + info.sfeejendomsnr);
                        return { features: [] };
                    });
                } else {
                    let singleEjerlavMatrikelSetList: { ejerlavKode: number, matrikelNr: string }[] = [{
                        ejerlavKode: Number.parseInt(elav, 10),
                        matrikelNr: matr
                    }];
                    singleResult.geoJson = await PropertyInfoInterface.getGeojsonOfCadasters(singleEjerlavMatrikelSetList)
                    .catch((error) => {
                        console.log(error, "No geometry data for this cadaster: " + elav +" mat "+ matr);
                        return { features: [] };
                    });                }
            }
        } else {
            singleResult.dataqualityNote = Localization.getFormattedText("Cadaster {elav} {matr} not found", { elav, matr });
            singleResult.dataquality = RowDataQuality.Error;
        }
        return singleResult;
    }
}