import { Utils } from "@viamap/viamap2-common";
import { Debuglog, ObliqueFunc, UtmCoords, ViewDirection } from "./ObliqueFunc";
import { SettingsManager } from "@viamap/viamap2-common";

export class BuildingHeight {

    static async hillClimb(oc: UtmCoords, currentDepth: number, maxDepth: number, currentMax: number, excludeDirection?: ViewDirection): Promise<{ max: number, loc: UtmCoords, depth: number }> {
        let stepSize = 0.4; // meters
        let localMax = currentMax;
        let locForMax: UtmCoords | undefined = undefined;
        let directionForMax: ViewDirection | undefined = undefined;
        Debuglog.log(ObliqueFunc.formatString(
            "HillClimb depth {dep} maxD {maxDepth} max {currentMax} loc {loc}",
            { dep: currentDepth, maxDepth: maxDepth, currentMax: currentMax, loc: JSON.stringify(oc) }));

        /* tslint:disable-next-line */
        function moveInDirection(loc: UtmCoords, dir: ViewDirection, stepSize: number): UtmCoords {
            switch (dir) {
                case ViewDirection.EAST:
                    return { x: loc.x + stepSize, y: loc.y };
                case ViewDirection.WEST:
                    return { x: loc.x - stepSize, y: loc.y };
                case ViewDirection.NORTH:
                    return { x: loc.x, y: loc.y + stepSize };
                case ViewDirection.SOUTH:
                    return { x: loc.x + stepSize, y: loc.y - stepSize };
                default:
                    throw new Error("Unexpected direction:" + dir);
            }
        }

        /* tslint:disable-next-line */
        function oppositeDirection(dir: ViewDirection): ViewDirection {
            switch (dir) {
                case ViewDirection.EAST:
                    return ViewDirection.WEST;
                case ViewDirection.WEST:
                    return ViewDirection.EAST;
                case ViewDirection.NORTH:
                    return ViewDirection.SOUTH;
                case ViewDirection.SOUTH:
                    return ViewDirection.NORTH;
                default:
                    throw new Error("Unexpected direction:" + dir);
            }
        }

        let res = { max: currentMax, loc: oc, depth: currentDepth };
        if (currentDepth >= maxDepth) {
            Debuglog.log("At max depth. Returning");
            return res;
        }

        let directions = [ViewDirection.EAST, ViewDirection.NORTH, ViewDirection.SOUTH, ViewDirection.WEST];
        for (let i = 0; i < directions.length; i++) {
            let dir = directions[i];
            if (dir !== excludeDirection) { // don't go back where we came from
                let loc = moveInDirection(oc, dir, stepSize);
                let localHeight = await this.getHeightInformation(loc);
                Debuglog.log(
                    ObliqueFunc.formatString(
                        "Direction {dir} height {height} ",
                        { dir: dir, height: localHeight }));
                if (localHeight > localMax) {
                    localMax = localHeight;
                    locForMax = loc;
                    directionForMax = dir;
                }
            }
        }

        if (localMax > currentMax) {
            Debuglog.log(ObliqueFunc.formatString("New maximum found in direction {dir}", { dir: directionForMax }));
            res = await this.hillClimb(locForMax!, currentDepth + 1, maxDepth, localMax, oppositeDirection(directionForMax!));
            return res;
        }
        Debuglog.log("No higher directions. Returning");
        return res;
    }

    static async getHeightInformationLocalMaximum(oc: UtmCoords): Promise<any> {

        let localHeight = await this._getHeightInformation("dhm_overflade", oc);
        let { max, loc, depth } = await this.hillClimb(oc, 0, 10, localHeight, undefined);

        console.log("Maximum is " + max);
        console.log("Maximum found at " + JSON.stringify(loc));
        console.log("Maximum found at depth " + depth);
        console.log("Location " + JSON.stringify(oc));
        let h = await this.getHeightInformation(oc);
        console.log("Height at Location " + h);
        return max;
    }

    static async getHeightInformation(loc: UtmCoords): Promise<number> {
        return await this._getHeightInformation("dhm_terraen", loc);
    }

    static async _getHeightInformation(layer: string, loc: UtmCoords): Promise<number> {

        function url(path: string, layers: string, BBOX: string, HEIGHT: number, WIDTH: number, X: number, Y: number) {
            // let token=Utils.getSystemSetting("kortforsyningenToken");
            const username = SettingsManager.getSystemSetting("datafordelerUsername","");
            const password = SettingsManager.getSystemSetting("datafordelerPassword","");
            return "/DHMNedboer/dhm/1.0.0/WMS?username=" + username + "&password=" + password + "&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&BBOX=" + BBOX + "&CRS=EPSG:25832&WIDTH=" + WIDTH + "&HEIGHT=" + HEIGHT + "&STYLES=&FORMAT=image/jpeg&DPI=96&MAP_RESOLUTION=96&FORMAT_OPTIONS=dpi:96&Layers=" + layers + "&query_layers=" + layers + "&i=" + X + "&j=" + Y + "&info_format=text/plain";
        }
        // set parameters needed for GetFeatureInfo WMS request
        // create a small box around the point (as required by service)
        let offset = 1; // meters (UTM32)
        var sw = { x: loc.x - offset, y: loc.y - offset };
        var ne = { x: loc.x + offset, y: loc.y + offset };
        var BBOX2 = sw.x + "," + sw.y + "," + ne.x + "," + ne.y;
        var WIDTH2 = 1000;
        var HEIGHT2 = 1000;
        var X2 = 500;
        var Y2 = 500;

        // compose the URL for the request
        let URL = url("", layer, BBOX2, HEIGHT2, WIDTH2, X2, Y2);
        return new Promise<number>((resolve, reject) => {
            fetch("https://services.datafordeler.dk"+URL).then((a) => {
                return a.text()
            }).then((text) => {
                if (text.search("Search returned no results.") >= 0) {
                    resolve(-1)
                }
                let lines = text.split(/\r?\n/);
                let attrs2 = {};
                lines.forEach(line => {
                    if (line.includes("=")) {
                        let parts = line.split("=");
                        let key = parts[0].trim();
                        // Strings are quoted with '. For presentation remove ' from strings
                        let val = parts[1] && parts[1].trim().split("'").join("");
                        attrs2[key] = val;
                    }
                });
                let resultFloat = parseFloat(attrs2["value_0"]);
                resolve(resultFloat)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}