import {LayerInfo, LayerType, RowDataQuality, MitDataType, DawaQuality} from '../common/managers/Types';
import {Logger} from '@viamap/viamap2-common';
import {ReferenceGeomDenmark} from './ReferenceGeomDenmark';
import {ReferenceGeomWorld} from './ReferenceGeomWorld';
import {Utils} from '@viamap/viamap2-common';
import {GenerateGeom} from './GenerateGeom';
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import {ReferenceGeomRomania} from '../managers/ReferenceGeomRomania';
import {ReferenceGeomParish} from '../managers/ReferenceGeomParish';
import { ReferenceGeomSchleswig } from './ReferenceGeomSchleswig';

/**
 * This class encapsulates funtionality to preprocess input data for a layer.
 * This can be geocoding, validate coordinates, validate that e.g. region codes are correct.
 */
export class ImportDataProcessor {

    static evaluateRowDataQuality(layerInfo:LayerInfo): {rowDataQuality:RowDataQuality[], rowDataQualityNote:string[]} {
        let rowDataQuality:RowDataQuality[]  = Array<RowDataQuality>();
        let rowDataQualityNote:string[]  = Array<string>();
        let res;

        switch(layerInfo.type) {
        case LayerType.AreaCountryId:
        res = ImportDataProcessor.genericEval(
            Localization.getText("Country Code"),
            layerInfo.data![MitDataType.AdmReg_INT_CountryId],
            (obj) => {return Boolean(ReferenceGeomWorld.lookupByCountryCode(obj));},
//            (obj) => {return Localization.getFormattedText("Unknown {item}: {code}",{code:obj, item:Localization.getText("Country Code")});}
        );
        break;
        case LayerType.AreaCountryName:
        res = ImportDataProcessor.genericEval(
            Localization.getText("Country Name"),
            layerInfo.data![MitDataType.AdmReg_INT_CountryName],
            (obj) => {return Boolean(ReferenceGeomWorld.lookupByCountryName(obj));},
//            (obj) => {return Localization.getFormattedText("Unknown country name: {code}",{code:obj});}
        );
        break;
        case LayerType.AreaMunicipalityId:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Municipality Id"),
                layerInfo.data![MitDataType.AdmReg_DK_MunicipalityId],
                (obj) => {let n:number = Number.parseInt(obj, 10); return Boolean(ReferenceGeomDenmark.lookupByMunicipalityCode(n));},
//                (obj) => {return Localization.getFormattedText("Unknown municipality id: {code}",{code:obj});}
            );           
            break;
        case LayerType.AreaMunicipalityName:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Municipality Name"),
                layerInfo.data![MitDataType.AdmReg_DK_MunicipalityName],
                (obj) => {return Boolean(ReferenceGeomDenmark.lookupByMunicipalityName(obj));},
//                (obj) => {return Localization.getFormattedText("Unknown municipality name: {code}",{code:obj});}
            );
            break;
        case LayerType.AreaParishId:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Parish Id"),
                layerInfo.data![MitDataType.AdmReg_DK_ParishId],
                (obj) => {let n:number = Number.parseInt(obj, 10); return Boolean(ReferenceGeomParish.lookupByParishCode(n));},
            );           
            break;
        case LayerType.AreaParishName:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Parish Name"),
                layerInfo.data![MitDataType.AdmReg_DK_ParishName],
                (obj) => {return Boolean(ReferenceGeomParish.lookupByParishName(obj));},
            );
            break;
        case LayerType.AreaRegionId:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Region Id"),
                layerInfo.data![MitDataType.AdmReg_DK_RegionId],
                (obj) => {return Boolean(ReferenceGeomDenmark.lookupByRegionCode(obj));},
//                (obj) => {return Localization.getFormattedText("Unknown region id: {code}",{code:obj});}
            );
            break;
        case LayerType.AreaRegionName:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Region Name"),
                layerInfo.data![MitDataType.AdmReg_DK_RegionName],
                (obj) => {return Boolean(ReferenceGeomDenmark.lookupByRegionName(obj));},
//                (obj) => {return Localization.getFormattedText("Unknown region name: {code}",{code:obj});}
            );
            break;
        case LayerType.AreaZipcodesId:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Zipcodes Id"),
                layerInfo.data![MitDataType.AdmReg_DK_ZipCodeId],
                (obj) => {let n:number = Number.parseInt(obj, 10); return Boolean(ReferenceGeomDenmark.lookupByZipCode(n));},
//                (obj) => {return Localization.getFormattedText("Unknown zip id: {code}",{code:obj});}
            );
            break;
        case LayerType.AreaZipcodesName:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Zipcodes Name"),
                layerInfo.data![MitDataType.AdmReg_DK_ZipCodeName],
                (obj) => {return Boolean(ReferenceGeomDenmark.lookupByZipCodeName(obj));},
//                (obj) => {return Localization.getFormattedText("Unknown zip code name: {code}",{code:obj});}
            );
            break;
        case LayerType.PointUTM32:
            res = ImportDataProcessor.evalCoordinates(
                layerInfo.data![MitDataType.Coord_UTM32_X],
                layerInfo.data![MitDataType.Coord_UTM32_Y],
                layerInfo.type);
            break;
        case LayerType.PointWGS84:
            res = ImportDataProcessor.evalCoordinates(
                layerInfo.data![MitDataType.Coord_WGS84_Lat],
                layerInfo.data![MitDataType.Coord_WGS84_Lon],
                layerInfo.type);
            break;
        case LayerType.AreaMunicipalityId_RO:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Romanian Geo"),
                layerInfo.data![MitDataType.AdmReg_RO_MunicipalityId],
                (obj) => {return true; }, // Boolean(lookupByZipCodeName(obj));},
            );
            break;
        case LayerType.AreaMunicipalityName_RO:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Romanian Geo"),
                layerInfo.data![MitDataType.AdmReg_RO_MunicipalityName],
                (obj) => {return Boolean(ReferenceGeomRomania.lookupByMunicipalityName(obj));},
            );
            break;
        case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Southern Schleswig Geo"),
                layerInfo.data![MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId],
                (obj) => { return true; }, // Boolean(lookupByZipCodeName(obj));},
            );
            break;
        case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:
            res = ImportDataProcessor.genericEval(
                Localization.getText("Southern Schleswig Geo"),
                layerInfo.data![MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityName],
                (obj) => { return Boolean(ReferenceGeomSchleswig.lookupByMunicipalityName(obj)); },                );
            break;
        case LayerType.Grid:
            res = ImportDataProcessor.evalGridData(
                layerInfo.data![MitDataType.Coord_UTM32_X],
                layerInfo.data![MitDataType.Coord_UTM32_Y],
                layerInfo.data![MitDataType.GridSizeMeters]
                );
            break;
        default:
          throw new Error("Unknown or unsupported layerType: "+layerInfo.type);
        }
        return res;
    }

    static genericEval(itemText:string, dataArray:any[], testFunction:(obj:any)=>boolean):
    {rowDataQuality:RowDataQuality[], rowDataQualityNote:string[]} {
        let rowDataQuality:RowDataQuality[]  = Array<RowDataQuality>();
        let rowDataQualityNote:string[]  = Array<string>();
        dataArray.forEach((obj, idx) => {
            let res:RowDataQuality= RowDataQuality.Error;
            let note:string="";
            if (testFunction(obj)) {
                res= RowDataQuality.Good;
                note=Utils.formatString("[{obj}]",{obj:obj});
            } else {
                res= RowDataQuality.Bad;
                note=Localization.getFormattedText("Unknown {item}: {code}",{code:obj, item:itemText});
            }
            rowDataQuality.push(res);
            rowDataQualityNote.push(note);
        });
        return {rowDataQuality:rowDataQuality, rowDataQualityNote:rowDataQualityNote};
    }



    static evalCoordinates(coord1:any[],coord2:any[], layerType:LayerType):
    {rowDataQuality:RowDataQuality[], rowDataQualityNote:string[]} {
        let rowDataQuality:RowDataQuality[]  = Array<RowDataQuality>();
        let rowDataQualityNote:string[]  = Array<string>();
        for (let j=0; j<coord1.length;j++) {
            let res:RowDataQuality= RowDataQuality.Error;
            let note:string="";
            let coords;
            if (layerType === LayerType.PointUTM32) {
              let c = [coord1[j], coord2[j]].map((a) => Number(a))
              coords = c.every((a) => a) && GenerateGeom.convertFromUTMtoLatLng2(c) || [coord1[j], coord2[j]];
            } else {
              coords = [coord1[j], coord2[j]];
            }
      
            // Validate coords
            let lat = coords[0];
            let lon = coords[1];

            if (GenerateGeom.coordinatesAreOutOfBounds(lat, lon)) {
                Logger.logError("GenerateGeom", "showLayerMarkerByValue", "Coordinates out of bounds "+JSON.stringify(coords));
                res= RowDataQuality.Bad;
                note=Localization.getFormattedText("Coordinates outside of limits: {x},{y}",{x:coord1[j],y:coord2[j]});
            } else {          
                res= RowDataQuality.Good;
                note=Utils.formatString("[{x},{y}]",{x:coord1[j],y:coord2[j]});
            }
            rowDataQuality.push(res);
            rowDataQualityNote.push(note);
        }
        return {rowDataQuality:rowDataQuality, rowDataQualityNote:rowDataQualityNote};
    }

    static evalGridData(coord1:any[],coord2:any[], gridSizeM:any[]):
    {rowDataQuality:RowDataQuality[], rowDataQualityNote:string[]} {
        let rowDataQuality:RowDataQuality[]  = Array<RowDataQuality>();
        let rowDataQualityNote:string[]  = Array<string>();
        for (let j=0; j<coord1.length;j++) {
            let res:RowDataQuality= RowDataQuality.Error;
            let note:string="";
            let coords;
            coords = GenerateGeom.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
      
            // Validate coords
            let lat = coords[0];
            let lon = coords[1];

            if (GenerateGeom.coordinatesAreOutOfBounds(lat, lon)) {
                Logger.logError("GenerateGeom", "showLayerGrid", "Coordinates out of bounds "+JSON.stringify(coords));
                res= RowDataQuality.Bad;
                note=Localization.getFormattedText("Coordinates outside of limits: {x},{y}",{x:coord1[j],y:coord2[j]});
            } else {          
                // Validate grid size
                let size = gridSizeM[j];
                let limitLow = SettingsManager.getSystemSetting("gridSizeBoundsLow", 50);
                let limitHigh = SettingsManager.getSystemSetting("gridSizeBoundsHigh", 100000);
                if (size > limitHigh || size < limitLow) {
                    Logger.logError("GenerateGeom", "showLayerGrid", "Grid size out of bounds "+JSON.stringify(size));
                    res= RowDataQuality.Bad;
                    note=Localization.getFormattedText(
                        "Grid size {size} should be between: {limit_low} and {limit_high}",
                        {size, limit_low: limitLow, limit_high: limitHigh}
                    );   
                } else {
                    res= RowDataQuality.Good;
                    note=Utils.formatString("[{x},{y},{size}]",{x:coord1[j],y:coord2[j], size});
                }
            }
            rowDataQuality.push(res);
            rowDataQualityNote.push(note);
        }
        return {rowDataQuality:rowDataQuality, rowDataQualityNote:rowDataQualityNote};
    }

    static evalDawaQuality(dataQuality:any[]):
    {rowDataQuality:RowDataQuality[], rowDataQualityNote:string[]} {
        let rowDataQuality:RowDataQuality[]  = Array<RowDataQuality>();
        let rowDataQualityNote:string[]  = Array<string>();
        dataQuality.forEach((elm:DawaQuality) => {
            let res:RowDataQuality= RowDataQuality.Error;
            let note:string="";
            switch(elm) {
              case DawaQuality.dawa_error:
                res= RowDataQuality.Error;
                note=Localization.getText("Error. Data point ignored");
                break;
              case DawaQuality.dawa_matched:
                res= RowDataQuality.Good;
                note="";
                break;
              case DawaQuality.dawa_washed:
              case DawaQuality.fuzzy_washed:
              case DawaQuality.dawa_washed:
                res= RowDataQuality.Warning;
                note=Localization.getText("Not complete address match. Please review result address");
                break;
              case DawaQuality.unwashable:
                res= RowDataQuality.Bad;
                note=Localization.getText("Address could not be found. Data point ignored");
                break;
              default:
                throw new Error("Unknown or unsupported dawaquality: "+elm);
            }
            rowDataQuality.push(res);
            rowDataQualityNote.push(note);
        });
        return {rowDataQuality:rowDataQuality, rowDataQualityNote:rowDataQualityNote};
    }
  
}