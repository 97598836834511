import polylabel from "polylabel"
import {point, geometry, lineString} from "@turf/helpers"
import area from "@turf/area"
import length from "@turf/length"
import centroid from "@turf/centroid"
import { BsTruckFlatbed } from "react-icons/bs"
import { Localization } from "@viamap/viamap2-common"
import { formatArea, formatLength } from "./DrawingToolFunctions"


export function ExtendWithMeasureMents(obj) {
  return {...obj,
    toDisplayFeatures: function(a,b,c) {
      const measureDisplay = function(geojson: any) {
        // geojson = testJson;
        c(geojson)
        if (geojson.properties["meta:type"] === "LineString"
            || geojson.properties["meta:type"] === "Polygon") {
          if (geojson.geometry.type === "LineString") {
          c(point(geojson.geometry.coordinates.at(-1),{
            ...geojson.properties,
            "meta": "measure",
            "meta:type": "Point",
            "length": formatLength(length(geojson as any)),
          }
          ))
          let rest = geojson.geometry.coordinates.filter((a,idx) => idx !== 0)
          let [x,y] = geojson.geometry.coordinates[0] as [number,number]
          rest.forEach((pos:[number,number]) => {
            c(lineString([[x,y],pos],{
              ...geojson.properties,
              "meta": "measure",
              "meta:type": "LineString",
              "length": formatLength(length(lineString([[x,y],pos]) as any)),
                }
              ))
            x = pos[0]
            y = pos[1]
          });

        }
        if (geojson.geometry.type === "Polygon") {
          if (geojson.properties.active !== "true") {
            c(point(polylabel(geojson.geometry.coordinates, 1),{
              ...geojson.properties,
              "meta": "measure",
              "meta:type": "Point",
              "length": formatLength(length(geojson as any)),
              "area": formatArea(area(geojson as any)),
            }))
          } else {
            c(centroid(geojson as any, {
              properties: {
                ...geojson.properties,
                "meta": "measure",
                "meta:type": "Point",
                "length": formatLength(length(geojson as any)),
                "area": formatArea(area(geojson as any)),
                },
              }  
            ))
          }

          geojson.geometry.coordinates.forEach(element => {
            let rest = element.filter((a,idx) => idx !== 0)
            let [x,y] = element[0] as [number,number]
            rest.forEach((pos:[number,number]) => {
              c(lineString([[x,y],pos],{
                ...geojson.properties,
                "meta": "measure",
                "meta:type": "LineString",
                "length": formatLength(length(lineString([[x,y],pos]) as any)),
                  }
                ))
              x = pos[0]
              y = pos[1]
            });
          });
          
        }
      }
    }
      obj.toDisplayFeatures?.apply?.(this, [a,b,measureDisplay])
    }
  }
}


const testJson = {
  "type": "Feature",
  "properties": {
      "id": "b0be0511edd0a74b45721074285d7dad",
      "meta": "feature",
      "meta:type": "Polygon",
      "active": "true",
      "mode": "simple_select"
  },
  "geometry": {
      "coordinates": [
          [
              [
                  12.443649748881285,
                  55.70721520642127
              ],
              [
                  12.442951654710328,
                  55.70262093978104
              ],
              [
                  12.455264432590724,
                  55.70220590248377
              ],
              [
                  12.455711212859654,
                  55.707350870158905
              ],
              [
                  12.449707602987218,
                  55.70941181396037
              ],
              [
                  12.443649748881285,
                  55.70721520642127
              ]
          ]
      ],
      "type": "Polygon"
  }
}