import { ReferenceGeomTypeParish, ReferenceGeomFeatureParish } from '../common/managers/Types';
import { Utils } from '@viamap/viamap2-common';

export class ReferenceGeomParish {

    /**
     * Some names have alias names
     */
    private static parishNameAliasList = {
      //   "Alias": "Name in GEOJSON",
      "Bellahøj" : "Bellahøj-Utterslev",
      "Utterslev" : "Bellahøj-Utterslev",
      "Fredens-Nazaret" : "Fredens og Nazaret",
      "Højdevangs" : "Højdevang",
      "Godthaabs": "Godthåb"
    };

    private static parishLookupList = [
        {
            "Code": 7521,
            "Name": "Bogø"
        },
        {
            "Code": 7645,
            "Name": "Engestofte"
        },
        {
            "Code": 7446,
            "Name": "Humlebæk"
        },
        {
            "Code": 7765,
            "Name": "Avernakø"
        },
        {
            "Code": 9168,
            "Name": "Iglsø"
        },
        {
            "Code": 8644,
            "Name": "Sennels"
        },
        {
            "Code": 7108,
            "Name": "Godthåb"
        },
        {
            "Code": 9203,
            "Name": "Trandum"
        },
        {
            "Code": 9251,
            "Name": "Paddesø"
        },
        {
            "Code": 7539,
            "Name": "Gørslev"
        },
        {
            "Code": 8261,
            "Name": "Udby"
        },
        {
            "Code": 8319,
            "Name": "Nøvling"
        },
        {
            "Code": 7885,
            "Name": "Nørre Lyndelse"
        },
        {
            "Code": 8857,
            "Name": "Engbjerg"
        },
        {
            "Code": 8069,
            "Name": "Søften"
        },
        {
            "Code": 8407,
            "Name": "Løkken-Furreby"
        },
        {
            "Code": 8105,
            "Name": "Mårslet"
        },
        {
            "Code": 8896,
            "Name": "Vejrup"
        },
        {
            "Code": 8341,
            "Name": "Veggerby"
        },
        {
            "Code": 7957,
            "Name": "Vrigsted"
        },
        {
            "Code": 8930,
            "Name": "Folding"
        },
        {
            "Code": 7039,
            "Name": "Sankt Pauls"
        },
        {
            "Code": 9127,
            "Name": "Klitmøller"
        },
        {
            "Code": 9189,
            "Name": "Stigs Bjerby-Mørkøv"
        },
        {
            "Code": 8865,
            "Name": "Fjaltring"
        },
        {
            "Code": 7067,
            "Name": "Husum"
        },
        {
            "Code": 7695,
            "Name": "Faaborg"
        },
        {
            "Code": 8619,
            "Name": "Vindum"
        },
        {
            "Code": 9134,
            "Name": "Opstandelseskirkens"
        },
        {
            "Code": 9138,
            "Name": "Hjerting"
        },
        {
            "Code": 7913,
            "Name": "Erritsø"
        },
        {
            "Code": 8750,
            "Name": "Rindum"
        },
        {
            "Code": 7495,
            "Name": "Magleby Stevns"
        },
        {
            "Code": 7647,
            "Name": "Vantore"
        },
        {
            "Code": 7359,
            "Name": "Kirke Stillinge"
        },
        {
            "Code": 9230,
            "Name": "Jerup"
        },
        {
            "Code": 8328,
            "Name": "Vokslev"
        },
        {
            "Code": 7491,
            "Name": "Fanefjord"
        },
        {
            "Code": 8677,
            "Name": "Dragstrup"
        },
        {
            "Code": 8078,
            "Name": "Skovby"
        },
        {
            "Code": 7780,
            "Name": "Korsløkke"
        },
        {
            "Code": 8852,
            "Name": "Fabjerg"
        },
        {
            "Code": 7304,
            "Name": "Karrebæk"
        },
        {
            "Code": 8639,
            "Name": "Vorning"
        },
        {
            "Code": 8531,
            "Name": "Skals"
        },
        {
            "Code": 7142,
            "Name": "Strandmark"
        },
        {
            "Code": 7724,
            "Name": "Nyborg"
        },
        {
            "Code": 8785,
            "Name": "Ådum"
        },
        {
            "Code": 8559,
            "Name": "Dommerby"
        },
        {
            "Code": 8049,
            "Name": "Nebel"
        },
        {
            "Code": 9106,
            "Name": "Sædding"
        },
        {
            "Code": 8686,
            "Name": "Heltborg"
        },
        {
            "Code": 8665,
            "Name": "Stagstrup"
        },
        {
            "Code": 7182,
            "Name": "Gershøj"
        },
        {
            "Code": 9193,
            "Name": "Nimtofte-Tøstrup"
        },
        {
            "Code": 8033,
            "Name": "Alling"
        },
        {
            "Code": 8774,
            "Name": "Stauning"
        },
        {
            "Code": 7082,
            "Name": "Vor Frelsers"
        },
        {
            "Code": 7448,
            "Name": "Bistrup"
        },
        {
            "Code": 9026,
            "Name": "Uge"
        },
        {
            "Code": 8479,
            "Name": "Bangsbostrand"
        },
        {
            "Code": 7862,
            "Name": "Melby"
        },
        {
            "Code": 7065,
            "Name": "Emdrup"
        },
        {
            "Code": 8383,
            "Name": "Horsens"
        },
        {
            "Code": 8794,
            "Name": "Vildbjerg"
        },
        {
            "Code": 7028,
            "Name": "Aalholm"
        },
        {
            "Code": 7786,
            "Name": "Ubberud"
        },
        {
            "Code": 7851,
            "Name": "Klinte"
        },
        {
            "Code": 7296,
            "Name": "Kundby"
        },
        {
            "Code": 8622,
            "Name": "Bjerringbro"
        },
        {
            "Code": 9013,
            "Name": "Bjolderup"
        },
        {
            "Code": 7774,
            "Name": "Sankt Knuds"
        },
        {
            "Code": 8561,
            "Name": "Ørslevkloster"
        },
        {
            "Code": 7036,
            "Name": "Holmens"
        },
        {
            "Code": 7937,
            "Name": "Viuf"
        },
        {
            "Code": 7658,
            "Name": "Krønge"
        },
        {
            "Code": 8567,
            "Name": "Løvel"
        },
        {
            "Code": 7499,
            "Name": "Toksværd"
        },
        {
            "Code": 8024,
            "Name": "Søby"
        },
        {
            "Code": 7756,
            "Name": "Hillerslev"
        },
        {
            "Code": 8405,
            "Name": "Hæstrup"
        },
        {
            "Code": 7985,
            "Name": "Lindeballe"
        },
        {
            "Code": 7916,
            "Name": "Gauerslund"
        },
        {
            "Code": 7588,
            "Name": "Systofte"
        },
        {
            "Code": 8921,
            "Name": "Hostrup"
        },
        {
            "Code": 8715,
            "Name": "Lild"
        },
        {
            "Code": 8532,
            "Name": "Ulbjerg"
        },
        {
            "Code": 8915,
            "Name": "Årre"
        },
        {
            "Code": 8174,
            "Name": "Tirstrup"
        },
        {
            "Code": 8848,
            "Name": "Flynder"
        },
        {
            "Code": 8690,
            "Name": "Ørum"
        },
        {
            "Code": 7572,
            "Name": "Povlsker"
        },
        {
            "Code": 7185,
            "Name": "Ledøje"
        },
        {
            "Code": 8795,
            "Name": "Nøvling"
        },
        {
            "Code": 8951,
            "Name": "Sønder Hygum"
        },
        {
            "Code": 7803,
            "Name": "Kærum"
        },
        {
            "Code": 7450,
            "Name": "Lillerød"
        },
        {
            "Code": 7808,
            "Name": "Sandager"
        },
        {
            "Code": 8780,
            "Name": "Nørre Bork"
        },
        {
            "Code": 7356,
            "Name": "Sludstrup"
        },
        {
            "Code": 7896,
            "Name": "Hornstrup"
        },
        {
            "Code": 7433,
            "Name": "Mårum"
        },
        {
            "Code": 7442,
            "Name": "Hellebæk"
        },
        {
            "Code": 7905,
            "Name": "Jelling"
        },
        {
            "Code": 7512,
            "Name": "Vester Egede"
        },
        {
            "Code": 8508,
            "Name": "Vroue"
        },
        {
            "Code": 7866,
            "Name": "Veflinge"
        },
        {
            "Code": 9161,
            "Name": "Blågårdens"
        },
        {
            "Code": 8021,
            "Name": "Voel"
        },
        {
            "Code": 7550,
            "Name": "Lyderslev"
        },
        {
            "Code": 7600,
            "Name": "Musse"
        },
        {
            "Code": 7996,
            "Name": "Tønning"
        },
        {
            "Code": 8185,
            "Name": "Glenstrup"
        },
        {
            "Code": 7493,
            "Name": "Lille Heddinge"
        },
        {
            "Code": 8491,
            "Name": "Thorsø"
        },
        {
            "Code": 8954,
            "Name": "Nustrup"
        },
        {
            "Code": 7889,
            "Name": "Østrup"
        },
        {
            "Code": 9061,
            "Name": "Tinglev"
        },
        {
            "Code": 8869,
            "Name": "Hodde"
        },
        {
            "Code": 7505,
            "Name": "Vester Egesborg"
        },
        {
            "Code": 7841,
            "Name": "Ørslev"
        },
        {
            "Code": 8668,
            "Name": "Sejerslev"
        },
        {
            "Code": 7784,
            "Name": "Paarup"
        },
        {
            "Code": 9225,
            "Name": "Hou"
        },
        {
            "Code": 8461,
            "Name": "Bjergby"
        },
        {
            "Code": 7601,
            "Name": "Herritslev"
        },
        {
            "Code": 9226,
            "Name": "Østervrå"
        },
        {
            "Code": 7943,
            "Name": "Sønder Stenderup"
        },
        {
            "Code": 8997,
            "Name": "Lysabild"
        },
        {
            "Code": 8699,
            "Name": "Rakkeby"
        },
        {
            "Code": 8202,
            "Name": "Hem"
        },
        {
            "Code": 8487,
            "Name": "Gerning"
        },
        {
            "Code": 8608,
            "Name": "Sejling"
        },
        {
            "Code": 8573,
            "Name": "Resen"
        },
        {
            "Code": 9070,
            "Name": "Halsskov"
        },
        {
            "Code": 7836,
            "Name": "Nørre Aaby"
        },
        {
            "Code": 7451,
            "Name": "Hørsholm"
        },
        {
            "Code": 7994,
            "Name": "Brædstrup"
        },
        {
            "Code": 8637,
            "Name": "Viskum"
        },
        {
            "Code": 8310,
            "Name": "Valsgård"
        },
        {
            "Code": 7375,
            "Name": "Øde Førslev"
        },
        {
            "Code": 7911,
            "Name": "Sankt Michaelis"
        },
        {
            "Code": 8969,
            "Name": "Sønder Starup"
        },
        {
            "Code": 7822,
            "Name": "Helnæs"
        },
        {
            "Code": 8216,
            "Name": "Hørning"
        },
        {
            "Code": 8522,
            "Name": "Testrup"
        },
        {
            "Code": 8046,
            "Name": "Lundum"
        },
        {
            "Code": 8672,
            "Name": "Bjergby"
        },
        {
            "Code": 7159,
            "Name": "Sankt Jørgensbjerg"
        },
        {
            "Code": 7678,
            "Name": "Sørup"
        },
        {
            "Code": 7460,
            "Name": "Kyndby"
        },
        {
            "Code": 7880,
            "Name": "Allerup"
        },
        {
            "Code": 9033,
            "Name": "Vodder"
        },
        {
            "Code": 7090,
            "Name": "Solvang"
        },
        {
            "Code": 8362,
            "Name": "Farstrup"
        },
        {
            "Code": 8460,
            "Name": "Astrup"
        },
        {
            "Code": 8471,
            "Name": "Lyngby"
        },
        {
            "Code": 9288,
            "Name": "Gludsted"
        },
        {
            "Code": 8767,
            "Name": "Nørre Vium"
        },
        {
            "Code": 7360,
            "Name": "Vemmelev"
        },
        {
            "Code": 7435,
            "Name": "Valby"
        },
        {
            "Code": 7547,
            "Name": "Vråby"
        },
        {
            "Code": 8304,
            "Name": "Skelund"
        },
        {
            "Code": 8374,
            "Name": "Hasseris"
        },
        {
            "Code": 7465,
            "Name": "Vrangstrup"
        },
        {
            "Code": 9149,
            "Name": "Houlkær"
        },
        {
            "Code": 9205,
            "Name": "Tiphede"
        },
        {
            "Code": 7555,
            "Name": "Nylarsker"
        },
        {
            "Code": 7590,
            "Name": "Falkerslev"
        },
        {
            "Code": 7821,
            "Name": "Sønderby"
        },
        {
            "Code": 8616,
            "Name": "Tapdrup"
        },
        {
            "Code": 8947,
            "Name": "Lintrup"
        },
        {
            "Code": 7532,
            "Name": "Ulse"
        },
        {
            "Code": 8192,
            "Name": "Dalbyover"
        },
        {
            "Code": 8329,
            "Name": "Sønderholm"
        },
        {
            "Code": 8245,
            "Name": "Hornslet"
        },
        {
            "Code": 9025,
            "Name": "Rinkenæs"
        },
        {
            "Code": 7489,
            "Name": "Magleby"
        },
        {
            "Code": 8976,
            "Name": "Jegerup"
        },
        {
            "Code": 7995,
            "Name": "Føvling"
        },
        {
            "Code": 9157,
            "Name": "Gistrup"
        },
        {
            "Code": 8083,
            "Name": "Storring"
        },
        {
            "Code": 7921,
            "Name": "Pjedsted"
        },
        {
            "Code": 8583,
            "Name": "Junget"
        },
        {
            "Code": 8087,
            "Name": "Odder"
        },
        {
            "Code": 8493,
            "Name": "Granslev"
        },
        {
            "Code": 7904,
            "Name": "Skibet"
        },
        {
            "Code": 7129,
            "Name": "Nærum"
        },
        {
            "Code": 8961,
            "Name": "Vor Frue"
        },
        {
            "Code": 9049,
            "Name": "Mjolden"
        },
        {
            "Code": 7118,
            "Name": "Maglegårds"
        },
        {
            "Code": 7064,
            "Name": "Bispebjerg"
        },
        {
            "Code": 7653,
            "Name": "Tågerup"
        },
        {
            "Code": 8642,
            "Name": "Hillerslev"
        },
        {
            "Code": 8516,
            "Name": "Hornbæk"
        },
        {
            "Code": 8586,
            "Name": "Grønning"
        },
        {
            "Code": 7776,
            "Name": "Bolbro"
        },
        {
            "Code": 8226,
            "Name": "Krogsbæk"
        },
        {
            "Code": 7683,
            "Name": "Hundstrup"
        },
        {
            "Code": 7585,
            "Name": "Karleby"
        },
        {
            "Code": 9278,
            "Name": "Haurvig"
        },
        {
            "Code": 7519,
            "Name": "Glumsø"
        },
        {
            "Code": 7962,
            "Name": "Glud"
        },
        {
            "Code": 9212,
            "Name": "Christianshede"
        },
        {
            "Code": 7843,
            "Name": "Lunde"
        },
        {
            "Code": 8288,
            "Name": "Vognsild"
        },
        {
            "Code": 7173,
            "Name": "Kirke Hvalsø"
        },
        {
            "Code": 7804,
            "Name": "Gamtofte"
        },
        {
            "Code": 7438,
            "Name": "Sankt Mariæ"
        },
        {
            "Code": 9186,
            "Name": "Frederiksværk-Vinderød"
        },
        {
            "Code": 8434,
            "Name": "Skærum"
        },
        {
            "Code": 8589,
            "Name": "Lyby"
        },
        {
            "Code": 8964,
            "Name": "Halk"
        },
        {
            "Code": 8895,
            "Name": "Gørding"
        },
        {
            "Code": 8754,
            "Name": "Nørre Omme"
        },
        {
            "Code": 8134,
            "Name": "Mellerup"
        },
        {
            "Code": 8369,
            "Name": "Sankt Markus"
        },
        {
            "Code": 9267,
            "Name": "Vesterhede"
        },
        {
            "Code": 8387,
            "Name": "Hals"
        },
        {
            "Code": 7338,
            "Name": "Munke Bjergby"
        },
        {
            "Code": 8472,
            "Name": "Jelstrup"
        },
        {
            "Code": 8881,
            "Name": "Henne"
        },
        {
            "Code": 7383,
            "Name": "Sorø"
        },
        {
            "Code": 7150,
            "Name": "Torslunde"
        },
        {
            "Code": 7230,
            "Name": "Niløse"
        },
        {
            "Code": 7455,
            "Name": "Skibby"
        },
        {
            "Code": 7691,
            "Name": "Landet"
        },
        {
            "Code": 8916,
            "Name": "Sneum"
        },
        {
            "Code": 7380,
            "Name": "Freerslev"
        },
        {
            "Code": 7219,
            "Name": "Ubby"
        },
        {
            "Code": 7699,
            "Name": "Vester Skerninge"
        },
        {
            "Code": 8023,
            "Name": "Voldby"
        },
        {
            "Code": 7940,
            "Name": "Vonsild"
        },
        {
            "Code": 7381,
            "Name": "Teestrup"
        },
        {
            "Code": 9269,
            "Name": "Hovborg"
        },
        {
            "Code": 8519,
            "Name": "Gedsted"
        },
        {
            "Code": 7927,
            "Name": "Sankt Nicolai"
        },
        {
            "Code": 8729,
            "Name": "Jannerup"
        },
        {
            "Code": 8122,
            "Name": "Åby"
        },
        {
            "Code": 7778,
            "Name": "Munkebjerg"
        },
        {
            "Code": 8060,
            "Name": "Lyngå"
        },
        {
            "Code": 9094,
            "Name": "Rønnevang"
        },
        {
            "Code": 8166,
            "Name": "Ålsø"
        },
        {
            "Code": 7990,
            "Name": "Voerladegård"
        },
        {
            "Code": 8874,
            "Name": "Janderup"
        },
        {
            "Code": 8072,
            "Name": "Ølsted"
        },
        {
            "Code": 7701,
            "Name": "Heden"
        },
        {
            "Code": 8356,
            "Name": "Sebber"
        },
        {
            "Code": 7497,
            "Name": "Strøby"
        },
        {
            "Code": 8536,
            "Name": "Ravnstrup"
        },
        {
            "Code": 8335,
            "Name": "Støvring"
        },
        {
            "Code": 8142,
            "Name": "Vissing"
        },
        {
            "Code": 9125,
            "Name": "Ilderhede"
        },
        {
            "Code": 8782,
            "Name": "Sønder Vium"
        },
        {
            "Code": 7956,
            "Name": "Barrit"
        },
        {
            "Code": 7857,
            "Name": "Skovby"
        },
        {
            "Code": 8270,
            "Name": "Havbro"
        },
        {
            "Code": 9079,
            "Name": "Vestervang"
        },
        {
            "Code": 8265,
            "Name": "Vivild"
        },
        {
            "Code": 9238,
            "Name": "Sørup"
        },
        {
            "Code": 8355,
            "Name": "Vindblæs"
        },
        {
            "Code": 7257,
            "Name": "Sejerø"
        },
        {
            "Code": 7760,
            "Name": "Jordløse"
        },
        {
            "Code": 7221,
            "Name": "Store Fuglede"
        },
        {
            "Code": 9133,
            "Name": "Tingbjerg"
        },
        {
            "Code": 8697,
            "Name": "Tæbring"
        },
        {
            "Code": 8577,
            "Name": "Oddense"
        },
        {
            "Code": 8102,
            "Name": "Holme"
        },
        {
            "Code": 8937,
            "Name": "Andst"
        },
        {
            "Code": 9110,
            "Name": "Margrethe"
        },
        {
            "Code": 9221,
            "Name": "Øster Hjermitslev"
        },
        {
            "Code": 8218,
            "Name": "Auning"
        },
        {
            "Code": 9224,
            "Name": "Dorf"
        },
        {
            "Code": 8397,
            "Name": "Haverslev"
        },
        {
            "Code": 9121,
            "Name": "Gjesing"
        },
        {
            "Code": 8249,
            "Name": "Hjortshøj"
        },
        {
            "Code": 8878,
            "Name": "Kvong"
        },
        {
            "Code": 8466,
            "Name": "Tornby"
        },
        {
            "Code": 7162,
            "Name": "Fløng"
        },
        {
            "Code": 8588,
            "Name": "Jebjerg"
        },
        {
            "Code": 7914,
            "Name": "Bredstrup"
        },
        {
            "Code": 7570,
            "Name": "Bodilsker"
        },
        {
            "Code": 7640,
            "Name": "Vesterborg"
        },
        {
            "Code": 8086,
            "Name": "Tunø"
        },
        {
            "Code": 7556,
            "Name": "Nyker"
        },
        {
            "Code": 7153,
            "Name": "Taastrup Nykirke"
        },
        {
            "Code": 7174,
            "Name": "Særløse"
        },
        {
            "Code": 7922,
            "Name": "Gårslev"
        },
        {
            "Code": 8478,
            "Name": "Abildgård"
        },
        {
            "Code": 7466,
            "Name": "Præstø"
        },
        {
            "Code": 8541,
            "Name": "Rybjerg"
        },
        {
            "Code": 7762,
            "Name": "Svanninge"
        },
        {
            "Code": 8051,
            "Name": "Søvind"
        },
        {
            "Code": 7665,
            "Name": "Østofte"
        },
        {
            "Code": 7026,
            "Name": "Timotheus"
        },
        {
            "Code": 7959,
            "Name": "Klakring"
        },
        {
            "Code": 9196,
            "Name": "Blåvandshuk"
        },
        {
            "Code": 7191,
            "Name": "Kastrup"
        },
        {
            "Code": 8840,
            "Name": "Lemvig"
        },
        {
            "Code": 7252,
            "Name": "Avnsø"
        },
        {
            "Code": 8057,
            "Name": "Hvirring"
        },
        {
            "Code": 7423,
            "Name": "Græse"
        },
        {
            "Code": 7202,
            "Name": "Havdrup"
        },
        {
            "Code": 7942,
            "Name": "Sønder Bjert"
        },
        {
            "Code": 8349,
            "Name": "Løgsted"
        },
        {
            "Code": 8925,
            "Name": "Lindknud"
        },
        {
            "Code": 8996,
            "Name": "Hørup"
        },
        {
            "Code": 7805,
            "Name": "Søby"
        },
        {
            "Code": 8859,
            "Name": "Hygum"
        },
        {
            "Code": 8084,
            "Name": "Stjær"
        },
        {
            "Code": 8617,
            "Name": "Vinkel"
        },
        {
            "Code": 8518,
            "Name": "Østerbølle"
        },
        {
            "Code": 8444,
            "Name": "Hørby"
        },
        {
            "Code": 7832,
            "Name": "Gelsted"
        },
        {
            "Code": 7138,
            "Name": "Grøndalslund"
        },
        {
            "Code": 8779,
            "Name": "Sønder Bork"
        },
        {
            "Code": 7708,
            "Name": "Lindelse"
        },
        {
            "Code": 7414,
            "Name": "Farum"
        },
        {
            "Code": 8650,
            "Name": "Hurup"
        },
        {
            "Code": 8593,
            "Name": "Almind"
        },
        {
            "Code": 8481,
            "Name": "Råbjerg"
        },
        {
            "Code": 8367,
            "Name": "Vesterkær"
        },
        {
            "Code": 7792,
            "Name": "Fangel"
        },
        {
            "Code": 7729,
            "Name": "Skellerup"
        },
        {
            "Code": 7551,
            "Name": "Frøslev"
        },
        {
            "Code": 8862,
            "Name": "Ferring"
        },
        {
            "Code": 8979,
            "Name": "Sommersted"
        },
        {
            "Code": 7707,
            "Name": "Fuglsbølle"
        },
        {
            "Code": 8933,
            "Name": "Veerst"
        },
        {
            "Code": 8877,
            "Name": "Outrup"
        },
        {
            "Code": 9059,
            "Name": "Hostrup"
        },
        {
            "Code": 9250,
            "Name": "Nøbbet"
        },
        {
            "Code": 8689,
            "Name": "Hvidbjerg Vesten Å"
        },
        {
            "Code": 8740,
            "Name": "Sønder Nissum"
        },
        {
            "Code": 8801,
            "Name": "Ilskov"
        },
        {
            "Code": 7486,
            "Name": "Keldby"
        },
        {
            "Code": 7883,
            "Name": "Rolsted"
        },
        {
            "Code": 7907,
            "Name": "Kollerup"
        },
        {
            "Code": 8146,
            "Name": "Vorup"
        },
        {
            "Code": 8633,
            "Name": "Sønder Vinge"
        },
        {
            "Code": 8854,
            "Name": "Humlum"
        },
        {
            "Code": 7376,
            "Name": "Sneslev"
        },
        {
            "Code": 8334,
            "Name": "Øster Hornum"
        },
        {
            "Code": 7167,
            "Name": "Ågerup"
        },
        {
            "Code": 7809,
            "Name": "Holevad"
        },
        {
            "Code": 9103,
            "Name": "Mørdrup"
        },
        {
            "Code": 8501,
            "Name": "Sparkær"
        },
        {
            "Code": 7231,
            "Name": "Ørslev"
        },
        {
            "Code": 7027,
            "Name": "Vigerslev"
        },
        {
            "Code": 8470,
            "Name": "Rubjerg"
        },
        {
            "Code": 8990,
            "Name": "Augustenborg"
        },
        {
            "Code": 8040,
            "Name": "Tåning"
        },
        {
            "Code": 8790,
            "Name": "Sankt Johannes"
        },
        {
            "Code": 7575,
            "Name": "Ønslev"
        },
        {
            "Code": 7444,
            "Name": "Asminderød"
        },
        {
            "Code": 7149,
            "Name": "Vallensbæk"
        },
        {
            "Code": 7429,
            "Name": "Nødebo"
        },
        {
            "Code": 7223,
            "Name": "Sæby"
        },
        {
            "Code": 7386,
            "Name": "Lynge"
        },
        {
            "Code": 8753,
            "Name": "No"
        },
        {
            "Code": 8627,
            "Name": "Skjern"
        },
        {
            "Code": 8034,
            "Name": "Tulstrup"
        },
        {
            "Code": 7176,
            "Name": "Kisserup"
        },
        {
            "Code": 8773,
            "Name": "Hanning"
        },
        {
            "Code": 7627,
            "Name": "Avnede"
        },
        {
            "Code": 7737,
            "Name": "Søllinge"
        },
        {
            "Code": 7667,
            "Name": "Fejø"
        },
        {
            "Code": 9086,
            "Name": "Hadsten"
        },
        {
            "Code": 7426,
            "Name": "Uggeløse"
        },
        {
            "Code": 9097,
            "Name": "Gellerup"
        },
        {
            "Code": 9185,
            "Name": "Vesterbro"
        },
        {
            "Code": 8721,
            "Name": "Thisted"
        },
        {
            "Code": 8592,
            "Name": "Sjørslev"
        },
        {
            "Code": 7335,
            "Name": "Hyllinge"
        },
        {
            "Code": 9164,
            "Name": "Fredens og Nazaret"
        },
        {
            "Code": 7507,
            "Name": "Lundby"
        },
        {
            "Code": 7685,
            "Name": "Kirkeby"
        },
        {
            "Code": 8148,
            "Name": "Hadbjerg"
        },
        {
            "Code": 8978,
            "Name": "Oksenvad"
        },
        {
            "Code": 9292,
            "Name": "Vipperød"
        },
        {
            "Code": 8140,
            "Name": "Lerbjerg"
        },
        {
            "Code": 9032,
            "Name": "Brøns"
        },
        {
            "Code": 8020,
            "Name": "Linå"
        },
        {
            "Code": 8348,
            "Name": "Løgstør"
        },
        {
            "Code": 8154,
            "Name": "Råsted"
        },
        {
            "Code": 7333,
            "Name": "Vallensved"
        },
        {
            "Code": 8819,
            "Name": "Mejrup"
        },
        {
            "Code": 7872,
            "Name": "Revninge"
        },
        {
            "Code": 7856,
            "Name": "Bogense"
        },
        {
            "Code": 7295,
            "Name": "Gislinge"
        },
        {
            "Code": 8260,
            "Name": "Holbæk"
        },
        {
            "Code": 8381,
            "Name": "Sulsted"
        },
        {
            "Code": 7752,
            "Name": "Allested"
        },
        {
            "Code": 9190,
            "Name": "Valby Søndre"
        },
        {
            "Code": 8811,
            "Name": "Arnborg"
        },
        {
            "Code": 9081,
            "Name": "Korsvejens"
        },
        {
            "Code": 8992,
            "Name": "Ulkebøl"
        },
        {
            "Code": 7950,
            "Name": "Urlev"
        },
        {
            "Code": 7502,
            "Name": "Næstelsø"
        },
        {
            "Code": 7789,
            "Name": "Brændekilde"
        },
        {
            "Code": 9057,
            "Name": "Bylderup"
        },
        {
            "Code": 8061,
            "Name": "Vitten"
        },
        {
            "Code": 8179,
            "Name": "Ebdrup"
        },
        {
            "Code": 9115,
            "Name": "Nygårds"
        },
        {
            "Code": 9143,
            "Name": "Sejs-Svejbæk"
        },
        {
            "Code": 7062,
            "Name": "Kapernaums"
        },
        {
            "Code": 9054,
            "Name": "Ballum"
        },
        {
            "Code": 8851,
            "Name": "Gudum"
        },
        {
            "Code": 8043,
            "Name": "Tolstrup"
        },
        {
            "Code": 8863,
            "Name": "Dybe"
        },
        {
            "Code": 9277,
            "Name": "Fjelstervang"
        },
        {
            "Code": 7814,
            "Name": "Orte"
        },
        {
            "Code": 7727,
            "Name": "Flødstrup"
        },
        {
            "Code": 8500,
            "Name": "Stoholm"
        },
        {
            "Code": 7169,
            "Name": "Jyllinge"
        },
        {
            "Code": 7115,
            "Name": "Ordrup"
        },
        {
            "Code": 7932,
            "Name": "Nørre Bjert"
        },
        {
            "Code": 8566,
            "Name": "Rødding"
        },
        {
            "Code": 8028,
            "Name": "Skannerup"
        },
        {
            "Code": 7467,
            "Name": "Ullerød"
        },
        {
            "Code": 8235,
            "Name": "Voldby"
        },
        {
            "Code": 8418,
            "Name": "Øster Brønderslev"
        },
        {
            "Code": 7673,
            "Name": "Arninge"
        },
        {
            "Code": 8169,
            "Name": "Homå"
        },
        {
            "Code": 8228,
            "Name": "Søby"
        },
        {
            "Code": 8833,
            "Name": "Ejsing"
        },
        {
            "Code": 7953,
            "Name": "Daugård"
        },
        {
            "Code": 7105,
            "Name": "Flintholm"
        },
        {
            "Code": 7688,
            "Name": "Kværndrup"
        },
        {
            "Code": 8433,
            "Name": "Åsted"
        },
        {
            "Code": 7829,
            "Name": "Brenderup"
        },
        {
            "Code": 8552,
            "Name": "Volling"
        },
        {
            "Code": 8654,
            "Name": "Gettrup"
        },
        {
            "Code": 9027,
            "Name": "Kliplev"
        },
        {
            "Code": 7908,
            "Name": "Vindelev"
        },
        {
            "Code": 8141,
            "Name": "Nørre Galten"
        },
        {
            "Code": 9166,
            "Name": "Skanderborg"
        },
        {
            "Code": 7206,
            "Name": "Køge"
        },
        {
            "Code": 9152,
            "Name": "Fonnesbæk"
        },
        {
            "Code": 7714,
            "Name": "Marstal"
        },
        {
            "Code": 7810,
            "Name": "Tanderup"
        },
        {
            "Code": 7279,
            "Name": "Kvanløse"
        },
        {
            "Code": 7960,
            "Name": "Rårup"
        },
        {
            "Code": 8829,
            "Name": "Bur"
        },
        {
            "Code": 8521,
            "Name": "Simested"
        },
        {
            "Code": 7813,
            "Name": "Ørsted"
        },
        {
            "Code": 8850,
            "Name": "Nees"
        },
        {
            "Code": 7628,
            "Name": "Vestenskov"
        },
        {
            "Code": 9195,
            "Name": "Søllested-Skovlænge-Gurreby"
        },
        {
            "Code": 9107,
            "Name": "Godthåb"
        },
        {
            "Code": 7432,
            "Name": "Græsted"
        },
        {
            "Code": 7879,
            "Name": "Fraugde"
        },
        {
            "Code": 8351,
            "Name": "Malle"
        },
        {
            "Code": 7488,
            "Name": "Borre"
        },
        {
            "Code": 8458,
            "Name": "Tårs"
        },
        {
            "Code": 9264,
            "Name": "Stenderup"
        },
        {
            "Code": 8377,
            "Name": "Lindholm"
        },
        {
            "Code": 8411,
            "Name": "Tise"
        },
        {
            "Code": 8517,
            "Name": "Vesterbølle"
        },
        {
            "Code": 7769,
            "Name": "Ellested"
        },
        {
            "Code": 7524,
            "Name": "Spjellerup"
        },
        {
            "Code": 7168,
            "Name": "Kirkerup"
        },
        {
            "Code": 7651,
            "Name": "Rødby"
        },
        {
            "Code": 7002,
            "Name": "Helligånds"
        },
        {
            "Code": 8446,
            "Name": "Skæve"
        },
        {
            "Code": 8359,
            "Name": "Kornum"
        },
        {
            "Code": 7617,
            "Name": "Stadager"
        },
        {
            "Code": 8382,
            "Name": "Ajstrup"
        },
        {
            "Code": 8003,
            "Name": "Tørring"
        },
        {
            "Code": 8079,
            "Name": "Borum"
        },
        {
            "Code": 8843,
            "Name": "Tørring"
        },
        {
            "Code": 9217,
            "Name": "Terndrup"
        },
        {
            "Code": 8004,
            "Name": "Hammer"
        },
        {
            "Code": 8703,
            "Name": "Vejerslev"
        },
        {
            "Code": 7447,
            "Name": "Birkerød"
        },
        {
            "Code": 8562,
            "Name": "Ørum"
        },
        {
            "Code": 8233,
            "Name": "Enslev"
        },
        {
            "Code": 7179,
            "Name": "Kirke Hyllinge"
        },
        {
            "Code": 8012,
            "Name": "Hørning"
        },
        {
            "Code": 8353,
            "Name": "Hyllebjerg"
        },
        {
            "Code": 7854,
            "Name": "Nørre Højrup"
        },
        {
            "Code": 9192,
            "Name": "Tuse Næs"
        },
        {
            "Code": 7498,
            "Name": "Varpelev"
        },
        {
            "Code": 8467,
            "Name": "Vidstrup"
        },
        {
            "Code": 9146,
            "Name": "Lystrup"
        },
        {
            "Code": 7952,
            "Name": "Ørum"
        },
        {
            "Code": 8389,
            "Name": "Dall"
        },
        {
            "Code": 8986,
            "Name": "Hjerndrup"
        },
        {
            "Code": 8984,
            "Name": "Frørup"
        },
        {
            "Code": 9248,
            "Name": "Langø"
        },
        {
            "Code": 8190,
            "Name": "Kastbjerg"
        },
        {
            "Code": 7403,
            "Name": "Skævinge"
        },
        {
            "Code": 8468,
            "Name": "Skallerup"
        },
        {
            "Code": 9223,
            "Name": "Melholt"
        },
        {
            "Code": 7273,
            "Name": "Rørby"
        },
        {
            "Code": 8681,
            "Name": "Tødsø"
        },
        {
            "Code": 8696,
            "Name": "Mollerup"
        },
        {
            "Code": 9273,
            "Name": "Rousthøje"
        },
        {
            "Code": 8357,
            "Name": "Store Ajstrup"
        },
        {
            "Code": 8799,
            "Name": "Gjellerup"
        },
        {
            "Code": 7510,
            "Name": "Sankt Peders"
        },
        {
            "Code": 8815,
            "Name": "Nørrelands"
        },
        {
            "Code": 9015,
            "Name": "Øster Løgum"
        },
        {
            "Code": 7440,
            "Name": "Tikøb"
        },
        {
            "Code": 7631,
            "Name": "Tillitse"
        },
        {
            "Code": 8640,
            "Name": "Kvorning"
        },
        {
            "Code": 9063,
            "Name": "Lendum"
        },
        {
            "Code": 8480,
            "Name": "Skagen"
        },
        {
            "Code": 8658,
            "Name": "Odby"
        },
        {
            "Code": 8153,
            "Name": "Kousted"
        },
        {
            "Code": 7227,
            "Name": "Ruds Vedby"
        },
        {
            "Code": 8903,
            "Name": "Jernved"
        },
        {
            "Code": 7051,
            "Name": "Sankt Stefans"
        },
        {
            "Code": 9118,
            "Name": "Broholm"
        },
        {
            "Code": 7326,
            "Name": "Agersø"
        },
        {
            "Code": 8111,
            "Name": "Astrup"
        },
        {
            "Code": 8182,
            "Name": "Sankt Peders"
        },
        {
            "Code": 8054,
            "Name": "Tamdrup"
        },
        {
            "Code": 8927,
            "Name": "Holsted"
        },
        {
            "Code": 8636,
            "Name": "Ørum"
        },
        {
            "Code": 9140,
            "Name": "Skelgård"
        },
        {
            "Code": 7719,
            "Name": "Oure"
        },
        {
            "Code": 7187,
            "Name": "Ballerup"
        },
        {
            "Code": 8187,
            "Name": "Tvede"
        },
        {
            "Code": 7924,
            "Name": "Jerlev"
        },
        {
            "Code": 9160,
            "Name": "Antvorskov"
        },
        {
            "Code": 7379,
            "Name": "Haslev"
        },
        {
            "Code": 9131,
            "Name": "Hjallese"
        },
        {
            "Code": 8810,
            "Name": "Skarrild"
        },
        {
            "Code": 7041,
            "Name": "Kastels"
        },
        {
            "Code": 8293,
            "Name": "Grynderup"
        },
        {
            "Code": 7351,
            "Name": "Nordrupvester"
        },
        {
            "Code": 8956,
            "Name": "Agerskov"
        },
        {
            "Code": 8901,
            "Name": "Vilslev"
        },
        {
            "Code": 7758,
            "Name": "Vester Hæsinge"
        },
        {
            "Code": 8170,
            "Name": "Lyngby"
        },
        {
            "Code": 7717,
            "Name": "Bregninge"
        },
        {
            "Code": 8673,
            "Name": "Alsted"
        },
        {
            "Code": 8605,
            "Name": "Lemming"
        },
        {
            "Code": 8483,
            "Name": "Uggerby"
        },
        {
            "Code": 8825,
            "Name": "Naur"
        },
        {
            "Code": 8352,
            "Name": "Overlade"
        },
        {
            "Code": 8263,
            "Name": "Estruplund"
        },
        {
            "Code": 7103,
            "Name": "Solbjerg"
        },
        {
            "Code": 7127,
            "Name": "Ny Holte"
        },
        {
            "Code": 8873,
            "Name": "Varde"
        },
        {
            "Code": 8035,
            "Name": "Fruering"
        },
        {
            "Code": 8624,
            "Name": "Lee"
        },
        {
            "Code": 8459,
            "Name": "Sindal"
        },
        {
            "Code": 7204,
            "Name": "Jersie"
        },
        {
            "Code": 8733,
            "Name": "Vigsø"
        },
        {
            "Code": 9022,
            "Name": "Ensted"
        },
        {
            "Code": 8757,
            "Name": "Hover"
        },
        {
            "Code": 8081,
            "Name": "Harlev"
        },
        {
            "Code": 7870,
            "Name": "Birkende"
        },
        {
            "Code": 8879,
            "Name": "Nørre Nebel"
        },
        {
            "Code": 9155,
            "Name": "Engholm"
        },
        {
            "Code": 9029,
            "Name": "Bov"
        },
        {
            "Code": 7791,
            "Name": "Stenløse"
        },
        {
            "Code": 7793,
            "Name": "Verninge"
        },
        {
            "Code": 8002,
            "Name": "Linnerup"
        },
        {
            "Code": 7736,
            "Name": "Herrested"
        },
        {
            "Code": 7268,
            "Name": "Vor Frue"
        },
        {
            "Code": 8827,
            "Name": "Gørding"
        },
        {
            "Code": 8886,
            "Name": "Ribe Domsogn"
        },
        {
            "Code": 8289,
            "Name": "Kongens Tisted"
        },
        {
            "Code": 9265,
            "Name": "Urup"
        },
        {
            "Code": 9120,
            "Name": "Kollund"
        },
        {
            "Code": 8574,
            "Name": "Hem"
        },
        {
            "Code": 8596,
            "Name": "Grønbæk"
        },
        {
            "Code": 8213,
            "Name": "Kristrup"
        },
        {
            "Code": 7040,
            "Name": "Østervold"
        },
        {
            "Code": 8831,
            "Name": "Rønbjerg"
        },
        {
            "Code": 7156,
            "Name": "Roskilde Domsogn"
        },
        {
            "Code": 7480,
            "Name": "Mern"
        },
        {
            "Code": 7818,
            "Name": "Flemløse"
        },
        {
            "Code": 8743,
            "Name": "Idom"
        },
        {
            "Code": 8219,
            "Name": "Marie Magdalene"
        },
        {
            "Code": 8121,
            "Name": "Christians"
        },
        {
            "Code": 8985,
            "Name": "Tyrstrup"
        },
        {
            "Code": 7978,
            "Name": "Vester"
        },
        {
            "Code": 8836,
            "Name": "Sevel"
        },
        {
            "Code": 9014,
            "Name": "Rise"
        },
        {
            "Code": 8171,
            "Name": "Albøge"
        },
        {
            "Code": 7734,
            "Name": "Frørup"
        },
        {
            "Code": 8557,
            "Name": "Vejby"
        },
        {
            "Code": 7336,
            "Name": "Kvislemark"
        },
        {
            "Code": 8207,
            "Name": "Snæbum"
        },
        {
            "Code": 8856,
            "Name": "Harboøre"
        },
        {
            "Code": 8910,
            "Name": "Øse"
        },
        {
            "Code": 7749,
            "Name": "Gestelev"
        },
        {
            "Code": 8544,
            "Name": "Nautrup"
        },
        {
            "Code": 7605,
            "Name": "Kettinge"
        },
        {
            "Code": 8361,
            "Name": "Oudrup"
        },
        {
            "Code": 7287,
            "Name": "Kirke Eskilstrup"
        },
        {
            "Code": 8998,
            "Name": "Kegnæs"
        },
        {
            "Code": 9268,
            "Name": "Sankt Peders"
        },
        {
            "Code": 7140,
            "Name": "Hendriksholm"
        },
        {
            "Code": 8330,
            "Name": "Frejlev"
        },
        {
            "Code": 8763,
            "Name": "Nysogn"
        },
        {
            "Code": 7639,
            "Name": "Stokkemarke"
        },
        {
            "Code": 8254,
            "Name": "Agri"
        },
        {
            "Code": 7256,
            "Name": "Hørve"
        },
        {
            "Code": 7184,
            "Name": "Sengeløse"
        },
        {
            "Code": 7523,
            "Name": "Hylleholt"
        },
        {
            "Code": 9012,
            "Name": "Løjt"
        },
        {
            "Code": 8125,
            "Name": "Hasle"
        },
        {
            "Code": 8068,
            "Name": "Spørring"
        },
        {
            "Code": 8210,
            "Name": "Lem"
        },
        {
            "Code": 8268,
            "Name": "Nørager"
        },
        {
            "Code": 7193,
            "Name": "Dragør"
        },
        {
            "Code": 8041,
            "Name": "Hylke"
        },
        {
            "Code": 8109,
            "Name": "Beder"
        },
        {
            "Code": 8337,
            "Name": "Aarestrup"
        },
        {
            "Code": 8666,
            "Name": "Skyum"
        },
        {
            "Code": 8005,
            "Name": "Klovborg"
        },
        {
            "Code": 9281,
            "Name": "Skærlund"
        },
        {
            "Code": 8183,
            "Name": "Sankt Clemens"
        },
        {
            "Code": 8607,
            "Name": "Svostrup"
        },
        {
            "Code": 7318,
            "Name": "Ting Jellinge"
        },
        {
            "Code": 8981,
            "Name": "Aller"
        },
        {
            "Code": 7374,
            "Name": "Høm"
        },
        {
            "Code": 8920,
            "Name": "Alslev"
        },
        {
            "Code": 7509,
            "Name": "Sværdborg"
        },
        {
            "Code": 8438,
            "Name": "Hals"
        },
        {
            "Code": 7534,
            "Name": "Kongsted"
        },
        {
            "Code": 8454,
            "Name": "Sankt Catharinæ"
        },
        {
            "Code": 7709,
            "Name": "Humble"
        },
        {
            "Code": 7865,
            "Name": "Vigerslev"
        },
        {
            "Code": 8162,
            "Name": "Ginnerup"
        },
        {
            "Code": 7782,
            "Name": "Fredens"
        },
        {
            "Code": 8741,
            "Name": "Staby"
        },
        {
            "Code": 8751,
            "Name": "Velling"
        },
        {
            "Code": 7501,
            "Name": "Holme-Olstrup"
        },
        {
            "Code": 7416,
            "Name": "Nørre Herlev"
        },
        {
            "Code": 8441,
            "Name": "Understed"
        },
        {
            "Code": 8229,
            "Name": "Skader"
        },
        {
            "Code": 7478,
            "Name": "Ørslev"
        },
        {
            "Code": 8805,
            "Name": "Tjørring"
        },
        {
            "Code": 7644,
            "Name": "Våbensted"
        },
        {
            "Code": 9017,
            "Name": "Hellevad"
        },
        {
            "Code": 9044,
            "Name": "Møgeltønder"
        },
        {
            "Code": 8529,
            "Name": "Roum"
        },
        {
            "Code": 8032,
            "Name": "Låsby"
        },
        {
            "Code": 8959,
            "Name": "Branderup"
        },
        {
            "Code": 7626,
            "Name": "Halsted"
        },
        {
            "Code": 7906,
            "Name": "Hover"
        },
        {
            "Code": 7003,
            "Name": "Trinitatis"
        },
        {
            "Code": 7201,
            "Name": "Karlstrup"
        },
        {
            "Code": 7316,
            "Name": "Gimlinge"
        },
        {
            "Code": 7228,
            "Name": "Tersløse"
        },
        {
            "Code": 8711,
            "Name": "Klim"
        },
        {
            "Code": 7935,
            "Name": "Herslev"
        },
        {
            "Code": 7817,
            "Name": "Glamsbjerg"
        },
        {
            "Code": 7853,
            "Name": "Uggerslev"
        },
        {
            "Code": 7897,
            "Name": "Øster Snede"
        },
        {
            "Code": 8651,
            "Name": "Boddum"
        },
        {
            "Code": 8804,
            "Name": "Snejbjerg"
        },
        {
            "Code": 8761,
            "Name": "Vedersø"
        },
        {
            "Code": 8076,
            "Name": "Skjørring"
        },
        {
            "Code": 7946,
            "Name": "Ødis"
        },
        {
            "Code": 8513,
            "Name": "Øster Bjerregrav"
        },
        {
            "Code": 7199,
            "Name": "Tune"
        },
        {
            "Code": 8250,
            "Name": "Egå"
        },
        {
            "Code": 8716,
            "Name": "Kettrup"
        },
        {
            "Code": 7339,
            "Name": "Bromme"
        },
        {
            "Code": 7915,
            "Name": "Taulov"
        },
        {
            "Code": 7887,
            "Name": "Nørre Søby"
        },
        {
            "Code": 8781,
            "Name": "Hemmet"
        },
        {
            "Code": 9194,
            "Name": "Utterslev-Herredskirke-Løjtofte"
        },
        {
            "Code": 8080,
            "Name": "Lyngby"
        },
        {
            "Code": 8333,
            "Name": "Svenstrup"
        },
        {
            "Code": 7154,
            "Name": "Værløse"
        },
        {
            "Code": 7392,
            "Name": "Blistrup"
        },
        {
            "Code": 7893,
            "Name": "Sankt Nikolaj"
        },
        {
            "Code": 7368,
            "Name": "Valsølille"
        },
        {
            "Code": 8055,
            "Name": "Nim"
        },
        {
            "Code": 7951,
            "Name": "Stenderup"
        },
        {
            "Code": 7348,
            "Name": "Sankt Povls"
        },
        {
            "Code": 8062,
            "Name": "Haldum"
        },
        {
            "Code": 7141,
            "Name": "Hvidovre"
        },
        {
            "Code": 8897,
            "Name": "Åstrup"
        },
        {
            "Code": 8675,
            "Name": "Solbjerg"
        },
        {
            "Code": 7801,
            "Name": "Assens"
        },
        {
            "Code": 8728,
            "Name": "Hundborg"
        },
        {
            "Code": 7553,
            "Name": "Knudsker"
        },
        {
            "Code": 8014,
            "Name": "Dover"
        },
        {
            "Code": 8425,
            "Name": "Hune"
        },
        {
            "Code": 7134,
            "Name": "Mørkhøj"
        },
        {
            "Code": 9046,
            "Name": "Nørre Løgum"
        },
        {
            "Code": 7795,
            "Name": "Brylle"
        },
        {
            "Code": 7730,
            "Name": "Ellinge"
        },
        {
            "Code": 7286,
            "Name": "Soderup"
        },
        {
            "Code": 7437,
            "Name": "Sankt Olai"
        },
        {
            "Code": 7573,
            "Name": "Pedersker"
        },
        {
            "Code": 7454,
            "Name": "Dråby"
        },
        {
            "Code": 7537,
            "Name": "Bjæverskov"
        },
        {
            "Code": 9176,
            "Name": "Hjembæk-Svinninge"
        },
        {
            "Code": 9090,
            "Name": "Helligånds"
        },
        {
            "Code": 8649,
            "Name": "Agger"
        },
        {
            "Code": 7984,
            "Name": "Gadbjerg"
        },
        {
            "Code": 7180,
            "Name": "Lyndby"
        },
        {
            "Code": 8720,
            "Name": "Arup"
        },
        {
            "Code": 8071,
            "Name": "Trige"
        },
        {
            "Code": 7847,
            "Name": "Næsby"
        },
        {
            "Code": 8789,
            "Name": "Herning"
        },
        {
            "Code": 8747,
            "Name": "Vinding"
        },
        {
            "Code": 7696,
            "Name": "Diernæs"
        },
        {
            "Code": 7800,
            "Name": "Kølstrup"
        },
        {
            "Code": 7919,
            "Name": "Mølholm"
        },
        {
            "Code": 8463,
            "Name": "Horne"
        },
        {
            "Code": 7391,
            "Name": "Bjernede"
        },
        {
            "Code": 7963,
            "Name": "Hjarnø"
        },
        {
            "Code": 7275,
            "Name": "Tveje Merløse"
        },
        {
            "Code": 9201,
            "Name": "Feldborg"
        },
        {
            "Code": 7211,
            "Name": "Syv"
        },
        {
            "Code": 8632,
            "Name": "Torup"
        },
        {
            "Code": 8123,
            "Name": "Vejlby"
        },
        {
            "Code": 8038,
            "Name": "Yding"
        },
        {
            "Code": 9075,
            "Name": "Aarup"
        },
        {
            "Code": 7546,
            "Name": "Endeslev"
        },
        {
            "Code": 8732,
            "Name": "Hansted"
        },
        {
            "Code": 9114,
            "Name": "Stenderup"
        },
        {
            "Code": 8527,
            "Name": "Klejtrup"
        },
        {
            "Code": 7826,
            "Name": "Strib"
        },
        {
            "Code": 7766,
            "Name": "Ringe"
        },
        {
            "Code": 8124,
            "Name": "Risskov"
        },
        {
            "Code": 7462,
            "Name": "Næsby"
        },
        {
            "Code": 9181,
            "Name": "Frederiksberg"
        },
        {
            "Code": 9130,
            "Name": "Mejdal"
        },
        {
            "Code": 8026,
            "Name": "Tvilum"
        },
        {
            "Code": 7710,
            "Name": "Tryggelev"
        },
        {
            "Code": 8143,
            "Name": "Voldum"
        },
        {
            "Code": 7337,
            "Name": "Fyrendal"
        },
        {
            "Code": 7873,
            "Name": "Mesinge"
        },
        {
            "Code": 7410,
            "Name": "Stenløse"
        },
        {
            "Code": 8770,
            "Name": "Sønder Lem"
        },
        {
            "Code": 8066,
            "Name": "Sabro"
        },
        {
            "Code": 8946,
            "Name": "Gram"
        },
        {
            "Code": 8718,
            "Name": "Øsløs"
        },
        {
            "Code": 7425,
            "Name": "Lynge"
        },
        {
            "Code": 8017,
            "Name": "Silkeborg"
        },
        {
            "Code": 7160,
            "Name": "Himmelev"
        },
        {
            "Code": 7496,
            "Name": "Holtug"
        },
        {
            "Code": 7234,
            "Name": "Gierslev"
        },
        {
            "Code": 8234,
            "Name": "Karlby"
        },
        {
            "Code": 7406,
            "Name": "Gørløse"
        },
        {
            "Code": 7200,
            "Name": "Karlslunde"
        },
        {
            "Code": 8507,
            "Name": "Fly"
        },
        {
            "Code": 7344,
            "Name": "Sigersted"
        },
        {
            "Code": 8194,
            "Name": "Sødring"
        },
        {
            "Code": 7459,
            "Name": "Selsø"
        },
        {
            "Code": 8816,
            "Name": "Struer"
        },
        {
            "Code": 7346,
            "Name": "Hejninge"
        },
        {
            "Code": 8195,
            "Name": "Øster Tørslev"
        },
        {
            "Code": 8272,
            "Name": "Flejsborg"
        },
        {
            "Code": 8368,
            "Name": "Vor Frue"
        },
        {
            "Code": 8191,
            "Name": "Dalbyneder"
        },
        {
            "Code": 8604,
            "Name": "Serup"
        },
        {
            "Code": 7235,
            "Name": "Finderup"
        },
        {
            "Code": 8705,
            "Name": "Ljørslev"
        },
        {
            "Code": 7583,
            "Name": "Sønder Kirkeby"
        },
        {
            "Code": 8075,
            "Name": "Sjelle"
        },
        {
            "Code": 9137,
            "Name": "Askov"
        },
        {
            "Code": 7190,
            "Name": "Tårnby"
        },
        {
            "Code": 8264,
            "Name": "Ørsted"
        },
        {
            "Code": 7657,
            "Name": "Fuglse"
        },
        {
            "Code": 7871,
            "Name": "Rynkeby"
        },
        {
            "Code": 8746,
            "Name": "Tvis"
        },
        {
            "Code": 7692,
            "Name": "Bjerreby"
        },
        {
            "Code": 8610,
            "Name": "Kragelund"
        },
        {
            "Code": 9010,
            "Name": "Aabenraa"
        },
        {
            "Code": 7475,
            "Name": "Beldringe"
        },
        {
            "Code": 8230,
            "Name": "Halling"
        },
        {
            "Code": 7535,
            "Name": "Roholte"
        },
        {
            "Code": 8082,
            "Name": "Framlev"
        },
        {
            "Code": 9275,
            "Name": "Væggerskilde"
        },
        {
            "Code": 7877,
            "Name": "Seden"
        },
        {
            "Code": 8394,
            "Name": "Øland"
        },
        {
            "Code": 8031,
            "Name": "Dallerup"
        },
        {
            "Code": 9150,
            "Name": "Langeslund"
        },
        {
            "Code": 9249,
            "Name": "Gedser"
        },
        {
            "Code": 7181,
            "Name": "Sæby"
        },
        {
            "Code": 8376,
            "Name": "Hvorup"
        },
        {
            "Code": 8112,
            "Name": "Tulstrup"
        },
        {
            "Code": 8116,
            "Name": "Sankt Markus"
        },
        {
            "Code": 8131,
            "Name": "Kasted"
        },
        {
            "Code": 7468,
            "Name": "Skibinge"
        },
        {
            "Code": 8943,
            "Name": "Grene"
        },
        {
            "Code": 8424,
            "Name": "Jetsmark"
        },
        {
            "Code": 7264,
            "Name": "Egebjerg"
        },
        {
            "Code": 7430,
            "Name": "Søborg"
        },
        {
            "Code": 7807,
            "Name": "Barløse"
        },
        {
            "Code": 7155,
            "Name": "Herstedvester"
        },
        {
            "Code": 7875,
            "Name": "Dalby"
        },
        {
            "Code": 8674,
            "Name": "Sønder Dråby"
        },
        {
            "Code": 7508,
            "Name": "Køng"
        },
        {
            "Code": 8498,
            "Name": "Kvols"
        },
        {
            "Code": 7702,
            "Name": "Bøstrup"
        },
        {
            "Code": 7068,
            "Name": "Brønshøj"
        },
        {
            "Code": 9100,
            "Name": "Bredballe"
        },
        {
            "Code": 8526,
            "Name": "Vester Bjerregrav"
        },
        {
            "Code": 7589,
            "Name": "Horbelev"
        },
        {
            "Code": 9220,
            "Name": "Mylund"
        },
        {
            "Code": 9254,
            "Name": "Bagenkop"
        },
        {
            "Code": 8206,
            "Name": "Hvornum"
        },
        {
            "Code": 7754,
            "Name": "Nørre Broby"
        },
        {
            "Code": 8645,
            "Name": "Hunstrup"
        },
        {
            "Code": 8614,
            "Name": "Søndre"
        },
        {
            "Code": 8657,
            "Name": "Søndbjerg"
        },
        {
            "Code": 8826,
            "Name": "Sir"
        },
        {
            "Code": 7224,
            "Name": "Hallenslev"
        },
        {
            "Code": 8570,
            "Name": "Lindum"
        },
        {
            "Code": 8995,
            "Name": "Tandslet"
        },
        {
            "Code": 7355,
            "Name": "Slots Bjergby"
        },
        {
            "Code": 8726,
            "Name": "Skjoldborg"
        },
        {
            "Code": 8215,
            "Name": "Essenbæk"
        },
        {
            "Code": 7456,
            "Name": "Ferslev"
        },
        {
            "Code": 7867,
            "Name": "Søndersø"
        },
        {
            "Code": 8905,
            "Name": "Vor Frelsers"
        },
        {
            "Code": 8971,
            "Name": "Vilstrup"
        },
        {
            "Code": 8392,
            "Name": "Tranum"
        },
        {
            "Code": 8871,
            "Name": "Ølgod"
        },
        {
            "Code": 8924,
            "Name": "Sønderho"
        },
        {
            "Code": 9148,
            "Name": "Tornbjerg"
        },
        {
            "Code": 7634,
            "Name": "Landet"
        },
        {
            "Code": 7464,
            "Name": "Sandby"
        },
        {
            "Code": 7420,
            "Name": "Snostrup"
        },
        {
            "Code": 7811,
            "Name": "Kerte"
        },
        {
            "Code": 8029,
            "Name": "Røgen"
        },
        {
            "Code": 8764,
            "Name": "Gammelsogn"
        },
        {
            "Code": 8464,
            "Name": "Asdal"
        },
        {
            "Code": 7992,
            "Name": "Vinding"
        },
        {
            "Code": 7934,
            "Name": "Bramdrup"
        },
        {
            "Code": 8872,
            "Name": "Ansager"
        },
        {
            "Code": 7567,
            "Name": "Østermarie"
        },
        {
            "Code": 8232,
            "Name": "Hammelev"
        },
        {
            "Code": 7461,
            "Name": "Krogstrup"
        },
        {
            "Code": 8489,
            "Name": "Vejerslev"
        },
        {
            "Code": 8237,
            "Name": "Villersø"
        },
        {
            "Code": 8550,
            "Name": "Lihme"
        },
        {
            "Code": 7663,
            "Name": "Tirsted"
        },
        {
            "Code": 7988,
            "Name": "Gammel Rye"
        },
        {
            "Code": 7443,
            "Name": "Egebæksvang"
        },
        {
            "Code": 9229,
            "Name": "Sørig"
        },
        {
            "Code": 7393,
            "Name": "Vejby"
        },
        {
            "Code": 8297,
            "Name": "Siem"
        },
        {
            "Code": 7751,
            "Name": "Vantinge"
        },
        {
            "Code": 7205,
            "Name": "Kirke Skensved"
        },
        {
            "Code": 7672,
            "Name": "Stormark"
        },
        {
            "Code": 8723,
            "Name": "Skinnerup"
        },
        {
            "Code": 8977,
            "Name": "Jels"
        },
        {
            "Code": 7819,
            "Name": "Haarby"
        },
        {
            "Code": 9252,
            "Name": "Stige"
        },
        {
            "Code": 8091,
            "Name": "Randlev"
        },
        {
            "Code": 7703,
            "Name": "Snøde"
        },
        {
            "Code": 8302,
            "Name": "Fræer"
        },
        {
            "Code": 7944,
            "Name": "Hejls"
        },
        {
            "Code": 8300,
            "Name": "Blenstrup"
        },
        {
            "Code": 7522,
            "Name": "Faxe"
        },
        {
            "Code": 8408,
            "Name": "Vejby"
        },
        {
            "Code": 7125,
            "Name": "Søllerød"
        },
        {
            "Code": 8875,
            "Name": "Billum"
        },
        {
            "Code": 7936,
            "Name": "Almind"
        },
        {
            "Code": 8412,
            "Name": "Tolstrup"
        },
        {
            "Code": 7441,
            "Name": "Hornbæk"
        },
        {
            "Code": 8273,
            "Name": "Ulstrup"
        },
        {
            "Code": 7898,
            "Name": "Grejs"
        },
        {
            "Code": 8158,
            "Name": "Kærby"
        },
        {
            "Code": 8515,
            "Name": "Tånum"
        },
        {
            "Code": 8013,
            "Name": "Adslev"
        },
        {
            "Code": 8133,
            "Name": "Støvring"
        },
        {
            "Code": 7152,
            "Name": "Høje Taastrup"
        },
        {
            "Code": 8634,
            "Name": "Tjele"
        },
        {
            "Code": 7726,
            "Name": "Bovense"
        },
        {
            "Code": 8944,
            "Name": "Vorbasse"
        },
        {
            "Code": 7981,
            "Name": "Filskov"
        },
        {
            "Code": 9041,
            "Name": "Abild"
        },
        {
            "Code": 7652,
            "Name": "Ringsebølle"
        },
        {
            "Code": 7755,
            "Name": "Sønder Broby"
        },
        {
            "Code": 7315,
            "Name": "Kirkerup"
        },
        {
            "Code": 7721,
            "Name": "Gudme"
        },
        {
            "Code": 7676,
            "Name": "Sankt Nikolaj"
        },
        {
            "Code": 8611,
            "Name": "Funder"
        },
        {
            "Code": 7830,
            "Name": "Fjelsted"
        },
        {
            "Code": 8135,
            "Name": "Haslund"
        },
        {
            "Code": 8776,
            "Name": "Sædding"
        },
        {
            "Code": 7712,
            "Name": "Magleby"
        },
        {
            "Code": 8398,
            "Name": "Skræm"
        },
        {
            "Code": 8059,
            "Name": "Endelave"
        },
        {
            "Code": 8214,
            "Name": "Virring"
        },
        {
            "Code": 8327,
            "Name": "Nibe"
        },
        {
            "Code": 8100,
            "Name": "Viby"
        },
        {
            "Code": 8358,
            "Name": "Næsborg"
        },
        {
            "Code": 8161,
            "Name": "Ørum"
        },
        {
            "Code": 8225,
            "Name": "Mygind"
        },
        {
            "Code": 8638,
            "Name": "Vejrum"
        },
        {
            "Code": 9211,
            "Name": "Faurholt"
        },
        {
            "Code": 8553,
            "Name": "Krejbjerg"
        },
        {
            "Code": 8256,
            "Name": "Knebel"
        },
        {
            "Code": 8717,
            "Name": "Gøttrup"
        },
        {
            "Code": 7294,
            "Name": "Hagested"
        },
        {
            "Code": 7892,
            "Name": "Hjadstrup"
        },
        {
            "Code": 8474,
            "Name": "Harritslev"
        },
        {
            "Code": 9236,
            "Name": "Agerø"
        },
        {
            "Code": 7837,
            "Name": "Indslev"
        },
        {
            "Code": 7058,
            "Name": "Vanløse"
        },
        {
            "Code": 8988,
            "Name": "Sankt Marie"
        },
        {
            "Code": 8796,
            "Name": "Timring"
        },
        {
            "Code": 7526,
            "Name": "Hellested"
        },
        {
            "Code": 8678,
            "Name": "Skallerup"
        },
        {
            "Code": 7562,
            "Name": "Rø"
        },
        {
            "Code": 7874,
            "Name": "Viby"
        },
        {
            "Code": 7662,
            "Name": "Sædinge"
        },
        {
            "Code": 8738,
            "Name": "Skovlund"
        },
        {
            "Code": 7693,
            "Name": "Drejø"
        },
        {
            "Code": 7891,
            "Name": "Otterup"
        },
        {
            "Code": 7855,
            "Name": "Skamby"
        },
        {
            "Code": 7899,
            "Name": "Sindbjerg"
        },
        {
            "Code": 8548,
            "Name": "Fur"
        },
        {
            "Code": 8572,
            "Name": "Skive"
        },
        {
            "Code": 8691,
            "Name": "Lodbjerg"
        },
        {
            "Code": 8119,
            "Name": "Langenæs"
        },
        {
            "Code": 8203,
            "Name": "Sem"
        },
        {
            "Code": 8145,
            "Name": "Årslev"
        },
        {
            "Code": 8426,
            "Name": "Gjøl"
        },
        {
            "Code": 7918,
            "Name": "Skærup"
        },
        {
            "Code": 7582,
            "Name": "Gedesby"
        },
        {
            "Code": 7864,
            "Name": "Hårslev"
        },
        {
            "Code": 8484,
            "Name": "Sahl"
        },
        {
            "Code": 7146,
            "Name": "Avedøre"
        },
        {
            "Code": 7111,
            "Name": "Gentofte"
        },
        {
            "Code": 7632,
            "Name": "Gloslunde"
        },
        {
            "Code": 8664,
            "Name": "Harring"
        },
        {
            "Code": 8904,
            "Name": "Aalestrup"
        },
        {
            "Code": 8698,
            "Name": "Ovtrup"
        },
        {
            "Code": 8496,
            "Name": "Balle"
        },
        {
            "Code": 8712,
            "Name": "Vester Torup"
        },
        {
            "Code": 8597,
            "Name": "Levring"
        },
        {
            "Code": 9158,
            "Name": "Skæring"
        },
        {
            "Code": 9016,
            "Name": "Bedsted"
        },
        {
            "Code": 7828,
            "Name": "Roerslev"
        },
        {
            "Code": 7812,
            "Name": "Skydebjerg"
        },
        {
            "Code": 8044,
            "Name": "Ørridslev"
        },
        {
            "Code": 8030,
            "Name": "Sporup"
        },
        {
            "Code": 8913,
            "Name": "Vester Nykirke"
        },
        {
            "Code": 7350,
            "Name": "Sønderup"
        },
        {
            "Code": 8786,
            "Name": "Hoven"
        },
        {
            "Code": 7124,
            "Name": "Taarbæk"
        },
        {
            "Code": 7119,
            "Name": "Kongens Lyngby"
        },
        {
            "Code": 7718,
            "Name": "Søby"
        },
        {
            "Code": 8814,
            "Name": "Måbjerg"
        },
        {
            "Code": 8198,
            "Name": "Hobro"
        },
        {
            "Code": 7471,
            "Name": "Jungshoved"
        },
        {
            "Code": 8104,
            "Name": "Skåde"
        },
        {
            "Code": 9153,
            "Name": "Agerbæk"
        },
        {
            "Code": 8558,
            "Name": "Højslev"
        },
        {
            "Code": 7469,
            "Name": "Vordingborg"
        },
        {
            "Code": 7609,
            "Name": "Torkilstrup"
        },
        {
            "Code": 9124,
            "Name": "Troldhede"
        },
        {
            "Code": 7080,
            "Name": "Islands Brygges"
        },
        {
            "Code": 8742,
            "Name": "Ulfborg"
        },
        {
            "Code": 8880,
            "Name": "Lydum"
        },
        {
            "Code": 7886,
            "Name": "Højby"
        },
        {
            "Code": 7566,
            "Name": "Gudhjem"
        },
        {
            "Code": 7850,
            "Name": "Bederslev"
        },
        {
            "Code": 7794,
            "Name": "Tommerup"
        },
        {
            "Code": 8308,
            "Name": "Rostrup"
        },
        {
            "Code": 8818,
            "Name": "Vejrum"
        },
        {
            "Code": 8606,
            "Name": "Gødvad"
        },
        {
            "Code": 8758,
            "Name": "Stadil"
        },
        {
            "Code": 7500,
            "Name": "Rønnebæk"
        },
        {
            "Code": 8486,
            "Name": "Hvorslev"
        },
        {
            "Code": 8958,
            "Name": "Tirslund"
        },
        {
            "Code": 7387,
            "Name": "Vester Broby"
        },
        {
            "Code": 7559,
            "Name": "Olsker"
        },
        {
            "Code": 8967,
            "Name": "Bjerning"
        },
        {
            "Code": 7274,
            "Name": "Sankt Nikolai"
        },
        {
            "Code": 7470,
            "Name": "Kastrup"
        },
        {
            "Code": 7311,
            "Name": "Flakkebjerg"
        },
        {
            "Code": 8415,
            "Name": "Em"
        },
        {
            "Code": 7598,
            "Name": "Fjelde"
        },
        {
            "Code": 7909,
            "Name": "Trinitatis"
        },
        {
            "Code": 7166,
            "Name": "Svogerslev"
        },
        {
            "Code": 7144,
            "Name": "Brøndbyvester"
        },
        {
            "Code": 7112,
            "Name": "Jægersborg"
        },
        {
            "Code": 8402,
            "Name": "Brønderslev"
        },
        {
            "Code": 7993,
            "Name": "Bryrup"
        },
        {
            "Code": 8205,
            "Name": "Nørre Onsild"
        },
        {
            "Code": 8719,
            "Name": "Vesløs"
        },
        {
            "Code": 8152,
            "Name": "Fårup"
        },
        {
            "Code": 9258,
            "Name": "Lundeborg"
        },
        {
            "Code": 7046,
            "Name": "Hans Egedes"
        },
        {
            "Code": 7682,
            "Name": "Øster Skerninge"
        },
        {
            "Code": 8039,
            "Name": "Ovsted"
        },
        {
            "Code": 7371,
            "Name": "Nordrupøster"
        },
        {
            "Code": 8540,
            "Name": "Roslev"
        },
        {
            "Code": 7229,
            "Name": "Skellebjerg"
        },
        {
            "Code": 8070,
            "Name": "Folby"
        },
        {
            "Code": 7323,
            "Name": "Sønder Bjerge"
        },
        {
            "Code": 8942,
            "Name": "Grindsted"
        },
        {
            "Code": 8338,
            "Name": "Haverslev"
        },
        {
            "Code": 8890,
            "Name": "Hjortlund"
        },
        {
            "Code": 8542,
            "Name": "Hjerk"
        },
        {
            "Code": 8584,
            "Name": "Thorum"
        },
        {
            "Code": 8538,
            "Name": "Durup"
        },
        {
            "Code": 8167,
            "Name": "Hoed"
        },
        {
            "Code": 8473,
            "Name": "Mårup"
        },
        {
            "Code": 8702,
            "Name": "Hvidbjerg"
        },
        {
            "Code": 8053,
            "Name": "Vor Frelsers"
        },
        {
            "Code": 8980,
            "Name": "Vedsted"
        },
        {
            "Code": 8476,
            "Name": "Frederikshavn"
        },
        {
            "Code": 8659,
            "Name": "Jegindø"
        },
        {
            "Code": 8864,
            "Name": "Ramme"
        },
        {
            "Code": 9172,
            "Name": "Hvide Sande"
        },
        {
            "Code": 7597,
            "Name": "Slemminge"
        },
        {
            "Code": 7578,
            "Name": "Nykøbing F"
        },
        {
            "Code": 8824,
            "Name": "Fousing"
        },
        {
            "Code": 7903,
            "Name": "Bredsten"
        },
        {
            "Code": 7213,
            "Name": "Dåstrup"
        },
        {
            "Code": 8953,
            "Name": "Øster Lindet"
        },
        {
            "Code": 7171,
            "Name": "Osted"
        },
        {
            "Code": 8011,
            "Name": "Blegind"
        },
        {
            "Code": 7192,
            "Name": "Store Magleby"
        },
        {
            "Code": 9171,
            "Name": "Ebeltoft-Dråby-Handrup"
        },
        {
            "Code": 8259,
            "Name": "Helgenæs"
        },
        {
            "Code": 7529,
            "Name": "Alslev"
        },
        {
            "Code": 9179,
            "Name": "Skamstrup-Frydendal"
        },
        {
            "Code": 9256,
            "Name": "Tårup"
        },
        {
            "Code": 7961,
            "Name": "Skjold"
        },
        {
            "Code": 8765,
            "Name": "Skjern"
        },
        {
            "Code": 8940,
            "Name": "Seest"
        },
        {
            "Code": 8199,
            "Name": "Mariager"
        },
        {
            "Code": 7930,
            "Name": "Eltang"
        },
        {
            "Code": 8787,
            "Name": "Strellev"
        },
        {
            "Code": 7687,
            "Name": "Lunde"
        },
        {
            "Code": 8036,
            "Name": "Vitved"
        },
        {
            "Code": 7679,
            "Name": "Skårup"
        },
        {
            "Code": 8178,
            "Name": "Kolind"
        },
        {
            "Code": 8530,
            "Name": "Låstrup"
        },
        {
            "Code": 8807,
            "Name": "Havnstrup"
        },
        {
            "Code": 7358,
            "Name": "Gerlev"
        },
        {
            "Code": 8221,
            "Name": "Øster Alling"
        },
        {
            "Code": 7262,
            "Name": "Vig"
        },
        {
            "Code": 7831,
            "Name": "Harndrup"
        },
        {
            "Code": 8892,
            "Name": "Vester Vedsted"
        },
        {
            "Code": 9266,
            "Name": "Nollund"
        },
        {
            "Code": 8693,
            "Name": "Lødderup"
        },
        {
            "Code": 9051,
            "Name": "Højer"
        },
        {
            "Code": 7748,
            "Name": "Herringe"
        },
        {
            "Code": 7949,
            "Name": "Bjerre"
        },
        {
            "Code": 7349,
            "Name": "Tårnborg"
        },
        {
            "Code": 7973,
            "Name": "Engum"
        },
        {
            "Code": 9062,
            "Name": "Rømø"
        },
        {
            "Code": 7409,
            "Name": "Ølstykke"
        },
        {
            "Code": 8860,
            "Name": "Hove"
        },
        {
            "Code": 7622,
            "Name": "Horslunde"
        },
        {
            "Code": 7226,
            "Name": "Reerslev"
        },
        {
            "Code": 8488,
            "Name": "Vellev"
        },
        {
            "Code": 8950,
            "Name": "Skrave"
        },
        {
            "Code": 7689,
            "Name": "Thurø"
        },
        {
            "Code": 8635,
            "Name": "Nørre Vinge"
        },
        {
            "Code": 9056,
            "Name": "Burkal"
        },
        {
            "Code": 8429,
            "Name": "Bindslev"
        },
        {
            "Code": 7354,
            "Name": "Ottestrup"
        },
        {
            "Code": 8685,
            "Name": "Visby"
        },
        {
            "Code": 7422,
            "Name": "Uvelse"
        },
        {
            "Code": 8684,
            "Name": "Villerslev"
        },
        {
            "Code": 8842,
            "Name": "Nørre Nissum"
        },
        {
            "Code": 8404,
            "Name": "Vrejlev"
        },
        {
            "Code": 8866,
            "Name": "Trans"
        },
        {
            "Code": 7373,
            "Name": "Vetterslev"
        },
        {
            "Code": 8706,
            "Name": "Ørding"
        },
        {
            "Code": 8331,
            "Name": "Nørholm"
        },
        {
            "Code": 8585,
            "Name": "Grinderslev"
        },
        {
            "Code": 8447,
            "Name": "Albæk-Lyngså"
        },
        {
            "Code": 7457,
            "Name": "Vellerup"
        },
        {
            "Code": 7941,
            "Name": "Dalby"
        },
        {
            "Code": 7731,
            "Name": "Kullerup"
        },
        {
            "Code": 7427,
            "Name": "Jørlunde"
        },
        {
            "Code": 7671,
            "Name": "Branderslev"
        },
        {
            "Code": 9262,
            "Name": "Klejs"
        },
        {
            "Code": 7517,
            "Name": "Rislev"
        },
        {
            "Code": 7253,
            "Name": "Værslev"
        },
        {
            "Code": 8625,
            "Name": "Hjorthede"
        },
        {
            "Code": 8339,
            "Name": "Sønderup"
        },
        {
            "Code": 8492,
            "Name": "Houlbjerg"
        },
        {
            "Code": 9228,
            "Name": "Hulsig"
        },
        {
            "Code": 8427,
            "Name": "Hjallerup"
        },
        {
            "Code": 7611,
            "Name": "Gundslev"
        },
        {
            "Code": 9175,
            "Name": "Davids"
        },
        {
            "Code": 7783,
            "Name": "Hans Tausens"
        },
        {
            "Code": 7869,
            "Name": "Marslev"
        },
        {
            "Code": 8332,
            "Name": "Ellidshøj"
        },
        {
            "Code": 9187,
            "Name": "Romdrup-Klarup"
        },
        {
            "Code": 7790,
            "Name": "Bellinge"
        },
        {
            "Code": 7840,
            "Name": "Føns"
        },
        {
            "Code": 8067,
            "Name": "Grundfør"
        },
        {
            "Code": 8846,
            "Name": "Rom"
        },
        {
            "Code": 7249,
            "Name": "Bregninge"
        },
        {
            "Code": 8653,
            "Name": "Helligsø"
        },
        {
            "Code": 7254,
            "Name": "Jorløse"
        },
        {
            "Code": 8106,
            "Name": "Tiset"
        },
        {
            "Code": 9067,
            "Name": "Vamdrup"
        },
        {
            "Code": 7024,
            "Name": "Valby"
        },
        {
            "Code": 8962,
            "Name": "Gammel Haderslev"
        },
        {
            "Code": 7404,
            "Name": "Lille Lyngby"
        },
        {
            "Code": 9099,
            "Name": "Kvaglund"
        },
        {
            "Code": 8284,
            "Name": "Fovlum"
        },
        {
            "Code": 8120,
            "Name": "Sankt Johannes"
        },
        {
            "Code": 8694,
            "Name": "Elsø"
        },
        {
            "Code": 7796,
            "Name": "Vissenbjerg"
        },
        {
            "Code": 8822,
            "Name": "Ølby"
        },
        {
            "Code": 7549,
            "Name": "Højerup"
        },
        {
            "Code": 7742,
            "Name": "Simmerbølle"
        },
        {
            "Code": 7445,
            "Name": "Grønholt"
        },
        {
            "Code": 8423,
            "Name": "Alstrup"
        },
        {
            "Code": 8911,
            "Name": "Næsbjerg"
        },
        {
            "Code": 7123,
            "Name": "Lundtofte"
        },
        {
            "Code": 7245,
            "Name": "Stenlille"
        },
        {
            "Code": 7697,
            "Name": "Vester Åby"
        },
        {
            "Code": 8660,
            "Name": "Snedsted"
        },
        {
            "Code": 8714,
            "Name": "Tømmerby"
        },
        {
            "Code": 7270,
            "Name": "Røsnæs"
        },
        {
            "Code": 7054,
            "Name": "Aldersro"
        },
        {
            "Code": 7596,
            "Name": "Toreby"
        },
        {
            "Code": 9077,
            "Name": "Sønderbro"
        },
        {
            "Code": 8581,
            "Name": "Selde"
        },
        {
            "Code": 9247,
            "Name": "Svinø"
        },
        {
            "Code": 8147,
            "Name": "Ødum"
        },
        {
            "Code": 7116,
            "Name": "Skovshoved"
        },
        {
            "Code": 8497,
            "Name": "Tårup"
        },
        {
            "Code": 8255,
            "Name": "Egens"
        },
        {
            "Code": 7659,
            "Name": "Holeby"
        },
        {
            "Code": 8752,
            "Name": "Hee"
        },
        {
            "Code": 7157,
            "Name": "Roskilde Søndre"
        },
        {
            "Code": 9167,
            "Name": "Ørnhøj"
        },
        {
            "Code": 7137,
            "Name": "Rødovre"
        },
        {
            "Code": 7428,
            "Name": "Esbønderup"
        },
        {
            "Code": 8830,
            "Name": "Estvad"
        },
        {
            "Code": 7214,
            "Name": "Borup"
        },
        {
            "Code": 7773,
            "Name": "Svindinge"
        },
        {
            "Code": 8209,
            "Name": "Gimming"
        },
        {
            "Code": 7121,
            "Name": "Virum"
        },
        {
            "Code": 7603,
            "Name": "Vester Ulslev"
        },
        {
            "Code": 7194,
            "Name": "Greve"
        },
        {
            "Code": 7954,
            "Name": "Stouby"
        },
        {
            "Code": 9098,
            "Name": "Sthens"
        },
        {
            "Code": 7859,
            "Name": "Guldbjerg"
        },
        {
            "Code": 7063,
            "Name": "Tagensbo"
        },
        {
            "Code": 7815,
            "Name": "Søllested"
        },
        {
            "Code": 8276,
            "Name": "Ejdrup"
        },
        {
            "Code": 8837,
            "Name": "Haderup"
        },
        {
            "Code": 8025,
            "Name": "Skorup"
        },
        {
            "Code": 8118,
            "Name": "Sankt Pauls"
        },
        {
            "Code": 7365,
            "Name": "Haraldsted"
        },
        {
            "Code": 7846,
            "Name": "Næsbyhoved-Broby"
        },
        {
            "Code": 7514,
            "Name": "Tybjerg"
        },
        {
            "Code": 7599,
            "Name": "Døllefjelde"
        },
        {
            "Code": 7212,
            "Name": "Ørsted"
        },
        {
            "Code": 7560,
            "Name": "Allinge-Sandvig"
        },
        {
            "Code": 8603,
            "Name": "Karup"
        },
        {
            "Code": 7705,
            "Name": "Hou"
        },
        {
            "Code": 8336,
            "Name": "Gravlev"
        },
        {
            "Code": 8095,
            "Name": "Falling"
        },
        {
            "Code": 8436,
            "Name": "Gærum"
        },
        {
            "Code": 9122,
            "Name": "Tarm"
        },
        {
            "Code": 8138,
            "Name": "Ørum"
        },
        {
            "Code": 8400,
            "Name": "Bejstrup"
        },
        {
            "Code": 8396,
            "Name": "Øster Svenstrup"
        },
        {
            "Code": 7564,
            "Name": "Svaneke"
        },
        {
            "Code": 7763,
            "Name": "Horne"
        },
        {
            "Code": 9282,
            "Name": "Uhre"
        },
        {
            "Code": 7258,
            "Name": "Nykøbing S"
        },
        {
            "Code": 7515,
            "Name": "Herlufmagle"
        },
        {
            "Code": 7011,
            "Name": "Betlehems"
        },
        {
            "Code": 7313,
            "Name": "Fårdrup"
        },
        {
            "Code": 7669,
            "Name": "Askø"
        },
        {
            "Code": 7390,
            "Name": "Slaglille"
        },
        {
            "Code": 7997,
            "Name": "Træden"
        },
        {
            "Code": 8313,
            "Name": "Rold"
        },
        {
            "Code": 8987,
            "Name": "Skodborg"
        },
        {
            "Code": 8224,
            "Name": "Lime"
        },
        {
            "Code": 9276,
            "Name": "Finderup"
        },
        {
            "Code": 7136,
            "Name": "Præstebro"
        },
        {
            "Code": 9085,
            "Name": "Egeris"
        },
        {
            "Code": 7834,
            "Name": "Balslev"
        },
        {
            "Code": 7175,
            "Name": "Kirke Såby"
        },
        {
            "Code": 8410,
            "Name": "Vrensted"
        },
        {
            "Code": 9042,
            "Name": "Visby"
        },
        {
            "Code": 8211,
            "Name": "Harridslev"
        },
        {
            "Code": 8419,
            "Name": "Hallund"
        },
        {
            "Code": 7852,
            "Name": "Grindløse"
        },
        {
            "Code": 7660,
            "Name": "Bursø"
        },
        {
            "Code": 7396,
            "Name": "Annisse"
        },
        {
            "Code": 9101,
            "Name": "Bistrup"
        },
        {
            "Code": 8710,
            "Name": "Hjortdal"
        },
        {
            "Code": 8291,
            "Name": "Durup"
        },
        {
            "Code": 7775,
            "Name": "Ansgars"
        },
        {
            "Code": 7165,
            "Name": "Kornerup"
        },
        {
            "Code": 8136,
            "Name": "Ølst"
        },
        {
            "Code": 9091,
            "Name": "Hedeager"
        },
        {
            "Code": 7198,
            "Name": "Snoldelev"
        },
        {
            "Code": 9244,
            "Name": "Reersø"
        },
        {
            "Code": 7684,
            "Name": "Ollerup"
        },
        {
            "Code": 8760,
            "Name": "Madum"
        },
        {
            "Code": 7713,
            "Name": "Ærøskøbing"
        },
        {
            "Code": 7723,
            "Name": "Gudbjerg"
        },
        {
            "Code": 8968,
            "Name": "Øsby"
        },
        {
            "Code": 7148,
            "Name": "Herstedøster"
        },
        {
            "Code": 8587,
            "Name": "Thise"
        },
        {
            "Code": 7291,
            "Name": "Søndersted"
        },
        {
            "Code": 7327,
            "Name": "Omø"
        },
        {
            "Code": 7619,
            "Name": "Købelev"
        },
        {
            "Code": 7722,
            "Name": "Brudager"
        },
        {
            "Code": 8345,
            "Name": "Komdrup"
        },
        {
            "Code": 8676,
            "Name": "Sundby"
        },
        {
            "Code": 7895,
            "Name": "Sankt Johannes"
        },
        {
            "Code": 7328,
            "Name": "Tystrup"
        },
        {
            "Code": 8731,
            "Name": "Ræhr"
        },
        {
            "Code": 9102,
            "Name": "Ulstrupbro"
        },
        {
            "Code": 7345,
            "Name": "Sankt Mikkels"
        },
        {
            "Code": 8582,
            "Name": "Åsted"
        },
        {
            "Code": 7802,
            "Name": "Bågø"
        },
        {
            "Code": 7110,
            "Name": "Helleruplund"
        },
        {
            "Code": 9174,
            "Name": "Vor Frue"
        },
        {
            "Code": 7971,
            "Name": "Hedensted"
        },
        {
            "Code": 7263,
            "Name": "Nørre Asmindrup"
        },
        {
            "Code": 7544,
            "Name": "Hårlev"
        },
        {
            "Code": 8316,
            "Name": "Hørby"
        },
        {
            "Code": 7250,
            "Name": "Bjergsted"
        },
        {
            "Code": 7520,
            "Name": "Bavelse"
        },
        {
            "Code": 8539,
            "Name": "Tøndering"
        },
        {
            "Code": 7680,
            "Name": "Tved"
        },
        {
            "Code": 8157,
            "Name": "Hald"
        },
        {
            "Code": 7246,
            "Name": "Orø"
        },
        {
            "Code": 9037,
            "Name": "Højrup"
        },
        {
            "Code": 9071,
            "Name": "Haralds"
        },
        {
            "Code": 9112,
            "Name": "Juelsminde"
        },
        {
            "Code": 8475,
            "Name": "Rakkeby"
        },
        {
            "Code": 8384,
            "Name": "Hammer"
        },
        {
            "Code": 8499,
            "Name": "Kobberup"
        },
        {
            "Code": 8551,
            "Name": "Balling"
        },
        {
            "Code": 7882,
            "Name": "Rønninge"
        },
        {
            "Code": 7777,
            "Name": "Thomas Kingos"
        },
        {
            "Code": 7750,
            "Name": "Espe"
        },
        {
            "Code": 9087,
            "Name": "Hannerup"
        },
        {
            "Code": 9076,
            "Name": "Skalborg"
        },
        {
            "Code": 8778,
            "Name": "Faster"
        },
        {
            "Code": 8096,
            "Name": "Gosmer"
        },
        {
            "Code": 8127,
            "Name": "Lisbjerg"
        },
        {
            "Code": 8736,
            "Name": "Vester Vandet"
        },
        {
            "Code": 9036,
            "Name": "Arrild"
        },
        {
            "Code": 8543,
            "Name": "Harre"
        },
        {
            "Code": 7746,
            "Name": "Brahetrolleborg"
        },
        {
            "Code": 7089,
            "Name": "Simon Peters"
        },
        {
            "Code": 8909,
            "Name": "Skads"
        },
        {
            "Code": 8176,
            "Name": "Feldballe"
        },
        {
            "Code": 8457,
            "Name": "Ugilt"
        },
        {
            "Code": 7145,
            "Name": "Brøndbyøster"
        },
        {
            "Code": 8137,
            "Name": "Værum"
        },
        {
            "Code": 8110,
            "Name": "Malling"
        },
        {
            "Code": 7434,
            "Name": "Helsinge"
        },
        {
            "Code": 8204,
            "Name": "Sønder Onsild"
        },
        {
            "Code": 7747,
            "Name": "Krarup"
        },
        {
            "Code": 7716,
            "Name": "Tranderup"
        },
        {
            "Code": 8802,
            "Name": "Ikast"
        },
        {
            "Code": 8223,
            "Name": "Hvilsager"
        },
        {
            "Code": 8803,
            "Name": "Rind"
        },
        {
            "Code": 7629,
            "Name": "Kappel"
        },
        {
            "Code": 8292,
            "Name": "Rørbæk"
        },
        {
            "Code": 8422,
            "Name": "Saltum"
        },
        {
            "Code": 8222,
            "Name": "Vester Alling"
        },
        {
            "Code": 7208,
            "Name": "Rorup"
        },
        {
            "Code": 8258,
            "Name": "Vistoft"
        },
        {
            "Code": 7561,
            "Name": "Klemensker"
        },
        {
            "Code": 7947,
            "Name": "Taps"
        },
        {
            "Code": 8085,
            "Name": "Galten"
        },
        {
            "Code": 8564,
            "Name": "Fiskbæk"
        },
        {
            "Code": 7290,
            "Name": "Undløse"
        },
        {
            "Code": 7720,
            "Name": "Vejstrup"
        },
        {
            "Code": 8503,
            "Name": "Daugbjerg"
        },
        {
            "Code": 7518,
            "Name": "Skelby"
        },
        {
            "Code": 7259,
            "Name": "Rørvig"
        },
        {
            "Code": 9002,
            "Name": "Oksbøl"
        },
        {
            "Code": 7728,
            "Name": "Ullerslev"
        },
        {
            "Code": 7740,
            "Name": "Årslev"
        },
        {
            "Code": 7823,
            "Name": "Middelfart"
        },
        {
            "Code": 9239,
            "Name": "Hareskov"
        },
        {
            "Code": 8914,
            "Name": "Fåborg"
        },
        {
            "Code": 8247,
            "Name": "Todbjerg"
        },
        {
            "Code": 7106,
            "Name": "Sankt Thomas"
        },
        {
            "Code": 8186,
            "Name": "Skjellerup"
        },
        {
            "Code": 9117,
            "Name": "Boholte"
        },
        {
            "Code": 8876,
            "Name": "Lunde"
        },
        {
            "Code": 7966,
            "Name": "Torsted"
        },
        {
            "Code": 8661,
            "Name": "Nørhå"
        },
        {
            "Code": 8739,
            "Name": "Husby"
        },
        {
            "Code": 8560,
            "Name": "Lundø"
        },
        {
            "Code": 9180,
            "Name": "Kollerup-Fjerritslev"
        },
        {
            "Code": 7926,
            "Name": "Ødsted"
        },
        {
            "Code": 7260,
            "Name": "Odden"
        },
        {
            "Code": 8437,
            "Name": "Byrum"
        },
        {
            "Code": 8505,
            "Name": "Smollerup"
        },
        {
            "Code": 7352,
            "Name": "Gudum"
        },
        {
            "Code": 9004,
            "Name": "Broager"
        },
        {
            "Code": 9080,
            "Name": "Rungsted"
        },
        {
            "Code": 8975,
            "Name": "Vojens"
        },
        {
            "Code": 7955,
            "Name": "Hornum"
        },
        {
            "Code": 9030,
            "Name": "Hviding"
        },
        {
            "Code": 8342,
            "Name": "Bislev"
        },
        {
            "Code": 8631,
            "Name": "Langå"
        },
        {
            "Code": 9052,
            "Name": "Emmerlev"
        },
        {
            "Code": 8667,
            "Name": "Hørdum"
        },
        {
            "Code": 7616,
            "Name": "Kippinge"
        },
        {
            "Code": 8189,
            "Name": "Udbyneder"
        },
        {
            "Code": 9031,
            "Name": "Rejsby"
        },
        {
            "Code": 8160,
            "Name": "Glesborg"
        },
        {
            "Code": 9095,
            "Name": "Svingelbjerg"
        },
        {
            "Code": 8430,
            "Name": "Elling"
        },
        {
            "Code": 9283,
            "Name": "Farre"
        },
        {
            "Code": 9183,
            "Name": "Samsø"
        },
        {
            "Code": 7592,
            "Name": "Tårs"
        },
        {
            "Code": 7431,
            "Name": "Gilleleje"
        },
        {
            "Code": 7088,
            "Name": "Højdevang"
        },
        {
            "Code": 7668,
            "Name": "Femø"
        },
        {
            "Code": 8056,
            "Name": "Underup"
        },
        {
            "Code": 9132,
            "Name": "Virklund"
        },
        {
            "Code": 7087,
            "Name": "Sundkirkens"
        },
        {
            "Code": 8156,
            "Name": "Gassum"
        },
        {
            "Code": 7086,
            "Name": "Filips"
        },
        {
            "Code": 7558,
            "Name": "Rutsker"
        },
        {
            "Code": 9246,
            "Name": "Lumsås"
        },
        {
            "Code": 7278,
            "Name": "Nørre Jernløse"
        },
        {
            "Code": 7979,
            "Name": "Sønder Omme"
        },
        {
            "Code": 8902,
            "Name": "Hunderup"
        },
        {
            "Code": 8101,
            "Name": "Fredens"
        },
        {
            "Code": 8274,
            "Name": "Gundersted"
        },
        {
            "Code": 8652,
            "Name": "Ydby"
        },
        {
            "Code": 8360,
            "Name": "Skarp Salling"
        },
        {
            "Code": 7980,
            "Name": "Blåhøj"
        },
        {
            "Code": 7591,
            "Name": "Åstrup"
        },
        {
            "Code": 8242,
            "Name": "Thorsager"
        },
        {
            "Code": 7548,
            "Name": "Store Heddinge"
        },
        {
            "Code": 8759,
            "Name": "Tim"
        },
        {
            "Code": 8283,
            "Name": "Ullits"
        },
        {
            "Code": 7552,
            "Name": "Rønne"
        },
        {
            "Code": 8290,
            "Name": "Binderup"
        },
        {
            "Code": 7568,
            "Name": "Christiansø"
        },
        {
            "Code": 7816,
            "Name": "Vedtofte"
        },
        {
            "Code": 7785,
            "Name": "Korup"
        },
        {
            "Code": 9021,
            "Name": "Varnæs"
        },
        {
            "Code": 7207,
            "Name": "Ølsemagle"
        },
        {
            "Code": 9082,
            "Name": "Rødbyhavn"
        },
        {
            "Code": 7674,
            "Name": "Sandby"
        },
        {
            "Code": 7280,
            "Name": "Sønder Jernløse"
        },
        {
            "Code": 8615,
            "Name": "Asmild"
        },
        {
            "Code": 7325,
            "Name": "Magleby"
        },
        {
            "Code": 7139,
            "Name": "Islev"
        },
        {
            "Code": 8784,
            "Name": "Egvad"
        },
        {
            "Code": 8576,
            "Name": "Dølby"
        },
        {
            "Code": 8537,
            "Name": "Skjød"
        },
        {
            "Code": 7531,
            "Name": "Tureby"
        },
        {
            "Code": 8413,
            "Name": "Stenum"
        },
        {
            "Code": 8898,
            "Name": "Vester Starup"
        },
        {
            "Code": 7824,
            "Name": "Kauslunde"
        },
        {
            "Code": 8077,
            "Name": "Skivholme"
        },
        {
            "Code": 7131,
            "Name": "Søborggård"
        },
        {
            "Code": 7055,
            "Name": "Kildevælds"
        },
        {
            "Code": 8861,
            "Name": "Vandborg"
        },
        {
            "Code": 9240,
            "Name": "Gurre"
        },
        {
            "Code": 9034,
            "Name": "Roager"
        },
        {
            "Code": 8045,
            "Name": "Vedslet"
        },
        {
            "Code": 7271,
            "Name": "Tømmerup"
        },
        {
            "Code": 9003,
            "Name": "Egen"
        },
        {
            "Code": 8391,
            "Name": "Lerup"
        },
        {
            "Code": 8294,
            "Name": "Skibsted"
        },
        {
            "Code": 8788,
            "Name": "Lyne"
        },
        {
            "Code": 7965,
            "Name": "Ølsted"
        },
        {
            "Code": 7308,
            "Name": "Skælskør"
        },
        {
            "Code": 7281,
            "Name": "Søstrup"
        },
        {
            "Code": 8618,
            "Name": "Sønder Rind"
        },
        {
            "Code": 8364,
            "Name": "Budolfi"
        },
        {
            "Code": 9231,
            "Name": "Kvissel"
        },
        {
            "Code": 8282,
            "Name": "Farsø"
        },
        {
            "Code": 8420,
            "Name": "Ingstrup"
        },
        {
            "Code": 7771,
            "Name": "Øksendrup"
        },
        {
            "Code": 7405,
            "Name": "Strø"
        },
        {
            "Code": 7239,
            "Name": "Drøsselbjerg"
        },
        {
            "Code": 7733,
            "Name": "Vindinge"
        },
        {
            "Code": 7745,
            "Name": "Tullebølle"
        },
        {
            "Code": 8555,
            "Name": "Ramsing"
        },
        {
            "Code": 8227,
            "Name": "Skørring"
        },
        {
            "Code": 8354,
            "Name": "Vilsted"
        },
        {
            "Code": 8662,
            "Name": "Sønderhå"
        },
        {
            "Code": 7357,
            "Name": "Lundforlund"
        },
        {
            "Code": 7925,
            "Name": "Egtved"
        },
        {
            "Code": 7839,
            "Name": "Udby"
        },
        {
            "Code": 7767,
            "Name": "Ryslinge"
        },
        {
            "Code": 8090,
            "Name": "Nølev"
        },
        {
            "Code": 8238,
            "Name": "Gjerrild"
        },
        {
            "Code": 8286,
            "Name": "Alstrup"
        },
        {
            "Code": 9202,
            "Name": "Vinderup"
        },
        {
            "Code": 7542,
            "Name": "Valløby"
        },
        {
            "Code": 8197,
            "Name": "Enslev"
        },
        {
            "Code": 8792,
            "Name": "Aulum"
        },
        {
            "Code": 7487,
            "Name": "Elmelunde"
        },
        {
            "Code": 8533,
            "Name": "Lynderup"
        },
        {
            "Code": 7633,
            "Name": "Græshave"
        },
        {
            "Code": 7989,
            "Name": "Sønder Vissing"
        },
        {
            "Code": 8918,
            "Name": "Bryndum"
        },
        {
            "Code": 7972,
            "Name": "Store Dalby"
        },
        {
            "Code": 7113,
            "Name": "Vangede"
        },
        {
            "Code": 7177,
            "Name": "Rye"
        },
        {
            "Code": 8813,
            "Name": "Holstebro"
        },
        {
            "Code": 8704,
            "Name": "Blidstrup"
        },
        {
            "Code": 8399,
            "Name": "Aggersborg"
        },
        {
            "Code": 8893,
            "Name": "Farup"
        },
        {
            "Code": 8509,
            "Name": "Resen"
        },
        {
            "Code": 7715,
            "Name": "Rise"
        },
        {
            "Code": 8309,
            "Name": "Oue"
        },
        {
            "Code": 8580,
            "Name": "Hvidbjerg"
        },
        {
            "Code": 8510,
            "Name": "Nørbæk"
        },
        {
            "Code": 8175,
            "Name": "Fuglslev"
        },
        {
            "Code": 9019,
            "Name": "Gråsten"
        },
        {
            "Code": 9074,
            "Name": "Lindehøj"
        },
        {
            "Code": 7210,
            "Name": "Gadstrup"
        },
        {
            "Code": 8048,
            "Name": "Vær"
        },
        {
            "Code": 8692,
            "Name": "Nykøbing M"
        },
        {
            "Code": 8999,
            "Name": "Nordborg"
        },
        {
            "Code": 7571,
            "Name": "Nexø"
        },
        {
            "Code": 8236,
            "Name": "Veggerslev"
        },
        {
            "Code": 7186,
            "Name": "Smørum"
        },
        {
            "Code": 8928,
            "Name": "Føvling"
        },
        {
            "Code": 9232,
            "Name": "Gudumholm"
        },
        {
            "Code": 7677,
            "Name": "Sankt Jørgens"
        },
        {
            "Code": 8092,
            "Name": "Bjerager"
        },
        {
            "Code": 8006,
            "Name": "Tyrsting"
        },
        {
            "Code": 7247,
            "Name": "Føllenslev"
        },
        {
            "Code": 7066,
            "Name": "Lundehus"
        },
        {
            "Code": 7222,
            "Name": "Lille Fuglede"
        },
        {
            "Code": 8855,
            "Name": "Venø"
        },
        {
            "Code": 8683,
            "Name": "Hassing"
        },
        {
            "Code": 7545,
            "Name": "Himlingøje"
        },
        {
            "Code": 7238,
            "Name": "Kirke Helsinge"
        },
        {
            "Code": 8908,
            "Name": "Jerne"
        },
        {
            "Code": 7218,
            "Name": "Højelse"
        },
        {
            "Code": 8982,
            "Name": "Fjelstrup"
        },
        {
            "Code": 8416,
            "Name": "Jerslev"
        },
        {
            "Code": 8891,
            "Name": "Kalvslund"
        },
        {
            "Code": 8132,
            "Name": "Sankt Lukas"
        },
        {
            "Code": 7655,
            "Name": "Errindlev"
        },
        {
            "Code": 7233,
            "Name": "Havrebjerg"
        },
        {
            "Code": 9182,
            "Name": "Bellahøj-Utterslev"
        },
        {
            "Code": 8325,
            "Name": "Storvorde"
        },
        {
            "Code": 7449,
            "Name": "Blovstrød"
        },
        {
            "Code": 8591,
            "Name": "Lysgård"
        },
        {
            "Code": 7384,
            "Name": "Pedersborg"
        },
        {
            "Code": 7764,
            "Name": "Lyø"
        },
        {
            "Code": 9159,
            "Name": "Stenvad"
        },
        {
            "Code": 8569,
            "Name": "Vammen"
        },
        {
            "Code": 7580,
            "Name": "Væggerløse"
        },
        {
            "Code": 7820,
            "Name": "Dreslette"
        },
        {
            "Code": 7781,
            "Name": "Sankt Hans"
        },
        {
            "Code": 7289,
            "Name": "Ugerløse"
        },
        {
            "Code": 8455,
            "Name": "Sankt Hans"
        },
        {
            "Code": 8299,
            "Name": "Gerding"
        },
        {
            "Code": 7050,
            "Name": "Anna"
        },
        {
            "Code": 7107,
            "Name": "Mariendals"
        },
        {
            "Code": 9092,
            "Name": "Nørremarks"
        },
        {
            "Code": 8089,
            "Name": "Saksild"
        },
        {
            "Code": 8317,
            "Name": "Døstrup"
        },
        {
            "Code": 8563,
            "Name": "Vorde"
        },
        {
            "Code": 8406,
            "Name": "Børglum"
        },
        {
            "Code": 7412,
            "Name": "Slagslunde"
        },
        {
            "Code": 8155,
            "Name": "Spentrup"
        },
        {
            "Code": 8528,
            "Name": "Vester Tostrup"
        },
        {
            "Code": 8628,
            "Name": "Øster Velling"
        },
        {
            "Code": 7675,
            "Name": "Vor Frue"
        },
        {
            "Code": 9147,
            "Name": "Skelager"
        },
        {
            "Code": 7216,
            "Name": "Ejby"
        },
        {
            "Code": 7844,
            "Name": "Lumby"
        },
        {
            "Code": 8168,
            "Name": "Vejlby"
        },
        {
            "Code": 8431,
            "Name": "Tolne"
        },
        {
            "Code": 7044,
            "Name": "Sankt Jakobs"
        },
        {
            "Code": 7385,
            "Name": "Kindertofte"
        },
        {
            "Code": 8707,
            "Name": "Øster Assels"
        },
        {
            "Code": 8630,
            "Name": "Grensten"
        },
        {
            "Code": 9073,
            "Name": "Grundtvigs"
        },
        {
            "Code": 8130,
            "Name": "Tilst"
        },
        {
            "Code": 9008,
            "Name": "Sottrup"
        },
        {
            "Code": 7525,
            "Name": "Smerup"
        },
        {
            "Code": 7147,
            "Name": "Glostrup"
        },
        {
            "Code": 8535,
            "Name": "Finderup"
        },
        {
            "Code": 7272,
            "Name": "Årby"
        },
        {
            "Code": 8159,
            "Name": "Fjellerup"
        },
        {
            "Code": 8894,
            "Name": "Mandø"
        },
        {
            "Code": 9285,
            "Name": "Langelund"
        },
        {
            "Code": 8372,
            "Name": "Vejgård"
        },
        {
            "Code": 8549,
            "Name": "Rødding"
        },
        {
            "Code": 7577,
            "Name": "Tingsted"
        },
        {
            "Code": 9060,
            "Name": "Ravsted"
        },
        {
            "Code": 7928,
            "Name": "Kristkirkens"
        },
        {
            "Code": 8058,
            "Name": "Hornborg"
        },
        {
            "Code": 8298,
            "Name": "Torup"
        },
        {
            "Code": 9162,
            "Name": "Holsted"
        },
        {
            "Code": 7860,
            "Name": "Nørre Sandager"
        },
        {
            "Code": 8385,
            "Name": "Vester Hassing"
        },
        {
            "Code": 9058,
            "Name": "Højst"
        },
        {
            "Code": 7463,
            "Name": "Tyvelse"
        },
        {
            "Code": 9000,
            "Name": "Svenstrup"
        },
        {
            "Code": 7977,
            "Name": "Thyregod"
        },
        {
            "Code": 8375,
            "Name": "Nørresundby"
        },
        {
            "Code": 7835,
            "Name": "Ejby"
        },
        {
            "Code": 7901,
            "Name": "Langskov"
        },
        {
            "Code": 9135,
            "Name": "Skjoldhøj"
        },
        {
            "Code": 7372,
            "Name": "Farendløse"
        },
        {
            "Code": 9241,
            "Name": "Gadevang"
        },
        {
            "Code": 7269,
            "Name": "Raklev"
        },
        {
            "Code": 8064,
            "Name": "Lading"
        },
        {
            "Code": 7284,
            "Name": "Tølløse"
        },
        {
            "Code": 8963,
            "Name": "Åstrup"
        },
        {
            "Code": 8280,
            "Name": "Ravnkilde"
        },
        {
            "Code": 8117,
            "Name": "Møllevang"
        },
        {
            "Code": 8768,
            "Name": "Herborg"
        },
        {
            "Code": 8748,
            "Name": "Vind"
        },
        {
            "Code": 7630,
            "Name": "Dannemare"
        },
        {
            "Code": 7370,
            "Name": "Kværkeby"
        },
        {
            "Code": 7654,
            "Name": "Torslunde"
        },
        {
            "Code": 7939,
            "Name": "Vester Nebel"
        },
        {
            "Code": 7998,
            "Name": "Them"
        },
        {
            "Code": 7133,
            "Name": "Stengård"
        },
        {
            "Code": 9109,
            "Name": "Stensby"
        },
        {
            "Code": 8556,
            "Name": "Lem"
        },
        {
            "Code": 8001,
            "Name": "Åle"
        },
        {
            "Code": 8315,
            "Name": "Øls"
        },
        {
            "Code": 7472,
            "Name": "Everdrup"
        },
        {
            "Code": 8853,
            "Name": "Resen"
        },
        {
            "Code": 7788,
            "Name": "Sanderum"
        },
        {
            "Code": 7319,
            "Name": "Hyllested"
        },
        {
            "Code": 7586,
            "Name": "Horreby"
        },
        {
            "Code": 8868,
            "Name": "Horne"
        },
        {
            "Code": 9237,
            "Name": "Rørdal"
        },
        {
            "Code": 7209,
            "Name": "Glim"
        },
        {
            "Code": 8445,
            "Name": "Torslev"
        },
        {
            "Code": 7540,
            "Name": "Herfølge"
        },
        {
            "Code": 9068,
            "Name": "Søborgmagle"
        },
        {
            "Code": 7858,
            "Name": "Ore"
        },
        {
            "Code": 8602,
            "Name": "Frederiks"
        },
        {
            "Code": 7130,
            "Name": "Gladsaxe"
        },
        {
            "Code": 8428,
            "Name": "Mosbjerg"
        },
        {
            "Code": 9139,
            "Name": "Fredens"
        },
        {
            "Code": 8114,
            "Name": "Aarhus Domsogn"
        },
        {
            "Code": 8793,
            "Name": "Hodsager"
        },
        {
            "Code": 7215,
            "Name": "Kimmerslev"
        },
        {
            "Code": 8598,
            "Name": "Hørup"
        },
        {
            "Code": 8777,
            "Name": "Sønder Borris"
        },
        {
            "Code": 8128,
            "Name": "Brabrand"
        },
        {
            "Code": 7641,
            "Name": "Birket"
        },
        {
            "Code": 9191,
            "Name": "Vive-Hadsund"
        },
        {
            "Code": 7476,
            "Name": "Allerslev"
        },
        {
            "Code": 8671,
            "Name": "Flade"
        },
        {
            "Code": 7863,
            "Name": "Særslev"
        },
        {
            "Code": 8010,
            "Name": "Stilling"
        },
        {
            "Code": 7849,
            "Name": "Nørre Nærå"
        },
        {
            "Code": 7236,
            "Name": "Gørlev"
        },
        {
            "Code": 8196,
            "Name": "Gjerlev"
        },
        {
            "Code": 7060,
            "Name": "Grøndal"
        },
        {
            "Code": 9142,
            "Name": "Dyrup"
        },
        {
            "Code": 8948,
            "Name": "Hjerting"
        },
        {
            "Code": 8301,
            "Name": "Skørping"
        },
        {
            "Code": 7104,
            "Name": "Lindevang"
        },
        {
            "Code": 8835,
            "Name": "Handbjerg"
        },
        {
            "Code": 8440,
            "Name": "Sæby"
        },
        {
            "Code": 8648,
            "Name": "Vestervig"
        },
        {
            "Code": 8240,
            "Name": "Rimsø"
        },
        {
            "Code": 8724,
            "Name": "Sjørring"
        },
        {
            "Code": 7614,
            "Name": "Nørre Kirkeby"
        },
        {
            "Code": 7474,
            "Name": "Bårse"
        },
        {
            "Code": 8534,
            "Name": "Dollerup"
        },
        {
            "Code": 7364,
            "Name": "Benløse"
        },
        {
            "Code": 7576,
            "Name": "Eskilstrup"
        },
        {
            "Code": 7378,
            "Name": "Ørslev"
        },
        {
            "Code": 8682,
            "Name": "Erslev"
        },
        {
            "Code": 8679,
            "Name": "Galtrup"
        },
        {
            "Code": 8922,
            "Name": "Guldager"
        },
        {
            "Code": 8318,
            "Name": "Gunderup"
        },
        {
            "Code": 8932,
            "Name": "Læborg"
        },
        {
            "Code": 8469,
            "Name": "Vennebjerg"
        },
        {
            "Code": 8771,
            "Name": "Ølstrup"
        },
        {
            "Code": 8571,
            "Name": "Bigum"
        },
        {
            "Code": 8974,
            "Name": "Maugstrup"
        },
        {
            "Code": 7594,
            "Name": "Majbølle"
        },
        {
            "Code": 7565,
            "Name": "Østerlarsker"
        },
        {
            "Code": 9184,
            "Name": "Arden"
        },
        {
            "Code": 9156,
            "Name": "Agersted"
        },
        {
            "Code": 8737,
            "Name": "Øster Vandet"
        },
        {
            "Code": 9009,
            "Name": "Ullerup"
        },
        {
            "Code": 8578,
            "Name": "Otting"
        },
        {
            "Code": 9286,
            "Name": "Vonge"
        },
        {
            "Code": 9066,
            "Name": "Nyvang"
        },
        {
            "Code": 7983,
            "Name": "Give"
        },
        {
            "Code": 7143,
            "Name": "Risbjerg"
        },
        {
            "Code": 9007,
            "Name": "Nybøl"
        },
        {
            "Code": 8165,
            "Name": "Anholt"
        },
        {
            "Code": 7267,
            "Name": "Fårevejle"
        },
        {
            "Code": 7038,
            "Name": "Frederiks"
        },
        {
            "Code": 7910,
            "Name": "Vejlby"
        },
        {
            "Code": 8994,
            "Name": "Asserballe"
        },
        {
            "Code": 8887,
            "Name": "Seem"
        },
        {
            "Code": 8181,
            "Name": "Sankt Mortens"
        },
        {
            "Code": 7581,
            "Name": "Skelby"
        },
        {
            "Code": 8575,
            "Name": "Hindborg"
        },
        {
            "Code": 8965,
            "Name": "Hoptrup"
        },
        {
            "Code": 8285,
            "Name": "Lovns"
        },
        {
            "Code": 8421,
            "Name": "Vester Hjermitslev"
        },
        {
            "Code": 7945,
            "Name": "Vejstrup"
        },
        {
            "Code": 7735,
            "Name": "Ørbæk"
        },
        {
            "Code": 8343,
            "Name": "Sønder Kongerslev"
        },
        {
            "Code": 8613,
            "Name": "Viborg Domsogn"
        },
        {
            "Code": 9111,
            "Name": "Sankt Jørgens"
        },
        {
            "Code": 8929,
            "Name": "Malt"
        },
        {
            "Code": 7413,
            "Name": "Ganløse"
        },
        {
            "Code": 8609,
            "Name": "Sinding"
        },
        {
            "Code": 8275,
            "Name": "Blære"
        },
        {
            "Code": 7225,
            "Name": "Buerup"
        },
        {
            "Code": 7439,
            "Name": "Vestervang"
        },
        {
            "Code": 7579,
            "Name": "Idestrup"
        },
        {
            "Code": 8324,
            "Name": "Mou"
        },
        {
            "Code": 8449,
            "Name": "Hellevad"
        },
        {
            "Code": 8594,
            "Name": "Høbjerg"
        },
        {
            "Code": 7732,
            "Name": "Refsvindinge"
        },
        {
            "Code": 8525,
            "Name": "Hersom"
        },
        {
            "Code": 8590,
            "Name": "Vium"
        },
        {
            "Code": 9024,
            "Name": "Holbøl"
        },
        {
            "Code": 8626,
            "Name": "Vester Velling"
        },
        {
            "Code": 9006,
            "Name": "Dybbøl"
        },
        {
            "Code": 9206,
            "Name": "Ellebæk"
        },
        {
            "Code": 7485,
            "Name": "Nyord"
        },
        {
            "Code": 8448,
            "Name": "Voer"
        },
        {
            "Code": 7477,
            "Name": "Udby"
        },
        {
            "Code": 7114,
            "Name": "Dyssegård"
        },
        {
            "Code": 8755,
            "Name": "Brejning"
        },
        {
            "Code": 7163,
            "Name": "Herslev"
        },
        {
            "Code": 8821,
            "Name": "Borbjerg"
        },
        {
            "Code": 7894,
            "Name": "Vor Frelsers"
        },
        {
            "Code": 7347,
            "Name": "Sankt Peders"
        },
        {
            "Code": 7681,
            "Name": "Egense"
        },
        {
            "Code": 7593,
            "Name": "Vigsnæs"
        },
        {
            "Code": 8680,
            "Name": "Øster Jølby"
        },
        {
            "Code": 7528,
            "Name": "Karise"
        },
        {
            "Code": 7511,
            "Name": "Sankt Mortens"
        },
        {
            "Code": 9293,
            "Name": "Kerteminde-Drigstrup"
        },
        {
            "Code": 8435,
            "Name": "Flade"
        },
        {
            "Code": 8129,
            "Name": "Sønder Årslev"
        },
        {
            "Code": 7320,
            "Name": "Venslev"
        },
        {
            "Code": 7706,
            "Name": "Longelse"
        },
        {
            "Code": 7888,
            "Name": "Norup"
        },
        {
            "Code": 8832,
            "Name": "Sahl"
        },
        {
            "Code": 8579,
            "Name": "Brøndum"
        },
        {
            "Code": 9208,
            "Name": "Hjerm Østre"
        },
        {
            "Code": 8730,
            "Name": "Vang"
        },
        {
            "Code": 7421,
            "Name": "Slangerup"
        },
        {
            "Code": 7135,
            "Name": "Herlev"
        },
        {
            "Code": 9284,
            "Name": "Vorslunde"
        },
        {
            "Code": 8177,
            "Name": "Nødager"
        },
        {
            "Code": 8749,
            "Name": "Ringkøbing"
        },
        {
            "Code": 7314,
            "Name": "Sørbymagle"
        },
        {
            "Code": 8972,
            "Name": "Vonsbæk"
        },
        {
            "Code": 9035,
            "Name": "Spandet"
        },
        {
            "Code": 9169,
            "Name": "Simeon-Sankt Johannes"
        },
        {
            "Code": 8725,
            "Name": "Thorsted"
        },
        {
            "Code": 8074,
            "Name": "Elev"
        },
        {
            "Code": 7694,
            "Name": "Strynø"
        },
        {
            "Code": 9129,
            "Name": "Vorupør"
        },
        {
            "Code": 7367,
            "Name": "Jystrup"
        },
        {
            "Code": 7964,
            "Name": "Hatting"
        },
        {
            "Code": 9108,
            "Name": "Mosede"
        },
        {
            "Code": 8390,
            "Name": "Volsted"
        },
        {
            "Code": 8439,
            "Name": "Vesterø"
        },
        {
            "Code": 7056,
            "Name": "Sions"
        },
        {
            "Code": 7481,
            "Name": "Kalvehave"
        },
        {
            "Code": 8323,
            "Name": "Lillevorde"
        },
        {
            "Code": 7612,
            "Name": "Nørre Vedby"
        },
        {
            "Code": 7419,
            "Name": "Oppe Sundby"
        },
        {
            "Code": 7974,
            "Name": "Brande"
        },
        {
            "Code": 7353,
            "Name": "Sorterup"
        },
        {
            "Code": 8708,
            "Name": "Vester Assels"
        },
        {
            "Code": 8403,
            "Name": "Serritslev"
        },
        {
            "Code": 8547,
            "Name": "Sæby"
        },
        {
            "Code": 8775,
            "Name": "Bølling"
        },
        {
            "Code": 9209,
            "Name": "Fasterholt"
        },
        {
            "Code": 7132,
            "Name": "Bagsværd"
        },
        {
            "Code": 7473,
            "Name": "Snesere"
        },
        {
            "Code": 7618,
            "Name": "Brarup"
        },
        {
            "Code": 8629,
            "Name": "Helstrup"
        },
        {
            "Code": 7331,
            "Name": "Fuglebjerg"
        },
        {
            "Code": 7738,
            "Name": "Hellerup"
        },
        {
            "Code": 9043,
            "Name": "Daler"
        },
        {
            "Code": 8938,
            "Name": "Gesten"
        },
        {
            "Code": 8504,
            "Name": "Mønsted"
        },
        {
            "Code": 7613,
            "Name": "Nørre Alslev"
        },
        {
            "Code": 7342,
            "Name": "Stenmagle"
        },
        {
            "Code": 9279,
            "Name": "Lyngvig"
        },
        {
            "Code": 8395,
            "Name": "Torslev"
        },
        {
            "Code": 8735,
            "Name": "Tved"
        },
        {
            "Code": 8098,
            "Name": "Gylling"
        },
        {
            "Code": 7251,
            "Name": "Viskinge"
        },
        {
            "Code": 9038,
            "Name": "Skærbæk"
        },
        {
            "Code": 8907,
            "Name": "Treenigheds"
        },
        {
            "Code": 8016,
            "Name": "Mesing"
        },
        {
            "Code": 8007,
            "Name": "Vrads"
        },
        {
            "Code": 7102,
            "Name": "Sankt Lukas"
        },
        {
            "Code": 7363,
            "Name": "Ringsted"
        },
        {
            "Code": 7670,
            "Name": "Sankt Nikolaj"
        },
        {
            "Code": 7798,
            "Name": "Munkebo"
        },
        {
            "Code": 8485,
            "Name": "Gullev"
        },
        {
            "Code": 8923,
            "Name": "Nordby"
        },
        {
            "Code": 8960,
            "Name": "Toftlund"
        },
        {
            "Code": 8647,
            "Name": "Hjardemål"
        },
        {
            "Code": 7772,
            "Name": "Hesselager"
        },
        {
            "Code": 9053,
            "Name": "Hjerpsted"
        },
        {
            "Code": 7704,
            "Name": "Stoense"
        },
        {
            "Code": 8688,
            "Name": "Grurup"
        },
        {
            "Code": 9028,
            "Name": "Kværs"
        },
        {
            "Code": 7861,
            "Name": "Ejlby"
        },
        {
            "Code": 7266,
            "Name": "Asnæs"
        },
        {
            "Code": 8231,
            "Name": "Grenaa"
        },
        {
            "Code": 7321,
            "Name": "Holsteinborg"
        },
        {
            "Code": 8769,
            "Name": "Videbæk"
        },
        {
            "Code": 8346,
            "Name": "Bælum"
        },
        {
            "Code": 9163,
            "Name": "Lyng"
        },
        {
            "Code": 8841,
            "Name": "Nørlem"
        },
        {
            "Code": 8365,
            "Name": "Ansgars"
        },
        {
            "Code": 7122,
            "Name": "Sorgenfri"
        },
        {
            "Code": 8993,
            "Name": "Notmark"
        },
        {
            "Code": 7968,
            "Name": "Uth"
        },
        {
            "Code": 7923,
            "Name": "Højen"
        },
        {
            "Code": 7452,
            "Name": "Karlebo"
        },
        {
            "Code": 8344,
            "Name": "Nørre Kongerslev"
        },
        {
            "Code": 8912,
            "Name": "Grimstrup"
        },
        {
            "Code": 7332,
            "Name": "Førslev"
        },
        {
            "Code": 7543,
            "Name": "Tårnby"
        },
        {
            "Code": 7969,
            "Name": "Løsning"
        },
        {
            "Code": 9213,
            "Name": "Grathe"
        },
        {
            "Code": 8208,
            "Name": "Borup"
        },
        {
            "Code": 7494,
            "Name": "Havnelev"
        },
        {
            "Code": 8371,
            "Name": "Nørre Tranders"
        },
        {
            "Code": 8257,
            "Name": "Rolsø"
        },
        {
            "Code": 8363,
            "Name": "Lundby"
        },
        {
            "Code": 8287,
            "Name": "Gislum"
        },
        {
            "Code": 7698,
            "Name": "Åstrup"
        },
        {
            "Code": 8955,
            "Name": "Skrydstrup"
        },
        {
            "Code": 7656,
            "Name": "Olstrup"
        },
        {
            "Code": 8220,
            "Name": "Koed"
        },
        {
            "Code": 7490,
            "Name": "Damsholte"
        },
        {
            "Code": 8442,
            "Name": "Karup"
        },
        {
            "Code": 7334,
            "Name": "Marvede"
        },
        {
            "Code": 8052,
            "Name": "Klostersogn"
        },
        {
            "Code": 8037,
            "Name": "Østbirk"
        },
        {
            "Code": 9113,
            "Name": "Islebjerg"
        },
        {
            "Code": 7341,
            "Name": "Kirke Flinterup"
        },
        {
            "Code": 7388,
            "Name": "Alsted"
        },
        {
            "Code": 8655,
            "Name": "Hvidbjerg"
        },
        {
            "Code": 8018,
            "Name": "Mariehøj"
        },
        {
            "Code": 7881,
            "Name": "Davinde"
        },
        {
            "Code": 8417,
            "Name": "Hellum"
        },
        {
            "Code": 7504,
            "Name": "Vejlø"
        },
        {
            "Code": 8926,
            "Name": "Brørup"
        },
        {
            "Code": 7418,
            "Name": "Frederikssund"
        },
        {
            "Code": 8193,
            "Name": "Råby"
        },
        {
            "Code": 7305,
            "Name": "Fodby"
        },
        {
            "Code": 9040,
            "Name": "Ubjerg"
        },
        {
            "Code": 7307,
            "Name": "Gunderslev"
        },
        {
            "Code": 7492,
            "Name": "Lellinge"
        },
        {
            "Code": 8296,
            "Name": "Store Brøndum"
        },
        {
            "Code": 8973,
            "Name": "Hammelev"
        },
        {
            "Code": 9001,
            "Name": "Havnbjerg"
        },
        {
            "Code": 7237,
            "Name": "Bakkendrup"
        },
        {
            "Code": 8934,
            "Name": "Bække"
        },
        {
            "Code": 8184,
            "Name": "Dronningborg"
        },
        {
            "Code": 7838,
            "Name": "Gamborg"
        },
        {
            "Code": 7538,
            "Name": "Vollerslev"
        },
        {
            "Code": 8093,
            "Name": "Hundslund"
        },
        {
            "Code": 7417,
            "Name": "Præstevang"
        },
        {
            "Code": 8806,
            "Name": "Studsgård"
        },
        {
            "Code": 8791,
            "Name": "Fredens"
        },
        {
            "Code": 7666,
            "Name": "Bandholm"
        },
        {
            "Code": 7615,
            "Name": "Vålse"
        },
        {
            "Code": 8595,
            "Name": "Elsborg"
        },
        {
            "Code": 9170,
            "Name": "Ørre-Sinding"
        },
        {
            "Code": 7595,
            "Name": "Radsted"
        },
        {
            "Code": 7128,
            "Name": "Gammel Holte"
        },
        {
            "Code": 9047,
            "Name": "Brede"
        },
        {
            "Code": 8347,
            "Name": "Solbjerg"
        },
        {
            "Code": 8744,
            "Name": "Råsted"
        },
        {
            "Code": 8305,
            "Name": "Visborg"
        },
        {
            "Code": 9088,
            "Name": "Ellevang"
        },
        {
            "Code": 8601,
            "Name": "Thorning"
        },
        {
            "Code": 8931,
            "Name": "Vejen"
        },
        {
            "Code": 7081,
            "Name": "Christians"
        },
        {
            "Code": 9045,
            "Name": "Løgumkloster"
        },
        {
            "Code": 9270,
            "Name": "Skjoldbjerg"
        },
        {
            "Code": 7059,
            "Name": "Advents"
        },
        {
            "Code": 9123,
            "Name": "Isenvad"
        },
        {
            "Code": 9126,
            "Name": "Søndermark"
        },
        {
            "Code": 7900,
            "Name": "Uldum"
        },
        {
            "Code": 7761,
            "Name": "Håstrup"
        },
        {
            "Code": 7563,
            "Name": "Ibsker"
        },
        {
            "Code": 9188,
            "Name": "Hvejsel"
        },
        {
            "Code": 8151,
            "Name": "Asferg"
        },
        {
            "Code": 7725,
            "Name": "Aunslev"
        },
        {
            "Code": 7070,
            "Name": "Husumvold"
        },
        {
            "Code": 8172,
            "Name": "Hyllested"
        },
        {
            "Code": 8828,
            "Name": "Vemb"
        },
        {
            "Code": 7084,
            "Name": "Sundby"
        },
        {
            "Code": 8401,
            "Name": "Vedsted"
        },
        {
            "Code": 7743,
            "Name": "Skrøbelev"
        },
        {
            "Code": 8834,
            "Name": "Ryde"
        },
        {
            "Code": 8314,
            "Name": "Vebbestrup"
        },
        {
            "Code": 7779,
            "Name": "Vor Frue"
        },
        {
            "Code": 7261,
            "Name": "Højby"
        },
        {
            "Code": 8889,
            "Name": "Obbekær"
        },
        {
            "Code": 8663,
            "Name": "Hørsted"
        },
        {
            "Code": 7827,
            "Name": "Asperup"
        },
        {
            "Code": 9207,
            "Name": "Hogager"
        },
        {
            "Code": 8386,
            "Name": "Ulsted"
        },
        {
            "Code": 7037,
            "Name": "Garnisons"
        },
        {
            "Code": 8936,
            "Name": "Jordrup"
        },
        {
            "Code": 8326,
            "Name": "Sejlflod"
        },
        {
            "Code": 9093,
            "Name": "Vollsmose"
        },
        {
            "Code": 8870,
            "Name": "Tistrup"
        },
        {
            "Code": 8941,
            "Name": "Hjarup"
        },
        {
            "Code": 7741,
            "Name": "Rudkøbing"
        },
        {
            "Code": 8094,
            "Name": "Ørting"
        },
        {
            "Code": 7643,
            "Name": "Nysted"
        },
        {
            "Code": 7362,
            "Name": "Boeslunde"
        },
        {
            "Code": 8867,
            "Name": "Thorstrup"
        },
        {
            "Code": 8279,
            "Name": "Brorstrup"
        },
        {
            "Code": 7503,
            "Name": "Mogenstrup"
        },
        {
            "Code": 8047,
            "Name": "Hansted"
        },
        {
            "Code": 8139,
            "Name": "Laurbjerg"
        },
        {
            "Code": 7085,
            "Name": "Allehelgens"
        },
        {
            "Code": 7806,
            "Name": "Turup"
        },
        {
            "Code": 9235,
            "Name": "Stenbjerg"
        },
        {
            "Code": 7389,
            "Name": "Fjenneslev"
        },
        {
            "Code": 9116,
            "Name": "Simon Peters"
        },
        {
            "Code": 8817,
            "Name": "Gimsing"
        },
        {
            "Code": 7986,
            "Name": "Nørup"
        },
        {
            "Code": 8621,
            "Name": "Mammen"
        },
        {
            "Code": 8103,
            "Name": "Tranbjerg"
        },
        {
            "Code": 8217,
            "Name": "Fausing"
        },
        {
            "Code": 7929,
            "Name": "Brændkær"
        },
        {
            "Code": 8065,
            "Name": "Fårup"
        },
        {
            "Code": 9072,
            "Name": "Buddinge"
        },
        {
            "Code": 8490,
            "Name": "Aidt"
        },
        {
            "Code": 8945,
            "Name": "Hejnsvig"
        },
        {
            "Code": 7483,
            "Name": "Stege"
        },
        {
            "Code": 7842,
            "Name": "Husby"
        },
        {
            "Code": 8844,
            "Name": "Heldum"
        },
        {
            "Code": 9141,
            "Name": "Gullestrup"
        },
        {
            "Code": 9197,
            "Name": "Øster Hassing-Gåser Sogn"
        },
        {
            "Code": 8432,
            "Name": "Hørmested"
        },
        {
            "Code": 8373,
            "Name": "Hans Egedes"
        },
        {
            "Code": 9136,
            "Name": "Vodskov"
        },
        {
            "Code": 7999,
            "Name": "Nørre Snede"
        },
        {
            "Code": 7197,
            "Name": "Vindinge"
        },
        {
            "Code": 7322,
            "Name": "Ørslev"
        },
        {
            "Code": 8088,
            "Name": "Torrild"
        },
        {
            "Code": 7533,
            "Name": "Øster Egede"
        },
        {
            "Code": 8722,
            "Name": "Tilsted"
        },
        {
            "Code": 8695,
            "Name": "Frøslev"
        },
        {
            "Code": 7043,
            "Name": "Rosenvængets"
        },
        {
            "Code": 8099,
            "Name": "Alrø"
        },
        {
            "Code": 9216,
            "Name": "Øster Hurup"
        },
        {
            "Code": 8772,
            "Name": "Dejbjerg"
        },
        {
            "Code": 8899,
            "Name": "Darum"
        },
        {
            "Code": 8845,
            "Name": "Lomborg"
        },
        {
            "Code": 8266,
            "Name": "Vejlby"
        },
        {
            "Code": 9242,
            "Name": "Skoven"
        },
        {
            "Code": 9154,
            "Name": "Grønnevang"
        },
        {
            "Code": 7400,
            "Name": "Ølsted"
        },
        {
            "Code": 7753,
            "Name": "Vejle"
        },
        {
            "Code": 8656,
            "Name": "Lyngs"
        },
        {
            "Code": 9055,
            "Name": "Sønder Skast"
        },
        {
            "Code": 8600,
            "Name": "Vinderslev"
        },
        {
            "Code": 8271,
            "Name": "Vester Hornum"
        },
        {
            "Code": 7642,
            "Name": "Sakskøbing"
        },
        {
            "Code": 7053,
            "Name": "Kingo-Samuel"
        },
        {
            "Code": 8502,
            "Name": "Gammelstrup"
        },
        {
            "Code": 7833,
            "Name": "Rørup"
        },
        {
            "Code": 7232,
            "Name": "Solbjerg"
        },
        {
            "Code": 7527,
            "Name": "Vemmetofte"
        },
        {
            "Code": 7711,
            "Name": "Fodslette"
        },
        {
            "Code": 7620,
            "Name": "Vindeby"
        },
        {
            "Code": 7646,
            "Name": "Hunseby"
        },
        {
            "Code": 8267,
            "Name": "Gjesing"
        },
        {
            "Code": 8451,
            "Name": "Dronninglund"
        },
        {
            "Code": 8520,
            "Name": "Fjelsø"
        },
        {
            "Code": 8350,
            "Name": "Ranum"
        },
        {
            "Code": 8906,
            "Name": "Zions"
        },
        {
            "Code": 8015,
            "Name": "Veng"
        },
        {
            "Code": 7436,
            "Name": "Frederiksborg Slotssogn"
        },
        {
            "Code": 9119,
            "Name": "Kølkær"
        },
        {
            "Code": 7912,
            "Name": "Christians"
        },
        {
            "Code": 8000,
            "Name": "Ejstrup"
        },
        {
            "Code": 7382,
            "Name": "Bråby"
        },
        {
            "Code": 8812,
            "Name": "Bording"
        },
        {
            "Code": 7401,
            "Name": "Tjæreby"
        },
        {
            "Code": 7220,
            "Name": "Svallerup"
        },
        {
            "Code": 7117,
            "Name": "Hellerup"
        },
        {
            "Code": 7991,
            "Name": "Grædstrup"
        },
        {
            "Code": 8957,
            "Name": "Bevtoft"
        },
        {
            "Code": 8113,
            "Name": "Hvilsted"
        },
        {
            "Code": 7458,
            "Name": "Skuldelev"
        },
        {
            "Code": 7982,
            "Name": "Ringive"
        },
        {
            "Code": 7083,
            "Name": "Nathanaels"
        },
        {
            "Code": 7635,
            "Name": "Ryde"
        },
        {
            "Code": 7768,
            "Name": "Gislev"
        },
        {
            "Code": 7607,
            "Name": "Stubbekøbing"
        },
        {
            "Code": 7178,
            "Name": "Kirke Sonnerup"
        },
        {
            "Code": 8554,
            "Name": "Håsum"
        },
        {
            "Code": 9089,
            "Name": "Pederstrup"
        },
        {
            "Code": 9245,
            "Name": "Alleshave"
        },
        {
            "Code": 7958,
            "Name": "As"
        },
        {
            "Code": 9084,
            "Name": "Brøndby Strand"
        },
        {
            "Code": 8452,
            "Name": "Asaa"
        },
        {
            "Code": 7623,
            "Name": "Nordlunde"
        },
        {
            "Code": 8838,
            "Name": "Grove"
        },
        {
            "Code": 8727,
            "Name": "Kallerup"
        },
        {
            "Code": 7700,
            "Name": "Ulbølle"
        },
        {
            "Code": 7402,
            "Name": "Alsønderup"
        },
        {
            "Code": 7377,
            "Name": "Terslev"
        },
        {
            "Code": 7848,
            "Name": "Krogsbølle"
        },
        {
            "Code": 9177,
            "Name": "Butterup-Tuse"
        },
        {
            "Code": 8243,
            "Name": "Bregnet"
        },
        {
            "Code": 8745,
            "Name": "Nørre Felding"
        },
        {
            "Code": 7608,
            "Name": "Maglebrænde"
        },
        {
            "Code": 7195,
            "Name": "Hundige-Kildebrønde"
        },
        {
            "Code": 7975,
            "Name": "Givskud"
        },
        {
            "Code": 8277,
            "Name": "Skivum"
        },
        {
            "Code": 7306,
            "Name": "Herlufsholm"
        },
        {
            "Code": 8295,
            "Name": "Lyngby"
        },
        {
            "Code": 7109,
            "Name": "Sankt Markus"
        },
        {
            "Code": 8514,
            "Name": "Ålum"
        },
        {
            "Code": 8599,
            "Name": "Hinge"
        },
        {
            "Code": 7664,
            "Name": "Vejleby"
        },
        {
            "Code": 9253,
            "Name": "Ravnebjerg"
        },
        {
            "Code": 8027,
            "Name": "Gjern"
        },
        {
            "Code": 9104,
            "Name": "Ry"
        },
        {
            "Code": 7394,
            "Name": "Tibirke"
        },
        {
            "Code": 7161,
            "Name": "Hvedstrup"
        },
        {
            "Code": 7757,
            "Name": "Øster Hæsinge"
        },
        {
            "Code": 7884,
            "Name": "Sønder Nærå"
        },
        {
            "Code": 7787,
            "Name": "Dalum"
        },
        {
            "Code": 9011,
            "Name": "Hjordkær"
        },
        {
            "Code": 8687,
            "Name": "Bedsted"
        },
        {
            "Code": 8734,
            "Name": "Nors"
        },
        {
            "Code": 8443,
            "Name": "Volstrup"
        },
        {
            "Code": 7948,
            "Name": "Nebsager"
        },
        {
            "Code": 8823,
            "Name": "Asp"
        },
        {
            "Code": 7506,
            "Name": "Hammer"
        },
        {
            "Code": 7650,
            "Name": "Skørringe"
        },
        {
            "Code": 8244,
            "Name": "Mørke"
        },
        {
            "Code": 8379,
            "Name": "Aaby"
        },
        {
            "Code": 7196,
            "Name": "Reerslev"
        },
        {
            "Code": 7265,
            "Name": "Grevinge"
        },
        {
            "Code": 7584,
            "Name": "Sønder Alslev"
        },
        {
            "Code": 8311,
            "Name": "Astrup"
        },
        {
            "Code": 8239,
            "Name": "Hemmed"
        },
        {
            "Code": 8952,
            "Name": "Fole"
        },
        {
            "Code": 9215,
            "Name": "Hjermind"
        },
        {
            "Code": 7120,
            "Name": "Christians"
        },
        {
            "Code": 8970,
            "Name": "Grarup"
        },
        {
            "Code": 8253,
            "Name": "Tved"
        },
        {
            "Code": 8456,
            "Name": "Sankt Olai"
        },
        {
            "Code": 7516,
            "Name": "Fensmark"
        },
        {
            "Code": 8278,
            "Name": "Giver"
        },
        {
            "Code": 9255,
            "Name": "Røjleskov"
        },
        {
            "Code": 7690,
            "Name": "Bregninge"
        },
        {
            "Code": 8019,
            "Name": "Alderslyst"
        },
        {
            "Code": 7415,
            "Name": "Hillerød"
        },
        {
            "Code": 8495,
            "Name": "Sall"
        },
        {
            "Code": 8511,
            "Name": "Sønderbæk"
        },
        {
            "Code": 8820,
            "Name": "Hjerm"
        },
        {
            "Code": 7938,
            "Name": "Øster Starup"
        },
        {
            "Code": 8494,
            "Name": "Haurum"
        },
        {
            "Code": 8919,
            "Name": "Vester Nebel"
        },
        {
            "Code": 7569,
            "Name": "Aaker"
        },
        {
            "Code": 7686,
            "Name": "Stenstrup"
        },
        {
            "Code": 8643,
            "Name": "Kåstrup"
        },
        {
            "Code": 8983,
            "Name": "Stepping"
        },
        {
            "Code": 8144,
            "Name": "Rud"
        },
        {
            "Code": 9204,
            "Name": "Herrup"
        },
        {
            "Code": 8107,
            "Name": "Ormslev"
        },
        {
            "Code": 8882,
            "Name": "Lønne"
        },
        {
            "Code": 8050,
            "Name": "Gangsted"
        },
        {
            "Code": 9173,
            "Name": "Falslev-Vindblæs"
        },
        {
            "Code": 9096,
            "Name": "Ravnsbjerg"
        },
        {
            "Code": 7217,
            "Name": "Nørre Dalby"
        },
        {
            "Code": 7189,
            "Name": "Skovlunde"
        },
        {
            "Code": 9289,
            "Name": "Hampen"
        },
        {
            "Code": 8303,
            "Name": "Als"
        },
        {
            "Code": 8646,
            "Name": "Østerild"
        },
        {
            "Code": 8847,
            "Name": "Bøvling"
        },
        {
            "Code": 8917,
            "Name": "Tjæreborg"
        },
        {
            "Code": 7453,
            "Name": "Gerlev"
        },
        {
            "Code": 7203,
            "Name": "Solrød"
        },
        {
            "Code": 8370,
            "Name": "Sønder Tranders"
        },
        {
            "Code": 7248,
            "Name": "Særslev"
        },
        {
            "Code": 9023,
            "Name": "Felsted"
        },
        {
            "Code": 8378,
            "Name": "Vadum"
        },
        {
            "Code": 8366,
            "Name": "Vor Frelsers"
        },
        {
            "Code": 9214,
            "Name": "Bjerring"
        },
        {
            "Code": 8246,
            "Name": "Skødstrup"
        },
        {
            "Code": 7661,
            "Name": "Nebbelunde"
        },
        {
            "Code": 7329,
            "Name": "Haldagerlille"
        },
        {
            "Code": 8512,
            "Name": "Læsten"
        },
        {
            "Code": 7407,
            "Name": "Melby"
        },
        {
            "Code": 7530,
            "Name": "Sønder Dalby"
        },
        {
            "Code": 7288,
            "Name": "Store Tåstrup"
        },
        {
            "Code": 7920,
            "Name": "Smidstrup"
        },
        {
            "Code": 9178,
            "Name": "Tønder"
        },
        {
            "Code": 7340,
            "Name": "Gyrstinge"
        },
        {
            "Code": 7587,
            "Name": "Nørre Ørslev"
        },
        {
            "Code": 8262,
            "Name": "Voer"
        },
        {
            "Code": 9048,
            "Name": "Døstrup"
        },
        {
            "Code": 7845,
            "Name": "Allese"
        },
        {
            "Code": 8073,
            "Name": "Elsted"
        },
        {
            "Code": 9065,
            "Name": "Stenild"
        },
        {
            "Code": 8565,
            "Name": "Romlund"
        },
        {
            "Code": 7170,
            "Name": "Gundsømagle"
        },
        {
            "Code": 9260,
            "Name": "Genner"
        },
        {
            "Code": 8939,
            "Name": "Skanderup"
        },
        {
            "Code": 8966,
            "Name": "Moltrup"
        },
        {
            "Code": 7739,
            "Name": "Sønder Højrup"
        },
        {
            "Code": 8173,
            "Name": "Rosmus"
        },
        {
            "Code": 7312,
            "Name": "Skørpinge"
        },
        {
            "Code": 8989,
            "Name": "Christians"
        },
        {
            "Code": 8150,
            "Name": "Svenstrup"
        },
        {
            "Code": 8670,
            "Name": "Jørsby"
        },
        {
            "Code": 8482,
            "Name": "Tversted"
        },
        {
            "Code": 9257,
            "Name": "Hjulby"
        },
        {
            "Code": 8188,
            "Name": "Linde"
        },
        {
            "Code": 7411,
            "Name": "Veksø"
        },
        {
            "Code": 9105,
            "Name": "Karlslunde Strandsogn"
        },
        {
            "Code": 9291,
            "Name": "Ål"
        },
        {
            "Code": 8462,
            "Name": "Mygdal"
        },
        {
            "Code": 7610,
            "Name": "Lillebrænde"
        },
        {
            "Code": 9050,
            "Name": "Randerup"
        },
        {
            "Code": 8900,
            "Name": "Bramming"
        },
        {
            "Code": 7479,
            "Name": "Øster Egesborg"
        },
        {
            "Code": 7369,
            "Name": "Vigersted"
        },
        {
            "Code": 9005,
            "Name": "Egernsund"
        },
        {
            "Code": 8414,
            "Name": "Vrå"
        },
        {
            "Code": 8393,
            "Name": "Brovst"
        },
        {
            "Code": 7825,
            "Name": "Vejlby"
        },
        {
            "Code": 8713,
            "Name": "Vust"
        },
        {
            "Code": 8108,
            "Name": "Kolt"
        },
        {
            "Code": 7126,
            "Name": "Vedbæk"
        },
        {
            "Code": 7876,
            "Name": "Stubberup"
        },
        {
            "Code": 8465,
            "Name": "Hirtshals"
        },
        {
            "Code": 7399,
            "Name": "Kregme"
        },
        {
            "Code": 9243,
            "Name": "Villingerød"
        },
        {
            "Code": 7395,
            "Name": "Ramløse"
        },
        {
            "Code": 9018,
            "Name": "Egvad"
        },
        {
            "Code": 7759,
            "Name": "Sandholts Lyndelse"
        },
        {
            "Code": 7649,
            "Name": "Hillested"
        },
        {
            "Code": 7255,
            "Name": "Vallekilde"
        },
        {
            "Code": 9234,
            "Name": "Koldmose"
        },
        {
            "Code": 7172,
            "Name": "Allerslev"
        },
        {
            "Code": 8097,
            "Name": "Halling"
        },
        {
            "Code": 9259,
            "Name": "Adsbøl"
        },
        {
            "Code": 7967,
            "Name": "Tyrsted"
        },
        {
            "Code": 8269,
            "Name": "Aars"
        },
        {
            "Code": 7770,
            "Name": "Langå"
        },
        {
            "Code": 7324,
            "Name": "Tjæreby"
        },
        {
            "Code": 8808,
            "Name": "Sønder Felding"
        },
        {
            "Code": 7970,
            "Name": "Korning"
        },
        {
            "Code": 8248,
            "Name": "Mejlby"
        },
        {
            "Code": 7917,
            "Name": "Vinding"
        },
        {
            "Code": 9145,
            "Name": "Kokkedal"
        },
        {
            "Code": 7408,
            "Name": "Torup"
        },
        {
            "Code": 7602,
            "Name": "Øster Ulslev"
        },
        {
            "Code": 8766,
            "Name": "Vorgod"
        },
        {
            "Code": 8858,
            "Name": "Thyborøn"
        },
        {
            "Code": 7931,
            "Name": "Sønder Vilstrup"
        },
        {
            "Code": 8991,
            "Name": "Ketting"
        },
        {
            "Code": 7343,
            "Name": "Bringstrup"
        },
        {
            "Code": 8809,
            "Name": "Assing"
        },
        {
            "Code": 9210,
            "Name": "Karstoft"
        },
        {
            "Code": 8700,
            "Name": "Karby"
        },
        {
            "Code": 9144,
            "Name": "Nørrevang"
        },
        {
            "Code": 7868,
            "Name": "Agedrup"
        },
        {
            "Code": 7366,
            "Name": "Allindemagle"
        },
        {
            "Code": 8839,
            "Name": "Simmelkær"
        },
        {
            "Code": 8935,
            "Name": "Lejrskov"
        },
        {
            "Code": 8756,
            "Name": "Torsted"
        },
        {
            "Code": 8545,
            "Name": "Vile"
        },
        {
            "Code": 8949,
            "Name": "Rødding"
        },
        {
            "Code": 7300,
            "Name": "Holmstrup"
        },
        {
            "Code": 8450,
            "Name": "Ørum"
        },
        {
            "Code": 9078,
            "Name": "Sankt Andreas"
        },
        {
            "Code": 7513,
            "Name": "Aversi"
        },
        {
            "Code": 7309,
            "Name": "Eggeslevmagle"
        },
        {
            "Code": 7648,
            "Name": "Maribo Domsogn"
        },
        {
            "Code": 8612,
            "Name": "Engesvang"
        },
        {
            "Code": 7933,
            "Name": "Harte"
        },
        {
            "Code": 8849,
            "Name": "Møborg"
        },
        {
            "Code": 9280,
            "Name": "Lem"
        },
        {
            "Code": 7536,
            "Name": "Lidemark"
        },
        {
            "Code": 7424,
            "Name": "Sigerslevvester"
        },
        {
            "Code": 8523,
            "Name": "Hvam"
        },
        {
            "Code": 7878,
            "Name": "Åsum"
        },
        {
            "Code": 7188,
            "Name": "Måløv"
        },
        {
            "Code": 8042,
            "Name": "Kattrup"
        },
        {
            "Code": 7061,
            "Name": "Ansgarkirkens"
        },
        {
            "Code": 8126,
            "Name": "Skejby"
        },
        {
            "Code": 7299,
            "Name": "Jyderup"
        },
        {
            "Code": 7317,
            "Name": "Hårslev"
        },
        {
            "Code": 8669,
            "Name": "Ejerslev"
        },
        {
            "Code": 8149,
            "Name": "Vester Tørslev"
        },
        {
            "Code": 7541,
            "Name": "Sædder"
        },
        {
            "Code": 7361,
            "Name": "Hemmeshøj"
        },
        {
            "Code": 8409,
            "Name": "Sejlstrup"
        },
        {
            "Code": 8212,
            "Name": "Albæk"
        },
        {
            "Code": 9151,
            "Name": "Enghøj"
        },
        {
            "Code": 8380,
            "Name": "Biersted"
        },
        {
            "Code": 8568,
            "Name": "Pederstrup"
        },
        {
            "Code": 8506,
            "Name": "Vridsted"
        },
        {
            "Code": 8546,
            "Name": "Glyngøre"
        },
        {
            "Code": 7158,
            "Name": "Vor Frue"
        },
        {
            "Code": 8783,
            "Name": "Lønborg"
        },
        {
            "Code": 7604,
            "Name": "Godsted"
        },
        {
            "Code": 8241,
            "Name": "Kastbjerg"
        },
        {
            "Code": 8800,
            "Name": "Sunds"
        },
        {
            "Code": 8641,
            "Name": "Hammershøj"
        },
        {
            "Code": 7744,
            "Name": "Tranekær"
        },
        {
            "Code": 8281,
            "Name": "Strandby"
        },
        {
            "Code": 8340,
            "Name": "Suldrup"
        },
        {
            "Code": 8888,
            "Name": "Sankt Katharine"
        },
        {
            "Code": 7164,
            "Name": "Gevninge"
        },
        {
            "Code": 9083,
            "Name": "Hedehusene"
        },
        {
            "Code": 7606,
            "Name": "Bregninge"
        },
        {
            "Code": 8022,
            "Name": "Hammel"
        },
        {
            "Code": 7557,
            "Name": "Hasle"
        },
        {
            "Code": 7151,
            "Name": "Ishøj"
        },
        {
            "Code": 7057,
            "Name": "Hyltebjerg"
        },
        {
            "Code": 7554,
            "Name": "Vestermarie"
        },
        {
            "Code": 7987,
            "Name": "Randbøl"
        },
        {
            "Code": 8701,
            "Name": "Redsted"
        },
        {
            "Code": 7330,
            "Name": "Krummerup"
        },
        {
            "Code": 9165,
            "Name": "Sydhavn"
        },
        {
            "Code": 8524,
            "Name": "Hvilsom"
        },
        {
            "Code": 7976,
            "Name": "Øster Nykirke"
        },
        {
            "Code": 8180,
            "Name": "Skarresø"
        },
        {
            "Code": 7890,
            "Name": "Skeby"
        },
        {
            "Code": 7310,
            "Name": "Høve"
        },
        {
            "Code": 8115,
            "Name": "Vor Frue"
        },
        {
            "Code": 8388,
            "Name": "Ferslev"
        }
    ];
    // Cache for reference informartion
    private static refGeom: any = null;
    private static isLoading: boolean = false;

    private static dataUrl = "https://s3.eu-central-1.amazonaws.com/mapit2-data/GeoJSONReferencedFromMapit/ReferenceGeomParish.json";

    // -------------------------------------------------------------
    static dumpParishLookupList(): any[] {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.map((feature) => {
            return (
                { 
                    "Code": this.selectCodeElement(feature), 
                    "Name": this.selectNameElement(feature) 
                }
            );
        });
        return (result);
    }

    static selectCodeElement(feature:ReferenceGeomFeatureParish):number {
        return feature && feature.properties && feature.properties.SOGNEID;
    } 

    static selectNameElement(feature:ReferenceGeomFeatureParish):string {
        return feature && feature.properties && feature.properties.SOGNENAVN;
    }

    static lookupByParishCode(code: number) {
        var dataSet = this.parishLookupList;
        var result = dataSet && dataSet.find((par) => {
            return this.equalityFuncParishCode(par.Code, code);
        });
        return (result);
    }

    static equalityFuncParishCode(prop: number, code: number) {
        return Boolean(prop && code && (prop.toString() === code.toString()));
    }

    static lookupByParishName(name: string) {
        var dataSet = this.parishLookupList;
        var result = dataSet && dataSet.find((par) => {
            return this.equalityFuncParishName(par.Name, name);
        });
        return (result);
    }

    static equalityFuncParishName(prop: string, name: string): boolean {
        let propConverted = ReferenceGeomParish.removeSognPart(ReferenceGeomParish.convertToAA(prop.toString().toLowerCase()));
        let nameConverted = ReferenceGeomParish.removeSognPart(ReferenceGeomParish.convertToAA(name.toString().toLowerCase()));
        let result = propConverted === nameConverted;
        // Check alias list if not found
        if (!result) {
            let foundKey = Object.keys(ReferenceGeomParish.parishNameAliasList).find((key, idx) => {
                let found = key.toString().toLowerCase() === name.toString().toLowerCase();
                if (found) {
                    let alias = ReferenceGeomParish.parishNameAliasList[key];
                    return (prop.toString().toLowerCase() === alias.toString().toLowerCase());
                } else {
                    return false;
                }
            });
            result = (foundKey !== undefined);
        }
        return result;
    }
    
    static hasRefGeoBeenLoaded():boolean {
        return ReferenceGeomParish.refGeom && !ReferenceGeomParish.isLoading;
    }

    static isLoadingReferenceGeo():boolean {
        return ReferenceGeomParish.isLoading;
    }

    static loadReferenceGeo():Promise<void> {
        let res = new Promise<void>((resolve, reject) => {
            if (ReferenceGeomParish.hasRefGeoBeenLoaded()) {
                resolve();
            } else {
                Utils.asyncFetchJsonFromUrl(ReferenceGeomParish.dataUrl)
                .then((data) => {
                    ReferenceGeomParish.refGeom = data;
                    ReferenceGeomParish.isLoading = false;
                    resolve();
                }).catch((err) => {
                    ReferenceGeomParish.isLoading = false;
                    reject("Error loading geojson: "+err.message);
                });
                ReferenceGeomParish.isLoading=true;    
            }
        });
        return res;
    }

    static getReferenceGeo(): ReferenceGeomTypeParish {
        // if (this.isLoading) {
        //     // wait a bit before trying again.
        //     const waitMilliSeconds=1000;
        //     const maxRetries=10;
        //     if (noOfRetries < maxRetries) {
        //     appMessageDispatch(actionSetInfoMessage("Loading "+refMissing+"... "+noOfRetries);
        //     Logger.logAction("MapScreen", "_AddALayer","Retry loading "+refMissing+": "+noOfRetries);
        //     await setTimeout(
        //         () => {
        //             this.getReferenceGeo();
        //         }, 
        //         waitMilliSeconds);  
        //     } else {
        //     appMessageDispatch(actionSetErrorMessage("Loading "+refMissing+" failed after "+noOfRetries+" retries");
        //     }
        // }

        return this.refGeom;
    }

    static convertToAA(name:string):string {
        return name && name.replace(/å/g, "aa");
    }

    static removeSognPart(name:string):string {
        return name && name.replace(/\ sogn$/, "");
    }

}
