import { actionSetErrorMessage, AppMessagesContext, SettingsManager, Utils } from "@viamap/viamap2-common"
import React, { useContext, useEffect, useState } from "react"
import { BBOXTURF, EmbeddedVectorMapEnhanced, MapState, SourceSpecs } from "src/compentsModus/EmbeddedVectorMapEnhanced"
import { GenerateGeom } from "src/managers/GenerateGeom"
import { Localization } from "@viamap/viamap2-common"
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface"

import { QuickOrthoPhoto } from "./QuickOrthoPhoto"
import { DynamicDataDrivenLayerPropertyInfo } from "src/managers/DynamicDataDrivenLayer"

type Props = {
   lat:number,
   lng:number,
   orthoPhoto? : boolean
}

export const PropertyInfoTextOnMap = (props: Props) => {
   const EmptyJSON = {
      "type": "FeatureCollection",
      "features": []
   };
   const minZoom = 14;
   const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");

   let [orthoPhoto, setOrthoPhoto] = useState<boolean>(props.orthoPhoto || false);
   let [mapState, setMapState] =useState<MapState>({center:[props.lng, props.lat], pitch:0, bearing: 0, zoom: 17});
   let [sources, setSources] = useState<SourceSpecs>({});
   let [bboxItemsDisplay, setBboxItemsToDisplay] = useState<any>(EmptyJSON);
   let [sfeAreaGeojson, setSfeAreaGeojson] = useState<any>(EmptyJSON);
   let [propertyLayer] = useState<DynamicDataDrivenLayerPropertyInfo>(new DynamicDataDrivenLayerPropertyInfo({
      minZoom: minZoom, 
      path:"dummy", 
      layers:"dummy", 
      label:"dummy", 
      host:"dummy",
      propertiesToDisplay: [
         "ejf_ejere",
         "handel_tidspunkt"
       ]
   }))

   const {dispatch:appMessageDispatch} = useContext(AppMessagesContext);

   async function visSamletFastEjendom(latLng: {lat:number, lng:number, alt?:number}): Promise<any> {
      try {
        let utmCoords = GenerateGeom.convertFromLatLngToUTM(latLng);
        let matrikelData = await PropertyInfoInterface.getMatrikelData(utmCoords)
          .then(result => {
            let props = result.features[0].properties;
            return {
              sfeEjdNr: props.samletfastejendomlokalid,
            };
          }).
          catch(() => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
            return undefined;
          });
        if (matrikelData && matrikelData.sfeEjdNr) {
          let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(matrikelData.sfeEjdNr)
            .then(result => 
               {
                  return result;
               })
            .catch(() => {
               appMessageDispatch(actionSetErrorMessage(Localization.getText("No geometry data for this property")));
              return undefined;
            });
            return ({
               "type": "FeatureCollection",
               ...geoJson
            });
        } else {
         appMessageDispatch(actionSetErrorMessage(Localization.getText("No cadaster data for this point")));
        }
      } catch (error:any) {
         appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
      }
    }

    console.log(propertyLayer.createLayer("source","layerid"));

   function getDataForBBOX(bbox: BBOXTURF) {
      propertyLayer.getDataForBBOX(bbox)
      .then((items) => {
         setBboxItemsToDisplay(items);
      })
      .catch((err) => {
         alert("Got error:" + (err.message || err))
      })
   }

   function createGeoJsonSource(id: string, data: any) {
      return {
         type: "geojson",
         data: data
      };
   }

   function createCadasterSource() {

      return {
         'type': 'raster',
         // use the tiles option to specify a WMS tile source URL
         'tiles': [
            'https://services.datafordeler.dk/MATRIKLEN2/MatGaeldendeOgForeloebigWMS/1.0.0/WMS?' +
            'bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&transparent=TRUE&crs=EPSG:3857&width=256&height=256' +
            '&layers=Jordstykke_Gaeldende&styles=Jordstykke_Gaeldende_Roed&username=QBXRBEJZGQ&password=Ballerup2100$'
         ],
         'tileSize': 256
      }
   }

   function createCadasterLayer(id: string, source: string) {
      return {
         'id': id,
         'type': 'raster',
         'source': source,
         'paint': {}
      }
   }

   function createOmridsLayer(id:string, source:string) {
      const color='#BD9060';
      return {
         id: id,
         type: 'fill',
         source: source,
         layout: {
         },
         paint: {
            'fill-outline-color':color,
            'fill-color':color,
            'fill-opacity':0.6
         }
       };
   }

   // ------------------------------------------------------------------------------------------------------------------------------------------------------------

   useEffect(() => {
      visSamletFastEjendom({lat:props.lat, lng:props.lng})
      .then((geojson) => {
         setSfeAreaGeojson(geojson);
         setMapState({center:[props.lng, props.lat], pitch:0, bearing: 0, zoom: 18});
         // ToDo: fit to bounds to show all of sfe in view.
      });
   }, [props.lat, props.lng]);

   useEffect(() => {
      if (props.orthoPhoto) {
         setSources({
            'sfeAreaData': createGeoJsonSource('sfeAreaData', sfeAreaGeojson)
         });
      } else {
         setSources({
            'cadasterSource': createCadasterSource(),
            // 'myPointSource': createPointSource(props.lat, props.lng),
            'sfeAreaData': createGeoJsonSource('sfeAreaData', sfeAreaGeojson),
            'propertyInfoData': createGeoJsonSource('propertyInfoData', bboxItemsDisplay),
         });
      }
   }, [sfeAreaGeojson, bboxItemsDisplay]);

   return (
      <>
         <QuickOrthoPhoto anchor={"bottomLeft"} current={orthoPhoto ? "Orthophoto" : "Viamap"} onSetBgLayer={(e) => {setOrthoPhoto(e === "Orthophoto")}} />
         <EmbeddedVectorMapEnhanced
            orthoPhoto={orthoPhoto}
            // height="400px"
            sources={sources}
            layers={props.orthoPhoto ? {
               'sfeAreaLayer': createOmridsLayer('sfeAreaLayer', 'sfeAreaData'),
            } : {
               'cadasterLayer': createCadasterLayer('cadasterLayer','cadasterSource'),
               // 'myPointLayer': createPointLayer('myPointLayer', 'myPointSource'),
               'sfeAreaLayer': createOmridsLayer('sfeAreaLayer', 'sfeAreaData'),
               'propertyInfoPoints': propertyLayer.createLayer('propertyInfoData', 'propertyInfoPoints'),
            }}
            fullScreenControl={true}
            drawControls={false}
            navigationControls={false}
            onMove={(newLocation: MapState) => {
               if (newLocation && newLocation.zoom >= minZoom) {
                  getDataForBBOX(newLocation.bbox || []);
               }
            }}
            hideBuildings={true}
            mapState={mapState}
         />
      </>
   );
}
