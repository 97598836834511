import { SettingsManager } from "@viamap/viamap2-common";

export type MarketDataItem = {
   timeStamp:number, // Epoc data value
   value:number
};

export type MarketData={
   [key:string]: {
         "data":MarketDataItem[],
         "observationCount":number,
         "minDate":Date,
         "maxDate":Date,
         "minDistanceMeters":number,
         "maxDistanceMeters":number
      }
};

export enum MarketDataKey { 
   BruttoLejeData = "BruttoLejeData",
   NettoLejeData = "NettoLejeData",
   VolumenData = "VolumenData",
   PrisKvmData = "PrisKvmData"
}

export class MarketDataInterface {

   /**
    * Gets Market Data for a property. It is averages of rent levels and transaction prices for the surrounding area.
    * @param lat Lattitude (center of search area)
    * @param lng Longotude (center of search area)
    * @param ejendomsType eg. "Kontor", "Detail", "Industri", "Andet"
    * @param targetCount Number of observations to include in averages. Will try to use the 'targetCount' nearest observations
    * @param maxDistanceMeters Observations more than 'maxDistanceMeters' from the center will be disregarded.
    * @param fromYear Earliest year. Eg. 2018
    * @param toYear Latest year. E.g. 2022
    * @param byYear Some data exists by quarter. If 'byYear' is true all data is returned as yearly averages in any case.
    * @returns 'MarketData' structure.
    */
   static async getMarketData(indexNameTxAct:string, indexNameRent:string, lat: number, lng: number, ejendomsType: string, targetCount:number, maxDistanceMeters:number, fromYear:number, toYear:number, byYear:boolean) : Promise<MarketData> {
      let url = SettingsManager.getSystemSetting("mapitServicesHost");
      let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
      let path = SettingsManager.getSystemSetting("marketDataServiceEndpoint");

      let query = {
        lat: lat,
        lng: lng,
        propertyType: ejendomsType,
        "fromDate": ""+fromYear,
        "toDate":  ""+toYear,
        "minHits": ""+targetCount,
        "maxDistanceMeters": ""+maxDistanceMeters,
        "byYear": ""+byYear,
        "indexNameTxAct": indexNameTxAct,
        "indexNameRent": indexNameRent
      };
      return fetch("https://"+url+"/"+path, {
         method: "POST",
         body: JSON.stringify(query),
         headers: {
          "x-api-key": apiKey
         }
      })
      .then(async (response) => {
         let body = await response.json();
         let result = body;
        return result;
      })
      .catch((err) => {
         console.error("Got error searching: ", JSON.stringify(query), err);
        });
   }

}