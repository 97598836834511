import { AppMessagesContext, actionSetProgressMessage, Localization, actionSetInfoMessage } from "@viamap/viamap2-common";
import React from "react";
import { Spinner, Modal } from "react-bootstrap";
import { FiCopy, FiPrinter } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { usePanWindow } from "src/common/components/CustomHooks";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { AddressInterface } from "src/managers/AddressInterface";
import { ExportData } from "src/managers/ExportData";
import { MitStorage } from "src/managers/MitStorage";
import { SheetFunc } from "src/managers/SheetFunc";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { ProjectContext, handleToBeAdded } from "src/states/ProjectState";
import { ViewButtonCheck, ViewButtonText, ViewButtonIcon } from "./PropertyComponents";
import { PropertyData } from "./PropertyInfoCollectorTypes";
import { Feature } from "src/states/ApplicationStateFeatures";
import { AdvancedLargeDragModal } from "src/components/AdvancedComponents";
import { CondominiumView } from "./CondominiumView";

type Props = {
    showWindow: number;
    callBackOnClose: () => void;
    bfeNr?:{bfeNr: string | number};
    allowExtendedInformation: boolean;
  };
  
  export function CondominiumDialog(props: Props) {
    const [propertyDefaultCollapsed, setPropertyDefaultCollapsed] =
      React.useState(MitStorage.getValue<Boolean>("EjerlejlighedAutoColapsed"));
    const { hasAccessToFeature } = React.useContext(ApplicationStateContext);
    const { dispatch: appMessageDispatch } = React.useContext(AppMessagesContext);
    const { state: projState, dispatch: projDispatch } =
      React.useContext(ProjectContext);
  
    const [isLoading, setIsLoading] = React.useState(true);
    const [propertyData, setPropertyData] = React.useState<any>();
  
    React.useEffect(() => {
      setIsLoading(true);
    }, [props.bfeNr]);
  
    if (!props.showWindow) {
      return;
    }
  
    function RemberCollapseSettings(e: any, val) {
      MitStorage.setValue<Boolean>("EjerlejlighedAutoColapsed", !val);
    }
  
    // MARK: Fix
    async function handleExportToExcel() {
      let wb = await (new ExportData()).createPropertyDataExcelExport(
        "Property Export",
        new Date(), 
        propertyData,         
        (progress:number) => {
          appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/1)));
       });
       let blob = SheetFunc.createBlob(wb);
       let fileName = "PropertyData"+(propertyData?.BBRRel?.bfeNummer || "");
       fileName += ".xlsx";
       ExportData.downloadBlob(fileName, blob);
    }
  
    let fold = (
        <ViewButtonCheck
          checked={!propertyDefaultCollapsed}
          className="no-print"
          onCheck={(e, val) => {
            RemberCollapseSettings(e, val);
            setPropertyDefaultCollapsed(val);
          }}
        >
          {Localization.getText("Expanded")}
        </ViewButtonCheck>
    );
  
    let closeButton = (
      <MdClose
        className="CloseButton no-print"
        onClick={() => props.callBackOnClose()}
      />
    );
  
    return (
      <AdvancedLargeDragModal
        posistionLocalStorageKey="CondominiumDialogPos"
        show={props.showWindow}
        style={{ zIndex: 1000 + props.showWindow, isolation: "isolate" }}
        className="mit-developer-information Flat-Modal unsetWidth Flat-PropertyView scrollbarHidden"
        headerContent={
          <>
            <h4 className="no-print">{Localization.getText("Condominium")}</h4>
            <ProtectedFeature
              feature={Feature.NotActive}
              contentsIfNoAccess={<></>}
            >
                Tilføj til Projekt
            </ProtectedFeature>
            <ProtectedFeature
              feature={Feature.PrintPropertyInfo}
              contentsIfNoAccess={<></>}
            >
              <>
                {!isLoading ? (
                  <>
                  <ViewButtonIcon
                    className="no-print"
                    onClick={() => {
                      print();
                    }}
                    title={Localization.getText("Print")}
                  >
                  <FiPrinter />
                  </ViewButtonIcon>
                  </>
                ) : (
                  <Spinner size="sm" />
                )}
              </>
            </ProtectedFeature>
            {fold}
            {closeButton}
          </>
        }
      >
        
  
        {(props.bfeNr && (
          <Modal.Body
            style={{
              overflow: "hidden",
              display: "grid",
              alignContent: "start",
            }}
          >
            <CondominiumView
              key={props.bfeNr.bfeNr}
              foldetUd={!propertyDefaultCollapsed}
              cols={hasAccessToFeature(Feature.ThemeNewSec) ? 2 : 1}
              bfeNr={props.bfeNr.bfeNr} 
              callBackOnLoaded={(data:PropertyData) => {
                setIsLoading(false);
                setPropertyData(data);
              }}
              allowExtendedInformation={true}
              />
          </Modal.Body>
        )) ||
        "Missing Dot"}
      </AdvancedLargeDragModal>
    );
    // 706899 Bygning på fremmedgrund
  }
  