
import React, { useEffect, useRef, useState, ReactPortal } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import {Cookies} from '@viamap/viamap2-common';
import { Localization } from "@viamap/viamap2-common";
import { BsX } from "react-icons/bs";
import ReactDOM from "react-dom";
import { MitBody, MitButton, MitCheckBox, MitDialog, MitFooter, MitHeader, MitInfo } from "./ComponentUtils";
import { getTheme, isTheme } from "./ThemeHelpers";

export type ConfirmationDialogEvent = {
  type: "auto" | "submit" | "cancel",
  checked: boolean,
};

export function ConfirmationDialog (
  props: {
    showWindow?:number,
    header?:string, 
    children?:JSX.Element | null | string,
    cookieTag?:string,
    defaultChecked?:boolean,
    onSubmit?:(evt: ConfirmationDialogEvent)=>void,
    onCancel?:(evt: ConfirmationDialogEvent)=>void,

  } = {
    showWindow:1,
    header:"",
    children:(<></>),
    defaultChecked:false,
    cookieTag:"",
    onSubmit: (evt: ConfirmationDialogEvent) => {return;},
    onCancel: (evt: ConfirmationDialogEvent) => {return;}
  }
  ) {
  const checkboxRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const isNewSecTheme = isTheme("NewSecTheme")

  useEffect(() => {
    if (props.cookieTag && props.cookieTag !== "") {
      if (Cookies.getCookieBoolean( props.cookieTag )) {
        if (props.onSubmit) {
        props.onSubmit({type: "auto", checked:true});
        }
      }
    }
    },
            [props.cookieTag]
    );

  function onSubmitHandler(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (checked && props.cookieTag && props.cookieTag !== "") {
      Cookies.setCookieBoolean( props.cookieTag , checked);
    }
    if (props.onSubmit) {
      props.onSubmit({type: "submit", checked: checked});
    }
  }

  function onCancelHandler() {
    if (props.onCancel) {
      props.onCancel({type: "cancel", checked: checked});
    }
  }

  function onCheckedChange(e: any) {
    setChecked(e.target.checked);
  }

  if (props.cookieTag && props.cookieTag !== "") {
    if (Cookies.getCookieBoolean( props.cookieTag )) {
      if (props.onSubmit) {
        return null;
      }
    }
  }

  
  if (props.showWindow) {
    return ReactDOM.createPortal(
      
      <MitDialog className={" MitConfirmationDialog "} show={props.showWindow} style={{left:"50%", transform:"translateX(-50%)", top:"20px"}} >
        {props.header != undefined ?
        <MitHeader position="center">{props.header && Localization.getText(props.header)}</MitHeader>
        : (isNewSecTheme ? <MitHeader position="center"  >{""}</MitHeader> : null)
        }
      <MitBody style={{paddingBottom:"0px"}}>
        {isNewSecTheme ? <div className="closeBtn" onClick={(e) => {onCancelHandler?.()}} ><BsX/></div> : null}
        <MitInfo variant="dark" style={{marginBottom:"0px", height:"100px"}}>
          {props.children}
        </MitInfo>
      </MitBody>
      <MitFooter className={isNewSecTheme ? "flex-mid":""} style={isNewSecTheme ? {justifyContent:"center"} : {}} >
         {(props.cookieTag && props.cookieTag !== "") ? (
            <MitCheckBox variant="normal" onClick={onCheckedChange} style={{fontSize: "14px", marginRight:"auto"}}>
              {Localization.getText("Do not ask me again")}
            </MitCheckBox>
         ) : null}
        
        <MitButton variant="normal" onClick={(e) => onSubmitHandler(e)} >{Localization.getText("Submit")}</MitButton>
        {!isNewSecTheme ? <MitButton variant="close" onClick={(e) => onCancelHandler()} >{Localization.getText("Cancel")}</MitButton> : null}
       </MitFooter>
      </MitDialog>
    
    ,document.getElementById("Mit-MapOverlay") || document.body);
  }
  return null;

}
/*



*/