import { BBRKodeLister } from "src/propertyInfoTemplates/BBRKodeLister"

/**
 * 
 * TODO:
 * @param dataKey Key from key/value pair BBR_Datafordeler
 * @param kode Value from key/value pair BBR_Datafordeler
 * @returns Human readable Text og DateObject
 */
export function BBRTranslateByDataKey(dataKey, kode) {
  const ignore = [
    "byg007Bygningsnummer",
    "byg026Opførelsesår",
    "byg038SamletBygningsareal",
    "byg039BygningensSamledeBoligAreal",
    "byg041BebyggetAreal",
    "byg043ArealIndbyggetCarport",
    "byg044ArealIndbyggetUdhus",
    "byg049ArealAfOverdækketAreal",
    "byg054AntalEtager",
    "byg404Koordinat", //Should be splitted into two elsewhere
    "forretningshændelse",
    "forretningsområde",
    "grund", "husnummer", "jordstykke", "virkningsaktør",
    "etageList", "opgangList", 
    "bygning", "eta006BygningensEtagebetegnelse", 
    "forretningshændelse", 
    "forretningsområde", "husnummer", "jordstykkeList", "bestemtFastEjendom", "adresseIdentificerer",
    "enh026EnhedensSamledeAreal", "enh027ArealTilBeboelse", "enh031AntalVærelser", 
    "enh065AntalVandskylledeToiletter", "enh066AntalBadeværelser", 
    "opgang", "registreringsaktør", "adgangFraHusnummer", "etage", 
    "byg069Sikringsrumpladser",
    "byg040BygningensSamledeErhvervsAreal",
    "eta020SamletArealAfEtage",
    "eta022Kælderareal",
    "enh028ArealTilErhverv",
    "byg069Sikringsrumpladser",
    "enh063AntalVærelserTilErhverv",
    "eta021ArealAfUdnyttetDelAfTagetage",
    "ejerlejlighedList",
    "byg027OmTilbygningsår",
    "byg048AndetAreal",
    "bfeNummer",
    "bygningPåFremmedGrund",
    "bygningPåFremmedGrundList",
    "ejendomsnummer",
    "byg047ArealAfAffaldsrumITerrænniveau",
    "byg046SamletArealAfLukkedeOverdækningerPåBygningen",
    "byg045ArealIndbyggetUdestueEllerLign",
    "eta026ErhvervIKælder",
    "byg130ArealAfUdvendigEfterisolering",
    "tek007Anlægsnummer",
    "tek024Etableringsår",
    "tek032Størrelse",
    "tek109Koordinat",
    "tek039Effekt"
  ]
  if (ignore.includes(dataKey)) {
    return kode
  }
  const DateTimeObject = [
    "datafordelerOpdateringstid",
    "byg094Revisionsdato",
    "byg114DatoForByggeskadeforsikring",
    "byg122Gyldighedsdato",
    "registreringFra",
    "virkningFra",
    "enh025OprettelsesdatoForEnhedensIdentifikation",
    "enh101Gyldighedsdato",
    "byg129DatoForTilladelseTilAlternativBortskaffelseEllerAfledning",
    "byg029DatoForMidlertidigOpførtBygning",
    "tek042Revisionsdato"
  ]
  if (DateTimeObject.includes(dataKey)) {
    if (kode) {
      return new Date(kode)
    }
  }

  switch (dataKey.trim()) {
    case "byg021BygningensAnvendelse":
      return BBRKodeLister.BygAnvendelse(kode);
    case "byg032YdervæggensMateriale":
    case "byg034SupplerendeYdervæggensMateriale":
      return BBRKodeLister.YdervaeggenesMateriale(kode);
    case "byg033Tagdækningsmateriale":
    case "byg035SupplerendeTagdækningsMateriale":
      return BBRKodeLister.Tagdaekningsmateriale(kode);
    case "byg037KildeTilBygningensMaterialer":
    case "byg053BygningsarealerKilde":
      return BBRKodeLister.KildeTilOplysninger(kode);
    case "byg056Varmeinstallation":
      return BBRKodeLister.Varmeinstallation(kode);
    case "byg058SupplerendeVarme":
      return BBRKodeLister.SupplerendeVarme(kode);
    case "byg113Byggeskadeforsikringsselskab":
      return BBRKodeLister.Byggeskadeforsikringsselskab(kode);
    case "byg121OmfattetAfByggeskadeforsikring":
      return BBRKodeLister.OmfattetAfByggeskadeforsikring(kode);
    case "byg133KildeTilKoordinatsæt":
    case "tek076KildeTilKoordinatsæt":
      return BBRKodeLister.KildeTilKoordinatsaet(kode);
    case "tek077KvalitetAfKoordinatsæt":
    case "byg134KvalitetAfKoordinatsæt":
      return BBRKodeLister.KvalitetAfKoordinatsaet(kode);
    case "byg135SupplerendeOplysningOmKoordinatsæt":
    case "tek078SupplerendeOplysningOmKoordinatsæt":
      return BBRKodeLister.SupplerendeOplysningerOmKoordinatsaet(kode);
    case "byg136PlaceringPåSøterritorie":
    case "tek107PlaceringPåSøterritorie":
      return BBRKodeLister.PaaSoeTerritorie(kode);
    case "byg406Koordinatsystem":
    case "tek045Koordinatsystem":
      return BBRKodeLister.Koordinatsystem(kode);
    case "forretningsproces":
      return BBRKodeLister.ForretningsProcess(kode);
    case "kommunekode":
      return BBRKodeLister.Kommunekode(kode);
    case "status":
      return BBRKodeLister.Livcyklus(kode);
    case "eta025Etagetype":
      return BBRKodeLister.EtageType(kode);
    case "byg030Vandforsyning":
      return BBRKodeLister.BygVandforsyning(kode);
    case "gru009Vandforsyning":
      return BBRKodeLister.GruVandforsyning(kode);
    case "byg031Afløbsforhold":
      return BBRKodeLister.BygAfloebsforhold(kode);
    case "gru010Afløbsforhold":
      return BBRKodeLister.AfloebsForhold(kode);
    case "enh020EnhedensAnvendelse":
      return BBRKodeLister.EnhAnvendelse(kode);
    case "enh023Boligtype":
      return BBRKodeLister.Boligtype(kode);
    case "enh030KildeTilEnhedensArealer":
      return BBRKodeLister.KildeTilOplysninger(kode);
    case "enh032Toiletforhold":
      return BBRKodeLister.ToiletForhold(kode);
    case "enh033Badeforhold":
      return BBRKodeLister.Badeforhold(kode);
    case "enh034Køkkenforhold":
      return BBRKodeLister.Køkkenforhold(kode);
    case "enh035Energiforsyning":
      return BBRKodeLister.Energiforsyning(kode);
    case "enh045Udlejningsforhold":
      return BBRKodeLister.Udlejningsforhold(kode);
    case "enh071AdresseFunktion":
      return BBRKodeLister.AdresseStatus(kode);
    case "opg020Elevator":
      return BBRKodeLister.Elevator(kode);
    case "enh048GodkendtTomBolig":
      return BBRKodeLister.GodkendtTomBolig(kode);
    case "enh024KondemneretBoligenhed":
      return BBRKodeLister.KondemneretBoligenhed(kode);
    case "byg055AfvigendeEtager":
      return BBRKodeLister.AfvigendeEtager(kode);
    case "byg057Opvarmningsmiddel":
      return BBRKodeLister.Opvarmningsmiddel(kode);
    case "ejendommensEjerforholdskode":
      return BBRKodeLister.Ejerforholdskode(kode);
    case "ejendomstype":
      return BBRKodeLister.Ejendomstype(kode);
    case "byg128TilladelseTilAlternativBortskaffelseEllerAfledning":
      return BBRKodeLister.TilladelseTilAlternativBortskaffelseEllerAfledning(kode);
    case "enh051Varmeinstallation":
      return BBRKodeLister.EnhVarmeinstallation(kode);
    case "enh052Opvarmningsmiddel":
      return BBRKodeLister.Opvarmningsmiddel(kode);
    case "tek020Klassifikation":
      return BBRKodeLister.Klassifikation(kode);
    case "tek027Placering":
      return BBRKodeLister.Placering(kode);
    case "tek033Type":
      return BBRKodeLister.TypeAfVægge(kode); // Correct!!
    case "tek034IndholdOlietank":
      return BBRKodeLister.Indhold(kode);
    case "tek110Driftstatus":
      return BBRKodeLister.Driftstatus(kode);
    case "byg070Fredning":
      return BBRKodeLister.Fredning(kode);

    default:
      console.log(dataKey, kode)
      return kode
  }
}
