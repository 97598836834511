import { createRef, useContext, useEffect, useRef, useState } from "react";
import { FiLayers} from 'react-icons/fi';
import { Localization } from "@viamap/viamap2-common";
import { GroupedLayerControl } from "./GroupedLayerControl";
import LayerList from "./LayerList";
import { MapFacadeAbstract } from "src/managers/MapFacade";
import { RightPaneContext, RightPaneModus } from "src/states/RightPaneState";
import { FeatureLayer } from "src/managers/WmsLayerFunc";
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";

type LayerSideBarProps<T> = {
  disabled?: boolean,

  // OLD LayerList
  mapFacade: MapFacadeAbstract,
  layers: any,
  callbackOnHierarchyChanges: any,
  callbackOnLayerChanges: any,
  layerHierarchy: any,
  selectedLayerToStyle: any,
  selectLayerToStyle: any,
  selectHierarchyToStyle: any,
  selectLayerToReopen: any,

  // OLD Grouped Layers
  addedAttributes: number;
  backgroundLayers: {[name:string]:string};
  featureLayers: {[name:string]:T};
  selectedBackgroundLayerKey: string;
  selectedFeatureLayerKeys: string[];
  customFeatureLayers: T[];
  editFeatureAvailable: boolean;
  onCustomFeatureLayerToggle: (customFeatureLayers: {}) => void,
  callBackOnSelectBackgroundLayer: (key: string) => void;
  callBackOnSelectFeatureLayer: (key: string, selected: boolean, layer?: FeatureLayer) => void;
  callbackToConvertCustomFeatureLayerToNormal: (featureLayer: T) => void;
  callBackonSettingsFeatureLayer: (key:string, show: boolean) => void;
}

export function LayerContainer(props:LayerSideBarProps<any>) {
  let sideBarRef = useRef<any>();
  let [pinOpen, setPinOpen] = useState(false);
  let [overflow, setOverflow] = useState(false);
  const {state: RightPaneState} = useContext(RightPaneContext);

  useEffect(() => {
    let RSize = new ResizeObserver((entries) => {
      let overflow = false
      for (const entry of entries) { 
        let x = entry.target
        console.log("sH ",x.scrollHeight)
        console.log("cH ",x.clientHeight)
        if (x.scrollHeight > x.clientHeight) {
          overflow = true
        }
      }
      setOverflow(overflow)
    })

    let DAelement = document.querySelector(".DaLayers .card-body")
    if (DAelement) {
      RSize.observe(DAelement)
    }
    let BGelement = document.querySelector(".BgLayers .Body")
    if (BGelement) {
      RSize.observe(BGelement)
    }

    return () => RSize.disconnect()
  },[])

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (sideBarRef.current) {
        sideBarRef.current!.style.setProperty('--content-Width', ""+entries[0].contentRect.width+"px")
      }
    })
    observer.observe(sideBarRef.current!)
    return () => sideBarRef.current && observer.unobserve(sideBarRef.current)
  },[])
  function openCloseHandler() {
    setPinOpen(!pinOpen)
  }
  
  return (
    <div className="FullContainer">
      <div ref={sideBarRef} className={"Sidebar-Right"+(pinOpen ? " Open":"")+(RightPaneState.current.includes(RightPaneModus.Map_Layer) ? " CalcSideBar":" ")}>
        {/* <div className="hoverActivator"></div> */}
        {/* <button className="LayerIcon" data-pinned={pinOpen ? "true":""} onClick={(e) => openCloseHandler()}>
            <FiLayers />
        </button>
        <button className="PinIcon" data-pinned={pinOpen ? "true":""} onClick={(e) => openCloseHandler()} >
          <AiOutlinePushpin />
        </button> */}
        {/* <div className="contentHead">
            <h5 style={{paddingTop:"7px"}}> {Localization.getText("Layers")} </h5>
        </div> */}
        <div  className="content">
          
          <div className="TypeGroup DaLayers" >
            <LayerList 
              mapFacade={(props.mapFacade as any)}
              layers={(props.layers as any)}
              callbackOnHierarchyChanges={(props.callbackOnHierarchyChanges as any)}
              callbackOnLayerChanges={(props.callbackOnLayerChanges as any)}
              selectedLayerToStyle={(props.selectedLayerToStyle as any)}
              selectLayerToStyle={(props.selectLayerToStyle as any)}
              layerHierarchy={(props.layerHierarchy as any)}
              selectHierarchyToStyle={(props.selectHierarchyToStyle)}
              selectLayerToReopen={(props.selectLayerToReopen)}
            />
          </div>
          <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
          {overflow ? <div style={{borderTop: "3px solid #6785B866", minHeight:"3px"}}></div> : <></>}
          </ProtectedFeature>
          <div className="TypeGroup BgLayers">
            <GroupedLayerControl
                  addedAttributes={props.addedAttributes}
                  backgroundLayers={props.backgroundLayers}
                  featureLayers={props.featureLayers}
                  selectedBackgroundLayerKey={props.selectedBackgroundLayerKey}
                  selectedFeatureLayerKeys={props.selectedFeatureLayerKeys}
                  customFeatureLayers={props.customFeatureLayers}
                  editFeatureAvailable={props.editFeatureAvailable}
                  callBackOnSelectBackgroundLayer={props.callBackOnSelectBackgroundLayer}
                  callBackOnSelectFeatureLayer={props.callBackOnSelectFeatureLayer}
                  onCustomFeatureLayerToggle={props.onCustomFeatureLayerToggle}
                  callbackToConvertCustomFeatureLayerToNormal={props.callbackToConvertCustomFeatureLayerToNormal}
                  callBackonSettingsFeatureLayer={props.callBackonSettingsFeatureLayer}
                  
                  />
          </div>
        </div>
      </div>
    </div>
  )
}