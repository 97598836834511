export class ObliqueMatrixFunc {
     
  // -----------------------------------------------------------------------------------------------
  // Matrix library to calculate transformation
  // -----------------------------------------------------------------------------------------------
  /* tslint:disable */

  static matrixSolver(source1, dest1, source2, dest2, source3, dest3, source4, dest4) {

   function adj(m) { // Compute the adjugate of m
     return [
       m[4]*m[8]-m[5]*m[7], m[2]*m[7]-m[1]*m[8], m[1]*m[5]-m[2]*m[4],
       m[5]*m[6]-m[3]*m[8], m[0]*m[8]-m[2]*m[6], m[2]*m[3]-m[0]*m[5],
       m[3]*m[7]-m[4]*m[6], m[1]*m[6]-m[0]*m[7], m[0]*m[4]-m[1]*m[3]
     ];
   }
   function multmm(a, b) { // multiply two matrices
     var c = Array(9);
     for (var i = 0; i != 3; ++i) {
       for (var j = 0; j != 3; ++j) {
         var cij = 0;
         for (var k = 0; k != 3; ++k) {
           cij += a[3*i + k]*b[3*k + j];
         }
         c[3*i + j] = cij;
       }
     }
     return c;
   }
   function multmv(m, v) { // multiply matrix and vector
     return [
       m[0]*v[0] + m[1]*v[1] + m[2]*v[2],
       m[3]*v[0] + m[4]*v[1] + m[5]*v[2],
       m[6]*v[0] + m[7]*v[1] + m[8]*v[2]
     ];
   }
   function pdbg(m, v) {
     var r = multmv(m, v);
     return r + " (" + r[0]/r[2] + ", " + r[1]/r[2] + ")";
   }
   function basisToPoints(x1, y1, x2, y2, x3, y3, x4, y4) {
     var m = [
       x1, x2, x3,
       y1, y2, y3,
       1,  1,  1
     ];
     var v = multmv(adj(m), [x4, y4, 1]);
     return multmm(m, [
       v[0], 0, 0,
       0, v[1], 0,
       0, 0, v[2]
     ]);
   }
   function general2DProjection(
     x1s, y1s, x1d, y1d,
     x2s, y2s, x2d, y2d,
     x3s, y3s, x3d, y3d,
     x4s, y4s, x4d, y4d
   ) {
     var s = basisToPoints(x1s, y1s, x2s, y2s, x3s, y3s, x4s, y4s);
     var d = basisToPoints(x1d, y1d, x2d, y2d, x3d, y3d, x4d, y4d);
     return multmm(d, adj(s));
   }
   function project(m, x, y) {
     var v = multmv(m, [x, y, 1]);
     return [v[0]/v[2], v[1]/v[2]];
   }
   function transform2d(width, height, x1, y1, x2, y2, x3, y3, x4, y4) {
     var w = width, h = height;
     var t = general2DProjection
       (0, 0, x1, y1, w, 0, x2, y2, 0, h, x3, y3, w, h, x4, y4);
     var i;
     for(i = 0; i != 9; ++i) t[i] = t[i]/t[8];
     t = [t[0], t[3], 0, t[6],
         t[1], t[4], 0, t[7],
         0   , 0   , 1, 0   ,
         t[2], t[5], 0, t[8]];
   }
   
   let proj = general2DProjection(
     source1[0], source1[1], dest1[0], dest1[1],
     source2[0], source2[1], dest2[0], dest2[1],
     source3[0], source3[1], dest3[0], dest3[1],
     source4[0], source4[1], dest4[0], dest4[1],
   );

   return proj;
 }

 static matrixProjection(proj:any, point:number[]):any {
   function multmv(m, v) { // multiply matrix and vector
     return [
       m[0]*v[0] + m[1]*v[1] + m[2]*v[2],
       m[3]*v[0] + m[4]*v[1] + m[5]*v[2],
       m[6]*v[0] + m[7]*v[1] + m[8]*v[2]
     ];
   }
   function project(m, x, y) {
     var v = multmv(m, [x, y, 1]);
     return [v[0]/v[2], v[1]/v[2]];
   }

   let res = project(proj, point[0], point[1]);

   return res;
 }
 
 /* tslint:enable */

}