import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {Button, Card} from 'react-bootstrap';

import {DemographyReport, DemographyReportMetaData} from './DemographyReport';
import { MitBody, MitButton, MitDialog, MitHeader } from './ComponentUtils';

import {Logger} from '@viamap/viamap2-common';
import {Localization} from "@viamap/viamap2-common";
import { SheetFunc } from '../managers/SheetFunc';
import Glyphicon from '@strongdm/glyphicon';
import { isTheme } from './ThemeHelpers';
import { ProtectedFeature } from './ProtectedFeature';
import { Feature } from 'src/states/ApplicationState';
import { ExportData } from 'src/managers/ExportData';
// import '../css/mit-controls.css';

type Props = {
    data:any;
    metaData:DemographyReportMetaData;
    callbackOnDismiss: ()=> void; 
    showWindow?:number;
    languageCode:string;
};

export function ReportResultDialog(props:Props) {
  const languageCode = ['da','en'].includes(props.languageCode) ? props.languageCode : "da";

  function handleDismiss() {
//    alert ("got dismiss 2");
    props.callbackOnDismiss();
  }

  function onClickDownload() {
    let ws = SheetFunc.create360NDataReport(props.metaData, props.data);
    let blob = SheetFunc.createBlob(ws);              
    let fileName = Localization.getText("FileName:Report") +".xlsx";
    ExportData.downloadBlob(fileName, blob);
  }

      let downloadButton = (
        <MitButton
            variant="normal"
            style={{fontSize:"14px", float:"right", marginRight:"20px"}}
            onClick={()=> onClickDownload()}
        >
        {Localization.getText("Download")}
        </MitButton>
      );
      let printButton = (
        <MitButton
            variant="normal"
            style={{fontSize:"14px", float:"right", marginRight:"10px"}}
            onClick={()=>  {   print()}}
        >
        {Localization.getText("Print")}
        </MitButton>
      );

      // When this window is shown printing should not contain background map too
      let element:HTMLElement|null;
      element = document.getElementById("mit-mainwindow-map");
      if (props.showWindow) {
        element && element.classList.add("no-print");
      } else {
        element && element.classList.remove("no-print");
      }

      let contents = props.showWindow ? (
        <MitDialog show={props.showWindow} style={{left:"10px", top:"10px"}} >
          <MitHeader id="mit-demography-report-resultheader" position='center' onClose={() => handleDismiss()}>
            {downloadButton}
            <ProtectedFeature feature={Feature.PrintPropertyInfo} contentsIfNoAccess={<></>}>
              {printButton}
            </ProtectedFeature>
            {Localization.getTextSpecificLanguage(languageCode,"Demography Report")}
          </MitHeader>
          <MitBody>
              <DemographyReport
                showWindow={true}
                data={props.data}
                metaData={props.metaData}
                callback={() => handleDismiss()}
              />
          </MitBody>
        </MitDialog>
      ) : null;

      return contents;

      // return (

      //   // <MyWindowPortal>
      //     {contents}
      //   // </MyWindowPortal>
      // );
  }
