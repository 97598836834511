import { MitThemes } from "./ThemeManager";

export function setTheme(style: MitThemes) {
    document.body.className = style;
  }
  
  export function isTheme(theme: MitThemes) {
    return document.body.classList.contains(theme)
  }
  
  // FIXME: IS Broken
  export const MitThemeList = ["Default","NewSecTheme","BigPinTheme"] as MitThemes[]
  export function getTheme() : MitThemes {
    if (MitThemeList.includes(document.body.className as MitThemes)) {
      return MitThemeList[document.body.className]
    } else {
      return MitThemeList[0]
    }
  }
  