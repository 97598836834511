import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { IdentTransformer, PropertyDataRequestsObj } from "./PropertyInfoCollectorTypes";
import { ObliqueInterfaces } from "src/managers/ObliqueInterfaces";
import { ViewDirection } from "src/managers/ObliqueFunc";
import { BuildingHeight } from "src/managers/BuildingHeight";
import { GenerateGeom } from "src/managers/GenerateGeom";
import { PropertyInformation } from "src/managers/PropertyInformation";

export const IdentTransformers: IdentTransformer[] = [
    {
      from: "latLng",
      to: ["BFEnr", "Matnr"],
      paramTransformer(ident) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return [y];
        }
        return [];
      },
      func: PropertyInfoInterface.getMatrikelData,
      resultTransform(result) {
        return {
          BFEnr: result?.features?.[0]?.properties?.samletfastejendomlokalid,
          ejerlavNr: result?.features?.[0]?.properties?.ejerlavskode,
          matrikelNr: result?.features?.[0]?.properties?.matrikelnummer,
          geom: result.sfeGeom,
        };
      },
      hasThis: PropertyInfoInterface,
    }
  ];

export const RequestObject: PropertyDataRequestsObj = {
    Mat2: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return [y];
        }
        return [];
      },
      func: PropertyInfoInterface.getMatrikelData,
      returnTransformer(result) {
        return { ...(result?.features?.[0]?.properties ?? {}) };
      },
      hasThis: PropertyInfoInterface,
    },
    Mat2Ejerlejlighed: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) {
          return [ident.BFEnr]
        }
        return []
      },
      func: PropertyInfoInterface.Ejerlejligheder,
      returnTransformer(result) {
        return result.features.map((a) => a.properties)
      },
      hasThis: PropertyInfoInterface,
    },
    ESearchEjerlejlighed: {
      dependency: "Mat2Ejerlejlighed",
      paramTransformer(ident, data) {
        if (data && "Mat2Ejerlejlighed" in data) {
          return data.Mat2Ejerlejlighed.flat().map((a) => {
            return [a.BFEnummer]
          }).filter((a) => a.length)
        }
        return []
      },
      func: PropertyInfoInterface.ejerAfEjerlejlighed,
      returnTransformer(a) {
        return a[0]
      }
    },
    SFEGeom: {
      identDependency: "BFEnr",
        paramTransformer(ident) {
          if ("BFEnr" in ident) {
            return [ident.BFEnr];
          } return [];
        },
        func: PropertyInfoInterface.fetchMat2CadasterWFSForSFE,
        hasThis: PropertyInfoInterface,
        returnTransformer(result) {
          return result
        }
    },
    BBRRel: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if (ident["BFEnr"]) return [(ident as any).BFEnr];
        return [];
      },
      func: PropertyInfoInterface.BBRejendomsrelationForBFE,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result[0];
      },
    },
    ViamapObliqueFoto: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return [y, ViewDirection.NORTH, 13, true, "2023"];
        }
        return [];
      },
      func: ObliqueInterfaces.getObliqueImage,
      hasThis: ObliqueInterfaces,
      returnTransformer(result) {
        let pictureDate: Date =
          (result && result.timeutc && new Date(result.timeutc)) || new Date();
        let ress = {
          point: [result.x, result.y],
          image: result.image,
          width: result.width,
          height: result.height,
          pictureDate: pictureDate,
        };
        return ress;
      },
    },
    ViamapTingBog: {
      identDependency: "Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident) return [ident.ejerlavNr, ident.matrikelNr];
        return [];
      },
      func: PropertyInfoInterface.getDataTingbog,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    ViamapEjendomData: {
      identDependency: "Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident) return [ident.ejerlavNr, ident.matrikelNr];
        return [];
      },
      func: PropertyInfoInterface.getEjendomData,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    Ejerlav: {
      identDependency: "Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident) return [ident.ejerlavNr];
        return [];
      },
      func: PropertyInfoInterface.getEjerlavMedKode,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result[0];
      },
    },
    ViamapHandelsOplysning: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) return [ident.BFEnr];
        return [];
      },
      func: PropertyInfoInterface.getHandelsOplysning,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    EBRBFE: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) return [ident.BFEnr];
        return [];
      },
      func: PropertyInfoInterface.EBRadresseForBFE,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result.features[0].properties || {};
      },
    },
    BBRGrund: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) return [ident.BFEnr];
        return [];
      },
      func: PropertyInfoInterface.BBRgrundForBFE,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    BBRSFE: {
      identDependency: "Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident) return [ident.ejerlavNr, ident.matrikelNr];
        return [];
      },
      func: PropertyInfoInterface.BBRsamletFastEjendomBFE,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result.features;
      },
    },
    Vurd: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) return [ident.BFEnr];
        return [];
      },
      func: PropertyInfoInterface.vurdLookupByBfeNr,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    PlanDataLokalPlan: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_lokalplan_vedtaget_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    PlanDataLokalPlanGeom: {
      dependency: "SFEGeom",
      paramTransformer(ident, data) {
        if (data && "SFEGeom" in data) {
          return ["pdk:theme_pdk_lokalplan_vedtaget_v", data.SFEGeom];
        }
        return []
      },
      func: PropertyInfoInterface.getLokalplanDataInsideGeom,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    PlanDataKommunePlan: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_kommuneplanramme_alle_vedtaget_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    PlanDataKommunePlanAflyst: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_kommuneplanramme_alle_aflyst_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    PlanDataKommunePlanForslag: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_kommuneplanramme_alle_forslag_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    DHM_Overflade: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return [y];
        }
        return [];
      },
      func: BuildingHeight.getHeightInformationLocalMaximum,
      returnTransformer(result) {
        return result;
      },
      hasThis: BuildingHeight,
    },
    DHM_Terraen: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return [y];
        }
        return [];
      },
      func: BuildingHeight.getHeightInformation,
      returnTransformer(result) {
        return result;
      },
      hasThis: BuildingHeight,
    },
    ViamapEjendomStats: {
      dependency: ["Mat2", "EBRBFE"],
      paramTransformer(ident, data) {
        if (
          data?.Mat2?.kommunekode &&
          data.EBRBFE?.husnummer?.[0]?.postnummer.postnr
        )
          return [
            Number(data.Mat2.kommunekode).toString(),
            data.EBRBFE.husnummer[0].postnummer.postnr,
            1,
          ];
        return [];
      },
      func: PropertyInfoInterface.getEjendomStats,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result.Samlet;
      },
    },
    BBRByg: {
      dependency: "BBRGrund",
      paramTransformer(ident, data) {
        if (data?.BBRGrund?.length) {
          return ([...(data?.BBRGrund?.map((a) => a && [a.id_lokalId]).filter((a) => a && a[0]) || [])] );
        }
        return [];
      },
      func: PropertyInfoInterface.BBRbygningForGrundId,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    DARAdgang: {
      dependency: "BBRByg",
      paramTransformer(ident, data) {
        if (data?.BBRByg?.length) {
          return ([...new Set(data.BBRByg?.flat()?.filter(PropertyInformation.filterOpførtGældende)?.map((a) => a.id_lokalId))].map((a) => [a]))
        }
        return []
      },
      
      func: PropertyInfoInterface.DAR_BygningTilAdgang,
      hasThis: PropertyInformation,
      returnTransformer(result) {
        return result;
      },
    },
    DARAdresser: {
      dependency: "DARAdgang",
      paramTransformer(ident, data) {
        if (data?.DARAdgang?.length) { 
          return ([...new Set(data.DARAdgang?.flat()?.map((a) => a.id_lokalId))].map((a) => [a]))
        }
        return []
      },
      func: PropertyInfoInterface.DAR_AdresserFraHusnummer,
      hasThis: PropertyInformation,
      returnTransformer(result) {
        return result;
      },
    },
    BBRGrundTeknik: {
      dependency: "BBRGrund",
      paramTransformer(ident, data) {
        if (data?.BBRGrund?.length) {
          return ([...(data?.BBRGrund?.map((a) => a && [a.id_lokalId]).filter((a) => a && a[0]) || [])] );
        }
        return [];
      },
      func: PropertyInfoInterface.BBRteknikForGrundId,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    BBRBygTeknik: {
      dependency: "BBRByg",
      paramTransformer(ident, data) {
        if (data?.BBRByg?.length) {
          return ([...(data?.BBRByg?.flat().map((a) => a && [a.id_lokalId]).filter((a) => a && a[0]) || [])] );
        }
        return [];
      },
      func: PropertyInfoInterface.BBRteknikForBygId,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    BBRBFETeknik: {
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
        if ("BFEnr" in ident) {
          return ([ident.BFEnr]);
        }
        return [];
      },
      func: PropertyInfoInterface.BBRteknikForBFENR,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    BBREnhed: {
      dependency: "BBRByg",
      paramTransformer(ident, data) {
        if (data?.BBRByg) {
          let FlatBB = data.BBRByg.flat();
          return [...FlatBB.flatMap((a) => a.etageList?.map((etage) => [etage.etage.id_lokalId]) || []).filter((a) => a.length)]
        }
        return [];
      },
      func: PropertyInfoInterface.BBRenhedForEtageId,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    BBREnhedTeknik: {
      dependency: "BBREnhed",
      paramTransformer(ident, data) {
        if (data?.BBREnhed?.length) {
          return ([...(data?.BBREnhed?.flat().map((a) => a && "id_lokalId" in a && [a.id_lokalId] || []).filter((a) => a && a[0]) || [])] );
        }
        return [];
      },
      func: PropertyInfoInterface.BBRteknikForEnhedId,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    PEnheder: {
      dependency: ["Mat2","BBRSFE"],
      paramTransformer(ident, data) {
        let matrikler = data?.BBRSFE?.[0]?.properties.jordstykke.map((js) => {
          let item = js.properties;
          return {
            ejerlavkode:  item.ejerlavskode,
            matrikelnr:   item.matrikelnummer,
            ejerlavsnavn: item.ejerlavsnavn
          }
        })
        if (matrikler && matrikler.length > 0) {
          return [[matrikler]];
        }
        return [];
      },
      func: PropertyInfoInterface.PEnhederpaaEjendom,
      hasThis: PropertyInfoInterface,
      returnTransformer(result) {
        return result;
      },
    },
    PlanDataLokalPlanAflyst: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_lokalplan_aflyst_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
    PlanDataLokalPlanForslag: {
      identDependency: "latLng",
      paramTransformer(ident, data) {
        if ("lat" in ident) {
          let y = GenerateGeom.convertFromLatLngToUTM({
            lat: ident.lat,
            lng: ident.lng,
          });
          return ["pdk:theme_pdk_lokalplan_forslag_v", y];
        }
        return [];
      },
      func: PropertyInfoInterface.getLokalplanData,
      returnTransformer(result) {
        return [ ...(result?.features?.map((a) => a?.properties) ?? []) ];
      },
      hasThis: PropertyInfoInterface,
    },
  };
  
  
  