
export function formatLength(length) {
    let formatter = Intl.NumberFormat("dk-da")
    if (length > 10) { //Kilometers
      return formatter.format(Math.round(length*1000)/1000) + "km"
    }
    if (length > 0.01) { //meters
      length *= 1_000;
      return formatter.format(Math.round(length*100)/100) + "m"
    }
    length *= 100_000; //centimeters
    return formatter.format(Math.round(length*10)/10) + "cm"
  }
  
  export function formatArea(area) {
    if (area > 10_000_000) { //km2
      area /= 1_000_000
      let formatter = Intl.NumberFormat("dk-da")
      return formatter.format(Math.round(area*1000)/1000) + "km2";
    } //m2
    let formatter = Intl.NumberFormat("dk-da")
    return formatter.format(Math.round(area*100)/100) + "m2";
  }
  