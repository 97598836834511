import { FilterType, FilterSpec, FilterValue } from "../states/ExploreSearchState";
import * as turf from '@turf/turf';
import { BBOXTURF } from "./EmbeddedVectorMap";

/**
 * Interface to Elastic Search
 */

export type UTMGridData = UTMGridItem[];

export type UTMGridItem = {
   key:string,
   doc_count:number,
   boxCorner_easting:number,
   boxCorner_norting:number,
   centerUTM32_easting:number,
   centerUTM32_northing:number
}

export class ESInterface {
   private indexName: string = "";
   constructor(index: string) {
      this.indexName = index;
   }

   // runtimeMapping = { "runtime_mappings": {
   //    "gp2": {
   //      "type": "geo_point",
   //      "script": {
   //        "source": `def lng = doc["koord_lng"];
   //        def lat = doc["koord_lat"];
   //        if (lng.size() > 0 && lat.size() > 0) {
   //        emit(lat.value,lng.value);
   //        } else emit(50,9);`
   //      }
   //    }
   //  }
   // };     
   runtimeMapping = {}; 

   async doCount(selectableFilterList: {[id:string]:FilterSpec},
      activeFilters: {[id:string]: FilterValue}): Promise<number> {
      let url = "https://0b9e0238a2734040a4c05af7efae5534.eu-central-1.aws.cloud.es.io";
      url += "/" + this.indexName + "/_count";
      let query = this.makeQuery(selectableFilterList, activeFilters);

      // _Count does not support runtimeMappings

      return fetch(url, {
         method: "POST",
         headers: {
            Authorization: "ApiKey UXFwd3BvVUJQc2VQXy14UUp4cFY6aE0xdzZ6a19TRU8za3pqZF9zOERfdw==",
            "content-type": "application/json"
         },
         body: JSON.stringify(query)

      })
         .then(async (response) => {
            let body = await response.json();
            let countRes = body.count;
            let msg = `There were ${countRes} matches`;
            console.log(msg);

            return countRes;
         });
   }


   /**
    * Fully customizable query
    * @param query 
    * @returns result
    */
   async doQuery(query: any): Promise<any> {
      let url = "https://0b9e0238a2734040a4c05af7efae5534.eu-central-1.aws.cloud.es.io";
      url += "/" + this.indexName + "/_search";
      return fetch(url, {
         method: "POST",
         headers: {
            Authorization: "ApiKey UXFwd3BvVUJQc2VQXy14UUp4cFY6aE0xdzZ6a19TRU8za3pqZF9zOERfdw==",
            "content-type": "application/json"
         },
         body: JSON.stringify(query)
      })
      .then(async (response) => {
         let body = await response.json();
         let result = body;

         return result;
      })
      .catch((err) => {
         console.error("Got error searching: ", JSON.stringify(query), err);
      });
   }
      

   async doSearch(selectableFilterList: {[id:string]:FilterSpec},
      activeFilters: {[id:string]: FilterValue},
      count?:number): Promise<any> {
      let url = "https://0b9e0238a2734040a4c05af7efae5534.eu-central-1.aws.cloud.es.io";
      url += "/" + this.indexName + "/_search";
      let query = this.makeQuery(selectableFilterList, activeFilters);
      if (count) {
         query = {...query, "size":count};
      }
      query = {...this.runtimeMapping, ...query};
      return fetch(url, {
         method: "POST",
         headers: {
            Authorization: "ApiKey UXFwd3BvVUJQc2VQXy14UUp4cFY6aE0xdzZ6a19TRU8za3pqZF9zOERfdw==",
            "content-type": "application/json"
         },
         body: JSON.stringify(query)

      })
      .then(async (response) => {
         let body = await response.json();
         let result = body.hits.hits.map((hit:any) => {return hit._source;} );

         return result;
      })
      .catch((err) => {
         console.error("Got error searching: ", JSON.stringify(query), err);
      });
   }

   async doGetGroups(selectableFilterList: {[id:string]:FilterSpec},
      activeFilters: {[id:string]: FilterValue}): Promise<any> {
      let url = "https://0b9e0238a2734040a4c05af7efae5534.eu-central-1.aws.cloud.es.io";
      url += "/" + this.indexName + "/_search";
      let query = this.makeQuery(selectableFilterList, activeFilters);
      query = {
         ...this.runtimeMapping,
         ...query, 
         "size": 0, // don't return data for hits
         "aggs": {
           "group_by_10km": {
             "terms": {
               "field": "bygningspunkt_DDKNcelle10km.keyword",
               "size": 10000 // return all groups
             }
           }
         }
      }

      return fetch(url, {
         method: "POST",
         headers: {
            Authorization: "ApiKey UXFwd3BvVUJQc2VQXy14UUp4cFY6aE0xdzZ6a19TRU8za3pqZF9zOERfdw==",
            "content-type": "application/json"
         },
         body: JSON.stringify(query)
      })
      .then(async (response) => {
         let body = await response.json();
         let result = body.aggregations.group_by_10km.buckets.map((bkt:any) => {return this.transformBucket(bkt, 10000);} ) || undefined;
         
         return result;
      })
      .catch((err) => {
         console.error("Got error searching: ", JSON.stringify(query), err);
      });
   }

   makeQuery(selectableFilterList: {[id:string]:FilterSpec},
      activeFilters: {[id:string]: FilterValue}):{}|undefined {
      let filters = Object.keys(activeFilters).map((key) => {
         return {
            item: key,
            value: activeFilters[key]
         }
      });
      let filterTerms = filters.reduce<{}[]>((prev, curr, idx) => {
         let {item, value} = curr;
         let filterSpec = selectableFilterList[item];
         if (filterSpec.index_field_override) {
            item = filterSpec.index_field_override;
         }
         let terms = this.makeFilterTerm(item, filterSpec.filterType, value);
         if (terms) {

         }
         return [...prev, ...terms];
      }, []);
      let body = filters.length > 0 ? {
         "query": {
            "bool": {
               "filter": filterTerms
            }
         }
      } : undefined;
   
      return body;
   }

   private makeFilterTerm(item:string, filterType:FilterType, value: FilterValue):{}[] {
      switch (filterType) {
         case FilterType.IntegerRange:
         case FilterType.DateRange:
            let fltr = {};
            if (value && value[0] && value[0] !== undefined) {
               fltr = { ...fltr, "gte": value[0]};
            }
            if (value && value[1] && value[1] !== undefined) {
               fltr = { ...fltr, "lte": value[1]};
            }
            return([{
               "range": {
                  [item]: fltr
               }
            }]);
         case FilterType.BoundingBox: {
               let fltr = {};
               if (value && value.length>3) {
                  let bbox:BBOXTURF=value as BBOXTURF;
                  return([{
                     "range": {
                        ["koord_lat"]: {"gte": bbox[1], lte:bbox && bbox[3]}
                     }
                  },
                  {
                     "range": {
                        ["koord_lng"]: {"gte": bbox[0], lte:bbox && bbox[2]}
                     }
                  }]);
               } else {
                  return [];
               }
            }
            case FilterType.PolygonFromLayer:
            case FilterType.Polygon: {
               let fltr = {};
               if (value && value.length > 0) {
                  return([{
                     "geo_shape": {
                        "geopoint": {
                          "shape": {
                            "type": "polygon",
                            "coordinates": value
                          },
                          "relation": "within"
                        }
                     }
               }]);
               } else {
                  return [];
               }
            }
         case FilterType.StaticValue:
         case FilterType.Integer:
         case FilterType.SingleSelect:
         case FilterType.Select:
               if (value && value.length > 1) {
                  return ([{
                     terms: {
                     [item]: value
                  }}])
               }
               if (value && value[0]) {
                  return ([{
                     term: {
                        [item]: value && value[0] || ""
                     }
                  }]);   
               } 
               return []
               
         case FilterType.SelectText:
            if (value && value.length > 1) {
               // multiselect
               return ([{
                  terms: {
                     [item+".keyword"]: value
                  }
               }]);
               } 
               if (value && value[0]) {
               // singleselect
               return ([{
               match: {
                  [item]: value && value[0] || ""
               }
            }]);
            }
            return []
         case FilterType.String:
               if (value && value[0]) {
                  let parts = value[0].split(" ");
                  return parts.map((p) => {return {
                     match: {
                        [item]: p
                     }
                  }});
               }
               return ([])
         case FilterType.IntegerSet: 
         if (value && value.length > 0) {
            if (value.length > 65536) {
               throw new Error(`Maximum 65,536 terms in a request has been exceeded`);
            }
            return ([
                  {
                     terms: {
                        [item]: value
                     }
                  }
         ])
         }
            // if (value && value.length > 0) {
            //    return ([{
            //       "bool": {
            //         "should": 
            //       value.map((val) => {
            //          return {
            //             term: {
            //                [item]: ""+val || ""
            //             }
            //          }
            //       })
            //    }
            //    }
            // ])
            // }
            return ([])

         default:
            throw new Error("Unexpected filterType:"+filterType);
      }
   }

   private transformBucket(bkt:any, boxSizeMeters:number): UTMGridItem{
      let elms = bkt.key.split("_");
      let norting = elms.length >= 3 ? elms[1]*boxSizeMeters : 0;
      let easting = elms.length >= 3 ? elms[2]*boxSizeMeters : 0;
      return {
         key:bkt.key,
         doc_count: bkt.doc_count,
         boxCorner_norting:norting,
         boxCorner_easting:easting,
         centerUTM32_northing: norting && norting + boxSizeMeters/2,
         centerUTM32_easting: easting && easting + boxSizeMeters/2
      }
   }

}