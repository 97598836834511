import * as M from 'maplibre-gl';

export class MaplibreMapFunc {
   map: M.Map | undefined;

   public static initMap(id:any, options:any={}):Promise<M.Map> {

      return new Promise<M.Map>((resolve, reject) => {

         let protocol="https:";
         let userinfo="mapit";
   
         /* tslint:disable-next-line */
         let token="eyJkcGZ4IjogIm1hcGl0Z2xvYmFsIiwgInZlciI6ICIyIiwgInByaXZzIjogInIxWjByMEYwazZCdFdxUWNPVXlrQi95NlNVcEp2MlFiZ3lYZXRxNEhZNFhPLzNZclcwK0s5NjRIWTRYMVdPeHJSQU53V1E9PSJ9.R83KpfTlM0cpNxsbzbIjlW+srr1gwrEgJjuYQFblbLdacfT0Hnb4hQALt2WdGjPlN9a+ukt+3nJKKfjPeh+3CA";
         /* tslint:disable-next-line */
         let isOrthophotoGlobal=false;
         let style = Boolean(isOrthophotoGlobal) ? 'https://mapit2-data.s3.eu-central-1.amazonaws.com/VectorStyling/mapitstyleglobal.json' : 
                                               'https://mapit2-data.s3.eu-central-1.amazonaws.com/VectorStyling/mapitstyle.json';
   
         var map2 = new M.Map({
            container: id,
            style: style,
            hash: true,
            transformRequest: (url, resourceType)=> {
               if(resourceType === 'Tile' && url.startsWith('http://13.48.91.12:8080/geoserver')) {
                 return {
                  url: url,
                  headers: { 'Origin': '*' }
                };
               } else {
                  return {
                     url: url,
                   };   
               }
             },...options        
         });

         map2.on("load", (evt) => {
   
            // this.initEventManagers(map2);
   
            resolve(map2);
         });
      });
   }

   public initEventManagers(map:M.Map) {
      // map.on('styleimagemissing', (e:any) => {
      //    let id = e.id; // id of the missing image
          
      //    map.loadImage(id, (error, image) => {
      //       if (error) {
      //          // throw error;
      //          console.log("Got error loading image: "+id+" :"+ error.message);
      //       } else {
      //          map.addImage(id, image);
      //       }
      //    });
      // });

      // map.on('rotate', () => {
      //    this.rotationCallbackHandler && this.rotationCallbackHandler(-map.getBearing());
      //    });
   }

}