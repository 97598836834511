import { useEffect, useState } from "react"
import { AddressInterface } from "src/managers/AddressInterface"
import { MitLatLng } from "src/managers/MapFacade"
import { PropertyInformationDialog } from "./PropertyInformationDialog"
import { NavigationBarSimple } from "./NavigationBarSimple"
import { PropertyInfoView } from "src/propertyInfoTemplates/PropertyInfoView"
import { PersonInfoView } from "./PersonInformationView"
import { lookupAndShowMatrikel } from "src/managers/RestoreMapLink"
import { CadasterInterface } from "src/managers/CadasterInterface"
import { Localization } from "@viamap/viamap2-common"


export function PropertyInfoQuery() {
  let [labelLatLng, setLabelLatLng] = useState<MitLatLng>()
  let [getError, setError] = useState("")
  const [query] = useState(new URLSearchParams(new URL(document.URL).search))
  
  useEffect(() => {
    if (query.has("latLng")) {
      let [lat,lng] = query.get("latLng")!.split(",").map((a) => parseFloat(a))
      setLabelLatLng(new MitLatLng(lat,lng))
    }else if (query.has("addr")) {
      AddressInterface.searchSingleDawaDirect(query.get("addr")!).then((a) => {
        if (a.result.latlng) {
          let [lat,lng] = a.result.latlng
          setLabelLatLng(new MitLatLng(lat,lng))
        }
      })
    } else if (query.has("ejerlav") && query.has("matrnr")) {
      let [ejerlavkode, matrikelnr] = [query.get("ejerlav"), query.get("matrnr")] as [string, string]
      CadasterInterface.searchMatrikel(ejerlavkode, matrikelnr)
      .then((result) => {
        console.log("Got: " + JSON.stringify(result));
        if (result.error || !result.data || (result.data && result.data.length === 0)) {
          getError = Localization.getFormattedText("Cadaster not found ({ejerlavkode} {matrikelnr})", { ejerlavkode: ejerlavkode, matrikelnr: matrikelnr });
        } else {
          let item = result.data[0];
          let lat = item.visueltcenter[1];
          let lng = item.visueltcenter[0];
          setLabelLatLng(new MitLatLng(lat,lng))
        }
      });
    } else {
      setError("Missing query")
    }
  },[])
  
  if (getError) {
    return "Error" + getError
  }

  if (!labelLatLng) {
    return "Loading"
  }

  return (
    <NavigationBarSimple title="Ejendoms info" >
    <div className='Paper-MitProp'>
    <div className='Container-MitProp PropertyInfoDestroyer' style={{padding:"0.5cm"}}>
    <PropertyInfoView labelLatLng={{latlng : labelLatLng}} foldetUd={true} cols={1} allowExtendedInformation={true} />
    </div>
    </div>
    </NavigationBarSimple>
  )
}

export function PersonInfoQuery() {
let [date, setDate] = useState<Date>()
let [name, setName] = useState<string>()
let [getError, setError] = useState("")
const [query] = useState(new URLSearchParams(new URL(document.URL).search))

useEffect(() => {
  if (query.has("name") && query.has("date")) {
    let name = (query.get("name")!)
    let date = new Date(query.get("date")!)
    setName(name)
    setDate(date)
  } else {
    setError("Missing query")
  }
},[])

if (getError) {
  return "Error" + getError
}

if (!(name && date)) {
  return "Loading"
}

return (
  <NavigationBarSimple title="Ejendoms info" >
  <div className='Paper-MitProp'>
  <div className='Container-MitProp PropertyInfoDestroyer' style={{padding:"0.5cm"}}>
  <PersonInfoView personInfo={{navn:name, foedselsdato:date}} foldetUd={true} cols={1} />
  </div>
  </div>
  </NavigationBarSimple>
)
}