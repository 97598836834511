import { FilterType } from "src/states/ExploreSearchState"
import { ESInterface } from "./ESInterface"
import { NodeType, OwnerShipStructureData } from "./OwnershipStructure"
import { PropertyInfoInterface } from "./PropertyInfoInterface"
import { SettingsManager } from "@viamap/viamap2-common"
import { NodeDataType } from "aws-sdk/clients/iotfleetwise"

export type GraphNodeData = {
   title: string,
   entityId: number,
   type: NodeType,
   expandedAsDefault: boolean,
   isCollapsed: boolean,
   pct: number,
   lat:number,
   lng:number,
   ejendomsType: string,
   countResult: CountResult
}

export type GraphNode = {
   data: GraphNodeData,
   children: GraphNode[]
}

export type CountResult = {
   virksomheder: number, 
   ejendomme: number,
   samletFastEjendom: number,
   bygnFremmedGrund: number,
   ejerlejlighed: number
}
export class OwnershipStructureTopDown {
   
   public countTree(node: GraphNode): CountResult {
      let nodeCount = { 
         virksomheder: node.data.type != "Ejendom" ? 1 : 0, 
         ejendomme: node.data.type === "Ejendom" ? 1 : 0,
         samletFastEjendom: node.data.ejendomsType === "SamletFastEjendom" ? 1 : 0,
         bygnFremmedGrund: node.data.ejendomsType === "BygningPaaFremmedGrund" ? 1 : 0,
         ejerlejlighed: node.data.ejendomsType === "Ejerlejlighed" ? 1 : 0,
      }
      let childCount = node.children.reduce<CountResult>((result:CountResult, chld:GraphNode) => {
         let childCount = this.countTree(chld);
         return { 
            virksomheder: result.virksomheder + childCount.virksomheder, 
            ejendomme: result.ejendomme + childCount.ejendomme,
            samletFastEjendom: result.samletFastEjendom + childCount.samletFastEjendom,
            bygnFremmedGrund: result.bygnFremmedGrund + childCount.bygnFremmedGrund,
            ejerlejlighed: result.ejerlejlighed + childCount.ejerlejlighed
         }
      }, { virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund:0, ejerlejlighed:0 });
      return { 
         virksomheder: nodeCount.virksomheder + childCount.virksomheder, 
         ejendomme: nodeCount.ejendomme + childCount.ejendomme ,
         samletFastEjendom: nodeCount.samletFastEjendom + childCount.samletFastEjendom,
         bygnFremmedGrund: nodeCount.bygnFremmedGrund + childCount.bygnFremmedGrund,
         ejerlejlighed: nodeCount.ejerlejlighed + childCount.ejerlejlighed
   };
   }

   static extractBFENumbers(node: GraphNode): number[] {
      if (node.data.type === "Ejendom") {
         return [node.data.entityId];
      } else {
         return node.children.reduce<number[]>((result, child) => {
            return [...result, ...this.extractBFENumbers(child)];
         }, []);
      }     
   }

   public checkTree(node: GraphNode): boolean {
      return this._checkTree(node, 1, 0);
   }

   public _checkTree(node: GraphNode, depth: number, index: number): boolean {
      let position = `depth ${depth} index ${index}`;
      if (!node) throw new Error(`Node is undefined ` + position);
      if (!node.data) throw new Error(`Node has no data` + position);
      if (!(node.data.entityId && node.data.title && node.data.type)) throw new Error(`Node data is missing properties ${JSON.stringify(node.data)} ` + position);
      node.children && node.children.forEach((chld, idx) => {
         this._checkTree(chld, depth + 1, idx);
      })
      return true;
   }

   public async createTree(data: GraphNodeData): Promise<GraphNode> {
      let x = this._createTree(data, [], data.title, 1);
      return x
   }

   private async _createTree(data: GraphNodeData, cvrAlreadyMapped: number[], path: string, level:number): Promise<GraphNode> {
      // console.log("CreateTree", data.entityId, data.title, data.type, path);
      const MAX_CHILDREN_DEFAULTEXPANDED = SettingsManager.getSystemSetting("ExplorerOwnerShipDiagramAutoCollapseOnChildrenCount",10);
      const MAX_ALLWAYS_EXPANDED_LEVEL = SettingsManager.getSystemSetting("ExplorerOwnerShipDiagramAutoCollapseOnlyAfterLevel",3);
   
      return new Promise(async (resolve, reject) => {

         let nodeCount = OwnershipStructureTopDown.constructCount(data);
         data.countResult = nodeCount;

         try {
            switch (data.type) {

               case "Ejendom":
                  // Nothing to do
                  resolve({
                     data,
                     children: []
                  });
                  break;


               case "Person":
                  
                  
                  resolve({
                     data,
                     children: []
                  });
                  break;

               case "Aktieselskab":
               case "Firma":
                  if (cvrAlreadyMapped.includes(data.entityId)) {
                     console.log(`-------- CVR ${data.entityId} is already mapped!`)
                     resolve({
                        data: { ...data, type: "Link" },
                        children: []
                     });
                  } else {

                     // Find and return any buildings owned by this CVR
                     let propertyDataPromise = new Promise((resolve, reject) => {
                        this.getPropertiesOwnedByCVR(data.entityId)
                           .then((properties) => {
                              resolve((properties || []) && properties.map((prop) => {
                                 let data:GraphNodeData = {
                                    entityId: prop.bfe_nr || "bfe",
                                    title: prop.bfe_adresse || `bfe: ${prop.bfe_nr}`,
                                    ejendomsType: prop.bfe_ejendomsType,
                                    type: "Ejendom",
                                    lat: prop.koord_lat,
                                    lng: prop.koord_lng,
                                    expandedAsDefault:true,
                                    isCollapsed: true,
                                    pct: 0,
                                    countResult: {
                                       virksomheder: 0, 
                                       ejendomme: 0,
                                       samletFastEjendom: 0,
                                       bygnFremmedGrund: 0,
                                       ejerlejlighed: 0
                                    }
                                 };
                                 data.countResult = OwnershipStructureTopDown.constructCount(data);
                                 return {
                                    data: data,
                                    children: []
                                 }
                              })
                              )
                           })
                           .catch((err) => reject("Got error getting properties for cvr " + (err.message || err)))
                     })

                     let ownedCompanies = await PropertyInfoInterface.getCVRSearch({
                        "_source": [
                           "Vrvirksomhed.cvrNummer",
                           "Vrvirksomhed.deltagerRelation.deltager",
                           "Vrvirksomhed.deltagerRelation.organisationer",
                           "Vrvirksomhed.virksomhedMetadata.nyesteNavn.navn",
                           "Vrvirksomhed.virksomhedMetadata.nyesteBeliggenhedsadresse",
                           "Vrvirksomhed.virksomhedMetadata.nyesteVirksomhedsform.kortBeskrivelse"
                        ],
                        "size": SettingsManager.getSystemSetting("OwnershipGraphMaxOwnedCompanies") ,
                        "query": {
                           "bool": {
                              "must": [
                                 {
                                    "nested": {
                                       "path": "Vrvirksomhed.livsforloeb",
                                       "query": {
                                          "bool": {
                                             "must_not": [
                                                {
                                                   "exists": {
                                                      "field": "Vrvirksomhed.livsforloeb.periode.gyldigTil"
                                                   }
                                                }
                                             ]
                                          }
                                       }
                                    }
                                 },
                                 {
                                    "nested": {
                                       "path": "Vrvirksomhed.deltagerRelation",
                                       "query": {
                                          "bool": {
                                             "must": [
                                                {
                                                   "term": {
                                                      "Vrvirksomhed.deltagerRelation.deltager.forretningsnoegle": data.entityId
                                                   }
                                                },
                                                {
                                                   "nested": {
                                                      "path": "Vrvirksomhed.deltagerRelation.organisationer",
                                                      "query": {
                                                         "bool": {
                                                            "must": [
                                                               {
                                                                  "match": {
                                                                     "Vrvirksomhed.deltagerRelation.organisationer.hovedtype": "REGISTER"
                                                                  }
                                                               },
                                                               {
                                                                  "nested": {
                                                                     "path": "Vrvirksomhed.deltagerRelation.organisationer.organisationsNavn",
                                                                     "query": {
                                                                        "bool": {
                                                                           "must": [
                                                                              {
                                                                                 "match": {
                                                                                    "Vrvirksomhed.deltagerRelation.organisationer.organisationsNavn.navn": "EJERREGISTER"
                                                                                 }
                                                                              }
                                                                           ]
                                                                        }
                                                                     }
                                                                  }
                                                               },
                                                               {
                                                                  "nested": {
                                                                     "path": "Vrvirksomhed.deltagerRelation.organisationer.medlemsData.attributter",
                                                                     "query": {
                                                                        "bool": {
                                                                           "must": [
                                                                              {
                                                                                 "match": {
                                                                                    "Vrvirksomhed.deltagerRelation.organisationer.medlemsData.attributter.type": "EJERANDEL_PROCENT"
                                                                                 }
                                                                              },
                                                                              {
                                                                                 "nested": {
                                                                                    "path": "Vrvirksomhed.deltagerRelation.organisationer.medlemsData.attributter.vaerdier",
                                                                                    "query": {
                                                                                       "bool": {
                                                                                          "must_not": [
                                                                                             {
                                                                                                "exists": {
                                                                                                   "field": "Vrvirksomhed.deltagerRelation.organisationer.medlemsData.attributter.vaerdier.periode.gyldigTil"
                                                                                                }
                                                                                             }
                                                                                          ]
                                                                                       }
                                                                                    }
                                                                                 }
                                                                              }
                                                                           ]
                                                                        }
                                                                     }
                                                                  }
                                                               }
                                                            ]
                                                         }
                                                      }
                                                   }
                                                },

                                                {
                                                   "nested": {
                                                      "path": "Vrvirksomhed.deltagerRelation.organisationer",
                                                      "query": {
                                                         "bool": {
                                                            "must": [
                                                               {
                                                                  "match": {
                                                                     "Vrvirksomhed.deltagerRelation.organisationer.hovedtype": "REGISTER"
                                                                  }
                                                               },
                                                               {
                                                                  "nested": {
                                                                     "path": "Vrvirksomhed.deltagerRelation.organisationer.organisationsNavn",
                                                                     "query": {
                                                                        "bool": {
                                                                           "must": [
                                                                              {
                                                                                 "match": {
                                                                                    "Vrvirksomhed.deltagerRelation.organisationer.organisationsNavn.navn": "EJERREGISTER"
                                                                                 }
                                                                              }
                                                                           ]
                                                                        }
                                                                     }
                                                                  }
                                                               }
                                                            ]
                                                         }
                                                      }
                                                   }
                                                }

                                             ]
                                          }
                                       }
                                    }
                                 }
                              ]
                           }
                        }
                     }
                     )

                     let childData = this.extractRelevantChildData(ownedCompanies, data.entityId);
                     // console.log(">>>>> children", childData && childData.length);
                     let cvrPromises: Promise<any>[] = [];
                     if (childData.length === 0) {
                        // No children companies
                     } else {
                        // collect data for each child
                        // some companies own own shares - filter them
                        cvrPromises = childData.filter((chld) => chld.cvrNr != data.entityId).map(async (chld) => {
                           return new Promise((resolve, reject) => {
                              let chldData: GraphNodeData = {
                                 title: chld.navn,
                                 entityId: chld.cvrNr,
                                 type: chld.virksomhedsForm === "A/S" ? "Aktieselskab" : "Firma",
                                 pct: chld.ejeranddelPct,
                                 expandedAsDefault: true,
                                 isCollapsed: true,
                                 ejendomsType: "", 
                                 lat: 0,
                                 lng: 0,
                                 countResult: {
                                    virksomheder: 0, 
                                    ejendomme: 0,
                                    samletFastEjendom: 0,
                                    bygnFremmedGrund: 0,
                                    ejerlejlighed: 0
                                 }
                              }
                              chldData.countResult = OwnershipStructureTopDown.constructCount(chldData);
                              this._createTree(chldData, [...cvrAlreadyMapped, data.entityId], path + " / " + chld.navn, level+1)
                                 .then((subTree => {
                                    // inserted into array to match format of propertyDataPromise.
                                    resolve([subTree]);
                                 }))
                                 .catch((err) => reject(`Got error getting subtree for cvr ${chld.cvrNr}` + (err.message || err)))
                           })
                        });
                     }
                     Promise.all([...cvrPromises, propertyDataPromise])
                        .then((results) => {
                           // console.log("Results", JSON.stringify(results));

                           // combine arrays of results into one array
                           let chldrn:GraphNode[] = results.reduce((result, curr) => {
                              return [...result, ...curr];
                           }, []);
                           data.expandedAsDefault = chldrn.length <= MAX_CHILDREN_DEFAULTEXPANDED || level <= MAX_ALLWAYS_EXPANDED_LEVEL;
                           let childCount = chldrn.reduce<CountResult>((result:CountResult, chld:GraphNode) => {
                              return { 
                                 virksomheder: result.virksomheder + (chld.data.countResult?.virksomheder || 0), 
                                 ejendomme: result.ejendomme + (chld.data.countResult?.ejendomme || 0),
                                 samletFastEjendom: result.samletFastEjendom + (chld.data.countResult?.samletFastEjendom || 0),
                                 bygnFremmedGrund: result.bygnFremmedGrund + (chld.data.countResult?.bygnFremmedGrund || 0),
                                 ejerlejlighed: result.ejerlejlighed + (chld.data.countResult?.ejerlejlighed || 0)
                              }
                           }, { virksomheder: 0, ejendomme: 0, samletFastEjendom: 0, bygnFremmedGrund:0, ejerlejlighed:0 });
                           let countResult = { 
                              virksomheder: nodeCount.virksomheder + childCount.virksomheder, 
                              ejendomme: nodeCount.ejendomme + childCount.ejendomme ,
                              samletFastEjendom: nodeCount.samletFastEjendom + childCount.samletFastEjendom,
                              bygnFremmedGrund: nodeCount.bygnFremmedGrund + childCount.bygnFremmedGrund,
                              ejerlejlighed: nodeCount.ejerlejlighed + childCount.ejerlejlighed
                           };
                           data.countResult = countResult;
                     
                           resolve({
                              data,
                              children: chldrn
                           });
                        })
                  }

                  break;

               default:
                  throw new Error("Unexpected state");
            }
         } catch (error: any) {
            reject(error);
         }

      })

   }

   
   static constructCount(data:GraphNodeData) {
      return {
         virksomheder: (data.type != "Ejendom" && data.type != "Person") ? 1 : 0,
         ejendomme: data.type === "Ejendom" ? 1 : 0,
         samletFastEjendom: data.ejendomsType === "SamletFastEjendom" ? 1 : 0,
         bygnFremmedGrund: data.ejendomsType === "BygningPaaFremmedGrund" ? 1 : 0,
         ejerlejlighed: data.ejendomsType === "Ejerlejlighed" ? 1 : 0,
      }
   }

   public extractRelevantChildData(rawCVRREturn: any, targetCvrNr: number): any {
      function getActiveRecord(recs: { periode: { gyldigFra: string, gyldigTil: string } }[]): any {
         return recs.find((r) => r.periode.gyldigTil === null);
      }

      let result: any[] = [];

      if (rawCVRREturn.hits) {
         if (rawCVRREturn.hits.total === 0) {
            return result;
         } else {
            result = rawCVRREturn.hits.hits.map((hit) => {
               let virk = hit._source.Vrvirksomhed;
               let ejeranddelPct: number = 0;
               if (virk.deltagerRelation) {
                  virk.deltagerRelation.forEach((dr) => {
                     if (dr.deltager && dr.deltager.forretningsnoegle === targetCvrNr) {
                        dr.organisationer.forEach((org) => {
                           if (org.hovedtype === "REGISTER") {
                              let navnRec = getActiveRecord(org.organisationsNavn);
                              if (navnRec && navnRec.navn === "EJERREGISTER") {
                                 org.medlemsData.forEach((md) => {
                                    md.attributter.forEach((att) => {
                                       if (att.type === "EJERANDEL_PROCENT") {
                                          let vrdAtt = getActiveRecord(att.vaerdier);
                                          if (vrdAtt && vrdAtt.vaerdi) {
                                             let vrd = Number.parseFloat(vrdAtt.vaerdi);
                                             if (ejeranddelPct) {
                                                throw new Error("Ejeranddel pct already set")
                                             }
                                             ejeranddelPct = vrd * 100.0;
                                             // console.log("Ejeranddel", ejeranddelPct)
                                          }
                                       };
                                    })
                                 })
                              }
                           }
                        });
                     }
                  })
               }
               if (ejeranddelPct === 0) {
                  let x = "no pct found!";
               }
               return {
                  "cvrNr": virk.cvrNummer,
                  "navn": virk.virksomhedMetadata.nyesteNavn.navn,
                  "ejeranddelPct": ejeranddelPct,
                  "virksomhedsForm": virk.virksomhedMetadata.nyesteVirksomhedsform.kortBeskrivelse
               }
            });
            return result;
         }

      } else {
         return { message: "Unexpected record format" }
      }
   }

   private async getPropertiesOwnedByCVR(cvrNr: number): Promise<any[]> {
      const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
      let ifx = new ESInterface(elasticIndex);
      let tmpFilters = { "ejf_cvrnr": { title: "cvr", filterType: FilterType.Integer, default: [""], translationTable: {} } };
      let tmpFiltersValues = { "ejf_cvrnr": [cvrNr] };
      let items = await ifx.doSearch(tmpFilters, tmpFiltersValues, SettingsManager.getSystemSetting("maxPropertiesFromElasticsearch"));
      // console.log(">>>>>> Searching for cvr", cvrNr, items && items.length);
      return items;
   }

}
