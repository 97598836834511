import CryptoJS from 'crypto-js';
import { Localization, Utils } from '@viamap/viamap2-common';
import react, { useContext, useEffect, useReducer } from "react";
import { MapCommandType, MapCommand, SessionInfo, UserSession, AppMode } from "src/common/managers/Types";
import MapScreen from './MapScreen';
import { SettingsManager } from '@viamap/viamap2-common';
import { GenericTransactionManager, MapitStateActions, MapitStateContext, MapitStateReducer, MapitWindowId, initialMapitState, transactionalMapitStateReducer, windowToBeShownImpl, windowToBeShownParametersImpl } from 'src/states/MapitState';
import FileImporter from './FileImporter';
import { ApplicationStateContext, Feature, FeatureType, actionAddAvailableFeature, actionSetAppMode, actionUpdateAvailableFeatures } from 'src/states/ApplicationState';
import { ProtectedFeature } from './ProtectedFeature';



export function DeepLinkManager(props:{children: JSX.Element}) {
  let [link, setLink] = react.useState("")
  let [commandList, setCommandList] = react.useState<MapCommand[]>([])
  const {state: applicationState, dispatch: applicationDispatch} = useContext(ApplicationStateContext)
  const [MapitStateState, MapitStateDispatch] = useReducer(MapitStateReducer, initialMapitState(document.body.clientWidth > 900));

  const [showLayerList, setShowLayerList] = react.useState(false)

  react.useEffect(() => {
    if (!showLayerList) {
      const event = (ev: TouchEvent) => {
        if (ev.touches[0].clientX > document.body.clientWidth - 10) {
          ev.preventDefault?.()
          // ev.stopPropagation?.()
          // ev.stopImmediatePropagation?.()

          let cX = ev.touches[0].clientX
          let cY = ev.touches[0].clientY
          
          const stopEvent = (ev: TouchEvent) => {
            let csX = ev.changedTouches[0].clientX
            let csY = ev.changedTouches[0].clientY
            if (Math.abs(cX - csX) > 2*Math.abs(cY - csY) && cX > csX) {
              setShowLayerList(true)
            }
            window.removeEventListener("touchend", stopEvent)
          }
          window.addEventListener("touchend", stopEvent,{passive:false})
          return false
        }
      }
      window.addEventListener("touchstart",event,{passive:false})
      return () => {
        window.removeEventListener("touchstart",event)
      }
    } else {
      const event = (ev: TouchEvent) => {
        let offset = document.getElementsByClassName("Sidebar-Right")[0].clientLeft
        if (ev.touches[0].clientX > offset) {
          let cX = ev.touches[0].clientX
          let cY = ev.touches[0].clientY
          
          const stopEvent = (ev: TouchEvent) => {
            let csX = ev.changedTouches[0].clientX
            let csY = ev.changedTouches[0].clientY
            if (Math.abs(cX - csX) > 2*Math.abs(cY - csY) && cX < csX && Math.abs(cX - csX) > 50) {
              setShowLayerList(false)
            }
            window.removeEventListener("touchend", stopEvent)
          }
          window.addEventListener("touchend", stopEvent,{passive:false})
        }
      }
      window.addEventListener("touchstart",event,{passive:false})
      return () => {
        window.removeEventListener("touchstart",event)
      }
    }
  },[showLayerList])


  react.useEffect(() => {
    let params = new URL(window.location.href).searchParams;
    let mapRef = params.get("mapdef");
    let embeddedMode = params.has('embeddedmode') && (params.get('embeddedmode') !== "0" && params.get('embeddedmode') !== "false");
    
    let readOnlyMapData = {}

    
    if (mapRef) {
      // Store user values to user sesion
      let userRoles = ["Basic", "DeviceLocation", "POI"];

      // expand features from the roles, such that we can add individual features as required
      let featureSet:FeatureType[] = [];  // ToDo: Fix feature set.

      setLink(mapRef)
      if (SettingsManager.getSystemSetting("shortLinks", false)) {
        readOnlyMapData = {ShortLink:mapRef};
      } else {
        // Saved map parsed in URL parameter
        mapRef = (mapRef && Array.isArray(mapRef))? mapRef[0] : mapRef;
        let ciphertext = mapRef && decodeURIComponent(mapRef);
        if (ciphertext) {

          let encryptionKey = SettingsManager.getSystemSetting("95eb3b20-844c-4cab-9150-b56c36ed0525", "secret key 123");
          // Decrypt
          var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), encryptionKey);
          readOnlyMapData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        }
        // Clear URL parameters - not to get too long URL for tile loading... 
        var newURL = location.href.split("?")[0];
        window.history.pushState('object', document.title, newURL);
      }
      if (params.get("bgmode")) {
        let mode = parseInt(params.get("bgmode")!);
        const allowedModes = [
          Feature.BackgroundLayerCadaster, //1 
          Feature.BackgroundLayerCadasterPoints, //2 
          Feature.BackgroundLayerLocalPlans, //4 
          Feature.BackgroundLayerZoning, //8 
          Feature.BackgroundLayerDAI, // 16 
          Feature.MapStyleAerial, // 32 
          Feature.BackgroundLayerJordforureningV1, //64 
          Feature.BackgroundLayerJordforurening_NuanceringV2 // 128 
        ]
        let i = 0;
        while (mode > 0) {
          if (mode & 1) {
            console.log(allowedModes[i])
          }
          mode = mode >> 1
          i += 1
        }
      }
      applicationDispatch(actionUpdateAvailableFeatures(userRoles));
      applicationDispatch(actionSetAppMode(AppMode.ReadOnly));

    } else {
      if (params.get("ejerlav") && params.get("matrnr")) {
        setLink("Matrikel Opslag")
        setCommandList([{
          cmd: MapCommandType.DisplayCadaster,
          params:[params.get("ejerlav") ?? "",params.get("matrnr") ?? ""] as [string,string]
        }]);
        let userRoles = ["Basic", "DeviceLocation", "POI"]
        applicationDispatch(actionUpdateAvailableFeatures(userRoles));
        applicationDispatch(actionSetAppMode(AppMode.ReadOnly));

      }
    }
    if (embeddedMode) {
      applicationDispatch(actionSetAppMode(AppMode.Embedded));
    }
  }, [])

  if (link === "" && !commandList.length) {
    return props.children
  }
  return (
    <MapitStateContext.Provider
          value={{
            state: MapitStateState,
            dispatch: GenericTransactionManager.dispatchMiddleware<MapitStateActions>(MapitStateDispatch, transactionalMapitStateReducer),
            windowToBeShownOrder: (windowId: MapitWindowId) => {
              return windowToBeShownImpl(MapitStateState, windowId);
            },
            windowToBeShownParameters: (windowId: MapitWindowId) => {
              return windowToBeShownParametersImpl(MapitStateState, windowId);
            }
          }}
    >
    <FileImporter >
    {/* <div className='mapApplication S_Map_Layer'> */}
    <div className={showLayerList ? 'S_Map_Layer' :""} style={{height:"100%", width:"100%", display:"flex"}}>
    <ProtectedFeature feature={Feature.ShowLayerControls} >
    <GrabButton onClick={setShowLayerList} showLayerList={showLayerList} observeClass={"Sidebar-Right"} />
    </ProtectedFeature>
    <MapScreen callbackToLogout={() =>console.log("User not Logged in")}
      callBackMap={(a) => console.log("")}
      showTrialSignupDialog={false}
      callBackOnTrialSignup={()=>console.log("SSS")}
      mapReference={link}
      commandList={commandList}
      />
      </div>
      </FileImporter>
      </MapitStateContext.Provider>
  )
}

function GrabButton(props: {showLayerList, onClick, observeClass}) {
  const [UpdateState, sUS] = react.useState(0)

  useEffect(() => {
    let observer = new ResizeObserver(() => {
      sUS((a) => a+1)
    })
    observer.observe(document.getElementsByClassName(props.observeClass)[0])
  }, [props.observeClass])

  return (
    <button className='GrabButton' style={{position: "absolute", right: props.showLayerList ? (document.getElementsByClassName(props.observeClass)[0].clientWidth + "px") : "0px" , top: "20%", zIndex: 1000}} onClick={(() => props.onClick((a) => !a))}>
      <div className='GrabButton-text' >{Localization.getText("Layers")}</div>
      <div>||</div>
    </button>
  )
}