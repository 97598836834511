import * as React from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { Utils } from "@viamap/viamap2-common";
import { MitBody, MitButton, MitDialog, MitFooter, MitInfo } from "./ComponentUtils";

type Props = {
  showWindow: number;
  onFormClose: () => void;
};

export function InfoScreen(props: Props) {
  function onClickClose() {
    props.onFormClose();
  }
  if (props.showWindow) {
    let contactInfo = SettingsManager.getSystemSetting("contactInformation");
    let linkToManual = contactInfo.linkToManual || "";
    let productInformationPage = contactInfo.productInformationPage || "";
    let contactPerson = contactInfo.contactPerson || "";
    let contactPhone = contactInfo.contactPhone || "";
    let contactEmail = contactInfo.contactEmail || "";
    let bodyTextWithPlaceHolders = Localization.getText(
      "InfoScreen:InfoTextHTML"
    );
    let bodyText = Utils.formatString(bodyTextWithPlaceHolders, {
      linkToManual,
      productInformationPage,
      contactPerson,
      contactPhone,
      contactEmail,
    });
    return (
        <MitDialog show={props.showWindow} style={{width:"1000px", left:"50%", transform:"translateX(-50%)", top:"1rem"}}>
          {/* <Modal.Header>
            </Modal.Header> */}

          <MitBody>
            <MitInfo>
              <h4>{Localization.getText("Info Page")}</h4>
            </MitInfo>
            <div dangerouslySetInnerHTML={{ __html: bodyText }} />
            {/* I denne <strong><a href={linkToManual} target="_blank">brugerguide</a></strong> kan du få hjælp til Mapit.
<p/>
Mapit er udviklet af korteksperterne i Viamap/Mølbak.
Læs mere om os på <a href="http://www.viamap.net" target="_blank">www.viamap.net</a> og <a href="http://www.molbak.dk" target="_blank">www.molbak.dk</a>
<p/>
Vedr. licens til adgang til Mapit eller andre spørgsmål,
kontakt Områdechef Thomas K Kristensen på tlf. 54551560 eller på <a href="mailto:tkk@molbak.dk">tkk@molbak.dk</a>
<p/>
Vedr. Databeskyttelse.
<br/>
Ingen af dine data gemmes på vores servere eller deles med andre. Alle dine data slettes når du lukker browseren ned.
<br/>
Vi gemmer logs og fejlmeddelelser udelukkende til intern brug, for at forbedre produktet. */}
          </MitBody>

          <MitFooter>
            <div id="mit-info-screen-version" style={{marginRight:"auto"}}>
              Version:{" "}
              {Utils.productNameAndVersion(process.env.REACT_APP_ENVIRONMENT)}
            </div>
            <MitButton variant="close" onClick={() => onClickClose()}>
              {Localization.getText("Close")}
            </MitButton>
          </MitFooter>
        </MitDialog>
    );
  } else {
    return null;
  }
}
