import { SimpleTranslationTable } from "@viamap/viamap2-common"
import { extend } from "jquery"
import { typeOf } from "maplibre-gl"
import { CustomLayerSpecs, FeatureLayer, StandardLayerSpecs, customLayerDefault } from "./WmsLayerFunc"
import { TbHomeSearch } from "react-icons/tb"

type updatedProperties = {
  type: "paint"|"layout",
  prop: string,
  value: any,
}

type Variants = {
  title: string,
  layers: {
    'type':string,
    'paint': object,
    'layout': object,
  }[],
}

type VectorLayerSpec = {
  licenseFeature:string,
  label: string,
  type: "vectorTile",
  group: string,
  sourceID: string,
  sources: {
    tiles: string[]
  }
  layers: {
    'type':string,
    'paint': object,
    'layout': object,
  }[],
  minZoom?: number,
  attrib?: string,
  legend: boolean|string,
  propertiesToDisplay?: string[]
  translationTable?: SimpleTranslationTable,
  variantSelectors?: any,
  variantValues?: any,
}


function EmptyString<T extends object,E extends keyof T & (string | number)>(key: E, object:T): object is (T & Record<E, string>) {
  return (key in object && typeof object[key] === "string" && object[key] !== "")
}

function isObject(T: unknown): T is Partial<VectorLayerSpec> {
  return (typeof T === "object")
}

export function isVectorLayer(layer: unknown): layer is VectorLayerSpec {
  if (!isObject(layer)) {
    return false
  }
  if (!EmptyString("label", layer)) {
    return false
  }
  if (!(EmptyString("type", layer) && layer.type === "vectorTile")) {
    return false
  }
  if (!EmptyString("group", layer)) {
    return false
  }
  return true 
}

//TODO: Change such BadHack isn't necessary
function BadHack(layer: CustomLayerSpecs) {
  let newLayer = window.structuredClone(layer)
  Object.keys(newLayer).map((a) => {
    newLayer[a] ||="a" 
  })
  return newLayer
}

function constructDefaultMitValues(layer: VectorLayerSpec):{[key:string]:any} {
  let defaults = {}
  if ("mitValuesControls" in layer && typeof layer.mitValuesControls === "object" ) { 
    Object.keys(layer.mitValuesControls as any).forEach((a) => {
      if (a in (layer.mitValuesControls as any)) {
        defaults[a] = (layer.mitValuesControls as any)[a]?.[1]
      }
    })
  }
  if ("variantSelectors" in layer && typeof layer.variantSelectors === "object" ) {
    Object.keys(layer.variantSelectors as any).forEach((a) => {
      if (a in (layer.variantSelectors as any)) {
        defaults[a] = (layer.variantSelectors as any)[a]?.default?.[0]
      }
    })
  }
  return defaults
}

export class VectorLayer extends FeatureLayer {
  vectorHandle: VectorLayerSpec;
  mitValues: {[key:string]:any};

  constructor(layer: VectorLayer | VectorLayerSpec) {
    if (isVectorLayer(layer)) {
      super({...BadHack(customLayerDefault),...layer})
      this.vectorHandle = layer
      this.mitValues = constructDefaultMitValues(this.vectorHandle)
      return this
    }
    super({...BadHack(customLayerDefault),...layer.vectorHandle})
    this.vectorHandle = layer.vectorHandle
    this.mitValues = constructDefaultMitValues(this.vectorHandle)
    return this
  }

  public copy(): VectorLayer {
    return new VectorLayer(window.structuredClone(this.vectorHandle))
  }

  public getType(): string {
    return "vectorTile";
  }

  public getWithKey() {
    return {[this.vectorHandle.label] : this}
  }

  public get minZoom(): number {
    return this.vectorHandle.minZoom || 5 
  }

  public setStyling(value: any): void {
    Object.keys(value).forEach((a) => {
      this.mitValues[a] = value[a][0]
    })
  }

  // public getUpdatedProperties(value): updatedProperties[] {

  // }

  public getTileURL(): string {
    return ""
  }
  
  public getLayerID() : string[]
  public getLayerID(idx: number)
  public getLayerID(idx?: number) {
    if (idx !== undefined) {
      return `${this.getSourceID()}-L${idx}`
    }
    return this.vectorHandle.layers?.map((a, idx) => {
      return this.getLayerID(idx)
    })
  }
  public getSourceID() {
    return this.vectorHandle.sourceID
  }
  public getSource() {
    return {
      type: "vector",
      scheme: "tms",
      tiles: this.vectorHandle.sources.tiles
    }
  }

  public getLegendURL() {
    if (typeof this.vectorHandle.legend == "string") {
      return this.vectorHandle.legend
    }
    return ""
  }

  public getLayers() {
    return this.vectorHandle.layers.map((a, idx) => {
      return {
        "minzoom": this.vectorHandle.minZoom ?? 9, //TODO: Make system setting for vector layer default zoom
        ...replaceMitTagsValues(this.mitValues,this.vectorHandle.layers[idx]),
        id: this.getLayerID(idx),
        source: this.getSourceID(),
        "source-layer": this.getSourceID(),
      }
    })
  }
}

function replaceMitTagsValues(values: {[key:string]:any}, object: unknown) {
  if (typeof object == "string") {
    if (object.includes("$")) {
      const key = object.split("$")[1]
      return values[key]
    }
  }
  if (Array.isArray(object)) {
    return object.map((a) => {
      return replaceMitTagsValues(values, a)
    })
  }
  if (typeof object == "object" && object) {
    const next = {}
    Object.keys(object).forEach((a) => {
      next[a] = replaceMitTagsValues(values, object[a])
    })
    return next
  }
  return object
}
