import {ColorRangeList, ColorRange, ProjectionType, LayerInfo, LayerInfoStyling, ReferenceGeomType, 
   ReferenceGeomFeature,
   SizeRangeList,
   SizeRange,
   DataDivisionList,
   DataDivision,
   DataDivisionType,
   POIResponse, POIResponseFound, RowDataQuality, LabelButton,
   SpecialValueStyling} from '../common/managers/Types';
import {Utils} from '@viamap/viamap2-common';
import proj4 from 'proj4';
import { Logger } from '@viamap/viamap2-common';
import { SettingsManager } from '@viamap/viamap2-common';
import { DivisionCalculator } from './DivisionCalculator';

export type PointMetaData = {
  index:number, 
  lat:number, 
  lng:number, 
  labelPairs?:any, 
  labelButtonList?: LabelButton[]};

type recursiveGeometry = [number,number] | recursiveGeometry[]

export class GenerateGeomUtils {
 
   public static StandardColors:string[] = ['blue', 'green', 'orange', 'yellow', 'violet', 'grey', 'black'];

   static orderSorter (v1:ColorRange|SizeRange, v2: ColorRange|SizeRange) { 
      return v1.order === v2.order ? 0: v1.order > v2.order ? 1: -1; 
   }

   public static getSizeRanges():SizeRange[] {
      let sizeRanges:SizeRangeList = [
         {
           name:"Normal",
           order: 1,
           sizes : [5,10,15] 
         },
         {
           name:"Reverse",
           order: 2,
           sizes : [15,10,5] 
         },
         {
           name:"Normal 4",
           order: 3,
           sizes : [5,10,15,20] 
         },
         {
           name:"Reverse 4",
           order: 4,
           sizes : [20,15,10,5] 
         },
         {
           name:"Log",
           order: 5,
           sizes : [3,7,15,30] 
         },
         {
           name:"Log Reverse",
           order: 6,
           sizes : [30,15,7,3] 
         },
       ];
      // sort by order
      sizeRanges.sort(this.orderSorter);
      return sizeRanges;
   }

   public static getColorRanges():ColorRange[] {

       /*
         ToDo: allow dynamic number of divisions and colors
         En simpel datastruktur hvor man kan slå op på det antal kategorier man skal bruge.
   
         {
         '2': ["#2b83ba", "#d7191c"],
         '3': ["#2b83ba", "#ffffbf", "#d7191c"],
         '4': ["#2b83ba", "#abdda4", "#fdae61", "#d7191c"],
         '5': ["#2b83ba", "#abdda4", "#ffffbf", "#fdae61", "#d7191c"],
         '6': ["#3288bd", "#99d594", "#e6f598", "#fee08b", "#fc8d59", "#d53e4f"],
           '7': ["#3288bd", "#99d594", "#e6f598", "#ffffbf", "#fee08b", "#fc8d59", "#d53e4f"],
           '8': ["#3288bd", "#66c2a5", "#abdda4", "#e6f598", "#fee08b", "#fdae61", "#f46d43", "#d53e4f"],
           '9': ["#3288bd", "#66c2a5", "#abdda4", "#e6f598", "#ffffbf", "#fee08b", "#fdae61", "#f46d43", "#d53e4f"],
           '10':["#5e4fa2", "#3288bd", "#66c2a5", "#abdda4", "#e6f598", "#fee08b", "#fdae61", "#f46d43", "#d53e4f", "#9e0142"],
         '11':['#5e4fa2', '#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#ffffbf', '#fee08b', '#fdae61', '#f46d43', '#d53e4f', '#9e0142']
         }
       */
   
       // initial color ranges
       let colorRanges:ColorRangeList = [ 
         {
         //   name:"Red to Green",
         //   colors : [
         //     '#FFEDA0',
         //     '#FED976',
         //     '#FEB24C',
         //     '#FD8D3C',
         //     '#FC4E2A',
         //     '#E31A1C',
         //     '#BD0026',
         //     '#800026'
         //   ] 
         // },
         // {
         //   name:"Green to Red",
         //   colors : [
         //     '#800026',
         //     '#BD0026',
         //     '#E31A1C',
         //     '#FC4E2A',
         //     '#FD8D3C',
         //     '#FEB24C',
         //     '#FED976',
         //     '#FFEDA0'
         //   ] 
         // },
         name:"Yellow to Red",
         order: 1,
         colors : [
           '#FFEDA0',
   //        '#FED976',
           '#FEB24C',
           '#FD8D3C',
           '#FC4E2A',
           '#E31A1C',
   //        '#BD0026',
           '#800026'
         ] 
       },
       {
         name:"Red to Yellow",
         order: 7,
         colors : [
           '#800026',
   //        '#BD0026',
           '#E31A1C',
           '#FC4E2A',
           '#FD8D3C',
           '#FEB24C',
   //        '#FED976',
           '#FFEDA0'
        ] 
       },  
         {
           name:"Dark Blue to Light Blue",
           order: 9,
           colors : [
             'rgb(0,0,102)',
             'rgb(0,51,153)',
             'rgb(87,129,213)',
             'rgb(204,236,255)'
           ] 			
         },
         {
           name:"Light Blue to Dark Blue",
           order: 3,
           colors : [
             'rgb(204,236,255)',
             'rgb(87,129,213)',
             'rgb(0,51,153)',
             'rgb(0,0,102)'
           ] 			
         },
         {
           name:"Light Green to Dark Green",
           order: 2,
           colors : [
             'rgb(209,255,255)',
             'rgb(143,255,255)',
             'rgb(51,204,204)',
             'rgb(0,153,153)'
           ] 			
         },
         {
           name:"Dark Green ro Light Green",
           order: 8,
           colors : [
             'rgb(0,153,153)',
             'rgb(51,204,204)',
             'rgb(143,255,255)',
             'rgb(209,255,255)'
           ] 			
         }      
       ];
   
       // sort by order
       colorRanges.sort(this.orderSorter);
       return colorRanges;
   }

   static coordinatesAreOutOfBounds(lat: number, lon:number):boolean {
      let coordinateBoundsLonLower = SettingsManager.getSystemSetting("coordinateBoundsLonLower", 8);  
      let coordinateBoundsLonUpper = SettingsManager.getSystemSetting("coordinateBoundsLonUpper", 16);  
      let coordinateBoundsLatLower = SettingsManager.getSystemSetting("coordinateBoundsLatLower", 54);  
      let coordinateBoundsLatUpper = SettingsManager.getSystemSetting("coordinateBoundsLatUpper", 58);  
 
      return !((lon >= coordinateBoundsLonLower && lon <= coordinateBoundsLonUpper) 
        && (lat >= coordinateBoundsLatLower && lat <= coordinateBoundsLatUpper));
    }
  
    /** Returns the color and viaibility and division for the element (div = -1 means "other") 
    */
    static getColorByValueNummeric(colors:ColorRange, value:number, divisions?:DataDivisionList, otherValue?:SpecialValueStyling):{color:string, hideMarker:boolean, divisionId:number} {
      let color=SettingsManager.getSystemSetting("areaMapDefaultFillColor","#FC4E2A");
      let otherDataColor = otherValue?.color || SettingsManager.getSystemSetting("mapColorForOtherDataValue",'cyan');

      let hideMarker = false;
      let divIdx = -1;
      if (divisions && divisions.list.length > 0) {
          console.assert(divisions.list.length >= 1, "Too few devisions");
          divIdx = DivisionCalculator.getDivisionId(divisions, value);
          if (divIdx === -1) {
            hideMarker = !(otherValue?.show || false)
            color = otherDataColor;
          } else {
            console.assert(divIdx >= 0, "Divisions not found for value: "+value);
            console.assert(divisions.list.length > divIdx, "Too few colors for division" );
            color= divisions.list[divIdx].color;
            hideMarker = Boolean(divisions.list[divIdx].hide);
          }
      }
  
      return {color, hideMarker, divisionId: divIdx};
    } 
  
    /** Returns the color and viaibility and division for the element (div = -1 means "other") */
    static getColorByValueDiscrete(colors:ColorRange, value:string, divisions?:DataDivisionList, otherValue?:SpecialValueStyling, noData?:SpecialValueStyling):{color:string, hideMarker:boolean, divisionId:number} {
      let color=SettingsManager.getSystemSetting("areaMapDefaultFillColor","#FC4E2A");
      let otherDataColor = otherValue?.color || SettingsManager.getSystemSetting("mapColorForOtherDataValue",'cyan');
      let hideMarker = false;

      let divIdx = -1;
      if (divisions && divisions.list.length > 0) {
          console.assert(divisions.list.length >= 1, "Too few devisions");
          divIdx = DivisionCalculator.getDivisionIdDiscrete(divisions, value);
          if (divIdx === -1) {
            hideMarker = !(otherValue?.show || false)
            color = otherDataColor;
          } else {
            console.assert(divisions.list.length > divIdx, "Too few colors for division" );
            color= divisions.list[divIdx].color;
            hideMarker = Boolean(divisions.list[divIdx].hide);
          }
      }

      return {color, hideMarker, divisionId: divIdx};
    } 
  
    static convertFromUTMtoLatLng2(utmCoord:number[]):number[] {
      let cx = this.convertFromUTMtoLatLng({x:utmCoord[0], y:utmCoord[1]});
      return [cx.lat, cx.lng];
    }

    static convertFromUTMtoLatLng(utmCoord:{x:number, y:number}):{lat:number, lng:number} {
      let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
      let utm32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs';
      let coords;
      coords = proj4(utm32, wgs84, [utmCoord.x, utmCoord.y]).reverse();

      return ({lat:coords[0], lng:coords[1]});
    }

    static convertProjection(from = "EPSG:4326", to = "EPSG:3857", [x, y]):[number,number] {
      return  proj4(from, to, [x, y])
    }

    static GeoJsonCastToFeatureCollection(feature: {geometry: any} | {type: "FeatureCollection", features: {geometry:any}[]}, from?: any) {
      if ("features" in feature && feature["features"]) {
        return this.convertGeoJson(feature, from)
      }
      else {
        return {
          type: "FeatureCollection",
          ...feature,
          features: [this.convertGeoJson(feature, from)]
        }
      }
    }
    
    static convertGeoJson(feature: {geometry: any} | {type: "FeatureCollection", features: {geometry:any}[]}, from?: any) {
      if ("geometry" in feature && feature["geometry"]) {
        return this.convertFeature(feature, from)
      }
      if ("features" in feature && feature["features"]) {
        return {
          ...feature,
          features: feature.features.map((a) => this.convertGeoJson(a, from)).filter((a) => a)
        }
      }
      return undefined
    }

    static convertFeature<T extends {geometry: any}>(feature: T, from?: any):T {
      if (!JSON.stringify(from ?? {crs:"EPSG:3857"}).includes("25832")) { //Only converts from 25832 to start
        return feature
      }
      const result = {
        ...feature,
        geometry: {
          type:feature.geometry.type,
          coordinates: GenerateGeomUtils.convertAnyGeometry(feature.geometry.coordinates)
        }
      }
      return result
    }

    /**
     * 
     * @param geojson geojson to apply transform on each coord pair/triplet
     * @param transformer 
     * @returns copy of @param geojson as
     */
    static geoJsonCoordsTransformer<T extends number|string>(geojson: any, transformer: (coord: [T,T,T?]) => [number,number] | [number,number,number]) {
      let copy = window.structuredClone(geojson);
      
      function walkCoords(coordinates) {
        if (Array.isArray(coordinates[0])) {
          return coordinates.map((a) => {
            return walkCoords(a).filter((a) => a)
          })
        }
        return transformer(coordinates)
      }

      function walkFeature(geojson) {
        if (geojson.features) {
          return {
            ...geojson,
            features: geojson.features.map((a) => walkFeature(a)).filter((a) => a)
          }
        }
        if (geojson.geometry) {
          return {
            ...geojson,
            geometry:{
              ...geojson.geometry,
              coordinates: walkCoords(geojson.geometry.coordinates).filter((a) => a) || []
            }
          }
        }
      }
      return walkFeature(copy)
    }

    

    static convertAnyGeometry(coords: any):any {
      if (typeof coords[0] == "number") {
        return (GenerateGeomUtils.convertFromUTMtoLatLng2(coords).reverse())
      } else {
        return coords.map((a) => GenerateGeomUtils.convertAnyGeometry(a))
      }
    } 

    static convertFromLatLngToUTM(latlng:{lat:number, lng:number}):{x:number,y:number} {
      let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
      let utm32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs';
      let coords = proj4(wgs84, utm32, [latlng.lng, latlng.lat]);

      return {x:coords[0], y:coords[1]};
    }

    static encodePointMetaData(index:number, lat:number, lng:number, labelPairs:any, labelButtonList?: LabelButton[]):string {
      let md:PointMetaData = {index:index, lat:lat, lng:lng, labelPairs, labelButtonList};
      return JSON.stringify(md);
    }

    static decodePointMetaData(json:string):PointMetaData {
      !json && Logger.logInfo("GenerateGeom","decodePointMetaData","Point metadata unexpectedly empty");
      return json && JSON.parse(json);
    }

    static    styleB(color:string, borderColor:string, weight?:number, fillOpacity?:number) {
      return {
          fillColor: color,
          weight: weight || 3,
          opacity: 1,
          color: borderColor,
          dashArray: '3',
          fillOpacity: fillOpacity || 0.7
      };
    }

    static findIndexWithEqualityFunc(codes:any[], value:any, equalityFunc:(a:any,b:any)=>boolean):number {
      let result = -1; // if not found
      for(let i=0; i<codes.length; i++) {
        if (equalityFunc(value, codes[i])) {
          result = i;
          break;
        }
      }
      return result;
    }

}