import React, { useContext, useEffect, useState } from "react"
import { Button, Card, Form, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { ExploreSearchContext, removeFilter, addFilter, FilterSpec, FilterType, FilterValue, removeAllFilters, heartFilter } from "../states/ExploreSearchState";
import { FaRegTimesCircle } from "react-icons/fa";
import { BsChevronRight, BsFillPinAngleFill, BsHeartFill, BsTrashFill } from "react-icons/bs";
import { Localization } from "@viamap/viamap2-common";
import { MitStorage } from "src/managers/MitStorage";
import { MapitStateContext } from "src/states/MapitState";
import { LayerInfo, LayerType, LayerVariabilityType } from "src/common/managers/Types";

import * as turf from '@turf/turf';
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";

export function Filters() {
   let { state, dispatch } = useContext(ExploreSearchContext);
   
   const {hasAccessToFeature} = useContext(ApplicationStateContext);

   let [ saves, setSave  ] = useState<null|boolean>(null)

   function doSave() {
      if (saves ?? (MitStorage.getValue("FilterSearch") || "{}") !== "{}") {
         // TODO: Save to userSettings
         MitStorage.setValue("FilterSearch",{})
         setSave(false)
      } else {
         MitStorage.setValue("FilterSearch",state.activeFilters)
         setSave(true)
      }
   }

   useEffect(() => {
      if (saves ?? (MitStorage.getValue("FilterSearch") || "{}") !== "{}") {
         // TODO: Save to userSettings
         MitStorage.setValue("FilterSearch",state.activeFilters)
         setSave(true)
      } else {
         setSave(false)
      }

   },[state.activeFilters])

   useEffect(() => {
      // TODO: Save to userSettings
      MitStorage.setValue("FilterFavoritSearch", state.favoritFilters)
   },[state.favoritFilters])
  
   function hearted(key:string) {
      return state.favoritFilters.includes(key) ? 1 : 0;
   }

   function filterByAccessRight(key:string):boolean {
      let filterSpec = state.selectableFilterList[key];
      return !filterSpec.licenseFeature || hasAccessToFeature(filterSpec.licenseFeature);
   }


   return (
      <>
      <Card className="FilterList" >
         <Card.Header>
         {Localization.getText("Filters")}
         <BsFillPinAngleFill title={(saves ?? (MitStorage.getValue("FilterSearch") || "{}") !== "{}") ? Localization.getText("Reset active filter between sessions") : Localization.getText("Store active filter between sessions")} color={(saves ?? (MitStorage.getValue("FilterSearch") || "{}") !== "{}") ? "#c78e57" : "#cbcbce"} onClick={doSave} />
         </Card.Header>
         <Card.Body>
            <Stack>
            {Object.keys(state.selectableFilterList).filter(filterByAccessRight).sort((a,b) => hearted(b) - hearted(a)).map((item) => {
               let value = state.selectableFilterList[item];
               let selected: boolean = Object.keys(state.activeFilters).includes(item);
               return (
                  <React.Fragment key={item} >
                  <FilterItem
                     item={item}
                     value={value}
                     selected={selected}
                     active={state.activeFilters[item]}
                     />
                  </React.Fragment>
                  )
               })
            }
            </Stack>
         </Card.Body>
      </Card>
      </>
   )
}

function FilterItem(props:{item:string, selected:boolean, value:FilterSpec, active:FilterValue}) {
   let [open, setOpen] = useState(false)
   let { state, dispatch } = useContext(ExploreSearchContext);
   



   function handleTrash(e) {
      e.preventDefault();
      e.stopPropagation()
      dispatch(removeFilter(props.item))
   }

   function handleHeart(e) {
      e.preventDefault();
      e.stopPropagation()
      dispatch(heartFilter(props.item, !(state.favoritFilters.includes(props.item))))
   }

   return props.value.hideOnFilterList ? null : (
      <Card className={open ? "filterOpen":"filterClosed"}>
         <Card.Header onClick={() => setOpen(!open)} >
            <span className='mit-transformer' style={{paddingLeft:"1px", transform:"Rotate("+(open?"90deg":"0deg")+")"}}><BsChevronRight /></span>
            {Localization.getTextSpecificTable(props.value.title, props.value.translationTable)}
            <BsHeartFill onClick={handleHeart} 
               title={state.favoritFilters.includes(props.item) ? Localization.getText("Undo favorite") : Localization.getText("Make favorite")}
               color={state.favoritFilters.includes(props.item) ? "#45465b":"#cbcbce"} style={{margin:"6px",marginTop:"9px",marginLeft:"auto"}} />
            {/* <span ><BsTrashFill onClick={handleTrash} /></span> */}
         </Card.Header>
         <Card.Body>
            <FilterItemS {...props} activeOnly={!open} />
         </Card.Body>
      </Card>
      // <button key={props.item} data-active={props.selected}
      //    onClick={() => {
      //       if (props.selected) {
      //          dispatch(removeFilter(props.item));
      //       } else {
      //          // let spec = state.selectableFilterList[props.item];  
      //          dispatch(addFilter(props.item, undefined));
      //       };
      //    }}
      // >
      //    {props.value.title} 
      // </button>
   );
}


function FilterTitleClickable (props:{item:string, title:string}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   const {item, title} = props;
   let placement="top";
   return (
      <div style={{display:"inline-block"}}>
         <OverlayTrigger
            key={placement}
          overlay={
         <Tooltip id={`tooltip-${placement}`}>
           Tryk for at fjerne dette filter
         </Tooltip>
       }
     >
   <Button size="sm" variant="light" onClick={() => {dispatch(removeFilter(item));}}>
            <FaRegTimesCircle style={{verticalAlign: "baseline"}}/>{' '}{title}
   </Button>
     </OverlayTrigger>
   </div>
   );
}


function FilterItemSSelect (props:{item:string, value:string, spec:FilterSpec, active:FilterValue, activeOnly?:boolean}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, active, activeOnly} = props;
   let val = state.activeFilters[item] || [];

   function setValue(item: string, value: any) {
      if ((active as string[])?.includes(value)) {
         dispatch(removeFilter(item))
      } else {
         dispatch(addFilter(item, [value]))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}> 
         <Form.Group className="long">
         {spec.options && Object.keys(spec.options).map((key) => {
               let text = spec.options && spec.options[key];
               if (text && spec.translateOptions) {
                  text = Localization.getTextSpecificTable(text, spec.translationTable);
               }
               if (activeOnly && !((active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" checked={!!((active as string[])?.includes(key))} onChange={(e) => {setValue(item, key)}} label={text} />
                  );
               })}
         </Form.Group>
      </Form>
   );
}


function FilterItemSelect (props:{item:string, value:string, spec:FilterSpec, active:FilterValue, activeOnly?:boolean}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, active, activeOnly} = props;
   let val = state.activeFilters[item] || [];

   const groups = {
      "0":"Helårsbeboelse", //"Bygninger til Helårsbeboelse",
      "210":"Landbrug, gartneri, skovbrug og fiskeri", //"Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, skovbrug og fiskeri",
      "220":"Industri og værksteder", //"Bygninger til erhvervsmæssig produktion vedrørende industri og værksteder",
      "230":"Energiproduktion og energidistribution", //"Bygninger vedrørende energiproduktion og energidistribution",
      "310":"Transport og parkering", //"Bygninger i forbindelse med transport og parkering",
      "320":"Kontor, handel og lager", //"Bygninger i forbindelse med kontor, handel og lager",
      "330":"Hotel, restaurant og øvrige serviceerhverv", //"Bygninger i forbindelse med hotel, restaurant og øvrige serviceerhverv",
      "410":"Biograf, bibliotek og trosudøvelse", //"Bygninger i forbindelse med biograf, bibliotek og trosudøvelse",
      "420":"Undervisning og forskning", //"Bygninger i forbindelse med undervisning og forskning",
      "430":"Hospitaler, hospicer og lægehuse", //"Bygninger i forbindelse med hospitaler, hospicer og lægehuse",
      "440":"Dag- og døgninstitutioner", //"Bygninger i forbindelse med dag- og døgninstitutioner",
      "510":"Fritidsformål og idræt", //"Bygninger til fritidsformål og idræt",
      "910":"Garageformål, ophold og opbevaring samt faldefærdig bygning", //"Mindre bygninger til garageformål, ophold og opbevaring samt faldefærdig bygning",
      "1000":"test"
   } as const
   let groupList = Object.keys(groups).map((a) => parseInt(a))

   function setValue(item: string, value: any) {

      if ((active as string[])?.includes(value)) {
         if (active!.length <= 1) {
            dispatch(removeFilter(item))
         }
         dispatch(addFilter(item, active!.filter((a) => a != value)));
      } else {
         dispatch(addFilter(item, [...(active || []), value]))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   if (spec.title === "Building Usage") {
      return (<>{Object.keys(groups).map((a, i) => {
         if (a === "1000") {
            return null
         }
         return (
            <React.Fragment key={a}>
               <BuildingUsageGroup item={props.item} value={value} spec={props.spec} setValue={setValue} active={props.active} activeOnly={props.activeOnly} min={groupList[i]} max={groupList[i+1]} groups={groups} />
            </React.Fragment>
         )
      }) }</>)
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}> 
         <Form.Group className="long">
         {spec.options && Object.keys(spec.options).map((key) => {
               let text = spec.options && spec.options[key];
               if (text && spec.translateOptions) {
                  text = Localization.getTextSpecificTable(text, spec.translationTable);
               }
               if ((!open || props.activeOnly) && !((props.active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" checked={!!((props.active as string[])?.includes(key))} onChange={(e) => {setValue(props.item, key)}} label={text} />
                  );
               })}
         </Form.Group>
      </Form>
   );
}

function FilterItemS(props:{ item, value, selected, active, activeOnly }) {
   let { item, value, selected, active, activeOnly } = props; // as {key:string, value:string}
   let [textValue, setTextValue] = useState<string>(value);
   let { state, dispatch } = useContext(ExploreSearchContext);

   let spec: FilterSpec = state.selectableFilterList[item];
   // check the type of the filter
   switch(spec.filterType) {
      case FilterType.SingleSelect:
         return (
            <FilterItemSSelect item={item} value={value} spec={spec} active={active} activeOnly={activeOnly} />
         )
      case FilterType.Select:
      case FilterType.SelectText:
         return (
            <FilterItemSelect item={item} value={value} spec={spec} active={active} activeOnly={activeOnly} />
         );
      case FilterType.IntegerRange:
         return (
            <FilterItemIntegerRange item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.DateRange:
         return (
            <FilterItemDateRange item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.String:
      case FilterType.Integer:
            return (
            <FilterItemSingleValue item={item} value={value} spec={spec} activeOnly={activeOnly} />
         );
      case FilterType.StaticValue:
      case FilterType.BoundingBox:
            return (
            <>
            <FilterTitleClickable item={item} title={Localization.getTextSpecificTable(spec.title, spec.translationTable)} />
            {JSON.stringify(value).substring(0,50)}
            </>
         );
      case FilterType.Polygon:
            return( <></> );

      case FilterType.PolygonFromLayer:
            return (
               <FilterSelectMapLayer item={item} value={value} spec={spec} activeOnly={activeOnly} />
            )
      default:
         return (
            <div>Unexpected filter type {spec.filterType}</div>
         );
   }
}

function BuildingUsageGroup (props:{item:string, value:string, spec:FilterSpec, setValue:(a,b) => void ,active:FilterValue, activeOnly?:boolean, min:number, max:number, groups:any}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let [open,setOpen] = useState(false)

   let allInGroup = (Object.keys((props.spec?.options || {})).filter((a) => parseInt(a) >= props.min && parseInt(a) < props.max) || [])
   let activeInGroup = (props.active?.filter((a) => a >= props.min && a < props.max) || [])
   let inActiveInGroup = (allInGroup.filter((a) => !!!activeInGroup.includes(a)))
   if (props.activeOnly && activeInGroup.length === 0) {
      return null
   }
   let spec = props.spec

   function ClickCheck() {
      if (inActiveInGroup.length > 0) {
         dispatch(addFilter(props.item, [...(props.active || []), ...inActiveInGroup]))
      } else {
         dispatch(addFilter(props.item, [...(props.active || []).filter((a) => !!!allInGroup.includes(a))]))
      }
   }


   return (
      <Card>
         <Card.Header style={{marginTop:"8px", fontFamily:"var(--readingFont)",fontWeight:"400", textTransform:"unset" ,fontSize:"14px", zIndex:"0"}}>
         <Form.Check type="checkbox" checked={activeInGroup.length > 0} onChange={(e) => {ClickCheck()}} />
         <div onClick={() => setOpen(!open)}>{props.groups[props.min.toString()]}<span className='mit-transformer' style={{position:"absolute" ,marginLeft:"8px", transform:"Rotate("+(open?"90deg":"0deg")+")"}}><BsChevronRight /></span></div>
         </Card.Header>
         { ((!open || props.activeOnly) && activeInGroup.length < 0) ? null :
         <Card.Body style={{paddingLeft:"12px"}}>
            {spec.options && Object.keys(spec.options).filter((a) => parseInt(a) >= props.min && parseInt(a) < props.max).map((key) => {
               let text = spec.options && spec.options[key];
               if (text && spec.translateOptions) {
                  text = Localization.getTextSpecificTable(text, spec.translationTable);
               }
               if ((!open || props.activeOnly) && !((props.active as string[])?.includes(key))) {
                  return null
               }
               return (
                  <Form.Check key={key} type="checkbox" style={{fontWeight:"300"}} checked={!!((props.active as string[])?.includes(key))} onChange={(e) => {props.setValue(props.item, key)}} label={text} />
                  );
               })}
         </Card.Body>
         }
      </Card>
   )
}

function FilterItemIntegerRange (props:{item:string, value:string, spec:FilterSpec, activeOnly:boolean}) {

   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [minValue, setMinValue] = useState<string>((val && val[0]) || "");
   let [maxValue, setMaxValue] = useState<string>((val && val[1]) || "");

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   useEffect(() => {
      setMinValue(state.activeFilters?.[item]?.[0] || "")
      setMaxValue(state.activeFilters?.[item]?.[1] || "")
   },[state.activeFilters])

   function handleTrash(item, value) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
      if (value[0] || value[1]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }
   
   function handleMinValue(item: string, value:any) {
      setValue(item, [value, maxValue])
      setMinValue(value)
   }

   function handleMaxValue(item: string, value:any) {
      setValue(item, [minValue, value])
      setMaxValue(value)
   }

   if (activeOnly && (!!!val || val.length < 1 || !val.some((a) => !!a))) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         {activeOnly && !!!minValue ? null : <Form.Group className={!!!minValue ?"":"hV"}>
            <Form.Control  size="sm" value={minValue} style={{ display: "inline-block", maxWidth: "5vw" }} onChange={(e) => { handleMinValue(props.item ,e.target.value) }}  />
         <BsTrashFill onClick={(e) => handleTrash(item,["",maxValue])} />
         </Form.Group>}
            {/* <span style={{float:"left"}}>{" < "}</span> */}
            {activeOnly && !!!maxValue ? null : <Form.Group className={!!!maxValue ?"":"hV"}>
            <Form.Control  size="sm" value={maxValue} style={{ display: "inline-block", maxWidth: "5vw" }} onChange={(e) => { handleMaxValue(props.item ,e.target.value) }}  />
         <BsTrashFill onClick={(e) => handleTrash(item,[minValue,""])} />
         </Form.Group>}
      </Form>
   );
}

function FilterItemDateRange (props:{item:string, value:string, spec:FilterSpec, activeOnly}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [minValue, setMinValue] = useState<string>((val && val[0]) || "");
   let [maxValue, setMaxValue] = useState<string>((val && val[1]) || "");

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   useEffect(() => {
      setMinValue(state.activeFilters?.[item]?.[0] || "")
      setMaxValue(state.activeFilters?.[item]?.[1] || "")
   },[state.activeFilters])

   function handleMinValue(item: string, value:any) {
      setValue(item, [value, maxValue])
      setMinValue(value)
   }

   function handleTrash(item, value) {
      setMinValue(value[0]);
      setMaxValue(value[1]);
      if (value[0] || value[1]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }

   function handleMaxValue(item: string, value:any) {
      setValue(item, [minValue, value])
      setMaxValue(value)
   }

   if (activeOnly && (!!!val || val.length < 1 || !val.some((a) => !!a))) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         {activeOnly && !!!minValue ? null: <Form.Group className={!!!minValue ? "":"hV"}>
            <Form.Control className={!!!minValue ? "":"hV"} type="date" size="sm" value={minValue} style={{ display: "inline-block", maxWidth: "10vw" }} onChange={(e) => { handleMinValue(item, e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,["",maxValue])} />
            </Form.Group>}
            {/* <span style={{float:"left"}}>{" < "}</span> */}
         {activeOnly && !!!maxValue ? null: <Form.Group className={!!!maxValue ? "":"hV"}>
            <Form.Control className={!!!maxValue ? "":"hV"} type="date" size="sm" value={maxValue} style={{ display: "inline-block", maxWidth: "10vw" }} onChange={(e) => { handleMaxValue(item, e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,[minValue,""])} />
         </Form.Group>}
      </Form>
   );
}

function FilterItemSingleValue (props:{item:string, value:string, spec:FilterSpec, activeOnly:boolean}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   let val = state.activeFilters[item];
   let [fieldValue, setFieldValue] = useState<string>((val && val[0]) || "");

   useEffect(() => {
      setFieldValue(state.activeFilters?.[item]?.[0] || "")
   },[state.activeFilters])

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   function handleTrash(item, value) {
      setFieldValue(value[0]);
      if (value[0]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }

   if (activeOnly && (!!!val || val.length < 1)) {
      return null
   }

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         <Form.Group className={!!!val ? "":"hV"} onBlur={(e) => { setValue(item, [fieldValue]) }}>
            <Form.Control size="sm" value={fieldValue} style={{ display: "inline-block", maxWidth: "8vw" }} onChange={(e) => { setFieldValue( e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,[""])} />
         </Form.Group>
      </Form>
   );
}

function FilterSelectMapLayer (props:{item:string, value:string, spec:FilterSpec, activeOnly:boolean}) {
   let { state, dispatch } = useContext(ExploreSearchContext);
   let { item, value, spec, activeOnly } = props;
   const { state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
   const [availableLayers, setAvailableLayers] = React.useState<any>([]);
   const [selectedLayerId, setSelectedLayerId] = React.useState<number>();
   
   let val = state.activeFilters[item];
   let [fieldValue, setFieldValue] = useState<string>((val && val[0]) || "");

   useEffect(() => {
      setFieldValue(state.activeFilters?.[item]?.[0] || "")
      if (!Object.keys(state.activeFilters).includes("map_layer")) {
         setSelectedLayerId(undefined);
      }
   },[state.activeFilters])

   function setValue(item: string, value: FilterValue) {
      dispatch(addFilter(item, value));
   }

   function handleTrash(item, value) {
      setFieldValue(value[0]);
      if (value[0]) {
         setValue(item, value)
      } else {
         dispatch(removeFilter(item))
      }
   }

   React.useEffect(() => {
      let relevantLayers = Object.values(mapitState.layers).filter((layerInfo: LayerInfo) => { return layerInfo.type === LayerType.GeoJSON_Polygon && ((layerInfo.layerVariability || LayerVariabilityType.NormalLayer) === LayerVariabilityType.NormalLayer)});
      let relevantLayerKeys = relevantLayers.map((lr) => { return lr.layerId}).sort();
      let availableLayerKeys = availableLayers.map((lr) => { return lr.layerId}).sort();
      // only update if there are new layers - not on any (e.g. data or styling change)
      if (!(relevantLayerKeys+"" === availableLayerKeys+"")) {
         setAvailableLayers(relevantLayers);
         setSelectedLayerId(undefined);
      }

    }, [mapitState.layers]);


   if (!(availableLayers?.length)) {
      return activeOnly ? (<></>) : ( <div style={{ display: "inline-block", maxWidth: "8vw" }}>{Localization.getText("No suitable Area Layers")}</div> );
   }
   
   function SelectLayer (props: { title: string, onSelect: (value: number|undefined) => void }) {
   return (
     <>
       <select onChange={e => {
         let layerId:number|undefined = Number.parseInt(e.target.value);
         if (Number.isNaN(layerId)) {
           layerId=undefined;
         }
         props.onSelect(layerId);
       }}
         className="form-control input-sm"
         style={{ width: "auto" }}
         value={selectedLayerId}>
         <option key={"default"} value={""} >{Localization.getText("PointSource:ChooseALayer")}</option>
         {availableLayers.map((layerInfo: LayerInfo, index) => {
       return (
         <option key={"" + layerInfo.layerId + index} value={layerInfo.layerId}>{layerInfo.datasetname}</option>
       );
     })}
       </select>
     </>
   )
 }
 

   
function callbackOnUserPolygon(poly:any) {
   // alert("Got polygon callback "+JSON.stringify(poly));
   if (poly && poly.features && poly.features.length > 0) {
      let feature=poly.features[0];
      // let p = turf.polygonize(poly);
      // const union = feature.geometry.coordinates.reduce((a, b) => turf.union(a, b), feature.geometry.coordinates[0])
      if (feature.geometry.type === "Polygon") {
         // Convert to multipolygon
         feature.geometry = {
            type:"MultiPolygon",
            coordinates: [feature.geometry.coordinates]
         }
      }
      dispatch(addFilter("map_layer", feature.geometry.coordinates[0]));
   }
}

   return (
      <Form onSubmit={(e) => e.preventDefault()}>
         <Form.Group className={!!!val ? "":"hV"} onBlur={(e) => { setValue(item, [fieldValue]) }}>
         <SelectLayer
                  title={Localization.getText("MultiPointDataLayer")}
                  onSelect={(layerId: any) => {
                     setSelectedLayerId(layerId);
                     let found = availableLayers.find((lr) => lr.layerId === layerId);
                     if (found && found.geoJson) {
                        callbackOnUserPolygon(found.geoJson);
                     } else {
                        dispatch(removeFilter(item))
                     }
                  } } 
               />
            {/* <Form.Control size="sm" value={fieldValue} style={{ display: "inline-block", maxWidth: "8vw" }} onChange={(e) => { setFieldValue( e.target.value) }}  />
            <BsTrashFill onClick={(e) => handleTrash(item,[""])} /> */}
         </Form.Group>
      </Form>
   );
}