import { Feature } from "src/states/ApplicationStateFeatures"
import { MitBody, MitButton, MitDialog, MitFooter, MitHeader, MitInfo } from "./ComponentUtils"
import { AppMessagesContext, Localization, Logger, PersistenceObjectType, PersistenceScope, SessionContext, SettingsFixedPriorities, SettingsManager, ViamapPersistenceLayer, actionSetErrorMessage, actionSetInfoMessage } from "@viamap/viamap2-common"
import { Fragment, useContext, useEffect, useState } from "react"
import { ApplicationStateContext } from "src/states/ApplicationState"
import { SETTINGS_PATH_SELECTOR_SEPARATOR, SettingsValues } from "@viamap/viamap2-common/dist/managers/SettingsManager"
import { AiOutlineCreditCard, AiOutlineGlobal, AiOutlineQuestion, AiOutlineTeam, AiOutlineUser } from "react-icons/ai"
import { MdSettingsBackupRestore } from "react-icons/md"
import { FaRegEdit } from "react-icons/fa"

type SingleTranslation = {
  da: string,
  en: string
}

type MinMax = {
  min?: number,
  max?: number
}

type GroupInfo = ({
  key: string,
} & SingleTranslation)[]

type SettingsType = 
  | {type: "String"}
  | {type: "Select", options: ({value: any} & SingleTranslation)[]}
  | {type: "Integer", sufix?: string} & MinMax
  | {type: "Float", precision?:number} & MinMax
  | {type: "Boolean", true:SingleTranslation, false:SingleTranslation }
  | {type: "TimeADay"}
  | {type: "Date"} & MinMax
  | {type: "DateTime"} & MinMax
  | {type: "Year"} & MinMax

  type SingleSetting = {
    access: Feature | Feature[],
    default?: boolean|string|number,
    key: string,
    group: string,
  } & SettingsType & SingleTranslation

  type keyValueObject = {[key:string]:any}

export function SettingsEditor(props: {
  showWindow:number,
  scope: PersistenceScope,
  callbackOnClose:() => any,
  callbackToResetCatchment:(a:any) => any
}) {
  const [settingValues, setSettingValue] = useState<{[key:string]:{value: any, disabled: boolean, reset:boolean}}>({})
  const {state: sessionState} = useContext(SessionContext);
  const {dispatch: appMessageDispach} = useContext(AppMessagesContext);

  useEffect(() => {
    if (props.showWindow) {
      let priority = PersistenceScopeToSettingsFixedPriorities(props.scope)
      let settingValues = {...SettingsManager.extractSettingsByPriority(priority,priority, true)};
      Object.keys(settingValues).forEach((key) => {
        settingValues[key] = {value: settingValues[key], disabled:(getSource(key) !== priority), reset:false}
      })
      setSettingValue((a) => ({...(settingValues)}))
    } else {
      setSettingValue({})
    }
  },[props.scope, props.showWindow])

  function savePersistentSettings() {
    onClickSaveCustomUserSettings(settingValues, sessionState, props.callbackOnClose, appMessageDispach, PersistenceScopeToSettingsFixedPriorities(props.scope))
  }

  function setSetting(key, value) {
    setSettingValue((a) => ({...a, [key]:value}));
    let newSettings = {...settingValues, [key]:value};
    const source = PersistenceScopeToSettingsFixedPriorities(props.scope)
    const settings = removeUndefined(newSettings)
    SettingsManager.clearByPriority(source);
    SettingsManager.addSettings(settings, {filename:"LiveSetting", priority: source});
    let settingValuesExtracted = {...SettingsManager.extractSettingsByPriority(source,source, true)};
    Object.keys(settingValuesExtracted).forEach((key) => {
      settingValuesExtracted[key] = {value: settingValuesExtracted[key], disabled:(getSource(key) < source), reset:false}
    })
    setSettingValue((a) => ({...(settingValuesExtracted)}))
  }

  function closeWindow() {
    savePersistentSettings()
    props.callbackOnClose()
  }

  return (
    <MitDialog id="settings" show={props.showWindow} style={{minWidth:"660px", left:"50%", transform:"translateX(-50%)", top:"1em"}}>
      <MitHeader position="center" >{Localization.getText("Settings")}</MitHeader>
      <MitBody >
        <MitInfo>
          {props.scope == PersistenceScope.User 
            ? Localization.getText("Changed settings affect current user")
            : Localization.getText("Changed settings affect all employees from the same organization")
        }</MitInfo>
      {SettingsGroups.map((a) => {
        return <Fragment key={a.key}><Group group={a} values={settingValues} onChange={(key, value) => setSetting(key,value)} /></Fragment>
      })}
      </MitBody>
      <MitFooter>
        <MitButton onClick={() => closeWindow()} variant={"close"} >Luk</MitButton>
      </MitFooter>
    </MitDialog>
  )
}

function Group(props:{group:GroupInfo[number], values: keyValueObject, onChange:(key,value) => any}) {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const availableSettings = Settings.filter((ss) => {
    return ss.group == props.group.key && (typeof ss.access == "string" ? hasAccessToFeature(ss.access) : ss.access.every((a) => hasAccessToFeature))
  })
  if (availableSettings.length === 0) {
    return
  }
  return (
    <div className="Setting_Group">
    <div className="Setting_Title"><h2>{props.group[Localization.getLanguage()]}</h2></div>
    {availableSettings.map((ss) => {
      return <Fragment key={ss.key}><SettingRow setting={ss} values={props.values} onChange={props.onChange} /></Fragment>
    })}
    </div>
  )
}

function IconBySource(props:{value:SettingsFixedPriorities}) {
  let value = props.value
  switch (value) {
    case (0 as SettingsFixedPriorities) :
    case SettingsFixedPriorities.GlobalSettings:
    case SettingsFixedPriorities.EnvironmentSettings:
    case SettingsFixedPriorities.ProductVariantSettings:
      return <div className="SourceIcon" title={Localization.getText("Default")}><AiOutlineGlobal /></div>
    case SettingsFixedPriorities.LicenseSettings:
      return <div className="SourceIcon" title={Localization.getText("License")}><AiOutlineCreditCard /></div>
    case SettingsFixedPriorities.CustomerSettings:
      return <div className="SourceIcon" title={Localization.getText("Customer")}><AiOutlineTeam /></div>
    case SettingsFixedPriorities.UserSettings:
      return <div className="SourceIcon" title={Localization.getText("User")}><AiOutlineUser /></div>
    default:
      return <div className="SourceIcon" title={Localization.getText("Unknown")}><AiOutlineQuestion /></div>
  }
}

function SettingRow(props:{setting:SingleSetting, values:keyValueObject, onChange:(key,value) => any}) {
  const value = props.values[props.setting.key]?.value ?? SettingsManager.getSystemSetting(props.setting.key, props.setting.default, true)
  const disabled = props.values[props.setting.key]?.disabled ?? true
  const lang: "da"|"en" = Localization.getLanguage() as "da" | "en";
  return <>
    <div className="Setting_Label">{props.setting[lang]}</div>
    <div className="Setting_Input">
      {/* <ValueAsString setting={props.setting} /> */}
      <IconBySource value={getSource(props.setting.key)} />
      {props.values[props.setting.key]?.reset ?
        <span className={"disabled"}>{Localization.getText("Reset to default")}</span>
      : <>
      <SettingsInput disabled={disabled} value={value} setting={props.setting} onChange={(value) => {props.onChange(props.setting.key, {value: stringToValue(props.setting,value), disabled: disabled, reset:false})}} />
      <span className={(disabled ? "disabled":"")}>{"sufix" in props.setting ? props.setting.sufix : ""}</span>
      </>}
    </div>
    <div className="Setting_Butto">
    {disabled ? <div className="sm" onClick={() => {props.onChange(props.setting.key, {value: value, disabled: false, reset:false})} }><FaRegEdit title={Localization.getText("Edit")} color="grey"/></div> : 
    <><MdSettingsBackupRestore onClick={() => {props.onChange(props.setting.key, {value: value, disabled: true, reset:true})}} color="grey" title={Localization.getText("Reset")} /></>}
    </div>
  </>
}

function stringToValue(setting:SingleSetting, value) {
  if (value === undefined) {
    return undefined
  }
  switch (setting.type) {
    case "Boolean": return value === "true" ? true : false
    case "TimeADay": {
      let split = value.split(":")
      return parseInt(split[0]) * 3600 + parseInt(split[1]) * 60 
    }
    // case "DateTime": 
    case "Date": return parseInt(new Date(value).toISOString().split("T")[0].replaceAll("-",""))
    case "Year": //next
    case "Integer": {
      let valueInt = value !== "" ? parseInt(value) : (setting.min ?? 0);
      return Math.max(Math.min(setting.max ?? valueInt,valueInt),setting.min ?? valueInt)}
    case "Float": return parseFloat(value)
    default: return value?.toString() || value
  }
}

function valueToString(setting:SingleSetting, value) {
  if (value === undefined) {
    return ""
  }
  switch (setting.type) {
    case "TimeADay": return `${format2Digits(Math.floor(value / 3600))}:${format2Digits(Math.floor(value/60) % 60)}`
    case "Integer": return value === "" ? (setting.min ?? "0") : value;
    case "Date": {
      let copy = ((value?.toString() || "").length == 8 ? value.toString() : "00000000").split("");
      return `${copy.splice(0,4).join("")}-${copy.splice(0,2).join("")}-${copy.splice(0,2).join("")}`
    }
    case "Select": return value === "" ? "0" : value
    case "Float":
    case "Boolean":
    case "DateTime":
    case "Year":
    default: return value?.toString() || value
  }
}

function SettingsInput(props:{setting:SingleSetting, value:any, disabled?:boolean, onChange: (value) => any }) {
  const lang: "da"|"en" = Localization.getLanguage() as "da" | "en";

  const value = valueToString(props.setting, props.value)

  function onChange(ev) {
    props.onChange(ev.target.value)
  }

  let si = props.setting;
  switch (si.type) {
    case "String": return <input disabled={props.disabled} value={value} onChange={onChange}></input>
    case "Select": return <select disabled={props.disabled} value={value} onChange={onChange}>{si.options.map((a) => <option value={a.value} >{a[lang]}</option>)}</select>
    case "Integer": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" max={si.max} min={si.min} />
    case "Float": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" step={si.precision ?? 0.1} max={si.max} min={si.min} />
    case "Boolean": return <select disabled={props.disabled} value={value} onChange={onChange}><option value={"true"} >{si.true[lang]}</option><option value={"false"} >{si.false[lang]}</option></select>
    case "TimeADay": return <input disabled={props.disabled} value={value} onChange={onChange} type="time" />
    case "Date": return <input disabled={props.disabled} value={value} onChange={onChange} type="date" />
    case "DateTime": return <input disabled={props.disabled} value={value} onChange={onChange} type="datetime-local" />
    case "Year": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" min={2000} max={2900} />
    default: return <>{props.value}</>
  }
  return <></>
}


const SettingsGroups:GroupInfo = [
  {
    key: "import",
    da: "Import",
    en: "Import"
  },
  {
    key: "catchment",
    da: "Rejsetid",
    en: "Traveltime"
  },
  {
    key: "legend",
    da: "Signaturforklaring",
    en: "Legend",
  },
  {
    key: "featurelayers",
    da: "Featurelag",
    en: "Feature layers"
  },
  {
    key:"debugging",
    da: "Fejlfinding",
    en: "Debugging"
  }
]

const Settings:SingleSetting[] = [
  {
    access: Feature.BasicMap,
    group: "import",
    key: "AsDefaultCreatePopupInfoForAllFieldsInGeoJSONImports",
    type:"Boolean",
    default: true,
    true: {da: "Alle Attributter i Popup", en:"All attrib. in Popup"},
    false: {da: "Ingen Attibutter i Popup", en:"No attrib. in Popup"},
    da: "Json Import",
    en: "Json Import",
  },
  {
    access: Feature.BasicMap,
    group: "legend",
    key: "OrderBackgroundLayersInDrawingOrder",
    default: true,
    da: "Rækkefølge af Baggrundslag",
    en: "Order of Background layers",
    type:"Boolean",
    true: {da:"Som tegnet på kortet",en:"As shown on map"},
    false:{da:"Som vist på listen",en:"As ordered in layer list"}
  },
  {
    access: Feature.BasicMap,
    group: "featurelayers",
    key: "SaveOpendedFeatureLayers",
    default: false,
    da: "Husk tændte lag",
    en: "Remeber active layer",
    type:"Boolean",
    true: {da:"Slået til",en:"Activated"},
    false:{da:"Slået fra",en:"Deactivated"}
  },
  {
    access: Feature.PowerUser,
    group: "import",
    key: "timeBetweenLoadingMultipleFilesMS",
    type:"Integer",
    sufix: "ms",
    default: true,
    min: 0,
    max: 2000,
    da: "Tid mellem fil indlæsning",
    en: "Time between reading imported files",
  },
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.reverse",
    type: "Boolean",
    true: {da: "Mod Punkt", en: "Towards Points"},
    false: {da: "Fra Punkt", en: "From Points"},
    da: "Retning",
    en: "Direction",
  },
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.duration",
    type: "Integer",
    default: 90,
    sufix: "min",
    min: 5,
    max: 180,
    da: "Max rejsetid",
    en: "Max travel duration",
  }, 
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.date",
    default: 20230810,
    type: "Date",
    da: "Dato",
    en: "Date"
  },
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.time",
    type: "TimeADay",
    da: "Tidspunkt",
    en: "Travel time"
  },
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.earliestArrivalTime",
    default: 0,
    type: "TimeADay",
    da: "Tidligst ank./afr.",
    en: "Earliest arr./dep.",
  },
  {
    access: Feature.TravelTimeMultiple,
    group: "catchment",
    key: "catchmentStateDefaults.earliestArrival",
    default: true,
    type: "Boolean",
    true: {da: "Kortest rejsetid", en:"Shortest traveltime"},
    false: {da: "Kortest ventetid", en:"Shortest waiting time"},
    da: "Beregningsmetode",
    en: "Calculation method"
  },
  {
    access: Feature.TravelTimeMultipleBufferSetting,
    group: "catchment",
    key: "catchmentStateDefaults.buffer",
    default: 0.002,
    precision: 0.0001,
    type: "Float",
    min: 0,
    max: 0.002,
    da: "Rejsetids Buffer",
    en: "Traveltime Buffer"
  },
  {
    access: Feature.Debugging,
    group: "debugging",
    key: "debuggingShowExtraDebugging",
    default: false,
    type: "Boolean",
    da: "Vis ekstra debugging knapper",
    en: "Show extra debugging buttons",
    true: {da:"Vis knapper", en:"show buttons"},
    false: {da:"Skjul knapper", en:"hide buttons"}
  }
]

function removeUndefined(node) {
  let newNode = node
  let keys = Object.keys(newNode).filter((a) => {
    // Remove undefined
    return newNode[a] != undefined
  }).filter((a) => {
    // Remove resetted
    return !(newNode[a].reset || false)
  }).filter((a) => {
    // Remove disabled
    return !(newNode[a].disabled || false)
  })
  let result = {}
  keys.forEach((a) => {
    result = {...result, [a]: newNode[a].value}
  }) 
  return result
}

async function onClickSaveCustomUserSettings(localSettings, sessionState, onClose, appMessageDispatch, source:SettingsFixedPriorities): Promise<void> {
  let scope = PersistenceScope.User
  switch (source) {
    case SettingsFixedPriorities.CustomerSettings:
      scope = PersistenceScope.Customer
      break;
    case SettingsFixedPriorities.UserSettings:
      scope = PersistenceScope.User
      break;
    default:
      return appMessageDispatch(actionSetErrorMessage(Localization.getText("Unknown location for settings")));
  }

  let inst = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"));
  let objectRef = inst.createJSONObjectRef(PersistenceObjectType.Settings, "Mapit");
  let settings = removeUndefined(localSettings);
  onClose();
  let metaData = {
    filename: encodeURIComponent(objectRef),
    lastModified: new Date().getTime().toString()
  };
  inst.uploadObject(scope, PersistenceObjectType.Settings, sessionState.customerRef, sessionState.userRef, JSON.stringify(settings), objectRef, metaData)
    .catch(error => {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Could not save settings")));
        Logger.logError("SettingsEditor", "onClickSaveCustomUserSettings", error);
    });
}

function format2Digits(number:number) {
  return number.toLocaleString('dk-da', {minimumIntegerDigits:2})
}

function getSource(key: string):number {
  let Splitted = key.split(SETTINGS_PATH_SELECTOR_SEPARATOR)
  if (Splitted.length === 1) {
    return SettingsManager.getState().settings[Splitted[0]].source.priority
  }
  let cursor = SettingsManager.getState().settings
  for (let idx = 0; idx < Splitted.length; idx++) {
    const element = Splitted[idx];
    if (idx == Splitted.length - 1) {
      return cursor[element]?.source?.priority || -1
    }
    cursor = (cursor[element].value as SettingsValues)
  }    
  return 0
}

function PersistenceScopeToSettingsFixedPriorities(scope: PersistenceScope) {
  switch (scope) {
    case PersistenceScope.User: return 60
    case PersistenceScope.Customer: return 50
    default: return 60
  }
}