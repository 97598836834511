import React, { useEffect, useRef, useState } from "react"
import { PoiManager } from "../managers/PoiManager";
import { TMouseEventCallbackParams } from "../components/Distances";
import { MapView } from "../components/MapView";
import UseWindowDimensions from "../components/useWindowDimensions";
import { BsXLg } from "react-icons/bs"


const initThisVal = {
  big: false,
  hidden: true,
  animating: false,
}

export function FloatMap (props:{params: TMouseEventCallbackParams | undefined ,boundBox: any, scroll: boolean}) {
  let [thisVal, setThisVal] = useState(initThisVal);
  let [currentStyle, setCurrentStyle] = useState({opacity:0} as React.CSSProperties);
  let [ekstraClass, setEkstraClass] = useState("")
  let mapFramRef = useRef(null);
  const { height, width } = UseWindowDimensions();
  let timer = useRef(null as any);
  let [subscription, setSubscription] = useState(0);

  useEffect(() => {
    PoiManager.instance().subscribe("POS",setSubscription)
  },[])

  useEffect(() => {
    if (props.params){
      if (props.params.type) {
        let poi = PoiManager.instance().getPoi(props.params.type);
        if (poi) {
          if (poi.routepolyline) {
            return;
          }
        }
      }
    }
    // CloseHandler()
  },[subscription])

  useEffect(() => {
    if (props.params === undefined) {
      return
    }
    if (thisVal.big) {
      if (props.boundBox && props.boundBox.current) {
        let {y,width} = props.boundBox.current.getBoundingClientRect();
        if (window.innerHeight < 480 || window.innerWidth < 480) {
          setCurrentStyle({top:"0px", height:"calc(var(--vh, 1vh) * 100)", width:Math.min(800, width), left: (Math.floor(width - Math.min(800,width))/2)})
        } else {
          let top = props.scroll ? 0:75
          setCurrentStyle({top:top, height:"calc(calc(var(--vh, 1vh) * 100) - "+top+"px)", width:Math.min(800, width), left: (Math.floor(width - Math.min(800,width))/2)})
        }
      }
      return
    }
    let {x, y} = props.params.pos;
    y = Math.min(y+10, height-350)
    x = Math.min(x+10, width-350)
    if (thisVal.hidden === true && thisVal.animating === true) {
      timer.current = setTimeout(() => {
        setThisVal({...thisVal, hidden: true, animating: false});
      }, 200);
      setCurrentStyle({top:y,left:x, opacity: 0})
      return;
    }
    if (!thisVal.animating) {
      switch( props.params.event ) {
        case "MouseClick":
          setThisVal({...thisVal, big: true})
          setEkstraClass("map-frameAni")
          break;
        case "MouseMove":
          clearTimeout(timer.current);
          if (thisVal.hidden) {
            setThisVal({...thisVal, hidden: false});
          }
          break;
        case "MouseLeave":
          if (!thisVal.hidden) {
            timer.current = setTimeout(() => setThisVal({...thisVal, hidden: true, animating: true}), 300)
          }
          break;
      }
    }

    setCurrentStyle({top:y,left:x, opacity: thisVal.hidden ? 0 : 1})

  },[height, props.boundBox, props.params, thisVal, width, props.scroll])



  function CloseHandler() {
    setThisVal({big:false, animating:true,hidden:true})
    setTimeout(() => setEkstraClass(""),200)
  }

  return (
    <div ref={mapFramRef} className={ekstraClass + " map-frame"} style={{pointerEvents: thisVal.big ? "auto":"none" , width:"320px",height:"320px",...currentStyle}}>
      <div className="map-container">
      <button onClick={(e) => CloseHandler()} className="close_button bi bi-x-circle" style={{opacity: thisVal.big ? 1:0}}><BsXLg /></button>
      <MapView mapFrame={mapFramRef} big={thisVal.big} type={props.params?.type || ""} />
      </div>
    </div>
  )
}