import * as React from 'react';
import { Image } from 'react-bootstrap';
import {Localization, Utils} from '@viamap/viamap2-common';
import { GenerateGeom } from '../managers/GenerateGeom';
import { MapitStateContext, actionSetPoiTypesToShow } from 'src/states/MapitState';
import { SettingsManager } from '@viamap/viamap2-common';
import { POIS } from 'src/HLED/managers/PoiManager';
import { BsArrowUpRightSquareFill } from 'react-icons/bs';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';

type PoiSelectornProps = {
  onSavePoisAsLayer?:(poiTypesToShow:string[]) => void
}

export function PoiSelector(props:PoiSelectornProps) {
  let POIsInUse = SettingsManager.getSystemSetting("POIsInUse", ["supermarket","stop","train","strain","metro","chargingstation","supercharger"]);
  const {hasAccessToFeature} = React.useContext(ApplicationStateContext);
  let {state, dispatch} = React.useContext(MapitStateContext)
  let [currZoom, setCurrZoom] = React.useState(0);

  React.useEffect(() => {
    if (state.map) {
      let map = state.map
      setCurrZoom(map.getZoom())
      const ZoomFunc = (e) => {
        setCurrZoom(e.target.getZoom())
      };
      map.on('move',ZoomFunc)
      return () => map.off('move',ZoomFunc)
    }
  },[state.map])
  
  let isInactive = !state.viewPoi;

  function inActive(key:string) {
    return (currZoom && ((POIS[key].maxZoom as number) ?? SettingsManager.getSystemSetting("MaxZoomPoi", 12, true) as number) > currZoom)
  }

  function onClickToogle(key:string) {
    let CurrentList = state.poiTypesToShow || []

    if (inActive(key)) {
      state.map.easeTo({
        zoom: POIS[key].maxZoom ?? SettingsManager.getSystemSetting("MaxZoomPoi", 12, true)
      })
    }
    
    let newList = [key,...CurrentList]
    if (CurrentList.includes(key) && isInactive) {
      newList = CurrentList
    } else if (CurrentList.includes(key)) {
      newList = CurrentList.filter((a) => a !== key)
    }
    dispatch(actionSetPoiTypesToShow(newList));
  }

  function onClickCreateLayerForPois() {
    props.onSavePoisAsLayer && props.onSavePoisAsLayer(state.poiTypesToShow);
  }

  let saveAsNewLayersButton = (true || props.onSavePoisAsLayer) ? (
    <div>
      <hr />
      <div className="mit-poi-selector-poi-icon">
        <BsArrowUpRightSquareFill
          style={{
            fontSize: "25px",
            fontWeight: "bolder",
            margin: "auto",
            marginTop: "5px",
            color: "#45465B",
          }}
          onClick={() => onClickCreateLayerForPois()}
          title={Localization.getText("Click to save visible pois as new layer")}
        />
      </div>
    </div>
  ) : null;
  let result = POIsInUse.map((key) => <PoiIconButton key={key} pKey={key} inActive={inActive(key)} isSelected={state.poiTypesToShow?.includes(key)} onChange={(e:string) => onClickToogle(e)} />)
  if (hasAccessToFeature(Feature.PoiSaveAsNewLayer)) {
    result.push(saveAsNewLayersButton);
  }

  return result;
}


type PoiIconButtonProps = {
  pKey:string
  inActive:{}
  isSelected:boolean
  onChange:(e:string) => void
}
function PoiIconButton(props:PoiIconButtonProps) {
  if (!POIS[props.pKey]) {
    return null;
  }
  if (POIS[props.pKey].featureGroup) {
    return null;
  }
  let icon=POIS[props.pKey].icon;
  let label=POIS[props.pKey][Localization.getLanguage()];
  let isSelected = props.isSelected;
  let imageUrl:any
  try {
    if (document.body.className === "NewSecTheme") {
      imageUrl = new URL("/images/markersNewSec/"+icon+".svg", import.meta.url).href;
    } else {
      imageUrl = new URL("/images/markers/"+icon+".svg", import.meta.url).href;
    }
  } catch {
    imageUrl = new URL("/images/markers/"+icon+".svg", import.meta.url).href;
  }
  let backgroundColor = isSelected ? "white" : "";
  
  return (
      <div className="mit-poi-selector-poi-icon" 
        data-title={props.inActive ?  Localization.getText("Zoom in to see POI") : label}
      >
      <Image
          className={"mit-poi-button" + (isSelected ? " active" : "") + (props.inActive ? " inactive" : "")}
          draggable={false}
          src={imageUrl} 
          data-toggle="tooltip" 
          onClick={()=> props.onChange(props.pKey)}
      />
      </div>
  );
}
