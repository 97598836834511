import * as React from 'react';
import {ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar} from 'recharts';
import {Container, Row, Col} from 'react-bootstrap';

import {AppMessagesContext, Logger, actionSetErrorMessage} from '@viamap/viamap2-common';
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import { MitLatLng } from 'src/managers/MapFacade';
import { CircleAreaMap } from './CircleAreaMap';

import './DemographyReport.css';

export type DemographyReportMetaData = {                
    center:MitLatLng,
    radiusMeters:number,
    reportName:string,
    reportDate:Date,
    logo:string,
    address?: string,
    languageCode: string
};

type State = {
  showWindow:boolean;
  data:any;
  metaData:DemographyReportMetaData;
  callback: ()=> void; 
};

type Props = State;

export class ReportTranslation {

  private static simpleTranslationTable = {
      "en":{
          "Averages": "Averages",
          "VariableName": "Variable Name",
          "HighestPersonalIncome": "Highest Personal Income",
          "SelectionAverage": "Selection Average",
          "ComparisonAverage": "Countrywide Average",
          "Index": "Index",
          "HouseholdIncome": "Household Income",
          "HouseholdAssets": "Household Assets",
          "Age": "Age",
          "NumberOfChildren": "Number Of Children",
          "ResidenceArea": "Residence Area",
          "AgeOldest": "Age Oldest",
          "BbrBuildingYear": "Building Year",
          "EducationLevel": "Education Level",
          "EducationElementary": "Elementary",
          "EducationGymnasial": "Gymnasial",
          "EducationVocational": "Vocational",
          "EducationShortCycle": "College (short)",
          "EducationMidCycle": "College (med)",
          "EducationLongCycle": "College (long)",
          "Total": "Total",
          "Children": "Children",
          "0Children": "0 Children",
          "1Child": "1 Child",
          "2Children": "2 Children",
          "3ChildrenPlus": "3+ Children",
          "CivilStatus": "Civil Status",
          "Unmarried": "Unmarried",
          "Married": "Married",
          "Divorced": "Divorced",
          "Widow(er)": "Widow(er)",
          "CarAvailability": "Car Availability",
          "0Cars": "0 Cars",
          "1Car": "1 Car",
          "2CarsPlus": "2+ Cars",
          "Occupation": "Occupation",
          "SocioHighSelfEmployed": "High Self Employed",
          "SocioMiddle": "Middle",
          "SocioGround": "Ground",
          "SocioUnemployed": "Unemployed",
          "SocioRetired": "Retired",
          "SocioOther": "Other",
          "<100k": "<100k",
          "100k-199k": "100k-199k",
          "200k-299k": "200k-299k",
          "300k-399k": "300k-399k",
          "400k-499k": "400k-499k",
          "500k-599k": "500k-599k",
          "600k-799k": "600k-799k",
          "800k-999k": "800k-999k",
          ">1000k": ">1000k",
          "600k-699k": "600k-699k",
          "700k-799k": "700k-799k",
          "800k-899k": "800k-899k",
          "900-999k": "900-999k",
          "1000k-1499k": "1000k-1499k",
          "1500k+": "1500k+",
          "UnderMinus250k": "Under Minus 250k",
          "Minus250kToMinus50k": "Minus 250k To Minus 50k",
          "Minus50kToPlus50k": "Minus 50k To Plus 50k",
          "Plus50kTo650k": "Plus 50k To 650k",
          "Plus650kTo1500k": "Plus 650k To 1500k",
          "Plus1500kToPlus3000k": "Plus 1500k To Plus 3000k",
          "Plus3000k": "Plus 3000k",
          "MosaicGroups": "Mosaic Groups",
          "MosaicTypes": "Mosaic Types",
          "SelectionCount":"Selection Count",
          "SelectionPercentage":"Selection Percentage",
          "ComparisonPercentage":"Countrywide Percentage",
          "BbrResidenceArea":"Unit Size (sqm)",
          "BbrOwnerRelation":"Ownership Type",
          "Owner":"Owner",
          "Rented":"Rented",
          "Coop":"Coop",
          "Selected Area %":"Selected Area %",
          "DK %":"Countrywide %",
          "A": "Established wealthy",
          "B": "Modern wealthy",
          "C": "Well-educated on the way",
          "D": "Youth community in the city",
          "E": "Singles under education",
          "F": "Wealthy in the province",
          "G": "Profit in the midst of life",
          "H": "Detached house and family fun",
          "I": "Social differences",
          "J": "Seniors with a plus on the account",
          "K": "Caring in the 3. age",
          "L": "Elderly life in the countryside",
          "A01":"Sunny side",
          "A02":"Culture and education",
          "B03":"Job and marriage",
          "B04":"Professional perfection",
          "B05":"Leadership",
          "B06":"Success in the suburbs",
          "C07": "Kids and careers in the big city",
          "C08": "Hipster with a career",
          "D09": "At a café with a child",
          "D10": "Cooperative housing in CPH",
          "D11": "Parental buying in the city",
          "E12": "College",
          "E13": "Student",
          "E14": "Housing association",
          "E15": "Young tenant",
          "E16": "Shawarma youth",
          "F17": "Between country and city",
          "F18": "Friday sweets and DIY",
          "F19": "Business owner and traditional",
          "F20": "The new neighbors",
          "G21": "Home sweet home",
          "G22": "Family life",
          "G23": "Family camping and DIY",
          "G24": "CPH commuters",
          "H25": "The new working families",
          "H26": "Working in the countryside",
          "H27": "Dog, caravan and trailer",
          "I28": "Job seekers",
          "I29": "Ethnic melting pot",
          "I30": "Single city tenant",
          "I31": "Transient wear and tear",
          "I32": "Public housing in the suburbs",
          "J33": "Established elderly",
          "J34": "Profits by the coast",
          "J35": "Life in the townhouse",
          "J36": "Cozy in the village",
          "J37": "Condo in the suburbs",
          "J38": "Senior single",
          "K39": "Older citizens with no extra",
          "K40": "Bingo and crossword puzzles",
          "L41": "Familiar in the countryside",
          "L42": "Retire with space and affordability",
          "L43": "Seaside retirement",
          "L44": "Rents in the village",
          // Life Classifications
          "NXAClassifications": "Life Classifications",
      },
      "da": {
          "Averages":"Overblik", 
          "VariableName":"Variabel",
          "HighestPersonalIncome":"Højeste personlige indkomst",
          "SelectionAverage":"Områdets gennemsnit",
          "ComparisonAverage":"Landsgennemsnit",
          "Index":"Index",
          "HouseholdIncome":"Husstandsindkomst",
          "HouseholdAssets":"Husstandsformue",
          "Age":"Alder",
          "NumberOfChildren":"Antal børn",
          "ResidenceArea":"Boligareal",
          "AgeOldest":"Ældste person i husstand",
          "BbrBuildingYear":"Opførelsesår", 
          "EducationLevel":"Højeste uddannelse",
          "EducationElementary":"Folkeskole",
          "EducationGymnasial":"Gymnasial",
          "EducationVocational":"Erhvervsfaglig",
          "EducationShortCycle":"Kort videregående",
          "EducationMidCycle":"Mellemlang videregående" ,
          "EducationLongCycle":"Lang videregående",
          "Total":"I alt",
          "Children":"Børn", 
          "0Children":"0 Børn",
          "1Child":"1 Barn",
          "2Children":"2 Børn",
          "3ChildrenPlus":"3+ Børn",
          "CivilStatus":"Civilstand",
          "Unmarried":"Ugift",
          "Married":"Gift",
          "Divorced":"Fraskilt",
          "Widow(er)":"Enke(mand)",
          "CarAvailability":"Bilrådighed",
          "0Cars":"0 Biler",
          "1Car":"1 Bil",
          "2CarsPlus":"2+ Biler",
          "Occupation":"Beskæftigelse",
          "SocioHighSelfEmployed":"Højniveau/selvstændig",
          "SocioMiddle":"Mellemniveau",
          "SocioGround":"Grundniveau",
          "SocioUnemployed":"Arbejdsløs",
          "SocioRetired":"Pensionist",
          "SocioOther":"Øvrige",
          "<100k":"<100.000 kr.",
          "100k-199k":"100.000 - 199.999 kr.",
          "200k-299k":"200.000 - 299.999 kr.",
          "300k-399k":"300.000 - 399.999 kr.",
          "400k-499k":"400.000 - 499.999 kr.",
          "500k-599k":"500.000 - 599.999 kr.",
          "600k-799k":"600.000 - 799.999 kr.",
          "800k-999k":"800.000 - 999.999 kr.",
          ">1000k": "Over 1.000,000 kr.",
          "600k-699k":"600.000 - 699.999 kr.",
          "700k-799k":"700.000 - 799.999 kr.",
          "800k-899k":"800.000 - 899.999 kr.",
          "900-999k":"900.000 - 999.999 kr.",
          "1000k-1499k":"1.000.000 - 1.499.999 kr.",
          "1500k+":"Over 1.500.000 kr.",
          "UnderMinus250k":"<-250.000 kr.",
          "Minus250kToMinus50k":"<-50.000 kr.",
          "Minus50kToPlus50k":"<50.000 kr.",
          "Plus50kTo650k":"<650.000 kr.",
          "Plus650kTo1500k":"<1.500.000 kr.",
          "Plus1500kToPlus3000k":"<3.000.000 kr.",
          "Plus3000k":"+3.000.000 kr.",
          "MosaicGroups":"Mosaic Grupper",
          "MosaicTypes":"Mosaic Typer",
          "SelectionCount":"Antal",
          "SelectionPercentage":"Gennemsnit",
          "ComparisonPercentage":"Landsgennemsnit",
          "BbrResidenceArea":"Boligstørrelse",
          "BbrOwnerRelation":"Ejerskabstype",
          "Owner":"Ejer",
          "Rented":"Lejer",
          "Coop":"Andel",
          "Selected Area %":"Områdets %",
          "DK %":"DK %",
          "A": "Etablerede velhavere",
        "B": "Moderne formuende",
        "C": "Veluddannede på vej",
        "D": "Ungt fællesskab i byen",
        "E": "Singler under uddannelse",
        "F": "Velstillede i provinsen",
        "G": "Overskud midt i livet",
        "H": "Parcelhus og familiehygge",
        "I": "Sociale forskelle",
        "J": "Seniorer med plus på kontoen",
        "K": "Omsorg i den 3. alder",
        "L": "Ældreliv på landet",
        "A01": "Solsiden",
        "A02": "Kultur og uddannelse",
        "B03": "Job og ægteskab",
        "B04": "Professionel perfektion",
        "B05": "Lederliv",
        "B06": "Succes i forstaden",
        "C07": "Børn og karriere i storbyen",
        "C08": "Hipster med karriere",
        "D09": "På café med barn",
        "D10": "Andel i København",
        "D11": "Forældrekøb i byen",
        "E12": "På kollegium",
        "E13": "Studerende",
        "E14": "I kollektiv",
        "E15": "Ung lejer",
        "E16": "Shawarmaung",
        "F17": "Mellem land og by",
        "F18": "Fredagsslik og gør-det-selv",
        "F19": "Selvstændig og traditionel",
        "F20": "De nye naboer",
        "G21": "Hjem kære hjem",
        "G22": "Familieliv",
        "G23": "Familiecamping og gør-det-selv",
        "G24": "KBH pendlere",
        "H25": "De nye arbejderfamilier",
        "H26": "Arbejder på landet",
        "H27": "Hund, campingvogn og trailer",
        "I28": "Jobsøgende",
        "I29": "Etnisk smeltedigel",
        "I30": "Single city lejer",
        "I31": "Forbigående slid",
        "I32": "Almen bolig i forstaden",
        "J33": "Etableret ældre",
        "J34": "Overskud ved kysten",
        "J35": "Liv i rækkehuset",
        "J36": "Hygge i landsbyen",
        "J37": "Andelshus i forstaden",
        "J38": "Single senior",
        "K39": "Folkepensionist uden ekstra",
        "K40": "Banko og krydsord",
        "L41": "Lokalkendt på landet",
        "L42": "Otium på plads og råd",
        "L43": "Seaside otium",
        "L44": "Lejer i landsbyen",
        // Life Classifications
        "NXAClassifications": "Life Klassifikationer",
        "A1":	"A1: Ambitiøs og nysgerrig studerende",
        "A2":	"A2: Karrierekometer og høj puls",
        "A3":	"A3: Faglærte singler",
        "A4":	"A4: Midt i livet, midt i byen",
        "B1":	"B1: Familier i balance",
        "B2":	"B2: Moderne familieliv",
        "B3":	"B3: Veluddannede og pålidelige",
        "C1":	"C1: Travle og engagerede voksne",
        "C2":	"C2: Solid økonomi og stærkt sammenhold",
        "C3":	"C3: Aktive pensionister med overskud",
        "C4":	"C4: Enlig ældre med appetit på livet",
        "D1":	"D1: Villa og vandkant",
        "E1":	"E1: Ældrepleje og omsorg"
      }
    };

    static getTextSpecificLanguage(languageCode:string, englishText:string):string {
      if (!this.simpleTranslationTable[languageCode]) {
        throw Utils.createErrorEventObject("Unsupported translation language:"+languageCode);
      } 
      var result = this.simpleTranslationTable[languageCode][englishText] || englishText;
      // if (!result) {
      //     result = Utils.formatString("No translation found for ({text})", {text:englishText});
          
      //     Logger.logWarning("Localization","getText",result);
      // } 
      return result;
    }
  
  
}

type TableProps = {
  data:any,
  header:any,
  metaData:any,
}

export function DemographyReportSectionTable(props: TableProps) {
  
  function formatValue(columnHeader:string, value:any, numberFormat?:string) {
        if (typeof value === "number") {
            let result;
            if (columnHeader.toLowerCase().includes("count")
              || columnHeader.toLowerCase().includes("index")
              ) {
                    result = value.toFixed(0);
              } else {
                if (columnHeader.toLowerCase().includes("percent")) {
                    result = (100*value).toFixed(1)+"%";
                } else {
                  if (value > 10000) {
                    result = value.toFixed(0);
                  } else {
                    result = value.toFixed(2);
                  }
                }
              } 
            return result;
        } else {
            return value;
        }
    }

    if (!(props.data && props.header && props.metaData)) {
      return (
        <>
        {"Missing Data"}
        </>
      )
    }

    let tableheaders:any[]=[];
    let element0 = props.data[0];
    Object.keys(element0).forEach((key2,idx) => {
        let align;
        if (idx===0) {
//            key2 = props.header;
            key2=""; // Keep this cell empty
            align="left";
        } else {
            align="right";
        }
        key2 = ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode, key2);
        let elm = (
            <th style={{textAlign:align}} key={"xx"+idx}>{key2}</th>
          );
        tableheaders.push(elm);
    });

    let dataRows:any[]=[];
    props.data.forEach((element, idx2) => {
      let row:any[]=[];
      Object.keys(element).forEach((key2,idx) => {
        let dataKey=key2;
        let val = element[key2];
        let align;
        if (idx === 0) {
            align="left";
            val = ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode, val);
        } else {
            align="right";
        }
        let elm = (
          <td style={{textAlign:align}} key={"rw"+idx}>{formatValue(key2, val)}</td>
        );
        row.push(elm);
      });
      let r = (
        <tr key={"ssa"+idx2}>{row}</tr>
      );
      dataRows.push(r);
    });
    let columnCount=Object.keys(props.data[0]).length;
    let header = ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode, props.header);
    return (
<table id="mit-demography-report-detailsTable">
  <thead>
    <tr>
      <th colSpan={columnCount} style={{fontSize:"12pt", fontWeight:"bold"}}>{header}</th>
    </tr>
    <tr>
      {tableheaders}    
    </tr>
  </thead>
  <tbody>
    {dataRows}
  </tbody>
</table>
    );
}



export function DemographyReportSectionChart(props:TableProps) {

  /**
   * Is text is too long it is truncated and '...' added. 
   * @param limit 
   * @param text 
   */
  function limitLabelLength(limit:number, text:string):string {
    if (text.length > limit) {
       text = text.substr(0, limit-3)+"...";
    }
    return text;
  }



  if (!(props.data && props.header && props.metaData)) {
    return (
      <>
      {"Missing Data"}
      </>
    )
  }

    let result:any[] = [];
    props.data.forEach((element) => {
        // dont put total on the graph
        if (!element.VariableName.includes("Total")) {
            let txt = ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode, element.VariableName);
            let row = {name:limitLabelLength(15,txt)};
            Object.keys(element).forEach((key2:string,idx:number) => {
               let dataKey=key2;
               if (key2.includes("Selection")) {
                  dataKey = "pv";
               } else {
                  if (key2.includes("Comparison")) {
                     dataKey = "uv";
                  }
               }
               let val = element[key2];
               row = { ...row, [dataKey]:val};
            });
            result.push(row);
        }
    });
    let data=result;

    const toPercent = (decimal, fixed = 0) => {
        let pct = (100*decimal).toFixed(fixed)+"%";
        return pct;
    };

    let selAreaText=ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode,"Selected Area %");
    let dkAreaText = ReportTranslation.getTextSpecificLanguage(props.metaData.languageCode,"DK %");
    return (
      // <div style={{display:"block", position:"relative", fontFamily:"Calibri"}}>
      // <div id="mit-demography-report-chartcontainer">
//      <ResponsiveContainer width="80%" height="200">
      <BarChart width={400} height={200} data={data} margin={{ top: 10, right: 50, left: 10, bottom: 10 }}>
        <CartesianGrid vertical={false}/>
        <XAxis 
            dataKey="name"
            tick={{ rotate: -30, textAnchor:"end", fontSize: 8, width:"20px"}}
            interval={0}
            height={60}
        />
        <YAxis tickFormatter={toPercent} tick={{fontSize:10}} />
        {/* <YAxis tickFormatter={toPercent} tick={{fontSize:12}} domain={[0, dataMax => (Math.abs(dataMax*10)+1)/10]} /> */}
        <Tooltip /> 
        <Legend verticalAlign="bottom" align="center" iconSize={12}/>
        {/* <Bar name="Selected Area %" dataKey="pv" fill="rgb(79,129,189)" />
        <Bar name="DK %" dataKey="uv" fill="rgb(192,80,77)" /> */}
        <Bar name={selAreaText} dataKey="pv" fill="#5D85BD" />
        <Bar name={dkAreaText} dataKey="uv" fill="#C68F57" />
      </BarChart>
 //     </ResponsiveContainer>
      // </div>
      );
}

export function DemographyReportSection(props:TableProps) {
    let renderTable = props.data && props.header !== "MosaicGroups" && props.header !== "MosaicTypes" ? (
        <DemographyReportSectionTable header={props.header} data={props.data} metaData={props.metaData}/>
    ) : null;
    let renderChart = props.header !== "MosaicGroups" && props.header !== "MosaicTypes" ? (
        <DemographyReportSectionChart header={props.header} data={props.data} metaData={props.metaData}/>
    ) : null;

    return  (renderTable && renderChart) ? (
       <Container className="no-page-break" style={{paddingTop:"50px"}}>
          <Row>
             <Col lg={7} sm={12} md={12} xs={12}>
             {renderTable}
             </Col>
             <Col lg={5} sm={12} md={12} className='hidden-xs' >
             {renderChart}
             </Col>
          </Row>
       </Container>
    ): null;
}

/**
 * Renders map of the report area.
 * If there is an Averages data section this is rendered to the left of map
 */
export function DemographyReportAveragesAndMap(props:TableProps) {
  
    let renderTable = props.data ? (
        <DemographyReportSectionTable header={props.header} data={props.data} metaData={props.metaData}/>
    ) : null;
    let renderMap = (
      <div className="DemographyReportMap" style={{width:"400px", height:"200px"}}>
      <CircleAreaMap
      center={props.metaData.center}
      radiusMeters={props.metaData.radiusMeters}
      />
      </div>
    );

    if (renderTable) {
      return (
        <Container className="no-page-break" style={{paddingTop:"50px"}}>
            <Row>
              <Col lg={7} sm={12} md={12} xs={12}>
              {renderTable}
              </Col>
              <Col lg={5} sm={12} md={12} className='hidden-xs' >
              {renderMap}
              </Col>
            </Row>
        </Container>
      );
    } else {
      return (
        <Container className="no-page-break" style={{paddingTop:"50px"}}>
          <Row>
            <Col lg={7} sm={12} md={12} xs={12}>
            {renderMap}
            </Col>
            <Col lg={5} sm={12} md={12} className='hidden-xs' >
            {null}
            </Col>
          </Row>
        </Container>
      );
    }
}

export function DemographyReport(props:State) {
  const {dispatch:appMessageDispatch} = React.useContext(AppMessagesContext);
  
    if (props.showWindow) {

      let reportName = props.metaData.reportName;
      let reportDate = props.metaData.reportDate;
      let center = props.metaData.center;
      let radiusMeters = props.metaData.radiusMeters;
      let address = props.metaData.address;
      let languageCode = props.metaData.languageCode;

      // let translatedData = this.translateReportData(languageCode, props.data);

      // todo: add create PDF button.

      // todo: render logo from url instead of css (for easier change):  <Image className={imageClassName} src={val} responsive={true}/>
      let RenderLogo = props.metaData.logo ? (
        <div className={props.metaData.logo} id={props.metaData.logo}/>
      ) : null ;
      let OptionalAddressRow = address ? (
        <tr>
          <td>{Localization.getTextSpecificLanguage(languageCode,"Report:Address")}</td>
          <td>{address}</td>
        </tr>
      ) : null;

      let reportInformation = (
         <Container>
         <Row>
            <Col lg={7} md={7} sm={12} xs={12}>
            <table id="mit-demography-report-infoTable">
         <tbody>
            <tr>
              <td>{Localization.getTextSpecificLanguage(languageCode,"Report")}</td>
              <td>{reportName}</td>
            </tr>
            <tr>
              <td>{Localization.getTextSpecificLanguage(languageCode,"Date")}</td>
              <td>{reportDate.toLocaleString('da-DK')}</td>
            </tr>
            {OptionalAddressRow}
            <tr>
              <td>{Localization.getTextSpecificLanguage(languageCode,"Report:Position (lat/lng)")}</td>
              <td>{Localization.getFormattedText("Report:{lat}/{lng}",{lat:center.lat.toFixed(4), lng:center.lng.toFixed(4)})}</td>
            </tr>
            <tr>
              <td>{Localization.getTextSpecificLanguage(languageCode,"Report:Radius")}</td>
              <td>{Localization.getFormattedText("Report:{radius}m",{radius:radiusMeters.toFixed(0)})}</td>
            </tr>
         </tbody>
         </table>
            </Col>
            <Col lg={5} md={5} sm={12} className='hidden-xs' >
              {RenderLogo}
            </Col>
         </Row>
      </Container>
      );

      let linkToDocumentation=SettingsManager.getSystemSetting("ambitionVariableDocumentationLink");
      let dataSourcesText=Localization.getTextSpecificLanguage(languageCode,"Datasources: Danmarks Statistik and OIS");

      let reportFooter = (
        <Container className="no-page-break" style={{paddingTop:"50px"}}>
        <Row>
           <Col lg={12} sm={12} md={12} xs={12}>
            <div className="mit-demography-report-footer">
          {dataSourcesText}. Specification: <a href={linkToDocumentation} target="_blank">Dataelement specification</a>
            </div>
           </Col>
        </Row>
       </Container>
      );

      let json = props.data;
//       /* tslint:disable-next-line */      
//       if (json["Averages"]) {
//         /* tslint:disable-next-line */        
// //        delete json["Averages"];
//         /* tslint:disable-next-line */        
//         json["Averages"] = [{"VariableName":"Alder","Opdelt på":"Grupper"}];
//       }
      let averagesKey="Averages";
      let averagesAndMap = (
        <DemographyReportAveragesAndMap
          data={json[averagesKey]}
          metaData={props.metaData}
          header={averagesKey}
        />
      );

      let sections:JSX.Element[] = [];
      try {
        Object.keys(json).forEach(key => {
          // If averages section exists it is rendered in <DemographyReportAveragesAndMap> above. Don't also render in loop below.
          if (key !== averagesKey) {
            let title=key;
            let list = json[key];
            let elm = (
              <DemographyReportSection
                key={"x"+title}
                header={title}
                data={list}
                metaData={props.metaData}
              />
            );
            sections.push(elm);
          }
        });
      } catch (e) {
        Logger.logError("DemographyReport","Show sections", (e as Error).message);
        appMessageDispatch(actionSetErrorMessage((e as Error).message));
        // ToDo: show error to user in user language.
      }

      return (
        <div id="mit-demography-report-view">
            {reportInformation}
            {averagesAndMap}
            {sections}
            {reportFooter}
        </div>
    );
    } else {
        return null;
    }
}
