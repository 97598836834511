import React, { useContext, useReducer, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';

import { Filters } from './Filters';
import { Results } from './Results';
import { ActiveFilters } from './ActiveFilters';
import { ExploreSearchActions, ExploreSearchContext, ExploreSearchReducer, GenericTransactionManager, initialExploreSearchState, transactionalExploreSearchReducer } from '../states/ExploreSearchState';
import { PropertyInformationDialog } from 'src/components/PropertyInformationDialog';
import { usePanWindow } from 'src/common/components/CustomHooks';
import { MdClose } from 'react-icons/md';
import { MapitStateContext, actionSetShowWindow } from 'src/states/MapitState';
import { RightPaneContext, RightPaneModus, actionStepOut, actionToggleModus } from 'src/states/RightPaneState';
import { ProtectedFeature } from 'src/components/ProtectedFeature';
import { actionSetInfoMessage, AppMessagesContext, Localization } from '@viamap/viamap2-common';
import { MitButton } from 'src/components/ComponentUtils';
import { AddressInterface } from 'src/managers/AddressInterface';
import { ProjectContext, handleToBeAdded, setInfo } from 'src/states/ProjectState';
import { Feature } from 'src/states/ApplicationStateFeatures';
import { ViewButtonText } from 'src/propertyInfoTemplates/PropertyComponents';
import { PropertyInfoView } from 'src/propertyInfoTemplates/PropertyInfoView';

type Coords = [number,number]

export const SearchWindow = (props:{show:boolean}) => {
  const [state, dispatch] = useReducer(ExploreSearchReducer, initialExploreSearchState());
  const {dispatch:rightPaneDispatch} = useContext(RightPaneContext)
  const [viewInfoBox, setViewInfoBox] = useState(0)
  const [coords, setViewCoords] = useState<[number,number]>([0,0])
  const [latLng, setLatLng] = useState<{lat:number,lng:number}>({lat:0,lng:0})

  const {state:projState, dispatch:projDispatch} = useContext(ProjectContext)
  const {dispatch:appMsgDispatch} = useContext(AppMessagesContext)

  const [Eventer, ref, clickEventer] = usePanWindow(40)

  // const { state, dispatch } = useContext(ExploreSearchContext);

  function bfeHandler(a,b) {
    setViewInfoBox(a);
    setViewCoords(b);
  }

  React.useEffect(() => {
    //Refreshes PropertyInfo
    setLatLng({lat:coords[1],lng:coords[0]})
  },[coords])

  if (!!!props.show) {
    return <></>
  }

  return (
    <ExploreSearchContext.Provider value={{ state: state, dispatch: GenericTransactionManager.dispatchMiddleware<ExploreSearchActions>(dispatch, transactionalExploreSearchReducer) }}>
      <div className='' style={{position: "fixed", "inset":"0px",zIndex:1,top: "var(--topBarHeight)", pointerEvents:"none"}}>
    <Modal.Dialog className='nModal SearchWindow MitNotInfoFix' ref={ref}>
      <Modal.Header onMouseDown={Eventer} onTouchStart={Eventer}>
        <div className='GrabLines' onMouseDown={clickEventer} />
        {/* <Modal.Title> */}
        <h4>Explore</h4>
        {/* </Modal.Title> */}
        {viewInfoBox ? 
        <ProtectedFeature feature={Feature.AddToProjectUnassignedList} contentsIfNoAccess={<></>} >
          <ViewButtonText onClick={async (e) => {
            if (projState.isEmpty) {
              appMsgDispatch(actionSetInfoMessage(Localization.getText("Please visit the Project tabs, an action is needed!")))
                return
            }
            if (latLng?.lat && latLng?.lng) {
                
                let resp = await AddressInterface.reverseGeocodeFull(latLng.lat,latLng.lng);
                let addrData = await resp.json();
                let jordstykke = await (await fetch(addrData.jordstykke.href)).json()

                let fullAddress = addrData.adressebetegnelse;
                let tmp = fullAddress.split(',');
                let streetAndNumber = tmp[0] || fullAddress;
                let zipAndCity = addrData.postnummer.nr+" "+addrData.postnummer.navn;
                
                projDispatch(handleToBeAdded([{bfeNr:jordstykke.bfenummer,name:streetAndNumber + ", " + zipAndCity ,latLng:[latLng.lat,latLng.lng]}]))
                return
                }
            }} 
            >
            {Localization.getText("Add to Project")}
          </ViewButtonText>
        </ProtectedFeature>
        : null }
        <MdClose className='CloseButton' onClick={() => rightPaneDispatch(actionStepOut())} />
      </Modal.Header>
      <Modal.Body className='bodyLimitHeight' style={{height:"100%"}}>
        <Row className='NS'>
        {!viewInfoBox ? <>
        <Col md={3} ><Filters /></Col>
        </> :null}
        <Col md={viewInfoBox ? 6 : 9} style={{display:"flex", flexDirection:"column"}} >
        <ActiveFilters column={viewInfoBox ? "2" : "3"} />
        <Results selectBFECallback={(a,b) => bfeHandler(a,b)} callBackOnClose={() => setViewInfoBox(0)} showClose={Boolean(viewInfoBox)}  />
        </Col>
        {viewInfoBox ? <>
        <Col md={6} className='InfoBox fixedEjendom' style={{backgroundColor:"#F8F8F6", minWidth:"270px", borderRadius: "9px",boxShadow:"0px 2px 3px #0003", position:"relative"}}>
          <Modal.Body>
          <PropertyInfoView
            key={latLng.lat.toString()}
            foldetUd={true}
            cols={1}
            labelLatLng={{latlng: latLng}}
            callBackOnLoaded={() => {}}
            allowExtendedInformation={true}
          />
          </Modal.Body>
        </Col>
        </> :null}
        </Row>
      </Modal.Body>
    </Modal.Dialog>
          </div>
  </ExploreSearchContext.Provider>
  );
}



