import * as React from 'react';
import {Col, Row, Form, Modal, Alert, FormGroup, FormControl } from 'react-bootstrap';

import {Localization} from "@viamap/viamap2-common";
import {Logger} from '@viamap/viamap2-common';
import {Utils} from '@viamap/viamap2-common';
import {ValidationType, SelectionOptions, LayerInfo, ColumnMapping, ProjectionType, LayerType, MitDataType, 
    MappingPreference, MappingSpec, MappingSpecElement, SheetAnalysisResult, SelectionListWithPrefs, MapInfo} from '../common/managers/Types';
import {MitBody, MitButton, MitCheckBox, MitDialog, MitFooter, MitHeader, MitInfo} from './ComponentUtils';

type Props= {
    showWindow:number;
    mapInfo: MapInfo;
    title?: string;
    callbackOnCancel: ()=>void;
    callbackOnCommit: (mapInfo: MapInfo, readOnly:boolean)=>void;
};

export function SaveMapDialog(props:Props) {
    const [readOnly,setReadOnly] = React.useState(false); // Readonly is false by default 
    const [name, setName] = React.useState(props.mapInfo.mapTitle || "")

    function nameOnChange(event:any) {
        setName(event.target.value);
    }

    React.useEffect(() => {
        setName(props.mapInfo.mapTitle || "")
    },[props.mapInfo.mapTitle])

    // function onChangeReadOnly(e:any) {
    //     setReadOnly(e.target.checked);    
    // }

    function onFormSubmit(e:any) {
        e && e.preventDefault();
        props.callbackOnCommit({...props.mapInfo, mapTitle: name || props.mapInfo.mapTitle}, readOnly);
    }

    function getValidationStateForName():ValidationType {
        const length = name && name.length || 1;
        if (length >= 1) { 
            return 'success'; 
        } else {
            // if (length >= 5) { 
            //     return 'warning'; 
            // } else {
            //     if (length >= 0) { 
                    return 'error'; 
            //     }
            // }
        }
    }
    function validateForm() {return getValidationStateForName() === "success";}

    function handleCancel(e:any) {
        // hide this window
        e && e.preventDefault();
        props.callbackOnCancel();
    }

    
        if (props.showWindow) {
            return(
                <MitDialog show={props.showWindow} style={{left:"50%", transform:"translateX(-50%)", top:"1rem"}} className="Flat-Modal" >
                    <MitHeader position='center'>{props.title ? props.title : Localization.getText("Save map")}
                    </MitHeader>
                <MitBody>
                    <MitInfo style={{marginBottom: "20px"}}>
                        {Localization.getText("Savemap:description")}
                    </MitInfo>
                    <Form /* horizontal={true} */>
                    <FormGroup 
                        as={Row}
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        // validationState="success"
                    >
                    <Form.Label column sm={4}>
                        {Localization.getText("File name")}
                    </Form.Label>
                    <Col sm={8} lg={8}>
                            <FormControl
                                size="sm"
                                type="text"
                                autoComplete='off'
                                value={name}
                                placeholder={Localization.getText("Savemap:defaultFileName")}
                                onChange={(e) => nameOnChange(e)}
                            />
                    </Col>
                    </FormGroup>
                    {/* <FormGroup 
                        as={Row}
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        // validationState="success"
                    >
                        <Form.Label column sm={4} >
                            {Localization.getText("Read only")}
                        </Form.Label>
                        <Col sm={8} lg={8}>
                        <MitCheckBox variant="normal"
                            checked={!!readOnly}
                            onClick={(e) => onChangeReadOnly(e)}
                        />
                        </Col>
                    </FormGroup> */}
{ /*
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <Col as={ControlLabel} sm={2}>
                            {Localization.getText("Protect with password")}
                        </Col>
                        <Col sm={10}>
                            <FormControl
                                size="sm"
                                type="text"
                                value={props.name}
                                placeholder={Localization.getText("Enter password")}
                                onChange={(e) => nameOnChange(e)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <Col as={ControlLabel} sm={2}>
                            {Localization.getText("Expiry Date")}
                        </Col>
                        <Col sm={10}>
                            <FormControl
                                size="sm"
                                type="date"
                                value={props.name}
                                placeholder={Localization.getText("Enter expiry date")}
                                onChange={(e) => nameOnChange(e)}
                            />
                        </Col>
                    </FormGroup>
*/ }
                    {/* <div className="row" style={{marginLeft:"10%", marginRight:"10%"}}>
                        <div className="col-sm-4" ></div>
                        <div className="col-sm-5 Flat-ButtonWrap" >
                            
                        </div>
                    </div> */}
                    </Form>
            </MitBody>
            <MitFooter>
            <MitButton variant='normal' onClick={(e)=>onFormSubmit(e)}>{Localization.getText("Download")}</MitButton>
            <MitButton variant='close'  onClick={(e)=>handleCancel(e)}>{Localization.getText("Cancel")}</MitButton>
            </MitFooter>
            </MitDialog>
            ); 
            } else {
                return (null);
            }

            /*
            
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("File name")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="text"
                            value={props.name}
                            placeholder={Localization.getText("Enter file name")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("Protect With Password")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="text"
                            value={props.name}
                            placeholder={Localization.getText("Enter password")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("Expire Date")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="date"
                            value={props.name}
                            placeholder={Localization.getText("Enter date")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
            */

}
