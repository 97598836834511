
import { AppMessagesContext, AutomaticUserHelpPopup, PersistenceObjectType, PersistenceScope, SessionContext, SettingsManager, ViamapPersistenceLayer, actionClearInfoMessage, actionSetErrorMessage, actionSetInfoMessage } from "@viamap/viamap2-common";
import React, { useEffect } from "react"
import chroma from "chroma-js"
import { Logger } from "@viamap/viamap2-common";
import { AppMode, DawaAutoCompleteResult, LayerInfo, LayerType, LayerVariabilityType, MapInfo, PictureLayoutType, PictureSize, PopupLayout, ViewerState } from "src/common/managers/Types";
import { Utils } from "@viamap/viamap2-common";
import { AWSAPIGatewayInterface } from "src/managers/AWSAPIGatewayInterface";
import { GenerateGeom, SpatialReturnType } from "src/managers/GenerateGeom";
import { LayerFunc } from "src/managers/LayerFunc";
import { Localization } from "@viamap/viamap2-common";
import { MitLatLng } from "src/managers/MapFacade";
import { actionAddDataLayer, actionGenerateDemographyReportSuccess, actionRemoveDataLayer, actionSetMessage, actionSetShowWindow, MapitStateActionType, MapitStateContext, MapitWindowId } from "src/states/MapitState"
import { DemographyReportMetaData } from "./DemographyReport";
import { InfoScreen } from "./InfoScreen";
import { ObliqueViewer } from "./ObliqueViewer";
import { PropertyInformationDialog } from "./PropertyInformationDialog";
// import { ReleaseViewer } from "./ReleaseViewer";
import { ReportResultDialog } from "./ReportResultDialog";
import { SaveMapDialog } from "./SaveMapDialog";
import { SelectCircle } from "./SelectCircle";
import { GenerateReportDialog, SpatialExportDialog } from "./SpatialFunctionDialog";
import { Persistence } from "src/managers/Persistence";
import { MitDialog, MitExample, MitHeader } from "./ComponentUtils";
import { SaveMapLinkDialog } from "./SaveMapLinkDialog";
import { SavedMapDialog } from "./ReadOnlyMapDialog";
import { ApplicationStateContext, Feature, FeatureType } from "src/states/ApplicationState";
import { CatchmentSelectionState, CatchmentSelector } from "./CatchmentSelector";
import { SheetFunc } from "src/managers/SheetFunc";
import { DialogMode, MultiPointTravelDialog } from "./MultiPointTravelDialog";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { MapInteractionStateInfoBox } from "./MapInteractionStateInfoBox";
import { CalculationMode, CatchmentStateContext , actionSetCatchmentState, actionGenerateCatchmentSimple, CatchmentState, MessageType  } from "src/states/CatchmentState";
import * as Turf from "@turf/turf";
import { ReturnTo17 } from "./ReturnTo17";
import { NameOnCreation } from "./NameOnCreation";
import { GDPRNoticeApproval } from "./GDPRNoticeApproval";
import { DownloadDialog } from "./DownloadDialog";
import { actionClearMessages } from "@viamap/viamap2-common";
import { QuickOrthoPhoto } from "src/components/QuickOrthoPhoto";
import { CompanyInformationDialog } from "./CompanyInformationDialog";
import { StreetViewer } from "./StreetViewer";
import { PersonInformationDialog } from "./PersonInformationDialog";
import { CatchmentInterface } from "src/managers/CatchmentInterface";
import OwnershipDiagram from "src/compentsModus/OwnershipDiagram";
import { ExportData } from "src/managers/ExportData";
import { CondominiumDialog } from "src/propertyInfoTemplates/CondominiumDialog";
import { MaplinkOverview } from "./MapLinkOverview";
import { CloudLayerList } from "./CloudLayerList";
// import JSZip from 'jszip';

export const MapitButtonsAndOverlays = () => {
  const { state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters } = React.useContext(MapitStateContext);
  const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = React.useContext(ApplicationStateContext);
  const { state: sessionState, dispatch: sessionStateDispatch } = React.useContext(SessionContext);
  const { dispatch: appMessageDispatch } = React.useContext(AppMessagesContext);
  const {state:catchmentState, dispatch:catchmentStateDispatch} = React.useContext(CatchmentStateContext);
  
  useEffect(() => {
    if (catchmentState.message) {
      switch(catchmentState.messageType) {
        case MessageType.Error: {
          appMessageDispatch(actionSetErrorMessage(catchmentState.message));
          break;
        }
        default: {
          appMessageDispatch(actionSetInfoMessage(catchmentState.message));
        }
      }    
    } else {
      appMessageDispatch(actionClearMessages());
    }
  }, [catchmentState.message])

  // ------------------------------- HANDLERS --------------------------------------

  /**
   * 
   * @param addressInfo The DAWA data structure representing an address
   */
  function callbackOnSearchAddress(addressInfo: DawaAutoCompleteResult) {
    // Lookup the coordinates
    let req = addressInfo && addressInfo.data && addressInfo.data.href;
    req && Utils.asyncFetchJsonFromUrl(req)
      .then((body) => {
        let createNewPoint = true;
        if (applicationState.appMode === AppMode.Embedded) {
          // Check if there is a point already there. Then just zoom.
          throw new Error("Not implemented");
          // let addressLookupResponse:DawaAddressLookupResult = body;
          // let center: MitLatLng = new MitLatLng(addressLookupResponse.adgangspunkt.koordinater[1], addressLookupResponse.adgangspunkt.koordinater[0]);
          // let radius = Utils.getSystemSetting("gotoAdressUseExistingPointRadiusMeters",50);
          // let metaData:PointMetaData[] = GenerateGeom.spatialSelection(mapitState.map!, center, radius);
          // if (metaData && metaData.length > 0) {
          //   // pick the first target, if more.
          //   let {index, lat, lng} = metaData[0];
          //   let p:MitLatLngExpression = [lat,lng];
          //   let bounds: MitLatLngBoundsExpression = MitLatLngBounds(p, p);
          //   // zoom to the area
          //   mapitState.map && mapitState.map.fitBounds(bounds);
          //   createNewPoint = false;
          // }
        }

        if (createNewPoint) {
          // Create a full layer for the adress point.
          let layerInfo = LayerFunc.createLayerInfoForOneAddress(addressInfo, body);
          mapitStateDispatch(actionAddDataLayer(layerInfo, true));
        }
      }
      )
      .catch(error => {
        appMessageDispatch(actionSetErrorMessage(JSON.stringify(error)));
      })
    // hide search window
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.ShowSearchField, false))
  }

  // --------------------------- Demography Report ----------------------------------

  /**
   * Demography Report
   */
  function callbackOnGenerateReport(center: MitLatLng, radiusMeters: number, address?: string) {
    Logger.logAction("MapScreen", "Demography Report", Utils.formatString("center {center} radius {radius} address {address}", { center: center.toString(), radius: radiusMeters, address: address || "" }));
    let utmCoords = GenerateGeom.convertFromLatLngToUTM({ lat: center.lat, lng: center.lng });

    let authHost = SettingsManager.getSystemSetting("ambitionAuthHost");
    let cloudHost = SettingsManager.getSystemSetting("ambitionApiHost");
    let clientId = SettingsManager.getSystemSetting("ambitionClientId");
    let clientSecret = SettingsManager.getSystemSetting("ambitionClientSecret");
    let dataGroup = SettingsManager.getSystemSetting("ambitionDataGroup");
    // ToDo: remove hardcoding
    dataGroup = 9;
    if (!(authHost && cloudHost && clientId && clientSecret && dataGroup)) {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Missing configuration data. Cannot run report")));
    } else {
      appMessageDispatch(actionSetInfoMessage(Localization.getText("Generating Report...")));

      let apiVersion = SettingsManager.getSystemSetting("mapitServicesVersion", "1.0");

      switch (apiVersion) {
        case "1.0":
          throw new Error("Not supported any more");
          break;
        case "2.0":
          let result3 = AWSAPIGatewayInterface.ambitionGetReportDataV2(authHost, clientId, clientSecret, cloudHost, utmCoords.x, utmCoords.y, radiusMeters, dataGroup).then(
            (success2) => {
              Logger.logInfo("MapScreen", "Demography Report", "ReportData:" + JSON.stringify(success2));

              /**
               * Customer Specific Report Logo for 360North
               */
              let demographyReportLogo = SettingsManager.getSystemSetting("demographyReportLogo");
              let demographyReportLanguageCode = Localization.getLanguage();

              let reportName = Localization.getTextSpecificLanguage(demographyReportLanguageCode, "Demography Report");
              let reportDate = new Date();

              let metaData: DemographyReportMetaData = {
                center: center,
                radiusMeters: radiusMeters,
                reportName: reportName,
                reportDate: reportDate,
                logo: demographyReportLogo,
                address: address,
                languageCode: demographyReportLanguageCode
              };

              mapitStateDispatch(actionGenerateDemographyReportSuccess(metaData, success2))
              // Hide report dialog when done
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.GenerateReportDialog, false));
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.GenerateReportResultDialog, true));
              appMessageDispatch(actionClearInfoMessage());
            },
            (error2) => {
              appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Report generation failed: {error}", { error: error2 })));
              //  setState({showGenerateReportDialog:false});
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.GenerateReportDialog, false));
              appMessageDispatch(actionClearInfoMessage());
            }
          );
          break;
        default:
          throw Utils.createErrorEventObject("Unknown or unexpected mapitSystemServicesVersion: " + apiVersion);
      }

    }
  }

  function SpatialSelectionToNewLayers(spatialSelection: SpatialReturnType) {
    let layersToAdd = Object.keys(spatialSelection).map((layerKey) => {
      let layer: LayerInfo = mapitState.layers[layerKey]
      if (!layer) {
        return
      }
      
      let IdsToInclude = spatialSelection[layerKey]
      if (layer.datasheet) {
        let linesToInclude = IdsToInclude.map((obj) => obj + 1);  // correct for 1 header row.
        let sheet = SheetFunc.filterWorkSheet(layer.datasheet!, linesToInclude);
        let data = SheetFunc.sheetToJson(sheet)
        
        const featuresToInclude = IdsToInclude.map((id) => layer.handle.source.data.features[id])
        const features = data.map((a,idx) => {
          const [lng, lat, ..._] = (featuresToInclude[idx].geometry.coordinates as number[])
          return Turf.point([lng,lat], a)
        })
        const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(features, layer.datasetname)
        return (newLayerInfo)
        
      }
      const featuresToInclude = IdsToInclude.map((id) => layer.geoJson.features[id])
      const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(featuresToInclude, layer.datasetname)
      return (newLayerInfo)
    })
    let layersToRename = layersToAdd.filter(a => a)
    if (layersToRename.length) {
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, true, {layers: layersToAdd}))
    }
  }

/**
 * Asserts that the map only contains 1 visible layer, and that the layer is a point layer.
 * @param dispatchErrorMessages Toggle whether or not to dispatch to error handler on error. Defaults to false.
 * @returns True for success, false on error.
 */
function assertMapContainsOneActivePointLayer(dispatchErrorMessages: boolean = false): boolean {
  function displayErrorIfAllowed(message) {
    dispatchErrorMessages && appMessageDispatch(actionSetErrorMessage(Localization.getText(message)));
    return false;
  }

  let layer: LayerInfo;
  let foundVisibleLayers = Object.keys(mapitState.layers).filter((layerKey) => {
    const li = mapitState.layers[layerKey];
    return (li && li.type.includes("Point") && li.visible)
  })

  if (foundVisibleLayers.length > 1) {
    return displayErrorIfAllowed("This feature requires a single point layer. There are more.");
  }
  if (!foundVisibleLayers || foundVisibleLayers.length === 0) {
    return displayErrorIfAllowed("This feature requires a single point layer");
  }
  
  const foundLayer = mapitState.layers[foundVisibleLayers[0]]
  
  if (!(foundLayer.type === LayerType.PointUTM32 || foundLayer.type === LayerType.PointWGS84 || foundLayer.type === LayerType.Point || foundLayer.type === LayerType.GeoJSON_Point)) {
    return displayErrorIfAllowed("This feature requires a single point layer");
  }

  return true
}

  function getViewerState(): ViewerState | undefined {
    if (mapitState.map) {
      return {
        bearing: mapitState.map.getBearing(),
        pitch:  mapitState.map.getPitch(),
        zoomFactor: mapitState.map.getZoom(),
        center: mapitState.map.getCenter()
      };
    } else {
      return undefined;
    }
  }


  function doSaveMapLink(mapInfo?: MapInfo, daysValidity?: number, minZoom?: number, maxZoom?: number, sharedFeatureLayers?: any[], overWriteExstingLinkFile?: boolean, oldLinkName?: string) {
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, false));
    let expireDate = Utils.addDays(new Date(), (daysValidity || 30));
    doMapLink(
      (ref) => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SavedLinkDialog, true, {ref:ref, expireDate:expireDate})),
      "abc",
      expireDate,
      minZoom,
      maxZoom,
      sharedFeatureLayers,
      mapInfo,
      overWriteExstingLinkFile,
      oldLinkName
    );
  }

  async function doMapLink(
    callback: (ref?: string) => void,
    securityKey?: string,
    endDate?: Date,
    minZoom?: number,
    maxZoom?: number,
    sharedFeatureLayers?: any[],
    mapInfo?: MapInfo,
    overWriteExstingLinkFile?: boolean,
    oldLinkName?: string
  ) {
    let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
    Logger.logAction("MapScreen", "Save map link", "Layers= " + layerArray.length);
    let userName = sessionState.userName;
    let userId = sessionState.userRef;
    let currentDate = new Date();
    // todo: only a subset of the features should be available when in readonlyanonymous mode
    let enabledFeatures: FeatureType[] = applicationState.availableFeatures;
    let activeFeatureLayers = mapitState.selectedFeatureLayerKeys
    // tslint:disable-next-line: max-line-length
    let mapState = await Persistence.extractMapState(mapitState ,true, (mapInfo ? mapInfo : mapitState.mapInfo), layerArray, userName, currentDate, true, userId, getViewerState(), endDate, securityKey, mapitState.layerHierarchy, enabledFeatures, minZoom, maxZoom, sharedFeatureLayers, activeFeatureLayers);

    let maxStateSize = SettingsManager.getSystemSetting("maxSavedMapLinkSize", 5900000); // Amazon AWS Lambda has a payload limit of 6MB (and API Gateway has one of 10MB)
    let mapSize = JSON.stringify(mapState).length;
    if (mapSize > maxStateSize) {
      appMessageDispatch(actionSetErrorMessage("Cannot save link. Map exceeds the maximum size of 6MB for linked maps"));
    } else {
      let path = SettingsManager.getSystemSetting("mapLinkOnS3Path", "savedmaps/");
      Persistence.createStateReference(mapState, path, overWriteExstingLinkFile ? oldLinkName : undefined)
        .then((result) => { callback(result); })
        .catch((error) => { appMessageDispatch(actionSetErrorMessage("Persist failed: " + error)); });
      // appMessageDispatch(actionSetErrorMessage("Saved"));
    }

  }

  async function drawMatrikelLocation(matrNr: string, ejerlavskode: number, betegnelse: string): Promise<void> {
    let res = await PropertyInfoInterface.searchCadaster(ejerlavskode.toString(), matrNr);
    if (res.data[0]) {
        let coordinates: number[] = res.data[0].visueltcenter;
        let layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(coordinates[1], coordinates[0]), betegnelse + " " + matrNr, { "Matrikel nummer": matrNr });
        mapitStateDispatch(actionAddDataLayer(layerInfo, true, true));
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.ShowSearchField,false));
    } else {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("No data for given cadaster")));
    }
  }

  async function doSaveMap(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, false))
    let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
    Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
    let fileExtension = hasAccessToFeature(Feature.ThemeNewSec) ? ".estateExplorer" : ".mapit"
    let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + fileExtension;
    let userName = sessionState.userName;
    let userId = sessionState.userRef;
    let currentDate = new Date();
    let enabledFeatures: FeatureType[] = applicationState.availableFeatures;
    
    let saveState = await Persistence.extractMapState(mapitState ,readOnly, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(), endDate, securityKey, mapitState.layerHierarchy, enabledFeatures);
    let json = JSON.stringify(saveState);
    let blob = new Blob([json], { type: "application/json" });

    ExportData.downloadBlob(fileName, blob);
  }

  async function PrivateCloudSave(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
    let domainName = sessionState.customerRef;
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.CloudSaveDialog, false))
    let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
    Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
    let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + ".mapit";
    let userName = sessionState.userName;
    let userId = sessionState.userRef;
    let currentDate = new Date();
    // todo: only a subset of the features should be available when in readonlyanonymous mode
    // tslint:disable-next-line: max-line-length
    let saveState = await Persistence.extractMapState(mapitState ,false, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(), endDate, securityKey, mapitState.layerHierarchy);
    const ref = Persistence.chance.guid();
    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    let prefixPath = persit.createPrefix(PersistenceScope.User, PersistenceObjectType.MapitMaps, domainName, userId)
    const metadata = {
      mapTitle: encodeURI(mapInfo.mapTitle || ""),
      userId: encodeURI(userId),
      userName: encodeURI(userName),
      expirationDate: "null",
      createdDate: encodeURI(new Date().toISOString()),
      layers: Object.keys(mapitState.layers).length.toString(),
      size: JSON.stringify(saveState).length.toString(), // TODO: Find something better
      version: saveState.interfaceVersion?.toString()
    };
    let saveSuccessful = await persit.uploadObjectS3(prefixPath +"/"+ ref, JSON.stringify(saveState), metadata)

    if (saveSuccessful) {
      layerArray.forEach((layer) => {
        mapitStateDispatch(actionRemoveDataLayer(layer.layerId, false))
        layer.awsFilename = ref; // Store the saved filename in the layer for future paths.
        layer.group = ["TilføjedeLag"]
        layer.layerVariability = LayerVariabilityType.FixedFeatureLayer;
        mapitStateDispatch(actionAddDataLayer(layer, false))
      })

      Logger.logAction("MapScreen", "Save cloud layer", "Layers= " + layerArray.length);
      appMessageDispatch(actionSetInfoMessage(Localization.getText("Layer saved as cloud layer")));
    } else {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Failed to save as cloud layer")));
    }
  }

  async function CloudSave(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
    let domainName = sessionState.customerRef;
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.AdminCloudSaveDialog, false))
    let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
    Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
    let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + ".mapit";
    let userName = sessionState.userName;
    let userId = sessionState.userRef;
    let currentDate = new Date();
    // todo: only a subset of the features should be available when in readonlyanonymous mode
    // tslint:disable-next-line: max-line-length
    let saveState = await Persistence.extractMapState(mapitState ,false, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(), endDate, securityKey, mapitState.layerHierarchy);
    const ref = Persistence.chance.guid();
    let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
    let prefixPath = persit.createPrefix(PersistenceScope.Customer, PersistenceObjectType.SavedMaps, domainName)
    const metadata = {
      mapTitle: encodeURI(mapInfo.mapTitle || ""),
      expirationDate: endDate ? endDate.toISOString() : "",
      userId: userId,
      userName: userName
    };
    let saveSuccessful = await persit.uploadObjectS3(prefixPath +"/"+ ref, JSON.stringify(saveState), metadata)

    if (saveSuccessful) {
      layerArray.forEach((layer) => {
        mapitStateDispatch(actionRemoveDataLayer(layer.layerId, false))
        layer.awsFilename = ref; // Store the saved filename in the layer for future paths.
        layer.group = ["TilføjedeLag"]
        layer.layerVariability = LayerVariabilityType.FixedFeatureLayer;
        mapitStateDispatch(actionAddDataLayer(layer, false))
      })

      Logger.logAction("MapScreen", "Save cloud layer", "Layers= " + layerArray.length);
      appMessageDispatch(actionSetInfoMessage(Localization.getText("Layer saved as cloud layer")));
    } else {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Failed to save as cloud layer")));
    }
  }

  function handleReportResultDialogDismiss() {
    // Restore printing for background map
    // let element: HTMLElement | null;
    // element = document.getElementById("mit-mainwindow-map");
    // element && element.classList.remove("no-print");

    mapitStateDispatch(actionSetShowWindow(MapitWindowId.GenerateReportResultDialog, false));
  }

  // -------------------------------------------------------------------------------

  return (
    <div id="Mit-MapOverlay" >
      {/* <MitExample /> */}
      <InfoScreen
        showWindow={windowToBeShownOrder(MapitWindowId.InformationWindow)}
        onFormClose={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.InformationWindow, false))}
      />
      {/* <ShowLocationSelector
                showWindow={showLocationSelector}
                callbackToToggleState={() => callbackOnShowMyLocationToggle()}
                locationStateCode={state.showMyLocationState}
                locationStateText={state.showMyLocationText}
                /> */}
      {/* {mapitState.sessionInfo ? (
        <ReleaseViewer
          expandRelease={(e) => { throw new Error("Not implemented"); }}
          sessionInfo={mapitState.sessionInfo}
          gettingStarted={windowToBeShown(MapitWindowId.GettingStarted)}
          show={windowToBeShown(MapitWindowId.ShowReleases)}
          onClose={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.ShowReleases, false))}
        />
      ) : null} */}
      <AutomaticUserHelpPopup
        sessionState={sessionState}
      />
      <SelectCircle
        showWindow={windowToBeShownOrder(MapitWindowId.SelectCircle)}
      />
      {windowToBeShownOrder(MapitWindowId.DemographyReportDialog) ? (
        <GenerateReportDialog
          showWindow={windowToBeShownOrder(MapitWindowId.DemographyReportDialog)}
          callbackOnCancel={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.DemographyReportDialog, false)); }}
          callbackOnReport={(c, d, a) => {
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.DemographyReportDialog, false));
            callbackOnGenerateReport(c, d, a)
          }}
          map={mapitState.map!}
        />
      ) : null}

      {windowToBeShownOrder(MapitWindowId.SpatialSelection) ? (
        <SpatialExportDialog
          showWindow={windowToBeShownOrder(MapitWindowId.SpatialSelection)}
          callbackOnCancel={() => { mapitStateDispatch(actionSetShowWindow(MapitWindowId.SpatialSelection, false)); }}
          callbackOnExport={(m, c, d) => {
            SpatialSelectionToNewLayers(m)
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.SpatialSelection, false))}
          }
          map={mapitState.map!}
        />
      ) : null}

      <ObliqueViewer
        showWindow={windowToBeShownOrder(MapitWindowId.ObliqueViewer)}
        imageProps={windowToBeShownParameters(MapitWindowId.ObliqueViewer) || { latlng: new MitLatLng(0, 0) }}
        onFormClose={() => {
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.ObliqueViewer, false))
        }}
      />
      <PropertyInformationDialog
        showWindow={windowToBeShownOrder(MapitWindowId.PropertyInformationDialog)}
        labelLatLng={windowToBeShownParameters(MapitWindowId.PropertyInformationDialog) || { latlng: new MitLatLng(0, 0) }}
        callBackOnClose={() => {
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.PropertyInformationDialog, false));
        }}
      allowExtendedInformation={true}
      cols={2}
      />
      <CondominiumDialog 
        showWindow={windowToBeShownOrder(MapitWindowId.CondominiumDialog)}
        bfeNr={windowToBeShownParameters(MapitWindowId.CondominiumDialog)}
        callBackOnClose={() => {
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.CondominiumDialog, false));
        }}
        allowExtendedInformation={true}
      />

      <CompanyInformationDialog
        showWindow={windowToBeShownOrder(MapitWindowId.CompanyInformationDialog)}
        cvrNr={(windowToBeShownParameters(MapitWindowId.CompanyInformationDialog) || {cvrNr:0}).cvrNr}
        callBackOnClose={() => {
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.CompanyInformationDialog, false));
        }}
      />
      <PersonInformationDialog
        showWindow={windowToBeShownOrder(MapitWindowId.PersonInformationDialog)}
        personInfo={windowToBeShownParameters(MapitWindowId.PersonInformationDialog)}
        callBackOnClose={() => {
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.PersonInformationDialog, false));
        }}
      />
      <CloudLayerList 
        show={!!windowToBeShownOrder(MapitWindowId.ViewCloudSaveDialog)}
        close={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.ViewCloudSaveDialog, false))}
      />
      <MaplinkOverview 
        show={!!windowToBeShownOrder(MapitWindowId.MapLinkOverview)}
        close={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.MapLinkOverview, false))}
      />

      <OwnershipDiagram
        showWindow={windowToBeShownOrder(MapitWindowId.OwnerShipDiagram)}
        search={windowToBeShownParameters(MapitWindowId.OwnerShipDiagram)}
      />

      {/* Cloudsaves */}
      <SaveMapDialog
        showWindow={windowToBeShownOrder(MapitWindowId.CloudSaveDialog)}
        mapInfo={mapitState.mapInfo}
        title="Save Map on Cloud"
        callbackOnCancel={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.CloudSaveDialog, false))}
        callbackOnCommit={(mapInfo, readOnly) => { PrivateCloudSave(mapInfo, true)}}
      />
      <SaveMapDialog
        showWindow={windowToBeShownOrder(MapitWindowId.AdminCloudSaveDialog)}
        mapInfo={mapitState.mapInfo}
        title="(ADMIN do not use) Save Map on Cloud"
        callbackOnCancel={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.AdminCloudSaveDialog, false))}
        callbackOnCommit={(mapInfo, readOnly) => { CloudSave(mapInfo, true)}}
      />
      <SaveMapDialog
        showWindow={windowToBeShownOrder(MapitWindowId.SaveMapDialog)}
        mapInfo={mapitState.mapInfo}
        callbackOnCancel={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveMapDialog, false))}
        callbackOnCommit={(mapInfo, readOnly) => { doSaveMap(mapInfo, readOnly) }}
      />

      {windowToBeShownOrder(MapitWindowId.SaveLinkDialog) ?
        <SaveMapLinkDialog
          showWindow={windowToBeShownOrder(MapitWindowId.SaveLinkDialog)}
          mapInfo={mapitState.mapInfo}
          linkDuration={SettingsManager.getSystemSetting("DefaultLinkDurationDays", 30)}
          currentZoomLevel={mapitState.map?.getZoom?.() || 16}
          backgroundLayers={mapitState.backgroundLayers || {}}
          callbackOnCancel={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SaveLinkDialog, false))}
          callbackOnCommit={doSaveMapLink}
        />

        : null}

      <CatchmentSelector
        showWindow={Boolean(windowToBeShownOrder(MapitWindowId.CatchmentSelector))}
        showHideButton={true}
        showDownloadButton={hasAccessToFeature(Feature.TravelTimeDownloadPolygons)}
        showRushHourSelector={hasAccessToFeature(Feature.TravelTimeRushHourMode)}
        showCombinedBikeAndTransit={hasAccessToFeature(Feature.TravelTimeCombinedBikeAndTransit) && catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange}
        isCollapsed={false}
        onChange={(modeOfTransport:string, rushHourMode:boolean) => {
          let newState:CatchmentState = {...catchmentState, selectedModeOfTransport:modeOfTransport, rushHourMode:rushHourMode};
          catchmentStateDispatch(actionSetCatchmentState(newState))
          if (catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange) {
            catchmentStateDispatch(actionGenerateCatchmentSimple(newState , mapitState, catchmentState.catchmentLocation!));
          } else {
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.CatchmentSelector, false))
          }
        }}
        hideCatchments={() => {
          // Hide any polygon layer shown
          catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle);
          mapitStateDispatch(actionSetShowWindow(MapitWindowId.CatchmentSelector, false))
          // clear any cached polygons. To allow an easy way of making sure that new User Settings - affecting polygons - have effect the next time the dialog is used.
          CatchmentInterface.clearCache();
        }}
        onDownloadCatchments={() => { 
          if (catchmentState.polygons) {
          let json = JSON.stringify(catchmentState.polygons);
          let blob = new Blob([json], {type: "application/json"});
          let fileName="catchment.json";
          ExportData.downloadBlob(fileName, blob);
          } else {
            appMessageDispatch(actionSetErrorMessage("No catchment to download"));
          }
        }}
        onSaveCatchments={() => {
          const createAsSingleLayer:boolean = false;
          if (createAsSingleLayer) {
            let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(catchmentState.polygons, Localization.getText("Travel Time"));
            mapitStateDispatch(actionAddDataLayer(newLayerInfo));
          } else {
            let layerInfos = (catchmentState.polygons! as any).features.map((ft) => {
              let timeSeconds = ft.properties.time;
              let idx = catchmentState.travelTimes.findIndex((val) => val === timeSeconds);
              let color = catchmentState.colorScale[idx];
              let rushHourText = catchmentState.rushHourMode ? ","+Localization.getText("Rush hour"):"";
              let motText = Localization.getText("ModeOfTransport:"+catchmentState.selectedModeOfTransport || "");
              let layerName = Localization.getFormattedText("Travel Time {mot} ({time} mins)",{mot:motText+rushHourText, time:Math.floor(timeSeconds/60)});
              let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeature(ft, layerName);
              // set opacity to 0.7
              newLayerInfo.styling.color = chroma(color).alpha(0.7).css();
              newLayerInfo.styling.lineOpacity = 0;
              return newLayerInfo;
            }) as LayerInfo[]
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, true, {layers: layerInfos})) 
            catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle);
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.CatchmentSelector, false));
            mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, false))
          }
        }}
        onToggleShowItemCounts={()=>{
          let newState:CatchmentState = {...catchmentState, showSpatialItemsCounts:!(catchmentState.showSpatialItemsCounts)};
          catchmentStateDispatch(actionSetCatchmentState(newState))
        }}
      />

      <MultiPointTravelDialog
          showWindow={windowToBeShownOrder(MapitWindowId.PointDistanceTable)}
          options={windowToBeShownParameters(MapitWindowId.PointDistanceTable)}
          callbackOnClose={() => {
           catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle);
           mapitStateDispatch(actionSetShowWindow(MapitWindowId.PointDistanceTable, false))}
          } 
        />

      {windowToBeShownOrder(MapitWindowId.SavedLinkDialog) ?
        <SavedMapDialog
          showWindow={windowToBeShownOrder(MapitWindowId.SavedLinkDialog)}
          refLink={windowToBeShownParameters(MapitWindowId.SavedLinkDialog).ref || "test"}
          expireDate={windowToBeShownParameters(MapitWindowId.SavedLinkDialog).expireDate || new Date()}
          callbackOnCancel={() => mapitStateDispatch(actionSetShowWindow(MapitWindowId.SavedLinkDialog, false))}
        />
        : null}
        {windowToBeShownOrder(MapitWindowId.GenerateReportResultDialog) ? (
          <ReportResultDialog
            showWindow={windowToBeShownOrder(MapitWindowId.GenerateReportResultDialog)}
            metaData={mapitState.demographyReport?.metadata!}
            data={mapitState.demographyReport?.result}
            callbackOnDismiss={() => { handleReportResultDialogDismiss(); }}
            languageCode={SettingsManager.getSystemSetting("demographyReportLanguageCode")}
          />
        ) : null}
        {windowToBeShownOrder(MapitWindowId.NameOnCreation) ? (
          <NameOnCreation
            showWindow={windowToBeShownOrder(MapitWindowId.NameOnCreation)}
            layers={windowToBeShownParameters(MapitWindowId.NameOnCreation).layers}
            callBackConfirm={(layers) => {
              layers.forEach((newLayerInfo) => {
                mapitStateDispatch(actionAddDataLayer(newLayerInfo));
              })
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, false))
            }}
            callBackCancel={() => {
              mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, false))
            }}
          />
        ): null }
        {windowToBeShownOrder(MapitWindowId.DownloadLayerDialog) ? (
          <DownloadDialog
            showWindow={windowToBeShownOrder(MapitWindowId.DownloadLayerDialog)}
            layers={windowToBeShownParameters(MapitWindowId.DownloadLayerDialog).layers}
            callBack={(evt) => {mapitStateDispatch(actionSetShowWindow(MapitWindowId.DownloadLayerDialog, false))}}
          />
        ): null }
        {windowToBeShownOrder(MapitWindowId.GDPRNoticeApproval) ?
          <GDPRNoticeApproval
            showWindow={windowToBeShownOrder(MapitWindowId.GDPRNoticeApproval) }
            currentCookie={windowToBeShownParameters(MapitWindowId.GDPRNoticeApproval).cookie}
            callbackOnCancel={()=> {mapitStateDispatch(actionSetShowWindow(MapitWindowId.GDPRNoticeApproval, false))}}
            callbackOnCommit={()=> {windowToBeShownParameters(MapitWindowId.GDPRNoticeApproval).acceptCallback(); mapitStateDispatch(actionSetShowWindow(MapitWindowId.GDPRNoticeApproval, false))}}
          />
          :null}
        
        {windowToBeShownOrder(MapitWindowId.StreetView) ?
          <StreetViewer
          showWindow={!!windowToBeShownOrder(MapitWindowId.StreetView)}
            onFormClose={()=> {mapitStateDispatch(actionSetShowWindow(MapitWindowId.StreetView, false))}}
            latlng={windowToBeShownParameters(MapitWindowId.StreetView)}
            />
          :null}
      <QuickOrthoPhoto />
      <MapInteractionStateInfoBox />
      {/* <ReturnTo17 /> */}



    </div>
  )
}




