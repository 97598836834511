
export enum BebyggelsesType {
   RaekkeOgDobbeltHus="Rækkehus/Dobbelthus", 
   FritliggendeEnToFamiliesHus="Fritliggende en/tofamilieshus",
   Sommerhus="Sommerhus",
   Etagebebyggelse="Etagebebyggelse",
   Andre="Andet", 
   Fejl="BBRdata mangelfuldt"
}

export type Byggeregel = {
   bebygpct: number,
   maxbygnhjd: number,
   maxetager: number,
   note?: string
};

export class PropertyInformation {
   
   private static byg021BygningensAnvendelseLookup = {
      110: "Stuehus til landbrugsejendom",
      120: "Fritliggende enfamilieshus (parcelhus)",
      121: "Sammenbygget enfamiliehus",
      130: "(UDFASES) Række-, kæde-, eller dobbelthus (lodret adskillelse mellem enhederne).",
      131: "Række- og kædehus",
      132: "Dobbelthus",
      140: "Etagebolig-bygning, flerfamiliehus eller to-familiehus",
      150: "Kollegium",
      160: "Boligbygning til døgninstitution",
      185: "Anneks i tilknytning til helårsbolig.",
      190: "Anden bygning til helårsbeboelse",
      210: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
      211: "Stald til svin",
      212: "Stald til kvæg, får mv.",
      213: "Stald til fjerkræ",
      214: "Minkhal",
      215: "Væksthus",
      216: "Lade til foder, afgrøder mv.",
      217: "Maskinhus, garage mv.",
      218: "Lade til halm, hø mv.",
      219: "Anden bygning til landbrug mv.",
      220: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o.lign.)",
      221: "Bygning til industri med integreret produktionsapparat",
      222: "Bygning til industri uden integreret produktionsapparat",
      223: "Værksted",
      229: "Anden bygning til produktion",
      230: "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
      231: "Bygning til energiproduktion",
      232: "Bygning til forsyning- og energidistribution",
      233: "Bygning til vandforsyning",
      234: "Bygning til håndtering af affald og spildevand",
      239: "Anden bygning til energiproduktion og -distribution",
      290: "(UDFASES) Anden bygning til landbrug, industri etc.",
      310: "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
      311: "Bygning til jernbane- og busdrift",
      312: "Bygning til luftfart",
      313: "Bygning til parkering- og transportanlæg",
      314: "Bygning til parkering af flere end to køretøjer i tilknytning til boliger",
      315: "Havneanlæg",
      319: "Andet transportanlæg",
      320: "(UDFASES) Bygning til kontor, handel, lager, herunder offentlig administration",
      321: "Bygning til kontor",
      322: "Bygning til detailhandel",
      323: "Bygning til lager",
      324: "Butikscenter",
      325: "Tankstation",
      329: "Anden bygning til kontor, handel og lager",
      330: "(UDFASES) Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
      331: "Hotel, kro eller konferencecenter med overnatning",
      332: "Bed & breakfast mv.",
      333: "Restaurant, café og konferencecenter uden overnatning",
      334: "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
      339: "Anden bygning til serviceerhverv",
      390: "(UDFASES) Anden bygning til transport, handel etc",
      410: "(UDFASES) Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
      411: "Biograf, teater, koncertsted mv.",
      412: "Museum",
      413: "Bibliotek",
      414: "Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund",
      415: "Forsamlingshus",
      416: "Forlystelsespark",
      419: "Anden bygning til kulturelle formål",
      420: "(UDFASES) Bygning til undervisning og forskning (skole, gymnasium, forskningslabratorium o.lign.).",
      421: "Grundskole",
      422: "Universitet",
      429: "Anden bygning til undervisning og forskning",
      430: "(UDFASES) Bygning til hospital, sygehjem, fødeklinik o. lign.",
      431: "Hospital og sygehus",
      432: "Hospice, behandlingshjem mv.",
      433: "Sundhedscenter, lægehus, fødeklinik mv.",
      439: "Anden bygning til sundhedsformål",
      440: "(UDFASES) Bygning til daginstitution",
      441: "Daginstitution",
      442: "Servicefunktion på døgninstitution",
      443: "Kaserne",
      444: "Fængsel, arresthus mv.",
      449: "Anden bygning til institutionsformål",
      490: "(UDFASES) Bygning til anden institution, herunder kaserne, fængsel o. lign.",
      510: "Sommerhus",
      520: "(UDFASES) Bygning til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
      521: "Feriecenter, center til campingplads mv.",
      522: "Bygning med ferielejligheder til erhvervsmæssig udlejning",
      523: "Bygning med ferielejligheder til eget brug",
      529: "Anden bygning til ferieformål",
      530: "(UDFASES) Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
      531: "Klubhus i forbindelse med fritid og idræt",
      532: "Svømmehal",
      533: "Idrætshal",
      534: "Tribune i forbindelse med stadion",
      535: "Rideskole",
      539: "Anden bygning til idrætformål",
      540: "Kolonihavehus",
      585: "Anneks i tilknytning til fritids- og sommerhus",
      590: "Anden bygning til fritidsformål",
      910: "Garage (med plads til et eller to køretøjer)",
      920: "Carport",
      930: "Udhus",
      940: "Drivhus",
      950: "Fritliggende overdækning",
      960: "Fritliggende udestue",
      970: "Tiloversbleven landbrugsbygning",
      990: "Faldefærdig bygning",
      999: "Ukendt bygning"
   };

   public static findAdresse(adresseID:string, adresserObject: any[]) {
      let adresse = adresserObject.flat().find((a) => a.id_lokalId == adresseID)
      return adresse?.adressebetegnelse || "Ingen Adresse"
   }

   public static findFirstKommune(DarAdgang) {
      let førsteValid = DarAdgang.flat().find((a) => a?.kommuneinddeling?.navn || "")
      return førsteValid?.kommuneinddeling.navn || "Ingen Kommune";
   }

   public static findAllKommune(DarAdgang) {
      let alleValide = DarAdgang.flat().map((a) => a?.kommuneinddeling?.navn || "").filter((a) => a)
      let alleUnique = [...new Set(...alleValide)]
      return alleUnique
   }
   
   public static bygningsAnvendelsesTekst(byg021BygningensAnvendelse: string):string {
      return byg021BygningensAnvendelse && PropertyInformation.byg021BygningensAnvendelseLookup[byg021BygningensAnvendelse] || "Ukendt kode:"+byg021BygningensAnvendelse;
   }

   public static  filterOpførtGældende(elem: any): boolean {
      // Se https://teknik.bbr.dk/kodelister/0/1/0/Livscyklus
      // Se https://bbr.dk/file/665192/bbr-informationsmodel.pdf under Livscyklus "Kun 7 gældende og 6 Opført"
      // Midlertidigt Afsluttet (12) tilføjet 04/07-2024, da indgår i BBR meddelse SE https://bbr.dk/pls/wwwdata/get_newois_pck.show_bbr_meddelelse_pdf?i_bfe=6017314
      return !!(
      "status" in elem &&
      (elem.status.toString() === "6" || elem.status.toString() === "7" || elem.status.toString() === "12")
      );
   }
   
   /**
    * Beregner det samlede bygningsareal. Det består af summen af grundplanets størrelse (byg041BebyggetAreal) for alle bygninger. 
    * @param bbrEjd Array of bbr ejendomsdata
    * @returns Det samlede bygningsareal
    */
   public static beregnSamletBebyggetAreal(bbrEjd:any):number {
      
      let result:number = 0;
      if (bbrEjd) {
         bbrEjd.flatMap((a) => a).filter(PropertyInformation.filterOpførtGældende).forEach((bygn) => {
            result += bygn.byg041BebyggetAreal || 0;
         });
      }
      return result;
   }


   public static beregnSamletBygningsAreal(bbrEjd:any):number {
      
      let result:number = 0;
      if (bbrEjd) {
         bbrEjd.flat().filter(PropertyInformation.filterOpførtGældende).forEach((bygn:any) => {
            result += bygn.byg038SamletBygningsareal || 0;
         });
      }
      return result;
   }

   public static beregnBebyggelsesType(bbrBygninger:any):BebyggelsesType {
      let result:BebyggelsesType|undefined = undefined;
      bbrBygninger && bbrBygninger.forEach((bygn) => {
         let anvendelseskode = bygn.byg021BygningensAnvendelse;
         let bygRes;
         switch(anvendelseskode) {
            case "120": case "121": bygRes = BebyggelsesType.FritliggendeEnToFamiliesHus; break;
            case "130": case "131": case "132": bygRes = BebyggelsesType.RaekkeOgDobbeltHus; break;
            case "140": bygRes = BebyggelsesType.Etagebebyggelse; break;
            case "510": bygRes = BebyggelsesType.Sommerhus; break;
            default:
         }
         if (result && bygRes && result !== bygRes) {
            // another type is already found.
            result = BebyggelsesType.Fejl;
         } else {
            if (bygRes) {
               result = bygRes;
            }
         }
      });
      return result || BebyggelsesType.Andre;
   }
   
   public static beregnStandardRegel(type:BebyggelsesType):Byggeregel {
      switch(type) {
         case BebyggelsesType.RaekkeOgDobbeltHus:
         return {
            bebygpct: 40,
            maxbygnhjd: 8.5,
            maxetager: 2
         };
         case BebyggelsesType.FritliggendeEnToFamiliesHus:
         return {
            bebygpct: 30,
            maxbygnhjd: 8.5,
            maxetager: 2
         };
         case BebyggelsesType.Sommerhus:
         return {
            bebygpct: 15,
            maxbygnhjd: 5,
            maxetager: 1
         };
         case BebyggelsesType.Etagebebyggelse:
         return {
            bebygpct: 30,
            maxbygnhjd: 8.5,
            maxetager: 2
         };
         case BebyggelsesType.Andre:
         return {
            bebygpct: 60,
            maxbygnhjd: 8.5,
            maxetager: 2
         };
         default:
         return {
            bebygpct: 45,
            maxbygnhjd: 8.5,
            maxetager: 2,
            note: "Fejl i BBR"
         };
      }
   }
   
   public static beregnBebyggelsesRegler(standardRegel:any, lokalPlan?:any, kommunePlanRamme?:any):any {
      /*
      Generelle regler hvis ikke begrænset i kommune eller lokalplaner 
      Rækkehuse/Dobbelthuse: 40%
      Fritliggende en/tofamiliehuse: 30%
      Sommerhuse: 15%
      Etagebebyggelse: 60%
      Andre: 45%
      
      Ejendomskategori "1": parcel-/Rækkehus "2": Ejerlejlighed "3": Fritidshus
      BBR Ejendomstype: "1"
      */
      function dimmension(variabel:string, sr:any, lp?:any, kpr?:any) {
         let resultat:number;
         let kilde:string;
         
         if (lp && lp[variabel]) {
            resultat = lp[variabel];
            kilde = "Lokalplan";
         } else {
            if (kpr && kpr[variabel]) {
               resultat = kpr[variabel];
               kilde = "Kommuneplanramme";
            } else {
               resultat=sr[variabel];
               kilde="Bygningsreglement";
            }
         }
         return {source:kilde, val:resultat};
      }
      
      let x = dimmension("bebygpct", standardRegel, lokalPlan, kommunePlanRamme);
      let y = dimmension("maxbygnhjd", standardRegel, lokalPlan, kommunePlanRamme);
      let z = dimmension("maxetager", standardRegel, lokalPlan, kommunePlanRamme);
      return {
         "bebygpct":x,
         "maxbygnhjd":y,
         "maxetager":z
      };
   }
   
   static splitMatrikel(mat:string):{tal:number,tekst:string} {
      let res = mat && mat.match(/[0-9]+(.*)/);
      return {
         tal:res && Number.parseInt(res[0], 10) || 0,
         tekst: res && res[1] || ""
      };
   }
   
   static matrikelSortFunc(a:string, b:string):number {
      // Matrikler består at et tal og efterfulgt af nul eller flere bogstaver.
      // Så tal delen skal sorteres nummerisk og det efterfølgende skal sorteres alfabetisk
      let aSplit = PropertyInformation.splitMatrikel(a);
      let bSplit = PropertyInformation.splitMatrikel(b);
      if (aSplit.tal < bSplit.tal) {
         return -1;
      } else {
         if (aSplit.tal > bSplit.tal) {
            return 1;
         } else {
            if (aSplit.tekst < bSplit.tekst) {
               return -1;
            } else {
               return 1;
            }
         }
      }
      return 1;
   }
   
   static VurdÆndringskode(kode:string, år:number):string {
      if (kode === undefined || kode === "") {
         return "Alm. vurdering";
      }
      if (kode === "9") {
         if (år >= 1998 && år <= 2002) {
            return "Ordinær vurdering (Maskinelt fremskrevet)";
         }
         return "Årsregulering til årets kontantniveau";
      }
      let x:{[a:string]:string | undefined} = {
         "1":"SKAT ankenævnsændring",
         "2":"Revision/Ligningsrådsændring",
         "3":"Landsskatteretskendelse",
         "4":"SKAT 1. instans, rettelse/genoptagelse",
         "5":"Pgf. 4 vurdering",
         "6":"Pgf. 4A vurdering (før 2004-vurderingen)",
         "7":"SKAT ankenævn (arbejdskopi)",
         "8":"SKAT 1. instans (arbejdskopi)",
         "H":"Overspringsvurdering, kun bærer af skattefelter"
      };
      let result = x[kode];
      if (result === undefined) {
         return "Ukendt";
      }
      return result;
   }
   
   
   static formatMatrikler(matrikler:any[], visEjerlavsKode:boolean):string {
      // sorter 1) ejerlavkode, 2) matrikelnr
      matrikler.sort((a,b) => {
         if (a.ejerlavskode < b.ejerlavskode) {
            return -1;
         } else {
            if (a.ejerlavskode > b.ejerlavskode) {
               return 1;
            } else {
               return PropertyInformation.matrikelSortFunc(a.matrikelnummer,b.matrikelnummer);                    
            }
         }
      });
      // console.log(matrikler);
      let uniqueEjerlav:any[] = [];
      const map = new Map();
      for (const item of matrikler) {
         if(!map.has(item.ejerlavskode)) {
            map.set(item.ejerlavskode, true);
            uniqueEjerlav.push(item.ejerlavskode);
         }  
      }
      // console.log(uniqueEjerlav);
      let result="";
      uniqueEjerlav.forEach((ekode) => {
         let matriklerForEjerlav = matrikler.filter((mat) => { return mat.ejerlavskode === ekode;});
         // console.log(matriklerForEjerlav);
         let resForEjerlav = matriklerForEjerlav.reduce(
            (prev, mat, currentIndex, array) => {
               return prev + (currentIndex > 0 ? ", " : "") + mat.matrikelnummer;
            },
            ""
         );
         resForEjerlav += " " + matriklerForEjerlav[0].ejerlavsnavn;
         if (visEjerlavsKode) {
            resForEjerlav += " ("+ekode+")";
         }
         result += (result.length > 0 ? " og ": "") + resForEjerlav;
      });
         // console.log(result);
      return result;
   }
      
      static RemoveFromObject(filter:string[], Obj:any) {
         let x = {};
         let remaining = Object.keys(Obj).filter((a) => !filter.includes(a));
         for (let i of remaining) {
            x = {...x, [i]:Obj[i]};
         }
         return x;
      }

      /**
      * Used to find information about one named owner (if the property has multiple owners).
      * 
      * @param ejf_ejere_liste Array of owner data (containing at least property 'navn')
      * @param name to compare with
      * @returns returns the array element that best matches the name (using Levenshtein distance)
      */
      static propertyOwnerMatchingName(ejf_ejere_liste: { navn: string }[], name: string) {

         /** Levenshtein distance
         Calculate the Levenshtein distance between two strings in JavaScript
   
         Definition
         The Levenshtein distance is a measure of the difference between two strings. It is defined as the minimum number of single-character edits (insertions, deletions or substitutions) required to change one string into the other. It is also sometimes referred to as edit distance, although that may also refer to different distance metrics.
   
         */
         const levenshteinDistance = (s: string, t: string): number => {
            if (!s.length) return t.length;
            if (!t.length) return s.length;
            const arr: any[][] = [];
            for (let i = 0; i <= t.length; i++) {
               arr[i] = [i];
               for (let j = 1; j <= s.length; j++) {
                  arr[i][j] =
                     i === 0
                        ? j
                        : Math.min(
                           arr[i - 1][j] + 1,
                           arr[i][j - 1] + 1,
                           arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
                        );
               }
            }
            return arr[t.length][s.length];
         };

         let owners = ejf_ejere_liste;
         let selectedOwnerData;
         if (owners.length > 1 && name) {
            // get data for the owner (the one best matching the search criterium) if there are more than one owner
            selectedOwnerData = owners.reduce((result, own, idx) => {
               if (idx === 0) {
                  return own;
               } else {
                  if (levenshteinDistance(name, own.navn) < levenshteinDistance(name, result.navn)) {
                     return own;
                  } else {
                     return result;
                  }
               }

            }, {navn:""});
         } else {
            selectedOwnerData = owners[0];
         }
         return selectedOwnerData;
      }

   }