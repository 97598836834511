import { ReferenceGeomType_RO } from '../common/managers/Types';
import { Utils } from '@viamap/viamap2-common';
import { ReferenceGeomFeature_RO } from '../common/managers/Types';
import * as RemoveAccents from 'remove-accents';

export class ReferenceGeomRomania {

    /**
     * Some municipalities have alias names
     */
    private static municipalityNameAliasList = {
        "Caineni": "Cainenii Mici",
        "Nimigea":"Nimigea de jos", 
        "Moieciu": "Moieciu de jos",
        "Ucea": "Ucea de jos",
        "Vistea": "Vistea de jos",
    };

 
    // Cache for reference informartion
    private static refGeom: any = null;
    private static isLoading: boolean = false;

    private static dataUrl = "https://s3.eu-central-1.amazonaws.com/mapit2-data/GeoJSONReferencedFromMapit/ReferenceGeomRomania.json";

    // -------------------------- Municipalities -----------------------------------
    static dumpMunicpalityLookupList(): any[] {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.map((feature) => {
            return (
                { 
                    "Code": this.selectCodeElement(feature), 
                    "Name": this.selectNameElement(feature) 
                }
            );
        });
        return (result);
    }

    static selectCodeElement(feature:ReferenceGeomFeature_RO):number {
        return feature && feature.properties && feature.properties.ID_2;
    }

    static selectNameElement(feature:ReferenceGeomFeature_RO):string {
        return feature && feature.properties && feature.properties.NAME_2;
    }

    static lookupByMunicipalityCode(code: number) {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.find((feature) => {
            return this.equalityFuncMunicipalityCode(this.selectCodeElement(feature), code);
        });
        return (result);
    }

    static equalityFuncMunicipalityCode(prop: number, code: number) {
        return (prop.toString() === code.toString());
    }

    static lookupByMunicipalityName(name: string) {
        var dataSet = this.getReferenceGeo();
        var result = dataSet.features.find((feature) => {
            return this.equalityFuncMunicipalityName(this.selectNameElement(feature), name);
        });
        return (result);
    }

    static equalityFuncMunicipalityName(prop: string, namePossiblyWithAccents: string): boolean {
        let name = RemoveAccents.remove(namePossiblyWithAccents);
        let result = prop.toString().toLowerCase() === name.toString().toLowerCase();
        // Check alias list if not found
        if (!result) {
            let foundKey = Object.keys(ReferenceGeomRomania.municipalityNameAliasList).find((key, idx) => {
                let found = key.toString().toLowerCase() === name.toString().toLowerCase();
                if (found) {
                    let alias = ReferenceGeomRomania.municipalityNameAliasList[key];
                    return (prop.toString().toLowerCase() === alias.toString().toLowerCase());
                } else {
                    return false;
                }
            });
            result = (foundKey !== undefined);
        }
        return result;
    }

    static hasRefGeoBeenLoaded():boolean {
        return ReferenceGeomRomania.refGeom && !ReferenceGeomRomania.isLoading;
    }

    static isLoadingReferenceGeo():boolean {
        return ReferenceGeomRomania.isLoading;
    }

    static loadReferenceGeo():Promise<void> {
        let res = new Promise<void>((resolve, reject) => {
            if (ReferenceGeomRomania.hasRefGeoBeenLoaded()) {
                resolve();
            } else {
                Utils.asyncFetchJsonFromUrl(ReferenceGeomRomania.dataUrl)
                .then((data) => {
                    ReferenceGeomRomania.refGeom = data;
                    ReferenceGeomRomania.isLoading = false;
                    resolve();
                }).catch((err) => {
                    ReferenceGeomRomania.isLoading = false;
                    reject("Error loading geojson: "+err.message);
                });
                ReferenceGeomRomania.isLoading=true;    
            }
        });
        return res;
    }

    static getReferenceGeo(): ReferenceGeomType_RO {
        return this.refGeom;
    }

}
