import * as React from "react";

import { Button, FormControl, Card } from "react-bootstrap";
import { Localization } from "@viamap/viamap2-common";
import { LayerHierarchy } from "../common/managers/Types";
import ReactDOM from "react-dom";
import { MitBody, MitButton, MitDialog, MitFooter, MitHeader, MitInfo } from "./ComponentUtils";

type Props = {
  group: LayerHierarchy;
  callbackOnGroupStyling: (group: LayerHierarchy) => void;
};

/**
 * React group-styling window.
 */
export function GroupStyling(props: Props) {
  const [name, setName] = React.useState<string>(props.group.name);

  return ReactDOM.createPortal(
    <MitDialog show={true} style={{right: "15px", top: "5px", width:"400px"}}>
      <MitHeader position="center" onClose={(e) => {
        e.preventDefault;
        props.callbackOnGroupStyling({ ...(props.group), name: name })
      }} >
          {Localization.getText("Styling")}
      </MitHeader>
      <MitBody style={{fontSize:"12px", textAlign:"left"}}>
        <MitInfo style={{ paddingBlock: "4px" }} />
          <div className="row">
            <div className="col-sm-4">
              {Localization.getText("Dataset name")}
            </div>
            <div className="col-sm-8">
              <FormControl
                size="sm"
                type="text"
                disabled={false}
                value={name}
                placeholder={Localization.getText("Enter name of dataset")}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6"> </div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-6"> </div>
          </div>
        </MitBody>
        <MitFooter>
          <MitButton
            variant="close"
            onClick={(e) => {
              e.preventDefault;
              props.callbackOnGroupStyling({ ...(props.group), name: name });
            }}
          >
            {Localization.getText("Close")}
          </MitButton>
        </MitFooter>
      </MitDialog>
  , document.getElementById("Mit-MapOverlay") || document.body);
}
