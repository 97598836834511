// Composite pattern object providing interface to all ReferenceGeom object types through an interface.
//
import { FeatureCollection } from 'geojson';

import {ReferenceGeomDenmark} from './ReferenceGeomDenmark';
import {ReferenceGeomWorld} from './ReferenceGeomWorld';
import {ReferenceGeomRomania} from './ReferenceGeomRomania';
import { ReferenceGeomSchleswig } from './ReferenceGeomSchleswig';
import {ReferenceGeomParish} from './ReferenceGeomParish';
import {Utils} from '@viamap/viamap2-common';
import {LayerType,
  ReferenceGeomFeatureMunicipality,
  ReferenceGeomFeatureRegion,
  ReferenceGeomFeatureZipcodes,
  ReferenceGeomFeatureParish,
  PopupLayout
} from '../common/managers/Types';

export class ReferenceGeomComposite {

  private static referenceGeomLoadedCheckList:{
    geomType: string,
    geomNeeded: (lt:LayerType) => boolean,
    isLoading: () => boolean,
    hasBeenLoaded: () => boolean,
    load: () => Promise<void>
  }[] = [
    {
      geomType: "Romania",
      geomNeeded: (lt:LayerType) => { 
        return (lt === LayerType.AreaMunicipalityId_RO || lt === LayerType.AreaMunicipalityName_RO); 
      },
      isLoading: () => { return ReferenceGeomRomania.isLoadingReferenceGeo();},
      hasBeenLoaded: ReferenceGeomRomania.hasRefGeoBeenLoaded,
      load: ReferenceGeomRomania.loadReferenceGeo
    },
    {
      geomType: "Souhern Schleswig",
      geomNeeded: (lt:LayerType) => { 
        return (lt === LayerType.AreaMunicipalityId_DK_And_SydSlesvig || lt === LayerType.AreaMunicipalityName_DK_And_SydSlesvig); 
      },
      isLoading: () => { return ReferenceGeomSchleswig.isLoadingReferenceGeo();},
      hasBeenLoaded: ReferenceGeomSchleswig.hasRefGeoBeenLoaded,
      load: ReferenceGeomSchleswig.loadReferenceGeo
    },
    {
      geomType: "World",
      geomNeeded: (lt:LayerType) => { 
        return (lt === LayerType.AreaCountryId || lt === LayerType.AreaCountryName); 
      },
      isLoading: () => { return ReferenceGeomWorld.isLoadingReferenceGeo();},
      hasBeenLoaded: ReferenceGeomWorld.hasRefGeoBeenLoaded,
      load: ReferenceGeomWorld.loadReferenceGeo
    },
    {
      geomType: "Denmark: Muni, Region, Zip Code",
      geomNeeded: (lt:LayerType) => { 
        return (
          lt === LayerType.AreaMunicipalityId || lt === LayerType.AreaMunicipalityName
          || lt === LayerType.AreaRegionId || lt === LayerType.AreaRegionName
          || lt === LayerType.AreaZipcodesId || lt === LayerType.AreaZipcodesName
        ); 
      },
      isLoading: () => { return ReferenceGeomDenmark.isLoadingReferenceGeo();},
      hasBeenLoaded: ReferenceGeomDenmark.hasRefGeoBeenLoaded,
      load: ReferenceGeomDenmark.loadReferenceGeo
    },
    {
      geomType: "Denmark: Parish",
      geomNeeded: (lt:LayerType) => { 
        return (lt === LayerType.AreaParishId || lt === LayerType.AreaParishName); 
      },
      isLoading: () => { return ReferenceGeomParish.isLoadingReferenceGeo();},
      hasBeenLoaded: ReferenceGeomParish.hasRefGeoBeenLoaded,
      load: ReferenceGeomParish.loadReferenceGeo
    }
  ];

  // Check if all loads has completed which is needed by the specified LayerType otherwise load the required data
  static isRequiredGeomDataLoaded(layerType: LayerType):boolean {
    let result = true;
    // check to see that all needed ReferenceGeom has loaded.
    ReferenceGeomComposite.referenceGeomLoadedCheckList.forEach((ref:any) => {
      if (ref.geomNeeded(layerType) && !ref.hasBeenLoaded()) {
        result = false;
      }
    });
    return result;
  }
  
  // Check if all loads has completed which is needed by the specified LayerType otherwise load the required data
  static ensureRequiredGeomData(layerType: LayerType):Promise<void> {

    let result = new Promise<void>((resolve, reject) => {
      let toLoad:any[] = [];
      // check to see that all needed ReferenceGeom has loaded.
      ReferenceGeomComposite.referenceGeomLoadedCheckList.forEach((ref) => {
        if (ref.geomNeeded(layerType) && !ref.hasBeenLoaded()) {
          toLoad.push(ref);
        }
      });

      if (toLoad.length === 0) {
        // Nothing to do or it has already been loaded
        resolve();
      } else {
        if (toLoad.length > 1) {
          reject(new Error("Unexpected loadlist length: "+toLoad.length));
        } else {
          let ref = toLoad[0];
          // if (!ref.hasBeenLoaded()) { 
          //   // wait for the load to complete - then return
          //   const waitMilliSeconds=1000;
          //   const maxRetries=10;
          //   let noOfRetries = 0;
          //   while (noOfRetries < maxRetries) {
          //     setTimeout(
          //       () => {
          //         if (ref.hasBeenLoaded()) {
          //           resolve();
          //         } else {
          //           noOfRetries++;
          //         }
          //       }, 
          //       waitMilliSeconds);  
          //     } 
          //   reject(new Error("Load not completed after "+waitMilliSeconds*maxRetries/1000+" seconds"));
          // } else {
          ref.load()
          .then(data => resolve())
          .catch(error => reject(error));
          // }
        }
      }
    });
    return result;
  }

  static getReferenceGeo(layerType:LayerType):FeatureCollection {
    switch(layerType) {
      case LayerType.AreaMunicipality:
      case LayerType.AreaMunicipalityId:
      case LayerType.AreaMunicipalityName:
        return ReferenceGeomDenmark.getReferenceGeo().municipality as FeatureCollection;

      case LayerType.AreaParish:
      case LayerType.AreaParishId:
      case LayerType.AreaParishName:
        return ReferenceGeomParish.getReferenceGeo() as FeatureCollection;

      case LayerType.AreaMunicipality_RO:
      case LayerType.AreaMunicipalityId_RO:
      case LayerType.AreaMunicipalityName_RO:              
        return ReferenceGeomRomania.getReferenceGeo() as FeatureCollection; 

      case LayerType.AreaMunicipality_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:              
        return ReferenceGeomSchleswig.getReferenceGeo() as FeatureCollection; 

      case LayerType.AreaRegion:
      case LayerType.AreaRegionId:
      case LayerType.AreaRegionName:
        return ReferenceGeomDenmark.getReferenceGeo().region as FeatureCollection; 
        
      case LayerType.AreaZipcodes:
      case LayerType.AreaZipcodesId:
      case LayerType.AreaZipcodesName:
        return ReferenceGeomDenmark.getReferenceGeo().zipcodes as FeatureCollection; 

      case LayerType.AreaCountry:
      case LayerType.AreaCountryId:
      case LayerType.AreaCountryName:
        return ReferenceGeomWorld.getReferenceGeoWorld() as FeatureCollection;

      default:
        throw Utils.createErrorEventObject("Unknown or unexpected layer type: "+ layerType);
    }
  }

  static getLabelHeader(layerType: LayerType, jsonFeature: any, popupLayout?: PopupLayout): string {
    let name = ReferenceGeomComposite.selectNameElement(layerType, jsonFeature);
    let code = ReferenceGeomComposite.selectCodeElement(layerType, jsonFeature);
    let header: string = "";

    switch (popupLayout) {
      case PopupLayout.ShowNothing:
        header = "";
        break;
      case PopupLayout.ShowAreaName:
        header = (name || "");
        break;
      case PopupLayout.ShowAreaCode:
        header = (code ? "(" + code + ")" : "");
        break;
      case PopupLayout.ShowAll:
        header = (name || "") + (code ? "(" + code + ")" : "");
        break;
      default:
        header = (name || "") + (code ? "(" + code + ")" : "");
        break;
    }
    return header;
  }

  static selectCodeElement(layerType: LayerType, jsonFeature:any): any {
    switch(layerType) {
      case LayerType.AreaMunicipality:
      case LayerType.AreaMunicipalityId:
      case LayerType.AreaMunicipalityName:
        return (jsonFeature as ReferenceGeomFeatureMunicipality).properties.Komnr;

      case LayerType.AreaParish:
      case LayerType.AreaParishId:
      case LayerType.AreaParishName:
        return (jsonFeature as ReferenceGeomFeatureParish).properties.SOGNEID;

      case LayerType.AreaMunicipality_RO:
      case LayerType.AreaMunicipalityId_RO:
      case LayerType.AreaMunicipalityName_RO:              
      return ReferenceGeomRomania.selectCodeElement(jsonFeature); 

      case LayerType.AreaMunicipality_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:              
      return ReferenceGeomSchleswig.selectCodeElement(jsonFeature); 

      case LayerType.AreaRegion:
      case LayerType.AreaRegionId:
      case LayerType.AreaRegionName:
        return (jsonFeature as ReferenceGeomFeatureRegion).properties.REGIONKODE;
        
      case LayerType.AreaZipcodes:
      case LayerType.AreaZipcodesId:
      case LayerType.AreaZipcodesName:
        return (jsonFeature as ReferenceGeomFeatureZipcodes).properties.Postnummer;

      case LayerType.AreaCountry:
      case LayerType.AreaCountryId:
      case LayerType.AreaCountryName:
        return ReferenceGeomWorld.selectCodeElement(jsonFeature);

      default:
        throw Utils.createErrorEventObject("Unknown or unexpected layer type: "+ layerType);
    }

  }

  static selectNameElement(layerType: LayerType, jsonFeature:any): any {
    switch(layerType) {
      case LayerType.AreaMunicipality:
      case LayerType.AreaMunicipalityId:
      case LayerType.AreaMunicipalityName:
          return (jsonFeature as ReferenceGeomFeatureMunicipality).properties.KOMNAVN;

      case LayerType.AreaParish:
      case LayerType.AreaParishId:
      case LayerType.AreaParishName:
          return (jsonFeature as ReferenceGeomFeatureParish).properties.SOGNENAVN;

      case LayerType.AreaMunicipality_RO:
      case LayerType.AreaMunicipalityId_RO:
      case LayerType.AreaMunicipalityName_RO:              
        return ReferenceGeomRomania.selectNameElement(jsonFeature); 

      case LayerType.AreaMunicipality_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:              
        return ReferenceGeomSchleswig.selectNameElement(jsonFeature); 

      case LayerType.AreaRegion:
      case LayerType.AreaRegionId:
      case LayerType.AreaRegionName:
        return (jsonFeature as ReferenceGeomFeatureRegion).properties.REGIONNAVN;
        
      case LayerType.AreaZipcodes:
      case LayerType.AreaZipcodesId:
      case LayerType.AreaZipcodesName:
        return (jsonFeature as ReferenceGeomFeatureZipcodes).properties.POSTBYNAVN;

      case LayerType.AreaCountry:
      case LayerType.AreaCountryId:
      case LayerType.AreaCountryName:
        return ReferenceGeomWorld.selectNameElement(jsonFeature);

      default:
        throw Utils.createErrorEventObject("Unknown or unexpected layer type: "+ layerType);
    }

  }

  static equalityFunc(layerType:LayerType): (a:any, b:any) => boolean {
    switch(layerType) {
      case LayerType.AreaMunicipality:
      case LayerType.AreaMunicipalityId:
        return ReferenceGeomDenmark.equalityFuncMunicipalityCode;
      case LayerType.AreaMunicipalityName:
        return ReferenceGeomDenmark.equalityFuncMunicipalityName;

      case LayerType.AreaParish:
      case LayerType.AreaParishId:
        return ReferenceGeomParish.equalityFuncParishCode;
      case LayerType.AreaParishName:
        return ReferenceGeomParish.equalityFuncParishName;

      case LayerType.AreaMunicipality_RO:
      case LayerType.AreaMunicipalityId_RO:
        return ReferenceGeomRomania.equalityFuncMunicipalityCode;
      case LayerType.AreaMunicipalityName_RO:              
        return ReferenceGeomRomania.equalityFuncMunicipalityName; 

      case LayerType.AreaMunicipality_DK_And_SydSlesvig:
      case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
        return ReferenceGeomSchleswig.equalityFuncMunicipalityCode;
      case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:              
        return ReferenceGeomSchleswig.equalityFuncMunicipalityName; 

      case LayerType.AreaRegion:
      case LayerType.AreaRegionId:
        return ReferenceGeomDenmark.equalityFuncRegionCode;
      case LayerType.AreaRegionName:
        return ReferenceGeomDenmark.equalityFuncRegionName; 
        
      case LayerType.AreaZipcodes:
      case LayerType.AreaZipcodesId:
        return ReferenceGeomDenmark.equalityFuncZipCode;
      case LayerType.AreaZipcodesName:
        return ReferenceGeomDenmark.equalityFuncZipCodeName; 

      case LayerType.AreaCountry:
      case LayerType.AreaCountryId:
        return ReferenceGeomWorld.equalityFuncCountryCode;
      case LayerType.AreaCountryName:
        return ReferenceGeomWorld.equalityFuncCountryName;

      default:
        throw Utils.createErrorEventObject("Unknown or unexpected layer type: "+ layerType);
    }
  }
}