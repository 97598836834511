import * as XLSX from 'xlsx';
// import * as XLSX from '@sheet/chartdemo';

import {Logger} from '@viamap/viamap2-common';
import {Utils} from '@viamap/viamap2-common';
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import { DemographyReportMetaData, ReportTranslation } from '../components/DemographyReport';
import {ExcelSheetDataAccessor} from '../managers/Parser';
import {MitDataType} from '../common/managers/Types';

/**
 * This module contains functionality for creating and writing spreadsheets
 */

export class SheetFunc {

  static getColumn (sheetx:any, column:number, m:MitDataType ) {

    var da:ExcelSheetDataAccessor = new ExcelSheetDataAccessor(sheetx);
    let noOfRows=da.getRowMax();
    let isNumericField:boolean = // If it is a numeric value and not a number.
      [MitDataType.Number, MitDataType.AdmReg_DK_MunicipalityId, MitDataType.AdmReg_DK_RegionId, MitDataType.AdmReg_DK_ZipCodeId,
      MitDataType.Coord_UTM32_X, MitDataType.Coord_UTM32_Y, MitDataType.Coord_WGS84_Lat, MitDataType.Coord_WGS84_Lon,
      /*MitDataType.Value*/, MitDataType.Value2].indexOf(m) !== -1;

    let values:any[] = [];
    // Skip header row
    for (let row=1; row <= noOfRows; row++) {
      let key = XLSX.utils.encode_cell({c:column-1,r:row});
      let hop = sheetx.hasOwnProperty(key);
      let val:any = undefined;
      if (hop) {
        val = sheetx[key].v;
        let fmt:any = sheetx[key].t;
        if (isNumericField && isNaN(val)) {
          val=0;
          // ToDo: find a better way of marking bad data elements
        }
        if (fmt==='n') {
          // reduce precision for numeric values.
          // excel sometimes returns 7.000000000000001 instead of 7.
          // excel sometimes returns 57.9999999999999 instead of 58.
          val = Number.parseFloat((val+0.00000000000001 as Number).toFixed(13));
        }
        // Trim whitespace from strings
        if (fmt==='s') {
          let vl:string = val;
          val = vl && vl.trim(); 
        }
      }
      if (val === undefined) {
        val= isNumericField ? 0 : "";
      }

      values.push(val);
    }
    return values;
  }

    static createBlob(wb:XLSX.WorkBook):Blob {
        var wbout = XLSX.write(wb, {/* cellStyles:true, */ bookType:'xlsx',  type: 'binary'});
        function s2ab(s:any) {

                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i<s.length; i++) { 
                    /* tslint:disable-next-line */
                    view[i] = s.charCodeAt(i) & 0xFF;
                }
                return buf;
                
        }
        return new Blob([s2ab(wbout)],{type:"application/octet-stream"});
    }

    /**
     * Customer Specific Area Report
     */
    static create360NDataReport(metaData:DemographyReportMetaData, json:any): XLSX.WorkBook {
        var wb = XLSX.utils.book_new();
        wb.Props = {
                Title: metaData.reportName,
                Author: "Viamap",
                CreatedDate: metaData.reportDate
        };
        // Column widths
        var wscols = [
          {wch: 15}, // "characters"
          {wch: 20}, // "characters"
          {wch: 15}, // "characters"
          {wch: 20}, // "characters"
          {wch: 20}, // "characters"
          {wch: 15}, // "characters"
          // {wch: 15}, // "characters"
          // {wpx: 50}, // "pixels"
          // ,
          // {hidden: true} // hide column
        ];
        let languageCode=metaData.languageCode;

        let sheetName = Localization.getTextSpecificLanguage(languageCode,"SheetName:Report");
        wb.SheetNames.push(sheetName);

        // Load some dummy data to initalize worksheet object.
        var ws = XLSX.utils.json_to_sheet([{Report:"Date"}]);

        // Write header section
        this.writeToCell(ws,{r:0, c:0},metaData.reportName);
        // this.writeToCell(ws,{r:0, c:0},Localization.getTextSpecificLanguage(languageCode,"Report"));
        // this.writeToCell(ws,{r:0, c:1},metaData.reportName);
        this.writeToCell(ws,{r:1, c:0},Localization.getTextSpecificLanguage(languageCode,"Date"));
        this.writeToCell(ws,{r:1, c:1},metaData.reportDate.toLocaleString('da-DK'));
        if (metaData.address) {
          this.writeToCell(ws,{r:2, c:0},Localization.getTextSpecificLanguage(languageCode,"Report:Address"));
          this.writeToCell(ws,{r:2, c:1},metaData.address);
        }
        this.writeToCell(ws,{r:3, c:0},Localization.getTextSpecificLanguage(languageCode,"Report:Position (lat/lng)"));
        this.writeToCell(ws,{r:3, c:1},Localization.getFormattedText("Report:{lat}/{lng}",{lat:metaData.center.lat.toFixed(4), lng:metaData.center.lng.toFixed(4)}));
        this.writeToCell(ws,{r:4, c:0},Localization.getTextSpecificLanguage(languageCode,"Report:Radius"));
        this.writeToCell(ws,{r:4, c:1},Localization.getFormattedText("Report:{radius}m",{radius:metaData.radiusMeters.toFixed(0)}));
    
        let row=5;
        let col=0;
    
        function getNumberFormat(sectionTitle:string, columnIdx:number, columnHeader:string):string {
          let result = "0.00";
          if (columnHeader.toLowerCase().includes("count")
            || columnHeader.toLowerCase().includes("index")
            ) {
              result = "0";
            } else {
              if (columnHeader.toLowerCase().includes("percent")) {
                result = "0%";
              }
            }
          return result;
        }

        function constructSheetRange(letter:string, firstRow:number, lastRow:number):string {
          return Utils.formatString("'Rapport'!{letter}{firstRow}:{letter}{lastRow}", {letter:letter, firstRow:firstRow, lastRow:lastRow});
        }

        if(!ws["!charts"]) {
          ws["!charts"] = [];
        }
    
        const minimumSectionNoOfRows = 1;
        try {
          Object.keys(json).forEach(key => {
            let list = json[key];
            let title = ReportTranslation.getTextSpecificLanguage(languageCode, key);
            row++;
            this.writeToCell(ws,{r:row, c:col},title);
            row++;
            let columnNumberFormats:any[] = [];
            let isHeaderRow=true;
            let firstRowInGroup;
            let lastRowInGroup;
            list.forEach((element) => {
              Object.keys(element).forEach((key2,idx) => {
                let val = element[key2];
                let hdr = ReportTranslation.getTextSpecificLanguage(languageCode, key2);
                if (isHeaderRow) {
                  this.writeToCell(ws,{r:row, c:1+idx},idx === 0 ? title : hdr);
                  columnNumberFormats[idx] = getNumberFormat(title, idx, key2);
                }
                if (idx === 0 && (typeof(val)==="string")) {
                  val = ReportTranslation.getTextSpecificLanguage(languageCode, val);
                }
                this.writeToCell(ws,{r:row+(isHeaderRow?1:0), c:1+idx} ,val || val === 0 ? val : "", columnNumberFormats[idx]);
              });
              if (isHeaderRow) {
                row++;
                firstRowInGroup = row;
              }
              row++;
              isHeaderRow = false;
              lastRowInGroup = row; // is overwritten eact time
            });

            // add space if section is short (to allow room for the Chart)
            while(row-firstRowInGroup < minimumSectionNoOfRows) {
              row++;
            }
          });
        } catch (e:any) {
          Logger.logError("MapScreen","Write report to excel", e.message);
          // ToDo: show error to user in user language.
          throw new Error(e.message);
        }
     
        // Set column widths
        ws['!cols'] = wscols;
    
        wb.Sheets[sheetName] = ws;

        return wb;
      }

      /**
       * Customer Specific Chart solution
       */
    static Create360NChart(title:string, x:number, y:number, width:number, height:number, categoryRange:string, titleRange1:string, valueRange1:string, titleRange2:string, valueRange2:string):any {
      // Generate a bar chart
      var cs1 = XLSX.utils.aoa_to_sheet([[]]);
      cs1["!type"] = "chart";
      cs1["!title"] = title;
      cs1["!legend"] = { pos: "r" };
      cs1["!plot"] = [];

      /* position on worksheet required for embedded charts */
      cs1["!pos"] = { x: x, y: y, w: width, h: height };

      var stackbar = {
        t: 'bar',
        ser: [] as any[],
//              grouping: "stacked" // stacked bar chart
      };

      stackbar.ser.push({
        name: titleRange1,
        cols: ["cat", "val"],
        ranges: [categoryRange, valueRange1],
        raw: true
      });
      stackbar.ser.push({
        name: titleRange2,
        cols: ["cat", "val"],
        ranges: [categoryRange, valueRange2],
        raw: true
      });

      cs1["!plot"].push(stackbar);
  
      return cs1;
    }

    static _getTypeFromValue(value:any):XLSX.ExcelDataType {
      let result;

      /* tslint:disable */
      if(typeof value == "string") result = 's'; // string
      else if(typeof value == "number") result = 'n'; // number
      else if(value === true || value === false) result = 'b'; // boolean
      else if(value instanceof Date) result = 'd';
      else {
        throw new Error("cannot store value");
      }
      /* tslint:enable */

      return result;
    }

    static formatCell(val:any, excelCellFormat?:string):string {
        let cell:XLSX.CellObject = {t:this._getTypeFromValue(val), v:val, z:excelCellFormat||"", s:{}};  
        if (cell.t === 'd') {
          let dateFormat = SettingsManager.getSystemSetting("dateLabelFormat", "dd-mm-yy");
          cell.z = dateFormat;
        }
        let res = XLSX.utils.format_cell(cell);
        if (cell.t === 'n') {
          // todo: should react to the user's current language selection (when this is implemented)
          let language = SettingsManager.getSystemSetting("defaultLanguage","da");
          if (language === "da") {
            res = res.replace(/\,/g,"#").replace(/\./g,",").replace(/\#/g,".");
          }
        }
        return res;
    }

    static  writeToCell(worksheet:XLSX.WorkSheet,cellRef:{c:number,r:number}, value:any, numberFormat?:string, excelCellFormat?:string) {
        var cell = {t:'?', v:value, z:"", s:{}};
        cell.t = this._getTypeFromValue(value);
        /* tslint:disable */
        
        // Set format for numbers
        let nF = numberFormat || "0.00";
        if (cell.t === 'n') {
          // DROP DECIMALS FOR LARGE NUMBERS
          if (nF === "0.00" && value > 10000) {
            nF="0";
          }
          cell.z = nF;
        }
        if (excelCellFormat) {
          cell.z = excelCellFormat;
        }

        // banded tables
        // todo: below does not work. no effect.
        if (Math.abs(cellRef.r / 2) === 0) {
          cell.s = { italic:true, fgColor: { rgb: "D9D9D9" }, color: "red"}; // grey background
        } else {
//          cell.s = { bold:true, bgColor: { rgb: "FF0000" }, color:"blue" }; // green background
          cell.s = { patternType:"none"};
        }

        let address = XLSX.utils.encode_cell(cellRef)
        /* add to worksheet, overwriting a cell if it exists */
        worksheet[address] = cell;
      
        /* find the cell range */
        var range = XLSX.utils.decode_range(worksheet['!ref']!);
        var addr = XLSX.utils.decode_cell(address);
      
        /* extend the range to include the new cell */
        if(range.s.c > addr.c) range.s.c = addr.c;
        if(range.s.r > addr.r) range.s.r = addr.r;
        if(range.e.c < addr.c) range.e.c = addr.c;
        if(range.e.r < addr.r) range.e.r = addr.r;
      
        /* update range */
        worksheet['!ref'] = XLSX.utils.encode_range(range);
        /* tslint:enable */
      }

      static createWorkBookFromJSON(
        props: {
          Title: string,
          Author: string,
          CreatedDate: Date
        },
        sheets:{
          sheetName:string, 
          rows:any[]
        }[]
      ):XLSX.WorkBook  {
        let wb = XLSX.utils.book_new();
        wb.Props = props;
        sheets.forEach((sht) => {
          wb.SheetNames.push(sht.sheetName);
    
          let ws = XLSX.utils.json_to_sheet(sht.rows, {cellDates:true});
  
          wb.Sheets[sht.sheetName] = ws;
        })
        return wb;
    }

    static createImportResultWorkBook(rows:any):XLSX.WorkBook  {
        let sheetName="Result";
        let wb = XLSX.utils.book_new();
        wb.Props = {
                Title: "GeoCode Result",
                Author: "Viamap",
                CreatedDate: new Date()
        };
        
        wb.SheetNames.push(sheetName);
  
        let ws = XLSX.utils.json_to_sheet(rows);

        // todo: Change headings to local language
        wb.Sheets[sheetName] = ws;
        return wb;
    }

    static createFilterWorkBook(ws:XLSX.WorkSheet):XLSX.WorkBook  {
      let sheetName="Result";
      let wb = XLSX.utils.book_new();
      wb.Props = {
              Title: "GeoCode Result",
              Author: "Viamap",
              CreatedDate: new Date()
      };
      
      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = ws;
      return wb;
    }

    static sheetToDownload(ws:XLSX.WorkSheet):XLSX.WorkBook {
      let wb = XLSX.utils.book_new();
      wb.Props = {
          Title: "Mapit Download",
          Author: "MapitV3",
          CreatedDate: new Date()
      }
      const sheetName = "Result"
      wb.SheetNames.push(sheetName)
      wb.Sheets[sheetName] = ws;
      return wb
    }

    static filterWorkSheet(input:XLSX.WorkSheet, linesToInclude:number[]):XLSX.WorkSheet {
      let result = XLSX.utils.json_to_sheet([{Report:"Date"}]);
      let range = XLSX.utils.decode_range(input['!ref']!);
      let outputRange = range;

      let outputRow=0;
      let R, C;
      /* walk every row in the range */
      const firstRow = range.s.r;
      for (R = firstRow; R <= range.e.r; ++R) {
        let found:boolean = Boolean(linesToInclude.find((obj) => { return obj === R; }));
        if (R === firstRow) {
          found=true; // always copy header row.
        }

        if (found) {
          /* walk every column in the range */
          for (C = range.s.c; C <= range.e.c; ++C) {
            try {
              let cell = input[XLSX.utils.encode_cell({c: C, r: R})];
              result[XLSX.utils.encode_cell({c: C, r: outputRow})] = cell;
            } catch (e:any) {
                Logger.logError("SheetFunc", "filterWorkSheet", "Got cell exception:"+e);
            }
          }
          outputRow++;
        }
      }
      // Set the result range
      outputRange.s.r=range.s.r;
      outputRange.e.r=outputRow;
      outputRange.s.c=range.s.c;
      outputRange.e.c=range.e.c;
      
      /* update range */
      result['!ref'] = XLSX.utils.encode_range(outputRange);
      return result;
    }

    static jsonToSheet(rows: any): XLSX.WorkSheet {
      return XLSX.utils.json_to_sheet(rows);
    }

    static sheetToJson(sheet: XLSX.Sheet): {[key:string]:any}[] {
      return XLSX.utils.sheet_to_json(sheet)
    }

}
