import * as React from 'react';
import { Button, Table, Modal, FormControl, FormGroup, Row, Form, Col, Alert, Badge } from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import { GenerateGeom, SpatialReturnType } from '../managers/GenerateGeom';
import { useState } from 'react';
import { MitLatLng } from 'src/managers/MapFacade';
import { SelectCircle } from './SelectCircle';
import { MapitStateContext } from 'src/states/MapitState';
import { MitBody, MitButton, MitDialog, MitFooter, MitHeader, MitInfo } from './ComponentUtils';
import { Left } from 'react-bootstrap/lib/Media';

enum SelectionState { Initial, CenterSelected, RimSelected, WaitingForServer, EmptyArea}
enum SpatialFunctionMode { Report, Export }

type Props = {
    showWindow: number;
    callbackOnCancel: () => void;
    callbackOnReport?: (center:MitLatLng, distance:number, address?:string) => void;
    callbackOnExport?: (spatialReturn:SpatialReturnType, center:MitLatLng, distance:number) => void;
    mode:SpatialFunctionMode,
    maxDistanceMeters:number,
    minDistanceMeters: number
};

export const SpatialFunctionDialog = (props:Props) => {
   let DefaultDistance=50;
   let MinimumDistance=50; 

    let [distance, setDistance] = useState<number>(DefaultDistance);
    let [selectionState, setSelectionState] = useState<SelectionState>(SelectionState.Initial);
    let [coords, setCoords] = useState<MitLatLng>();
    const { state: mapitState } = React.useContext(MapitStateContext);

   function cleanup() {
    //   map && marker && marker.removeFrom(map);
    //   map && map.off("click", mapClickHandler, this);
    //   map && map.off("mousemove", mapMoveHandler, this);
    //   map && map.closePopup();
    //   setState({marker: undefined});
   }
   
   function onClickExport() {
      cleanup();
      if (coords) {
        let spatialReturn = GenerateGeom.spatialSelection(mapitState, coords, distance!);
        if (Object.keys(spatialReturn).length == 0) {
            setSelectionState(SelectionState.EmptyArea)
          } else  {
            props.callbackOnExport?.(spatialReturn, coords, distance!);
          }
      }
   }

   function onClickApply() {
      cleanup();
      setSelectionState(SelectionState.WaitingForServer);
      coords && props.callbackOnReport && props.callbackOnReport(coords, distance, "");
   }

   function onClickCancel() {
      cleanup();
      props.callbackOnCancel();
   }

   function validateForm():boolean {
    return Boolean(coords && distance);
   }

    if (props.showWindow) {
      
        let instruction;
        let infoBsStyle;
        switch(selectionState) {
            case SelectionState.Initial:
                instruction = Localization.getText("Click to mark center of area to report on");
                infoBsStyle="warning";
                break;
            case SelectionState.EmptyArea:
                instruction = Localization.getText("No points in the selected area");
                infoBsStyle="warning";
                break;
            case SelectionState.CenterSelected:
                instruction = Localization.getText("Stretch to size and click when done");
                infoBsStyle="info";
                break;
            case SelectionState.RimSelected:
                infoBsStyle="success";
                if (props.mode === SpatialFunctionMode.Export) {
                    instruction = Localization.getText("Press 'Spatial Export' or click on map to start over");
                    break;
                }
                instruction = Localization.getText("Press 'Report' button to generate report or click to start over");
                break;
            case SelectionState.WaitingForServer:
                instruction = (
                    <div className="mit-text-appear-95">
                    {Localization.getText("Wating for server to produce the results...")}
                    </div>);
                infoBsStyle="success";
                break;            
            default:
                throw Utils.createErrorEventObject("Unknown or unexpected state:"+selectionState);
        }

        let RenderCommitButton = props.mode === SpatialFunctionMode.Export ? (
            <MitButton 
                variant="normal"
                onClick={()=> onClickExport()}
                disabled={!validateForm()}
            >
            {Localization.getText("Spatial Export")}
            </MitButton>
        ) : (
            <MitButton 
                variant="normal"
                onClick={()=> onClickApply()}
                disabled={!validateForm()}
            >
            {Localization.getText("Generate")}
            </MitButton>
        );

        let HeadingText = props.mode === SpatialFunctionMode.Export ?
            Localization.getText("Spatial Export") : Localization.getText("Demography Report");

        return (

        <MitDialog show={props.showWindow} style={{left:"25%", top:"2em"}} >
            <MitHeader position="center" >
                {HeadingText}
            </MitHeader>
            <MitBody>
            <MitInfo>{instruction} </MitInfo>
            <Form>

                    <FormGroup as={Row}
                        style={{marginBottom:"10px"}}
                        controlId="formBasicText"
                        >
                        {/* <Form.Label column sm={3}>{""}
                        </Form.Label> */}
                        <Col sm={9} style={{textAlign:"left"}}>
                       {coords ? (distance > MinimumDistance ? "Radius "+distance.toLocaleString(undefined, {maximumSignificantDigits:4})+" m" : "") : Localization.getText("(Click on map to mark center)")}
                        </Col>
                    </FormGroup>

            </Form>
            </MitBody>
            <MitFooter>
            {RenderCommitButton}
            <MitButton
                autoFocus={true}
                            variant="close"
                            onClick={()=> onClickCancel()}
                            disabled={selectionState === SelectionState.WaitingForServer}
                        >
                        {Localization.getText("Cancel")}
                        </MitButton>
            </MitFooter>
            {selectionState !== SelectionState.WaitingForServer ? (
            <SelectCircle
            active={true}
            showWindow = {props.showWindow}
            minDistanceMeters = {props.minDistanceMeters}
            maxDistanceMeters = {props.maxDistanceMeters}
            callbackOnStatus = { (centerSelected, rimSelected, center, distanceMeters) => {
                setDistance(distanceMeters);
                setCoords(center);
                if (centerSelected) {
                    if (rimSelected)
                        setSelectionState(SelectionState.RimSelected);
                    else 
                        setSelectionState(SelectionState.CenterSelected);
                }
                else 
                    setSelectionState(SelectionState.Initial);
            }}
            callbackOnCircle = { (center, distanceMeters) => {
                setDistance(distanceMeters);
                setCoords(center);
            }}
            />) : null }
        </MitDialog>
        );
    } else {
        return(null);
    }
  }


export const GenerateReportDialog = (props:any) => {
    return SpatialFunctionDialog({...props, mode:SpatialFunctionMode.Report, minDistanceMeters: 50, maxDistanceMeters: 10000});
}

export const SpatialExportDialog = (props:any) => {
    return SpatialFunctionDialog({...props, mode:SpatialFunctionMode.Export, minDistanceMeters: 50, maxDistanceMeters: 200000});
}


