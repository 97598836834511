/**
 * This dialog is used to display a URL link - which is used to open a map in read only mode.
 * Users will copy the link from a text area and e.g. send by email to recipients.
 */

import * as React from 'react';
import {Button, Nav, Modal, FormGroup, Form, FormControl, Alert } from 'react-bootstrap';

import {Localization, SettingsManager} from "@viamap/viamap2-common";

type Props= {
    showWindow:boolean;
    url:string;
    embedCode:string;
    expireDate?:Date;
    callbackOnCancel: ()=>void;
};

export function ReadOnlyMapDialog(props:Props) {
    const [showEmbedCode,setShowEmbedCode] = React.useState(false)

    function handleClose(e:any) {
        // hide this window
        e && e.preventDefault();
        props.callbackOnCancel();
    }

    function onSubmit(e:any) {
        e && e.preventDefault();
    }

    function handleCopyToClipboard(e:any) {
        /* Get the text field */
        let copyText:HTMLInputElement = document.getElementById("mit-url-text-area") as HTMLInputElement;
        
        if (copyText) {
            /* Select the text field */
            copyText.select();
        
            /* Copy the text inside the text field */
            document.execCommand("copy");
        
            /* Alert the copied text */
//                alert("Copied the text: " + copyText.value);
        } else {
//                alert("Field not found");
        }
    }

    function handleShowEmbedCode(showEmbedCode:boolean) {
        setShowEmbedCode(showEmbedCode)
    }

    if (!props.showWindow) {
        return null
    }


            const copytoclipboardbutton = <Button type="submit" onClick={(e)=>handleCopyToClipboard(e)} className="mb-4 btn btn-primary">{Localization.getText("Copy to Clipboard")}</Button>;
            const cancelbutton = <Button type="button" onClick={(e)=>handleClose(e)} className="mb-4 btn btn-default">{Localization.getText("Close")}</Button>;
            return(
            <div id="mit-save-map-dialog" className="small save-map-dialog" style={{width:"600px", maxWidth:"100%"}}>
                <Modal.Dialog className='Flat-Modal' style={{height:"80%", borderRadius: "0px", margin:"0px"}}>
                    <Modal.Header style={{display: "block"}}>
                    <Modal.Title as="h4">{Localization.getText("Readonly Link has been Saved")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert>
                    {Localization.getText("Expires")} {props?.expireDate?.toLocaleDateString() || "ukendt"}
                    </Alert>
                    <Nav 
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"0px"}}
                    >
                        <Button 
                            style={{borderRadius:"0px", marginBottom:"0px !mportant"}}
                            variant={showEmbedCode ? "outline-success" : "success"} 
                            onClick={() => {handleShowEmbedCode(false);}}
                            className="btn"
                        >
                            Link
                        </Button>
                        <Button 
                            style={{borderRadius:"0px", marginBottom:"0px !mportant"}}
                            variant={!showEmbedCode ? "outline-success" : "success"} 
                            onClick={() => {handleShowEmbedCode(true);}}
                            className="btn"
                        >
                            Embed Code
                        </Button>
                    </Nav>
                    <Form /* horizontal={true} */ onSubmit={(e)=>onSubmit(e)}>
                    <FormGroup 
                        // bsSize="small"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                    >
                            <FormControl
                                id="mit-url-text-area"
                                size="sm"
                                readOnly={true}
                                as="textarea"
                                type="textarea"
                                value={showEmbedCode ? props.embedCode : props.url}
                            />
                    </FormGroup>
                    </Form>
            </Modal.Body>
            <Modal.Footer className='Flat-ButtonWrap'>
                {copytoclipboardbutton}{cancelbutton}
            </Modal.Footer>
            </Modal.Dialog>
                    
            </div>)
}

export function SavedMapDialog(props: {showWindow, refLink, callbackOnCancel, expireDate}) {
    const [call] = React.useState(location.origin + location.pathname)

    return (<ReadOnlyMapDialog
        showWindow={props.showWindow}
        url={call + "?mapdef=" + props.refLink}
        embedCode={"<iframe src=\"" + call + "?mapdef=" + props.refLink + "&embeddedmode=true\" width=\"350\" height=\"300\"></iframe>"}
        expireDate={props.expireDate}
        callbackOnCancel={props.callbackOnCancel} />)
}