export class BBRKodeLister {
  static AdresseRolle(kode) {
    return ["Fastsat til denne", "Kun vejledende"][Number(kode)] ?? "-";
  }
  static AdresseStatus(kode) {
    return (
      [
        "Har husnummer (adresse)",
        "Markeret til at få husnummer (adresse)",
        "Uden husnummer",
      ][Number(kode)] ?? "-"
    );
  }
  static AfloebsForhold(kode) {
    return (
      {
        1: "Fælleskloakeret: spildevand + tag- og overfladevand",
        2: "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
        3: "Fælleskloakeret: spildevand",
        4: "Fælleskloakeret: tag- og overfladevand",
        5: "Separatkloakeret: spildevand + tag- og overfladevand",
        6: "Separatkloakeret: spildevand + delvis tag- og overfladevand",
        7: "Separatkloakeret: spildevand",
        8: "Separatkloakeret: tag- og overfladevand",
        9: "Spildevandskloakeret: Spildevand",
        10: "Afløb til offentligt kloaksystem",
        11: "Afløb til fællesprivat kloaksystem",
        12: "Afløb til fællesprivat kloaksystem med tilslutning til spildevandsforsyningens kloaksystem",
        20: "Afløb til samletank",
        21: "Afløb til samletank for toiletvand og mekanisk rensning af øvrigt spildevand",
        29: "Mekanisk rensning med nedsivningsanlæg med tilladelse",
        30: "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
        31: "Mekanisk rensning med privat udledning direkte til vandløb, søer eller havet",
        32: "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
        70: "Udledning uden rensning direkte til vandløb, søer eller havet",
        75: "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
        80: "Anden type afløb",
        90: "Ingen udledning",
        101: "SOP: Minirenseanlæg med direkte udledning",
        102: "SOP: Minirenseanlæg med udledning til markdræn",
        103: "SOP: Minirenseanlæg med nedsivning i faskine",
        104: "SOP: Nedsivning til sivedræn",
        105: "SOP: Samletank",
        106: "SOP: Pileanlæg med nedsivning (uden membran)",
        107: "SOP: Pileanlæg uden udledning (med membran)",
        108: "SOP: Beplantede filteranlæg med nedsivning i faskine",
        109: "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
        110: "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
        190: "SOP: Andet",
        201: "SO: Biologisk sandfilter med direkte udledning",
        202: "SO: Biologisk sandfilter med udledning til markdræn",
        203: "SO: Minirensanlæg med direkte udledning",
        204: "SO: Minirenseanlæg med udledning til markdræn",
        205: "SO: Beplantede filteranlæg med direkte udledning",
        206: "SO: Beplantede filteranlæg med udledning til markdræn",
        290: "SO: Andet",
        301: "OP: Minirenseanlæg med direkte udledning",
        302: "OP: Minirenseanlæg med udledning til markdræn",
        390: "OP: Andet",
        401: "O: Rodzoneanlæg med direkte udledning",
        402: "O: Rodzoneanlæg med udledning til markdræn",
        403: "O: Minirenseanlæg med direkte udledning",
        404: "O: Minirenseanlæg med udledning til markdræn",
        490: "O: Andet",
        501: "Øvrige renseløsninger: Mekanisk med direkte udledning",
        502: "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
        503: "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
        504: "Udledning til jordoverfladen",
        505: "Udledning urenset",
        590: "Øvrige renseløsninger: Andet",
        601: "Anden type afløb (større end 30 PE med egen udledning)",
        701: "Intet afløb",
      }[Number(kode)] ?? "-"
    );
  }
  static AfvigendeEtager(kode) {
    return (
      {
        0: "Bygningen har ikke afvigende etager",
        10: "Bygningen har afvigende etager",
        11: "Bygningen indeholder hems",
        12: "Bygningen indeholder etagegennembrydende rum",
        13: "Bygningen indeholder indskudt etage",
      }[Number(kode)] ?? "-"
    );
  }
  static ArealType(kode) {
    return Number(kode) ? "Type " + Number(kode) : "-";
  }
  static Artskode(kode) {
    return (
      {
        0: "Vigtigste matrikelnummer på ejendommen (normalt med evt. bygning)",
        1: "Andre matrikelnumre på ejendommen",
        2: "Kode for ejerlejlighed",
        3: "Bygning på matrikelnummer (på lejet grund)",
        4: "Del af matrikelnummer (parcel) – [kan være fælleslod]",
        5: "Umatrikuleret areal",
        6: "Umatrikuleret havneareal",
        7: "Umatrikuleret jernbaneareal",
        8: "Bygning på umatrikuleret areal",
        9: "Bygning på umatrikuleret havneareal",
        10: "Bygning på umatrikuleret jernbaneareal",
        20: "Andet afgivet areal, f. eks. lejet grund",
        21: "Tilskyllet",
        22: "Bortskyllet",
        23: "Eksproprieret til",
        24: "Eksproprieret fra",
        25: "Dokumenteret arealafvigelse tillagt",
        26: "Dokumenteret arealafvigelse afgivet",
        27: "Tillagt ved jordfordeling",
        28: "Afgivet ved jordfordeling",
        30: "(Foreløbig) Vigtigste matrikelnummer på ejendommen (normalt med evt. bygning)",
        31: "(Foreløbig) Andre matrikelnumre på ejendommen",
        32: "(Foreløbig) Kode for ejerlejlighed",
        33: "(Foreløbig) Bygning på matrikelnummer (på lejet grund)",
        34: "(Foreløbig) Del af matrikelnummer (parcel)",
        35: "(Foreløbig) Umatrikuleret areal",
        36: "(Foreløbig) Umatrikuleret havneareal",
        37: "(Foreløbig) Umatrikuleret jernbaneareal",
        38: "(Foreløbig) Bygning på umatrikuleret havneareal",
        39: "(Foreløbig) Bygning på umatrikuleret havneareal",
        40: "(Foreløbig) Bygning på umatrikuleret jernbaneareal",
      }[Number(kode)] ?? "-"
    );
  }
  static AsbestholdigtMateriael(kode) {
    return (
      {
        1: "Asbestholdigt ydervægsmateriale",
        2: "Asbestholdigt tagdækningsmateriale",
        3: "Asbestholdigt ydervægs- og tagdækningsmateriale",
        4: "Øvrige asbestholdige materialer",
        5: "Ikke asbest i bygningen",
      }[Number(kode)] ?? "-"
    );
  }
  static Badeforhold(kode) {
    return (
      {
        C: "Adgang til badeværelse",
        D: "Hverken badeværelse eller adgang til badeværelse",
        V: "Badeværelse i enheden",
      }[kode] ?? "-"
    );
  }
  static BeregningsprincipForArealAfCarport(kode) {
    return (
      {
        1: "Carportareal er målt efter tagflade",
        2: "Carportarealet er målt ½ meter inde på åbne sider",
      }[Number(kode)] ?? "-"
    );
  }
  static Boligtype(kode) {
    return (
      {
        1: "Egentlig beboelseslejlighed med eget køkken",
        2: "Blandet bolig og erhverv med eget køkken",
        3: "Enkeltværelse uden eget køkken",
        4: "Fællesbolig",
        5: "Sommer- eller fritidsbolig",
        E: "Andet",
      }[kode] ?? "-"
    );
  }

  static BygAfloebsforhold(kode) {
    return (
      {
        1: "Fælleskloakeret: spildevand + tag- og overfladevand",
        10: "Afløb til offentligt kloaksystem",
        101: "SOP: Minirenseanlæg med direkte udledning",
        102: "SOP: Minirenseanlæg med udledning til markdræn",
        103: "SOP: Minirenseanlæg med nedsivning i faskine",
        104: "SOP: Nedsivning til sivedræn",
        105: "SOP: Samletank",
        106: "SOP: Pileanlæg med nedsivning (uden membran)",
        107: "SOP: Pileanlæg uden udledning (med membran)",
        108: "SOP: Beplantede filteranlæg med nedsivning i faskine",
        109: "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
        11: "Afløb til fællesprivat kloaksystem",
        110: "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
        12: "Afløb til fællesprivat kloaksystem med tilslutning til spildevandsforsyningens kloaksystem",
        190: "SOP: Andet",
        2: "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
        20: "Afløb til samletank",
        201: "SO: Biologisk sandfilter med direkte udledning",
        202: "SO: Biologisk sandfilter med udledning til markdræn",
        203: "SO: Minirensanlæg med direkte udledning",
        204: "SO: Minirenseanlæg med udledning til markdræn",
        205: "SO: Beplantede filteranlæg med direkte udledning",
        206: "SO: Beplantede filteranlæg med udledning til markdræn",
        21: "Afløb til samletank for toiletvand og mekanisk rensning af øvrigt spildevand",
        29: "Mekanisk rensning med nedsivningsanlæg med tilladelse",
        290: "SO: Andet",
        3: "Fælleskloakeret: spildevand",
        30: "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
        301: "OP: Minirenseanlæg med direkte udledning",
        302: "OP: Minirenseanlæg med udledning til markdræn",
        31: "Mekanisk rensning med privat udledning direkte til vandløb, søer eller havet",
        32: "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
        390: "OP: Andet",
        4: "Fælleskloakeret: tag- og overfladevand",
        401: "O: Rodzoneanlæg med direkte udledning",
        402: "O: Rodzoneanlæg med udledning til markdræn",
        403: "O: Minirenseanlæg med direkte udledning",
        404: "O: Minirenseanlæg med udledning til markdræn",
        490: "O: Andet",
        5: "Separatkloakeret: spildevand + tag- og overfladevand",
        501: "Øvrige renseløsninger: Mekanisk med direkte udledning",
        502: "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
        503: "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
        504: "Udledning til jordoverfladen",
        505: "Udledning urenset",
        590: "Øvrige renseløsninger: Andet",
        6: "Separatkloakeret: spildevand + delvis tag- og overfladevand",
        601: "Anden type afløb (større end 30 PE med egen udledning)",
        7: "Separatkloakeret: spildevand",
        70: "Udledning uden rensning direkte til vandløb, søer eller havet",
        701: "Intet afløb",
        75: "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
        8: "Separatkloakeret: tag- og overfladevand",
        80: "Anden type afløb",
        9: "Spildevandskloakeret: Spildevand",
        90: "Ingen udledning",
      }[kode] ?? "-"
    );
  }

  static BygAnvendelse(kode) {
    return (
      {
        110: "Stuehus til landbrugsejendom",
        120: "Fritliggende enfamiliehus",
        121: "Sammenbygget enfamiliehus",
        122: "Fritliggende enfamiliehus i tæt-lav bebyggelse",
        130: "(UDFASES) Række-, kæde-, eller dobbelthus (lodret adskillelse mellem enhederne).",
        131: "Række-, kæde- og klyngehus",
        132: "Dobbelthus",
        140: "Etagebolig-bygning, flerfamiliehus eller to-familiehus",
        150: "Kollegium",
        160: "Boligbygning til døgninstitution",
        185: "Anneks i tilknytning til helårsbolig.",
        190: "Anden bygning til helårsbeboelse",
        210: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
        211: "Stald til svin",
        212: "Stald til kvæg, får mv.",
        213: "Stald til fjerkræ",
        214: "Minkhal",
        215: "Væksthus",
        216: "Lade til foder, afgrøder mv.",
        217: "Maskinhus,  garage mv.",
        218: "Lade til halm, hø mv.",
        219: "Anden bygning til landbrug mv.",
        220: "(UDFASES) Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o.lign.)",
        221: "Bygning til industri med integreret produktionsapparat",
        222: "Bygning til industri uden integreret produktionsapparat",
        223: "Værksted",
        229: "Anden bygning til produktion",
        230: "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
        231: "Bygning til energiproduktion",
        232: "Bygning til energidistribution",
        233: "Bygning til vandforsyning",
        234: "Bygning til håndtering af affald og spildevand",
        239: "Anden bygning til energiproduktion og forsyning",
        290: "(UDFASES) Anden bygning til landbrug, industri etc.",
        310: "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
        311: "Bygning til jernbane- og busdrift",
        312: "Bygning til luftfart",
        313: "Bygning til parkering- og transportanlæg",
        314: "Bygning til parkering af flere end to køretøjer i tilknytning til boliger",
        315: "Havneanlæg",
        319: "Andet transportanlæg",
        320: "(UDFASES) Bygning til kontor, handel, lager, herunder offentlig administration",
        321: "Bygning til kontor",
        322: "Bygning til detailhandel",
        323: "Bygning til lager",
        324: "Butikscenter",
        325: "Tankstation",
        329: "Anden bygning til kontor, handel og lager",
        330: "(UDFASES) Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
        331: "Hotel, kro eller konferencecenter med overnatning",
        332: "Bed & breakfast mv.",
        333: "Restaurant, café og konferencecenter uden overnatning",
        334: "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
        339: "Anden bygning til serviceerhverv",
        390: "(UDFASES) Anden bygning til transport, handel etc",
        410: "(UDFASES) Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
        411: "Biograf, teater, koncertsted mv.",
        412: "Museum",
        413: "Bibliotek",
        414: "Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund",
        415: "Forsamlingshus",
        416: "Forlystelsespark",
        419: "Anden bygning til kulturelle formål",
        420: "(UDFASES) Bygning til undervisning og forskning (skole, gymnasium, forskningslabratorium o.lign.).",
        421: "Grundskole",
        422: "Universitet",
        429: "Anden bygning til undervisning og forskning",
        430: "(UDFASES) Bygning til hospital, sygehjem, fødeklinik o. lign.",
        431: "Hospital og sygehus",
        432: "Hospice, behandlingshjem mv.",
        433: "Sundhedscenter, lægehus, fødeklinik mv.",
        439: "Anden bygning til sundhedsformål",
        440: "(UDFASES) Bygning til daginstitution",
        441: "Daginstitution",
        442: "Servicefunktion på døgninstitution",
        443: "Kaserne",
        444: "Fængsel, arresthus mv.",
        449: "Anden bygning til institutionsformål",
        451: "Beskyttelsesrum",
        490: "(UDFASES) Bygning til anden institution, herunder kaserne, fængsel o. lign.",
        510: "Sommerhus",
        520: "(UDFASES) Bygning til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
        521: "Feriecenter, center til campingplads mv.",
        522: "Bygning med ferielejligheder til erhvervsmæssig udlejning",
        523: "Bygning med ferielejligheder til eget brug",
        529: "Anden bygning til ferieformål",
        530: "(UDFASES) Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
        531: "Klubhus i forbindelse med fritid og idræt",
        532: "Svømmehal",
        533: "Idrætshal",
        534: "Tribune i forbindelse med stadion",
        535: "Bygning til træning og opstaldning af heste",
        539: "Anden bygning til idrætformål",
        540: "Kolonihavehus",
        585: "Anneks i tilknytning til fritids- og sommerhus",
        590: "Anden bygning til fritidsformål",
        910: "Garage",
        920: "Carport",
        930: "Udhus",
        940: "Drivhus",
        950: "Fritliggende overdækning",
        960: "Fritliggende udestue",
        970: "Tiloversbleven landbrugsbygning",
        990: "Faldefærdig bygning",
        999: "Ukendt bygning",
      }[kode] ?? "-"
    );
  }

  static BygDaekningsafgift(kode) {
    return (
      {
        0: "Ikke omfattet af dækningsafgift",
        1: "Omfattet af dækningsafgift",
        2: "Delvist omfattet af dækningsafgift",
      }[kode] ?? "-"
    );
  }

  static Byggesagskode(kode) {
    return (
      {
        1: "BR - Tilladelsessag uden ibrugtagningstilladelse",
        2: "(UDFASES) BR - Anmeldelsessag (garager, carporte, udhuse og nedrivning)",
        3: "BR - Tilladelsessag med ibrugtagningstilladelse",
        4: "BR - Tilladelsessag landbrugsbygning",
        5: "(UDFASES) BR - Anmeldelsessag (øvrige)",
        6: "BR – Tilladelsessag Nedrivning",
        7: "BR – Lovliggørelse",
      }[kode] ?? "-"
    );
  }

  static Byggeskadeforsikringsselskab(kode) {
    return (
      {
        0: "Ingen byggeskadeforsikring",
        1: "Tryg",
        10: "Købstædernes Forsikring",
        100: "Byggeskadeforsikring udløbet",
        11: "ALKA",
        12: "Frida Forsikring Agentur",
        13: "NemForsikring",
        14: "AXA",
        15: "Husejernes Forsikring",
        16: "Garbo",
        17: "Marsh og McLennan Agency A/S",
        18: "First Marine",
        19: "Domus Forsikring A/S",
        2: "Topdanmark",
        4: "Codan",
        5: "If Forsikring",
        6: "Alm. Brand",
        7: "Danske Forsikring",
        8: "Caplloyd A/S",
        98: "Dækket af byggeskadefonden",
        99: "Ingen forsikring på grund af dispensation",
      }[kode] ?? "-"
    );
  }

  static BygherreForhold(kode) {
    return (
      {
        10: "Privatpersoner eller interessentskab",
        20: "Alment boligselskab",
        30: "Aktie-, anpart- eller andet selskab (undtagen interessent­skab)",
        40: "Forening, legat eller selvejende institution",
        41: "Privat andelsboligforening",
        50: "Den kommune, hvori ejendommen er beliggende",
        60: "Anden primærkommune",
        70: "Region",
        80: "Staten",
        90: "Andet, herunder moderejendomme for bebyggelser, der er op­delt i ejerlejligheder samt ejendomme, der ejes af flere ka­te­gorier af ejere",
      }[kode] ?? "-"
    );
  }

  static BygSupplerendeVarme(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Varmepumpe",
        10: "Biogasanlæg",
        2: "Brændeovne og lignende med skorsten",
        3: "Biopejse og lignende uden skorsten",
        4: "Solpaneler",
        5: "Pejs",
        6: "Gasradiator",
        7: "Elvarme",
        80: "Andet",
        90: "(UDFASES) Bygningen har ingen supplerende varme",
      }[kode] ?? "-"
    );
  }

  static BygVandforsyning(kode) {
    return (
      {
        1: "Alment vandforsyningsanlæg",
        2: "Privat vandforsyningsanlæg",
        3: "Enkeltindvindingsanlæg",
        4: "Brønd",
        6: "Ikke alment vandforsyningsanlæg",
        7: "Blandet vandforsyning",
        9: "Ingen vandforsyning",
      }[kode] ?? "-"
    );
  }

  static DispensationFritagelseIftKollektivVarmeforsyning(kode) {
    return (
      {
        1: "Dispensation er tidsbegrænset",

        2: "Dispensationen er ikke tidsbegrænset",
      }[kode] ?? "-"
    );
  }

  static Driftstatus(kode) {
    return (
      {
        1: "I drift",
        2: "Ikke i drift",
      }[kode] ?? "-"
    );
  }

  static Ejendomstype(kode) {
    return (
      {
        1: "Matrikuleret Areal",
        2: "BPFG",
        3: "Ejerlejlighed",
      }[kode] ?? "-"
    );
  }

  static Ejerforholdskode(kode) {
    return (
      {
        10: "Privatpersoner eller interessentskab",
        20: "Alment boligselskab",
        30: "Aktie-, anpart- eller andet selskab (undtagen interessent­skab)",
        40: "Forening, legat eller selvejende institution",
        41: "Privat andelsboligforening",
        50: "Den kommune, hvori ejendommen er beliggende",
        60: "Anden primærkommune",
        70: "Region",
        80: "Staten",
        90: "Andet, herunder moderejendomme for bebyggelser, der er opdelt i ejerlejligheder samt ejendomme, der ejes af flere kategorier af ejere",
        99: "Ikke beregnet",
      }[kode] ?? "-"
    );
  }

  static Elevator(kode) {
    return (
      {
        0: "Der er ikke elevator i opgangen",
        1: "Der er elevator i opgangen",
      }[kode] ?? "-"
    );
  }

  static Energiforsyning(kode) {
    return (
      {
        1: "Gas fra værk",
        2: "230 V el fra værk",
        3: "400 V el fra værk",
        4: "Både 230 V el og gas fra værk",
        5: "Både 400 V el og gas fra værk",
        6: "Hverken el eller gas fra værk",
      }[kode] ?? "-"
    );
  }

  static EnhAnvendelse(kode) {
    return (
      {
        110: "Stuehus til landbrugsejendom",
        120: "Fritliggende enfamiliehus",
        121: "Sammenbygget enfamiliehus",
        122: "Fritliggende enfamiliehus i tæt-lav bebyggelse",
        130: "(UDFASES) Række-, kæde- eller dobbelthus (lodret adskillelse mellem enhederne).",
        131: "Række-, kæde- og klyngehus",
        132: "Dobbelthus",
        140: "Bolig i etageejendom, flerfamiliehus eller to-familiehus",
        150: "Kollegiebolig",
        160: "Bolig i døgninstitution",
        185: "Anneks i tilknytning til helårsbolig",
        190: "Anden enhed til helårsbeboelse",
        210: "(UDFASES) Erhvervsmæssig produktion vedrørende landbrug, skovbrug, gartneri, råstofudvinding og lign.",
        211: "Stald til svin",
        212: "Stald til kvæg, får mv.",
        213: "Stald til fjerkræ",
        214: "Minkhal",
        215: "Væksthus",
        216: "Lade til foder, afgrøder mv.",
        217: "Maskinhus,  garage mv.",
        218: "Lade til halm, hø mv.",
        219: "Anden enhed til landbrug mv.",
        220: "(UDFASES) Erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o. lign.)",
        221: "Enhed til industri med integreret produktionsapparat",
        222: "Enhed til industri uden integreret produktionsapparat",
        223: "Værksted",
        229: "Anden enhed til produktion",
        230: "(UDFASES) El-, gas-, vand- eller varmeværk, forbrændingsanstalt o. lign.",
        231: "Enhed til energiproduktion",
        232: "Enhed til energidistribution",
        233: "Enhed til vandforsyning",
        234: "Enhed til håndtering af affald og spildevand",
        239: "Anden enhed til energiproduktion og forsyning",
        290: "(UDFASES) Anden enhed til produktion og lager i forbindelse med landbrug, industri o. lign.",
        310: "(UDFASES) Transport- og garageanlæg (fragtmandshal, lufthavnsbygning,banegårdsbygning o. lign.)",
        311: "Enhed til jernbane- og busdrift",
        312: "Enhed til luftfart",
        313: "Enhed til parkerings- og transportanlæg",
        314: "Enhed til parkering af flere end to køretøjer i tilknytning til boliger",
        315: "Havneanlæg",
        319: "Andet transportanlæg",
        320: "(UDFASES) Engroshandel og lager.",
        321: "Enhed til kontor",
        322: "Enhed til detailhandel",
        323: "Enhed til lager",
        324: "Butikscenter",
        325: "Tankstation",
        329: "Anden enhed til kontor, handel og lager",
        330: "(UDFASES) Detailhandel m.v.",
        331: "Hotel, kro eller konferencecenter med overnatning",
        332: "Bed & breakfast mv.",
        333: "Restaurant, café og konferencecenter uden overnatning",
        334: "Privat servicevirksomhed som frisør, vaskeri, netcafé mv.",
        339: "Anden enhed til serviceerhverv",
        340: "(UDFASES) Pengeinstitut, forsikringsvirksomhed m.v.",
        350: "(UDFASES) Kontor og liberale erhverv bortset fra offentlig administration  (kontorer for advokater, rådgivende ingeniører, klinikker o.lign.)",
        360: "(UDFASES) Offentlig administration.",
        370: "(UDFASES) Hotel, restauration, vaskeri, frisør og anden servicevirksomhed.",
        390: "(UDFASES) Anden enhed til handel, transport etc.",
        410: "(UDFASES) Biograf, teater, erhvervsmæssig udstilling m.v.",
        411: "Biograf, teater, koncertsted mv.",
        412: "Museum",
        413: "Bibliotek",
        414: "Kirke eller anden enhed til trosudøvelse for statsanerkendte trossamfund",
        415: "Forsamlingshus",
        416: "Forlystelsespark",
        419: "Anden enhed til kulturelle formål",
        420: "(UDFASES) Bibliotek, museum, kirke o. lign.",
        421: "Grundskole",
        422: "Universitet",
        429: "Anden enhed til undervisning og forskning",
        430: "(UDFASES) Undervisning og forskning (skole, gymnasium, forskningslaboratorium).",
        431: "Hospital og sygehus",
        432: "Hospice, behandlingshjem mv.",
        433: "Sundhedscenter, lægehus, fødeklinik mv.",
        439: "Anden enhed til sundhedsformål",
        440: "(UDFASES) Hospital, fødeklinik o. lign.",
        441: "Daginstitution",
        442: "Servicefunktion på døgninstitution",
        443: "Kaserne",
        444: "Fængsel, arresthus mv.",
        449: "Anden enhed til institutionsformål",
        450: "(UDFASES) Daginstitution.",
        451: "Enhed til beskyttelsesrum",
        490: "(UDFASES) Anden institution, herunder kaserne, fængsel m.v.",
        510: "Sommerhus.",
        520: "(UDFASES) Enhed til feriekoloni, vandrehjem o.lign. bortset fra sommerhus",
        521: "Feriecenter, center til campingplads mv.",
        522: "Ferielejlighed til erhvervsmæssig udlejning",
        523: "Ferielejlighed til eget brug",
        529: "Anden enhed til ferieformål",
        530: "(UDFASES) Enhed i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.).",
        531: "Klubhus i forbindelse med fritid- og idræt",
        532: "Svømmehal",
        533: "Idrætshal",
        534: "Tribune i forbindelse med stadion",
        535: "Enhed til træning og opstaldning af heste",
        539: "Anden enhed til idrætsformål",
        540: "Kolonihavehus",
        585: "Anneks i tilknytning til fritids- og sommerhus",
        590: "Anden enhed til fritidsformål",
      }[kode] ?? "-"
    );
  }

  static EnhDaekningsafgift(kode) {
    return (
      {
        0: "Ikke omfattet af dækningsafgift",
        1: "Omfattet af dækningsafgift",
      }[kode] ?? "-"
    );
  }

  static EnhSupplerendeVarme(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Varmepumpe",
        10: "Biogasanlæg",
        2: "Brændeovne og lignende med skorsten",
        3: "Biopejse og lignende uden skorsten",
        4: "Solpaneler",
        5: "Pejs",
        6: "Gasradiator",
        7: "Elvarme",
        80: "Andet",
        90: "(UDFASES) Bygningen har ingen supplerende varme",
      }[kode] ?? "-"
    );
  }

  static EnhVarmeinstallation(kode) {
    return (
      {
        1: "Fjernvarme/blokvarme",
        2: "Centralvarme med én fyringsenhed",
        3: "Ovn til fast og flydende brændsel",
        5: "Varmepumpe",
        6: "Centralvarme med to fyringsenheder",
        7: "Elvarme",
        8: "Gasradiator",
        9: "Ingen varmeinstallation",
        99: "Blandet",
      }[kode] ?? "-"
    );
  }

  static EtageType(kode) {
    return (
      {
        0: "Regulær etage",
        1: "Tagetage",
        2: "Kælder",
      }[kode] ?? "-"
    );
  }

  static Fordelingsnoegle(kode) {
    return (
      {
        1: "Manuel fordeling",
        2: "Ligelig fordeling",
        3: "Institutions fordeling",
      }[kode] ?? "-"
    );
  }

  static ForretningsHaendelse(kode) {
    return (
      {
        BUH: "Bygning uden Husnummer",
        BYG: "Bygning",
        ENH: "Enhed",
        GRU: "Grund",
        SAG: "BBR-sag",
        TEK: "Teknisk Anlæg",
        TUH: "Teknisk Anlæg uden Husnummer",
      }[kode] ?? "-"
    );
  }

  static ForretningsOmraade(kode) {
    return { BBR: "54.15.05.05" }[kode] ?? "-";
  }

  static ForretningsProcess(kode) {
    return (
      {
        0: "Ikke angivet",
        1: "Oprettet grundet nybyggeri",
        10: "Anmeldelsessag",
        11: "Tilladelsessag",
        12: "Opdateret grundet ændring i grunddataregister: Matriklen",
        13: "Opdateret grundet ændring i grunddataregister: DAR",
        14: "Opdateret grundet ændring i grunddataregister: Ejerfortegnelsen",
        15: "Opdateret grundet ændring i grunddataregister: Ejendomsbeliggenhedsregisteret",
        16: "Automatisk lukning af anmeldelsessag",
        17: "Flytning af underliggende elementer på matrikel (Matrikulær ændring)",
        18: "Fordelingsareal af fordelingsareal",
        19: "Opdateret grundet ændret Sikkerhedsklassificering",
        2: "Opdateret grundet til/ombygning",
        20: "Fremdatering af indflytning",
        21: "Opdatering af indberetning",
        22: "ESR Event Processering",
        23: "AWS Event Processering",
        24: "Indberetnings services",
        25: "SKATServices",
        26: "EnergiindberetningProcessering",
        27: "EJDbATilknytningHusnummerService",
        28: "BPFG Tilknyttet gennem Ajorføring hos MU",
        3: "Opdateret grundet nedrivning",
        4: "Fejlrettelse af faktiske fejlregistreringer og udeladelser",
        5: "Faktisk udført ændring uden byggesagsbehandling",
        6: "Opdeling af enheder",
        7: "Sammenlægning af enheder",
        8: "Opdateret som følge af digital indberetning fra borger mm.",
        9: "Opdateret som følge af digital indberetning fra SKAT",
      }[kode] ?? "-"
    );
  }

  static Fredning(kode) {
    return {
      1: "Bygningen fredet iht. bygningsfredningsloven",
      2: "Som 1, men med tinglyste bevaringsbestemmelser jf. lovens §15",
      3: "Tinglyst bevaringsdeklaration, men bygningen ikke fredet",
      4: "På bygningens middelalderlige bygningsdele er der tinglyst fredningsbestemmelser",
      5: "Bygningen indeholder middelalderlige bygningsdele",
      6: "Bygningen og dens umiddelbare omgivelser fredet iht. bygningsfredningsloven",
      7: "Som 6, men med tinglyst bevaringsdeklaration",
      8: "Bygningen bevaringsværdig",
      9: "Bygningen medtaget i registrant, bevaringsplan mm.",
    };
  }

  static GodkendtTomBolig(kode) {
    return (
      {
        0: "Krav om persontilmelding",
        100: "Bolig uden krav om persontilmelding",
      }[kode] ?? "-"
    );
  }

  static GruAfloebsforhold(kode) {
    return (
      {
        1: "Fælleskloakeret: spildevand + tag- og overfladevand",
        10: "Afløb til offentligt kloaksystem",
        101: "SOP: Minirenseanlæg med direkte udledning",
        102: "SOP: Minirenseanlæg med udledning til markdræn",
        103: "SOP: Minirenseanlæg med nedsivning i faskine",
        104: "SOP: Nedsivning til sivedræn",
        105: "SOP: Samletank",
        106: "SOP: Pileanlæg med nedsivning (uden membran)",
        107: "SOP: Pileanlæg uden udledning (med membran)",
        108: "SOP: Beplantede filteranlæg med nedsivning i faskine",
        109: "SOP: Sandfiltre med P-fældning i bundfældningstanken og direkte udledning",
        11: "Afløb til fællesprivat kloaksystem",
        110: "SOP: Sandfiltre med P-fældning i bundfældningstanken og udledning til markdræn",
        12: "Afløb til fællesprivat kloaksystem med tilslutning til spildevandsforsyningens kloaksystem",
        190: "SOP: Andet",
        2: "Fælleskloakeret: spildevand + delvis tag- og overfladevand",
        20: "Afløb til samletank",
        201: "SO: Biologisk sandfilter med direkte udledning",
        202: "SO: Biologisk sandfilter med udledning til markdræn",
        203: "SO: Minirensanlæg med direkte udledning",
        204: "SO: Minirenseanlæg med udledning til markdræn",
        205: "SO: Beplantede filteranlæg med direkte udledning",
        206: "SO: Beplantede filteranlæg med udledning til markdræn",
        21: "Afløb til samletank for toiletvand og mekanisk rensning af øvrigt spildevand",
        29: "Mekanisk rensning med nedsivningsanlæg med tilladelse",
        290: "SO: Andet",
        3: "Fælleskloakeret: spildevand",
        30: "Mekanisk rensning med nedsivningsanlæg (tilladelse ikke påkrævet)",
        301: "OP: Minirenseanlæg med direkte udledning",
        302: "OP: Minirenseanlæg med udledning til markdræn",
        31: "Mekanisk rensning med privat udledning direkte til vandløb, søer eller havet",
        32: "Mekanisk og biologisk rensning (ældre anlæg uden renseklasse)",
        390: "OP: Andet",
        4: "Fælleskloakeret: tag- og overfladevand",
        401: "O: Rodzoneanlæg med direkte udledning",
        402: "O: Rodzoneanlæg med udledning til markdræn",
        403: "O: Minirenseanlæg med direkte udledning",
        404: "O: Minirenseanlæg med udledning til markdræn",
        490: "O: Andet",
        5: "Separatkloakeret: spildevand + tag- og overfladevand",
        501: "Øvrige renseløsninger: Mekanisk med direkte udledning",
        502: "Øvrige renseløsninger: Mekanisk med udledning til markdræn",
        503: "Øvrige renseløsninger: Ældre nedsivningsanlæg med nedsivning til sivebrønd",
        504: "Udledning til jordoverfladen",
        505: "Udledning urenset",
        590: "Øvrige renseløsninger: Andet",
        6: "Separatkloakeret: spildevand + delvis tag- og overfladevand",
        601: "Anden type afløb (større end 30 PE med egen udledning)",
        7: "Separatkloakeret: spildevand",
        70: "Udledning uden rensning direkte til vandløb, søer eller havet",
        701: "Intet afløb",
        75: "Blandet afløbsforhold på ejendommen (er specificeret på bygningen)",
        8: "Separatkloakeret: tag- og overfladevand",
        80: "Anden type afløb",
        9: "Spildevandskloakeret: Spildevand",
        90: "Ingen udledning",
      }[kode] ?? "-"
    );
  }

  static GruVandforsyning(kode) {
    return (
      {
        1: "Alment vandforsyningsanlæg",
        2: "Privat vandforsyningsanlæg",
        3: "Enkeltindvindingsanlæg",
        4: "Brønd",
        6: "Ikke alment vandforsyningsanlæg",
        7: "Blandet vandforsyning",
        9: "Ingen vandforsyning",
      }[kode] ?? "-"
    );
  }

  static gulvBelægning(kode) {
    return (
      {
        1: "Beton",
        2: "Andet",
        3: "Ingen",
      }[kode] ?? "-"
    );
  }

  static HusnummerRolle(kode) {
    return (
      {
        0: "Fastsat til denne",
        1: "Kun vejledende",
      }[kode] ?? "-"
    );
  }

  static Indhold(kode) {
    return (
      {
        10: "Mineralske olieprodukter",
        11: "Fuelolie",
        12: "Fyringsgasolie",
        13: "Diesel",
        14: "Benzin",
        20: "Biobrændstoffer",
        30: "Affaldsprodukter",
        31: "Oliebaserede affaldsprodukter",
        40: "Gylle",
        50: "Ajle, ensilagesaft, mælkerumsvand eller møddingvand",
        60: "Øvrige stoffer, produkter og materiale der kan forurene grundvand, jord og undergrund",
        70: "Korn",
        99: "Andet",
      }[kode] ?? "-"
    );
  }

  static KildeTilKoordinatsaet(kode) {
    return (
      {
        E: "Ejer",
        K: "Kommune",
        L: "Landinspektør",
        M: "Maskinelt dannet eller anden kilde",
      }[kode] ?? "-"
    );
  }

  static KildeTilOplysninger(kode) {
    return {
      1: "Oplyst af ejer eller dennes repræsentant",
      2: "Oplyst af kommunen",
      3: "Oplyst af andre",
      4: "Maskinelt oprettet",
      5: "Oplyst og kontrolleret af kommunen",
    }[kode] || kode;
  }

  static Klassifikation(kode) {
    return (
      {
        1110: "Tank",
        1120: "Silo",
        1130: "Gasbeholder",
        1140: "Affaldsbeholder",
        1150: "Udskiller",
        1210: "Vindmølle",
        1220: "Slanger til jordvarme",
        1230: "Solcelleanlæg",
        1235: "Solvarmeanlæg",
        1240: "Nødstrømsforsyningsanlæg",
        1250: "Transformerstation",
        1255: "Oliefyr",
        1260: "Elskab",
        1265: "Naturgasfyr",
        1270: "(UDFASES) Andet energiproducerende eller - distribuerende anlæg",
        1275: "Halmfyr",
        1280: "Biogasanlæg",
        1285: "Pillefyr",
        1290: "Brændeovn",
        1295: "Andet energianlæg",
        1310: "Vandtårn",
        1320: "Pumpestation",
        1330: "Swimmingpool",
        1340: "Privat rensningsanlæg",
        1350: "Offentligt rensningsanlæg",
        1360: "Regnvandsanlæg",
        1905: "Legeplads",
        1910: "Teknikhus",
        1915: "Døgnautomat",
        1920: "Køleanlæg",
        1925: "Kunstværk",
        1930: "Sirene og mast med sirene",
        1935: "Skilt",
        1940: "Antenne og mast med antenne",
        1945: "Dambrug",
        1950: "Møddingsanlæg",
        1955: "Andet teknisk anlæg",
        1960: "(UDFASES) Ensilageanlæg",
        1965: "Planlager",
        1970: "Fortidsminde, historisk ruin",
      }[kode] ?? "-"
    );
  }

  static Sikkerhedsklassifikation(kode) {
    return (
      {
        0: "Er ikke omfattet af sikkerhedshensyn, jfr. afsnit 7",
        1: "Er beskyttet",
      }[kode] ?? "-"
    );
  }

  static Køkkenforhold(kode) {
    return (
      {
        E: "Eget køkken med afløb",
        F: "Adgang til fælles køkken",
        G: "Fast kogeinstallation i værelse eller på gang",
        H: "Ingen fast kogeinstallation",
      }[kode] ?? "-"
    );
  }

  static Kommunekode(kode) {
    return (
      {
        "0101": "Københavns Kommune",
        "0147": "Frederiksberg Kommune",
        "0151": "Ballerup Kommune",
        "0153": "Brøndby Kommune",
        "0155": "Dragør Kommune",
        "0157": "Gentofte Kommune",
        "0159": "Gladsaxe Kommune",
        "0161": "Glostrup Kommune",
        "0163": "Herlev Kommune",
        "0165": "Albertslund Kommune",
        "0167": "Hvidovre Kommune",
        "0169": "Høje Taastrup Kommune",
        "0173": "Lyngby-Taarbæk Kommune",
        "0175": "Rødovre Kommune",
        "0183": "Ishøj Kommune",
        "0185": "Tårnby Kommune",
        "0187": "Vallensbæk Kommune",
        "0190": "Furesø Kommune",
        "0201": "Allerød Kommune",
        "0210": "Fredensborg Kommune",
        "0217": "Helsingør Kommune",
        "0219": "Hillerød Kommune",
        "0223": "Hørsholm Kommune",
        "0230": "Rudersdal Kommune",
        "0240": "Egedal Kommune",
        "0250": "Frederikssund Kommune",
        "0253": "Greve Kommune",
        "0259": "Køge Kommune",
        "0260": "Halsnæs Kommune",
        "0265": "Roskilde Kommune",
        "0269": "Solrød Kommune",
        "0270": "Gribskov Kommune",
        "0306": "Odsherred Kommune",
        "0316": "Holbæk Kommune",
        "0320": "Faxe Kommune",
        "0326": "Kalundborg Kommune",
        "0329": "Ringsted Kommune",
        "0330": "Slagelse Kommune",
        "0336": "Stevns Kommune",
        "0340": "Sorø Kommune",
        "0350": "Lejre Kommune",
        "0360": "Lolland Kommune",
        "0370": "Næstved Kommune",
        "0376": "Guldborgsund Kommune",
        "0390": "Vordingborg Kommune",
        "0400": "Bornholms Regionskommune",
        "0410": "Middelfart Kommune",
        "0420": "Assens Kommune",
        "0430": "Faaborg-Midtfyn Kommune",
        "0440": "Kerteminde Kommune",
        "0450": "Nyborg Kommune",
        "0461": "Odense Kommune",
        "0479": "Svendborg Kommune",
        "0480": "Nordfyns Kommune",
        "0482": "Langeland Kommune",
        "0492": "Ærø Kommune",
        "0510": "Haderslev Kommune",
        "0530": "Billund Kommune",
        "0540": "Sønderborg Kommune",
        "0550": "Tønder Kommune",
        "0561": "Esbjerg Kommune",
        "0563": "Fanø Kommune",
        "0573": "Varde Kommune",
        "0575": "Vejen Kommune",
        "0580": "Aabenraa Kommune",
        "0607": "Fredericia Kommune",
        "0615": "Horsens Kommune",
        "0621": "Kolding Kommune",
        "0630": "Vejle Kommune",
        "0657": "Herning Kommune",
        "0661": "Holstebro Kommune",
        "0665": "Lemvig Kommune",
        "0671": "Struer Kommune",
        "0706": "Syddjurs Kommune",
        "0707": "Norddjurs Kommune",
        "0710": "Favrskov Kommune",
        "0727": "Odder Kommune",
        "0730": "Randers Kommune",
        "0740": "Silkeborg Kommune",
        "0741": "Samsø Kommune",
        "0746": "Skanderborg Kommune",
        "0751": "Aarhus Kommune",
        "0756": "Ikast-Brande Kommune",
        "0760": "Ringkøbing-Skjern Kommune",
        "0766": "Hedensted Kommune",
        "0773": "Morsø Kommune",
        "0779": "Skive Kommune",
        "0787": "Thisted Kommune",
        "0791": "Viborg Kommune",
        "0810": "Brønderslev Kommune",
        "0813": "Frederikshavn Kommune",
        "0820": "Vesthimmerlands Kommune",
        "0825": "Læsø Kommune",
        "0840": "Rebild Kommune",
        "0846": "Mariagerfjord Kommune",
        "0849": "Jammerbugt Kommune",
        "0851": "Aalborg Kommune",
        "0860": "Hjørring Kommune",
      }[kode] ?? "-"
    );
  }

  static KondemneretBoligenhed(kode) {
    return (
      {
        0: "Ikke kondemneret boligenhed",
        1: "Kondemneret boligenhed",
      }[kode] ?? "-"
    );
  }

  static Konstruktion(kode) {
    return (
      {
        1: "Åben konstruktion",
        2: "Lukket konstruktion",
      }[kode] ?? "-"
    );
  }

  static Konstruktionsforhold(kode) {
    return (
      {
        1: "Bygningen har jernbetonskelet",
        2: "Bygningen har ikke jernbetonskelet",
      }[kode] ?? "-"
    );
  }

  static Koordinatsystem(kode) {
    return (
      {
        1: "(UDFASES )System 34",
        2: "(UDFASES) System 45",
        3: "(UDFASES) KP2000 (System 2000)",
        4: "(UDFASES) UTM ED50",
        5: "UTM 32 Euref89",
      }[kode] ?? "-"
    );
  }

  static KvalitetAfKoordinatsaet(kode) {
    return (
      {
        1: "Sikker geokodning",
        2: "(UDFASES) Næsten sikker",
        3: "Usikker geokodning",
      }[kode] ?? "-"
    );
  }

  static Livcyklus(kode) {
    return (
      {
        1: "Start",
        10: "Historisk",
        11: "Fejlregistreret",
        12: "Midlertidig Afsluttet",
        13: "Delvis Afsluttet",
        14: "Henlagt",
        15: "Modtaget",
        16: "UnderBehandling",
        17: "Fejl",
        18: "Udført",
        19: "Foreløbig",
        2: "Projekteret",
        20: "BomSagModtaget",
        21: "BomSagUnderBehandling",
        22: "BomSagFejl",
        23: "BomSagUdført",
        3: "Under opførsel",
        4: "Sagsgrund",
        5: "Oprettet",
        6: "Opført",
        7: "Gældende",
        8: "Godkendt",
        9: "Afsluttet",
      }[kode] ?? "-"
    );
  }

  static LovligAnvendelse(kode) {
    return (
      {
        A: "Bolig har bevaret helårsstatus efter områdets udlægning til sommerhusområde",
        B: "Tidsbegrænset dispensation til helårsbeboelse til ejer. Dispensation bortfalder ved ejerskifte",
        C: "Dispensation til helårsbeboelse til ejer. Dispensation bortfalder ved ejerskifte",
        D: "Personlig ret for pensionister til helårsbeboelse",
        E: "Dispensation til afvikling af ulovlig helårsbeboelse",
        I: "Ikke relevant for denne enhed",
      }[kode] ?? "-"
    );
  }

  static Materiale(kode) {
    return (
      {
        1: "Plast",
        2: "Stål",
        3: "Plasttank med udvendig stålvæg",
      }[kode] ?? "-"
    );
  }

  static MedlemsskabAfSplidevandforsyning(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Ikke medlemskab af spildevandsforsyning",
        2: "Medlemskab af spildevandsforsyning",
      }[kode] ?? "-"
    );
  }

  static MidlertidigOprettelseEllerFuldfoersel(kode) {
    return (
      {
        0: "Bygningen er ikke midlertidig oprettet",
        1: "Bygningen er midlertidig oprettet, nybyggeri",
        2: "Bygningen er midlertidig fuldført, nybyggeri",
        3: "Bygningen er midlertidig oprettet, om-/tilbygning",
        4: "Bygningen er midlertidig fuldført, om-/tilbygning",
      }[kode] ?? "-"
    );
  }

  static Niveau(kode) {
    return (
      {
        ALL: "Alle",
        BYG: "Bygning",
        EJD: "Ejendom",
        ENH: "Enhed",
        GRU: "Grund",
        SAG: "Byggesag",
        TEK: "Teknisk Anlæg",
        UMAT: "Umatrikuleret",
      }[kode] ?? "-"
    );
  }

  static NiveauType(kode) {
    return (
      {
        1: "Grund",
        2: "Bygning",
        3: "TekniskAnlaeg",
        4: "Etage",
        5: "Opgang",
        6: "Enhed",
      }[kode] ?? "-"
    );
  }

  static OffentligStoette(kode) {
    return (
      {
        0: "Ingen offentlig støtte",
        10: "Almen familiebolig",
        15: "Støttet privat udlejningsbolig",
        20: "Støttet privat andelsbolig",
        25: "Almen ungdomsbolig",
        30: "Støttet privat ungdomsbolig",
        40: "Almen ældrebolig",
        42: "Almen plejebolig",
        80: "Serviceareal",
      }[kode] ?? "-"
    );
  }

  static OmfattetAfByggeskadeforsikring(kode) {
    return (
      {
        0: "Bygningen er ikke omfattet af byggeskadeforsikring",
        10: "Bygningen er omfattet af byggeskadeforsikring",
        11: "Bygningen er opført som selvbyg",
        12: "Udlejningsejendom",
      }[kode] ?? "-"
    );
  }

  static Opvarmningsmiddel(kode) {
    return (
      {
        1: "Elektricitet",
        2: "Gasværksgas",
        3: "Flydende brændsel",
        4: "Fast brændsel",
        6: "Halm",
        7: "Naturgas",
        9: "Andet",
      }[kode] ?? "-"
    );
  }

  static Oversvoemmelsesselvrisiko(kode) {
    return (
      {
        0: "Ingen udbetalt erstatning fra Naturskaderådet",
        1: "(UDFASES) Bygningens selvrisiko er forhøjet til trin 1",
        2: "(UDFASES) Bygningens selvrisiko er forhøjet til trin 2",
        3: "Naturskaderådet registrerer, når der er udbetalt erstatning som følge af stormflod, oversvømmelse fra vandløb og søer og tørke. Læs om naturskadeordningerne på naturskaderaadet.dk.",
      }[kode] ?? "-"
    );
  }

  static Placering(kode) {
    return (
      {
        0: "Ukendt",
        1: "Nedgravet",
        2: "Over terræn, udendørs",
        3: "Indendørs",
      }[kode] ?? "-"
    );
  }

  static PaaSoeTerritorie(kode) {
    return (
      {
        0: "Ikke på søterritorie",
        1: "På søterritorie",
      }[kode] ?? "-"
    );
  }

  static Rensningspaabud(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Rensning ok. Intet påbud",
        2: "Rensning skal forbedres til SOP",
        3: "Rensning skal forbedres til SO",
        4: "Rensning skal forbedres til OP",
        5: "Rensning skal forbedres til O",
        6: "Skal tilsluttes spildevandsforsyningsselskab",
        7: "Skal tilsluttes separatkloakering",
      }[kode] ?? "-"
    );
  }

  static Sagsniveau(kode) {
    return (
      {
        1: "Grund",
        2: "Bygning",
        3: "Teknik",
        4: "Etage[kode]",
        5: "Opgang",
        6: "Enhed",
      }[kode] ?? "-"
    );
  }

  static Sloejfning(kode) {
    return (
      {
        1: "Tanken er afblændet",
        10: "Jordvarmeslangerne er sløjfet/taget ud af drift",
        2: "Tanken er tømt og afblændet",
        3: "Tanken er tømt, afblændet og opfyldt",
        4: "Tanken er tømt, afblændet og påfyldningsstuds samt udluftningsrør afmonteret",
      }[kode] ?? "-"
    );
  }

  static Stoerrelsesklasse(kode) {
    return (
      {
        1: "Under 6.000 l",
        2: "6.000 l - 100.000 l",
        3: "Over 100.000 l",
      }[kode] ?? "-"
    );
  }

  static SupplerendeAnvendelseskode(kode) {
    return (
      {
        210: "Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o. lign",
        220: "Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v.",
        230: "El-, gas-, vand- eller varmeværk, forbrændingsanstalt m.v.",
        290: "Anden bygning til landbrug, industri etc.",
        310: "Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910",
        320: "Bygning til kontor, handel, lager, herunder offentlig administration",
        330: "Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed",
        390: "Anden bygning til transport, handel etc",
        410: "Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o. lign.",
        420: "Bygning til undervisning og forskning.",
        430: "Bygning til hospital, sygehjem, fødeklinik o. lign.",
        440: "Bygning til daginstitution",
        490: "Bygning til anden institution, herunder kaserne, fængsel o. lign.",
        520: "Bygning til ferieformål m.v., bortset fra sommerhus (feriekoloni, vandrehjem o. lign.)",
        530: "Bygning i forbindelse med idrætsudøvelse (klubhus, idrætshal, svømmehal o. lign.)",
        590: "Anden bygning til fritidsformål",
      }[kode] ?? "-"
    );
  }

  static SupplerendeIndvendigKorrosionsbeskyttelse(kode) {
    return (
      {
        1: "Glasfiberbelægning",
        2: "Organisk belægning",
        3: "Anoder",
        4: "Zinkstøvmaling",
      }[kode] ?? "-"
    );
  }

  static SupplerendeOplysningerOmKoordinatsaet(kode) {
    return {
      11: "Koordinatsæt ligger i bygningen/anlægget (over jorden)",
      12: "Koordinatsæt ligger i bygningen/anlægget (under jorden)",
      21: "(UDFASES) Koordinatsæt ligger i bygningen/anlægget (over jorden)",
      22: "(UDFASES) Koordinatsæt ligger i bygningen/anlægget (under jorden)",
      31: "Koordinatsæt ligger på matriklen",
      32: "Ukendt",
    };
  }

  static SupplerendeVarme(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Varmepumpe",
        10: "Biogasanlæg",
        2: "Brændeovne og lignende med skorsten",
        3: "Biopejse og lignende uden skorsten",
        4: "Solpaneler",
        5: "Pejs",
        6: "Gasradiator",
        7: "Elvarme",
        80: "Andet",
        90: "(UDFASES) Bygningen har ingen supplerende varme",
      }[kode] ?? "-"
    );
  }

  static Tagdaekningsmateriale(kode) {
    return (
      {
        1: "Tagpap med lille hældning",
        10: "Fibercement uden asbest",
        11: "Plastmaterialer",
        12: "Glas",
        2: "Tagpap med stor hældning",
        20: "Levende tage",
        3: "Fibercement herunder asbest",
        4: "Betontagsten",
        5: "Tegl",
        6: "Metal",
        7: "Stråtag",
        80: "(UDFASES) Ingen",
        90: "Andet materiale",
      }[kode] ?? "-"
    );
  }

  static Tilladelsesart(kode) {
    return (
      {
        1: "Upersonlig tilladelse uden tidsbegrænsning",
        2: "Personlig tilladelse uden tidsbegrænsning",
        3: "Upersonlig tilladelse med tidsbegrænsing",
        4: "Personlig tilladelse med tidsbegrænsing",
      }[kode] ?? "-"
    );
  }

  static TilladelseTilAlternativBortskaffelseEllerAfledning(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Tilladelse meddelt",
        2: "Tilladelse bortfaldet",
      }[kode] ?? "-"
    );
  }
  static TilladelseTilUdtræden(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Tilladelse meddelt",
        2: "Tilladelse bortfaldet",
      }[kode] ?? "-"
    );
  }

  static ToiletForhold(kode) {
    return (
      {
        A: "Vandskyllende toilet uden for enheden",
        B: "Intet vandskyllende toilet",
        T: "Vandskyllende toilet i enheden",
      }[kode] ?? "-"
    );
  }

  static TypeAfVægge(kode) {
    return (
      {
        1: "Enkeltvægget",
        2: "Dobbeltvægget",
        3: "Dobbeltvægget med overvågning",
        4: "Overjordisk anlæg, hele anlægget er tilgængeligt for udvendig visuel inspektion",
        5: "Tanke som er installeret før 1970, udvendig korrosionsbeskyttelse med bitumenbelægning",
      }[kode] ?? "-"
    );
  }

  static Udlejningsforhold(kode) {
    return (
      {
        1:"Udlejet",
        2:"Benyttet af ejeren",
        3:"Ikke benyttet",
      }[kode] ?? "-"
    )
  }

  static Udledningstilladelse(kode) {
    return (
      {
        0: "Ikke oplyst",
        1: "Udledningstilladelse mangler",
        2: "Renseanlæg etableret før 1974, derfor ikke behov for tilladelse",
        3: "Udledningstilladelse til enkeltprivat renseanlæg",
        4: "Udledningstilladelse til fællesprivat renseanlæg",
        5: "Der foreligger ingen kendt tilladelse",
        6: "Der foreligger tilladelse",
        7: "Tilladelsesforhold er oplyst på bygningsniveau",
      }[kode] ?? "-"
    );
  }

  static VandForsyning(kode) {
    return (
      {
        1: "Alment vandforsyningsanlæg",
        2: "Privat vandforsyningsanlæg",
        3: "Enkeltindvindingsanlæg",
        4: "Brønd",
        6: "Ikke alment vandforsyningsanlæg",
        7: "Blandet vandforsyning",
        9: "Ingen vandforsyning",
      }[kode] ?? "-"
    );
  }

  static Varmeinstallation(kode) {
    return (
      {
        1: "Fjernvarme/blokvarme",
        2: "Centralvarme med én fyringsenhed",
        3: "Ovn til fast og flydende brændsel",
        5: "Varmepumpe",
        6: "Centralvarme med to fyringsenheder",
        7: "Elvarme",
        8: "Gasradiator",
        9: "Ingen varmeinstallation",
        99: "Blandet",
      }[kode] ?? "-"
    );
  }

  static YdervaeggenesMateriale(kode) {
    return (
      {
        1: "Mursten",
        10: "Fibercement uden asbest",
        11: "Plastmaterialer",
        12: "Glas",
        2: "Letbetonsten",
        3: "Fibercement herunder asbest",
        4: "Bindingsværk",
        5: "Træ",
        6: "Betonelementer",
        8: "Metal",
        80: "Ingen",
        90: "Andet materiale",
      }[kode] ?? "-"
    );
  }
}
